import {DriverLocation, TrackingDevice} from "../../classes/classes";
import IReducerAction from "../models/IReducerAction";
import DriverMapTypes from "../types/DriverMap.type";

export interface IDriverMapState {
    drivers: {
        data: DriverLocation[],
        loading: boolean,
        error: ''
    };
    trackingDrivers: {
        data: TrackingDevice[],
        loading: boolean,
        error: ''
    };
}

const initialState: IDriverMapState = {
    drivers: {
        data: [],
        loading: false,
        error: ''
    },
    trackingDrivers: {
        data: [],
        loading: false,
        error: ''
    },
}

export default function DriverMapReducer(state: IDriverMapState = initialState, action: IReducerAction): IDriverMapState
{
    switch (action.type)
    {
        case DriverMapTypes.GET_ALL_DRIVERS_STARTED:
            return {
                ...state,
                ...{
                    drivers: {
                        loading: true,
                        error: '',
                        ...{data: state.drivers.data}
                    }
                }
            };
        case DriverMapTypes.GET_ALL_DRIVERS_SUCCESS:
            return {
                ...state,
                ...{
                    drivers: {
                        loading: false,
                        error: '',
                        data: action.payload,
                    }
                }
            };
        case DriverMapTypes.GET_ALL_DRIVERS_ERROR:
            return {
                ...state,
                ...{
                    drivers: {
                        loading: false,
                        error: action.payload,
                        ...{data: state.drivers.data}
                    }
                }
            }
        case DriverMapTypes.GET_DRIVERS_TRACKING_STARTED:
            return {
                ...state,
                ...{
                    trackingDrivers: {
                        loading: false,
                        error: action.payload,
                        ...{data: state.trackingDrivers.data}
                    }
                }
            }

        case DriverMapTypes.GET_DRIVERS_TRACKING_SUCCESS:
            return {
                ...state,
                ...{
                    trackingDrivers: {
                        loading: false,
                        error: '',
                        data: action.payload,
                    }
                }
            };
        case DriverMapTypes.GET_DRIVERS_TRACKING_ERROR:
            return {
                ...state,
                ...{
                    trackingDrivers: {
                        loading: false,
                        error: action.payload,
                        ...{data: state.trackingDrivers.data}
                    }
                }
            }
        default:
            return state;
    }
}

import React, { useEffect, useState } from "react";
import FormGroup from "../../../../components/FormGroup/FormGroup";
import "./VehicleDetailsForm.scss";
import {useToasts} from "react-toast-notifications";
import Helper from "../../../../helper/Helper";
import {
  getAllMakes,
  getAllModelsMake,
  resetModels,
} from "../../../../redux/actions/Vehicle.action";
import { useDispatch, useSelector } from "react-redux";
import { QuoteVehicleInfo, Vehicle } from "../../../../classes/classes";
import { AllStates } from "../../../../redux/reducers";
import { IVehicleState } from "../../../../redux/reducers/Vehicle.reducer";
import { Queries } from "../../../../services/endpoints/queries";

const colors = ['Red', 'Blue', 'White', 'Black', 'Silver', 'Gold', 'Gray', 'Yellow', 'Green', 'Other'];

const VehicleDetailsForm = ({ data, onSubmit, onCancel }) => {
  const [vehicle, setVehicle] = useState(new Vehicle());
  const { makes, models } = useSelector<AllStates>(
    (x) => x.vehicles
  ) as IVehicleState;

  const dispatch = useDispatch();
  const toast = useToasts();

  const handleOnChanged = (key, value) => {
    let temp = { ...vehicle };

    temp[key] = value;

    setVehicle(temp);
    onSubmit(temp);
    // console.log(temp);
  };

  function changeVinType(newType: "standard" | "special" | "no vin") {
    let temp = { ...vehicle };
    switch (newType) {
      case "standard":
        temp.no_vin = false;
        temp.special_vin = false;
        break;
      case "special":
        temp.no_vin = false;
        temp.special_vin = true;
        break;
      case "no vin":
        temp.no_vin = true;
        temp.special_vin = false;
        break;
    }
    setVehicle(temp);
    onSubmit(temp);
  }

  useEffect(() => {
    setVehicle(new Vehicle(data));
  }, [data]);

  useEffect(() => {
    dispatch(getAllMakes());
  }, []);

  async function decodeVin(vin = vehicle.vin) {
    try {
        console.log('decoding vin: ', vin);
        const data = await Queries.decode_vin({vin});

        const temp = { ...vehicle};
        if (vin){
            vehicle.vin = vin;
        }
        
        vehicle.fillData(data);
        toast.addToast('Decoded vin: ' + vehicle.getName(), {appearance: 'success'});
        
        setVehicle(temp);
        onSubmit(temp);

    } catch (ex) {
        toast.addToast(ex.message, {appearance: 'error'});
    }
}

  return (
    <>
      <div className="col-12 mb-2">
        <p className="fs-12">VIN Type*</p>
      </div>

      <div className="row col-12 vin-cnt">
        <div className="col-6 pb-1">
          <button
            className={`btn-vin ${
              !vehicle.special_vin && !vehicle.no_vin ? "selected-vin" : ""
            }`}
            onClick={() => changeVinType('standard')}
          >
            <span className="d-flex justify-content-between align-items-center">
              <p>US Standard</p>
              <p>17 DIGITS</p>
            </span>
          </button>
        </div>
        <div className="col-6 pb-1">
          <button
            className={`btn-vin ${vehicle.special_vin ? "selected-vin" : ""}`}
            onClick={() => changeVinType('special')}
          >
            <span className="d-flex justify-content-between align-items-center">
              <p>Special</p>
              <p>ANY DIGITS</p>
            </span>
          </button>
        </div>
        <div className="col-6 pb-1">
          <button
            className={`btn-vin ${vehicle.no_vin ? "selected-vin" : ""}`}
            onClick={() => changeVinType('no vin')}
          >
            <span className="d-flex justify-content-between align-items-center">
              NO VIN
            </span>
          </button>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 mt-1 d-flex justify-content-between align-items-center">
            <FormGroup
            name={"VIN"}
            value={vehicle.vin}
            required={false}
            label={"VIN"}
            onTextChange={(value) => handleOnChanged("vin", Helper.Masks.VIN(value))}
            />
            <button
              onKeyDown={(evt) => {
                if (evt.keyCode == 9) evt.stopPropagation();
              }}
              onClick={(evt) => decodeVin()}
              className="btn btn-blue py-1 px-4 ml-4 mt-2 font-12"
            >
              DECODE
            </button>
        </div>

        <FormGroup
          name={"year"}
          value={vehicle.year}
          required={false}
          colSize={6}
          label={"Year"}
          onTextChange={(value) =>
            handleOnChanged("year", Helper.Masks.IntNumbers(value, 4))
          }
        />

        <FormGroup
          name={"make"}
          value={vehicle.make}
          required={false}
          colSize={6}
          label={"Make"}
          onTextChange={(value) => handleOnChanged("make", value)}
        />

        <FormGroup
          name={"model"}
          value={vehicle.model}
          label={"Model"}
          type="combobox"
          options={{
            data: models,
            value: (x) => x.Model_Name,
            label: (y) => y.Model_Name,
          }}
          onBlur={(evt) => {
            dispatch(resetModels());
          }}
          onFocus={(evt) => {
            if (vehicle.make.trim() != "")
              dispatch(getAllModelsMake(vehicle.make.trim()));
          }}
          placeholder={"Model"}
          onTextChange={(value) => handleOnChanged("model", value)}
        />

        <FormGroup
          name={"submodel"}
          value={data.submodel}
          colSize={6}
          required={false}
          label={"Submodel"}
          onTextChange={(value) => handleOnChanged("submodel", value)}
        />

        <FormGroup
          name={"vehicletype"}
          value={""}
          required={false}
          colSize={6}
          label={"Vehicle type"}
          type={"select"}
          placeholder={vehicle.type}
          //options={{data: type, value: x => x, label: x => x}}
          onTextChange={(value) => console.log(value)}
        />

        <FormGroup
          name={"color"}
          value={"hola"}
          required={false}
          colSize={6}
          label={"Color"}
          type={"select"}
          placeholder={vehicle.color || '-- Select a color --'}
          options={{data: colors, value: x => x, label: x => x}}
          onTextChange={(event) => handleOnChanged('color', event)}
        />

        <FormGroup 
        colSize={6}
        name={'license_plate'}
        value={vehicle.license_plate}
        label={'License Plate'}
        placeholder={'License plate #'}
        onTextChange={value => handleOnChanged('license_plate', value)}/>

        {/* <FormGroup
          name={"registered_state"}
          value={""}
          required={false}
          colSize={6}
          label={"Registered state"}
          type={"select"}
          placeholder={"--"}
          options={{data: Helper.States, value: x => x, label: x => x}}
          onTextChange={(value) => handleOnChanged('license_state', value)}
        /> */}

        <FormGroup
          value={vehicle.internal_note}
          //onTextChange={(evt) => setCurrentData({...currentData, note: evt})}
          textAreaHeight={"8rem"}
          className={"mt-3"}
          placeholder="Inspection notes..."
          type="textarea"
          name="inspection_notes"
          label="Notes"
        />
      </div>
    </>
  );
};

export default VehicleDetailsForm;

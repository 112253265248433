import legTypes from "../types/Leg.type";

export interface ILegHistoryState {
    loading: boolean;
    error: string;
    data: any[];
}

const initialState: ILegHistoryState = {
    loading: true,
    data: [],
    error: ''
}

const LegHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case legTypes.FETCH_LEG_SEARCH:
            return {
                ...state,
                loading: true
            };
        case legTypes.FETCH_LEGS_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case legTypes.FETCH_LEGS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

export default LegHistoryReducer;

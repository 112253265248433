import { gql } from 'apollo-boost';
import moment from 'moment';
import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useToasts } from 'react-toast-notifications';
import environments from '../../../environments/environments';
import { Address, Carrier, Contact, Customer, CustomerProfile, Driver, Leg, Location, ServiceCharge, Stop, TimeFrame } from '../../classes/classes';
import LegMutations from '../../graphql/mutation/Leg';
import Helper from '../../helper/Helper';
import useLoading from '../../hooks/useLoading/useLoading';
import { GraphqlService } from '../../services/graphql.service';
import CarrierDriverSelector from '../CarrierDriverSelector/CarrierDriverSelector';
import FormGroup from '../FormGroup/FormGroup';
import LegForm from '../LegForm/LegForm';
import StopLegEntry from '../NewOrder/StopLegEntry/StopLegEntry';
import LegSection from './LegSection';

const mutation = gql`
    mutation($legs: [Leg_request_input], $carrier_message: String, $hours: Int){
        split_leg(legs: $legs, carrier_message: $carrier_message, hours:$hours){
            success
            message
        }
    }
`;

interface ISplitRoundtripLeg {
    leg: Leg, onClose: (evt) => any, master_id?: number, vehicle_order_id?: number, onLegSplitted: (legs) => any, roundtrip?: boolean
}

const SplitRoundtripLeg: FC<ISplitRoundtripLeg> = ({leg, onClose, onLegSplitted, roundtrip}) => {

    const [hours, setHours] = useState('');
    const [changeReason, setChangeReason] = useState(null);

    const [animationOn, setAnimationOn] = useState(true);
    const [leg1, setLeg1] = useState(new Leg());
    const [leg2, setLeg2] = useState(new Leg());
    const [totalAmount, setTotalAmount] = useState(0);
    const textareaRef = useRef<HTMLTextAreaElement>();

    const loading = useLoading();

    const toast = useToasts();


    // const getLegFormatted = (leg: Leg) => {
    //     return {
    //         equipment: leg.equipment,
    //         equipment_price: +leg.equipment_price,
    //         id: leg.id,
    //         master_order_id: master_id,
    //         vehicle_order_id: vehicle_order_id,
    //         velox_order_id: leg.velox_order_id,
    //         price: +leg.carrier_pay,
    //         pickup: {
    //             id: leg.getPickup().id,
    //             time_scheduled: leg.getPickup().time_scheduled,
    //             is_pickup: true,
    //             location_id: leg.getPickup().location.id,
    //             nofail: leg.getPickup().nofail,
    //             nofail_price: +leg.getPickup().nofail_price,
    //             contacts: leg.getPickup().contacts.map(x => ({id: x.id})),
    //             time_frame: leg.getPickup().time_frame
    //         },
    //         dropoff: {
    //             id: leg.getDropoff().id,
    //             time_scheduled: leg.getDropoff().time_scheduled,
    //             is_pickup: false,
    //             location_id: leg.getDropoff().location.id,
    //             nofail: leg.getDropoff().nofail,
    //             nofail_price: +leg.getDropoff().nofail_price,
    //             contacts: leg.getDropoff().contacts.map(x => ({id: x.id})),
    //             time_frame: leg.getDropoff().time_frame
    //         }
    //     }
    // }
    function getLegFormatted(leg: Leg)
    {
        let res = {
            "id":leg?.id,
            carrier_pay: environments.JP_CARRIER_ID == leg?.carrier?.id && leg.driver?.id != null ? +leg.driver_pay : +leg.carrier_pay,
            driver_pay: +leg.driver_pay,
            "vehicle_order":{
               "id":leg.vehicle_order.id,
               "vehicle":{
                  "year":leg.vehicle_order.vehicle.year,
                  "make":leg.vehicle_order.vehicle.make,
                  "model":leg.vehicle_order.vehicle.model,
                  "vin":leg.vehicle_order.vehicle.vin
               }
            },
            "carrier":{
               "id": leg.carrier?.id,
               "name": leg.carrier?.name
            },
            "driver":{
               "id":leg.driver?.id,
               "name":leg.driver?.name
            },
            "service_charges":[
               {
                  "id":leg?.service_charges[0]?.id,
                  "name":leg.equipment,
                  "amount":+leg.equipment_price
               }
            ],
            "stops": leg.stops.map(stop => ({
                "id":stop.id,
                "is_pickup":stop.is_pickup,
                "completed":stop.completed,
                "time_scheduled":stop.time_scheduled,
                "time_frame": stop.time_frame,
                "nofail":stop.nofail,
                "location":{
                   "id":stop.location.id,
                   "title":stop.location.title,
                   "customer":{
                      "id":stop.location.customer.id,
                      "name":stop.location.customer.name,
                      "key":stop.location.customer.key
                   },
                   "address":{
                      "street_number":stop.location.address.street_number,
                      "street":stop.location.address.street,
                      "city":stop.location.address.city,
                      "state":stop.location.address.state,
                      "postal_code":stop.location.address.postal_code,
                      "country":stop.location.address.country
                   }
                },
                "contacts": stop.contacts.map(contact => ({id: contact.id, name: contact.name}))
             })),
             velox_order: leg.velox_order
             
            //  legPrice: +leg.equipment_price
         }
         return res;
    }


    function splitLeg() {


        setTotalAmount(+leg.equipment_price);

        const leg1 = new Leg();

        const leg2 = new Leg();

        leg1.id = leg.id;


        leg1.equipment = leg.equipment;
        leg1.equipment_price = leg.equipment_price;

        leg1.carrier = new Carrier(leg.carrier);
        leg1.driver = new Driver(leg.driver);
        leg1.carrier_pay = leg.carrier_pay;
        if (leg1.carrier.id == environments.JP_CARRIER_ID)
            leg1.driver_pay = leg.carrier_pay;

        leg2.equipment = leg.equipment;


        let s1 = new Stop(leg.getPickup());
        let s2 = new Stop(leg.getPickup());
        s2.is_pickup = false;
        s2.location = new Location();
        s2.id = null;
        s2.contacts = [];
        
        s2.time_scheduled = moment(s1.time_scheduled).add(1, 'day').toDate();
        s2.time_frame.start = moment(s1.time_frame.start).add(1, 'day').toDate();
        s2.time_frame.end = moment(s1.time_frame.end).add(1, 'day').toDate();


        let s3 = new Stop(leg.getDropoff());
        s3.time_scheduled = moment(s2.time_scheduled).add(1, 'day').toDate();
        s3.time_frame.start = moment(s2.time_frame.start).add(1, 'day').toDate();
        s3.time_frame.end = moment(s2.time_frame.end).add(1, 'day').toDate();

        let s4 = new Stop(leg.getDropoff());
        s3.is_pickup = true;
        s3.location = new Location();
        s3.contacts = [];
        s3.id = null;
        // console.log(s)

        leg1.setStop(s1);
        leg1.setStop(s2);

        leg2.setStop(s3);
        leg2.setStop(s4);


        // const temp = new Leg(leg);


        // const leg1 = new Leg(temp);


        // const dindex1 = leg1.stops.findIndex(x => !x.is_pickup);




        // const drop1 = JSON.parse(JSON.stringify(leg1.stops[dindex1]));


        // leg1.stops[dindex1] = JSON.parse(JSON.stringify(leg1.getPickup()));
        // // console.log(leg1);
        // leg1.stops[dindex1].is_pickup = false;


        // const leg2 = new Leg();
        // const dindex2 = leg2.stops.findIndex(x => !x.is_pickup);

        // leg2.equipment = leg1.equipment;

        // leg2.stops[dindex2 == 1 ? 0 : 1] = new Stop();

        // leg1.stops[dindex2 == 1 ? 0 : 1].time_scheduled = new Date(drop1.time_scheduled);
        // leg1.stops[dindex2 == 1 ? 0 : 1].time_frame = new TimeFrame(drop1.time_frame);
        // leg2.stops[dindex2] = new Stop(drop1);



        // setSelectedLeg({...selectedLeg, leg: leg1, legSplit: leg2});
        setLeg1(leg1);

        setLeg2(leg2);
    }

    function getInvalidMessage(myLeg: Leg) {
        const vehicle = myLeg.vehicle_order.vehicle;

        // if (vehicle.year.trim() == '' || vehicle.make.trim() == '' || vehicle.model.trim() == '')
        //     return 'Vehicle has missing information';

        const pickup = myLeg.getPickup();

        if (pickup.contacts.length == 0 || !pickup.location.customer.id)
            return 'Pickup has missing information';

        const dropoff = myLeg.getDropoff();
        
        if (dropoff.contacts.length == 0 || !dropoff.location.customer.id)
            return 'Dropoff has missing information';

        if (myLeg.equipment.trim() == '')
            return 'Equipment is missing';

        if (+myLeg.equipment_price == 0)
            return 'Equipment price is missing';

        // if (myLeg.driver?.id && +myLeg?.driver_pay == 0 && myLeg?.carrier?.id == environments.JP_CARRIER_ID)
        //     return 'Driver pay is missing';

        // if (myLeg.carrier?.id && (!myLeg?.driver?.id || myLeg?.carrier?.id != environments.JP_CARRIER_ID))
        // {
        //     if (+myLeg?.carrier_pay == 0)
        //         return 'Carrier pay is missing';
            
        //     if (+hours == 0)
        //         return 'Expires In (Hours) information is missing'
        // }


        return '';

            
    }


    async function submitSplitLeg(legs) {

        const error1 = getInvalidMessage(leg1);

        if (error1)
            return toast.addToast('Error in first leg: ' + error1, {appearance: 'warning', autoDismiss: true});

        const error2 = getInvalidMessage(leg2);
        

        if (error2)
            return toast.addToast('Error in seconds leg: ' + error2, {appearance: 'warning', autoDismiss: true});


        // if ()
        const price = +leg.equipment_price;
        const price1 = +leg1.equipment_price;
        const price2 = +leg2.equipment_price;

        if (price != price1 + price2)
        {
            const div = <div>
                <p className='font-10'>Leg prices must be {price.toCurrency()}</p>
                <p className='font-10'>First leg price: {price1.toCurrency()}</p>
                <p className='font-10'>Second leg price: {price2.toCurrency()}</p>
                <p className='font-10'>Total price: {(price1 + price2).toCurrency()}</p>
            </div>

            return toast.addToast(div, {appearance: 'warning', autoDismiss: true});
        }

        
        if (changeReason == null && legs.some(x => x.carrier.id))
        {
            setChangeReason('');
            setHours('1');
            return setTimeout(() => {
                textareaRef.current?.focus();   
            });
        }
        

        


        const _legs = [getLegFormatted(legs[0]), getLegFormatted(legs[1])];

        try {
                loading.open('Splitting leg...')
                const data = await GraphqlService.SendMutation(mutation, {legs: _legs, carrier_message: changeReason, hours: +hours});
                // //console.log(data);
                if (!data?.success)
                    throw new Error(data?.message || 'Something went wrong');
                loading.close();
                toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
                onLegSplitted(legs);


        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});

        }
    }


    function handleSameAsBillingClicked(myLeg: Leg, is_pickup: boolean) {
        
        const temp = new Leg(myLeg);
        const location = new Location();
        location.address = new Address(leg.vehicle_order.master_order.customer.billing_address);
        location.customer = new Customer(leg.vehicle_order.master_order.customer);
        const contacts = leg.vehicle_order.master_order.contacts.map(c => new CustomerProfile(c));
        const stop = new Stop(temp[is_pickup ? 'getPickup' : 'getDropoff']());
        stop.location = new Location(location);
        stop.contacts = contacts.map(c => new CustomerProfile(c));
       

        if (!is_pickup && myLeg.id)
        {
            const x = new Stop(leg2.getPickup());
            x.location = new Location(location);
            x.contacts = contacts.map(c => new CustomerProfile(c));
            const l = new Leg(leg2);
            l.setStop(x);
            setLeg2(l);
        }
        else if (is_pickup && !myLeg.id)
        {
            const x = new Stop(leg1.getDropoff());
         
            x.location = new Location(location);
            x.contacts = contacts.map(c => new CustomerProfile(c));
            const l = new Leg(leg1);
            l.setStop(x);
            setLeg1(l);
        }

        temp.setStop(new Stop(stop));

        if (myLeg.id)
            setLeg1(temp);
        else
            setLeg2(temp);
    }

    function handleStopChanged(myLeg: Leg, stop: Stop) {
        const temp = new Leg(myLeg);

        if (!stop.is_pickup && myLeg.id)
        {
            console.log('here???')
            const x = new Stop(stop);
            x.time_scheduled = leg2.getPickup().time_scheduled; 
            x.time_frame = new TimeFrame(leg2.getPickup().time_frame); 
            x.is_pickup = true;
            const l = new Leg(leg2);
            l.setStop(x);
            setLeg2(l);
        }
        else if (stop.is_pickup && !myLeg.id)
        {
            const x = new Stop(stop);
            x.time_scheduled = leg1.getDropoff().time_scheduled; 
            x.time_frame = new TimeFrame(leg1.getDropoff().time_frame); 
            x.is_pickup = false;
            const l = new Leg(leg1);
            l.setStop(x);
            setLeg1(l);
        }

        temp.setStop(new Stop(stop));

        if (myLeg.id)
            setLeg1(temp);
        else
            setLeg2(temp);
    }

    function isTimeRequired() {
        return (leg1.carrier.id && leg1.carrier.id != environments.JP_CARRIER_ID) || (leg2.carrier.id && leg2.carrier.id != environments.JP_CARRIER_ID);
    }

    function handleOnLegChanged(myLeg: Leg) {
        if (myLeg.id)
            setLeg1(myLeg);
        else
            setLeg2(myLeg);
    }

    function roundtripLeg() {
        const l = new Leg(leg);

    }

    useEffect(() => {
        splitLeg();
        setAnimationOn(!roundtrip);
        setTimeout(() => {
            setAnimationOn(false)
        }, 1500);
    }, [leg])

    function handleEquipmentChanged(myLeg: Leg, key, value) {

        const temp = new Leg(myLeg);
        temp[key] = value;
        temp.service_charges = [];
        if (myLeg.id)
            setLeg1(temp);
        else
            setLeg2(temp);
    }

   
    


    return <ReactModal isOpen={true} className={'modal-1016'}>
        <div className='row mb-3'>
            <div className='box'>
                <h4 className='font-12 mb-1'>{leg?.vehicle_order?.vehicle?.vin || '-'}</h4>    
                <h4 className='font-12 text-center font-normal'>{leg?.vehicle_order?.vehicle?.getName()}</h4>    
            </div>
            <h4 className='font-14 text-center'>{roundtrip ? 'Roundtrip' : 'Split'} Leg</h4>
            <div className='d-flex'>
                <h4 className="font-14 mr-3">Total: {Helper.FORMAT.USCurrency(totalAmount)}</h4>
                <h4 onClick={onClose} className='font-14 cursor-pointer'><i style={{position: 'relative', top: 'unset', left: 'unset', right: 'unset', bottom: 'unset'}} className='fas fa-times'></i></h4>
            </div>
        </div>

        <div className={'flex-1-container'} >
            
           {!roundtrip &&  <LegSection carrier_pay={leg?.carrier_pay} driver_pay={leg?.carrier_pay} leg={leg1} handleSameAsBillingClicked={handleSameAsBillingClicked} handleStopChanged={handleStopChanged} handleEquipmentChanged={handleEquipmentChanged} handleOnLegChanged={handleOnLegChanged} />}
           {<LegSection leg={leg2} animationOn={animationOn} handleSameAsBillingClicked={handleSameAsBillingClicked} handleStopChanged={handleStopChanged} handleEquipmentChanged={handleEquipmentChanged} handleOnLegChanged={handleOnLegChanged}  animated />}

        </div>

        <div className="mt-3 row">
            <button className="btn btn-clear text-blue-light col-6 py-2" onClick={onClose}>CANCEL</button>
            <button className="btn btn-blue-light col-6 py-2" onClick={(evt) => submitSplitLeg([leg1, leg2])}>SUBMIT</button>
        </div>

        <ReactModal isOpen={changeReason != null} className={'modal-fit'}>
                                <div className='d-flex' style={{width: '300px', height: 'auto'}}>
                                    <div className="flex-1-container">
                                        <div className="flex-1-container">
                                            <FormGroup innerRef={textareaRef} value={changeReason} onTextChange={(evt) => setChangeReason(evt)} placeholder='Explain the reason for the change....' name='reason' label='Message to carrier / driver' type='textarea' />
                                            {isTimeRequired() &&  <FormGroup  required errorText='Hours must be more than 0' validation={(evt) => +evt > 0} name='expires' label='Must respond to this request within (Hours)' placeholder='Hours'
                                                        value={hours}
                                                        onTextChange={(text) =>setHours(!text ? '' : (Helper.Masks.IntNumbers(text)).toString())}/>}
                                        </div>

                                        <div className="row">
                                            <button onClick={(evt) => setChangeReason(null)} className="btn btn-clear text-blue-light col-6 py-2">CANCEL</button>
                                            <button disabled={isTimeRequired() && +hours <= 0} onClick={(evt) => submitSplitLeg([leg1, leg2])} className="btn btn-blue-light col-6 py-2">SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </ReactModal>
    </ReactModal>

    
}

export default SplitRoundtripLeg;

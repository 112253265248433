import {Attribute} from "../attribute/attribute";
import {Reference} from "../master_order/master_order";

export class Payment {
    __typename?: "Payment"
    id?: number = null;
    time?: string = '';
    type?: string = '';
    amount?: number = 0;
    amount_left?: number = 0;
    bank_account_id?: number = null;
    note?: string = '';
    files?: File[] = [];
    voided?: boolean = false;

    constructor(x?: Payment){
        if(x){
            this.id = x.id;
            this.time = x.time;
            this.type = x.type;
            this.amount = x.amount;
            this.amount_left = x.amount_left;
            this.bank_account_id = x.bank_account_id;
            this.note = x.note;
            this.files = x.files?.map(z => z) || [];
            this.voided = x.voided;            
        }
    }
}

export class File {
    __typename?: "File"
    uid?: string = '';
    name?: string = '';

    constructor(x?: File) {
        if(x) {
            this.uid = x.uid;
            this.name = x.name;
        }
    }
}

export class Bank_Account {
    __typename?: "Bank_account"
    id?: number = null;
    name?: string = '';
    note?: string = '';
    bank?: string = '';
    active?: boolean = false;
    last_4?: string = '';

    constructor(x?: Bank_Account){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.note = x.note;
            this.bank = x.bank;
            this.active = x.active;
            this.last_4 = x.last_4;            
        }
    }
}

export class Payment_Date_Sp {
    __typename?: "Payment_date_SP"
    id?: number = null;
    note?: string = '';
    time?: string = '';
    type?: string = '';
    amount?: number = 0;
    masters?: Master_Payment_Sp[] = [];
    paid_for?: Paid_For[] = [];
    reference?: string = '';
    time_paid?: string = '';
    amount_left?: string = '';
    credit_card?: Attribute = new Attribute();
    bank_account?: Bank_Account = new Bank_Account();
    files?: File[] = [];

    constructor (x?: Payment_Date_Sp){
        if(x){
            this.id = x.id;
            this.note = x.note;
            this.time = x.time;
            this.type = x.type;
            this.amount = x.amount;
            this.masters = x.masters?.map(z => z) || [];
            this.paid_for = x.paid_for?.map(z => z) || [];
            this.reference = x.reference;
            this.time_paid = x.time_paid;
            this.amount_left = x.amount_left;
            this.credit_card = new Attribute(x.credit_card);
            this.bank_account = new Bank_Account(x.bank_account);
            this.files = x.files?.map(z => z) || [];            
        }
    }
}

export class Master_Payment_Sp {
    __typename?: "master_payment_SP"
    id?: number = null;
    references?: Reference[] = [];

    constructor (x?: Master_Payment_Sp){
        if(x){
            this.id = x.id;
            this.references = x.references?.map(z => z) || [];
        }
    }
}

export class Paid_For {
    __typename?: "Paid_for"
    key?: string = ''; 
    name?: string = '';

    constructor(x?: Paid_For){
        if(x){
            this.key = x.key;
            this.name = x.name;
        }
    }
}

export class Bank_Account_Input {
    name?: string = '';
    bank?: string = '';
    note?: string = '';
    last_4?: string = '';

    constructor(x?: Bank_Account_Input){
        if(x){
            this.name = x.name;
            this.bank = x.bank;
            this.note = x.note;
            this.last_4 = x.last_4;            
        }
    }
}

export class File_Input {
    name?: string = '';
    data?: string = '';

    constructor(x?: File_Input){
        if(x) {
            this.name = x.name;
            this.data = x.data;
        }
    }
}

export class Service_Payment_Input {
    service_charge_id?: number = null;
    amount_to_charge?: number = 0;
    name?: string = '';

    constructor(x?: Service_Payment_Input){
        if(x){
            this.service_charge_id = x.service_charge_id;
            this.amount_to_charge = x.amount_to_charge;
            this.name = x.name;
        }
    }
}

export class Transportation_Payments_Input {
    id?: number = null;
    amount_to_charge?: number = 0;
    name?: string = '';

    constructor(x?:Transportation_Payments_Input) {
        if(x){
            this.id = x.id;
            this.amount_to_charge = x.amount_to_charge;
            this.name = x.name;
        }
    }
}

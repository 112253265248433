export class Attribute
{
    id: number = null;
    cardholder_name: string = '';
    number: string = '';
    exp: string = '';
    expired: boolean = false;
    cvv: string = '';
    type: string = '';
    zip: string = '';
    country: string = "USA";
    street: string = "";
    city: string = "";
    state: string = "CA";

    constructor(props?: Attribute)
    {
        if (props)
        {
            this.id = props.id;
            this.cardholder_name = props.cardholder_name;
            this.number = props.number;
            this.type = props.type;
            this.expired = props.expired;
            if (props.exp)
                this.exp = props.exp;
            if (props.cvv)
                this.cvv = props.cvv;
            this.country = props.country;
            this.state = props.state;
            this.city = props.city;
            this.zip = props.zip;
            this.street = props.street;
        }
    }
}

export class inputAttribute{
    __typename?: "inputAttribute"
    id: number = null;
    customer_id: number = null;
    cardholder_name: string = '';
    number: string = '';
    type: string = '';
    expiration: string = '';
    cvc: string = '';
    zip: string = '';
    country: string = '';
    street: string = '';
    city: string = '';
    state: string = '';
    cvv: string = '';
    exp: string = '';

    constructor(x?: inputAttribute) {
        if(x){
            this.id = x.id;
            this.customer_id = x.customer_id;
            this.cardholder_name = x.cardholder_name;
            this.number = x.number;
            this.type = x.type;
            this.expiration = x.expiration;
            this.cvc = x.cvc;
            this.zip = x.zip;
            this.country = x.country;
            this.street = x.street;
            this.city = x.city;
            this.state = x.state;
            this.cvv = x.cvv;
            this.exp = x.exp;
        }
    }
}

export class attribute_input {
    __typename?: "attribute_input"
    id: number = null;
    customer_id: number = null;
    cardholder_name: string = '';
    number: string = '';
    type: string = '';
    expiration: string = '';
    cvc: string = '';
    zip: string = '';
    country: string = '';
    street: string = '';
    city: string = '';
    state: string = '';

    constructor(x?: attribute_input ) {
        if(x) {
            this.id = x.id;
            this.customer_id = x.customer_id;
            this.cardholder_name = x.cardholder_name;
            this.number = x.number;
            this.type = x.type;
            this.expiration = x.expiration;
            this.cvc = x.cvc;
            this.zip = x.zip;
            this.country = x.country;
            this.street = x.street;
            this.city = x.city;
            this.state = x.state;
        }
    }
}

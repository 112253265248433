import {ResultWithLink} from "../../customer_profile/customer_profile";
import {gql} from "apollo-boost";
import {Payee_Request_Input} from "../payable_request";
import {GraphqlService} from "../../../../graphql.service";

export const create_payable_request = (input:{type: string, reference: string, note: string, amount: number, category: string, payable_to: string, bank_account_id: number, time_due: string, payee: Payee_Request_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($type: String, $reference: String, $note: String, $amount: Float, $category: String, $payable_to: String, $bank_account_id: Int, $time_due: String, $payee: payee_request_input) {
      create_payable_request(type: $type, reference: $reference, note: $note, amount: $amount, category: $category, payable_to: $payable_to, bank_account_id: $bank_account_id, time_due: $time_due, payee: payee_request_input) {
        id
        link
        success
        message
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {type, reference, note, amount, category, payable_to, bank_account_id, time_due, payee} as 
  Promise<ResultWithLink>;
}
import React, { useEffect, useRef, useState } from 'react';
import './Signature.scss'
import SignaturePad from 'react-signature-canvas'
import ICONS from '../../../../../assets/svg';
import { useToasts } from 'react-toast-notifications';
import FormGroup from '../../../../components/FormGroup/FormGroup';


const Signature = ({onCancel,onSubmit,imgSaved,inputName='', value}) => {
    const canvasref = useRef<any>()
    const [inputValue,setInputValue]=useState('');
    const[savedImg,setsavedImg]=useState()

    const toast = useToasts()


    useEffect(() => {
        setInputValue(value);
    }, [value])

    const handleSaveSignature=()=>{

        if(canvasref.current.isEmpty()===false && inputValue.trim()){
                let image = new Image();
                image.src = canvasref.current.toDataURL();
                // console.log(image.src)
                onSubmit(image.src,inputValue, inputValue.trim())
        }else toast.addToast('Complete Signature', {appearance: 'error',autoDismiss: true});

    }

    return(
        <>
            <div className=" ps-popup row">

            <div className={'container-clean'}
                        onClick={()=>{
                            canvasref.current.clear()
                        }}
                        >
                        <img  src={ICONS.IconCloseBig}/>
                    </div>
                   
                <div className={'container-signature '}>

         

              <div className={'row '}>
                 <div className="col-12 ">
                    <FormGroup  name={inputName}
                                value={inputValue}
                                label={ inputName === 'origin'?'Person at Origin':'Driver Name'}
                                onTextChange={value => setInputValue(value)}/>
                </div>

                        
                <div className="col-12 ">
                    <span className={'main-text '}>At Origin Signature</span>
                </div>
              { /* <span className={`main-text ${imgSaved?"d-flex ":"v-hidden"}`}>Use saved signature</span>*/}
               
              </div>
                {/*
                    <div className={`w-100 justify-content-end ${imgSaved?"d-flex ":"v-hidden"}`}>
                        <img className={'img-saved-signature '} src={imgSaved} alt="" />
                    </div>
                */}
                     
                            
                            <SignaturePad ref={canvasref} canvasProps={{
                    className:'canvas-signature'
                }}/>
                     

                    <div className="row mt-2">
                    <div className="col-6 text-right">
                        <button className="btn w-100 py-2 bg-white rounded-pill font-12 text-light-blue"
                                onClick={() => onCancel()}>CANCEL
                        </button>
                    </div>
                    <div className="col-6">
                        <button className={"btn w-100 py-2 btn-blue-light rounded-pill fs-12  font-medium-i"}  
                                onClick={()=>handleSaveSignature()}
                        >SAVE</button>
                    </div>
                    </div>

               </div>
            </div>
        </>
    )
}

export default Signature

import {useState} from "react";
import Modal from 'react-modal';
import PickupCustomer from "../NewOrder/PickupCustomer/PickupCustomer";

const LegDetailDestination = ({customer, destination}: {customer: any, destination: any}) => {
    const [pickupLocation, setPickupLocation] = useState(undefined);

    return(
        <>
            <Modal isOpen={pickupLocation != undefined} onRequestClose={() => setPickupLocation(undefined)} >

                <div className="card" style={{width: '100%'}}>
                    <div className="card-body">
                        <PickupCustomer title={'Edit pickup location'}
                                        stop={pickupLocation}
                                        currentCustomer={customer}
                                        showCustomerList={false}
                                        onCancelClicked={() => {setPickupLocation(undefined)}}
                                        onConfirmClicked={() => {setPickupLocation(undefined)}}/>
                    </div>
                </div>
            </Modal>

            <div className={`card bg-gray`}>
                <div className="card-body p-3">
                    <div className="row">
                        <div className="col-6">
                            <p className="font-14 m-0 font-medium text-red">{destination?.address?.street}</p>
                            <p className="font-12 m-0 text-red mb-2">{ destination?.address?.city } { destination?.address?.state }</p>

                            <p className="font-medium font-10 cursor-pointer btn btn-blue-light" style={{width: 'max-content'}}
                               onClick={() => setPickupLocation(destination)}>EDIT</p>
                        </div>

                        <div className="col-6">
                            <div className="card mb-3">
                                <div className="card-body p-3">
                                    <p className="font-12 m-0 font-medium">BMW of North America</p>

                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="font-12 m-0 font-italic">Corporate</p>
                                        <p className="font-12 m-0">BMWNA</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body p-3">

                                    {
                                        destination?.contacts?.map(contact => {
                                            return(
                                                <>
                                                    <p className="font-12 m-0 font-medium">{contact.name}</p>
                                                    <p className="font-12 mb-2 font-italic">Admin</p>

                                                    {
                                                        contact?.value?.map(value => <p key={value}
                                                                                        className="font-12 m-0">{value}</p>)
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default LegDetailDestination;

import {Equipment} from "../equipment";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";

export const get_all_equipments = (input:{}) : Promise<Equipment[]> => {
  let query = gql`
    query {
      get_all_equipments {
        id
        type
        year
        make
        model
        vin
        registration_expiration
        insurance_expiration
        photos {
          uid
          name
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Equipment[]>;
}

export const get_equipment_by_id = (input:{equipment_id: number}) : Promise<Equipment> => {
  let query = gql`
    query(!equipment_id: Int) {
      get_equipment_by_id(equipment_id: $equipment_id) {
        id
        type
        year
        make
        model
        vin
        registration_expiration
        insurance_expiration
        photos {
          uid
          name
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, { equipment_id } as 
  Promise<Equipment>;
}
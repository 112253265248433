import {useEffect, useRef, useState} from "react";
import FormGroup from "../../FormGroup/FormGroup";
import Helper from "../../../helper/Helper";
import {OrderSelection, VehicleItem} from "../OrderSelection";
import {GraphqlService} from "../../../services/graphql.service";
import VehicleQueries from "../../../graphql/query/Vehicle";
import {useToasts} from "react-toast-notifications";
import {useDispatch, useSelector} from "react-redux";
import {AllStates} from "../../../redux/reducers";
import {IVehicleState} from "../../../redux/reducers/Vehicle.reducer";
import {getAllMakes, getAllModelsMake, resetModels} from "../../../redux/actions/Vehicle.action";
import './VehicleInformation.scss';
import { VehicleOrder } from "../../../classes/classes";
// import { VehicleOrder } from "../../../services/endpoints/schemas/vehicle_order/vehicle_order";

const colors = ['Red', 'Blue', 'White', 'Black', 'Silver', 'Gold', 'Gray', 'Yellow', 'Green', 'Other'];
const states = Helper.States;

const VehicleInformation = ({
                                vehicleOrder = undefined,
                                onVehicleOrdersChanged,
                                focusVIN,
                                isAnyVehicleItemDifferent = false,
                                masterID = undefined,
                                showInModal = false
                            }: {
    vehicleOrder: VehicleOrder,
    onVehicleOrdersChanged: (vehicleOrder: VehicleOrder, saveAll?: boolean) => void, masterID?: number,
    isAnyVehicleItemDifferent?: boolean, showInModal?: boolean, focusVIN?: boolean}) => {

    const [currentVehicleOrder, setCurrentVehicleOrder] = useState(new VehicleOrder());

    const [driverNotes, setDriverNotes] = useState('');
    const [bolComments, setBolComments] = useState('');
    const vinRef = useRef<HTMLInputElement>();


    useEffect(() => {
        setCurrentVehicleOrder({...vehicleOrder});
        setBolComments(vehicleOrder.bol_comment);
        setDriverNotes(vehicleOrder.driver_note);

    }, [vehicleOrder])

    const toast = useToasts();
    const dispatch = useDispatch();

    const {makes, models} = useSelector<AllStates>(x => x.vehicles) as IVehicleState;

    useEffect(() => {
        dispatch(getAllMakes());
    }, []);

    useEffect(() => {
        if(focusVIN){
            vinRef.current?.focus();
        }
    }, [focusVIN]);

    async function decodeVin(vin = currentVehicleOrder.vehicle.vin) {
        try {
            const data = await GraphqlService.SendQuery(VehicleQueries.DECODE_VIN, {vin});

            const temp = {...vehicleOrder};
            if (vin)
                temp.vehicle.vin = vin;
            temp.vehicle.fillData(data);
            if (!masterID)
                onVehicleOrdersChanged(temp);
            setCurrentVehicleOrder(temp);
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error'});
        }
    }

    const changeVinType = (key, checked = true) => {
        let temp = {...vehicleOrder};
        if (!temp.vehicle.special_vin && 'incomplete_vin' == key) return;

        temp.vehicle.no_vin = 'no_vin' == key ? checked : temp.vehicle.no_vin && key == 'incomplete_vin';
        temp.vehicle.special_vin = 'special_vin' == key ? checked : temp.vehicle.no_vin || key == 'incomplete_vin';
        temp.vehicle.incomplete_vin = 'incomplete_vin' == key ? !checked : true;

        temp.vehicle.incomplete_vin = !
            (temp.vehicle.no_vin ||
                (temp.vehicle.special_vin && !temp.vehicle.incomplete_vin) ||
                (!temp.vehicle.special_vin && temp.vehicle.vin.length == 17));
        temp.vehicle.no_vin = temp.vehicle.special_vin && temp.vehicle.vin.length == 0 && !temp.vehicle.incomplete_vin;

        setCurrentVehicleOrder(temp);
    }

    const getVinTypeSection = () => {
        return (
            <>
                <div className="row col-12">
                <div className="col-12 mb-2">
                    <p className="fs-12">VIN Type*</p>
                </div>

                <div className="row col-12">
                    <div className="col-3">
                        <button className={` btn-vin ${!currentVehicleOrder.vehicle.special_vin && !currentVehicleOrder.vehicle.no_vin ? 'selected-vin' : ''}`}
                                onClick={() => changeVinType('special_vin', false)}>
                            <span className="d-flex justify-content-between align-items-center">
                                <p>US Standard</p>
                                <p>17 DIGITS</p>
                            </span>
                        </button>
                    </div>
                    <div className="col-3">
                        <button className={` btn-vin ${currentVehicleOrder.vehicle.special_vin && !currentVehicleOrder.vehicle.no_vin ? 'selected-vin' : ''}`}
                                onClick={() => changeVinType('special_vin')}>
                            <span className="d-flex justify-content-between align-items-center">
                                <p>Special</p>
                                <p>ANY DIGITS</p>
                            </span>
                        </button>
                    </div>
                    <div className="col-3">
                        <button className={` btn-vin ${currentVehicleOrder.vehicle.no_vin ? 'selected-vin' : ''}`}
                                onClick={() => changeVinType('no_vin')}>
                            <span className="d-flex justify-content-between align-items-center">NO VIN</span>
                        </button>
                    </div>
                    <div className="col-3">
                        <button className={`font-8 btn btn-vin ${currentVehicleOrder.vehicle.not_running ? 'btn-danger' : ''}`}
                                onClick={() => {
                                    let temp = {...vehicleOrder};
                                    temp.vehicle.not_running = !temp.vehicle.not_running;

                                    setCurrentVehicleOrder(temp);
                                }}>
                            <span className="d-flex justify-content-between align-items-center">
                            <p>NOT RUNNING</p>
                                <p>(INOP)</p>
                                </span>
                        </button>
                    </div>


                </div>
                </div>
            </>
        );
    }

    const handleOnChanged = (key, value) => {
        // let temp = new VehicleOrder(vehicleOrder);
        let temp = {...currentVehicleOrder};

        temp.vehicle[key] = value;
        if (!temp.vehicle.special_vin) {
            temp.vehicle.vin = temp.vehicle.vin.substring(0, 17);
        }

        if (key == 'vin') {
            temp.vehicle.incomplete_vin = !
                (temp.vehicle.no_vin ||
                    (temp.vehicle.special_vin && !temp.vehicle.incomplete_vin) ||
                    (!temp.vehicle.special_vin && temp.vehicle.vin.length == 17));
            temp.vehicle.no_vin = temp.vehicle.special_vin && temp.vehicle.vin.length == 0 && !temp.vehicle.incomplete_vin;
        }

        // if (key == 'vin' && value.trim().length == 17 && !temp.vehicle.special_vin)
        //     return decodeVin(value);


        setCurrentVehicleOrder(temp);

        if (!masterID)
            onVehicleOrdersChanged(temp);
    }

    const handleOnUpdate = () => {
        const temp = new VehicleOrder(currentVehicleOrder);
        temp.bol_comment = bolComments;
        temp.driver_note = driverNotes;

        setCurrentVehicleOrder(temp);
        onVehicleOrdersChanged(temp);
    }

    return (
        <>
            {
                <div className={`row ${isAnyVehicleItemDifferent ? 'bg-gold' : ''}`}>
                    <div className="col-12 d-flex justify-content-between mb-4 z-index-1">
                        <p className="fs-14 font-medium">Vehicle details</p>
                        <p className="fs-10 font-medium text-gray">CLEAR ALL</p>
                        { isAnyVehicleItemDifferent && <button onClick={()=>{onVehicleOrdersChanged(currentVehicleOrder, true)}} className="fs-10 font-medium btn btn-blue-outline">SAVE ALL</button>}
                    </div>

                    <div className={`${showInModal ? 'col-6' : 'col-12'} col-lg-12`}>
                        <div className="row">
                            <div className="col-12">
                                <div className="row justify-content-start">
                                    {getVinTypeSection()}

                                    <div className="col-12 mt-1 d-flex justify-content-between align-items-center">
                                        <FormGroup name={'vin'}
                                                   value={currentVehicleOrder.vehicle.vin}
                                                   label={'VIN'}
                                                   innerRef={vinRef}
                                                   placeholder={''}
                                                   onTextChange={value => handleOnChanged('vin', Helper.Masks.VIN(value))}/>

                                        {
                                            !vehicleOrder ? null :
                                                <button 
                                                onKeyDown={(evt) => {
                                                    if (evt.keyCode == 9)
                                                        evt.stopPropagation();
                                                }}
                                                onClick={(evt) => decodeVin()}
                                                        className="btn btn-blue py-1 px-4 ml-4 mt-2 font-12">DECODE
                                                </button>
                                        }
                                    </div>

                                    <div className="col-12">
                                        <div className="row col-12">
                                            <FormGroup colSize={4}
                                                       name={'year'}
                                                       value={currentVehicleOrder.vehicle.year}
                                                       label={'Year'}
                                                       placeholder={'xxxx'}
                                                       required
                                                       onTextChange={value => handleOnChanged('year', Helper.Masks.IntNumbers(value, 4))}/>

                                            <FormGroup colSize={4}
                                                       type='combobox'
                                                       name={'make'}
                                                       required
                                                       value={currentVehicleOrder.vehicle.make}
                                                       label={'Make'}
                                                       placeholder={'make'}
                                                       options={{
                                                           data: makes,
                                                           value: x => x.Make_Name,
                                                           label: y => y.Make_Name
                                                       }}
                                                       onTextChange={value => handleOnChanged('make', value)}/>

                                            <FormGroup colSize={4}
                                                       name={'model'}
                                                       required
                                                       value={currentVehicleOrder.vehicle.model}
                                                       label={'Model'}
                                                       type='combobox'
                                                       options={{
                                                           data: models,
                                                           value: x => x.Model_Name,
                                                           label: y => y.Model_Name
                                                       }}
                                                       onBlur={(evt) => {
                                                           dispatch(resetModels());
                                                       }}
                                                       onFocus={(evt) => {
                                                           if (currentVehicleOrder.vehicle.make.trim() != '')
                                                               dispatch(getAllModelsMake(currentVehicleOrder.vehicle.make.trim()))

                                                       }}
                                                       placeholder={'model'}
                                                       onTextChange={value => handleOnChanged('model', value)}/>

                                            <FormGroup name={'submodel'}
                                                       colSize={4}
                                                       value={currentVehicleOrder.vehicle.submodel}
                                                       label={'Sub Model'}
                                                       placeholder={'sub model'}
                                                       onTextChange={value => handleOnChanged('submodel', value)}/>

                                            <FormGroup name={'type'}
                                                       colSize={4}
                                                       value={currentVehicleOrder.vehicle.type}
                                                       label={'Type'}
                                                       placeholder={'Type'}
                                                       onTextChange={value => handleOnChanged('type', value)}/>
                                            <FormGroup name={'color'}
                                                        colSize={4}
                                                        label={'Color'}
                                                        value={currentVehicleOrder.vehicle.color}
                                                        type='select'
                                                        placeholder={'-- Select a color --'}
                                                        options={{data: colors, value: x => x, label: x => x}}
                                                        onTextChange={(event) => handleOnChanged('color', event)}/>
                                        </div>

                                        <div className="row">


                                            <FormGroup colSize={6}
                                                       name={'license_plate'}
                                                       value={currentVehicleOrder.vehicle.license_plate}
                                                       label={'License Plate'}
                                                       placeholder={'License plate #'}
                                                       onTextChange={value => handleOnChanged('license_plate', value)}/>

                                            <FormGroup name={'internal_note'}
                                                       value={currentVehicleOrder.vehicle.internal_note}
                                                       label={'Vehicle Note'}
                                                       colSize={6}
                                                       placeholder={'Internal Only'}
                                                       onTextChange={value => handleOnChanged('internal_note', value)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${showInModal ? 'col-12' : 'col-12'} col-12`}>
                        <div className="row">
                            <FormGroup onTextChange={(evt) => setDriverNotes(evt)}
                                       value={driverNotes}
                                       textAreaHeight={'3rem'}
                                       placeholder='Driver notes...' type='textarea' label='Driver Notes'
                                       name='driver_notes'/>

                            <FormGroup onTextChange={(evt) => setBolComments(evt)}
                                       value={bolComments}
                                       textAreaHeight={'3rem'}
                                       placeholder='Bol notes...' type='textarea' label='BOL Comment'
                                       name='bol_comment'/>
                        </div>
                    </div>
                            </div>
                         

                        </div>
                        
                    </div>

                    
                    {masterID &&
                    <div className="col-12 text-right">
                        <button className="btn btn-clear rounded-pill px-3 py-2 mr-3"
                                onClick={() => onVehicleOrdersChanged(undefined)}>CANCEL
                        </button>

                             <button className="btn btn-blue-light rounded-pill px-3 py-2"
                                               onClick={() => handleOnUpdate()}>UPDATE VEHICLE
                            </button>

                    </div> }
                </div>
            }
        </>
    );
}

export default VehicleInformation;

import React, {useState, useRef, useEffect, useReducer} from 'react'
import {useToasts} from "react-toast-notifications";
import {AllStates} from "../../redux/reducers";
import {useDispatch, useSelector} from "react-redux";
import {IOrderReceivables} from "../../redux/reducers/OrderReceivables.reducer";
import Table from "../../components/Table/Table";
import IColumn from "../../components/Table/models/IColumn";
import ICONS from "../../../assets/svg";
import Searchbar from "../../components/Searchbar/Searchbar";
import getReceivables from '../../redux/actions/OrderReceivables.action';
import {IAgingState} from "../../redux/reducers/aging.reducer";
import Helper from "../../helper/Helper";
import {getReceivableAging} from "../../redux/actions/aging.action";
import CarriersDriversTable from '../../components/CarriersDriversTable/CarriersDriversTable';
import {IAPCarrier, IAPOpen} from '../../redux/reducers/AP.reducer';
import getPayables from '../../redux/actions/AP.action';
import {ICarrierState} from '../../redux/reducers/Carrier.reducer';
import ReactModal from 'react-modal';
import setCarrier from '../../redux/actions/Carrier.action';
import FormGroup from '../../components/FormGroup/FormGroup';
import DriversAgingTable from '../../components/DriversAgingTable/DriversAgingTable';
import moment from 'moment';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import {GraphqlService} from '../../services/graphql.service';
import gql from 'graphql-tag';
import useLoading from '../../hooks/useLoading/useLoading';
import * as htmlToImage from 'html-to-image';
import domtoimage from 'dom-to-image';


import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import * as pdfmake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

import numberToWords from 'number-to-words';
import PayeeForm from "../../components/PayeeForm/PayeeForm";
import PayeeMutations from "../../graphql/mutation/Payee";
import environments from '../../../environments/environments';
import Toggle from "../../components/Toggle/Toggle";

enum OrderStatuses {
    OPEN,
    AGING,
    OPEN_DRIVER,
    AGING_DRIVER,
    REQUESTS,
    PAYMENTS
}

enum OrderStatusesString {
    'OPEN',
    'CLOSED',
    'AGING',
    'PAYMENTS',
}

class Check {
    amount: string = '';
    payable_to: string = '';
    number: string = '';
    reference: string = '';
    memo: string = '';

    constructor(props?: Check) {
        if (props) {
            this.reference = props.reference;
            this.amount = props.amount;
            this.number = props.number;
            this.memo = props.memo;
            this.payable_to = props.payable_to;
        }
    }
}

class TransportationPayment {
    id: number = null;
    name: string = '';
    amount_to_charge: number = 0;

    constructor(props?: TransportationPayment) {
        if (props) {
            this.id = props.id;
            this.name = props.name;
            this.amount_to_charge = props.amount_to_charge;
        }
    }
}

class Payee {
    id: number = null;
    name: string = '';

    constructor(props?: Payee) {
        if (props) {
            this.id = props.id;
            this.name = props.name;
        }
    }
}

class PaymentInfo {
    id: number = null;
    type: string = '';
    veloxIDS: number[] = [];
    amount_to_pay: string = '';
    payment_left: string = '';
    notes: string = '';
    carrier_id: number = null;
    bank_account_id: number = null;
    reference: string = '';
    time_paid: Date = new Date();
    category: string = '';
    check: Check = new Check();
    opened: boolean = false;
    payment_to: string = '';
    payee: Payee = new Payee();
    is_request: boolean = false;
    request_id: number = null;

    constructor(props?: PaymentInfo) {
        if (props) {
            this.id = props.id;
            this.type = props.type;
            this.veloxIDS = [...props.veloxIDS];
            this.amount_to_pay = props.amount_to_pay;
            this.payment_left = props.payment_left;
            this.notes = props.notes;
            this.carrier_id = props.carrier_id;
            this.bank_account_id = props.bank_account_id;
            this.reference = props.reference;
            this.time_paid = new Date(props.time_paid);
            this.category = props.category;
            this.check = new Check(props.check);
            this.opened = props.opened;
            this.payment_to = props.payment_to;
            this.payee = new Payee(props.payee);
            this.is_request = props.is_request;
            this.request_id = props.request_id;
        }
    }


}

const PayablesPage = () => {


    const [payeeOpened, setPayeeOpened] = useState(false);
    const [newPayee, setNewPayee] = useState(false);
    const [ckEditorData, setCKEditorData] = useState(null);
    const loading = useLoading();
    // const carrier = useSelector<AllStates>(states => states.carriers) as ICarrierState;


    const [myCarrier, setMyCarrier] = useState<any>({id: environments.JP_CARRIER_ID, name: environments.JP_CARRIER_NAME})
    const [modalOpened, setModalOpened] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState(new PaymentInfo());
    const [bankAccounts, setBankAccounts] = useState([]);
    const [modalDriverOpened, setModalDriverOpened] = useState(false);
    const [driver, setDriver] = useState(null);
    const driverRef = useRef<any>();
    const [pickerOpen, setPickerOpen] = useState(false);
    const toast = useToasts();

    const [drivers, setDrivers] = useState<any[]>([]);
    const [employees, setEmployees] = useState<any[]>([]);
    const [others, setOthers] = useState<any[]>([]);
    const [carriers, setCarrirers] = useState<any[]>([]);


    const [carrierSearch, setCarrierSearch] = useState('');
    const [driverSearch, setDriverSearch] = useState('');
    const [employeeSearch, setEmployeeSearch] = useState('');
    const [otherSearch, setOtherSearch] = useState('');

    const [payableSearch, setPayableSearch] = useState('');
    const [checkSearch, setCheckSearch] = useState('');


    const [paidItems, setPaidItems] = useState([]);

    const [loadingPaidItems, setLoadingPaidItems] = useState(false);



    const carrierSRef = useRef();
    const driverSRef = useRef();
    const employeeSRef = useRef();
    const otherSRef = useRef();
    const payableSRef = useRef();
    const checkRef = useRef();

    const isValidCheck = () => {
        const {amount, memo, payable_to, number, reference} = paymentInfo.check;

        return +amount > 0 && (payable_to || '').trim() != '' && +number > 0;
    }

    const isValidPayee = () => {
        return paymentInfo.payment_to != '' && paymentInfo.payee.id != null;
    }


    const isValid = () => {

        return (paymentInfo.bank_account_id != null || paymentInfo.is_request || paymentInfo.type == 'CASH') && (paymentInfo.type.trim() != '' || paymentInfo.is_request) && paymentInfo.category != '' && paymentInfo.payment_to != '' && +paymentInfo.amount_to_pay > 0 && (paymentInfo.type == 'CHECK' ? isValidCheck() : true) && (isValidPayee() || !paymentInfo.opened);
    }



    async function loadDefaultValues(pInfo = paymentInfo) {

        let settingQuery = gql(`query {
            all_settings {
              key
              value
            }
          }`);

        //   alert(+null as any);


          try {

            loading.open('Getting default bank account...');
              const data = await GraphqlService.SendQuery(settingQuery);

              let p = {...pInfo};



              p.bank_account_id = (data.find(x => x.key == 'DEFAULT_CHECK_ACCOUNT')?.value);


              if (p.bank_account_id)
                p.bank_account_id = +p.bank_account_id;
              setPaymentInfo(p);


            //   alert('here');


            setCategories(JSON.parse(data.find(x => x.key == 'PAYMENT_CATEGORIES')?.value || '[]'))

            // loading.close();

            // loading.open('Getting last check number...');


            loading.close();

            console.log(p);
            getLastCheck(p.bank_account_id, p);

          } catch (ex) {
            loading.close();
            setPaymentInfo(pInfo);
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
          }
    }

    async function submitPayableRequest() {
        const mutation = gql`
            mutation($type: String, $reference: String, $note: String, $amount: Float, $category: String, $payable_to: String, $bank_account_id:Int, $time_due:String, $payee: payee_request_input){
                create_payable_request(type:$type, reference:$reference, note:$note, amount:$amount, category:$category, payable_to:$payable_to, bank_account_id:$bank_account_id, time_due:$time_due, payee:$payee){
                    id
                    message
                    success
                }
            }
        `;

        loading.open('Creating payable request...');

        const variables = {
            "time_due": paymentInfo.time_paid,
            "payee": {
              "id": paymentInfo.payee.id,
              "name": paymentInfo.payee.name,
              "order_ids": []
            },
            "reference": paymentInfo.reference,
            "bank_account_id": paymentInfo.bank_account_id,
            "note": paymentInfo.notes,
            "amount": +paymentInfo.amount_to_pay,
            "payable_to": paymentInfo.payment_to,
            "type": "",
            "category": paymentInfo.category
        }



        try {
            const data = await GraphqlService.SendMutation(mutation, variables);
            // //console.log(data);
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            setPaymentInfo(new PaymentInfo())
            load();
            loading.close();

        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'success', autoDismiss: true});
            loading.close();
        }
    }

    async function submitPayable() {


        // console.log(img);



        // return;

        try {
            const query =
            paymentInfo.opened ?
                (paymentInfo.payment_to == 'DRIVER' ? gql`
                mutation(
                    $check: check_input
                    $note: String
                    $category: String
                    $reference: String
                    $type: String
                    $amount: Float
                    $time_paid: String
                    $bank_account_id: Int
                    $payee_id: Int
                    $request_id: Int
                ) {
                    pay_driver_extra(
                        check: $check
                        note: $note
                        category: $category
                        reference: $reference
                        type: $type
                        amount: $amount
                        time_paid: $time_paid
                        bank_account_id: $bank_account_id
                        driver_id: $payee_id
                        payable_request_id: $request_id
                    ) {
                        id
                        message
                        success
                    }
                }

            ` : (paymentInfo.payment_to == 'EMPLOYEE' ? gql`
            
            mutation($check: check_input, $note: String, $category: String, $reference: String, $type: String, $amount: Float, $time_paid: String, $bank_account_id: Int, $payee_id: Int, $request_id: Int){
  pay_employee(check:$check, note:$note, category:$category, reference:$reference, type:$type, amount:$amount, time_paid:$time_paid, bank_account_id:$bank_account_id, employee_id:$payee_id, payable_request_id: $request_id){
    id
    message
    success
  }
}
            ` : gql`mutation(
                        $check: check_input
                        $note: String
                        $category: String
                        $reference: String
                        $type: String
                        $amount: Float
                        $time_paid: String
                        $bank_account_id: Int
                        $payee_id: Int
                        $request_id: Int
                        ) {
                        create_payable(
                            check: $check
                            note: $note
                            category: $category
                            reference: $reference
                            type: $type
                            amount: $amount
                            time_paid: $time_paid
                            bank_account_id: $bank_account_id
                            payee_id: $payee_id
                            payable_request_id: $request_id
                        ) {
                            id
                            message
                            success
                        }
                        }`))
                :
                (paymentInfo.category == 'DRIVER PAYMENT' ? gql`mutation($check: check_input, $note: String, $category: String, $reference: String, $type: String, $amount: Float, $time_paid: String, $bank_account_id: Int, $velox_ids: [Int]){
                    pay_driver(check:$check, note:$note, category:$category, reference:$reference, type:$type, amount:$amount, time_paid:$time_paid, bank_account_id:$bank_account_id, velox_ids:$velox_ids){
                        id
                        message
                        success
                    }
                }` : gql`
                    mutation($check: check_input, $note: String, $category: String, $reference: String, $type: String, $amount: Float, $time_paid: String, $bank_account_id: Int, $velox_ids: [Int]){
                        pay_carrier(check:$check, note:$note, category:$category, reference:$reference, type:$type, amount:$amount, time_paid:$time_paid, bank_account_id:$bank_account_id, velox_ids:$velox_ids){
                            id
                            message
                            success
                        }
                    }
                `);

            const variables: any = {
                note: paymentInfo.notes,
                reference: paymentInfo.reference,
                category: paymentInfo.category,
                type: paymentInfo.type,
                amount: +paymentInfo.amount_to_pay,
                time_paid: paymentInfo.time_paid,
                bank_account_id: paymentInfo.type == 'CASH' ? null : paymentInfo.bank_account_id,
                check: paymentInfo.type != 'CHECK' ? null : {
                    amount: +paymentInfo.check.amount,
                    memo: paymentInfo.check.memo,
                    number: +paymentInfo.check.number,
                    payable_to: paymentInfo.check.payable_to,
                    reference: paymentInfo.check.reference
                }
            }

            if (paymentInfo.opened) {
                variables.payee_id = +paymentInfo.payee.id;
                variables.request_id = paymentInfo.request_id;
            } else
                variables.velox_ids = paymentInfo.veloxIDS;

            console.log(variables);

            loading.open('Submitting payable...');
            const data = await GraphqlService.SendMutation(query, variables);
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            if (paymentInfo.type == 'CHECK') {

                const h1 = document.getElementById('rich-text-formatted');


                console.log(h1);


                const image = await htmlToImage.toPng(h1, {quality: 1});
                const total = +Helper.Masks.DecimalNumbers(paymentInfo.amount_to_pay);
                const doc = {
                    pageSize: 'LETTER',
                    pageMargins: [0, 0, 0, 0],


                    content: [

                        {
                            text: moment().format('MM/DD/YYYY'),
                            absolutePosition: {x: 502, y: 58},
                            fontSize: 12
                        },
                        {
                            text: paymentInfo.check.payable_to,
                            absolutePosition: {x: 76, y: 90}
                        },
                        {
                            text: `**${Helper.FORMAT.USCurrency(total).replace('$', '')}**`,
                            absolutePosition: {x: 500, y: 90.5},
                            fontSize: 14
                        },
                        {
                            text: `**${numberToWords.toWords(total).toUpperCase()} AND ${Helper.FORMAT.USCurrency(total).split('.')[1]}/100**`,
                            absolutePosition: {x: 30, y: 118},
                            fontSize: 10
                        },
                        {
                            text: paymentInfo.check.memo,
                            absolutePosition: {x: 54, y: 195},
                            fontSize: 10

                        },
                        {
                            image,
                            absolutePosition: {x: 22, y: 269},
                            width: 570

                        },
                        {
                            image,
                            absolutePosition: {x: 22, y: 518},
                            width: 570

                        }

                    ]

                } as any;

                (pdfmake as any).vfs = pdfFonts.pdfMake.vfs;

                pdfmake.createPdf(doc).print();
            }
            console.log({paymentInfo});

            loading.close();
            
            setPaymentInfo(new PaymentInfo());
            load();
            loading.close();
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});


        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    function submitPayment() {


    }

    const getColumns = (status): IColumn[] => {
        switch (status) {
            case OrderStatuses.OPEN:
                return [
                    {
                        label: 'ORDER #',
                        active: false,
                        orderBy: 'DESC',
                        name: 'velox_order_column'
                    },
                    {
                        label: 'VEHICLE',
                        active: false,
                        orderBy: 'DESC',
                        name: 'vehicle'
                    },
                    {
                        label: 'CARRIER',
                        active: false,
                        orderBy: 'DESC',
                        name: 'carrier'
                    },
                    {
                        label: 'TIME IN',
                        active: false,
                        orderBy: 'DESC',
                        name: 'time_in'
                    },

                    {
                        label: 'ORIGIN',
                        active: false,
                        orderBy: 'DESC',
                        name: 'origin'
                    },

                    {
                        label: 'DESTINATION',
                        active: false,
                        orderBy: 'DESC',
                        name: 'destination'
                    },


                    {
                        label: 'CARRIER PAY',
                        active: false,
                        orderBy: 'DESC',
                        name: 'carrier_pay',
                        sort_by: 'carrier_pay_amount',
                    },

                    {
                        label: 'REMAINING BALANCE',
                        active: false,
                        orderBy: 'DESC',
                        name: 'remainig_balance',
                        sort_by: 'remainig_balance_amount',
                    },

                    {
                        label: 'STATUS',
                        active: false,
                        orderBy: 'DESC',
                        name: 'status'
                    }];
            case OrderStatuses.OPEN_DRIVER:
                return [
                    {
                        label: 'ORDER #',
                        active: false,
                        orderBy: 'DESC',
                        name: 'velox_order_column',
                        sort_by: 'velox_order_id'
                    },
                    {
                        label: 'VEHICLE',
                        active: false,
                        orderBy: 'DESC',
                        name: 'vehicle'
                    },
                    {
                        label: 'DRIVER',
                        active: false,
                        orderBy: 'DESC',
                        name: 'driver'
                    },
                    {
                        label: 'TIME IN',
                        active: false,
                        orderBy: 'DESC',
                        name: 'time_in',
                        sort_by: 'time'
                    },

                    {
                        label: 'ORIGIN',
                        active: false,
                        orderBy: 'DESC',
                        name: 'origin'
                    },

                    {
                        label: 'DESTINATION',
                        active: false,
                        orderBy: 'DESC',
                        name: 'destination'
                    },


                    {
                        label: 'DRIVER PAY',
                        active: false,
                        orderBy: 'DESC',
                        name: 'driver_pay'
                    },

                    {
                        label: 'REMAINING BALANCE',
                        active: false,
                        orderBy: 'DESC',
                        name: 'remainig_balance',
                        sort_by: 'remainig_balance_amount',
                    },

                    {
                        label: 'STATUS',
                        active: false,
                        orderBy: 'DESC',
                        name: 'status'
                    }];
            case OrderStatuses.AGING:
                return [
                    {label: 'CARRIER NAME', active: false, orderBy: 'DESC', name: 'name'},
                    { label: 'CURRENT', active: false, orderBy: 'DESC', name: 'current_$', sort_by: 'current' },
                    { label: '31 - 60', active: false, orderBy: 'DESC', name: 'plus30_$', sort_by: 'plus30' },
                    { label: '61 - 90', active: false, orderBy: 'DESC', name: 'plus60_$', sort_by: 'plus60' },
                    { label: 'OVER 90', active: false, orderBy: 'DESC', name: 'plus90_$', sort_by: 'plus90' },
                    { label: 'TOTAL', active: false, orderBy: 'DESC', name: 'total_$', sort_by: 'total' },
                    {label: 'PAYMENT TERMS', active: false, orderBy: 'DESC', name: 'payment_terms'},

                    {label: 'DELIVERED', active: false, orderBy: 'DESC', name: 'delivered'}
                ];

            case OrderStatuses.AGING_DRIVER:
                return [
                    {label: 'DRIVER NAME', active: false, orderBy: 'DESC', name: 'name'},
                    {label: 'CURRENT', active: false, orderBy: 'DESC', name: 'current_$', sort_by: 'current'},
                    {label: 'LAST PAYABLE', active: false, orderBy: 'DESC', name: 'last_payable'},
                    {label: 'TOTAL', active: false, orderBy: 'DESC', name: 'total_$', sort_by: 'total'},
                    {label: 'DELIVERED', active: false, orderBy: 'DESC', name: 'delivered'},

                    {label: 'VALID', active: false, orderBy: 'DESC', name: '_valid'}
                ];

            case OrderStatuses.PAYMENTS:
                return [
                    {label: 'PAYABLE #', active: false, orderBy: 'DESC', name: 'p_id'},
                    {label: 'NAME', active: false, orderBy: 'DESC', name: 'name'},
                    {label: 'CATEGORY', active: false, orderBy: 'DESC', name: 'category'},
                    {label: 'AMOUNT', active: false, orderBy: 'DESC', name: '_amount'}
                ];
        }
        return [];
    }

    const [searchTerm, setSearchTerm] = useState('');

    const orderReceivableOpen = useSelector<AllStates>(x => x.orderReceivables.open) as IOrderReceivables;
    const orderReceivableClosed = useSelector<AllStates>(x => x.orderReceivables.closed) as IOrderReceivables;
    const orderReceivableAging = useSelector<AllStates>(x => x.agings) as IAgingState;
    const orderReceivablePayments = useSelector<AllStates>(x => x.orderReceivables.payments) as IOrderReceivables;

    const apAging = useSelector<AllStates>(x => x.AP.aging) as { data: IAPCarrier[], error: string, loading: boolean };
    const apOpen = useSelector<AllStates>(x => x.AP.open) as { data: IAPOpen[], error: string, loading: boolean };

    const apOpenDriver = useSelector<AllStates>(x => x.AP.open_driver) as { data: IAPOpen[], error: string, loading: boolean };


    const apAgignDriver = useSelector<AllStates>(x => x.AP.aging_driver) as { data: IAPCarrier[], error: string, loading: boolean };

    const requests = useSelector<AllStates>(x => x.AP.requests) as { data: any[], error: string, loading: boolean };


    const [orderStatus, setOrderStatus] = useState(OrderStatuses.OPEN);

    const getRequests = () => requests.data.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase()));
    const carrierRef = useRef<any>();
    const dispatch = useDispatch();

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        // if (carrier)
            carrierRef.current = myCarrier;
    }, [myCarrier]);

    useEffect(() => {
        if (driver)
            load();
    }, [driver]);

    const load = () => {
        // dispatch(getReceivableAging());
        // dispatch(getReceivables());
        dispatch(getPayables({
            aging: {showempty: false},
            open: {carrier_id: carrierRef.current?.id},
            open_driver: driver ? {driver_id: driver.id, showClosed: false} : null,
            aging_driver: {showempty: false}
        }));
    }


    async function handlePaymentToChanged(text) {
        setPaymentInfo({...paymentInfo, payment_to: text, payee: new Payee()});
        try {
            loading.open('GETTING DATA...');
            if (text == 'DRIVER')
                await loadDrivers();
            else if (text == 'EMPLOYEE')
                await loadEmployees();
            else if (text == 'OTHER')
                await loadOthers();
            loading.close();
        } catch (ex) {
            alert('ex: ' + ex.message);
            loading.close();
        }
    }


    async function loadDrivers() {
        const query = gql`
            query($showempty:Boolean){
                get_aging_drivers_for_carrier_payables(showempty:$showempty){
                    name
                    total
                    valid
                    id
                    last_payable{
                        id
                        time
                        type
                        reference
                        amount
                    }
                    delivered
                    driver_id
                }
            }
        `;
        const data = await GraphqlService.SendQuery(query, {showempty: true});
        // alert('good');
        setDrivers(data.map(e => ({...e,
            last_payable: e.last_payable ? [`P-${e.last_payable?.id}`, Helper.FORMAT.USCurrency(e.last_payable.amount), Helper.FORMAT.USDate(e.last_payable.time_paid, 'do')] : '-',
            last_payable_id: e.last_payable?.id || 0,
            _valid: e.valid ? 'Yes' : 'No',
            exists: e.id != null ? 'Yes' : 'No',
            jp_driver_id: e.id,
            total_$: [Helper.FORMAT.USCurrency(e.total)],
            total: e.total,
            id: e.driver_id,
            name: e.name,
            // moves: [e.not_picked_up + ' not picked', e.not_delivered + ' undelivered', e.non_move + ' non move']
        })));
        // setDrivers(data)


    }

    async function loadEmployees() {
        const query = gql`
            query{
                get_all_employees{
                    id
                    name
                    address{
                        city
                        state
                        street
                        country
                        postal_code
                        street_number
                        postal_code_suffix
                    }
                    last_payable {
                        id
                        amount
                        time
                        type
                        time_paid
                        category
                    }
                    department
                    note
                }
            }
        `;
        const data = await GraphqlService.SendQuery(query);
        setEmployees(data);
    }

    async function loadOthers() {
        const query = gql`
            query{
                get_all_payees{
                    id
                    name
                    address{
                        city
                        state
                        street
                        country
                        postal_code
                        street_number
                        postal_code_suffix
                    }
                }
            }
        `;
        const data = await GraphqlService.SendQuery(query);
        // //console.log(data);
        setOthers(data);
    }

    async function loadCarriers() {
        const query = gql`
            query($showempty:Boolean){
                get_aging_carriers_for_broker_payment(showempty: $showempty){
                    name
                    carrier_id
                    drivers_dispatched
                    total
                    over30
                    over60
                    over90
                    current
                    delivered
                    payment_terms
                    valid
                }
            }
        `;
        const data = await GraphqlService.SendQuery(query, {showempty: true});
        setCarrirers(data.map(x => ({id: x.carrier_id, name: x.name})));


    }


    const getPayee = () => {
        switch (paymentInfo.payment_to) {
            case 'DRIVER':
                return drivers;
            case 'CARRIER':
                return carriers;
            default:
                return [];
        }

    }


    async function getLastCheck(bank_account_id, pInfo = paymentInfo) {

        let temp = {...pInfo};

        temp.bank_account_id = bank_account_id;
        try {
            const query = gql`
                query($bank_account_id:Int){
                    get_biggest_check(bank_account_id:$bank_account_id){
                        amount
                        payable_to
                        number
                        reference
                        memo
                        time
                        time_created
                        bank_account{
                            id
                            name
                            note
                            bank
                        }
                    }
                }
            `;
            loading.open('Getting last check number...');
            const data = await GraphqlService.SendQuery(query, {bank_account_id});
            // console.log(data, {bank_account_id});
            temp.check.number = ((data?.number || 0) + 1).toString();
            
            loading.close();
            console.log(temp);
            console.log(temp);
            setPaymentInfo(temp);
        } catch (ex) {
            temp.check.number = "1";
            setPaymentInfo(temp);
            
            loading.close();
            console.log(ex.message);
        }
    }

    async function loadBankAccounts() {
        try {
            setBankAccounts([]);

            const query = gql`
                query{
                    get_bank_accounts{
                        id
                        name
                        bank
                        note
                        last_4
                        active
                    }
                }
            `;
            const data = await GraphqlService.SendQuery(query);
            setBankAccounts([...data]);
        } catch (ex) {
            console.log('ex.message', ex.message);
        }
    }

    const [categories, setCategories] = useState([]);

    const ordersFiltered = () => {
        return []
    }

    const getOpens = () => {
        if (!apOpen) return [];

        return apOpen.data.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase()));

    }

    const getPaidItems = () => paidItems.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim()));

    const getOpensDriver = (modal = false) => {
        if (!apOpenDriver) return [];


        let result = (modal ? drivers : apOpenDriver
            .data)
            .filter(e => {
                return JSON.stringify(e).toLowerCase().includes(searchTerm.toLowerCase())
            });

        return result;

    }

    const getAgings = () => {
        if (!apAging) return [];
        if (!apAging.data) return [];

        return apAging
            .data
            .filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim()));
    }

    const getAgingsDriver = (other = false) => {
        if (!apAgignDriver) return [];
        if (!apAgignDriver.data) return [];

        return (other ? drivers : apAgignDriver
            .data)
            .filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase()));
    }

    // const PayableForm = () => <>






    // </>

    const getCount = (key = '') => {
        try {
            const keys = {
                'OPEN': apOpen,
                'OPEN_DRIVER': apOpenDriver,
                'AGING': apAging,
                'AGING_DRIVER': apAgignDriver,
                'CLOSED': orderReceivableClosed,
                'PAYMENTS': {data: paidItems},
                'REQUESTS': requests,
            }
            return keys[key].data?.length;
        } catch (ex) {
            return 0;
        }
    }
    const statusesOptions = [
        {
            label: 'Carrier Open Invoice ',
            key: 'OPEN',
            value: OrderStatuses.OPEN
        },
        {
            label: 'Carrier Aging',
            key: 'AGING',
            value: OrderStatuses.AGING
        },
        {
            label: 'JP Driver Payable',
            key: 'OPEN_DRIVER',
            value: OrderStatuses.OPEN_DRIVER
        },
        {
            label: 'Driver Aging',
            key: 'AGING_DRIVER',
            value: OrderStatuses.AGING_DRIVER
        },
        {
            label: 'Other Payables ',
            key: 'REQUESTS',
            value: OrderStatuses.REQUESTS
        },
        {
            label: 'Paid Items',
            key: 'PAYMENTS',
            value: OrderStatuses.PAYMENTS
        }
    ]

    const [emails, setEmails] = useState('');

    useEffect(() => {
        load();
    }, [myCarrier]);



    async function searchPayables() {


        if (checkSearch.trim() == '' &&
            otherSearch.trim() == '' &&
            employeeSearch.trim() == '' &&
            driverSearch.trim() == '' &&
            payableSearch.trim() == '' &&
            carrierSearch.trim() == '')
                return setPaidItems([]);
        const query = gql`
                query(
  $check_search: String
  $payee_search: String
  $employee_search: String
  $driver_search: String
  $the_search: String
  $carrier_search: String
) {
  all_paid_items(
    check_search: $check_search
    payee_search: $payee_search
    employee_search: $employee_search
    driver_search: $driver_search
    the_search: $the_search
    carrier_search: $carrier_search
  ) {
    id
    time
    type
    reference
    note
    amount
    category
    time_paid
    checks {
      amount
      payable_to
      number
      reference
      memo
      time
      time_created
      bank_account {
        id
        name
      }
    }
    carrier {
      id
      name
    }
    driver {
      id
      name
      phones
    }
    employee {
      id
      name
    }
    payee {
      id
      name
    }
  }
}
        
        `;


try {
    // console.log({
    //     check_search: checkSearch,
    //     payee_search: otherSearch,
    //     employee_search: employeeSearch,
    //     driver_search: driverSearch,
    //     the_search: payableSearch,
    //     carrier_search: carrierSearch
    // });
    setLoadingPaidItems(true);
    const data = await GraphqlService.SendQuery(query, {
        check_search: checkSearch,
        payee_search: otherSearch,
        employee_search: employeeSearch,
        driver_search: driverSearch,
        the_search: payableSearch,
        carrier_search: carrierSearch
    });
    //console.log(data);
    setLoadingPaidItems(false);
    setPaidItems(data.map(x => ({...x, name: x.employee?.name || x.payee?.name || x.driver?.name || x.carrier?.name || '-',  p_id: `P-${x.id}`, _amount: Helper.FORMAT.USCurrency(x.amount)})));
} catch (ex) {
    console.log('ex', ex.message);
    setLoadingPaidItems(false);
}
    }


    const ref = useRef();

    async function handleSubmitPayee(payee) {
        try {
            if (!payee.id) {
                delete payee.id
            }

            loading.open('Submitting payee...');

            const data = await GraphqlService.SendMutation(PayeeMutations.ADD_NEW_PAYEE, {
                name: payee.name,
                tax_info: payee.tax_info,
                address: payee.address,
            });

            loading.close();

            if (!data.success)
                throw new Error(data.message);

            setNewPayee(false);
            setPayeeOpened(false);

            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});

            setPaymentInfo({...paymentInfo, check: {...paymentInfo.check, payable_to: payee.name},  ...{payee: new Payee({id: data.id, name: payee.name})}});

        } catch (ex) {
            // setNewPayee(false);
            toast.addToast(ex.message, {appearance: 'error'});
        }
    }

    const paymentsHeader = () => {
        return <>
                <div className="row">
                    <Searchbar value={carrierSearch}
                               placeholder={'Carrier...'}
                               className={'col-3 mb-2'}
                               onChange={(evt) => setCarrierSearch(evt.target.value)}
                               onKeyEnter={(evt) => searchPayables()}
                               background={'#F8F8F8'}
                               reference={carrierSRef}/>

                    <Searchbar value={driverSearch}
                               className={'col-3 mb-2'}
                               placeholder={'Driver...'}
                               onChange={(evt) => setDriverSearch(evt.target.value)}
                               onKeyEnter={(evt) => searchPayables()}
                               background={'#F8F8F8'}
                               reference={driverSRef}/>

                    <Searchbar value={employeeSearch}
                               className={'col-3 mb-2'}
                               placeholder={'Employee...'}
                               onKeyEnter={(evt) => searchPayables()}
                               onChange={(evt) => setEmployeeSearch(evt.target.value)}
                               background={'#F8F8F8'}
                               reference={employeeSRef}/>


                    <Searchbar value={otherSearch}
                               className={'col-3 mb-2'}
                               onChange={(evt) => setOtherSearch(evt.target.value)}
                               onKeyEnter={(evt) => searchPayables()}
                               placeholder={'Other...'}
                               background={'#F8F8F8'}
                               reference={otherSRef}/>

                    <Searchbar value={payableSearch}
                               className={'col-3'}
                               onKeyEnter={(evt) => searchPayables()}
                               onChange={(evt) => setPayableSearch(evt.target.value)}
                               placeholder={'Payable...'}
                               background={'#F8F8F8'}
                               reference={payableSRef}/>

                    <Searchbar value={checkSearch}

                               className={'col-3'}
                               onKeyEnter={(evt) => searchPayables()}
                               onChange={(evt) => setCheckSearch(Helper.Masks.IntNumbers(evt.target.value))}
                               placeholder={'Check number...'}
                               background={'#F8F8F8'}
                               reference={checkRef}/>

                    <Searchbar value={searchTerm}
                               className={'col-3'}
                               onChange={(evt) => setSearchTerm(evt.target.value)}
                               background={'#F8F8F8'}
                               reference={ref}/>

                    {<div className="col-3 d-flex justify-content-start">
                        <button className='btn btn-icon-only icon-big'
                                onClick={() => load()}><img
                            className={(apOpen.loading || apAging.loading || apAgignDriver.loading || apOpenDriver.loading || loadingPaidItems || requests.loading) ? 'spin' : ''}
                            style={{transform: ''}} src={ICONS.IconRefresh}
                            alt=""/></button>
                    </div>}
                </div>

            </>
    }



    const getHeaderText = () => {
        const label = statusesOptions[orderStatus]?.label;
        // console.log('ORDER STATUS', orderStatus, statusesOptions[orderStatus]);

        const key = statusesOptions[orderStatus]?.key;
        const length = getCount(key);

        const length1 =
            orderStatus == OrderStatuses.OPEN ? getOpens().length
                : orderStatus == OrderStatuses.AGING ? getAgings().length
                : orderStatus == OrderStatuses.OPEN_DRIVER ? getOpensDriver().length
                : orderStatus == OrderStatuses.REQUESTS ? getRequests().length
                : orderStatus == OrderStatuses.AGING_DRIVER ? getAgingsDriver().length
                : getPaidItems().length;
            // console.log('ORDER STATU
        return `${length1} / ${length} ${label}` ;
    }

    return (
        <>
           <div className="flex-1-container">
           <div className='flex-1-container '>
                <div className="d-flex col-12 mb-3">
                    {
                        statusesOptions.map((opt, index) =>
                                <h4 key={'key-' + index} onClick={(evt) => setOrderStatus(opt.value)} className={"font-10 transition-3-all-ease cursor-pointer mr-3 " + (orderStatus == opt.value ? 'text-black' : 'text-gray')}>{opt.label} ({getCount(opt.key)})</h4>
                            // <div className={'mr-3'} key={'key-' + index}>
                            //     <p onClick={(evt) => setOrderStatus(opt.value)}
                            //        className={`fs-12 cursor-pointer mb-1 font-medium${opt.value != orderStatus ? ' text-gray' : ''}`}>{opt.label} ({getCount(opt.key)})</p>
                            //     <div style={{
                            //         width: '24px',
                            //         height: '2px',
                            //         background: orderStatus == opt.value ? '#42ACDE' : 'transparent'
                            //     }}/>
                            // </div>
                        )
                    }
                </div>
                <div className="col-12 bg-white flex-1-container p-3 bg-white rounded">
                    <div className='row col-12 align-items-start'>
                        <div className="col-6 d-flex align-items-center">
                            <h4 className="fs-12">{getHeaderText()}</h4>

                            <button className="btn btn-blue-outline ml-2" onClick={(evt) => {
                                const p = new PaymentInfo();
                                p.opened = true;
                                p.is_request = orderStatus == OrderStatuses.REQUESTS;
                                loadBankAccounts();
                                if (!p.is_request)
                                {
                                    p.type = 'CHECK';
                                    loadDefaultValues(p);
                                }
                                else
                                    setPaymentInfo(p);
                            }}>{orderStatus == OrderStatuses.REQUESTS ? 'New request' : 'New payable'} <i
                                className="fas fa-plus ml-1"></i></button>
                            {/* <kbd>test</kbd> */}
                            {/* <abbr title="Testing mode">TM</abbr> */}
                            {/* <div className="col-12"></div> */}
                            {/* <button className="btn btn-outline mx-2">THIS WEEK <i className="fas fa-chevron-down"></i></button> */}

                        </div>
                        <div className={`d-flex align-items-center justify-content-end col-6`}>

                            {orderStatus != OrderStatuses.PAYMENTS && <>
                                <button className='btn btn-icon-only mr-2'
                                        onClick={() => load()}><img
                                    className={(apOpen.loading || apAging.loading || apAgignDriver.loading || apOpenDriver.loading || loadingPaidItems || requests.loading) ? 'spin' : ''} style={{transform: ''}}
                                    src={ICONS.IconRefresh}
                                    alt=""/></button>
                            <Searchbar value={searchTerm}
                                       onChange={(evt) => setSearchTerm(evt.target.value)}
                                       background={'#F8F8F8'}
                                       reference={ref}/> </>}


                            {orderStatus == OrderStatuses.PAYMENTS && paymentsHeader()}
                            {/*{orderStatus == OrderStatuses.PAYMENTS && }*/}
                        </div>


                        <div className="col-12 d-flex my-2 justify-content-end align-items-center">
                            {/*{JSON.stringify(statusesOptions)}*/}
                            {/*<div className="col-6">*/}




                            {orderStatus == OrderStatuses.OPEN &&
                            <>
                            <img onClick={(evt) => setMyCarrier(null)} className={'btn-icon cursor-pointer delete mr-2'} src={ICONS.IconTrash}
                                     alt=""/>
                            <button style={{minWidth: 'max-content'}} onClick={(evt) => setModalOpened(true)}
                                    className="btn btn-orange d-flex align-items-center">{myCarrier?.name} <i
                                className="ml-1 fas fa-chevron-down"></i></button></>}
                            {orderStatus == OrderStatuses.OPEN_DRIVER &&
                            <button style={{minWidth: 'max-content'}} onClick={(evt) => setModalDriverOpened(true)}
                                    className="btn btn-orange d-flex align-items-center">{driver?.name} <i
                                className="ml-1 fas fa-chevron-down"></i></button>}

                        </div>


                    </div>

                    <div className={'col-12 flex-1-container'}>
                        {
                            orderStatus == OrderStatuses.OPEN &&
                            <Table rowHeight={80}

                                   showCheckbox={true}
                                   loading={apOpen.loading} tableHeight={'81vh'}
                                   menuOptions={[

                                       {
                                           icon: ICONS.IconProcessPayment,
                                           label: 'Pay to carrier',
                                           condition: x => true,
                                           multipleRows: true,
                                           action: (x: any | any[]) => {
                                               let veloxIDS = [];
                                               let total = '';
                                               console.log('here');
                                               if (Array.isArray(x)) {
                                                   veloxIDS = x.map(y => ({
                                                       id: y.row.velox_order_id,
                                                       amount: y.row.remainig_balance_amount
                                                   }));
                                                   total = x.sumBy(y => y.row.remainig_balance_amount).toString();
                                               } else {
                                                   veloxIDS = [{
                                                       id: +x.velox_order_id,
                                                       amount: +x.remainig_balance_amount
                                                   }];
                                                   total = x.remainig_balance_amount.toString();
                                               }



                                               let p = new PaymentInfo();
                                               p.veloxIDS = veloxIDS.map(x => x.id);
                                               p.payment_left = total;
                                               p.amount_to_pay = (+total).toString();
                                               p.check.amount = (+total).toString();
                                               p.category = 'CARRIER PAYMENT';
                                               p.payment_to = 'CARRIER';
                                               p.check.payable_to = carrierRef.current?.name;
                                               p.type = 'CHECK';

                                               function randomNumber(min, max) {
                                                   return +(Math.random() * (max - min) + min).toFixed(2);
                                               }


                                               const totalAmount = veloxIDS.sumBy(x => x.amount);


                                               const MAX_ROWS = 15;

                                               if (veloxIDS.length % MAX_ROWS == 0)
                                                   veloxIDS.push(null);

                                               const COLUMNS_COUNT = Math.ceil(veloxIDS.length / MAX_ROWS);


                                               console.log('COLUMNS COUNT', COLUMNS_COUNT);

                                               const headers = '<tr>' + [...Array(COLUMNS_COUNT).keys()].map(x => `
                                                <td><span class="ck-table-bogus-paragraph"><strong>ORDER #</strong></span></td>
                                                <td><span class="ck-table-bogus-paragraph"><strong>AMOUNT</strong></span></td>`).join(' ') + '</tr>'

                                               const body = [...Array(MAX_ROWS).keys()].map((_, i) => '<tr>' + [...Array(COLUMNS_COUNT).keys()].map((x, j) => `<td><span class="ck-table-bogus-paragraph">${i == MAX_ROWS - 1 && j == COLUMNS_COUNT - 1 ? '<strong>Total</strong>' : veloxIDS[i + j * MAX_ROWS] ? `VLX-${veloxIDS[i + j * MAX_ROWS].id}` : ''}</span></td>
                                                <td><span class="ck-table-bogus-paragraph">${i == MAX_ROWS - 1 && j == COLUMNS_COUNT - 1 ? `<strong>${Helper.FORMAT.USCurrency(totalAmount)}</strong>` : veloxIDS[i + j * MAX_ROWS] ? Helper.FORMAT.USCurrency(veloxIDS[i + j * MAX_ROWS].amount) : ''}</span></td>`).join(' ') + '</tr>').join('\n');


                                               p.check.reference = `<figure class='table' style='font-size: 12.5px;'>
                                                <table>
                                                <tbody>
                                                    ${headers}
                                                    ${body}
                                                </tbody>
                                                </table>
                                                
                                                </figure>`;

                                               loadBankAccounts();
                                               loadDefaultValues(p);
                                               //    setPaymentInfo(p);
                                           }
                                       }
                                   ]}
                                   onRowClicked={(event) => {
                                   }} columns={getColumns(OrderStatuses.OPEN)}
                                   rows={getOpens()}/>
                        }
                        {
                            orderStatus == OrderStatuses.AGING &&
                            <Table rowHeight={60} loading={apAging.loading} tableHeight={'81vh'}
                                    menuOptions={[
                                        {
                                            label: 'See Orders',
                                            icon: ICONS.IconDetails,
                                            action: (x: any) => {
                                                console.log(x);
                                                setMyCarrier({id: x.carrier_id, name: x.name})
                                                setOrderStatus(OrderStatuses.OPEN);
                                            }
                                        }
                                    ]}
                                   onRowClicked={(event) => {
                                   }} columns={getColumns(OrderStatuses.AGING)}
                                   rows={getAgings()}/>
                        }
                        {
                            orderStatus == OrderStatuses.PAYMENTS &&
                            <Table rowHeight={50} loading={loadingPaidItems} tableHeight={'81vh'}
                                   onRowClicked={(event) => {
                                   }} columns={getColumns(OrderStatuses.PAYMENTS)}
                                   rows={getPaidItems()}/>
                        }
                        {

// id
// time
// time_due
// type
// reference
// note
// amount
// category
// payable_to
// payee{
//   id
//   name
//   order_ids
// }
                            orderStatus == OrderStatuses.REQUESTS &&
                            <Table rowHeight={54} loading={requests.loading} tableHeight={'81vh'}
                                   menuOptions={[
                                       {
                                           icon: ICONS.IconCheck,
                                           label: 'Create payable',
                                           action: (x: any) => {
                                               // console.log(x);
                                               if (x.payable_id)
                                                   return toast.addToast(`This request #${x.id} has been already approved`, {appearance: 'warning', autoDismiss: true})
                                               console.log(x);
                                               loadBankAccounts();
                                               const p = new PaymentInfo();

                                               p.amount_to_pay =  x.amount.toString();
                                               p.category = x.category;
                                               p.payee = new Payee(x.payee);
                                               p.payment_to = x.payable_to;
                                               p.bank_account_id = x.bank_account_id;
                                               p.time_paid = new Date(x.time_due);
                                               p.notes = x.notes;
                                               p.request_id = x.id;
                                               p.reference = x.reference;
                                               p.opened = true;
                                               p.check.amount = x.amount.toString();
                                               p.check.payable_to = p.payee.name;
                                               p.type = 'CHECK';
                                               // setPaymentInfo(p);
                                               loadDefaultValues(p).then()
                                           }
                                       }
                                   ]}
                                   onRowClicked={(event) => {
                                   }} columns={[
                                {
                                    label: 'REQUEST #',
                                    orderBy: 'ASC',
                                    active: false,
                                    name: 'r_id'
                                },
                                {
                                    label: 'NAME',
                                    orderBy: 'ASC',
                                    active: false,
                                    name: 'name'
                                },
                                {
                                    label: 'TIME',
                                    orderBy: 'ASC',
                                    active: false,
                                    name: 'time'
                                },
                                {
                                    label: 'TIME DUE',
                                    orderBy: 'ASC',
                                    active: false,
                                    name: 'time_due'
                                },
                                {
                                    label: 'PAYABLE TO',
                                    orderBy: 'ASC',
                                    active: false,
                                    name: 'payable_to'
                                },
                                {
                                    label: 'CATEGORY',
                                    orderBy: 'ASC',
                                    active: false,
                                    name: 'category'
                                },
                                {
                                    label: 'PAYABLE #',
                                    orderBy: 'ASC',
                                    active: false,
                                    name: 'payable'
                                }
                            ]}
                                   rows={getRequests()}/>
                        }

                        {
                            orderStatus == OrderStatuses.OPEN_DRIVER &&
                            <Table
                                showCheckbox
                                rowHeight={80} loading={apOpenDriver.loading} tableHeight={'81vh'}
                                menuOptions={[

                                    {
                                        icon: ICONS.IconProcessPayment,
                                        label: 'Pay to driver',
                                        condition: x => true,
                                        multipleRows: true,
                                        action: (x: any | any[]) => {
                                            let veloxIDS = [];
                                            let total = '';
                                            console.log('here');
                                            if (Array.isArray(x)) {
                                                veloxIDS = x.map(y => ({
                                                    id: +(y.row.velox_order_id),
                                                    amount: +(y.row.remainig_balance_amount)
                                                }));
                                                total = x.sumBy(y => +Helper.Masks.DecimalNumbers(y.row.remainig_balance)).toString();
                                            } else {
                                                veloxIDS = [{
                                                    id: +(x.velox_order_id),
                                                    amount: +(x.remainig_balance_amount)
                                                }];
                                                total = x.remainig_balance_amount.toString();
                                            }

                                            //  alert(JSON.stringify(driver));

                                            let p = new PaymentInfo();
                                            p.veloxIDS = veloxIDS.map(x => x.id);
                                            p.payment_left = total;
                                            p.amount_to_pay = (+total).toString();
                                            p.check.amount = (+total).toString();
                                            p.category = 'DRIVER PAYMENT';
                                            p.check.payable_to = driverRef.current.name;
                                            p.payment_to = 'DRIVER';
                                            p.type = 'CHECK';
                                            const totalAmount = veloxIDS.sumBy(x => x.amount);


                                            const MAX_ROWS = 15;

                                            if (veloxIDS.length % MAX_ROWS == 0)
                                                veloxIDS.push(null);

                                            const COLUMNS_COUNT = Math.ceil(veloxIDS.length / MAX_ROWS);


                                            console.log('COLUMNS COUNT', COLUMNS_COUNT);

                                            const headers = '<tr>' + [...Array(COLUMNS_COUNT).keys()].map(x => `
                                                <td><span class="ck-table-bogus-paragraph"><strong>ORDER #</strong></span></td>
                                                <td><span class="ck-table-bogus-paragraph"><strong>AMOUNT</strong></span></td>`).join(' ') + '</tr>'

                                            const body = [...Array(MAX_ROWS).keys()].map((_, i) => '<tr>' + [...Array(COLUMNS_COUNT).keys()].map((x, j) => `<td><span class="ck-table-bogus-paragraph">${i == MAX_ROWS - 1 && j == COLUMNS_COUNT - 1 ? '<strong>Total</strong>' : veloxIDS[i + j * MAX_ROWS] ? `VLX-${veloxIDS[i + j * MAX_ROWS].id}` : ''}</span></td>
                                                <td><span class="ck-table-bogus-paragraph">${i == MAX_ROWS - 1 && j == COLUMNS_COUNT - 1 ? `<strong>${Helper.FORMAT.USCurrency(totalAmount)}</strong>` : veloxIDS[i + j * MAX_ROWS] ? Helper.FORMAT.USCurrency(veloxIDS[i + j * MAX_ROWS].amount) : ''}</span></td>`).join(' ') + '</tr>').join('\n');


                                            p.check.reference = `<figure class='table' style='font-size: 12.5px;'>
                                                <table>
                                                <tbody>
                                                    ${headers}
                                                    ${body}
                                                </tbody>
                                                </table>
                                                
                                                </figure>`;

                                            loadBankAccounts();
                                            // setPaymentInfo(p);
                                            loadDefaultValues(p);

                                        }
                                    }
                                ]}
                                onRowClicked={(event) => {
                                }} columns={getColumns(OrderStatuses.OPEN_DRIVER)} rows={getOpensDriver()}/>
                        }

                        {
                            orderStatus == OrderStatuses.AGING_DRIVER &&
                            <Table rowHeight={64} loading={apAgignDriver.loading} tableHeight={'81vh'}
                            menuOptions={[
                                {
                                    label: 'See Orders',
                                    icon: ICONS.IconDetails,
                                    action: (x: any) => {
                                        console.log(x);
                                        setDriver({id: x.driver_id, name: x.name});
                                        driverRef.current = {id: x.driver_id, name: x.name};
                                        setOrderStatus(OrderStatuses.OPEN_DRIVER);
                                    }
                                }
                            ]}
                                   onRowClicked={(event) => {
                                   }} columns={getColumns(OrderStatuses.AGING_DRIVER)} rows={getAgingsDriver()}/>
                        }
                    </div>
                </div>

            </div>
           </div>

            <ReactModal className={''} isOpen={modalOpened} onRequestClose={evt => setModalOpened(false)}>
                <CarriersDriversTable onCarrierSelected={carrier => {

                    // dispatch(setCarrier(carrier));
                    // carrierRef.current = carrier;
                    setMyCarrier(carrier);

                    setModalOpened(false);
                }}/>
            </ReactModal>

            <ReactModal isOpen={modalDriverOpened} onRequestClose={evt => setModalDriverOpened(false)}>
                <DriversAgingTable onDriverSelected={d => {

                    // console.log(d);
                    setDriver({
                        id: d.id,
                        name: d.name
                    });
                    driverRef.current = {
                        id: d.id,
                        name: d.name
                    }

                    // setTimeout(() => {

                    // }, 100);
                    // dispatch(setCarrier(carrier));

                    setModalDriverOpened(false);
                }}/>
            </ReactModal>

            <ReactModal  className={""}  isOpen={paymentInfo.veloxIDS.length != 0 || paymentInfo.opened} >

                <div className='d-flex flex-column h-100'>

                    <div className="row mb-3">
                        {!paymentInfo.opened &&
                        <h4 className='font-12'>Pay for orders: VLX-{paymentInfo.veloxIDS[0]}{paymentInfo.veloxIDS.length > 2 && <span>, +{paymentInfo.veloxIDS.length - 1} more</span>}</h4>}
                        {paymentInfo.opened && <h4>New payable {paymentInfo.is_request ? 'request' : ''}</h4>}
                        <h4 onClick={(evt) => setPaymentInfo(new PaymentInfo())}
                                className="font-12 cursor-pointer"><i className='fas fa-times'></i></h4>
                    </div>



                    <div style={{flex: 1, overflowY: 'auto'}}>
                        {/* <PayableForm /> */}
                        <div className='row'>


                        <FormGroup
                            value={paymentInfo.opened ? paymentInfo.payment_to : (paymentInfo.category == 'DRIVER PAYMENT' ? 'DRIVER' : (paymentInfo.category == 'CARRIER PAYMENT' ? 'CARRIER' : ''))}
                            disabled={!paymentInfo.opened} onTextChange={(text) => setPaymentInfo({...paymentInfo, payment_to: text})}
                            colSize={6} name='payment_to' type='select' label='Payment To' required
                            placeholder='-- Select --' options={{
                            data: [{label: 'DRIVER', value: 'DRIVER'}, {
                                label: 'CARRIER',
                                value: 'CARRIER',
                                disabled: true
                            }, {label: 'EMPLOYEE', value: 'EMPLOYEE'}, {label: 'OTHER', value: 'OTHER'}],
                            label: x => x.label,
                            value: x => x.value
                        }}/>
                        {!paymentInfo.opened && <FormGroup disabled={!paymentInfo.opened}
                                                           value={paymentInfo.opened ? '' : (paymentInfo.category == 'DRIVER PAYMENT' ? driver.name : (paymentInfo.category == 'CARRIER PAYMENT' ? myCarrier?.name : ''))}
                                                           colSize={6} name='payee'
                                                           type={paymentInfo.opened ? 'combobox' : 'text'} label='Payee'
                                                           required options={{
                            data: getPayee(),
                            label: x => x.name,
                            value: x => x.id
                        }} placeholder='-- Select --'/>}
                        {paymentInfo.opened && <div className='col-6'>
                            <h4 className='mb-2 font-11' style={{fontWeight: 'normal'}}>Payee*</h4>
                            <button style={{textAlign: 'left'}} onClick={(evt) => {

                                handlePaymentToChanged(paymentInfo.payment_to);
                                setPayeeOpened(true)}}
                                    disabled={paymentInfo.payment_to == ''} className='btn btn-orange col-12 py-2'><span
                                style={{display: 'inline-block'}}>{paymentInfo.payee.name}</span> <i
                                className={"fas fa-caret-down" + (paymentInfo.payee.name ? ' ml-1' : '')}></i></button>
                        </div>}

                        {!paymentInfo.is_request && <FormGroup value={paymentInfo.type}
                                                               onTextChange={(evt) => {
                                                                   const pi = {
                                                                    ...paymentInfo,
                                                                    type: evt
                                                                }
                                                                // setPaymentInfo()

                                                                if (evt == 'CHECK')
                                                                    loadDefaultValues(pi);
                                                                else
                                                                    setPaymentInfo(pi);
                                                               }} colSize={6} name='type' type='select' label='Type'
                                                               required placeholder='-- Select --' options={{
                            data: [{
                                label: 'CASH',
                                value: 'CASH'
                            }, {label: 'CREDIT CARD', value: 'CREDIT CARD'}, {label: 'CHECK', value: 'CHECK'}],
                            label: x => x.label,
                            value: x => x.value
                        }}/>}


                        {(!paymentInfo.is_request && paymentInfo.type != 'CASH') && <FormGroup disabled={bankAccounts.length == 0} value={paymentInfo.bank_account_id?.toString()}
                                   onTextChange={(evt) => {
                                       let id = evt == '' ? null : +evt;
                                       if (id && paymentInfo.type == 'CHECK')
                                           getLastCheck(id);
                                        else
                                       setPaymentInfo({...paymentInfo, bank_account_id: id})
                                   }

                                   } colSize={6} name='bank_account' type='select' label='Bank Account / Credit Card Account' required
                                   placeholder='-- Select --' options={{
                            data: bankAccounts,
                            label: x => `${x.name} **${x.last_4} - ${x.bank}`,
                            value: x => x.id
                        }}/>}


                        {paymentInfo.type == 'CHECK' &&
                        <div className="col-12 row p-3 mb-3" style={{border: '1px solid #CCC', borderRadius: '.6rem'}}>
                            <h4 className='fs-18 text-gray font-bold col-12 mb-3'>Check Information</h4>


                            <div className="col-12 row">
                                <FormGroup disabled value={paymentInfo.check.amount} colSize={4} name='check_reference'
                                           type='text' label='Amount' placeholder='Payable amount...' required/>
                                <FormGroup validation={(x) => +x > 0} errorText={'Check number must be more than 0'}
                                           value={paymentInfo.check.number} onTextChange={(evt) => setPaymentInfo({
                                    ...paymentInfo,
                                    check: {
                                        ...paymentInfo.check,
                                        number: (Helper.Masks.IntNumbers(evt) == '' ? '' : +Helper.Masks.IntNumbers(evt)).toString()
                                    }
                                })} colSize={4} name='check_reference' type='text' label='Check Number'
                                           placeholder='Number...' required/>


                                <FormGroup value={paymentInfo.check.memo} onTextChange={(evt) => setPaymentInfo({
                                    ...paymentInfo,
                                    check: {...paymentInfo.check, memo: evt}
                                })} colSize={4} name='check_reference' type='text' label='Memo' placeholder='Memo...'/>
                                <FormGroup value={paymentInfo.check.payable_to} onTextChange={(evt) => setPaymentInfo({
                                    ...paymentInfo,
                                    check: {...paymentInfo.check, payable_to: evt}
                                })} colSize={12} name='check_reference' type='text' label='Payable To'
                                           placeholder='Payable to...' required/>
                            </div>

                            <div className='col-12'>


                                <div className="row align-items-center mb-3">
                                    <h4 style={{fontWeight: 'normal'}} className='font-14'>Stub Reference</h4>
                                    <button className='btn btn-blue-light'
                                            onClick={(evt) => setCKEditorData(paymentInfo.check.reference)}>EDIT
                                    </button>
                                </div>
                                <div style={{
                                    padding: '0 24px',
                                    border: '1px solid black',
                                    maxHeight: '440px',
                                    minHeight: '440px',
                                    overflow: 'hidden'
                                }} id='rich-text-formatted'
                                     dangerouslySetInnerHTML={{__html: paymentInfo.check.reference}}>

                                </div>
                            </div>
                            <div className="col-12 mb-4"
                                 style={{display: 'flex', flexDirection: 'column', maxHeight: '84%'}}>
                                {/* <FormGroup className='h-100 mb-4' value={paymentInfo.check.reference} fullTextarea onTextChange={(evt) => setPaymentInfo({...paymentInfo, check: {...paymentInfo.check, reference: evt}})} colSize={12} name='check_reference' type='textarea' label='Stub Reference'  placeholder='Referece...' required />     */}

                            </div>

                            {/* <FormGroup value={paymentInfo.check.reference} onTextChange={(evt) => setPaymentInfo({...paymentInfo, check: {...paymentInfo.check, reference: evt}})} colSize={6} name='check_reference' type='text' label='Stub Reference'  placeholder='Referece...' required /> */}
                        </div>}

                        <FormGroup value={paymentInfo.reference}
                                   onTextChange={(evt) => setPaymentInfo({...paymentInfo, reference: evt})} colSize={6}
                                   name='reference' type='text' label='Internal Reference' placeholder='Reference...'/>
                        <FormGroup readOnly onClick={(evt) => setPickerOpen(true)} className='position-relative'
                                   value={moment(paymentInfo.time_paid).format('MM/DD/YYYY hh:mm:ss A')} required
                                   colSize={6} name='reference' type='text'
                                   label={`${paymentInfo.is_request ? 'Due Date' : 'Date Paid'}`} placeholder='Paid on...'>
                            <i className="far fa-calendar-alt"></i>
                        </FormGroup>


                        <>

                            <FormGroup errorText={'Payment must be more than 0'} validation={(evt) => +evt > 0}
                                       disabled={!paymentInfo.opened} value={paymentInfo.amount_to_pay}
                                       onTextChange={(evt) => {
                                           let p = Helper.Masks.DecimalNumbers(evt);
                                           setPaymentInfo({
                                               ...paymentInfo,
                                               amount_to_pay: p,
                                               check: {...paymentInfo.check, amount: p}
                                            //    check: {...paymentInfo.check, amount: p}
                                           });
                                       }} colSize={6} name='amount_to_pay' type='text' label='Amount To Pay' required
                                       placeholder='0.00' moneySymbol/>
                            {!paymentInfo.opened && <FormGroup moneySymbol colSize={6} name='payment_left' type='text'
                                                               value={paymentInfo.payment_left} disabled
                                                               label='Payment Left' required placeholder='0.00'/>}

                        </>

                        <FormGroup value={paymentInfo.category}
                                   onTextChange={(evt) => setPaymentInfo({...paymentInfo, category: evt})} colSize={6}
                                   name='category'
                                   type={paymentInfo.category == 'CARRIER PAYMENT' || paymentInfo.category == 'DRIVER PAYMENT' ? 'text' : 'select'}
                                   label='Category' required placeholder='-- Select --'
                                   options={{data: categories, label: x => x, value: x => x}}
                                   disabled={paymentInfo.category == 'CARRIER PAYMENT' || paymentInfo.category == 'DRIVER PAYMENT'}/>
                        <FormGroup className='mb-0' colSize={12} name='notes' type='textarea' value={paymentInfo.notes}
                                   onTextChange={(notes) => setPaymentInfo({...paymentInfo, notes})}
                                   label='Notes (optional)' placeholder='Notes...'/>


                    </div>


                    </div>



                    <div className="row mt-3">
                        <button className="btn btn-danger col-6 py-2"
                                onClick={(evt) => setPaymentInfo(new PaymentInfo())}>CANCEL
                        </button>
                        <button className="btn btn-blue-light col-6 py-2" disabled={!isValid()}
                                onClick={(evt) => paymentInfo.is_request ? submitPayableRequest() : submitPayable()}>SUBMIT
                        </button>
                    </div>
                </div>


            </ReactModal>

            {pickerOpen && <DateTimePicker date={paymentInfo.time_paid} onCancelClicked={(evt) => setPickerOpen(false)}
                                           onDateSubmitted={(evt) => {
                                               setPaymentInfo({...paymentInfo, time_paid: evt});
                                               setPickerOpen(false);
                                           }}/>}

            <ReactModal isOpen={ckEditorData != null} >
                {ckEditorData != null && <>
                    <div className='row align-items-center mb-3'>
                        <h4 className='font-16'>Stub Reference</h4>
                        <button className='btn btn-clear' onClick={(evt) => setCKEditorData(null)}><img
                            src={ICONS.IconCloseBig} alt=""/></button>
                    </div>
                    <CKEditor
                        editor={ClassicEditor}
                        data={ckEditorData}
                        onChange={(evt, editor) => {
                            setCKEditorData(editor.getData())
                            // setPaymentInfo({...paymentInfo, check: {...paymentInfo.check, reference: editor.getData()}});
                        }}
                        onReady={(editor) => {
                            editor.editing.view.change((writer) => {
                                writer.setStyle(
                                    'height',
                                    `calc(62vh - 2rem)`,
                                    editor.editing.view.document.getRoot()
                                );

                                writer.setStyle(
                                    'padding',
                                    `0 2rem`,
                                    editor.editing.view.document.getRoot()
                                );
                            });
                        }}
                    />

                    <div className="row mt-3">
                        <button className='btn btn-danger col-6 py-2' onClick={(evt) => setCKEditorData(null)}>CANCEL
                        </button>
                        <button className='btn btn-blue col-6 py-2' onClick={(evt) => {

                            setPaymentInfo({...paymentInfo, check: {...paymentInfo.check, reference: ckEditorData}});
                            setCKEditorData(null);
                        }}>SAVE
                        </button>
                    </div>
                </>}

            </ReactModal>


            <ReactModal onRequestClose={(evt) => setPayeeOpened(false)}  isOpen={payeeOpened}>
                <div className='flex-1-container'>
                    <div className="col-12 row mb-3">
                        
                        <h4 className="font-12">Select a Payee</h4>
                        <h4 onClick={(evt) => setPayeeOpened(false)} className="font-12 cursor-pointer"><i className='fas fa-times'></i></h4>

                    </div>
                    <div className="mb-3">
                         <div className='d-flex align-items-center'>



                            <Searchbar reference={ref}
                                    value={searchTerm}
                                    onChange={(evt) => {
                                        console.log(evt);
                                        setSearchTerm(evt.target.value)
                                    }}
                                    background={'#F8F8F8'}
                                    />


                            {paymentInfo.payment_to == 'OTHER' &&
                            <button className="btn btn-blue-outline ml-3" onClick={(evt) => {
                                setNewPayee(true)
                                // const pi = new PaymentInfo();
                                // pi.type = 'CHECK';
                                // loadDefaultValues(pi);
                            }}>New payee <i className="fas fa-plus ml-1"></i></button>}
                         </div>
                    </div>
                    {paymentInfo.payment_to == 'DRIVER' && <Table

                        rowHeight={60} loading={false} tableHeight={'70vh'}
                        onRowClicked={async (event) => {
                            let payee = new Payee({id: event.jp_driver_id, name: event.name});
                            if (!payee.id)
                                return toast.addToast(`Driver does not exist`, {
                                    appearance: 'error',
                                    autoDismiss: true
                                });
                            setPayeeOpened(false);
                            setPaymentInfo({...paymentInfo, payee, check: {...paymentInfo.check, payable_to: payee.name}})

                            //    console.log()
                        }} columns={[
                        {label: 'DRIVER NAME', active: false, orderBy: 'DESC', name: 'name'},
                        {label: 'LAST PAYABLE', active: false, orderBy: 'DESC', name: 'last_payable'},
                        {label: 'TOTAL', active: false, orderBy: 'DESC', name: 'total_$'},
                        {label: 'DELIVERED', active: false, orderBy: 'DESC', name: 'delivered'},

                        {label: 'VALID', active: false, orderBy: 'DESC', name: '_valid'},
                        {label: 'EXISTS', active: false, orderBy: 'DESC', name: 'exists'}
                    ]} rows={getAgingsDriver(true)}/>}

                    {paymentInfo.payment_to == 'EMPLOYEE' && <Table

                        rowHeight={60} loading={false} tableHeight={'70vh'}
                        onRowClicked={async (event) => {
                            console.log(event)
                            let p = new Payee({id: event.id, name: event.name});
                            setPayeeOpened(false);

                            setPaymentInfo({
                                ...paymentInfo,
                                payee: p,
                                check: {...paymentInfo.check, payable_to: p.name}
                            });


                            //    console.log()
                        }} columns={[
                        {
                            label: 'ID',
                            name: 'id',
                            active: false,
                            orderBy: 'ASC'
                        },
                        {
                            label: 'NAME',
                            name: 'name',
                            active: false,
                            orderBy: 'ASC'
                        },
                        {
                            label: 'DEPARTMENT',
                            name: 'department',
                            active: false,
                            orderBy: 'ASC'
                        },
                        {
                            label: 'LAST PAYABLE',
                            name: 'payable',
                            active: false,
                            orderBy: 'ASC'
                        },
                        {
                            label: 'TIME PAID',
                            name: 'time_paid',
                            active: false,
                            orderBy: 'ASC'
                        },
                        {
                            label: 'CATEGORY',
                            name: 'category',
                            active: false,
                            orderBy: 'ASC'
                        }
                    ]}
                        rows={employees.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim())).map(x => ({
                            ...x,
                            category: x.last_payable?.category || '-',
                            time_paid: !x.last_payable ? '-' : [Helper.FORMAT.USDate(x.last_payable.time_paid, 'do')],
                            payable: !x.last_payable ? '-' : [Helper.FORMAT.USCurrency(x.last_payable.amount)]
                        }))}/>}

                    {paymentInfo.payment_to == 'OTHER' &&
                    <Table
                        rowHeight={60} loading={false} tableHeight={'70vh'}
                        onRowClicked={async (event) => {
                            console.log(event)
                            let p = new Payee({id: event.id, name: event.name});
                            setPayeeOpened(false);

                            setPaymentInfo({
                                ...paymentInfo,
                                payee: p,
                                check: {...paymentInfo.check, payable_to: p.name}
                            });

                            //    console.log()
                        }} columns={[
                        {
                            label: 'ID',
                            name: 'id',
                            active: false,
                            orderBy: 'ASC'
                        },
                        {
                            label: 'NAME',
                            name: 'name',
                            active: false,
                            orderBy: 'ASC'
                        },
                        {
                            label: 'ADDRESS',
                            name: 'address_info',
                            active: false,
                            orderBy: 'ASC'
                        }
                    ]} rows={others.map(x => ({
                        ...x,
                        address_info: [`${x.address.street_number} ${x.address.street}`, `${x.address.city} ${x.address.state}, ${x.address.postal_code}`]
                    })).filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim())).map(x => ({...x}))}/>}
                </div>

            </ReactModal>

            {/*New Payee*/}
            <ReactModal onRequestClose={(evt) => setNewPayee(false)} isOpen={newPayee}>
                <div className='d-flex flex-column h-100' style={{flexWrap: 'nowrap'}}>
                    <div className="col-12 mb-3">
                        <PayeeForm onCancel={(e) => {
                            setNewPayee(false)
                        }} onSubmit={(data) => {
                            handleSubmitPayee(data)
                        }}/>
                    </div>
                </div>
            </ReactModal>

        </>
    )
}

export default PayablesPage;

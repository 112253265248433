import {gql, ObservableQuery} from "apollo-boost";
import Helper from "../../helper/Helper";
import {GraphqlService, ObservableQueryT} from "../../services/graphql.service";
import APTypes from "../types/AP.type";


// TODO: CHANGE QUERY

const queries = {

    'open': gql`
        query($carrier_id:Int){
  get_broker_orders(carrier_id: $carrier_id){
    time
    velox_order_id
    broker{
      id
      name
    }
    remaining_driver_payable
    remaining_carrier_receivable
    expires
    vehicle{
      id
      vin
      year
      make
      model
    }
    carrier_pay
    driver{
      id
      name
      equipment
    }
    origin{
      nofail
      scheduled
      time_completed
      completed
      contacts{
        name
        value
      }
      address{
        street_number
        street
        city
        state
        postal_code
        country
      }
    }
    destination{
      nofail
      scheduled
      time_completed
      completed
      contacts{
        name
        value
      }
      address{
        street_number
        street
        city
        state
        postal_code
        country
      }
    }
    carrier{
      id
      name

    }
  }
}

    `,

    'aging': gql`
        query($showempty:Boolean){
            get_aging_carriers_for_broker_payment(showempty: $showempty){
                name
                carrier_id
                drivers_dispatched
                total
                over30
                over60
                over90
                current
                delivered
                payment_terms
                valid
            }
        }
    `,
    'open_driver': gql`
      query ($driver_id: Int!, $showclosed:Boolean) {
  get_carrier_driver_orders(driver_id:$driver_id, showclosed:$showclosed){
    time
    velox_order_id
    carrier_pay
    driver_pay
    remaining_driver_payable
    remaining_carrier_receivable
    expires
    vehicle{
      id
      year
      make
      model
    }
    driver{
      id
      name
      equipment
    }
    origin{
      nofail
      scheduled
      time_completed
      completed
      address{
        street_number
        street
        city
        state
        postal_code
        country
      }
    }
    destination{
      nofail
      scheduled
      time_completed
      completed
      address{
        street_number
        street
        city
        state
        postal_code
        country
      }
    }
    carrier{
      id
      name

    }

  }
}

    `,
    'aging_driver': gql`
        query($showempty:Boolean){
          get_aging_drivers_for_carrier_payables(showempty:$showempty){
            name
            total
            valid
            last_payable{
              id
              time
              type
              reference
              amount
            }
            delivered
            driver_id
          }
        }
    `,
    'requests': gql`
        query{
          get_all_payable_requests{
            id
            time
            time_due
            type
            reference
            note
            amount
            category
            payable_to
            payable_id
            bank_account_id

            payee{
              id
              name
              order_ids
            }
          }
        }
    `
}

const dataMapped = {
    'aging': (e) => ({
      ...e,
      current_$: [Helper.FORMAT.USCurrency(e.current)],
      current: +Helper.Masks.DecimalNumbers(e.current),
      plus30_$: [Helper.FORMAT.USCurrency(e.over30)],
      plus30: +Helper.Masks.DecimalNumbers(e.over30),
      plus60_$: [Helper.FORMAT.USCurrency(e.over60)],
      plus60: +Helper.Masks.DecimalNumbers(e.over60),
      plus90_$: [Helper.FORMAT.USCurrency(e.over90)],
      plus90: +Helper.Masks.DecimalNumbers(e.over90),
      total_$: [Helper.FORMAT.USCurrency(e.total)],
      total: +Helper.Masks.DecimalNumbers(e.total)
      // moves: [e.not_picked_up + ' not picked', e.not_delivered + ' undelivered', e.non_move + ' non move']
  }),
    'open': (r) => (
      {...r,
        velox_order_column: 'VLX-' + r.velox_order_id,
        velox_order_id: r.velox_order_id,
    vehicle: [r.vehicle.vin || '-', r.vehicle.year + ' ' + r.vehicle.make, r.vehicle.model],
    driver: [r.driver?.name || '-'],
    carrier: [r?.carrier?.name || '-'],
    broker: [r.broker?.name || '-'],
    time_in: [Helper.FORMAT.USDate(r.time, 'do'), Helper.FORMAT.USDate(r.time, 'totz')],
    time: r.time,
    origin: [r.origin.address?.city, `${r.origin.address?.state} ${r.origin.address?.postal_code}`, Helper.FORMAT.USDate(r.origin.scheduled, 'do'), Helper.FORMAT.USDate(r.origin.scheduled, 'totz')],
    destination: [r.destination.address?.city, `${r.destination.address?.state} ${r.destination.address?.postal_code}`, Helper.FORMAT.USDate(r.destination.scheduled, 'do'), Helper.FORMAT.USDate(r.destination.scheduled, 'totz')],
    status: r.origin.completed && r.destination.completed ? 'COMPLETED' : (r.origin.completed ? 'PICKED UP' : 'DISPATCHED'),
    remainig_balance: Helper.FORMAT.USCurrency(r.remaining_carrier_receivable),
    remainig_balance_amount:  r.remaining_carrier_receivable,
    carrier_pay_amount: r.carrier_pay,
    carrier_pay: Helper.FORMAT.USCurrency(r.carrier_pay)}),
    'open_driver': r => ({id: r.driver_id,
        name: (r.driver?.name || r.name) || '-',
        velox_order_column: !r.velox_order_id ? '-' : 'VLX-' + r.velox_order_id,
        velox_order_id: r.velox_order_id,
        vehicle: !r.vehicle ? '-' : [r.vehicle?.vin || '-', r.vehicle?.year + ' ' + r.vehicle?.make + ' ' + r.vehicle?.model],
        driver: [r.driver?.name || r.name || '-'],
        broker: [r.broker?.name || '-'],
        time_in: !r.time ? '-' : [Helper.FORMAT.USDate(r.time, 'do'), Helper.FORMAT.USDate(r.time, 'totz')],
        origin: !r.origin ? '-' : [r.origin?.address?.city, `${r.origin.address?.state} ${r.origin.address?.postal_code}`, Helper.FORMAT.USDate(r.origin.scheduled, 'do'), Helper.FORMAT.USDate(r.origin.scheduled, 'totz')],
        destination: !r.destination ? '-' : [r.destination.address?.city, `${r.destination.address?.state} ${r.destination.address?.postal_code}`, Helper.FORMAT.USDate(r.destination.scheduled, 'do'), Helper.FORMAT.USDate(r.destination.scheduled, 'totz')],
        status: !r.destination ? '-' : r.origin.completed && r.destination.completed ? 'COMPLETED' : (r.origin.completed ? 'PICKED UP' : 'DISPATCHED'),
        remainig_balance: !r.destination ? '-' : Helper.FORMAT.USCurrency(r.remaining_driver_payable),
        remainig_balance_amount: r.remaining_driver_payable,
        driver_pay: !r.driver_pay ? '-' : Helper.FORMAT.USCurrency((r as any).driver_pay)}),
    'aging_driver': e => ({...e,
      last_payable: e.last_payable ? [`P-${e.last_payable?.id}`, Helper.FORMAT.USCurrency(e.last_payable.amount), Helper.FORMAT.USDate(e.last_payable.time_paid, 'do')] : '-',
      last_payable_id: e.last_payable?.id || 0,
      _valid: e.valid ? 'Yes' : 'No',
      exists: e.id != null ? 'Yes' : 'No',
      jp_driver_id: e.id,
      total_$: [Helper.FORMAT.USCurrency(e.total)],
      total: e.total
      // moves: [e.not_picked_up + ' not picked', e.not_delivered + ' undelivered', e.non_move + ' non move']
  }),
    'requests': x => ({
      ...x,
      id: x.id,
      r_id: 'PR-' + x.id,
      name: x.payee.name,
      payable_to: x.payable_to,
      category: x.category,
      time: [Helper.FORMAT.USDate(x.time, 'do'), Helper.FORMAT.USDate(x.time, 'totz')],
      payable: !x.payable_id ? '-' : 'P-' + x.payable_id,
      time_due: [Helper.FORMAT.USDate(x.time_due, 'do'), Helper.FORMAT.USDate(x.time_due, 'totz')]
  })
}

export default function getPayables(data: {open: {carrier_id: number}, aging: {showempty: boolean}, open_driver: {showClosed: boolean, driver_id: number}, aging_driver: {showempty: boolean}}) {
    return async dispatch => {

            // const statuses = ['open', 'closed', 'aging', 'payments'];
            const statuses = ['open', 'open_driver', 'closed', 'aging', 'aging_driver',  'payments', 'requests'];

            for (const stat of statuses) {
                dispatch(getAllAPByStatusAction(true, stat));
                if (!queries[stat] || (!data[stat] && stat != 'requests'))
                {
                    dispatch(getAllAPByStatusSuccessAction([], stat));
                    continue;
                }
                GraphqlService
                    .SendQuery(queries[stat], data[stat])
                    .then((data) => {
                        dispatch(getAllAPByStatusSuccessAction(data.map(x => dataMapped[stat](x)), stat)); // Replace new Object by the custom class
                    })
                    .catch((ex) => {
                        // dispatch(getAllOrderRequestByStatusErrorAction(ex.message, stat));
                        console.log('Error in getPayables: ', stat, ex);
                        dispatch(getAllAPByStatusErrorAction(ex.message, stat));
                    });
            }



    }
}

const getAllAPByStatusAction = (payload, status) => ({
    type: APTypes.GET_ALL_AP_STARTED,
    payload,
    status
});

const getAllAPByStatusSuccessAction = (payload, status) => ({
    type: APTypes.GET_ALL_AP_SUCCESS,
    payload,
    status
});

const getAllAPByStatusErrorAction = (payload, status) => ({
    type: APTypes.GET_ALL_AP_ERROR,
    payload,
    status
});

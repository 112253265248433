const GET_ALL_MAKES_STARTED = 'GET_ALL_MAKES_STARTED';
const GET_ALL_MAKES_SUCCESS = 'GET_ALL_MAKES_SUCCESS';
const GET_ALL_MAKES_ERROR = 'GET_ALL_MAKES_ERROR';

const GET_MODELS_MAKE_STARTED = 'GET_MODELS_MAKE_STARTED';
const GET_MODELS_MAKE_SUCCESS = 'GET_MODELS_MAKE_SUCCESS';
const GET_MODELS_MAKE_ERROR = 'GET_MODELS_MAKE_ERROR';
const RESET_MODELS_MAKE = 'RESET_MODELS_MAKE';


const VehicleTypes = {
    GET_ALL_MAKES_STARTED,
    GET_ALL_MAKES_SUCCESS,
    GET_ALL_MAKES_ERROR,
    GET_MODELS_MAKE_STARTED,
    GET_MODELS_MAKE_SUCCESS,
    GET_MODELS_MAKE_ERROR,
    RESET_MODELS_MAKE
}

export default VehicleTypes;

import Status from "../Status/Status";
import './LegDetail.scss';
import {VehicleOrder} from "../../classes/classes";
import LegDetailDestination from "./LegDetailDestination";
import Helper from "../../helper/Helper";
import VehicleInformation from "../NewOrder/VehicleInformation/VehicleInformation";
import React, {useState} from "react";
import Modal from 'react-modal';

const LegDetail = ({order, onClose}: {order: any, onClose: () => void}) => {
    const [currOrder, setCurrOrder] = useState(order);
    const [editVehicle, setEditVehicle] = useState(undefined);

    return(
        <>
            <Modal isOpen={editVehicle != undefined}  >
                <VehicleInformation masterID={currOrder?.master_order_id}
                                    vehicleOrder={editVehicle || new VehicleOrder()}
                                    onVehicleOrdersChanged={(evt) => {
                                        setEditVehicle(undefined);

                                        setCurrOrder({...currOrder, vehicle: evt});
                                    }}/>
            </Modal>

            <div className="card card-leg-detail">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-start align-items-start">
                                <div className="mr-3">
                                    <p className="font-16 font-medium mb-0 me-3">LG-{ currOrder?.leg_id }</p>
                                    <p className="font-14 mb-0 me-3">M-{ currOrder?.master_order_id }</p>
                                </div>

                                <Status statusName={currOrder?.status}/>
                            </div>

                            <i className="ti-close font-12 font-medium cursor-pointer" onClick={() => onClose()}></i>
                        </div>

                        <div className="col-12 mt-3">
                            <p className="font-14 font-medium">Vehicle & trip</p>
                        </div>

                        <div className="col-6 mt-3">
                            <p className="font-12 font-medium mb-2">Vehicle</p>

                            <div className="card bg-gray">
                                <div className="card-body p-3 d-flex justify-content-between align-items-start">
                                    <div>
                                        <p className="font-14 text-red font-medium mb-1">{ currOrder?.vehicle?.year } { currOrder?.vehicle?.make } { currOrder?.vehicle?.model }</p>
                                        <p className="font-12 text-red m-0">{ currOrder?.vehicle?.vin }</p>
                                    </div>

                                    <p className="font-10 font-medium cursor-pointer btn btn-blue-light cursor-pointer"
                                       onClick={() => setEditVehicle(currOrder?.vehicle)}>EDIT</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 mt-3">
                            <p className="font-12 font-medium mb-2">Requested by</p>

                            <div className="card bg-gray">
                                <div className="card-body p-3">
                                    <p className="font-14 font-medium mb-1">{ currOrder?.customer?.name }</p>
                                    <p className="font-12 mb-1">000 000 0000</p>
                                    <p className="font-12 m-0">longlongemail@emaildomain.com</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-5">
                            <p className="font-12 font-medium">Trip</p>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3 mt-3">
                            <div className="leg-trip">
                                <div className="mt-3">
                                    <p className="font-14 m-0 font-medium">{ Helper.FORMAT.ParseDate(currOrder?.origin?.time_completed) }</p>
                                    <p className="font-12 m-0">{ Helper.FORMAT.ParseTime(currOrder?.origin?.time_completed) }</p>

                                    { currOrder?.origin?.nofail && <span className="badge-rounded badge-late">NO FAIL</span> }
                                </div>

                                <div className="font-12">{'6 hrs'}</div>

                                <div className="">
                                    <p className="font-14 m-0 font-medium">{ Helper.FORMAT.ParseDate(currOrder?.destination?.time_completed) }</p>
                                    <p className="font-12 m-0">{ Helper.FORMAT.ParseTime(currOrder?.destination?.time_completed) }</p>

                                    { currOrder?.destination?.nofail && <span className="badge-rounded badge-late">NO FAIL</span> }
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8 col-sm-9 col-xs-9 col-9 mt-3">
                            <LegDetailDestination customer={currOrder?.customer} destination={currOrder?.origin}/>
                            <br/>
                            <LegDetailDestination customer={currOrder?.customer} destination={currOrder?.destination}/>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12 mt-3">
                            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                                <div className="card w-100 bg-gray">
                                    <div className="card-body p-3">
                                        <p className="font-12 m-0 font-medium">{ currOrder?.equipment }</p>
                                        <p className="font-12">$ 500.00</p>

                                        <p className="font-12 m-0 font-medium">{ currOrder?.carrier?.name }</p>
                                        <p className="font-12 m-0">$ 200.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LegDetail;

import {Charge_Input, New_Charge_Input_Cd, Service_Charge} from "../service_charge";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {ResultWithLink} from "../../customer_profile/customer_profile";

export const create_or_update_service_charge = (input:{charge: New_Charge_Input_Cd}) : Promise<Service_Charge> => {
  let mutation = gql`
    mutation($charge: new_charge_input_CD) {
      create_or_update_service_charge(charge: $charge) {
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {charge} as 
  Promise<Service_Charge>;
}

export const delete_service_charge = (input:{charge_id: number}) : Promise<string> => {
  let mutation = gql`
    mutation($charge_id: Int) {
      delete_service_charge(charge_id: $charge_id)
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {charge_id} as 
  Promise<string>;
}

export const manage_service_charges = (input:{master: Charge_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($master: charge_input) {
      manage_service_charges(master: $master) {
        id
        link
        success
        message
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {master} as 
  Promise<ResultWithLink>;
}
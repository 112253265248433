import driverTypes from "../types/DriverSetting.type";
import {GraphqlService} from "../../services/graphql.service";
import { gql } from "apollo-boost";

const ADD_DRIVER_QUERY = gql(`
    mutation($carrier_id: Int, $name: String, $username: String, $password: String, $equipment: String, $email: String, $phones: [String]){
        create_velox_driver(carrier_id:$carrier_id, name:$name, username:$username, password:$password, equipment:$equipment, email:$email,  phones:$phones){
            id
            message
            success
      }
    }`);

const UPDATE_DRIVER_QUERY = gql(`
    mutation($driver: driver_input) {
        update_velox_driver(driver: $driver) {
            id
            message
            success
        }
    }`);

const ADD_EQUIPMENT_FILE = gql(`
    mutation($driver_id: Int!, $file: Upload) {
        upload_velox_driver_equipment_photo(velox_driver_id: $driver_id, file: $file) {
            id
            message
            success
        }
    }`);

const REMOVE_EQUIPMENT_FILE = gql(`
    mutation($velox_driver_id: Int, $uid: String) {
         remove_driver_equipment(velox_driver_id: $velox_driver_id, uid: $uid) {
            id
            message
            success
          }
    }`);

const UPDATE_DRIVER_PASSWORD_QUERY = gql(`
    mutation($velox_driver_id:Int, $password: String, $confirm_password: String){
      update_driver_password(velox_driver_id:$velox_driver_id, password: $password, confirm_password: $confirm_password){ 
        message
        success
      }
    }`);

const UPDATE_DRIVER_USERNAME_QUERY = gql(`
    mutation($velox_driver_id: Int, $new_username: String!) {
      change_driver_username(
        velox_driver_id: $velox_driver_id
        new_username: $new_username
      ) {
        id
        message
        success
      }
}`);

const initDriverForm = () => {
    return { type: driverTypes.INIT_DRIVER_FORM }
}

const updateDriverRequest = () => {
    return { type: driverTypes.UPDATE_DRIVER_REQUEST }
}

const updateDriverSuccess = (response) => {
    return { type: driverTypes.UPDATE_DRIVER_SUCCESS, payload: response }
}

const removeEquipmentSuccess = (response) => {
    return {type: driverTypes.REMOVE_EQUIPMENT_SUCCESS, payload: response}
}

const updateDriverFailure = (error) => {
    return { type: driverTypes.UPDATE_DRIVER_FAILURE, payload: error }
}

export const initDriver = () => {
    return async (dispatch) => dispatch(initDriverForm());
}

export const createDriver = (params, equipments) => {

    return async (dispatch) => {
        dispatch(updateDriverRequest());
        GraphqlService.SendMutation(ADD_DRIVER_QUERY, params).then(response => {
            if (response && response.success) {
                if(equipments.length){
                    dispatch(addEquipmentFile(response, response.id, equipments));
                }else{
                    dispatch(updateDriverSuccess(response));
                }
            } else {
                dispatch(updateDriverFailure(response.message));
            }
        }, (error) => {
            dispatch(updateDriverFailure(error));
        });
    }
}

export const updateDriver = (params, equipments) => {
    return async (dispatch) => {
        dispatch(updateDriverRequest());
        GraphqlService.SendMutation(UPDATE_DRIVER_QUERY, params).then(response => {
            if (response && response.success) {
                dispatch(addEquipmentFile(response, response.id, equipments));
            } else {
                dispatch(updateDriverFailure(response.message));
            }
        }, (error) => {
            dispatch(updateDriverFailure(error));
        });
    }
}

export const updateDriverPassword = (params) => {
    return async (dispatch) => {
        dispatch(updateDriverRequest());
        GraphqlService.SendMutation(UPDATE_DRIVER_PASSWORD_QUERY, params).then(response => {
            if (response && response.success) {
                dispatch(updateDriverSuccess(response));
            } else {
                dispatch(updateDriverFailure(response.message));
            }
        }, (error) => {
            dispatch(updateDriverFailure(error));
        });
    }
}

export const updateDriverUsername = (params) => {
    return async (dispatch) => {
        dispatch(updateDriverRequest());
        GraphqlService.SendMutation(UPDATE_DRIVER_USERNAME_QUERY, params).then(response => {
            if (response && response.success) {
                dispatch(updateDriverSuccess(response));
            } else {
                dispatch(updateDriverFailure(response.message));
            }
        }, (error) => {
            dispatch(updateDriverFailure(error));
        });
    }
}

const addEquipmentFile = (parentResponse, driver_id, equipments, index = 0) => {
    return async (dispatch) => {
        GraphqlService.SendMutation(ADD_EQUIPMENT_FILE, {driver_id, file: equipments[index]?.files}).then(response => {
            if (response && index < equipments.length - 1) {
                index = index + 1;
                dispatch(addEquipmentFile(parentResponse, driver_id, equipments, index));
            } else {
                dispatch(updateDriverSuccess(parentResponse));
            }
        }, (error) => {
            dispatch(updateDriverFailure(error));
        });
    }
}


export const removeEquipmentFileSingle = (driver_id, uid) => {
    return async (dispatch) => {
        dispatch(updateDriverRequest());
        GraphqlService.SendMutation(REMOVE_EQUIPMENT_FILE, {velox_driver_id: driver_id, uid}).then(response => {
            dispatch(removeEquipmentSuccess(response.message));
        }, (error) => {
            dispatch(updateDriverFailure(error));
        });
    }
}

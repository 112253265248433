import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {Equipment, Equipment_Input} from "../equipment";
import {ResultWithLink} from "../../customer_profile/customer_profile";
import {Upload} from "../../upload";
import {StandardResult} from "../../leg/leg";

export const create_or_update_equipment = (input:{equipment: Equipment_Input}) : Promise<Equipment> => {
  let mutation = gql`
    mutation($equipment: equipment_input) {
      create_or_update_equipment(equipment: $equipment) {
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  // as 
  Promise<Equipment>;
}

export const upload_jp_equipment_photo = (input:{equipment_id: number, photo: Upload}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($equipment_id: Int, $photo: Upload) {
      upload_jp_equipment_photo(equipment_id: $equipment_id, photo: $photo) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, { equipment_id, photo } as 
  Promise<ResultWithLink>;
}

export const remove_equipment_photo = (input:{equipment_id: number, uid: string}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($equipment_id: Int, $uid: String) {
      remove_equipment_photo(equipment_id: $equipment_id, uid: $uid) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, { equipment_id, uid } as 
  Promise<StandardResult>;
}
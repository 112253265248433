import React from 'react';

const MastersPaymentDetail = ({paymentSelected, onClose, onMasterDetailClicked}) => {
    return <div style={{display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto'}}>
    <div className='mb-3 row col-12'>
        <h4 className="fs-16">Masters details for payment #{paymentSelected?.id}</h4>
        <h4 onClick={onClose} className="cursor-pointer"><i style={{position: 'relative', right: 'unset', bottom: 'unset', left: 'unset', top: 'unset'}} className='fas fa-times'></i></h4>
    </div>
    <div style={{display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto'}}>
       {paymentSelected?.masters.map((m, i) => <div className={`bg-gray p-3 card mb-${i == paymentSelected?.masters.length - 1 ? '0' : '3'}`}>
            <div className="row align-items-center">
                <h4 className="fs-16">Master #{m.id}</h4>
                <button className="btn btn-blue-light" onClick={(evt) => onMasterDetailClicked(m.id)}>See Details</button>
            </div>
             {
                 m.references.length > 0 &&
                 <>
                    <hr className={'my-2'} />
                    <h4 className="fs-16">References</h4>
                    {m.references.map(r => <h5 className="fs-14 mt-1" style={{fontWeight: 'normal'}}>{r.key}: {r.value}</h5>)}
                 </>
             }
        </div>)}
    </div>
</div>
};

export default MastersPaymentDetail;

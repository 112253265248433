import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {At_Location_Time, GPlaceRes} from "../location";
import {Address_Input} from "../../customer/customer";

export const get_google_place_details = (input:{placeid: string}) : Promise<GPlaceRes> => {
  let query = gql`
    query($placeid: String) {
      get_google_place_details(placeid: $placeid) {
        address {
          city
          state
          street
          country
          postal_code
          street_number
          postal_code_suffix
        }
        formatted
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {placeid} as 
  Promise<GPlaceRes>;
}

export const get_time_at_location = (input:{address: string, format: string, atTime: string, includeCity: boolean}) : Promise<At_Location_Time> => {
  let query = gql`
    query($address: String, $format: String, $atTime: String, $includeCity: Boolean) {
      get_time_at_location(address: $address, format: $format, atTime: $atTime, includeCity: $includeCity) {
        time_formatted
        timezone {
          code
          name
          daylight_offset
          utc_offset
        }
        place {
          formatted_address
          place_id
          location {
            latitude
            longitude
          }
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {address, format, atTime, includeCity} as 
  Promise<At_Location_Time>;
}

export const get_time_at_address = (input:{address: Address_Input, format: string, atTime: string, includePlace: boolean}) : Promise<At_Location_Time> => {
  let query = gql`
    query($address: address_input, $format: String, $atTime: String, $includePlace: Boolean) {
      get_time_at_address(address: $address, format: $format, atTime: $atTime, includePlace: $includePlace) {
        time_formatted
        timezone {
          code
          name
          daylight_offset
          utc_offset
        }
        place {
          formatted_address
          place_id
          location {
            latitude
            longitude
          }
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {address, format, atTime, includePlace} as 
  Promise<At_Location_Time>;
}
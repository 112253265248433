import {Customer, Customer_Details_Info, Customer_Location_Sp, CustomerSearchList} from "../customer";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {Address} from "../../location/location";
import {Master_Order} from "../../master_order/master_order";

export const get_customer_search_list = (input:{}) : Promise<CustomerSearchList> => {
  let query = gql`
    query {
      get_customer_search_list {
        id
        key
        name
        corporate_client
        billing_address {
          street_number
          street
          city
          state
          postal_code
          country 
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<CustomerSearchList>;
}

export const customer_by_id = (input:{customer_id: number}) : Promise<Customer> => {
  let query = gql`
    query($customer_id: Int) {
      customer_by_id(customer_id: $customer_id) {
        id
        name
        corporate_client
        key
        internal_note
        terms
        active
        billing_address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
        }
        default_invoice_email
        tax_number {
            type
            value
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {customer_id} as 
  Promise<Customer>;
}

export const get_billing_address = (input:{customer_id: number}) : Promise<Address> => {
  let query = gql`
    query($customer_id: Int) {
      get_billing_address(customer_id: $customer_id) {
        city
        state
        street
        country
        postal_code
        street_number
        postal_code_suffix
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {customer_id} as 
  Promise<Address>;
}

export const get_customer_locations = (input:{}) : Promise<Customer_Location_Sp[]> => {
  let query = gql`
    query {
      get_customer_locations {
        customer_id
        name
        key
        title
        location_id
        address {
            street
            street_number
            postal_code
            state
            country
            city
        }
        corporate_client
        active
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Customer_Location_Sp[]>;
}

export const get_customer_page_details = (input:{customer_id: number}) : Promise<Customer_Details_Info> => {
  let query = gql`
    query($customer_id: Int) {
      get_customer_page_details(customer_id: $customer_id) {
        quoted
        needs_quote
        open_orders
        needs_invoice
        recent_payments
        open_orders
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {customer_id} as 
  Promise<Customer_Details_Info>;
}

export const get_customer_paid_statement_data = (input:{customer_id: number, from_date: string, to_date: string}) : Promise<Master_Order[]> => {
  let query = gql`
    query($customer_id: Int!, $from_date: String!, $to_date: String!) {
      get_customer_paid_statement_data(customer_id: $customer_id, from_date: $from_date, to_date: $to_date) {
        id
        customer {
          id
          name
          key
          terms
          corporate_client
        }
        time
        started
        completed
        price
        payment_left
        terms
        canceled
        contacts {
          id
          name
          email
          contact {
            type
            title
            value
          }
        }
        references {
          key
          value
        }
        delivered
        picked_up
        non_move
        payment_due
        invoices {
          id
          amount
          time_due
        }
        last_invoice_amount
        needs_invoice
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {customer_id, from_date, to_date} as 
  Promise<Master_Order[]>;
}

export const get_customer_open_statement_data = (input:{customer_id: number}) : Promise<Master_Order[]> => {
  let query = gql`
    query($customer_id: Int!) {
      get_customer_open_statement_data(customer_id: $customer_id) {
        id
        customer {
          id
          name
          key
          terms
          corporate_client
        }
        time
        started
        completed
        price
        payment_left
        terms
        canceled
        contacts {
          id
          name
          email
          contact {
            type
            title
            value
          }
        }
        references {
          key
          value
        }
        delivered
        picked_up
        non_move
        payment_due
        invoices {
          id
          amount
          time_due
        }
        last_invoice_amount
        needs_invoice
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {customer_id} as 
  Promise<Master_Order[]>;
}
import { gql } from "apollo-boost";
import React, { FC, useEffect, useRef, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
  Address,
  Customer,
  CustomerProfile,
  CustomerProfileList,
  Leg,
  Location,
  Stop,
  TimeFrame,
  WorkHours,
} from "../../../classes/classes";
import Helper from "../../../helper/Helper";
import { GraphqlService } from "../../../services/graphql.service";
import DateTimePicker from "../../DateTimePicker/DateTimePicker";
import FormGroup from "../../FormGroup/FormGroup";
import Modal from "../../Modal/Modal";
import Searchbar from "../../Searchbar/Searchbar";
import { VehicleItem } from "../OrderSelection";
import PickupCustomer from "../PickupCustomer/PickupCustomer";

import { AutoSizer, List } from "react-virtualized";
import ICONS from "../../../../assets/svg";
import CustomerQueries from "../../../graphql/query/Customer";
import Loading from "../../Loading/Loading";
import ReactModal from "react-modal";
import CustomerProfileForm from "../../CustomerProfileForm/CustomerProfileForm";
import useLoading from "../../../hooks/useLoading/useLoading";
import moment from "moment";
import Scheduled from "./Components/Scheduled";
import TimeFrameDiv from "./Components/TimeFrame";

const queryTimeAtAddress = gql`
  query ($address: address_input, $atTime: String, $format: String) {
    get_time_at_address(address: $address, atTime: $atTime, format: $format) {
      time_formatted
    }
  }
`;

const queryUTCAtAddress = gql`
  query ($address: address_input, $time_string: String!) {
    get_utc_time_at_address(address: $address, time_string: $time_string)
  }
`;

const query = gql`
  query {
    get_customer_locations {
      customer_id
      name
      key
      title
      location_id
      address {
        street
        street_number
        postal_code
        state
        country
        city
      }
      corporate_client
      active
    }
  }
`;
interface IStopLegEntry {
  stop: Stop;
  stopChanged: (newStop: Stop) => any;
  sameAsBillingClicked: (pickup: boolean) => any;
  popupFit?: boolean;
  gold?: boolean;
}

const StopLegEntry: FC<IStopLegEntry> = ({
  stop,
  stopChanged,
  sameAsBillingClicked,
  popupFit,
  gold,
}) => {
  const [stopData, setStopData] = useState(new Stop(stop));
  const pickupContactsRef = useRef();

  const loading = useLoading();
  const [locations, setLocations] = useState<Location[]>([]);

  const [locationSearchShown, setLocationSearchShown] = useState(
    stop.location.customer.id == null
  );

  const [contactsOpen, setContactsOpen] = useState(false);

  const [showCalendar, setShowCalendar] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [locationsOpen, setLocationsOpen] = useState(false);

  const [customerSearch, setCustomerSearch] = useState("");
  const [keySearch, setKeySearch] = useState("");
  const [addressSearch, setAddressSearch] = useState("");
  const [contactSearch, setContactSearch] = useState("");

  const customerSearchRef = useRef<HTMLInputElement>();
  const addressesSearchRef = useRef<HTMLInputElement>();
  const contactsSearchRef = useRef<HTMLInputElement>();
  const keySearchRef = useRef<HTMLInputElement>();

  const [locationsLoading, setLocationsLoading] = useState(false);

  const [contactLoading, setContactLoading] = useState(false);

  const [contactFormOpen, setContactFormOpen] = useState(false);
  const [contacts, setContacts] = useState<CustomerProfileList[]>([]);

  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const toast = useToasts();

  const [contactsSearchShown, setContactsSearchShown] = useState(false);

  const [pickerDate, setPickerDate] = useState(null);

  useEffect(() => {
    if (locationsOpen) {
      setCustomerSearch("");
      setAddressSearch("");
      loadLocations();
    }
  }, [locationsOpen]);

  useEffect(() => {
    if (contactsOpen) {
      setContactSearch("");
      loadContacts();
    }
  }, [contactsOpen]);

  async function loadLocations() {
    setLocationsLoading(true);

    try {
      const data = await GraphqlService.SendQuery(query);

      setLocations(
        data.map(
          (x) =>
            new Location({
              address: new Address(x.address),
              customer: new Customer({
                id: x.customer_id,
                name: x.name,
                key: x.key,
                corporate_client: x.corporate_client,
              }),
              driver_note: "",
              id: x.location_id,
              internal_note: "",
              title: x.title,
              work_hours: new WorkHours(),
            })
        )
      );
      setLocationsLoading(false);
    } catch (error) {
      console.log(error.message);
      setLocationsLoading(false);
    }
  }

  async function loadContacts() {
    try {
      setContactLoading(true);
      const data = await GraphqlService.SendQuery(
        CustomerQueries.GET_ALL_PROFILE_LIST,
        { customerId: stop.location.customer.id }
      );
      const temp = data.map(
        (x) => new CustomerProfileList(x)
      ) as CustomerProfileList[];
      setContacts(temp.sort((x, y) => x.name.localeCompare(y.name)));
      setContactLoading(false);
    } catch (ex) {
      setContactLoading(false);
    }
  }

  function rowContactRenderer({ key, index, style }) {
    const ids = stop.contacts?.map((x) => x.id) || [];
    const myContact = new CustomerProfile(contactsFiltered()[index]);

    return (
      <div id={"contact-" + myContact?.id} key={key} style={style}>
        <div
          className={
            "bg-light position-relative cursor-pointer light-hover" +
            (ids.includes(myContact.id) ? " bg-gold" : "")
          }
          style={{ padding: "10px" }}
          onClick={(evt) => {
            let temp = [...stop.contacts];
            if (!ids.includes(myContact.id)) {
              temp.push(myContact);
            } else {
              temp = temp.removeBy((x) => x.id == myContact.id);
            }
            const myids = temp.map((x) => x.id);
            temp = contacts.filter((x) => myids.includes(x.id));
            // onContactsChanged(temp);
            const newStop = new Stop(stop);
            newStop.contacts = temp;
            stopChanged(newStop);
            contactsSearchRef.current?.focus();
          }}
        >
          {ids.includes(myContact.id) && (
            <i
              className=" fas fa-check"
              style={{ position: "absolute", right: "10px", fontSize: "11px" }}
            ></i>
          )}
          <p style={{ fontSize: "12px" }} className=" font-bold">
            {myContact.name}
          </p>
          <p
            style={{ fontSize: "12px" }}
            className={
              "mt-1 mb-1" + (myContact.title ? "" : " text-gray font-bold")
            }
          >
            {" "}
            {myContact.title ? `${myContact.title}` : "NO TITLE"}
          </p>
          <p style={{ fontSize: "12px", fontWeight: "normal" }}>
            {myContact.email}
          </p>
        </div>
      </div>
    );
  }
  const contactsFiltered = () =>
    contacts.filter((x) =>
      x.name.toLowerCase().includes(contactSearch?.toLowerCase())
    );

  useEffect(() => {
    setStopData(new Stop(stop));

    setLocationSearchShown(stop.location.customer.id == null);

    if (stop.location.customer.id && stop.contacts.length == 0)
      setContactsSearchShown(true);
    else if (
      stop.contacts.length > 0 &&
      contactsSearchRef.current != document.activeElement
    )
      setContactsSearchShown(false);

    if (stop.location.customer.id) {
      loadContacts();
    }
    // setContactsOpen(stop.contacts.length <= 0);
    // setContactsOpen()
  }, [stop]);

  useEffect(() => {
    loadLocations();
  }, []);

  function rowLocationRenderer({ key, index, style }) {
    const myLocation = new Location(locationsFiltered()[index]);

    return (
      <div id={"location-" + myLocation?.id} key={key} style={style}>
        <div
          className={
            "bg-light position-relative cursor-pointer light-hover" +
            (stop.location.id == myLocation.id ? " bg-gold" : "")
          }
          style={{ padding: "10px" }}
          onClick={(evt) => {
            const temp = new Stop(stop);
            temp.location = myLocation;
            temp.contacts = [];

            setLocationSearchShown(false);

            stopChanged(temp);
          }}
        >
          {myLocation.id == stop.location.id && (
            <i
              className=" fas fa-check"
              style={{ position: "absolute", right: "10px", fontSize: "11px" }}
            ></i>
          )}
          <div className="row">
            <p style={{ fontSize: "12px" }} className=" font-bold">
              {myLocation.customer.name}
            </p>
            <p style={{ fontSize: "12px" }} className=" font-bold">
              {myLocation.customer.key}
            </p>
          </div>
          <p className="mt-1 font-bold" style={{ fontSize: "12px" }}>
            {myLocation.title}
          </p>

          <p style={{ fontSize: "12px", fontWeight: "normal" }}>
            {myLocation.address.getName(1)} {myLocation.address.getName(2)}{" "}
            {myLocation.address.country}
          </p>
        </div>
      </div>
    );
  }

  const locationsFiltered = () => {
    const text = customerSearch.toLowerCase().trim();
    const text2 = addressSearch.toLowerCase().trim();
    const text3 = keySearch.toLowerCase().trim();
    return locations.filter(
      (x) =>
        (x.customer.name.trim().toLowerCase().includes(text) ||
          x.customer.key?.trim().toLowerCase().includes(text)) &&
        (`${x.address.street_number} ${x.address.street} ${x.address.city} ${x.address.state} ${x.address.postal_code} $${x.address.country}`
          .toLocaleLowerCase()
          .includes(text2) ||
          x.title.trim().toLowerCase().includes(text2)) &&
        (x.customer.key || "").trim().toLowerCase().includes(text3)
    );
  };

  async function getPickerDate(isSchedule = true) {
    if (!stop.location.customer.id) {
      setPickerDate(
        isSchedule
          ? stop.time_scheduled
          : [stop.time_frame.start, stop.time_frame.end]
      );
      setIsSchedule(isSchedule);
      setShowCalendar(true);
      setShowModal(true);
      return;
    }

    const address = stop.location.address;
    try {
      loading.open("Getting Time...");
      if (isSchedule) {
        const data = await GraphqlService.SendQuery(queryTimeAtAddress, {
          address,
          atTime: stop.time_scheduled,
          format: "YYYY/MM/DD HH24:MI",
        });
        setPickerDate(new Date(data.time_formatted));
      } else {
        const data1 = await GraphqlService.SendQuery(queryTimeAtAddress, {
          address,
          atTime: stop.time_frame.start,
          format: "YYYY/MM/DD HH24:MI",
        });
        const data2 = await GraphqlService.SendQuery(queryTimeAtAddress, {
          address,
          atTime: stop.time_frame.end,
          format: "YYYY/MM/DD HH24:MI",
        });

        setPickerDate([
          new Date(data1.time_formatted),
          new Date(data2.time_formatted),
        ]);
      }
      setIsSchedule(isSchedule);
      setShowCalendar(true);
      setShowModal(true);
      loading.close();
    } catch (ex) {
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
    }
  }

  async function dateSelected(dateData) {
    const temp = new Stop(stopData);

    const address = stop.location.address;

    try {
      if (!stop.location.customer.id) {
        if (Array.isArray(dateData)) {
          temp.time_frame.start = dateData[0];
          temp.time_frame.end = dateData[1];
        } else {
          temp.time_scheduled = dateData;
        }
      } else {
        loading.open("Getting Time...");
        if (Array.isArray(dateData)) {
          const data1 = await GraphqlService.SendQuery(queryUTCAtAddress, {
            address,
            time_string: moment(dateData[0]).format("YYYY/MM/DD HH:mm"),
          });
          const data2 = await GraphqlService.SendQuery(queryUTCAtAddress, {
            address,
            time_string: moment(dateData[1]).format("YYYY/MM/DD HH:mm"),
          });

          temp.time_frame = new TimeFrame({
            start: new Date(data1),
            end: new Date(data2),
          });
        } else {
          const data = await GraphqlService.SendQuery(queryUTCAtAddress, {
            address,
            time_string: moment(dateData).format("YYYY/MM/DD HH:mm"),
          });
          temp.time_scheduled = new Date(data);
        }
        loading.close();
      }

      setStopData(temp);
      setShowModal(false);
      setShowCalendar(false);
      stopChanged(temp);
    } catch (ex) {
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
      loading.close();
    }
  }

  return (
    <div className="col-12 h-100" onClick={(evt) => evt.stopPropagation}>
      {/* <Modal show={showModal && !showCalendar} onClose={() => setShowModal(false)}>
                    <PickupCustomer onConfirmClicked={(newStop) => {
                        setStopData(new Stop(newStop));
                        stopChanged(newStop);
                    }} stop={stopData}
                    onCancelClicked={() => setShowModal(false)}/>
                </Modal> */}
      <div
        className={
          "row col-12 p-2 align-items-start h-100" + (gold ? " bg-gold" : "")
        }
        style={{
          border: `1px solid ${stopData.is_pickup ? "#74C336" : "#C50532"}`,
          borderRadius: 8,
        }}
      >
        <div className="row col-12 align-items-start mb-2">
          <h6 className="font-12 font-normal">
            {stopData.is_pickup ? "Origin" : "Destination"}
          </h6>
          <button
            onClick={(evt) => {
              evt.stopPropagation();
              const temp = new Stop(stopData);
              temp.nofail = !temp.nofail;
              stopChanged(temp);
            }}
            className={`btn btn-blue${stopData.nofail ? "" : "-outline"} col-4`}
          >
            NO FAIL
          </button>
        </div>

        {locationSearchShown && (
          <div
            className="position-relative col-12 mb-2"
            onClick={(evt) => evt.stopPropagation()}
          >
            <div className="col-12 mb-1 row">
              <div className="col-6">
                <Searchbar
                  value={customerSearch}
                  onChange={(evt) => setCustomerSearch(evt.target.value)}
                  onBlur={(evt) =>
                    setTimeout(() => {
                      if (
                        customerSearchRef.current == document.activeElement ||
                        addressesSearchRef.current == document.activeElement ||
                        keySearchRef.current == document.activeElement
                      )
                        return;
                      setLocationsOpen(false);
                      if (stop.location.customer.id)
                        setLocationSearchShown(false);
                    }, 150)
                  }
                  onFocus={(evt) => {
                    setKeySearch("");
                    setAddressSearch("");
                    setLocationsOpen(true);
                  }}
                  reference={customerSearchRef}
                  background={"#f3f3f3"}
                  placeholder="Search Customer..."
                />
              </div>

              <div className="col-6">
                <Searchbar
                  value={keySearch}
                  onChange={(evt) => setKeySearch(evt.target.value)}
                  onBlur={(evt) =>
                    setTimeout(() => {
                      if (
                        customerSearchRef.current == document.activeElement ||
                        addressesSearchRef.current == document.activeElement ||
                        keySearchRef.current == document.activeElement
                      )
                        return;
                      setLocationsOpen(false);
                      if (stop.location.customer.id)
                        setLocationSearchShown(false);
                    }, 150)
                  }
                  onFocus={(evt) => {
                    setCustomerSearch("");
                    setAddressSearch("");
                    setLocationsOpen(true);
                  }}
                  reference={keySearchRef}
                  background={"#f3f3f3"}
                  placeholder="Search Key..."
                />
              </div>
            </div>
            <div className="col-12">
              <Searchbar
                value={addressSearch}
                onChange={(evt) => setAddressSearch(evt.target.value)}
                onBlur={(evt) =>
                  setTimeout(() => {
                    if (
                      customerSearchRef.current == document.activeElement ||
                      addressesSearchRef.current == document.activeElement ||
                      keySearchRef.current == document.activeElement
                    )
                      return;
                    setLocationsOpen(false);
                    if (stop.location.customer.id)
                      setLocationSearchShown(false);
                  }, 150)
                }
                onFocus={(evt) => {
                  setCustomerSearch("");
                  setKeySearch("");
                  setLocationsOpen(true);
                }}
                reference={addressesSearchRef}
                background={"#f3f3f3"}
                placeholder="Search Address..."
              />
            </div>

            {locationsOpen && (
              <div
                onMouseDown={(evt) => {
                  console.log("mousedown");
                  setTimeout(() => {
                    if (keySearch?.trim() != "")
                      return keySearchRef?.current?.focus();
                    if (addressSearch.trim() != "")
                      return addressesSearchRef?.current?.focus();
                    customerSearchRef?.current?.focus();
                  });
                }}
                className="bg-white flex-1-container p-2"
                style={{
                  position: "absolute",
                  height: "45vh",
                  width: popupFit ? "100%" : "30vw",
                  zIndex: 2,
                  boxShadow: "0 3px 6px #00000029",
                  right: stop.is_pickup ? "unset" : "0",
                }}
              >
                <div className="row align-items-center">
                  <button
                    onClick={(evt) => sameAsBillingClicked(stop.is_pickup)}
                    className="btn btn-orange"
                  >
                    SAME AS BILLING
                  </button>
                  <div
                    className="cursor-pointer"
                    onClick={(evt) => setLocationModalOpen(true)}
                    style={{ width: "fit-content" }}
                  >
                    <img src={ICONS.IconPlus} alt="" />
                  </div>
                </div>
                {locationsLoading && (
                  <div>
                    <Loading />
                    <h6 className="font-11 text-center">
                      Loading Locations...
                    </h6>
                  </div>
                )}
                {!locationsLoading && (
                  <div className="flex-1-container">
                    <AutoSizer>
                      {({ height, width }) => (
                        <List
                          height={height}
                          rowCount={locationsFiltered().length}
                          rowHeight={({ index }) => {
                            return 80;
                          }}
                          rowRenderer={rowLocationRenderer}
                          width={width}
                        />
                      )}
                    </AutoSizer>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {stop.location.customer.id && (
          <div
            className={"cursor-pointer col-12 px-1 mb-2"}
            onClick={(evt) => {
              evt.stopPropagation();
              setLocationSearchShown(true);
              setTimeout(() => {
                customerSearchRef.current?.focus();
              });
            }}
          >
            <div className="row">
              <p className="font-10 font-medium">
                {stopData.location.customer.name}
              </p>
              <p className="font-10">{stopData.location.customer.key}</p>
            </div>
            <p className="font-10">
              {stopData.location.address.getName(1)}{" "}
              {stopData.location.address.getName(2)}
              {stopData.location.address.country.toLocaleLowerCase() ==
              "united states"
                ? ""
                : stopData.location.address.country}
            </p>
          </div>
        )}

        {stop.location.customer.id && (
          <div
            className={"cursor-pointer col-12 px-1 mb-2"}
            onClick={(evt) => {
              evt.stopPropagation();
              setContactsSearchShown(true);
              setTimeout(() => {
                contactsSearchRef.current?.focus();
              });
            }}
          >
            {
              <div className="">
                <p className="font-10 ">Contacts</p>
                <p className="font-10 font-medium">
                  {stopData.contacts.map((x) => x.name).join(", ")}
                </p>
              </div>
            }
          </div>
        )}

        {contactsSearchShown && (
          <div className="mb-2 col-12">
            <div style={{ position: "relative" }}>
              <Searchbar
                value={contactSearch}
                onChange={(evt) => setContactSearch(evt.target.value)}
                onFocus={(evt) => setContactsOpen(true)}
                onBlur={(evt) => {
                  setTimeout(() => {
                    if (contactsSearchRef?.current == document.activeElement)
                      return;
                    setContactsOpen(false);

                    if (stop.contacts.length > 0) setContactsSearchShown(false);
                  }, 150);
                }}
                background={"#f3f3f3"}
                reference={contactsSearchRef}
              />
              {contactsOpen && (
                <div
                  onMouseDown={(evt) => {
                    console.log("here???");
                    setTimeout(() => {
                      contactsSearchRef?.current?.focus();
                    });
                  }}
                  className="bg-white flex-1-container p-2"
                  style={{
                    position: "absolute",
                    height: "45vh",
                    width: popupFit ? "100%" : "30vw",
                    zIndex: 2,
                    boxShadow: "0 3px 6px #00000029",
                    right: stop.is_pickup ? "unset" : "0",
                  }}
                >
                  <div
                    className="ml-auto cursor-pointer"
                    onClick={() => setContactFormOpen(true)}
                    style={{ width: "fit-content" }}
                  >
                    <img src={ICONS.IconPlus} alt="" />
                  </div>
                  <div className="flex-1-container">
                    <AutoSizer>
                      {({ height, width }) => (
                        <List
                          height={height}
                          rowCount={contactsFiltered().length}
                          rowHeight={({ index }) => {
                            const contact = contactsFiltered()[index];
                            if (!contact) return 100;
                            return contact.email ? 86 : 74;
                          }}
                          rowRenderer={rowContactRenderer}
                          width={width}
                        />
                      )}
                    </AutoSizer>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="col-12 row cursor-pointer">
          <div className="card col-6 m-0">
            <div
              className="p-1 "
              onClick={(evt) => {
                // setIsSchedule(true);
                // setShowCalendar(true);
                // setShowModal(true);
                getPickerDate(false).then();
              }}
            >
              {/* <p className="font-12 mb-1">Instructions for the location</p>
                                <p className="font-8 ml-2">...</p> */}

              <div className="row mb-1">
                <p className="font-10 font-medium">Time Frame</p>
                <button className="btn btn-blue-light">EDIT</button>
              </div>
              <TimeFrameDiv stop={stop} />
            </div>
          </div>
          <div className="card col-6 m-0">
            <div className="p-0 p-1 m-0">
              <div className="row col-12">
                <div
                  className="col-12"
                  onClick={(evt) => {
                    // setIsSchedule(true);
                    // setShowCalendar(true);
                    // setShowModal(true);
                    getPickerDate().then();
                  }}
                >
                  <div className="row">
                    <p className="font-10 font-medium mb-1">Scheduled</p>
                    <button className="btn btn-blue-light">EDIT</button>
                  </div>
                  <Scheduled stop={stop} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showCalendar && (
        <DateTimePicker
          date={pickerDate}
          onCancelClicked={(evt) => {
            setShowCalendar(false);
            setShowModal(false);
          }}
          onDateSubmitted={(dateData) => {
            dateSelected(dateData);
          }}
        />
      )}

      <ReactModal
        isOpen={contactFormOpen}
        onRequestClose={() => setContactFormOpen(false)}
        className={"modal-fit modal-90"}
        //style={{content: {borderRadius: '12px', width: '496px', paddingBottom: '0', minWidth: '496px'}}}
      >
        <CustomerProfileForm
          customerId={stop.location.customer.id}
          customerProfile={null}
          onSubmit={(customerProfile) => {
            let temp = [...stop.contacts];

            if (customerProfile) {
              let index = temp.findIndex((x) => x.id == customerProfile.id);
              if (index === -1) {
                temp.push(customerProfile);
              } else {
                temp[index] = customerProfile;
              }

              const newStop = new Stop(stop);
              newStop.contacts = temp;

              stopChanged(newStop);

              setContactFormOpen(false);
            }
            setContactFormOpen(false);
          }}
        />
      </ReactModal>

      <ReactModal isOpen={locationModalOpen} className={"modal-large"}>
        <PickupCustomer
          stop={new Stop()}
          onCancelClicked={(evt) => setLocationModalOpen(false)}
          onConfirmClicked={(data) => {
            setLocationModalOpen(false);
            const newStop = new Stop(stop);
            newStop.contacts = data.contacts;
            newStop.location = data.location;

            stopChanged(newStop);
          }}
        />
      </ReactModal>
    </div>
  );
};

export default StopLegEntry;

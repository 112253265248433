import {gql} from "apollo-boost";

const GET_ALL = gql`query {
    get_user_settings {
        driver_view_order {
            carrier_id
            order {
                id
            }
        }
    }
}`;

const UserSettingsQueries = {
    GET_ALL
}

export default UserSettingsQueries;
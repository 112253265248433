import React,{useState,useRef, useEffect} from 'react'
import './InventoryPage.scss'
import CarriersDriversTable from "../../components/CarriersDriversTable/CarriersDriversTable";
import IColumn from '../../components/Table/models/IColumn';
import Searchbar from '../../components/Searchbar/Searchbar';
import ICONS from '../../../assets/svg';
import Table from '../../components/Table/Table';
import {gql} from 'apollo-boost';
import { GraphqlService } from '../../services/graphql.service';
import columns from './TableColumns';
import IMenuOption from '../../components/Table/models/IMenuOption';
import ReactModal from 'react-modal';
import RequestInspectionForm from '../../components/LegTable/RequestInspection/RequestInspection';
import Helper from '../../helper/Helper';
import StorageLogs from '../../components/StorageLogs/StorageLogs';
import { useToasts } from 'react-toast-notifications';
import useMessageBox from '../../hooks/useMessageBox/useMessageBox';

class RequestInpsection
{
    id: number = null;
    vehicle_order_id: number = null;
    type: string = '';
    phones: string[] = [];
    emails: string[] = [];

    constructor(props?: RequestInpsection)
    {
        if (props)
        {
            this.id = props.id;
            this.vehicle_order_id = props.vehicle_order_id;
            this.type = props.type;
            this.phones = [...props.phones];
            this.emails = [...props.emails];
        }
    }


    isValid()
    {
        if (!this.vehicle_order_id) return false;

        if (this.type.trim() == '') return false;

        if (this.phones.length == 0 && this.emails.length == 0) return false;

        if (this.phones.length > 0 && this.phones.some(x => x == '' || !Helper.Validators.IsValidUSPhone(x)))
            return false;

        if (this.emails.length > 0 && this.emails.some(x => x == '' || !Helper.Validators.IsValidEmail(x)))
            return false;

            
        return true;
    }
}

const pendingInspectionsQuery = gql`
    query{
  get_pending_inspections{
      id
    user{
      id
      name
    }
    type
    time
    vehicle_order{
      id
      time
      vehicle{
        id
        vin
        year
        make
        model
        submodel
      }
      master_order{
        id
        customer{
          name
          key
          corporate_client
          terms
        }
      }
    }
  }
}

`;


const query = gql`
    query($open:Boolean, $warehouse_id: Int){
  get_storage_orders(open:$open, warehouse_id:$warehouse_id){
    id
    started
    delivered
    vehicle{
      id
      vin
      year
      make
      model

    }
    master_order {
        id
        payment_left
        last_invoice_amount
        customer {
            name
            key
        }
    }
    vehicle {
        id
        vin
        year
        make
        model
    }
    storage_order{
      id
      vehicle_order_id
      billing_cycle
      rate
      note
      departure_date
      date_last_issued
      warehouse {
          
          name

      }
      storage_charges {
          id
          price
      }

    }
    service_charges{
      id
      amount
      name
      storage_charge{
        id
        period_end
        period_start
        price
      }
    }
  }
}
`;



const PendingInspectionsColumns: IColumn[] = ['MASTER', 'VEHICLE_ORDER', 'CUSTOMER', 'VEHICLE', 'TYPE', 'USER']
                                  .map(x => ({
                                    active: false,
                                    label: x,
                                    name: x.toLowerCase(),
                                    orderBy: 'ASC', 
                                }))

enum InventoryStatus {
    OPEN,
    CLOSED,
    PENDING_INSPECTION
}



const InventoryPage =()=>{

    const ref = useRef();

    const toast = useToasts();
    const messageBox = useMessageBox();

    const [logsOpened, setLogsOpened] = useState(false);
    

    const [requestInspection, setRequestInspection] = useState(new RequestInpsection());

    const [loadingOpen, setLoadingOpen] = useState(false);
    const [loadingClosed, setLoadingClosed] = useState(false);
    const [loadingPendingInspections, setLoadingPendingInspections] = useState(false);

    const [tab, setTab] = useState(InventoryStatus.OPEN);
    const [openInventory, setOpenInventory] = useState([]);
    const [inventoryClosed, setInventoryClosed] = useState([]);
    const [pendingInspections, setPendingInspections] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');


    const inventoryStatuses = [
        {
            label: 'Open/Incoming Storage',
            value: InventoryStatus.OPEN,
            key: 'open'
        },
        {
            label: 'Completed Storage',   
            value: InventoryStatus.CLOSED,
            key: 'close'
        },
        {
            label: 'Pending Inspection',   
            value: InventoryStatus.PENDING_INSPECTION,
            key: 'pending_inspection'
        }
    ];
    

    const menuOptions: IMenuOption[] = [
        {
            label: 'See Order Details' ,
            icon: ICONS.IconDetails,
            action: (evt: any) => {
                Helper.Navigation.NavigateTo(`/order/${evt.master_order_id}`)
            }
         },
        {
           label: 'Resend Inspection Request' ,
           icon: ICONS.IconEmail,
           condition: x => tab == InventoryStatus.PENDING_INSPECTION,
           action: (evt: any) => {
               const temp = new RequestInpsection();
               temp.id = evt.id;
               setRequestInspection(temp);
           }
        },
        {
           label: 'Cancel Inspection Request' ,
           icon: ICONS.IconDelete,
           condition: x => tab == InventoryStatus.PENDING_INSPECTION,
           action: (evt: any) => {
               console.log(evt)
               cancelInspection(evt.id);
           }
        }
    ];

    function cancelInspection(inspectionID: number){
        try {messageBox.open({
            title: 'Are you sure?',
            message: `Cancel inspection "${inspectionID}", this action can't be reverted!`,
            buttons: [{
                text: 'Cancel',
                css: 'btn btn-clear text-light-blue'
            }, {
                text: 'Delete',
                css: 'btn btn-danger-light',
                action: async () => {
                    toast.addToast('Not implemented yet', {appearance: 'error', autoDismiss: true});
                }
            }]

        })
        } catch (error) {
            toast.addToast(error.message, {appearance: 'error', autoDismiss: true});
        }
    }

    function getBillingStatus(master) {
        //         payment_left
        // last_invoice_amount
        if (master.payment_left > 0 && master.payment_left != master.last_invoice_amount)
            return 'NEEDS INVOICE';
        return '';
    }

    async function loadPendingInspections(){

        

        try {
            setLoadingPendingInspections(true);
            const data = await GraphqlService.SendQuery(pendingInspectionsQuery);
            setPendingInspections(data.map(x => ({
                id: x.id,
                master: ['M-' + x.vehicle_order?.master_order?.id],
                vehicle_order: ['VO-' + x.vehicle_order?.id],
                customer: [x.vehicle_order.master_order?.customer?.name || '-', x.vehicle_order.master_order?.customer.corporate_client ? 'Corporate' : 'Private', x.vehicle_order.master_order?.customer.key],
                vehicle: [x.vehicle_order.vehicle.vin || '-', x.vehicle_order.vehicle.year + ' ' + x.vehicle_order.vehicle.make, x.vehicle_order.vehicle.model],
                
                type: [x.type],
                user: [x.user?.name || '-'],
                master_order_id: x.vehicle_order?.master_order?.id
            })))
            setLoadingPendingInspections(false);
            
        } catch (ex) {
            console.log('ex', ex.message);
            setLoadingPendingInspections(false);
        }
    }



    async function load(open) {

        const dataMapped = data => data.map(x => ({
            storage_id: 'S-' + x.storage_order.id,
            order_id: 'VH-' + x.id,
            master_column: x.master_order?.id,
            master_order_id: x.master_order?.id,
            customer: [x.master_order?.customer?.name || '-'],
            _vehicle: [x.vehicle.vin || '-', x.vehicle.year + ' ' + x.vehicle.make, x.vehicle.model],
            billing_cycle: x.storage_order.billing_cycle,
            rate: x.storage_order.rate,
            rate_column: x.storage_order.rate.toCurrency(),
            location: <p className='font-medium'>
                {x.storage_order?.warehouse?.name || '-'}
                <span className={`text-${x.storage_order?.date_last_issued ? 'red' : 'green'} d-block mt-1`}>{x.storage_order?.date_last_issued ? 'IN STORAGE' : 'INCOMING'}</span>
            </p>,
            status: getBillingStatus(x.master_order)
        }))
        try {

            if (open)
            {
                setLoadingOpen(true);
                const data = await GraphqlService.SendQuery(query, {open});
                setOpenInventory(dataMapped(data));
                setLoadingOpen(false);
            }
            else
            {
                setLoadingClosed(true);
                const data = await GraphqlService.SendQuery(query, {open});
                setInventoryClosed(dataMapped(data));
                setLoadingClosed(false);
            }
            
            
        } catch (ex) {
            console.log('error', ex.message)
            if (open)
                setLoadingOpen(false);
            else 
                setLoadingClosed(false);
            
            
        }

    }


    const getData = key => key == 'open' ? openInventory : (key == 'close' ? inventoryClosed : pendingInspections);


    useEffect(() => {
        if (tab != InventoryStatus.PENDING_INSPECTION)
            load(tab == InventoryStatus.OPEN);
        else
            loadPendingInspections()
    }, [tab]);


    useEffect(() => {
        // load(true);
        load(false);
        loadPendingInspections();
    }, []);


    const getDataFiltered = data => data.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim()));

    const getTextHeader = () => {
        const key = tab == InventoryStatus.OPEN ? 'open' : (tab == InventoryStatus.CLOSED ? 'close' : 'pending_inspections');
        const data = getData(key);

        const length1 = getDataFiltered(data).length;
        const length2 = data.length;
        

        return `${key == 'open' ? 'Open Inventory' : (key == 'close' ? 'Close Inventory' : 'Pending Inspections')} ${length1} / ${length2}`;
    }


    return(
        <>
            <div className='flex-1-container inventory-page'>
                <div className="row mb-3">
                <div className="d-flex">
                    {
                        inventoryStatuses.map((inv) => <h4 onClick={(evt) => setTab(inv.value)} className={'font-10 transition-3-all-ease cursor-pointer mr-3 text-' + (tab == inv.value ? 'black' : 'gray')}>{inv.label} ({getData(inv.key).length})</h4>)
                    }
                </div>
                <button onClick={(evt) => setLogsOpened(true)} className="btn btn-outline mr-2 rounded-pill d-flex align-items-center"><img className='mr-0-5' src={ICONS.IconClock} alt="" /> SEE LOGS</button>
                </div>
                <div className="flex-1-container bg-white rounded p-3">
                    <div className='row col-12 mb-2'>
                        <div className='col-6'>
                            <h4 className="font-10">{getTextHeader()}</h4>
                        </div>
                        <div className="col-4 d-flex align-items-center">
                            <button onClick={(evt) => {
                                if (tab != InventoryStatus.PENDING_INSPECTION)
                                load(tab == InventoryStatus.OPEN);
                                else
                                    loadPendingInspections()
                            }} className={'btn btn-icon-only mr-2 ' + (loadingOpen || loadingClosed ? 'spin' : '')}><img src={ICONS.IconRefresh} alt="" /></button>
                            <Searchbar value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)} background={'#F3F6F8'} className='col-12' reference={ref} placeholder='Search...' />
                        </div>
                    </div>

                    <div className="flex-1-container">
                        {
                            tab == InventoryStatus.OPEN  &&
                                <Table menuOptions={menuOptions} loading={loadingOpen} columns={columns} rows={getDataFiltered(openInventory)} onRowClicked={(evt) => false} />
                        }
                         { tab == InventoryStatus.CLOSED &&       <Table menuOptions={menuOptions} loading={loadingClosed} columns={columns} rows={getDataFiltered(inventoryClosed)} onRowClicked={(evt) => false} /> }

                         { tab == InventoryStatus.PENDING_INSPECTION &&       <Table menuOptions={menuOptions} loading={loadingPendingInspections} columns={PendingInspectionsColumns} rows={getDataFiltered(pendingInspections)} onRowClicked={(evt) => false} /> }
                    </div>
                </div>
            </div>

            <ReactModal isOpen={requestInspection.id != null}>
                <RequestInspectionForm data={requestInspection} onClose={(evt) => {
                    setRequestInspection(new RequestInpsection());
                }} />
            </ReactModal>

            <ReactModal isOpen={logsOpened}>
            <StorageLogs onClose={(evt) => setLogsOpened(false)} />
        </ReactModal>
        </>
    )
}

export default InventoryPage

import React, { useRef } from 'react';
import Searchbar from '../../../../components/Searchbar/Searchbar';
import CustomerContacts from '../CustomerContacts/CustomerContacts';


const CustomerCard = () => {

    const customerRef = useRef();


    return <div className='p-3 rounded bg-white'>
        <h4 className="font-11 mb-2">Customer</h4>
        
        <Searchbar placeholder={'Search customer...'} background={'#F3F6F8'} value={''} reference={customerRef} />

        {/* CUSTOMER INFO */}
        <div className="row mt-2-5">
            
            <div className="row col-12">
                <h6 className='font-10'>BMW Beverly Hills</h6>
                <h6 className="font-10 text-blue">BWM</h6>
            </div>

            <div className="col-12 mt-1">
                <p className="font-9">1000 Main St</p>
                <p className="font-9">Beverly Hills, CA 90000</p>
            </div>
        </div>

        <div className="mt-2-5">
            <CustomerContacts />
        </div>
    </div>
}

export default CustomerCard;
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Helper from '../../helper/Helper';
import { setLastID } from '../../redux/actions/Notifications.action';
import { AllStates } from '../../redux/reducers';
import { INotifications } from '../../redux/reducers/Notifications.reducer';

const NotificationsPage = () => {


    const {data} = useSelector<AllStates>(state => state.notifications) as INotifications;

    const dispatch = useDispatch();

    useEffect(() => {
        const last = data != null ? data[0]?.id || 0 : 0;
        dispatch(setLastID(last));
    }, [data]);

    return <div className='flex-1-container'>
        
        <h4 className='font-14 mb-3'>Notifications</h4>

        <div className='bg-white mx-auto border flex-1-container' style={{maxHeight: '100%', overflow: 'auto', maxWidth: '800px', width: '90%'}}>
            {(data || []).map((x, i) =>
                <div key={'not-' + i} className='p-3 border-bottom bg-light cursor-pointer'>
                    <div className="d-flex">
                        <div>

                            <h4 className='font-14 mb-1'>{x?.title || i}</h4>
                            <p className='font-11 mb-2'>{x?.message}</p>
                            <p className='font-9 text-gray'>{Helper.FORMAT.USDate(x?.time, 'do')} {Helper.FORMAT.USDate(x?.time, 'to')}</p>
                        </div>
                    </div>
                </div>)}
        </div>

    </div>
}

export default NotificationsPage;
export class PointClass{
    id?: any;
    lat: number | string = 0;
    lng: number | string = 0;

    constructor(props?: PointClass) {
        if(props){
            this.id = props.id;
            this.lat = props.lat;
            this.lng = props.lng;
        }
    }
}

import {Driver, DriverLocation} from "../../classes/classes";
import DriverQueries from "../../graphql/query/Driver";
import { GraphqlService } from "../../services/graphql.service";
import DriverTypes from "../types/Driver.type"


const fetchVeloxDriverRequest = () => {
    return {
        type: DriverTypes.FETCH_ALL_VELOX_DRIVERS_REQUEST
    }
}

const fetchDriverRequest = () => {
    return {
        type: DriverTypes.FETCH_ALL_DRIVERS_REQUEST
    }
}

const fetchDriverSuccess = (drivers: Driver[]) => {
    return {
        type: DriverTypes.FETCH_ALL_DRIVERS_SUCCESS,
        payload: drivers
    }
}

const fetchVeloxDriverSuccess = (drivers: Driver[]) => {
    return {
        type: DriverTypes.FETCH_ALL_VELOX_DRIVERS_SUCCESS,
        payload: drivers
    }
}

const fetchVeloxDriverFailure = (error: string) => {
    return {
        type: DriverTypes.FETCH_ALL_VELOX_DRIVERS_FAILURE,
        payload: error
    }
}

const fetchDriverFailure = (error: string) => {
    return {
        type: DriverTypes.FETCH_ALL_DRIVERS_FAILURE,
        payload: error
    }
}

export const getDrivers = () => {
    return async dispatch => {
        dispatch(fetchDriverRequest());
        GraphqlService.SendQuery(DriverQueries.GET_ALL_DRIVERS).then(drivers => {
            dispatch(fetchDriverSuccess(drivers));
        }).catch(error => {
            dispatch(fetchDriverFailure(error));
        });
    }
}

export const getVeloxDrivers = () => {
    return async dispatch => {
        dispatch(fetchVeloxDriverRequest());
        GraphqlService.SendQuery(DriverQueries.GET_ALL_VELOX_DRIVERS).then(drivers => {
            dispatch(fetchVeloxDriverSuccess(drivers));
        }).catch(error => {
            dispatch(fetchVeloxDriverFailure(error));
        });
    }
}

export function getDriverLocations(carrier_id) {
    return async dispatch => {
        dispatch(getDriverLocationsStarted());
        try {
            let data = await GraphqlService.SendQuery(DriverQueries.GET_ALL_LIST, {carrier_id});
            dispatch(getDriverLocationsSuccess([]));
            dispatch(getDriverLocationsSuccess(data.map(x => new DriverLocation(x))));
        } catch (ex) {
            dispatch(getDriverLocationsError(ex.message));
        }
    }
}

const getDriverLocationsStarted = () => ({
    type: DriverTypes.GET_ALL_DRIVER_LOCATIONS_STARTED,
});

const getDriverLocationsSuccess = (data) => ({
    type: DriverTypes.GET_ALL_DRIVER_LOCATIONS_SUCCESS,
    payload: data
});

const getDriverLocationsError = (error) => ({
    type: DriverTypes.GET_ALL_DRIVER_LOCATIONS_ERROR,
    payload: error
});

export function clearGetAllDriverListError()
{
    return dispatch => dispatch(getDriverLocationsError(null));
}

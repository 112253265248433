import {Attribute} from "../attribute";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";

export const attributes_by_customerId = (input:{customer_id: number}) : Promise<Attribute[]> => {
    let cardQuery = gql`query($customer_id: Int){
        attributes_by_customerId(customer_id:$customer_id){
            id
            cardholder_name
            number
            type
            expired
        }
    }`;
    return GraphqlService.SendQuery(cardQuery, input) as 
  Promise<Attribute[]>;
}

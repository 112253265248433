import { Card } from "../../../classes/classes";

class TransportationPayment {
    id: number = null;
    name: string = '';
    amount_to_charge: number = 0;

    constructor(props?: TransportationPayment) {
        if (props)
        {
            this.id = props.id;
            this.name = props.name;
            this.amount_to_charge = props.amount_to_charge;
        }
    }
}

class ServicePayment {
    service_charge_id: number = null;
    name: string = '';
    amount_to_charge: number = 0;

    constructor(props?: ServicePayment) {
        if (props)
        {
            this.service_charge_id = props.service_charge_id;
            this.name = props.name;
            this.amount_to_charge = props.amount_to_charge;
        }
    }
}

export class PaymentSelecction
{
    transportation_payments: TransportationPayment[] = [];
    service_payments: ServicePayment[] = [];
    totalToCharge: number = 0;

    constructor(props?: PaymentSelecction) {
        if (props)
        {
            this.totalToCharge = props.totalToCharge;
            this.service_payments = props.service_payments.map(x => new ServicePayment(x));
            this.transportation_payments = props.transportation_payments.map(x => new TransportationPayment(x));
        }
    }
}

export class PaymentInfo
{
    id: number = null;
    type: string = 'CREDIT CARD';
    mastersID: number[] = [];
    amount_to_pay: string = '';
    payment_left: string = '';
    cardInfo: Card = new Card();
    cardModalOpened: boolean = false;
    notes: string = '';
    customer_id: number = null;
    bank_account_id: number = null;
    credit_id: number = null;
    paymentPickerOpened: boolean = false;
    paymentSelections: PaymentSelecction = new PaymentSelecction();
    time_paid: Date = new Date();
    reference: string = '';

    constructor(props?: PaymentInfo) {
        if (props)
        {
            this.id = props.id;
            this.type = props.type;
            this.mastersID = [...props.mastersID];
            this.amount_to_pay = props.amount_to_pay;
            this.payment_left = props.payment_left;
            if (props.cardInfo)
                this.cardInfo = new Card(props.cardInfo);
            this.cardModalOpened = props.cardModalOpened;
            this.notes = props.notes;
            this.customer_id = props.customer_id;
            this.bank_account_id = props.bank_account_id;
            this.credit_id = props.credit_id;
            this.paymentPickerOpened = props.paymentPickerOpened;
            this.paymentSelections = new PaymentSelecction(props.paymentSelections);
            this.time_paid = new Date(props.time_paid);
            this.reference = props.reference;
        }
    }
}

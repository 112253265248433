import gql from "graphql-tag";

const GET_ALL_DRIVERS = gql(`
query {
  get_drivers {
    id
    name
    email
    phones
    velox_id
    veloxProfile{
        id
        name
        username
        equipment
        contacts {
            title
            type
            value
        }
    }
    equipment {
        id
    }
    samsara_truck {
        id
    }
    employee {
      id
      name
    }
  }
} 
`);

const GET_ALL_VELOX_DRIVERS = gql(`
query {
  get_velox_drivers_by_carrier {
    id
    name
    username
    equipment
    contacts {
         title
         type
         value
      }
   }
}
`);

/**
 * GET ALL DRIVER LOCATIONS
 * @param none This does not need any param
 */
const GET_ALL_LIST = gql`
    query($carrier_id: Int){
        get_driver_locations(carrier_id:$carrier_id){
            id
            samsara_truck_id
            name
            devices{
                time
                speed
                course
                device
                altitude
                coordinate{
                    Latitude
                    Longitude
                }
                device_info{
                    name
                    type
                    idiom
                    model
                    version
                    platform
                    manufacturer
                }
                background_mode
            }
            username
        }


        get_trucks_location{
            id
            name
            location {
                time
                latitude
                longitude
                heading
                speed
                reverseGeo {
                    formattedLocation
                }
            }
        }

    }
`;

const GET_DRIVER_SCHEDULE = gql`
    query($driver_id: Int) {
        get_driver_schedule(driver_id: $driver_id)
        {
            id
            name
            price
            carrier
            carrier_id
            equipment
            username
            contacts {
                title
                type
                value
            }
            status {
                text
                text_color
                background_color
                border_color
            }
            itinerary {
                address
                orders {
                    id
                    address
                    timezoneName

                    pending_change_request
                    change_requests{
                            id
                            order_id
                            accepted
                            rejected
                            time
                            payout
                            note
                            active
                            expired
                            expires_at
                            weblink
                            code
                        }
                    vehicle {
                        id
                        vin
                        year
                        make
                        model
                        submodel
                        type
                        color
                    }
                    order_id
                    is_pickup
                    scheduled
                    completed
                    nofail
                    contacts {
                        name
                        value
                    }
                    
                }
            }
        }
    }
`;

const GET_BY_CARRIER_ID = gql`
    query($carrier_id: Int) {
        get_carrier_drivers_schedule(carrier_id: $carrier_id) {
            driver {
                id
            name
            username
            price
            carrier
            carrier_id
            equipment
            samsara_truck_id
            contacts {
                title
                type
                value
            }
            status {
                text
                text_color
                background_color
                border_color
            }
            itinerary {
                
                address
                orders {
                    address
                    timezoneName
                    id
                    pending_change_request
                    change_requests{
                            id
                            order_id
                            accepted
                            rejected
                            time
                            payout
                            note
                            active
                            expired
                            expires_at
                            weblink
                            code
                        }
                    vehicle {
                        id
                        vin
                        year
                        make
                        model
                        submodel
                        type
                        color
                    }
                    order_id
                    is_pickup
                    scheduled
                    completed
                    nofail
                    contacts {
                        name
                        value
                    }
                }
            }
            }
        }
    }
`

const GET_DETAILS_BY_TRUCK_ID = gql`
    query($truckId:String) {
        get_truck_location_byId(truckId: $truckId) {
            id
            name
            image_url
            location {
                time
                latitude
                longitude
                heading
                speed
                reverseGeo {
                    formattedLocation
                }
            }
            fuel_percentage
        }
    }
`;

const CHECK_USERNAME = gql`
    query($username:String){
        get_valid_driver_username(username:$username){
            success
            message
        }
    }
`

const GET_DRIVER_BY_VELOX_ID = gql`
    query($velox_id:Int){
        get_driver_by_velox_id(velox_id:$velox_id){
            id
            name
            username
            equipment
            carrier_code
            photos{
                uid
                name
            }
            contacts{
                title
                type
                value
            }
        }
    }
`

const DriverQueries = {
    GET_ALL_LIST,
    GET_ALL_DRIVERS,
    GET_ALL_VELOX_DRIVERS,
    GET_BY_CARRIER_ID,
    GET_DETAILS_BY_TRUCK_ID,
    GET_DRIVER_SCHEDULE,
    CHECK_USERNAME,
    GET_DRIVER_BY_VELOX_ID
}

export default DriverQueries;

import {Employee} from "../../classes/classes";
import IReducerAction from "../models/IReducerAction";
import employeeTypes from "../types/employee.types";

export interface IEmployeeState {
    employees: Employee[];
    loading: boolean;
    error: string;
}

const initialState = {
    employees: [],
    loading: false,
    error: '',
}

export default function EmployeeReducer(state: IEmployeeState = initialState, action: IReducerAction): IEmployeeState {
    switch (action.type) {
        case employeeTypes.FETCH_ALL_EMPLOYEES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case employeeTypes.FETCH_ALL_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                employees: action.payload
            };
        case employeeTypes.FETCH_ALL_EMPLOYEES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
import React, {useEffect, useRef, useState} from 'react';
import ICONS from '../../../assets/svg';
import LegQueries from '../../graphql/query/Leg';
import Helper from '../../helper/Helper';
import {GraphqlService} from '../../services/graphql.service';
import Modal from 'react-modal';
import Searchbar from '../Searchbar/Searchbar';
import Table from '../Table/Table';
import DispatchFilters from '../DispatchFilters/DispatchFilters';
import IMenuOption from '../Table/models/IMenuOption';
import DriverCardJob from '../DriverCardJob/DriverCardJob';
import Loading from '../Loading/Loading';
import DispatchMutations from '../../graphql/mutation/Dispatch';
import FormGroup from '../FormGroup/FormGroup';
import {useToasts} from 'react-toast-notifications';
import moment from 'moment';
import LegDetail from "../LegDetail/LegDetail";
import environments from '../../../environments/environments';
import Swal from 'sweetalert2';
import gql from 'graphql-tag';
import './LegTable.scss';
import {Leg, ServiceCharge, Stop, Vehicle} from '../../classes/classes';
import SplitLegs from '../SplitLegs/SplitLegs';
import LegForm from '../LegForm/LegForm';
import SplitRoundtripLeg from '../SplitRoundtripLeg/SplitRoundtripLeg';
import useLoading from '../../hooks/useLoading/useLoading';
import Status from '../Status/Status';
import './LegTable.scss';
import ReactModal from 'react-modal';
import RequestInspectionForm from './RequestInspection/RequestInspection';
import DispatchLeg from '../DispatchLeg/DispatchLeg';
import momentTZ from 'moment-timezone';


class RequestInpsection
{
    vehicle_order_id: number = null;
    type: string = '';
    phones: string[] = [];
    emails: string[] = [];

    constructor(props?: RequestInpsection)
    {
        if (props)
        {
            this.vehicle_order_id = props.vehicle_order_id;
            this.type = props.type;
            this.phones = [...props.phones];
            this.emails = [...props.emails];
        }
    }


    isValid()
    {
        if (!this.vehicle_order_id) return false;

        if (this.phones.length == 0 || this.emails.length == 0) return false;

        return (this.phones.some(x => !Helper.Validators.IsValidUSPhone(x)) && this.phones.some(x => !Helper.Validators.IsValidUSPhone(x)))
    }
}

interface ILegTable {
    onVehicleOrderSelected: (data: { id: number, leg_id: number }) => void,
    vehicleOrderSelected: { id: number, leg_id: number },
    onTableRefreshed: () => void,
    legID: number,
    fullTable?: boolean,
    tableHeight?: string,
    showFilters?: boolean,
    showToggles?: boolean,
    showLegHistory?: boolean,
    refresh,
    onUndispatchLeg,
    customerID?: number
}

const DateFormatString = 'MM/DD/YYYY';

class LocationFilterClass{
    cities: string[] = [];
    states: string[] = [];
    months: string[] = [];
    dates: string[] = [];

    constructor(props?: LocationFilterClass)
    {
        if (props)
        {
            this.cities = [...props.cities];
            this.states = [...props.states];
            this.months = [...props.months];
            this.dates = [...props.dates];
        }
    }
  }

class FilterClass{
    origin = new LocationFilterClass();
    destination = new LocationFilterClass();

    constructor(props?: FilterClass){
        if(props){
            this.origin = new LocationFilterClass(props.origin);
            this.destination = new LocationFilterClass(props.destination);
        }
    }
}

const LegTable = ({
                      vehicleOrderSelected,
                      onVehicleOrderSelected,
                      onTableRefreshed,
                      legID,
                      tableHeight,
                      fullTable = true,
                      showFilters = true,
                      showToggles = true,
                      showLegHistory = false,
                      refresh,
                      onUndispatchLeg,
                      customerID = null
                  }: ILegTable) => {
    enum DispatchStatuses {
        DISPATCHED,
        NOT_DISPATCHED,
        PENDING,
        NONE
    }

    const [dispatching, setDispatching] = useState(false);
    const [selectedLeg, setSelectedLeg] = useState(undefined);
    const [splitOpen, setSplitOpen] = useState(false);
    const toast = useToasts();

    const _loading = useLoading();
    const [filterData, setFilterData] = useState(new FilterClass());
    const [loading, setLoading] = useState(false);
    const [legs, setLegs] = useState([]);
    const originallegs = useRef([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [dispatchStatus, setDispatchStatus] = useState(DispatchStatuses.NOT_DISPATCHED);
    const [filtersOpened, setFiltersOpened] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);
    const [legModalOpened, setLegModalOpen] = useState(false);
    const [requestInspection, setRequestInspection] = useState(new RequestInpsection())
    const [legInfo, setLegInfo] = useState<{ id: number, price: number, driver_pay: number, carrier: any, driver: any, hours: string, change?: boolean }>({
        id: null,
        price: null,
        driver_pay: null,
        carrier: null,
        driver: null,
        hours: null,
        change: false
    });
    const [showLegDetailModal, setShowLegDetailModal] = useState(undefined);
    // const [undispatching, setUndispatching] = useState(false);


    function getFilterCount() {
        return Object.keys(filterData).sumBy(x => Object.keys(filterData[x]).sumBy(y => filterData[x][y].length));
    }
    
    

   
    

    

    async function getLegByID(leg_id) {
        const query = gql`
            query($leg_id:Int){
  leg_by_id(leg_id:$leg_id){
    id
    time
    status
    time_completed
    completed
    canceled
    equipment
    velox_order_id
    velox_order {
        id
        accepted
        rejected
        canceled
        expired
        expires
        change_requests {
          id
          order_id
          accepted
          rejected
          time
          payout
          note
          active
          expired
          expires_at
          weblink
          code
        }
      }
    carrier_pay
    vehicle_order {
        id
        vehicle {
            vin
            id
            year
            make
            model
        }
        master_order {
            id
            contacts {
                id
                name
            }
            customer {
                id
                name
                billing_address {
                    city
                    state
                    street
                    country
                    postal_code
                    street_number
                    postal_code_suffix
                }
            }
        }
    }
    carrier {
        id
        name
    }
    driver{
      id
      name
    }
    stops{
      id
      location{
        id
        address{
          city
          state
          street
          country
          postal_code
          street_number
          postal_code_suffix
        }
        title
        internal_note
        driver_note
        customer {
            id
            name
            key
        }
      }
      is_pickup
      time_scheduled
      time_completed
      completed
      started
      canceled
      nofail
      service_charges{
        id
        amount
        base_amount
        tax_amount
        note
        amount_remaining
      }
      contacts{
        id
        name
        email
        contact{
          title
          value
          type
        }
      }
      time_frame{
        end
        start
      }
    }
    service_charges{
      id
      name
      amount
      base_amount
      tax_amount
      note
      amount_remaining
    }
  }
}
        `;

        try {
            const data = await GraphqlService.SendQuery(query, {leg_id});
            if (!data)
                throw new Error('Leg not found');
            return new Leg(data);
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error'});
            return null;
        }
    }

    async function splitLeg(evt) {

        const temp = {...selectedLeg?.leg} as Leg;
        const sc = new ServiceCharge();
        sc.name = evt.equipment;
        sc.amount = evt.equipment_price;
        sc.base_amount = evt.equipment_price;
        temp.service_charges = [sc];
        const leg1 = new Leg(temp);


        const dindex1 = leg1.stops.findIndex(x => !x.is_pickup);


        const drop1 = new Stop(leg1.stops[dindex1]);

        leg1.stops[dindex1] = new Stop(evt.dropoff);


        const leg2 = new Leg();
        const dindex2 = leg2.stops.findIndex(x => !x.is_pickup);


        leg2.stops[dindex2 == 1 ? 0 : 1] = new Stop(evt.pickup);
        leg2.stops[dindex2] = new Stop(drop1);


        leg1.equipment_price = evt.equipment_price;
        leg1.equipment = evt.equipment;

        leg2.equipment_price = evt.equipment_price_new;
        leg2.equipment = evt.equipment_new;

        const x = {...selectedLeg, leg: leg1, splitLeg: leg2};
        // console.log(x)
        setSelectedLeg(x);
        // setCurrentView(VIEWS.EDIT_LEG);
        setLegModalOpen(true);
        setSplitOpen(false);
    }

    function getLegFilters(data){
        let legs = data.filter(x => x.origin.address && x.destination.address);
        let result: {
            origin: {
                states: {name: string, count: number}[];
                cities: {name: string, count: number}[];
                dates: {name: string, count: number}[];
            },
            destination: {
                states: {name: string, count: number}[];
                cities: {name: string, count: number}[];
                dates: {name: string, count: number}[];
            }
        } = {
            origin: {
            states: [],
            cities: [],
            dates: []
            },
            destination: {
            states: [],
            cities: [],
            dates: []
            }
        }
        
        let origin_states = [...new Set(legs.map(x => x.origin.address.state.toUpperCase()))] as string[];
        let origin_cities = [...new Set(legs.map(x => x.origin.address.city.toUpperCase()))] as string[];
        let origin_dates = [...new Set(legs.map(x => x.origin.time_scheduled))] as string[];
        
        let destination_states = [...new Set(legs.map(x => x.destination.address.state.toUpperCase()))] as string[];
        let destination_cities = [...new Set(legs.map(x => x.destination.address.city.toUpperCase()))] as string[];
        let destination_dates = [...new Set(legs.map(x => x.destination.time_scheduled))] as string[];
        
        for (let state of origin_states){
            result.origin.states.push({
            name: state,
            count: legs.filter(x => x.origin.address.state.toUpperCase() == state).length
            });
        }
        
        for (let city of origin_cities){
            result.origin.cities.push({
            name: city,
            count: legs.filter(x => x.origin.address.city.toUpperCase() == city).length
            });
        }
        
        for (let date of origin_dates){
            result.origin.dates.push({
            name: date,
            count: legs.filter(x => x.origin.time_scheduled == date).length
            });
        }
        
        
        for (let state of destination_states){
            result.destination.states.push({
            name: state,
            count: legs.filter(x => x.destination.address.state.toUpperCase() == state).length
            });
        }
        
        for (let city of destination_cities){
            result.destination.cities.push({
            name: city,
            count: legs.filter(x => x.destination.address.city.toUpperCase() == city).length
            });
        }
        
        for (let date of destination_dates){
            result.destination.dates.push({
            name: date,
            count: legs.filter(x => x.destination.time_scheduled == date).length
            });
        }
        
        return result;
    }

    function applyFilters(data: any[], filters: FilterClass){
        
        let legs = data.filter(x => x.origin.address && x.destination.address);
        
      
        
        if(filters.origin.states.length > 0){
            legs = legs.filter(x => filters.origin.states.includes(x.origin.address.state.toUpperCase().trim()));
        }

        if(filters.origin.cities.length > 0){
          legs = legs.filter(x => filters.origin.cities.includes(x.origin.address.city.toUpperCase().trim()));
        }

        if(filters.origin.months.length > 0){
            legs = legs.filter(x => filters.origin.months.includes(moment(x.origin.time_scheduled).format('MMM').toUpperCase()));
        }

        if(filters.origin.dates.length > 0){
          legs = legs.filter(x => {
              console.log(x.origin.time_scheduled, x.leg_id);
              console.log(filters.origin.dates, filters.origin.dates.includes(x.origin.time_scheduled))
            return filters.origin.dates.includes(x.origin.time_scheduled)
          });
        }


        if(filters.destination.states.length > 0){
            legs = legs.filter(x => filters.destination.states.includes(x.destination.address.state.toUpperCase().trim()));
        }

        if(filters.destination.cities.length > 0){
          legs = legs.filter(x => filters.destination.cities.includes(x.destination.address.city.toUpperCase().trim()));
        }

        if(filters.destination.months.length > 0){
            legs = legs.filter(x => filters.destination.months.includes(moment(x.destination.time_scheduled).format('MMM').toUpperCase()));
        }
        
        if(filters.destination.dates.length > 0){
          legs = legs.filter(x => filters.destination.dates.includes(x.destination.time_scheduled));
        }
        
        return legs;
    }
    async function getAllDispatched() {
        try {
            setLoading(true);
            onTableRefreshed();
            // alert('dataLeg.length');
            let dataLeg;
            if (showLegHistory) {
               if (customerID) {
                    dataLeg = await GraphqlService.SendQuery(LegQueries.LEG_HISTORY_BY_CUSTOMER, {
                        vehicle_search: vehicleSearch,
                        pickup_search: pickupSearch,
                        dropoff_search: dropoffSearch,
                        contact_search: customerSearch,
                        leg_id_search: legSearch,
                        master_order_id_search: masterSearch,
                        velox_id_search: veloxSearch,
                        customer_id: customerID
                    })
               }
               else {
                    dataLeg = await GraphqlService.SendQuery(LegQueries.LEG_HISTORY, {
                        vehicle_search: vehicleSearch,
                        pickup_search: pickupSearch,
                        dropoff_search: dropoffSearch,
                        customer_search: customerSearch,
                        leg_id_search: legSearch,
                        master_order_id_search: masterSearch,
                        velox_id_search: veloxSearch

                    });
               }
                
            } 
            else
            {
                dataLeg = await GraphqlService.SendQuery(LegQueries.GET_ALL_DISPATCHING);
                dataLeg = dataLeg.sort((x, y) => x.vehicle_order_id > y.vehicle_order_id ? -1 : 1);
            }

            let r = dataLeg.map(x => {
                

                const pending = x.velox_order && (!x.velox_order.accepted && !x.velox_order.canceled);
                
                x.pending = pending;

                if(!x.origin || !x.destination){
                    return undefined;
                }

                const status = getLegStatus(x);

                return {
                    vehicle_info: [x?.vehicle.vin || '-', x?.vehicle?.year + ' ' + x?.vehicle.make + ' ' + x.vehicle.model, 'LEG-' + x.leg_id],
                    status_carrier: [status , (status == 'PENDING') ? moment(new Date(x.pending_change_request ? x.velox_order?.change_requests?.find(x => x.active)?.expires_at :  x.velox_order.expires)).diff(moment(), 'minutes') + ' min left' : '', `${x.carrier?.name} ${x.carrier?.id ? ` (${x.carrier?.id})` : ''}`, x?.driver?.name],
                    customer: [x?.customer?.name, x.corporate_client ? 'CORPORATE' : 'PRIVATE', 'M-' + x?.master_order_id],
                    leg_origin: <div><p className='font-bold'>{x?.origin.address?.city}</p><p style={{marginTop: '.1rem'}}>{x.origin?.address?.state + ' ' + x.origin.address?.postal_code}</p><p style={{marginTop: '.1rem'}}>{Helper.FORMAT.USDate(new Date(x.origin.time_scheduled), 'ns', x.origin.timezoneName)}</p>{x.origin.nofail && <Status className='mt-1' statusName='NO FAIL'  />}</div>,
                    leg_destination: <div><p className='font-bold'>{x?.destination.address?.city}</p><p style={{marginTop: '.1rem'}}>{x.destination?.address?.state + ' ' + x.destination.address?.postal_code}</p><p style={{marginTop: '.1rem'}}>{Helper.FORMAT.USDate(new Date(x.destination.time_scheduled), 'ns', x.destination.timezoneName)}</p>{x.destination.nofail && <Status className='mt-1' statusName='NO FAIL'  />}</div>,
                    velox_order_id: x.velox_order_id,
                    vehicle_order_id: x.vehicle_order_id,
                    master_order_id: x.master_order_id,
                    leg_id: x.leg_id,
                    pending: pending || x.pending_change_request,
                    weblink: x?.velox_order?.change_requests?.find(x => x.active)?.weblink || (environments.VELOX_LINK + '/order-requests'),
                    carrier_id: x.carrier?.id,
                    origin_date: new Date(x.origin.time_scheduled),
                    destination_date: new Date(x.destination.time_scheduled),
                    origin: {
                        time_scheduled: Helper.FORMAT.USDate(new Date(x.origin.time_scheduled), 'do', x.origin.timezoneName),
                        address: x.origin.address,
                        completed: x.origin.completed
                        // _: x.origin.nofail ? 'NO FAIL' : ''
                    },
                    destination: {
                        time_scheduled: Helper.FORMAT.USDate(new Date(x.destination.time_scheduled), 'do', x.destination.timezoneName),
                        address: x.destination.address,
                        completed: x.destination.completed
                        // _: x.destination.nofail ? 'NO FAIL' : ''
                    },
                    vehicle: new Vehicle(x?.vehicle),
                }
            });

            
            originallegs.current = r;
            
            renderTable();
            setLoading(false);
        } catch (ex) {
            console.log('ex', ex.message);
            
            setLoading(false);
        }
    }

    function renderTable(filters: FilterClass = filterData){
        if (!showLegHistory)
            setLegs(applyFilters(originallegs.current, filters));
        else
            setLegs(originallegs.current);
    }

     async function getLink(carrier_id, link) {
        const mutation = gql`
            mutation($carrier_id: Int, $link: String){
                create_velox_redirect_link(carrier_id: $carrier_id, link: $link)
            }
        `;
        try {
            _loading.open('Getting Link...');
            const r = await GraphqlService.SendMutation(mutation, {carrier_id:carrier_id, link})
            _loading.close();

        Object.assign(document.createElement('a'), {
            target: '_blank',
            href: r,
        }).click();
        } catch (ex) {
            _loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
                    

    }

    const options: IMenuOption[] = [
        {
            icon: ICONS.IconReverse,
            label: 'Dispatch To',
            condition: x => !showLegHistory && !x.velox_order_id,
            action: async (x: any) => {
                setLegInfo({
                        
                    id: x.leg_id
                } as any);

                console.log(x.leg_id);
                
                setModalOpened(true);
            }
        },
        {
            icon: ICONS.IconDetails,
            label: 'Accept Request',
            condition: x => x.pending,
            action: async (x: any) => {
                getLink(x.carrier_id, x.weblink);
            }
        },
        {
            icon: ICONS.IconReverse,
            label: 'Change Driver',
            condition: x => !showLegHistory && x.velox_order_id && x.carrier_id == environments.JP_CARRIER_ID,
            action: (x: any) => {
                setLegInfo({
                    driver_pay: '' as any,
                    price: '' as any,
                    id: x.leg_id,
                    carrier: null,
                    driver: null,
                    hours: '1',
                    change: true
                });
                setModalOpened(true);
            }
        },
        {
            icon: ICONS.IconCancelWhite,
            label: 'Undispatch',
            condition: x => !showLegHistory && x.velox_order_id,
            action: (x: any) => {
                // setLegInfo({driver_pay: '' as any, price: '' as any, id: x.leg_id, carrier: null, driver: null, hours: '1'});
                // setModalOpened(true);
                Swal.fire({
                    text: 'Are you sure you want to undispatch this leg? This action cannot be reverted.',
                    icon: 'question',
                    title: 'Undispatch Leg',
                    confirmButtonText: 'Confirm',
                    denyButtonText: `No, don't do it`,
                    showDenyButton: true
                }).then(r => {
                    if (r.isConfirmed) {
                        onUndispatchLeg(x.leg_id)
                    }
                })
            }
        },
        {
            icon: ICONS.IconAddLane,
            label: 'Split Leg',
            condition: x => !showLegHistory,
            action: async (x: any) => {
                // if (x.carrier_id && x.carrier_id != environments.JP_CARRIER_ID)
                //     return Swal.fire({
                //         title: 'Error',
                //         text: `You can't split this leg, because it's already dispatched to other carrier.`,
                //         icon: 'error'
                //     });

                    _loading.open('Getting leg data...');

                    try {
                        let leg = await getLegByID(x.leg_id);

                // console.log(leg);


                console.log(x);
                            console.log(leg);
                            if (leg) {
                                setSelectedLeg({...selectedLeg, leg, vehicle_order_id: x.vehicle_order_id, master_id: x.master_order_id });
                                setSplitOpen(true);

                                _loading.close();
                            }
                            else throw new Error('Leg not found');
                    } catch (ex) {
                        _loading.close();
                        toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
                    }
            }
        },
        {
            icon: ICONS.IconDetails,
            label: 'See Leg Details',
            condition: x => !showLegHistory,
            action: (x: any) => {
                setShowLegDetailModal(x);
            }
        },
        {
            icon: ICONS.IconDetails,
            label: 'Request Inspection',
            condition: x => !showLegHistory,
            action: (x: any) => {
                const vo_id = x.vehicle_order_id;

                console.log(vo_id);

                const temp = new RequestInpsection();
                temp.vehicle_order_id = vo_id;
                console.log(temp)
                setRequestInspection(temp);
            }
        },
        {
            icon: ICONS.IconDetails,
            label: 'See Order Details',
            condition: x => true,
            action: (x: any) => {
                let master_id = x.master_order_id;

                if (master_id) {
                    Helper.Navigation.NavigateTo('/order/' + master_id);
                }
            }
        },
        {
            icon: ICONS.IconDriver,
            label: 'Find Nearest Driver',
            condition: x => true,
            action: (x: any) => {
                // let master_id = x.master_order_id;

                // if (master_id) {
                //     Helper.Navigation.NavigateTo('/order/' + master_id);
                // }
            }
        }
    ];

    const legsFiltered = () => legs.filter(x => {
        let searchRes = searchTerm.split(' ').map(s => JSON.stringify(x).toLowerCase().includes(s.toLowerCase()));
        x.selected = x.leg_id == vehicleOrderSelected?.leg_id;

        if (!showToggles)
            return !searchRes.includes(false)

        return !searchRes.includes(false) &&
        (dispatchStatus == DispatchStatuses.NONE ? true : (dispatchStatus == DispatchStatuses.DISPATCHED ? x.velox_order_id != null : (
            dispatchStatus == DispatchStatuses.NOT_DISPATCHED ?
            x.velox_order_id == null
            : x.pending
        )));
    });

    const getLegStatus = (leg) => {

        if(leg.pending && !leg.pending_change_request)
            {
                return leg.velox_order?.expired ?  'EXPIRED' : (leg.velox_order?.rejected ? 'REJECTED' : 'PENDING');
            }

        if (leg.pending_change_request)
        {
            return leg.velox_order?.change_requests?.find(x=>x.active)?.expired ?  'EXPIRED' : (leg.velox_order?.change_requests?.find(x=>x.active)?.rejected ? 'REJECTED' : 'PENDING');
        }

        if (leg.origin.completed && leg.destination.completed)
            return 'COMPLETED';

        if ((!leg.origin.completed && new Date(+leg.origin.scheduled) < new Date()) || (!leg.destination.completed && new Date(+leg.destination.scheduled) < new Date()))
            return 'LATE';

        if (leg.origin.completed)
            return 'IN TRANSIT';

        if (leg.velox_order_id)
            return 'DISPATCHED';

        return 'BOOKED'

    }

    

    const [vehicleSearch, setVehicleSearch] = useState('');
    const [pickupSearch, setPickupSearch] = useState('');
    const [dropoffSearch, setDropoffSearch] = useState('');
    const [customerSearch, setCustomerSearch] = useState('');

    const [legSearch, setLegSearch] = useState('');
    const [masterSearch, setMasterSearch] = useState('');
    const [veloxSearch, setVeloxSearch] = useState('');

    useEffect(() => {
        if (refresh)
            getAllDispatched();
    }, [refresh]);
    

    

    const ref = useRef();

    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    const ref4 = useRef();
    const ref5 = useRef();
    const ref6 = useRef();
    const ref7 = useRef();

    const handleSearchHistory = () => getAllDispatched();

    return (
        <>
            <div className="flex-1-container col-12 leg-table">
                {
                    showLegHistory &&
                    <div className="col-12 mb-2 d-flex justify-content-between align-items-center">
                        <Searchbar value={pickupSearch}
                                   onChange={(evt) => setPickupSearch(evt.target.value)}
                                   onKeyEnter={() => handleSearchHistory()}
                                   background={'#F8F8F8'}
                                   placeholder={'Origin'}
                                   reference={ref1}/>
                        <Searchbar value={dropoffSearch}
                                   onChange={(evt) => setDropoffSearch(evt.target.value)}
                                   onKeyEnter={() => handleSearchHistory()}
                                   background={'#F8F8F8'}
                                   placeholder={'Destination'}
                                   reference={ref2}/>
                        <Searchbar value={vehicleSearch}
                                   onChange={(evt) => setVehicleSearch(evt.target.value)}
                                   onKeyEnter={() => handleSearchHistory()}
                                   background={'#F8F8F8'}
                                   placeholder={'Vehicle Details'}
                                   reference={ref3}/>
                        <Searchbar value={customerSearch}
                                   onChange={(evt) => setCustomerSearch(evt.target.value)}
                                   onKeyEnter={() => handleSearchHistory()}
                                   background={'#F8F8F8'}
                                   placeholder={customerID ? 'Bill to Contact' : 'Bill to Customer'}
                                   reference={ref4}/>

                            <Searchbar value={legSearch}
                                   onChange={(evt) => setLegSearch(Helper.Masks.IntNumbers(evt.target.value))}
                                   onKeyEnter={() => handleSearchHistory()}
                                   background={'#F8F8F8'}
                                   placeholder={'LEG #'}
                                   reference={ref5}/>

                            <Searchbar value={masterSearch}
                                   onChange={(evt) => setMasterSearch(Helper.Masks.IntNumbers(evt.target.value))}
                                   onKeyEnter={() => handleSearchHistory()}
                                   background={'#F8F8F8'}
                                   placeholder={'Master #'}
                                   reference={ref6} />
                                <Searchbar value={veloxSearch}
                                    onChange={(evt) => setVeloxSearch(Helper.Masks.IntNumbers(evt.target.value))}
                                    onKeyEnter={() => handleSearchHistory()}
                                    background={'#F8F8F8'}
                                    placeholder={'Velox #'}
                                    reference={ref7} />

                        <button className="btn btn-blue-light rounded-pill px-5"
                                onClick={() => handleSearchHistory()}><i className="ti-search font-10"></i> Search</button>
                    </div>
                }

                <div className='col-12 mb-2 d-flex justify-content-between align-items-start'>
                    <div className='d-flex align-items-center'>
                        <h4 className="fs-12 m-0">{legsFiltered().length} / {originallegs.current.length} LEGS</h4>
                        <button className='btn btn-clear ml-2'
                                onClick={() => getAllDispatched()}><img className={loading ? 'spin' : ''}
                                                                        style={{transform: ''}} src={ICONS.IconRefresh}
                                                                        alt=""/></button>
                    </div>

                    {
                        !showLegHistory &&
                        <Searchbar value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)}
                                   background={'#F8F8F8'} reference={ref}/>
                    }
                </div>

                <div className="col-12 d-flex">
                    {
                        showToggles &&
                        <div className="col-12 mb-3">
                            <button
                                onClick={() => setDispatchStatus(DispatchStatuses.NOT_DISPATCHED == dispatchStatus ? DispatchStatuses.NONE : DispatchStatuses.NOT_DISPATCHED)}
                                className={`btn ${dispatchStatus == DispatchStatuses.NOT_DISPATCHED ? 'btn-blue-light' : 'btn-blue-light-outline'}`}>NOT DISPATCHED
                            </button>
                            <button
                                onClick={() => setDispatchStatus(DispatchStatuses.DISPATCHED == dispatchStatus ? DispatchStatuses.NONE : DispatchStatuses.DISPATCHED)}
                                className={`btn ${dispatchStatus == DispatchStatuses.DISPATCHED ? 'btn-blue-light' : 'btn-blue-light-outline'} mx-2`}>DISPATCHED
                            </button>

                            <button
                                onClick={() => setDispatchStatus(DispatchStatuses.PENDING == dispatchStatus ? DispatchStatuses.NONE : DispatchStatuses.PENDING)}
                                className={`btn ${dispatchStatus == DispatchStatuses.PENDING ? 'btn-blue-light' : 'btn-blue-light-outline'}`}>PENDING
                            </button>

                        </div>
                    }
                    {
                        showFilters &&
                        <div className="col-6 mb-3 d-flex justify-content-end align-items-center">
                            {/* <h6 className="fs-12 font-light">From: <span className='font-medium'>All</span></h6>
                            <h6 className="fs-12 font-light mx-1">To: <span className='font-medium'>All</span></h6> */}

                            <button onClick={() => setFiltersOpened(true)} className="btn btn-clear px-0 position-relative">
                                {getFilterCount() > 0 && <span className='filter-indicator'>{getFilterCount()}</span>}
                                <img
                                src={ICONS.IconFilter} alt=""/></button>
                        </div>
                    }
                </div>

                <div className={`col-12 flex-1-container`}>
                    <Table rowHeight={90}
                           menuOptions={options}
                           loading={loading}
                           backgroundColor={(x) => {
                               let lateBackground = 'bg-red-light ', pickedUp = 'bg-green-light ', pending = 'bg-gold';
                                if(x.pending)
                                    return pending;

                                if(!x.origin.completed && x.origin_date < new Date().getTime())
                                    return lateBackground;
                                
                               if(!x.destination.completed && x.destination_date < new Date().getTime())
                                    return lateBackground;

                                if(x.origin.completed && !x.destination.completed)
                                    return pickedUp;
                           }}
                           columns={[
                               {
                                   label: 'VEHICLE INFO',
                                   active: false,
                                   orderBy: 'DESC',
                                   name: 'vehicle_info',
                                   type: 'number',
                                   sort_by: 'leg_id'
                               },
                               {
                                   label: 'STATUS / CARRIER',
                                   active: false,
                                   orderBy: 'DESC',
                                   name: 'status_carrier'
                               },
                               {
                                   label: 'CUSTOMER',
                                   active: false,
                                   orderBy: 'DESC',
                                   name: 'customer'
                               },
                               {
                                   label: 'LEG ORIGIN',
                                   active: false,
                                   orderBy: 'DESC',
                                   name: 'leg_origin',
                                   sort_by: 'origin_date',
                                   type: 'date'
                               },
                               {
                                   label: 'LEG DESTINATION',
                                   active: false,
                                   orderBy: 'DESC',
                                   name: 'leg_destination',
                                   sort_by: 'destination_date',
                                   type: 'date'
                               }]}
                           rows={legsFiltered()}
                           tableHeight={tableHeight ? tableHeight : '80vh'}
                           onRowClicked={(evt) => {
                               
                               const data = {id: evt.vehicle_order_id, leg_id: evt.leg_id};
                               onVehicleOrderSelected(vehicleOrderSelected?.leg_id == null ? data : (data.leg_id == vehicleOrderSelected?.leg_id ? {
                                   id: null,
                                   leg_id: null
                               } : data));
                           }}/>
                </div>
            </div>


            <Modal className={'modal-1016'} isOpen={filtersOpened}>
                <DispatchFilters onSubmit={(evt) => {
                    renderTable(evt);
                    setFilterData(new FilterClass(evt));
                    setFiltersOpened(false);
                }} filter={filterData} legs={originallegs.current} onCancelClicked={(evt) => setFiltersOpened(false)}/>
            </Modal>

            <Modal isOpen={modalOpened} className={'modal-1016'}>
                <DispatchLeg data={legInfo} tab={'DISPATCH'} onCancel={() => setModalOpened(false)} onSubmitted={() => {
                    setModalOpened(false);
                    getAllDispatched();
                }} />
            </Modal>

            <Modal isOpen={showLegDetailModal != undefined}
                   onRequestClose={() => setShowLegDetailModal(undefined)}  >
                <LegDetail order={showLegDetailModal?.object} onClose={() => setShowLegDetailModal(undefined)}/>
            </Modal>



            {splitOpen && <SplitRoundtripLeg onLegSplitted={(legs) => {
                setSplitOpen(false);
                getAllDispatched();
            }} leg={selectedLeg?.leg} master_id={selectedLeg?.master_id} vehicle_order_id={selectedLeg?.vehicle_order_id}
                                     onClose={evt => setSplitOpen(false)}/>}



            <ReactModal isOpen={requestInspection.vehicle_order_id != null}>
                <RequestInspectionForm data={requestInspection} onClose={(evt) => {
                    setRequestInspection(new RequestInpsection());
                }} />
            </ReactModal>

           </>
    )
}

export default LegTable;

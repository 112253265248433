import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { Charge, ServiceCharge, StorageCharge, StorageOrder, Warehouse } from '../../classes/classes';
import Helper from '../../helper/Helper';
import useMessageBox from '../../hooks/useMessageBox/useMessageBox';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import FormGroup from '../FormGroup/FormGroup';
import Toggle from '../Toggle/Toggle';
import EditServices from './EditServices';
import StorageChargeForm from './StorageChargeForm';
import StorageServices from './StorageServices';
import {gql} from 'apollo-boost';
import { GraphqlService } from '../../services/graphql.service';
import useLoading from '../../hooks/useLoading/useLoading';
import { useToasts } from 'react-toast-notifications';
import Status from '../Status/Status';

const StorageOrderModal = ({onClose, data, chargesData}) => {

    //console.log(data);
    const [storage, setStorage] = useState<StorageOrder>(new StorageOrder(data));
    const [charges, setCharges] = useState<ServiceCharge[]>(chargesData.map(x => new ServiceCharge(x)));
    const [pickerOpened, setPickerOpened] = useState(false);
    const [editCharge, setEditCharge] = useState(null);
    const [storageCID, setStorageCID] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const toast = useToasts();

    const loading = useLoading();
    
    const messageBox = useMessageBox();

    async function loadWarehouses()
    {
        const query  = gql`
            query{
  get_warehouses{
    id
    name
    
    }
  }

        `;
        try {
            const data = await GraphqlService.SendQuery(query);
            setWarehouses(data);
        } catch (ex) {
            console.log('ex', ex.message)
        }
    }

    useEffect(() => {
        loadWarehouses();
    }, []);

    async function submitStorageOrder() {
        const mutation = gql`
            mutation($vehicle_order_id: Int, $active: Boolean, $billing_cycle: String, $rate: Float, $note: String, $departure_date: String, $warehouse_id: Int){
                update_or_create_storage_order(vehicle_order_id:$vehicle_order_id, active:$active, billing_cycle:$billing_cycle, rate:$rate, note:$note, departure_date:$departure_date, warehouse_id: $warehouse_id){
                    id
                    message
                    success
                }
            }
        `;

        const variables = {
            vehicle_order_id: storage.vehicle_order_id,
            active: storage.active,
            billing_cycle: storage.billing_cycle,
            rate: +storage.rate,
            note: storage.note,
            departure_date: storage.departure_date,
            warehouse_id: storage.warehouse.id
        }


        try {
            loading.open((!storage.id ? 'Creating' : 'Updating') + ' storage order for VH-' + storage.vehicle_order_id);
            const data = await GraphqlService.SendMutation(mutation, variables);
            
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');

            

            loading.close();
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});

            onClose({reload: true});


        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    async function deleteStorageCharge(storage_charge_id) {

        console.log(storage_charge_id);

        loading.open(`Deleting storage charge #${storage_charge_id}...`);
        
        try {
            const query = gql`
                            mutation($storage_charge_id: Int){
                                    delete_storage_charge(storage_charge_id:$storage_charge_id){
                                        id
                                        message
                                        success
                                    }
                                }
                        `;

            const data = await GraphqlService.SendQuery(query, {storage_charge_id});
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
                                
            loading.close();

            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            onClose({reload: true});
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }
    
    return <>
        <div className='row mb-3'>
            
            <div className='d-flex'>
                <h4 className='font-10 mr-2'>Storage Order</h4>
                {/* <Toggle id='toggle_active' disabled={editCharge} label='Active' checked={storage.active} onCheck={(evt) => setStorage({...storage, active: evt})} /> */}
            </div>
            <h4 onClick={onClose} className='font-10 cursor-pointer'><i className='fas fa-times'></i></h4>
            <div className='mt-2 col-12'>
                <Status onClick={(evt) => setStorage({...storage, active: !storage.active})} className='font-12 px-3 py-2 no-select' statusName={storage.active ? 'ACTIVE' : 'INACTIVE'} />
            </div>
                       
                       
                    </div>             

                    <div className="flex-1-container">
                        <div className="row">
                            <FormGroup moneySymbol disabled={editCharge || !storage.active} placeholder='0.00' colSize={6} label='Rate' required={storage.active} name='rate' value={storage.rate.toString()} onTextChange={(evt) => setStorage({...storage, rate: Helper.Masks.DecimalNumbers(evt) as any})} />
                            <FormGroup disabled={editCharge || !storage.active} value={storage.billing_cycle} onTextChange={(evt) => setStorage({...storage, billing_cycle: evt})} placeholder='-- Select --' colSize={6} label='Billing Cycle' required={storage.active} name='billing_cicle' type='select' options={{data: ['MONTH', 'WEEK', 'DAY'], label: x=>x, value: x=>x}} />
                      
                            <FormGroup disabled={editCharge || !storage.active} readOnly onClick={(evt) => !editCharge && setPickerOpened(true)} className='position-relative' 
                                   value={!storage.departure_date ? 'N/A' : moment(storage.departure_date).format('MM/DD/YYYY')} required={storage.active}
                                   colSize={6} name='reference' type='text'
                                   label={`Departure Date`} placeholder='Paid on...'>
                                <i className="far fa-calendar-alt"
                                style={{opacity: editCharge ? .3 : 1}}></i>
                            </FormGroup>
                            <FormGroup
                                   readOnly
                                   className='position-relative'
                                   disabled
                                   value={moment(storage.date_last_issued).format('MM/DD/YYYY')}
                                   required={storage.active}
                                   colSize={6} name='reference' type='text'
                                   label={`Date Last Issued`} placeholder='Paid on...'>
                            </FormGroup>
                            <div className="col-12">
                                <FormGroup onCheckChanged={(evt) => {
                                    setStorage({...storage, departure_date: !evt ? new Date() : null});
                                }} checked={!storage.departure_date} name='no_departure' type='checkbox' label='No Departure Date' />
                            </div>
                            <FormGroup
                                   readOnly
                                   className='position-relative'
                                   value={moment(storage.time).format('MM/DD/YYYY')}
                                   required={storage.active}
                                   colSize={6} name='reference' type='text'
                                   disabled
                                   label={`Storage Created`} placeholder='Created At...'>
                            </FormGroup>
                            <FormGroup
                                   readOnly
                                   required={storage.active}
                                   disabled={editCharge || !storage.active}
                                   className='position-relative'
                                   options={{data: warehouses, label: x => x.name, value: x => x.id}}
                                   value={storage?.warehouse?.id?.toString()}
                                   colSize={6} name='warehouse' type='select'
                                   onTextChange={(evt) => {
                                       const warehouse = new Warehouse(warehouses.find(x => x.id == evt));
                                       setStorage({...storage, warehouse});
                                   }}
                                   label={`Warehouse`} placeholder='-- Select --'>
                            </FormGroup>
                            <FormGroup
                                disabled={editCharge || !storage.active} 
                                value={storage.note}
                                onTextChange={(evt) => setStorage({...storage, note: evt})}
                            textAreaHeight={'4rem'} colSize={12} label='Notes' name='storage_notes' type='textarea' placeholder='Type notes...' />

                            <div className="col-12 mb-3">
                                <hr />
                            </div>

                            {(!editCharge  && storage.id)&& <StorageServices onDeleteClicked={(evt) => {
                                messageBox.open({
                                    title: 'Are you sure?',
                                    message: `Remove storage charge #${evt.storage_charge.id}, this action can't be reverted!`,
                                    buttons: [
                                        {
                                            text: 'Cancel',
                                            css: 'btn btn-clear text-blue-light'
                                        },
                                        {
                                            action: async (id) => deleteStorageCharge(id),  
                                            text: 'Confirm',
                                            data: evt.storage_charge.id
                                        }
                                    ]
                                })
                            }} onAddClicked={(evt) =>{ 
                                const sc = new StorageCharge();
                                sc.storage_order_id = storage.id;
                                setEditCharge(sc);
                            
                            }} onEditClicked={(evt) => setEditCharge(new StorageCharge(evt.storage_charge))} charges={charges} />
                            
                            
                        }

{editCharge && <StorageChargeForm onSaved={(evt) => {
                                const temp = [...charges];
                                let index = temp.findIndex(x => x.storage_charge.id == evt.id);
                                if (index > -1)
                                {
                                    temp[index].storage_charge = new StorageCharge(evt);
                                }
                                else
                                {
                                    const sc = new ServiceCharge();
                                    sc.storage_charge = new StorageCharge(evt);
                                    temp.push(sc);
                                }
                                setCharges(temp);
                                setEditCharge(null);
                                onClose({reload: true});
                            }} charge={editCharge} onCancel={(evt) => setEditCharge(null)} />}

                        </div>
                    </div>

                   {!editCharge && <div className='row mt-3'>
                       <button onClick={onClose} className="btn btn-clear text-blue-light col-6 py-2">CANCEL</button>
                       <button id='test_button' onClick={(evt) => submitStorageOrder()} className="btn btn-blue-light col-6 py-2">CONFIRM</button>
                </div>     } 


                {pickerOpened && <DateTimePicker modal date={new Date(storage.departure_date)} onCancelClicked={(evt) => setPickerOpened(false)} onDateSubmitted={(evt) => {
                    setStorage({...storage, departure_date: new Date(evt)});
                    setPickerOpened(false)
                }} />}       


    </>
}

export default StorageOrderModal;
import React, {useRef, useState} from 'react';
import { SafeStorage } from '../../services/Storage/storageService';


const DashboardPage = () => {

    return (
        <>
            {SafeStorage.checkPermissions('new-order') && <a target='_blank' href='/new-order' className='btn btn-blue p-5'
               style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>CREATE NEW
                ORDER</a>}
        </>
    )
}

export default DashboardPage;

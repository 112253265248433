import {
  Inventory_Service,
  Storage_Orders_Sp,
  Storage_Section,
  Storage_Vehicle_Order_Sp,
  Warehouse
} from "../storage_order";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {Vehicle_Order} from "../../vehicle_order/vehicle_order";

export const get_storage_orders = (input:{open: boolean, warehouse_id: number}) : Promise<Storage_Orders_Sp[]> => {
  let query = gql`
    query($open: Boolean, $warehouse_id: Int) {
      get_storage_orders(open: $open, warehouse_id:$warehouse_id) {
        id
        started
        vehicle {
          id
          vin
          year
          make
          model
          submodel
        }
        delivered
        master_order {
          id
          customer_id
          customer {
            id
            name
            corporate_client
            key
            terms
            internal_note
            active
            tax_number {
              type
              value
            }
            billing_address {
              city
              state
              street
              country
              postal_code
              street_number
              postal_code_suffix
            }
            default_invoice_email
          }
          time
          started
          completed
          price
          payment_left
          terms
          canceled
          contacts {
            id
            email
            verified
            name
            role
            contact {
              title
              value
              type
              unsubscribed
            }
            title
            active
          }
          vehicle_orders {
            id
            time
            bol_comment
            time_changed
            driver_note
            started
            delivered
            vehicle {
              id
              vin
              year
              make
              model
              submodel
              type
              no_vin
              special_vin
              incomplete_vin
              color
              license_plate
              internal_note
              time_created
              user_id
            }
            charges {
              id
              amount
              tax_amount
              base_amount
              name
              note
              voided
              amount_remaining
            }
            storage_order {
warehouse_id
              id
              vehicle_order_id
              billing_cycle
              rate
              date_last_issued
              active
              departure_date
              note
            }
            legs {
              id
              vehicle_order_id
              vehicle_order {
                id
              }
              time
              status
              time_completed
              completed
              canceled
              equipment
              velox_order_id
              stops {
                id
                location {
                  id
                  customer_id
                  title
                  internal_note
                  driver_note
                  timezoneName
                }
                is_pickup
                time_scheduled
                time_completed
                completed
                started
                canceled
                nofail
                contacts {
                  id
                  email
                  verified
                  name
                  role
                }
                service_charges {
                  id
                  amount
                  tax_amount
                  base_amount
                  name
                  note
                  voided
                  amount_remaining
                }
                time_frame {
                  end
                  start
                }
                leg_id
                log {
                  user_id
                  message
                  time
                  vehicle_order_id
                  leg_id
                  stop_id
                }
              }
              service_charges {
                id
                amount
                tax_amount
                base_amount
                name
                note
                voided
                amount_remaining
              }
              driver {
                id
                name
                username
                equipment
                carrier_code
              }
              carrier {
                id
              }
              carrier_pay
              driver_pay
              remaining_carrier_receivable
              remaining_driver_payable
              velox_order {
                id
                accepted
                rejected
                canceled
                expired
                expires
              }
              pending_change_request
            }
            storage_bay {
              id
              label
              storage_section_id
              vehicle_order_id
            }
            inspections {
              id
              user {
                id
                name
              }
              type
              time
            }
            pending_inspection_id
            master_order_id
            warehouse_id
            non_move
          }
          delivered
          picked_up
          non_move
          payment_due
          last_invoice_amount
          needs_invoice
          token
        }
        service_charges {
          id
          amount
          tax_amount
          base_amount
          name
          note
          voided
          amount_remaining
        }
        storage_order {
warehouse_id
          id
          vehicle_order_id
          billing_cycle
          rate
          date_last_issued
          active
          departure_date
          note
          time
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {open, warehouse_id} as 
  Promise<Storage_Orders_Sp[]>;
}

export const get_storage_vehicle_orders = (input:{active: boolean, warehouse_id: number}) : Promise<Storage_Vehicle_Order_Sp[]> => {
  let query = gql`
    query($active: Boolean!, $warehouse_id: Int) {
      get_storage_vehicle_orders(active: $active, warehouse_id:$warehouse_id) {
        id
    price
    customer {
      id
      name
      key
      terms
      active
      internal_note
      corporate_client
    }
    canceled
    payment_left
    vehicle_order{
      id
      warehouse_id
      master_order_id
      pending_inspection_id
      charges {
        id
        name
        note
        voided
        base_amount
        tax_amount
        amount
        amount_remaining
        
        inventory_service {
          time_end
          time_start
          completed
          service_charge_id
        }
      }
      started
      vehicle {
        id
        year
        make
        model
        color
        vin
      }
      delivered
      storage_bay {
        id
        label
        position {
          x
          y
        }
        storage_section {
          id
          name
          rows
          columns
          position {
            x
            y
          }
          warehouse {
            id
            name
            address {
              street_number
              street
              city
              state
              postal_code
              postal_code_suffix
              country
            }
          }
          columns_format {
            format
            reverse
            starting_value
          }
        }
      }
      storage_order {
warehouse_id
        id
        note
        rate
        active
        billing_cycle
        departure_date
        date_last_issued
        vehicle_order_id
      }
      inventory_orders {
        id
        note
        completed
        carrier {
          name
          driver
          velox_order_id
        }
        stop_id
        incoming
        time_due
        time_completed
        warehouse_id
        vehicle_order_id
      }
    }
    last_invoice_amount
  }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {active, warehouse_id} as 
  Promise<Storage_Vehicle_Order_Sp[]>;
}

export const get_warehouse = (input:{warehouse_id: number}) : Promise<Warehouse> => {
  let query = gql`
    query($warehouse_id: Int) {
      get_warehouse(warehouse_id: $warehouse_id) {
        id
        name
        address
        storage_sections {
          id
          columns
          rows
          columns_format {
            format
            reverse
            starting_value
          }
          rows_format {
            format
            reverse
            starting_value
          }
          name
          warehouse_id
          warehouse {
            id
            name
            address
          }
          position {
            x
            y
          }
          storage_bays {
            id
            label
            storage_section_id
            position {
              x
              y
            }
            vehicle_order_id
          }
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {warehouse_id} as 
  Promise<Warehouse>;
}

export const get_warehouses = (input:{}) : Promise<Warehouse[]> => {
  let query = gql`
    query {
      get_warehouses {
        id
        name
        address
        storage_sections {
          id
          columns
          rows
          columns_format {
            format
            reverse
            starting_value
          }
          rows_format {
            format
            reverse
            starting_value
          }
          name
          warehouse_id
          warehouse {
            id
            name
            address
          }
          position {
            x
            y
          }
          storage_bays {
            id
            label
            storage_section_id
            position {
              x
              y
            }
            vehicle_order_id
          }
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Warehouse[]>;
}

export const row_values_formatted = (input:{starting_value: string, count: number}) : Promise<string[]> => {
  let query = gql`
    query($starting_value: String, $count: Int) {
      row_values_formatted(starting_value: $starting_value, count: $count)
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {starting_value, count} as 
  Promise<string[]>;
}

export const get_inventory_services_by_vehicle_order = (input:{vehicle_order_id: number}) : Promise<Inventory_Service> => {
  let query = gql`
    query($vehicle_order_id: Int) {
      get_inventory_services_by_vehicle_order(vehicle_order_id: $vehicle_order_id) {
        service_charge_id
        service_charge {
          id
          amount
          tax_amount
          base_amount
          name
          note
          voided
          amount_remaining
        }
        completed
        time_completed
        time_start
        time_end
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vehicle_order_id} as 
  Promise<Inventory_Service>;
}

export const get_bay_section = (input:{storage_section_id: number}) : Promise<Storage_Section> => {
  let query = gql`
    query($storage_section_id: Int) {
      get_bay_section(storage_section_id: $storage_section_id) {
        id
        columns
        rows
        columns_format {
          format
          reverse
          starting_value
        }
        rows_format {
          format
          reverse
          starting_value
        }
        name
        warehouse_id
        warehouse {
          id
          name
          address
        }
        position {
          x
          y
        }
        storage_bays {
          id
          label
          storage_section_id
          position {
            x
            y
          }
          vehicle_order_id
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {storage_section_id} as 
  Promise<Storage_Section>;
}

export const get_open_inventory_orders = (input:{warehouse_id: number}) : Promise<Storage_Vehicle_Order_Sp[]> => {
  let query = gql`
    query($warehouse_id: Int) {
      get_open_inventory_orders(warehouse_id: $warehouse_id) {
        id
        price
        canceled
        payment_left
        last_invoice_amount
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {warehouse_id} as 
  Promise<Storage_Vehicle_Order_Sp[]>;
}

export const get_warehouse_vehicle_orders = (input:{warehouse_id: number}) : Promise<Storage_Vehicle_Order_Sp[]> => {
  
let query = gql`
query($warehouse_id: Int!) {
    get_warehouse_vehicle_orders(warehouse_id: $warehouse_id) {
id
price
customer {
  id
  name
  key
  terms
  active
  internal_note
  corporate_client
}
canceled
payment_left
vehicle_order{
  id
  warehouse_id
  master_order_id
  pending_inspection_id
  charges {
    id
    name
    note
    voided
    base_amount
    tax_amount
    amount
    amount_remaining
    inventory_service {
      time_end
      time_start
      completed
      service_charge_id
    }
  }
  started
  vehicle {
    id
    year
    make
    model
    color
    vin
  }
  delivered
  storage_bay {
    id
    label
    position {
      x
      y
    }
    storage_section {
      id
      name
      rows
      columns
      position {
        x
        y
      }
      warehouse {
        id
        name
        address {
          street_number
          street
          city
          state
          postal_code
          postal_code_suffix
          country
        }
      }
      columns_format {
        format
        reverse
        starting_value
      }
    }
  }
  storage_order {
warehouse_id
    id
    note
    rate
    active
    billing_cycle
    departure_date
    date_last_issued
    vehicle_order_id
  }
  inventory_orders {
    id
    note
    completed
    carrier {
      name
      driver
      velox_order_id
    }
    stop_id
    incoming
    time_due
    time_completed
    warehouse_id
    vehicle_order_id
  }
}
last_invoice_amount
}
}
`;
  return GraphqlService.SendQuery(query, input) as  //, {warehouse_id} as 
  Promise<Storage_Vehicle_Order_Sp[]>;
}

export const find_inventory_vehicle_by_vin = (input:{vin: string}) : Promise<Storage_Vehicle_Order_Sp[]> => {
  let query = gql`
    query($vin: String) {
      find_inventory_vehicle_by_vin(vin: $vin) {
        id
        price
        canceled
        payment_left
        last_invoice_amount
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vin} as 
  Promise<Storage_Vehicle_Order_Sp[]>;
}

export const find_storage_vehicle_by_vin = (input:{vin: string}) : Promise<Storage_Vehicle_Order_Sp[]> => {
  let query = gql`
    query($vin: String) {
      find_storage_vehicle_by_vin(vin: $vin) {
        id
        price
        canceled
        payment_left
        last_invoice_amount
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vin} as 
  Promise<Storage_Vehicle_Order_Sp[]>;
}

export const get_inventory_details_by_vehicle_order_id = (input:{vehicle_order_id: number, warehouse_id: number}) : Promise<Vehicle_Order> => {
  let query = gql`
   query($vehicle_order_id: Int, $warehouse_id: Int) {
  get_inventory_details_by_vehicle_order_id(
    vehicle_order_id: $vehicle_order_id, warehouse_id: $warehouse_id
  ) {
    id
    time
    bol_comment
    time_changed
    driver_note
    delivered
    vehicle {
      id
      vin
      year
      make
      model
      submodel
      type
    }
    inventory_orders {
      note
      stop_id
      incoming
      id
      time_due
      completed
      time_completed
      warehouse_id
      carrier {
        name
        driver
        velox_order_id
        velox_id
      }
      warehouse {
        id
        name
        address {
          city
          state
          postal_code
          country
        }
        storage_sections {
          id
          columns
          rows
          storage_bays {
            id
            label
          }
        }
      }
      stop {
        id
        leg_id
      }
    }
    charges{
      id
      name
      amount
      base_amount
      tax_amount
      amount_remaining
      inventory_service{
        service_charge_id
        completed
        time_completed
        time_start
        time_end
      }
    }
  }
} `;
  return GraphqlService.SendQuery(query, input) as  //, {vehicle_order_id} as 
  Promise<Vehicle_Order>;
}
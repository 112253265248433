import {GraphqlService} from "../../../../graphql.service";
import {gql} from "apollo-boost";
import {Check_Input, Tax_Input} from "../payable";
import {ResultWithLink} from "../../customer_profile/customer_profile";
import {Address_Input, Tax_Info_Input} from "../../customer/customer";

export const pay_driver = (input:{check: Check_Input, note: string, category: string, reference: string, type: string, amount: number, time_paid: string, bank_account_id: number, velox_ids: number[], payable_request_id: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($check: check_input, $note: String, $category: String, $reference: String, $type: String, $amount: Float, $time_paid: String, $bank_account_id: Int, $velox_ids: Int[], $payable_request_id: Int) {
      pay_driver(check: $check, note: $note, category: $category, reference: $reference, type: $type, amount: $amount, time_paid: $time_paid, bank_account_id: $bank_account_id, velox_ids: $velox_ids, payable_request_id: $payable_request_id) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {check, note, category, reference, type, amount, time_paid, bank_account_id, velox_ids, payable_request_id} as 
  Promise<ResultWithLink>;
}

export const pay_carrier = (input:{check: Check_Input, note: string, category: string, reference: string, type: string, amount: number, time_paid: string, bank_account_id: number, velox_ids: number[], payable_request_id: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($check: check_input, $note: String, $category: String, $reference: String, $type: String, $amount: Float, $time_paid: String, $bank_account_id: Int, $velox_ids: Int[], $payable_request_id: Int) {
      pay_carrier(check: $check, note: $note, category: $category, reference: $reference, type: $type, amount: $amount, time_paid: $time_paid, bank_account_id: $bank_account_id, velox_ids: $velox_ids, payable_request_id: $payable_request_id) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {check, note, category, reference, type, amount, time_paid, bank_account_id, velox_ids, payable_request_id} as 
  Promise<ResultWithLink>;
}

export const pay_employee = (input:{check: Check_Input, note: string, category: string, reference: string, type: string, amount: number, time_paid: string, bank_account_id: number, employee_id: number, payable_request_id: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($check: check_input, $note: String, $category: String, $reference: String, $type: String, $amount: Float, $time_paid: String, $bank_account_id: Int, $employee_id: Int, $payable_request_id: Int) {
      pay_employee(check: $check, note: $note, category: $category, reference: $reference, type: $type, amount: $amount, time_paid: $time_paid, bank_account_id: $bank_account_id, employee_id: $employee_id, payable_request_id: $payable_request_id) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {check, note, category, reference, type, amount, time_paid, bank_account_id, employee_id, payable_request_id} as 
  Promise<ResultWithLink>;
}

export const create_payable = (input:{check: Check_Input, note: string, category: string, reference: string, type: string, amount: number, time_paid: string, bank_account_id: number, payee_id: number, payable_request_id: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($check: check_input, $note: String, $category: String, $reference: String, $type: String, $amount: Float, $time_paid: String, $bank_account_id: Int, $payee_id: Int, $payable_request_id: Int) {
      create_payable(check: $check, note: $note, category: $category, reference: $reference, type: $type, amount: $amount, time_paid: $time_paid, bank_account_id: $bank_account_id, payee_id: $payee_id, payable_request_id: $payable_request_id) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {check, note, category, reference, type, amount, time_paid, bank_account_id, payee_id, payable_request_id} as 
  Promise<ResultWithLink>;
}

export const create_payee = (input:{name: String, tax_info: Tax_Input, address: Address_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($name: String!, $tax_info: Tax_input, $address: address_input) {
      create_payee(name: $name, tax_info: $tax_info, address: $address) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {name, tax_info, address} as 
  Promise<ResultWithLink>;
}

export const pay_driver_extra = (input:{check: Check_Input, note: string, category: string, reference: string, type: string, amount: number, time_paid: string, bank_account_id: number, driver_id: number, payable_request_id: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($check: check_input, $note: String, $category: String, $reference: String, $type: String, $amount: Float, $time_paid: String, $bank_account_id: Int, $driver_id: Int, $payable_request_id: Int) {
      pay_driver_extra(check: $check, note: $note, category: $category, reference: $reference, type: $type, amount: $amount, time_paid: $time_paid, bank_account_id: $bank_account_id, driver_id: $driver_id, payable_request_id: $payable_request_id) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {check, note, category, reference, type, amount, time_paid, bank_account_id, driver_id, payable_request_id} as 
  Promise<ResultWithLink>;
}

export const update_payee_tax_info = (input:{payee_id: number, tax_info: Tax_Info_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($payee_id: Int, $tax_info: tax_info_input) {
      update_payee_tax_info(payee_id: $payee_id, tax_info: $tax_info) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {payee_id, tax_info} as 
  Promise<ResultWithLink>;
}
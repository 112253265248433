import {Address} from "../location/location";
import {User_Input, Users} from "../users/users";
import {File} from "../payment/payment";
import {Payable} from "../payable/payable";
import {Address_Input, Tax_Info, Tax_Info_Input} from "../customer/customer";

export class Employee {
    __typename?: "Employee"
    id?: number = null;
    name?: string = '';
    address?: Address = new Address();
    user?: Users = new Users();
    permission?: string = '';
    department?: string = '';
    active?: boolean = false;
    note?: string = '';
    documents?: File[] = [];
    last_payable?: Payable;
    type?: string = '';
    phone?: string = '';
    email?: string = '';
    tax_info?: Tax_Info = new Tax_Info();

    constructor(x?: Employee){
        if(x) {
            this.id = x.id;
            this.name = x.name;
            this.address =  new Address(x.address);
            this.user =  new Users(x.user);
            this.permission = x.permission;
            this.department = x.department;
            this.active = x.active;
            this.note = x.note;
            this.documents = x.documents?.map(z => z) || [];
            this.last_payable = x.last_payable ? new Payable(x.last_payable) : null;
            this.type = x.type;
            this.phone = x.phone;
            this.email = x.email;
            this.tax_info =  new Tax_Info(x.tax_info);            
        }
    }
}

export class Employee_Input {
    name?: string = '';
    address?: Address_Input = new Address_Input();
    department?: string = '';
    note?: string = '';
    tax_info?: Tax_Info_Input = new Tax_Info_Input();
    user?: User_Input = new User_Input();

    constructor(x?: Employee_Input){
        if(x){
            this.name = x.name;
            this.address = new Address(x.address);
            this.department = x.department;
            this.note = x.note;
            this.tax_info =  new Tax_Info_Input(x.tax_info);
            this.user = new Users(x.user);            
        }
    }
}

export class Employee_Input_Update {
    employee_id?: number = null;
    name?: string = '';
    address?: Address_Input = new Address_Input();
    department?: string = '';
    note?: string = '';
    file?: string = '';
    user?: User_Input = new User_Input();

    constructor(x?: Employee_Input_Update){
        if(x){
            this.employee_id = x.employee_id;
            this.name = x.name;
            this.address =  new Address(x.address);
            this.department = x.department;
            this.note = x.note;
            this.file = x.file;
            this.user = new Users(x.user);            
        }
    }
}

import FlatList, { PlainList } from 'flatlist-react';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import DashboardPage from './DashboardPage/DashboardPage';
import OrderQuotesPage from "./OrderQuotesPage/OrderQuotesPage";
import DispatchPage from "./DispatchPage/DispatchPage";
import InventoryPage from "./InventoryPage/InventoryPage";
import ArPage from "./ArPage/ArPage";
import ApPage from "./ApPage/ApPage";
import HistoryPage from "./HistoryPage/HistoryPage";
import NetworkPage from "./NetworkPage/NetworkPage";
import AnalyticsPage from "./AnalyticsPage/AnalyticsPage";
import OlNewOrderPage from "./OldNewOrderPage/NewOrderPage";
import NewOrderPage from "./NewOrderPage/NewOrderPage";
import CustomerProfilePage from "./CustomerProfilePage/CustomerProfilePage";
import SideBar from "../components/SideMenu/SideMenu";
import DriverCardJob from "../components/DriverCardJob/DriverCardJob";
import OrderDetailsPage from './OrderDetailsPage/OrderDetailsPage';
import QuotesPage from "./QuotesPage/QuotesPage";
import InspectionDetails from "../public/pages/Customer/InspectionDetails/InspectionDetails";
import DriversPage from "./DriversPage/DriversPage";
import NotificationsPage from './NotificationsPage/NotificationsPage';
import { useDispatch } from 'react-redux';
import getAllNotifications, { stopNotifications } from '../redux/actions/Notifications.action';

import {useSelector} from 'react-redux';
import { AllStates } from '../redux/reducers';
import { INotifications } from '../redux/reducers/Notifications.reducer';
import { useToasts } from 'react-toast-notifications';
import Helper from '../helper/Helper';
import ReceivablesPage from "./ReceivablesPage/ReceivablesPage";
import CustomerPage from "./CustomerPage/CustomerPage";
import PayablesPage from './PayablesPage/PayablesPage';
import SettingPage from "./SettingPage/SettingPage";
import OrdersQuotesPage from "./OrdersQuotesPage/OrdersQuotesPage";
import PendingInspectionPage from './PendingInspectionPage/PendingInspectionPage';
import InspectionReportPage from './InspectionReportPage/InspectionReport';
import WarehousePage from './WarehousePage/WarehousePage';
import OrdersPage from './OrdersPage/OrdersPage';
import environments from '../../environments/environments';
import BookOrderPage from './BookOrderPage/BookOrderPage';
import { UserSettings } from '../classes/classes';
import UserSettingsQueries from '../graphql/query/UserSettings';
import setUserSettings from '../redux/actions/UserSettings.action';
import { GraphqlService } from '../services/graphql.service';
import CarriersPage from './CarriersPage/CarriersPage';
import CarriersProfilePage from "./CarriersProfilePage/CarriersProfilePage";
import { SafeStorage } from '../services/Storage/storageService';
import NoPathRedirectPage from './RedirectPage/NoPathRedirectPage';

const MainPage = () => {

    const {data, last_id} = useSelector<AllStates>(state => state.notifications) as INotifications;
    const [myNotifications, setMyNotifications] = useState([]);
    const hasLoaded = useRef(false);
    const [isOpen, setIsOpen] = useState(false);
    const toast = useToasts();
    const [permissions, setPermissions] = useState<string[]>([]);



    useEffect(() => {

        if (hasLoaded.current)
        {
            const ids = myNotifications.map(x => x.id);
            let diff = data.filter(x => !ids.includes(x.id));
            // console.log(diff);
            diff.forEach(x => {

                toast.addToast(<div className='cursor-pointer' onClick={(evt) => Helper.Navigation.NavigateTo('/notifications')}><h2 className='font-14'>{x.title}</h2><p className='font-11'>{x.message}</p></div>, {appearance: 'info', autoDismiss: true, id: 'notification'});
            })
        }
        if (data != null)
            hasLoaded.current = true;

        setMyNotifications(data || []);

    }, [data])


        const notificationsDiff = () => (data || []).filter(x => x.id > last_id).length;


    const [height, setHeight] = useState(0);

    const array = Array.from(Array(1000).keys())
    const location = useLocation();

    const noSidebarUri = ['/inspection-report/', '/pending-inspection/'];

    const dispatch = useDispatch();


    function loadNotifications() {
        dispatch(getAllNotifications())
    }

    function onResize()
    {
        setHeight(window.innerHeight);
    }
    async function  loadSettings()
    {
        try {
            const data = await GraphqlService.SendQuery(UserSettingsQueries.GET_ALL)
            const us = new UserSettings(data);
            dispatch(setUserSettings(us));
            // dispatch();
  
        }
        catch (ex)
        {
            console.log(ex.message);
        }
    }
  
    useEffect(() => {

        loadSettings().then();
        onResize();
        if (environments.enableNotificationsListener)
            loadNotifications();
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
            dispatch(stopNotifications());
        };
    }, []);

    function handleClick(evt) {
        // console.log(evt.target.id);
        if (evt.target.id != 'user-label')
            setIsOpen(false);
    }

    useEffect(() => {
        try{
            SafeStorage.restoreUserData();
    
            if (!SafeStorage.User()?.name){
                Helper.Session.DoLogout();
            }

            setPermissions(SafeStorage.User()?.permissions);
            
            window.addEventListener('click', handleClick);
            
            return () => window.removeEventListener('click', handleClick);
        }
        catch (ex){
            console.log('Error in Main start: ' + ex.message);
            Helper.Session.DoLogout();
        }
    }, []);



    const [searchTerm, setSearchTerm] = useState('');

    return (
    <div className='main-container p-3' style={{height: height + 'px'}} >
        <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            height: '100%',
            width: '100%',
            display: 'flex'
        }}>
            {!noSidebarUri.some(x => location.pathname.startsWith(x)) && <SideBar notificationsNotSeen={notificationsDiff()}/>}
            <div className={(location.pathname.toLowerCase().startsWith('/pending-inspection/') ? '' : 'px-5 pb-3 pt-3 ') + "flex-1-container"} >
            <div className='d-flex mb-3 justify-content-end'>
                            <div className='position-relative'>
                                <h4 id='user-label' style={{minWidth:'200px', textAlign: 'end'}} onClick={(evt) => setIsOpen(true)} className='font-14 cursor-pointer'>{SafeStorage.User()?.name} <i className="fas fa-caret-down"></i></h4>
                                {isOpen && <div className='bg-white rounded' style={{position: 'absolute', width: '100%', minWidth:'200px', borderBottomLeftRadius: 16, borderBottomRightRadius: 16}}>
                                    <p onClick={(evt) => Helper.Session.DoLogout(false)} className="font-12 m-0 cursor-pointer p-3 hover-gray"><i className="mr-1 fas fa-power-off"></i> Log out</p>
                                    
                                </div>}
                            </div>
                    </div>

                    <div className="flex-1-container">
                    <Switch>
                    {/* <Route path='/orders-quotes' component={OrdersQuotesPage} /> */}
                    <Route exact path="/" component={DashboardPage}/>
                    {SafeStorage.checkPermissions('order') && <Route path="/order/:order_id/:verify?" component={OrderDetailsPage}/>}
                    {SafeStorage.checkPermissions('orders') && <Route path='/orders' component={OrdersPage} />}
                    {SafeStorage.checkPermissions('quotes') && <Route path='/quotes' component={QuotesPage} />}
                    {/* {SafeStorage.checkPermissions('dispatch') && <Route path="/dispatch" component={DispatchPage}/>} */}
                    {SafeStorage.checkPermissions('dispatch') && <Route path="/dispatch" component={()=>{Helper.Navigation.NavigateTo('/dispatch'); return <></>}}/>}
                    {SafeStorage.checkPermissions('recent-orders') && <Route path="/recent-orders" component={()=>{Helper.Navigation.NavigateTo('/recent-orders'); return <></>}}/>}
                    {SafeStorage.checkPermissions('drivers') && <Route path="/drivers" component={DriversPage}/>}
                    {SafeStorage.checkPermissions('storage') && <Route path="/storage" component={InventoryPage}/>}
                    {SafeStorage.checkPermissions('ar') && <Route path="/ar" component={ArPage}/>}
                    {SafeStorage.checkPermissions('ap') && <Route path="/ap" component={ApPage}/>}
                    {SafeStorage.checkPermissions('history') && <Route path="/history" component={HistoryPage}/>}
                    {SafeStorage.checkPermissions('network') && <Route path="/network" component={NetworkPage}/>}
                    {SafeStorage.checkPermissions('analytics') && <Route path="/analytics" component={AnalyticsPage}/>}
                    {/* {SafeStorage.checkPermissions('new-order') && <Route path='/new-order' component={OlNewOrderPage} />} */}
                    {SafeStorage.checkPermissions('new-order') && <Route path='/new-order' component={() => {Helper.Navigation.NavigateTo('/new-order'); return <></>}}/>}
                    {SafeStorage.checkPermissions('customer') && <Route path='/customer/:id/profile' component={CustomerProfilePage} />}
                    {SafeStorage.checkPermissions('quotes') && <Route path='/quotes' component={QuotesPage} />}
                    {SafeStorage.checkPermissions('customers') && <Route path='/customers' component={CustomerPage} />}
                    {SafeStorage.checkPermissions('carriers') && <Route path='/carriers/:id/profile' component={CarriersProfilePage} />}
                    {SafeStorage.checkPermissions('carriers') && <Route path='/carriers' component={CarriersPage} />}
                    {SafeStorage.checkPermissions('receivables') && <Route path='/receivables' component={ReceivablesPage} />}
                    {SafeStorage.checkPermissions('payables') && <Route path='/payables' component={PayablesPage} />}
                    {SafeStorage.checkPermissions('settings') && <Route path='/settings' component={SettingPage} />}
                    {SafeStorage.checkPermissions('notifications') && <Route path='/notifications' component={NotificationsPage} />}
                    {SafeStorage.checkPermissions('pending-inspection') && <Route path='/pending-inspection/:token' component={PendingInspectionPage} />}
                    {/* {SafeStorage.checkPermissions('warehouse') && <Route path='/warehouse' component={WarehousePage} />} */}
                    {SafeStorage.checkPermissions('warehouse') && <Route path="/warehouse" component={()=>{Helper.Navigation.NavigateTo('/warehouse'); return <></>}}/>}
                    {SafeStorage.checkPermissions('book-order') && <Route path='/book-order' component={BookOrderPage} />}
                    {<Route path='/*' component={NoPathRedirectPage}/>}
                </Switch>
                    </div>

              


            </div>
        </div>
    </div>)
}

export default MainPage;

import './DriverList.scss';
import {useEffect, useState} from "react";
import DriverForm from "../DriverForm/DriverForm";
import {useDispatch, useSelector} from "react-redux";
import {AllStates} from "../../../../redux/reducers";
import {IDriverState} from "../../../../redux/reducers/Driver.reducer";
import {getDrivers} from "../../../../redux/actions/Driver.action";
import {Driver} from "../../../../classes/classes";
import ReactModal from 'react-modal';
import {GraphqlService} from "../../../../services/graphql.service";
import EmployeeMutations from "../../../../graphql/mutation/Employee";
import useLoading from "../../../../hooks/useLoading/useLoading";
import {useToasts} from "react-toast-notifications";
import DriverMutations from "../../../../graphql/mutation/Driver";

const DriverList = () => {
    const dispatch = useDispatch();
    const {drivers, errorList} = useSelector<AllStates>(state => state.drivers) as IDriverState

    const [editDriver, setEditDriver] = useState<Driver>(null);
    const [driverList, setDriverList] = useState<Driver[]>([]);
    const [openModal,setOpenModal]=useState(false)
    const [employeeId,setEmployeeId]=useState<number>()
    const loading = useLoading();
    const toast = useToasts();

    useEffect(() => {
        dispatch(getDrivers());
    }, []);

    useEffect(() => {
        console.log(drivers);
        setDriverList([...drivers].sort((x, y) => x.name.localeCompare(y.name)));
    }, [drivers]);


    async function handleSubmit(item) {
        try {
            loading.open('Linking driver...');
            const data = await GraphqlService.SendMutation(DriverMutations.ADD_VELOX_DRIVER, {
                velox_id: item.veloxProfile.id,
                employee_id: item.employee.id,
                samsara_id: item.samsara_truck?.id || null,
                equipment_id: item.equipment?.id || null
            });

            loading.close();

            if (!data.success)
                throw new Error(data.message);

            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});

            dispatch(getDrivers());

            setOpenModal(false);
            setEditDriver(null);
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            loading.close()
        }

    }

    return(
        <>
            { errorList && <p className="font-12 font-medium">{errorList}</p> }

            <div className="row h-100">
                <div className="col-12 d-flex align-items-center justify-content-between mb-3">
                    <p className="font-14 font-medium m-0">Driver</p>
                    <p className="font-12 font-medium text-light-blue cursor-pointer"
                            onClick={() => {setEditDriver(new Driver());setOpenModal(!openModal)}}><i className="fa fa-plus"></i> NEW DRIVER</p>
                </div>

                <div className="col-5 ml-3">
                    <p className="font-14 font-medium mb-3">{ driverList.length } drivers</p>
                </div>

                {
                    editDriver ?
                        <div className="col-6 ml-3">
                            <p className="font-14 font-medium mb-3">Add new driver</p>
                        </div> : <div className="col-6"></div>
                }

                <div className="col-12 driver-list ml-3">
                    <div className="row">
                    {
                        driverList.map((e,index) => {
                            return <div key={index}
                                        className={`col-6 card cursor-pointer mb-3 ${editDriver && e.id == editDriver.id ? 'bg-light-blue' : 'bg-gray'}`}
                                        onClick={() => {setEditDriver(e);setOpenModal(!openModal);}}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                        <p className="font-14 m-0 font-medium">{ e.name }</p>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                        <p className="font-12 m-0">{ e.phones }</p>
                                    </div>

                                    <p className="font-12 m-0">{ e.email }</p>
                                </div>
                            </div>;
                        })
                    }

                </div>

                {/*
                    editDriver &&
                    <div className="col-6 driver-list">
                        <CarrierForm driver={editDriver}
                                    onCancel={() => {setEditDriver(undefined)}}
                                    onSubmit={(driver) => {
                                        console.log(driver);

                                        if (editDriver.id != 0) {
                                            let index = driverList.findIndex(e => +e.id === +editDriver.id);
                                            driverList[index] = {...driver};

                                            setDriverList([...driverList]);
                                            setEditDriver(undefined);
                                        } else {
                                            driver.id = driverList.length + 1;

                                            let temp = [...driverList];
                                            temp.push({...driver});

                                            setDriverList([...temp]);
                                            setEditDriver(undefined);
                                        }
                                    }} />
                                       */}
                    </div>


                <ReactModal className="" isOpen={openModal} onRequestClose={evt => {
                    dispatch(getDrivers());
                    setOpenModal(false)}}>
                      <DriverForm driver={editDriver}
                                    onCancel={() => {
                                        dispatch(getDrivers());
                                        setEditDriver(undefined);setOpenModal(!openModal)}}
                                    onSubmit={(driver) => {
                                        handleSubmit(driver)
                                    }} />
                 </ReactModal>
            </div>
        </>
    );
}

export default DriverList;

import React, { useEffect, useRef, useState } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import ICONS from '../../../../assets/svg';
import { CustomerProfile, CustomerProfileList } from '../../../classes/classes';
import CustomerQueries from '../../../graphql/query/Customer';
import { GraphqlService } from '../../../services/graphql.service';
import Loading from '../../Loading/Loading';
import Searchbar from '../../Searchbar/Searchbar';
import ReactModal from 'react-modal';
import {useParams} from "react-router";
import CustomerProfileForm from '../../CustomerProfileForm/CustomerProfileForm';
import { concat } from 'apollo-link';
import { MenuItem } from '@szhsin/react-menu';
import { ControlledMenu } from '@szhsin/react-menu';
interface ParamTypes {
    id: string;
}


const ContactsSearch = ({customer_id, customerProfiles, onContactsChanged, onCancel}) => {

    const contactRef = useRef<HTMLInputElement>();
    const [contacts, setContacts] = useState<CustomerProfileList[]>([]);
    const [contactLoading, setContactLoading] = useState(false);
    const [contactName, setContactName] = useState('');
    const [selectedProfile, setSelectedProfile] = useState<any>(undefined);
    const [open,setOpen] = useState(false);
    const [MyRow,setMyRow]=useState<any>()
    const [profiles, setProfiles] = useState<CustomerProfile[]>(customerProfiles || []);
    // console.log(customerProfiles);
    const contactsFiltered = () => contacts.filter(x => JSON.stringify(x).toLowerCase().includes(contactName?.toLowerCase()));

    const ref = useRef();

    const [focusID, setFocusID] = useState(null);



    useEffect(() => {
        (document.getElementById('contact-' + focusID)?.firstChild as any)?.classList.remove('bg-blue');
        setFocusID(null);
            
    }, [contactName]);

    const handleKeyUp = (evt) => {
        

        

        

        evt.stopPropagation();

        if (evt.keyCode == 27)
            return onCancel();

        const contacts = contactsFiltered();
        if (contacts.length == 0) return;

        let index = contacts.findIndex(x => x.id == focusID);
        // console.log(evt.keyCode);

        let lastID = contacts[index]?.id;
        if (evt.keyCode == 40)
        {
            if (index < contacts.length - 1)
                index++;
        }
        else if (evt.keyCode == 38)
        {
            if (index > 0)
                index--;
        }

        
        setFocusID(contacts[index]?.id);
        const id = 'contact-' + contacts[index]?.id;

        // console.log(id, lastID);
        const div = document.getElementById(id) as HTMLDivElement;
        if (!id)
            return;


        if (lastID != null)
        {
            (document.getElementById('contact-' + lastID).firstChild as any)?.classList.remove('bg-blue');
        }

        window.requestAnimationFrame(() => {
            (div?.firstChild as any)?.classList.add('bg-blue');
            div?.scrollIntoView({behavior: 'smooth',  block: 'nearest', inline: 'start'}) ;
        })
    }
    

    async function loadContacts() {
        try {
            setContactLoading(true);
            const data = await GraphqlService.SendQuery(CustomerQueries.GET_ALL_PROFILE_LIST, {customerId: customer_id});
            const temp = data.map(x => new CustomerProfileList(x)) as CustomerProfileList[];
            setContacts(temp.sort((x, y) => x.name.localeCompare(y.name)));
            setContactLoading(false);
        } catch (ex) {
            setContactLoading(false);
        }
    }

    const onContextMenuHandled = (event) => {
        event.preventDefault();
        setMyRow( { x: event.clientX, y: event.clientY });
    }

    function rowContactRenderer({key, index, style}) {
        
        const ids = customerProfiles?.map(x => x.id) || [];
        const myContact = new CustomerProfile(contactsFiltered()[index]);
        
        return (
          <div onContextMenu={(evt)=>{
                    evt.stopPropagation();
                    evt.preventDefault()
                    onContextMenuHandled(evt)
                    setSelectedProfile(myContact)}} 
            id={'contact-' + myContact?.id} key={key} style={style}>
            <div className={'bg-light position-relative cursor-pointer light-hover' + (ids.includes(myContact.id) && focusID != myContact.id ? ' bg-gold' : '')} style={{padding: '10px'}} onClick={(evt) => {

                let temp = [...customerProfiles];
                if (!ids.includes(myContact.id))
                {
                    
                    temp.push(myContact);
                  
                }
                else
                {
                    
                    temp = temp.removeBy(x => x.id == myContact.id);
                }
                onContactsChanged(temp);
                
            }}>
                {ids.includes(myContact.id) && <i className=' fas fa-check' style={{position: 'absolute', right: '10px', fontSize: '11px'}}></i>}
                <p style={{fontSize: '12px'}} className=" font-bold">{myContact.name}</p>
                <p  style={{fontSize: '12px'}} className={'mt-1 mb-1' + (myContact.title ? '' : ' text-gray font-bold')}> {myContact.title ? `${myContact.title}` : 'NO TITLE'}</p>
                <p  style={{fontSize: '12px', fontWeight: 'normal'}}>{myContact.email}</p>
         
            </div>
          </div>

        );
      }


    useEffect(() => {
        if (customer_id)
            loadContacts().then();
    }, [customer_id]);

    useEffect(() => {
        contactRef.current?.focus();
    }, []);

    return( <><div  onClick={(evt) => evt.stopPropagation()} className="flex-1-container" >
    <div className="row mb-3">
              <div className="d-flex">
              <h4 onClick={onCancel} className="font-9"><i className='fas fa-times cursor-pointer mr-2 text-red'></i></h4>
                <h4 onClick={()=>setSelectedProfile(new CustomerProfile())} className="font-9">Contacts: {contactsFiltered().length} / {contacts.length}</h4>
              </div>
              <h4 onClick={()=>setOpen(true)} style={{marginTop: '-.45rem', marginRight: '-.45rem'}} className="d-flex align-items-center font-9 text-blue-light cursor-pointer"><img src={ICONS.IconPlus} alt="" /></h4>
          </div>
          <div  className="d-flex align-items-center">
              <div className="col-12">
                  <Searchbar onKeyEnter={(evt) => {
                      if (!focusID) return;
                      
                      const ids = customerProfiles.map(x => x.id);
                      let temp = [...customerProfiles];
                      
                      if (!ids.includes(focusID))
                      {
                          
                          temp.push(contacts.find(x => x.id == focusID));
                          
                      }
                      else
                      {
                          
                          temp = temp.removeBy(x => x.id == focusID);
                      }
                      onContactsChanged(temp);
                  }} onKeyUp={handleKeyUp} onBlur={(evt) => {
                      (document.getElementById('contact-' + focusID)?.firstChild as any)?.classList.remove('bg-blue');
                      setFocusID(null);
                  }} value={contactName} onChange={(evt) => setContactName(evt.target.value)} background={'#f2f2f2'} reference={contactRef} />
              </div>
              <button onClick={(evt) => loadContacts()} className="btn btn-icon-only ml-2"><img src={ICONS.IconRefresh} className={contactLoading ? 'spin' : ''} alt="" /></button>
          </div>

          <div className="col-12 mt-2 row align-items-center">
              <h4 className="font-9">Selected: {customerProfiles.length}</h4>
              {customerProfiles.length > 0 && <button id='unselectall' className="btn btn-danger-light" onClick={(evt) => {
                  onContactsChanged([]);
                  
              }}>UNSELECT ALL</button>}
          </div>
          <div ref={ref} className='flex-1-container mt-3'>
              {contactLoading && <div>
                      <Loading />
                  </div>}
          {!contactLoading && <AutoSizer>
              {({height, width}) => (
              <List
                  height={height}
                  rowCount={contactsFiltered().length}
                  rowHeight={({index}) => {
                      const contact = contactsFiltered()[index];
                      if (!contact) return 100;
                      return contact.email ? 86 : 74;
                  }}
                  rowRenderer={rowContactRenderer}
                  width={width}
              />
              )}
          </AutoSizer>}
                <ControlledMenu boundingBoxRef={ref}
                                onContextMenu={(evt) => {
                                    evt.stopPropagation();
                                    evt.preventDefault()
                                }}
                                anchorPoint={MyRow}
                                isOpen={ MyRow != null}
                                animation={ false }
                                onClose={(evt) => {
                                    setMyRow(null)
                                }}>
                    {
                     
                        <MenuItem   className='my-menuitem'
                                    onClick={()=>{setOpen(true);setMyRow(null)}} 
                                    style={{paddingLeft: '0'}}> 
                                    <img style={{marginLeft: '.1rem', marginRight: '.45rem', width: '20px'}} src={ICONS.IconDetails} alt=""/>Edit Contact
                        </MenuItem>
                    }
                </ControlledMenu>
          </div>
    </div>

                {
                    <ReactModal isOpen={open} onRequestClose={()=>setOpen(false)}
                            className={"modal-fit modal-90"}
                            //style={{content: {borderRadius: '12px', width: '496px', paddingBottom: '0', minWidth: '496px'}}}
                            >
                            <CustomerProfileForm customerId={customer_id}
                                                customerProfile={selectedProfile}
                                                onSubmit={(customerProfile) => {
                                                    setSelectedProfile(undefined);
                                                    if (customerProfile) {
                                                        let index = contacts.findIndex(x => x.id == customerProfile.id )
                                                        let temp = [...contacts];
                                                        if(index === -1){
                                                            temp.push(customerProfile);
                                                            setContacts([...temp])
                                                            let tempChanged = [...customerProfiles];
                                                            tempChanged .push(customerProfile)
                                                            onContactsChanged(tempChanged)
                                                            setOpen(false)
                                                        }else{
                                                        setContacts(contacts.map(x=>{
                                                                if(x.id === customerProfile.id){
                                                                    x = customerProfile
                                                                }
                                                                return x
                                                        }))
                                                        setOpen(false)
                                                        }
                                                    } 
                                                    setOpen(false)
                                                }}/>
                    </ReactModal>
                }
            </>
            )
}

export default ContactsSearch;
import {Address} from "../location/location";
import {Customer_Profile} from "../customer_profile/customer_profile";
import {Customer} from "../customer/customer";
import {Invoice} from "../invoice/invoice";
import {Vehicle_Order, Vehicle_Order_Input} from "../vehicle_order/vehicle_order";
import {New_Charge_Input, Service_Charge} from "../service_charge/service_charge";
import {Location} from '../location/location'
import {Log} from "../storage_order/storage_order";
import {Attribute} from "../attribute/attribute";
import {Quote} from "../quote/quote";
import { User } from "../../../../classes/classes";

export class Master_Order {
    __typename?: "Master_order"
    id?: number;
    customer?: Customer = new Customer();
    time?: string | Date = new Date();
    user?: User = new User();
    notes?: string[] = [];
    started?: boolean = false;
    completed?: boolean = false;
    price?: number = 0;
    payment_left?: number = 0;
    paid?: boolean = false;
    terms?: string = '';
    canceled?: boolean = false;
    references?: Reference[] = [{key: 'PO', value: ''}, {key: 'RO', value: ''}, {key: 'STOCK', value: ''}];
    service_charges?: Service_Charge[] = [];
    contacts?: Customer_Profile[] = [];
    vehicle_orders?: Vehicle_Order[] = [];
    billing_address?: Location = new Location();
    card?: Attribute = new Attribute();
    logs?: Log[] = [];
    needs_invoice?: boolean = true;

    constructor(x?: Master_Order) {
        //console.log('new Master order, x: ', x);
        if (x) {
            // console.log('here 1')
            this.id = x.id;
            this.customer = new Customer(x.customer);
            this.time = new Date(x.time);
            this.user = new User(x.user);
            if (x.notes)
                this.notes = x.notes?.map(z => z) || [];
            this.started = x.started;
            this.completed = x.completed;
            this.price = x.price;
            this.payment_left = x.payment_left;
            this.paid = x.paid;
            if (x.references)
                this.references = x.references?.map(r => ({...r}));
            this.service_charges = !x.service_charges ? [] : x.service_charges.map(c => {
                c.master_order_id = x.id;
                return {...c}
            });
            this.contacts = !x.contacts ? [] : x.contacts.map(c => new Customer_Profile(c));
            //console.log('vehicle orders: ', x.vehicle_orders);
            this.vehicle_orders = !x.vehicle_orders ? [] : x.vehicle_orders.map(c => new Vehicle_Order(c));
            //console.log('vehicle orders after: ', this.vehicle_orders);
            this.billing_address = new Location(x.billing_address);
            this.card = new Attribute(x.card);
            this.logs = x.logs?.map(log => ({...log})) || [];
            this.needs_invoice = x.needs_invoice == null ? true : x.needs_invoice;
        }
    }

    getServiceChargesBreakDown() {
        let vehiclesList = [];
        this.vehicle_orders.map((vehicles) => {
            const serviceAmount = vehicles.charges.sumBy(x => x.amount);
            const legsAmount = vehicles.legs.sumBy(x => x.getServiceChargesTotal());


            const serviceAmountR = vehicles.charges.sumBy(x => x.amount_remaining);
            const legsAmountR = vehicles.legs.sumBy(x => (x.service_charges && x.service_charges[0]?.amount_remaining) || 0);

            const equipments = vehicles.legs.reduce((previousValue, currentValue) => {
                if (!previousValue[currentValue.equipment]) {
                    previousValue[currentValue.equipment] = 1;
                } else {
                    previousValue[currentValue.equipment] += 1;
                }

                return previousValue;
            }, {});

            vehiclesList.push(
                {
                    id: vehicles.id,
                    legs: vehicles.legs,
                    charges: vehicles.charges,
                    noFailCount: vehicles.legs.sumBy(x => x.getNoFailCount()),
                    equipments: Object.keys(equipments).map(key => {
                        return {equipment: key, count: equipments[key]}
                    }),
                    totalLegs: legsAmount,
                    vehicle: vehicles.vehicle,
                    amount: serviceAmount,
                    totalCost: serviceAmount + legsAmount,
                    totalRemaining: serviceAmountR + legsAmountR,
                    storage_order: {...vehicles.storage_order}
                })
        });

        const x = {
            breakdowns: vehiclesList,
            id: this.id,
            charges: this.service_charges
        };

        return x;

    }

    getTotalAmount() {
        return this.getServiceChargesBreakDown().breakdowns.sumBy(e => e.totalCost)
    }

    getTotalAmountRemainig() {
        return this.getServiceChargesBreakDown().breakdowns.sumBy(e => e.totalRemaining)
    }
}

export class Logs_ {
    __typename?: "Logs_"
    time?: string | Date = new Date();
    user?: User_ = new User_();
    message?: string = '';
    master_id?: number = null;
    customer_profile_id?: number = null;
    customer_profile?: User_ = new User_();
    vehicle_order_id?: number = null;

    constructor(x?: Logs_){
        if(x){
            this.time =  new Date(x.time);
            this.user = new User_(x.user);
            this.message = x.message;
            this.master_id = x.master_id;
            this.customer_profile_id = x.customer_profile_id;
            this.customer_profile = new User_(x.customer_profile);
            this.vehicle_order_id = x.vehicle_order_id;            
        }
    }

}

export class Confirmation_Result {
    __typename?: "Confirmation_Result"
    emails?: string[] = [];
    phones?: string[] = [];
    body?: string = '';

    constructor(x?: Confirmation_Result){
        if(x){
            this.emails = x.emails?.map(z => z) || [];
            this.phones = x.phones?.map(z => z) || [];
            this.body = x.body;
        }
    }
}

export class User_ {
    __typename?: "USER_"
    id?: number = null;
    name?: string = '';

    constructor(x?: User_){
        if(x){
            this.id = x.id
            this.name = x.name
        }
    }
}

export class Receivable_Aging_Res {
    __typename?: "receivable_aging_res"
    id?: number = null;
    name?: string = '';
    current?: string = '';
    plus30?: string = '';
    plus60?: string = '';
    plus90?: string = '';
    total?: string = '';
    not_picked_up?: string = '';
    not_delivered?: string = '';
    non_move?: string = '';
    internal_note?: string = '';

    constructor(x?: Receivable_Aging_Res){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.current = x.current;
            this.plus30 = x.plus30;
            this.plus60 = x.plus60;
            this.plus90 = x.plus90;
            this.total = x.total;
            this.not_picked_up = x.not_picked_up;
            this.not_delivered = x.not_delivered;
            this.non_move = x.non_move;
            this.internal_note = x.internal_note;            
        }
    }
}

export class Master_Order_Invoice_Charge_Pdf {
    __typename?: "Master_order_invoice_charge_pdf"
    id?: number = null;
    data?: string = '';

    constructor(x?: Master_Order_Invoice_Charge_Pdf) {
        if(x){
            this.id = x.id;
            this.data = x.data;
        }
    }
}

export class Master_Order_Invoice_Charge_Sp {
    __typename?: "Master_order_invoice_charge_SP"
    id?: number = null;
    invoice_id?: number = 0;
    master_order_id?: number = null;
    contacts?: Customer_Profile[] = [];
    customer?: C_Invoice_Sp = new C_Invoice_Sp();
    references?: Reference[] = [];
    date_due?: string = '';
    date_issued?: string = '';
    vehicle_orders?: Vehicle_Order_Invoice[] = [];
    service_charges?: Service_Charge[] = [];
    payment_left?: number = 0;
    amount?: number = 0;

    constructor(x?: Master_Order_Invoice_Charge_Sp){
        if(x){
            this.id = x.id;
            this.invoice_id = x.invoice_id;
            this.master_order_id = x.master_order_id;
            this.contacts = x.contacts?.map(z => z) || [];
            this.customer =  new C_Invoice_Sp(x.customer);
            this.references = x.references?.map(z => z) || [];
            this.date_due = x.date_due;
            this.date_issued = x.date_issued;
            this.vehicle_orders = x.vehicle_orders?.map(z => z) || [];
            this.service_charges = x.service_charges?.map(z => z) || [];
            this.payment_left = x.payment_left;
            this.amount = x.amount;            
        }
    }
}

export class C_Invoice_Sp {
    __typename?: "C_invoice_sp"
    id?: number = null;
    name?: string = '';
    billing_address?: Address = new Address();

    constructor(x?: C_Invoice_Sp){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.billing_address = new Address(x.billing_address);
        }
    }
}

export class Reference {
    __typename?: "Reference"
    key: string = '';
    value: string = ''; 

    constructor(x?: Reference){
        if(x){
            this.key = x.key;
            this.value = x.value;
        }
    }
}

export class Vehicle_Order_Invoice {
    __typename?: "Vehicle_order_invoice"
    vehicle?: Vehicle_Order_Vehice_Invoice = new Vehicle_Order_Vehice_Invoice();
    service_charges?: Service_Charge[] = [];
    transportation_charges?: Transportation_Charges = new Transportation_Charges();

    constructor(x?: Vehicle_Order_Invoice) {
        if(x){
            this.vehicle = new Vehicle_Order_Vehice_Invoice(x.vehicle);
            this.service_charges = x.service_charges?.map(z => z) || [];
            this.transportation_charges = new Transportation_Charges(x.transportation_charges);
        }
    }
}

export class Vehicle_Order_Vehice_Invoice {
    __typename?: "Vehicle_order_vehice_invoice"
    vin?: string = '';
    description?: string = '';
    vehicle_order_id?: number = null;

    constructor(x?: Vehicle_Order_Vehice_Invoice){
        if(x){
            this.vin = x.vin;
            this.description = x.description;
            this.vehicle_order_id = x.vehicle_order_id;
        }

    }
}

export class Transportation_Charges {
    __typename?: "Transportation_charges"
    amount?: number = 0;
    pickup?: PDtc = new PDtc();
    dropoff?: PDtc = new PDtc();
    amount_remaining?: number = 0;

    constructor(x?: Transportation_Charges){
        if(x){
            this.amount = x.amount;
            this.pickup = new PDtc(x.pickup);
            this.dropoff = new PDtc(x.dropoff);
            this.amount_remaining = x.amount_remaining;            
        }
    }
}

export class PDtc {
    __typename?: "PDtc"
    title?: string = '';
    address?: Address = new Address();
    is_pickup?: boolean = false;
    time_scheduled?: string = '';

    constructor(x?: PDtc){
        if(x){
            this.title = x.title;
            this.address = new Address(x.address);
            this.is_pickup = x.is_pickup;
            this.time_scheduled = x.time_scheduled;            
        }
    }
}

export class Batch_Master_References {
    id?: number = null;
    po?: string = '';
    ro?: string = '';
    stock?: string = '';

    constructor(x?: Batch_Master_References){
        if(x){
            this.id = x.id;
            this.po = x.po;
            this.ro = x.ro;
            this.stock = x.stock;    
        }
    }
}

export class Quote_Stop_Input {
    contacts?: Quote_Order_Contacts[] = [];
    location?: Location_Quote = new Location_Quote();
    guaranteed?: boolean = false;
    pickup_date?: string = '';
    dropoff_date?: string = '';

    constructor(x?:Quote_Stop_Input){
        if(x){
            this.contacts = x.contacts?.map(z => z) || [];
            this.location = new Location_Quote(x.location);
            this.guaranteed = x.guaranteed;
            this.pickup_date = x.pickup_date;
            this.dropoff_date = x.dropoff_date;            
        }
    }
}

export class Location_Quote {
    id?: number = null;
    address?: Address_New_Quote_Order = new Address_New_Quote_Order();
    title?: string = '';
    driver_note?: string = '';

    constructor(x?: Location_Quote){
        if(x){
            this.id = x.id;
            this.address =  new Address_New_Quote_Order(x.address);
            this.title = x.title;
            this.driver_note = x.driver_note;  
        }
    }
}

export class Address_New_Quote_Order {
    city?: string = '';
    state?: string = '';
    street?: string = '';
    country?: string = '';
    postal_code?: string = '';
    street_number?: string = '';
    postal_code_suffix?: string = '';

    constructor(x?: Address_New_Quote_Order){
        if(x){
            this.city = x.city;
            this.state = x.state;
            this.street = x.street;
            this.country = x.country;
            this.postal_code = x.postal_code;
            this.street_number = x.street_number;
            this.postal_code_suffix = x.postal_code_suffix;            
        }
    }
}

export class Quote_Order_Contacts {
    id?: number = null;

    constructor(x?:Quote_Order_Contacts) {
        if(x){
            this.id = x.id
        }
    }
}

export class Master_Order_Input {
    customer_id?: number = null;
    terms?: string = '';
    price?: number = 0;
    payment_left?: number = 0;
    notes?: Notes_Input[] = [];
    contacts?: Master_Order_Contacts_Input[] = [];
    references?: Reference_Input[] = [];
    vehicle_orders?: Vehicle_Order_Input[] = [];
    charges?: New_Charge_Input[] = [];
    billing_address?: string = '';
    attribute_id?: number = null;

    constructor(x?:Master_Order_Input){
        if(x){
            this.customer_id = x.customer_id;
            this.terms = x.terms;
            this.price = x.price;
            this.payment_left = x.payment_left;
            this.notes = x.notes?.map(z => z) || [];
            this.contacts = x.contacts?.map(z => z) || [];
            this.references = x.references?.map(z => z) || [];
            this.vehicle_orders = x.vehicle_orders?.map(z => z) || [];
            this.charges = x.charges?.map(z => z) || [];
            this.billing_address = x.billing_address;
            this.attribute_id = x.attribute_id; 
        }
    }
}

export class Master_Order_Contacts_Input {
    customer_profile_id?: number = null;

    constructor(x?:Master_Order_Contacts_Input){
        if(x){
            this.customer_profile_id = x.customer_profile_id;
        }
    }
}

export class Notes_Input {
    time?: string | Date = new Date();
    message?: string = '';
    user_id?: number = null;

    constructor(x?: Notes_Input){
        if(x){
            this.time =  new Date(x.time);
            this.message = x.message;
            this.user_id = x.user_id;
        }
    }
}

export class Reference_Input {
    key?: string = '';
    value?: string = '';

    constructor(x?:Reference_Input){
        if(x){
            this.key = x.key;
            this.value = x.value;
        }
    }
}

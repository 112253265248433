import './SettingPage.scss';
import {useEffect, useState} from "react";
import CompanyInfo from "./components/CompanyInfo/CompanyInfo";
import EmployeeList from "./components/EmployeeList/EmployeeList";
import DriverList from "./components/DriverList/DriverList";
import BankAccountList from "./components/BankAccounList/BankAccountList";
import BolDisclaimer from "./components/BolDisclaimer/BolDisclaimer";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../services/graphql.service";

let settingQuery = gql(`query {
  all_settings {
    key
    value
  }
}`);

const SettingPage = () => {
    const [menu, setMenu] = useState(1);
    const [setting, setSetting] = useState<any[]>([]);

    const [merchantBankAccount, setMerchantBankAccount] = useState();
    const [defaultCheckAccount, setDefaultCheckAccount] = useState();

    useEffect(() => {
        getSettings().then();
    }, []);

    const getSettings = async () => {
        return GraphqlService.SendQuery(settingQuery).then(settings => {
            setSetting(settings);

            setMerchantBankAccount(settings.find(e => e.key == 'MERCHANT_BANK_ACCOUNT').value);
            setDefaultCheckAccount(settings.find(e => e.key == 'DEFAULT_CHECK_ACCOUNT').value);
        }).catch(err => {
            
        });
    }

    return(
        <>
            <div className="flex-1-container setting-container">
                <div className="setting-page flex-1-container">
                    <div className="flex-1-container">
                        <div className="col-12 mb-4">
                            <p className="m-0 font-18 font-medium">Setting</p>
                        </div>

                        <div className="flex-1-container row-container">
                        <div className="col-2 d-flex">
                            <ul className="setting-menu m-0">
                                <li className={`font-14 font-medium ${menu === 1 ? 'active' : ''}`}
                                    onClick={() => setMenu(1)}>Company info</li>
                                <li className={`font-14 font-medium ${menu === 2 ? 'active' : ''}`}
                                    onClick={() => setMenu(2)}>Employees</li>
                                <li className={`font-14 font-medium ${menu === 3 ? 'active' : ''}`}
                                    onClick={() => setMenu(3)}>Drivers</li>
                                <li className={`font-14 font-medium ${menu === 4 ? 'active' : ''}`}
                                    onClick={() => setMenu(4)}>Bank Accounts</li>
                                <li className={`font-14 font-medium ${menu === 5 ? 'active' : ''}`}
                                    onClick={() => setMenu(5)}>BOL disclaimer</li>
                            </ul>
                        </div>

                        <div className="col-10 d-flex bg-white p-3 rounded">
                            <div className='flex-1-container'>
                                { menu === 1 && setting.length > 0 && <CompanyInfo onUpdated={() => getSettings().then()} setting={setting} /> }
                                        { menu === 2 && <EmployeeList /> }
                                        { menu === 3 && <DriverList /> }
                                        { menu === 4 && <BankAccountList merchantBank={merchantBankAccount}
                                                                        defaultCheck={defaultCheckAccount} /> }
                                        { menu === 5 && <BolDisclaimer /> }
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SettingPage;
import CustomerTable from "../../components/CustomerTable/CustomerTable";

const CustomerPage = () => {
    return(
        <>
        {/* bg-white p-3 rounded */}
            <div className="flex-1-container">
                <h4 className="font-14 mb-3">Customers</h4>
                <div className="flex-1-container bg-white p-3 rounded">
                    <CustomerTable refresh={true} />
                </div>
            </div>
        </>
    );
}

export default CustomerPage;
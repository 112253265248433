import {Address, Work_Hour_Input} from "../location/location";
import {ContactInput} from "../customer_profile/customer_profile";

export class Customer {
    __typename?: "Customer"
    id?: number = null;
    name?: string = '';
    corporate_client?: boolean = false; //Attention
    key?: string = '';
    terms?: string = '';
    internal_note?: string = '';
    active?: boolean = false;
    tax_number?: Tax_Info = new Tax_Info();
    billing_address?: Address = new Address();
    default_invoice_email?: string = '';

    constructor(x?: Customer) {
        if (x) {
            this.id = x.id;
            this.name = x.name;
            this.corporate_client = x.corporate_client;
            this.key = x.key;
            this.terms = x.terms;
            this.internal_note = x.internal_note;
            this.active = x.active;
            this.tax_number = new Tax_Info(x.tax_number);
            this.billing_address = new Address(x.billing_address);
            this.default_invoice_email = x.default_invoice_email;            
        }
    }
    
    getType?() {
        return `${this.corporate_client ? 'Corporate' : 'Private'}`
    }
}

export class Tax_Info {
    __typename?: "Tax_info"
    type: string = '';
    value: string = '';
    constructor(x?: Tax_Info){
        if(x){
            this.type = x.type;
            this.value = x.value;
        }
    }
}

export class Customer_Details_Info {
    __typename?: "Customer_Details_Info"
    quoted?: number = 0;
    needs_quote?: number = 0;
    needs_invoice?: number = 0;
    open_orders?: number = 0;
    recent_payments?: number = 0;

    constructor(x?: Customer_Details_Info) {
        if(x){
            this.quoted = x.quoted;
            this.needs_quote = x.needs_quote;
            this.needs_invoice = x.needs_invoice;
            this.open_orders = x.open_orders;
            this.recent_payments = x.recent_payments;            
        }
    }
}

export class Customer_Location_Sp {
    __typename?: "Customer_location_sp"
    customer_id?: number = null;
    name?: string = '';
    key?: string = '';
    title?: string = '';
    location_id?: number = null;
    address?: Address = new Address();
    corporate_client?: boolean = false; // Attention
    active?: boolean = false; // Attention

    constructor(x?: Customer_Location_Sp){
        if(x){
            this.customer_id = x.customer_id
            this.name = x.name
            this.key = x.key
            this.title = x.title
            this.location_id = x.location_id
            this.address = new Address(x.address)
            this.corporate_client = x.corporate_client
            this.active = x.active            
        }
    }
}

export class NewCustomerRes {
    __typename?: "newCustomerRes"
    message?: string = '';
    success?: boolean = false;
    customer_id?: number = null;

    constructor(x?: NewCustomerRes){
        if(x) {
            this.message = x.message;
            this.success = x.success;
            this.customer_id = x.customer_id;           
        }
    }
}

export class Customer_Input {
    name: string = '';
    corporate_client: boolean = false; // Attention
    key?: string = '';
    terms: string = '';
    internal_note: string = '';
    location?: NewCustomer_Location = new NewCustomer_Location();
    customer_profile: Profile_Input = new Profile_Input();
    tax_number?: Tax_Info_Input = new Tax_Info_Input();
    default_invoice_email?: string = '';

    constructor(x?: Customer_Input){
        if(x){
            this.name = x.name;
            this.corporate_client = x.corporate_client;
            this.key = x.key;
            this.terms = x.terms;
            this.internal_note = x.internal_note;
            this.location = new NewCustomer_Location(x.location);
            this.customer_profile = new Profile_Input(x.customer_profile);
            this.tax_number = new Tax_Info_Input(x.tax_number);
            this.default_invoice_email = x.default_invoice_email;            
        }
    }
}

export class Tax_Info_Input {
    type?: string = '';
    value?: string = '';

    constructor(x?: Tax_Info_Input){
        if(x){
            this.type = x.type;
            this.value = x.value;
        }
    }
}

export class Update_Customer_Input {
    name: string = '';
    corporate_client: boolean = false;
    key: string = '';
    terms: string = '';
    internal_note: string = '';
    tax_number?: Tax_Info_Input = new Tax_Info_Input();
    default_invoice_email: string = '';
    billing_address?: Address_Input = new Address_Input();

    constructor(x?:Update_Customer_Input){
        if(x){
            this.name = x.name;
            this.corporate_client = x.corporate_client;
            this.key = x.key;
            this.terms = x.terms;
            this.internal_note = x.internal_note;
            this.tax_number = new Tax_Info_Input(x.tax_number );
            this.default_invoice_email = x.default_invoice_email;
            this.billing_address =  new Address_Input(x.billing_address);            
        }
    }
}

export class NewCustomer_Location {
    address: Address_Input = new Address_Input();
    driver_note?: string = '';
    internal_note?: string = '';
    work_hours?: Work_Hour_Input = new Work_Hour_Input();

    constructor(x?: NewCustomer_Location){
        if(x){
            this.address = new Address_Input(x.address );
            this.driver_note = x.driver_note;
            this.internal_note = x.internal_note;
            this.work_hours = new Work_Hour_Input(x.work_hours);             
        }
    }
}

export class Customer_Contact_Input {
    type: string = '';
    title: string = '';
    value: string = '';
    constructor(x?: Customer_Contact_Input){
        if(x){
            this.type = x.type;
            this.title = x.title;
            this.value = x.value;
        }
    }
}

export class Address_Input {
    city: string = '';
    state: string = '';
    street: string = '';
    country: string = '';
    postal_code: string = '';
    street_number: string = '';
    postal_code_suffix?: string = '';

    constructor(x?: Address_Input){
        if(x){
            this.city = x.city;
            this.state = x.state;
            this.street = x.street;
            this.country = x.country;
            this.postal_code = x.postal_code;
            this.street_number = x.street_number;
            this.postal_code_suffix = x.postal_code_suffix;
        }
    }
}

export class Profile_Input {
    name: string = '';
    email?: string = '';
    title?: string = '';
    contact?: ContactInput[] = [];

    constructor (x?: Profile_Input) {
        if(x){
            this.name = x.name;
            this.email = x.email;
            this.title = x.title;
            this.contact = x.contact?.map(z => z) || [];            
        }
    }
}

export class CustomerSearchList {
    __typename?: "customerSearchList"
    id?: number = null;
    key?: string = '';
    name?: string = '';
    corporate_client?: boolean = false;
    billing_address?: Address = new Address();

    constructor(x?: CustomerSearchList){
        if(x){
            this.id = x.id;
            this.key = x.key;
            this.name = x.name;
            this.corporate_client = x.corporate_client;
            this.billing_address = new Address(x.billing_address);            
        }
    }
}

import IColumn from "../../components/Table/models/IColumn";

const columns: IColumn[] = [
    {
        label: 'CUSTOMER',
        name: 'customer',
        active: false,
        orderBy: 'DESC',
    },
    {
        label: 'MASTER #',
        name: 'master_column',
        active: false,
        orderBy: 'DESC',
        sort_by: 'master_order_id'

    },
    {
        label: 'VEHICLE',
        name: '_vehicle',
        active: false,
        orderBy: 'DESC',
    },
    {
        label: 'BILLING CYCLE',
        name: 'billing_cycle',
        active: false,
        orderBy: 'DESC',
    },
    {
        label: 'RATE',
        name: 'rate_column',
        active: false,
        orderBy: 'DESC',
        sort_by: 'rate'
    },
    {
        label: 'LOCATION',
        name: 'location',
        active: false,
        orderBy: 'DESC',
    },
    {
        label: 'BILLING STATUS',
        name: 'status',
        active: false,
        orderBy: 'DESC',
    }
];

export default columns;
import React, { FC } from 'react';
import { Leg } from '../../../../classes/classes';

interface ILegCard
{
    onClick?: (evt) => any,
    leg?: Leg,
    margin?: boolean
}


const LegCard: FC<ILegCard> = (props) => {
    return <div className={`${props.margin ? 'mt-3 ' : ''}p-2 bg-white rounded`} onClick={props.onClick}>
        <div className="position-relative row" >
            <div>
                <p className="font-8 mb-0-5 font-bold">11067 Main ST</p>
                <p className="font-8 font-bold">Beverly Hills, CA 90000</p>
                <p className="font-8 mt-1">12/24/2021 10:00 AM</p>
                {<p className="font-9 text-red font-bold mt-1">NO FAIL</p>}
            </div>

            <div className={'position-absolute'} style={{top: '50%', left: '50%', width: '4px', height: '2px', background: '#000'}} />

            <div>
                <p className="font-8 mb-0-5 font-bold">11067 Main ST</p>
                <p className="font-8 font-bold">Beverly Hills, CA 90000</p>
                <p className="font-8 mt-1">12/24/2021 10:00 AM</p>
            </div>
        </div>

        <hr className="my-2" />

        <div className="row">
            <div className="col-6">
                <p className="font-bold font-8 mb-0-5">Carrier/Driver</p>
                <p className='font-8 mb-0-5'>JP Logistics</p>
                <p className='font-8'>Gevork</p>
            </div>

            <div className="col-6">
                <p className="font-bold font-8 mb-0-5">Price/Pay</p>
                <p className='font-8 mb-0-5'>$100.00</p>
                <p className='font-8'>$20.00</p>
            </div>
        </div>
    </div>
}

export default LegCard;
import React,{useState} from "react";
import './ConfirmationForm.scss'
import FormGroup from "../../../../components/FormGroup/FormGroup";
import CarInspection from '../../../../../assets/images/inspection_car.png'
import Signature from "../Signature/Signature";
import {PendingInspection} from "../../../../classes/classes";
import ReactModal from "react-modal";
import VehicleShowDamages from "../VehicleShowDamages/VehicleShowDamages";

type IProps = {
   data: PendingInspection,
   signatures: any,
   onSubmitSignature: any
}

const ConfirmationForm = ({data, signatures, onSubmitSignature} : IProps)=>{
    const[open,setOpen]=useState(false)
    const[signature,setSignature]=useState(signatures)
    console.log(signatures)
    const[paramt,setParamt]=useState('')

    async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {

        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: 'image/png' });
    }


    return(
        <>
            {/* <div className="row ">
                <div className="col-12">
                    <span className={'main-text'}>Location</span>
                </div>
                <div className="col-6 d-flex flex-column">
                    <span className={'second-label'}>
                        Origin
                    </span>
                    <span className={'main-text'}>
                        Rolls-Royce & Bentley & Bugatti Service Beverly Hills
                    </span>
                    <span className={'second-text'}>
                        1000 Main St.
                        <br/>
                        Beverly Hills, CA 99999
                    </span>
                </div>
                <div className="col-6 d-flex flex-column">
                    <span className={'second-label'}>
                        Point of Contact
                    </span>
                    <span className={'main-text'}>
                        Natasha Gamble
                    </span>
                    <span className={'second-text'}>
                        (000) 000-0000
                    </span>
                    <span className={'second-text'}>
                        natashagamble@email.com
                    </span>
                </div>
                <div className="col-6 d-flex flex-column">
                    <span className={'second-label'}>
                        Destination
                    </span>
                    <span className={'main-text'}>
                        John Doe
                    </span>
                    <span className={'second-text'}>
                        1000 Main St.
                        <br/>
                        Beverly Hills, CA 99999
                    </span>
                </div>
                <div className="col-6 d-flex flex-column">
                    <span className={'second-label'}>
                        Point of Contact
                    </span>
                    <span className={'main-text'}>
                        Natasha Gamble
                    </span>
                    <span className={'second-text'}>
                        (000) 000-0000
                    </span>

                    <span className={'second-text'}>
                        natashagamble@email.com
                    </span>
                </div>
            </div> */}

            <div className="row">
                <div className="col-12 mb-3 mt-2">
                    <span className={'main-text'}>Vehicle & Inspection</span>
                </div>
                <div className="col-12">
                    <span className={'second-label'}>VIN/Year/Make/Model</span>
                </div>
                <div className={'mt-2'}>
                    <span className={'main-text'}>
                        {data.vehicle_data.vin} <br/>
                        <span className={'second-text'}>  {data.vehicle_data.getName()} </span>
                    </span>
                </div>
                <div className="col-12 d-flex flex-column mt-3 mb-3">
                    <span className={'second-label'}>Accessories</span>
                    <span className={'main-text'}>
                        {data.accessories.map((item,i)=>{
                            return `${item.name + ' ('+item.count+')'}${i != (data.accessories.length-1)?", ":""}`
                        })}
                    </span>
                </div>
                <div className="col-12 d-flex flex-column mt-3 mb-3">
                    <span className={'second-label'}>Inspection Notes</span>
                    <span className={'main-text'}>
                            {data.note}
                    </span>
                </div>
            </div>

            <div className="row ">
                <div className="col-12">
                    <span className={'second-label'}>Inspection</span>
                </div>
                <div className={'w-100 d-flex align-item-center'}>
                    <VehicleShowDamages damageList={data.damages}/>
                </div>
            </div>

            <div className="row ">
                <div className="col-12">
                    <span className={'second-label'}>Attention</span>
                </div>
                <div className="col-12">
                    <span className={'second-text'}>term and condition</span>
                </div>
            </div>

            <div className="row">
                <div className="col-12 mt-5 mb-2">
                    <span className={'main-text'}>Signature</span>
                </div>
                <div className="col-12 d-flex flex-column mt-3 mb-3">
                    <span className={'second-text'}>
                        I RELEASE THIS VEHICLE TO JP MOTORSPORT,INC AND GIVE
                        PERMISSION TO DRIVE THE VEHICLE TO AND FORM TRUCK.
                    </span>
                </div>

                {/* img signature person at origin*/}
                <h4 className="fs-18 col-12 mb-3">Signed By</h4>
                {signatures['origin'] && <div className="col-12">
                    <img className={'imgsignature-saved col-12'} src={signatures['origin']} alt="" />
                </div>}

                {data.signed_by.name && <h4 className="fs-16">{data.signed_by.name}</h4>}

                <div className="col-12">
                    <button onClick={()=>{
                            setOpen(true)
                            setParamt('origin')
                            }}
                            className={"btn w-100 py-2 btn-blue-light rounded-pill fs-12 mt-3 mb-4 font-medium-i"}>
                            ADD/REPLACE SIGNATURE
                    </button>

                    {/* <FormGroup  name={'personatorigin'}
                                value={''}
                                label={'Person at Origin'}
                            onTextChange={value => console.log(value)}/>

                    <span className={'main-text'}>
                        01/01/2021
                        </span>*/}
                </div>

                {/* img signature Driver*/}
                <h4 className="fs-18 col-12 mb-3">Performed By</h4>
                {signatures['driver'] && <div className="col-12">
                    <img className={'imgsignature-saved col-12'} src={signatures['driver']} alt="" />
                </div>}

                {data.performed_by.name && <h4 className="fs-16">{data.performed_by.name}</h4>}

                <div className="col-12">
                    <button onClick={()=>{
                            setOpen(true)
                            setParamt('driver')
                            }}
                            className={"btn w-100 py-2 btn-blue-light rounded-pill fs-12 mt-3 mb-4 font-medium-i"}>
                            ADD/REPLACE SIGNATURE
                    </button>

                    {/*<FormGroup  name={'drive_name'}
                                value={''}
                                label={'Driver Name'}
                                onTextChange={value => console.log(value)}/>

                    <span className={'main-text'}>
                        01/01/2021
                        </span>*/}
                </div>
            </div>


               {/* open && <Signature inputName={paramt} imgSaved={signature[paramt]} onCancel={()=>{setOpen(false)}} onSubmit={(value)=>{setSignature({...signature,[paramt]:value});setOpen(false)}}/>*/}


            <ReactModal isOpen={open} className={'modal-fit modal-90'}>
                <Signature value={paramt == 'origin' ? data.signed_by.name : data.performed_by.name} inputName={paramt} imgSaved={signatures[paramt]} onCancel={()=>{setOpen(false)}} onSubmit={async (value, name)=>{

                    console.log(paramt);
                    dataUrlToFile(value, paramt == 'origin' ? 'signed_by.png' : 'performed_by.png')
                    .then(d => {
                        setOpen(false);
                        onSubmitSignature(paramt, d, name);
                    })
                }}/>
            
            </ReactModal>



        </>
    )
}

export default ConfirmationForm

import {gql} from "apollo-boost";

const GET_BY_STOP_ID = gql`
    query($stop_id: Int) {
        get_inspection_by_stop_id(stop_id: $stop_id) {
            id
            performed_by {
                uid
                name
                user_id
            }
            signed_by {
                uid
                name
                user_id
            }
            device_type
            additional_emails
            time
            note
            odometer
            vehicle {
                id
                vin
                year
                make
                model
                submodel
                type
                no_vin
                special_vin
                incomplete_vin
                color
                license_plate
                internal_note
                time_created
            }
            accessories {
                name
                count
            }
            damages {
                stop_id
                point {
                    x
                    y
                }
                code
                uid
                is_pickup
            }
            flags {
                too_dirty
                too_dark
                raining
            }
            origin_inspection {
                origin_id
                driver_name
                driver_signature
                contact_name
            }
            destination_inspection {
                destination_id
                driver_name
                driver_signature
                contact_name
            }
        }
    }

`;
const GET_BY_TOKEN = gql`
    query($token:String){
  get_inspections_by_token(token:$token){
    id
    performed_by {
                uid
                name
                user_id
            }
            signed_by {
                uid
                name
                user_id
            }
            additional_emails
            time
            note
            odometer
            photos {
                uid
                type
                user_id
            }
            vehicle_data {
                id
                vin
                year
                make
                model
                submodel
                type
                no_vin
                special_vin
                incomplete_vin
                color
                license_plate
                internal_note
                time_created
            }
            accessories {
                name
                count
            }
            damages {
                
                point {
                    x
                    y
                }
                code
                uid
            }
            flags {
                too_dirty
                too_dark
                raining
            }
           
        }
  
}
`;
const GET_LINK = gql`
    query($stop_id:Int){
        get_inspection_link(stop_id:$stop_id)
    }`
const InspectionQueries = {
    GET_BY_STOP_ID,
    GET_BY_TOKEN,
    GET_LINK
}
 
export default InspectionQueries;
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {Carrier_Driver_Res} from "../driver";

export const attributes_by_customerId = (input: { carrier_id: number }) : Promise<Carrier_Driver_Res> => {
    return GraphqlService.SendQuery(
        gql`
            query($carrier_id: Int) {
                get_carrier_drivers_schedule(carrier_id: $carrier_id) {
                    driver {
                        id
                        name
                        username
                        price
                        carrier
                        carrier_id
                        equipment
                        samsara_truck_id
                        contacts {
                            title
                            type
                            value
                        }
                        status {
                            text
                            text_color
                            background_color
                            border_color
                        }
                        itinerary {

                            address
                            orders {
                                address
                                timezoneName
                                id
                                pending_change_request
                                change_requests{
                                    id
                                    order_id
                                    accepted
                                    rejected
                                    time
                                    payout
                                    note
                                    active
                                    expired
                                    expires_at
                                    weblink
                                    code
                                }
                                vehicle {
                                    id
                                    vin
                                    year
                                    make
                                    model
                                    submodel
                                    type
                                    color
                                }
                                order_id
                                is_pickup
                                scheduled
                                completed
                                nofail
                                contacts {
                                    name
                                    value
                                }
                            }
                        }
                    }
                }
            }`, input) as 
  Promise<Carrier_Driver_Res>
}

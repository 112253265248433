import IReducerAction from "../models/IReducerAction";
import APTypes from "../types/AP.type";



export interface IAPCarrier {
    name: string,
    carrier_id: number
    drivers_dispatched: number
    total: number,
    over30: number,
    over60: number,
    over90: number,
    current: number,
    delivered: number,
    payment_terms: number,
    valid: boolean
}

export interface IAPOpen
{
    time: Date,
    velox_order_id: number,
    broker: {
        id: number,
        name: string
    },
  "remaining_driver_payable": number,
  "remaining_carrier_receivable": number,
  "expires": Date,
  carrier_pay: number,
  "vehicle": {
    "id": number,
    "year": string,
    "make": string,
    "model": string,
    vin: string
  },
  "driver": {
    "id": number,
    "name": string,
    "equipment": string
  },
  "origin": {
    "nofail": boolean,
    "scheduled": Date,
    "time_completed": Date,
    "completed": boolean,
    "contacts": any[],
    "address": {
      "street_number": string,
      "street": string,
      "city": string,
      "state": string,
      "postal_code": string,
      "country": string
    }
  },
  "destination": {
    "nofail": boolean,
    "scheduled": Date,
    "time_completed": Date,
    "completed": boolean,
    "contacts": any[],
    "address": {
      "street_number": string,
      "street": string,
      "city": string,
      "state": string,
      "postal_code": string,
      "country": string
    }
  },
  "carrier": {
    "id": number,
    "name": string
  }
}

export interface IOrderReceivables {
    data: IAPCarrier[],
    loading: boolean,
    error: string,
}

export interface IAPKeys {
    open: IOrderReceivables,
    closed: IOrderReceivables,
    open_driver: IOrderReceivables,
    aging: {
        data: IAPCarrier[],
        loading: boolean,
        error: string
    },
    aging_driver: {
        data: IAPCarrier[],
        loading: boolean,
        error: string
    },
    requests: {
        data: any[],
        loading: boolean,
        error: string
    },
    payments: IOrderReceivables,
}

const initialState: IAPKeys = {
    open: {
        data: [],
        loading: false,
        error: '',
    },
    open_driver: {
        data: [],
        loading: false,
        error: ''
    },
    closed: {
        data: [],
        loading: false,
        error: '',
    },
    aging: {
        data: [],
        loading: false,
        error: '',
    },
    aging_driver: {
        data: [],
        loading: false,
        error: '',
    },
    payments: {
        data: [],
        loading: false,
        error: '',
    },
    requests: {
        data: [],
        loading: false,
        error: ''
    }
}

export default function APReducer(state: IAPKeys = initialState, action: IReducerAction): IAPKeys {
    switch (action.type) {
        case APTypes.GET_ALL_AP_STARTED:
            return Object.assign({}, {
                ...state,
                [action.status]: {
                    ...state[action.status],
                    loading: action.payload,
                    error: null
                }
            })
        case APTypes.GET_ALL_AP_SUCCESS:

            let z= Object.assign({}, {
                ...state,
                [action.status]: {
                    ...state[action.status],
                    data: action.payload,
                    loading: false,
                    error: null
                }
            });
            return z;
        case APTypes.GET_ALL_AP_ERROR:
            return Object.assign({}, {
                ...state,
                [action.status]: {
                    ...state[action.status],
                    loading: false,
                    error: null
                }
            })
        default:
            return state
    }
}

import React from 'react';
import VehicleOverview from '../VehicleOverview/VehicleOverview';




const VehiclesOverview = () => {
    return <div className='flex-1-container rounded p-3 bg-white'>
        <div className="flex-1-container">
            <div className="row">
                <h6 className="font-12">Vehicles Overview</h6>
                <h6 className="font-12">$820.00</h6>
            </div>
            <hr className="my-2" />

            <div className="flex-1-container">
                <VehicleOverview storage />
                <hr className="my-2" />
                <VehicleOverview index={2} />
            </div>
        </div>
    </div>
}

export default VehiclesOverview;
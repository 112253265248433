import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { User } from '../../classes/classes';
import UserQueries from '../../graphql/query/User.query';
import { GraphqlService } from '../../services/graphql.service';
import Accessories from './pages/Accessories/Accessories';
import Confirmation from './pages/Confirmation/Confirmation';
import Notes from './pages/Notes/Notes';
import RequiredPhotos from './pages/RequiredPhotos/RequiredPhotos';
import VehicleDamages from './pages/VehicleDamages/VehicleDamages';
import VehicleDetails from './pages/VehicleDetails/VehicleDetails';


import './PendingInspectionPage.scss';

const PendingInspectionPage = () => {

    
    const [user, setUser] = useState(new User());

    useEffect(() => {
    }, []);

    return <div className='pending-inspection-page flex-1-container'>
            <Switch>
                <Route exact path='/pending-inspection/:token' component={VehicleDetails}/>
                <Route path='/pending-inspection/:token/vehicle-damages' component={VehicleDamages} />
                <Route path='/pending-inspection/:token/required-photos' component={RequiredPhotos} />
                <Route path='/pending-inspection/:token/accessories' component={Accessories} />
                <Route path='/pending-inspection/:token/notes' component={Notes} />
                <Route path='/pending-inspection/:token/confirmation' component={Confirmation} />
            </Switch>
    </div>
}

export default PendingInspectionPage;
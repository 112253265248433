import AgingTypes from "../types/aging.types";
import {GraphqlService} from "../../services/graphql.service";
import AgingQuery from "../../graphql/query/aging";
import Helper from "../../helper/Helper";

const fetchReceivableAgingRequest = () => {
    return {
        type: AgingTypes.FETCH_RECEIVABLE_AGING_REQUEST
    }
}

const fetchReceivableAgingSuccess = (receivableAging) => {
    return {
        type: AgingTypes.FETCH_RECEIVABLE_AGING_SUCCESS,
        payload: receivableAging
    }
}

const fetchReceivableAgingFailure = () => {
    return {
        type: AgingTypes.FETCH_RECEIVABLE_AGING_FAILURE,
        payload: []
    }
}

export const getReceivableAging = () => {
    return async (dispatch) => {
        dispatch(fetchReceivableAgingRequest());
        try {
            let data = await GraphqlService.SendQuery(AgingQuery.GET_RECEIVABLE_AGING);
            dispatch(fetchReceivableAgingSuccess(data.map(e => {
                return {
                    ...e,
                    current_$: [Helper.FORMAT.USCurrency(e.current)],
                    current: [e.current],
                    plus30_$: [Helper.FORMAT.USCurrency(e.plus30)],
                    plus30: [e.plus30],
                    plus60_$: [Helper.FORMAT.USCurrency(e.plus60)],
                    plus60: [e.plus60],
                    plus90_$: [Helper.FORMAT.USCurrency(e.plus90)],
                    plus90: [e.plus90],
                    total_$: [Helper.FORMAT.USCurrency(e.total)],
                    total: [e.total],
                    moves: [e.not_picked_up + ' not picked', e.not_delivered + ' undelivered', e.non_move + ' non move']
                }
            })));
        } catch (ex) {
            dispatch(fetchReceivableAgingFailure());
        }
    }
}

import {gql} from "apollo-boost";

const UPDATE_VEHICLE_ORDER = gql`
    mutation ($vehicle: vehicle_input, $vehicle_order_id:Int, $vehicle_order: vehicle_order_update_input){
      update_vehicle(vehicle: $vehicle){
        id
      }
        
      update_vehicle_order(vehicle_order_id: $vehicle_order_id, vehicle_order: $vehicle_order ){
        id
        message
        success
      }
    }
`

const VehicleOrderMutations = {
    UPDATE_VEHICLE_ORDER
}

export default VehicleOrderMutations;
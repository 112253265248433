import {Master_Order} from "../master_order/master_order";
import {Payable} from "../payable/payable";
import {Log} from "../storage_order/storage_order";

export class Invoice {
    __typename?: "Invoice"
    id?: number = null;
    time?: string = '';
    time_due?: string = '';
    data?: string = '';
    type?: string = '';
    master_order_id?: number = null;
    master_order?: Master_Order = new Master_Order();
    amount?: number = 0;
    logs?: Log[] = [];

    constructor(x?: Invoice){
        if(x){
            this.id = x.id;
            this.time = x.time;
            this.time_due = x.time_due;
            this.data = x.data;
            this.type = x.type;
            this.master_order_id = x.master_order_id;
            this.master_order = new Master_Order(x.master_order);
            this.amount = x.amount;
            this.logs = x.logs?.map(z => z) || [];            
        }
    }
}

export class Carriers_For_Broker_Payment  {
    __typename?: "carriers_for_broker_payment"
    name?: string = '';
    total?: number = 0;
    over30?: number = 0;
    over60?: number = 0;
    over90?: number = 0;
    current?: number = 0;
    delivered?: number = 0;
    valid?: boolean = false;
    carrier_id?: number = null;
    payment_terms?: number = 0;
    drivers_dispatched?: number = 0;
    completed?: number = 0;

    constructor(x?: Carriers_For_Broker_Payment){
        if(x){
            this.name = x.name;
            this.total = x.total;
            this.over30 = x.over30;
            this.over60 = x.over60;
            this.over90 = x.over90;
            this.current = x.current;
            this.delivered = x.delivered;
            this.valid = x.valid;
            this.carrier_id = x.carrier_id;
            this.payment_terms = x.payment_terms;
            this.drivers_dispatched = x.drivers_dispatched;
            this.completed = x.completed;            
        }
    }
}

export class Broker  {
    __typename?: "Broker"
    id?: number = null;
    name?: string = '';

    constructor(x?: Broker){
        if(x){
            this.id = x.id;
            this.name = x.name;
        }
    }
}

export class Aging_Carrier_Driver_Payable  {
    __typename?: "aging_carrier_driver_payable"
    id?: number = null;
    name?: string = '';
    total?: number = 0;
    valid?: boolean = false;
    last_payable?: Payable = new Payable();
    delivered?: number = 0;
    driver_id?: number = null;

    constructor(x?: Aging_Carrier_Driver_Payable){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.total = x.total;
            this.valid = x.valid;
            this.last_payable = new Payable(x.last_payable);
            this.delivered = x.delivered;
            this.driver_id = x.driver_id;            
        }
    }
}

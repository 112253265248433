import React, {useEffect, useState} from 'react';
import Helper from '../../helper/Helper';
import FormGroup from '../FormGroup/FormGroup';
import ICONS from "../../../assets/svg";
import {useToasts} from "react-toast-notifications";
import useLoading from "../../hooks/useLoading/useLoading";
import {GraphqlService} from "../../services/graphql.service";
import CustomerMutations from "../../graphql/mutation/Customer";
import {Contact, CustomerProfile} from "../../classes/classes";
import CustomerQueries from "../../graphql/query/Customer";
import Toggle from "../Toggle/Toggle";
import gql from 'graphql-tag';

const CustomerProfileForm = ({
                                 customerId = 0,
                                 customerProfile = undefined,
                                 onSubmit
                             }: {
                                customerId: number,
                                customerProfile?: CustomerProfile,
                                onSubmit
                            }) => {

    const [currentCustomerProfile, setCurrentCustomerProfile] = useState(new CustomerProfile());
    const [createProfile, setCreateProfile] = useState(false);

    const toast = useToasts();
    const loading = useLoading();

    useEffect(() => {
        if (customerProfile != undefined && customerProfile.id != null) {
            getCustomerProfileById(customerProfile.id).then(() => {});
        } else {
            setCreateProfile(false);
            setCurrentCustomerProfile(new CustomerProfile());
        }
    }, []);

    async function updateContact(){
        let temp = currentCustomerProfile.contact.map(x=>{
            return {
                "title": x.title,
                "value": x.value, 
                "type":x.type
            }
            })
            
        const contactData ={

            "id": currentCustomerProfile.id,
            "name": currentCustomerProfile.name,
            "role": createProfile ? currentCustomerProfile.role : 'user',
            "contact": temp,
            "title": currentCustomerProfile.title,
            email: createProfile ? currentCustomerProfile.email : null
        }


        const mutation = gql`
        mutation($profile: profileInput){
            update_customer_profile(profile:$profile)  {
                success
                message
            }
        }`;

        const variables = {profile: contactData }

        try {
            loading.open('Update profile...')
            const data = await GraphqlService.SendMutation(mutation, variables);
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            toast.addToast('Update profile', {appearance: 'success',autoDismiss: true});
            onSubmit({...currentCustomerProfile, contactData})
            loading.close();
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error',autoDismiss: true});
        }
    }


    async function getCustomerProfileById(profile_id) {
        try {
            loading.open('loading...');
            let data = await GraphqlService.SendQuery(CustomerQueries.GET_CUSTOMER_PROFILE_BY_ID, { profile_id });
            loading.close();

            if (data) {
                setCurrentCustomerProfile(data);
                setCreateProfile(data.email != null);
            } else {
                setCreateProfile(false);
                setCurrentCustomerProfile(new CustomerProfile());
            }
        } catch (ex) {
            loading.close();
            toast.addToast(ex, {appearance: 'error', autoDismiss: true});
        }
    }

    const getInputsByType = (type: string) => {
        let j = -1;
        let title = type == 'email' ? 'Email' : 'Phone';
        return currentCustomerProfile?.contact.map((x, index) =>  {
            if (x.type != type)
                return <></>;
            j++;
            return <div className="p-2 mb-3 rounded" style={{border: '1px solid #ccc'}}>
                <div className="d-flex align-items-start">
                    <FormGroup name={'title'}
                                                    value={x.title}
                                                    placeholder={'-- Select --'}
                                                    label={'Title'}
                                                    options={{data: type != 'email' ? ['Mobile', 'Office', 'Home', 'Other'] : ['Work', 'Personal', 'Other'], label: x=> x, value: x => x}}
                                                    type={'select'}
                                                    required
                                                    onTextChange={(event) => handleUpdateContact(index, 'title', event)}>
                                                            <button onClick={(evt) => handleRemoveContact(index)} style={{marginBottom: '3.5rem', marginRight: '-1rem'}} className="btn btn-danger-light">REMOVE</button>
                                                        </FormGroup>

                   
                </div>
                <FormGroup validation={(x) => type == 'email' ? Helper.Validators.IsValidEmail(x) : Helper.Validators.IsValidUSPhone(x) } errorText={`${title} format must be ${type == 'email' ? 'abc@xyz.com' : '000-000-0000'}`} placeholder={title + ' ' + (j + 1)} label={`${title} ${j + 1}`} required name={`${type}_${j + 1}`} value={x.value} onTextChange={(evt) => {
                    handleUpdateContact(index, 'value', evt);
                }}>

                    <div className="d-flex justify-content-end flex-wrap" style={{marginTop: '-.5rem', marginRight: '-.5rem'}}>
                                <div className="col-12 text-right">
                                    <span className="font-8 font-normal text-gray text-right">Notifications</span> 
                                </div>
                                <div className="">
                                    <Toggle id={'toggle'} checked={!x.unsubscribed} label={''} onCheck={(evt) => {
                                        handleUpdateNotificationContact(index);
                                    }} />
                                </div>
                            </div>
                </FormGroup>
            </div>
        })
    }



    const handleRemoveContact = index => {
        const temp = [...currentCustomerProfile.contact];
        temp.splice(index, 1);

        setCurrentCustomerProfile({...currentCustomerProfile, contact: temp });
    }

    const handleUpdateContact = (index, key, value) => {
        const temp = [...currentCustomerProfile.contact];
        const contact = temp[index];

        if (key == 'value' && contact['type'] == 'phone')
            value = Helper.Masks.USPhone(value);

        if (key == 'type')
            contact.value = '';

        contact[key] = value;
        temp[index] = contact;
        
        setCurrentCustomerProfile({...currentCustomerProfile, contact: temp });
    }

    const handleUpdateNotificationContact = async (index) => {
        const temp = [...currentCustomerProfile.contact];
        const contact = temp[index];

        try {
            loading.open('loading...');
            let data = await GraphqlService.SendMutation(contact.unsubscribed ? CustomerMutations.SUBSCRIBE_CONTACT : CustomerMutations.UNSUBSCRIBE_CONTACT, {value: contact.value});
            loading.close();

            if (data.success) {
                toast.addToast('Notifications updated', {appearance: 'success', autoDismiss: true});

                contact.unsubscribed = !contact.unsubscribed;
                temp[index] = contact;
                setCurrentCustomerProfile({...currentCustomerProfile, contact: temp });
            } else {
                toast.addToast('Error to update notifications', {appearance: 'error', autoDismiss: true});
            }
        } catch (ex) {
            loading.close();
            toast.addToast(ex, {appearance: 'error', autoDismiss: true});
        }
    }

    const isValid = () => {
        return currentCustomerProfile.name.trim() != ''
        &&  (currentCustomerProfile.role.trim() != '' || !createProfile)
            && (createProfile ? Helper.Validators.IsValidEmail(currentCustomerProfile.email) : true) && currentCustomerProfile.title.trim() != ''
            && !currentCustomerProfile.contact.some(x => x.title.trim() == '' || x.type == '' ? true : (x.type == 'email' ? !Helper.Validators.IsValidEmail(x.value) : !Helper.Validators.IsValidUSPhone(x.value)) );
    }

    const saveProfile = async () => {
        try {
            loading.open('saving profile...')

            const data = await GraphqlService.SendMutation(CustomerMutations.ADD_PROFILE_TO_CUSTOMER, {
                newname: currentCustomerProfile.name,
                newrole: createProfile ? currentCustomerProfile.role : 'user',
                title: currentCustomerProfile.title,
                newemail: createProfile ? currentCustomerProfile.email : null,
                contact: currentCustomerProfile.contact.map(x => ({title: x.title, type: x.type, value: x.value})),
                redirectlink: '',
                customerid: customerId,
            });

            if (!data.success) {
                handleError(data.message);
                return;
            }

            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            loading.close();

            setCurrentCustomerProfile({...currentCustomerProfile, id: data.id});
            onSubmit({...currentCustomerProfile, id: data.id});
        } catch (ex) {
            handleError('Error saving profile');
        }
    }

    const handleError = (error) => {
        toast.addToast(error, {appearance: 'error', autoDismiss: true});
        loading.close();
    }

    return (
        <>
        <div className="flex-1-container" onClick={(evt) => evt.stopPropagation()}>
           
                 <h4 className="mb-3">{!currentCustomerProfile.id ?"New Profile":`Edit Profile with ID-${currentCustomerProfile.id}`}</h4>
           

        <div className="flex-1-container">
        <div className="row">
                    <FormGroup name={'profileName'}
                               value={currentCustomerProfile.name}
                               placeholder={'Contact Name'}
                               label={'Contact Name'}
                               colSize={6}
                               required={true}
                               onTextChange={(event) => setCurrentCustomerProfile({...currentCustomerProfile, name: event})}/>

            {createProfile && <FormGroup name={'email'}
                        value={currentCustomerProfile.email}
                        placeholder={'Email'}
                        label={'Email'}
                        colSize={6}
                        required={true}
                        type={'email'}
                        validation={Helper.Validators.IsValidEmail}
                        errorText={'Email format is wrong'}
                        onTextChange={(event) => setCurrentCustomerProfile({
                            ...currentCustomerProfile,
                            email: event
                        })}/>}
                        <div className="col-12">
                            <FormGroup name={'create_profile'}
                                       checked={createProfile}
                                       label={'Create profile'}
                                       colSize={6}
                                       type={'checkbox'}
                                       onCheckChanged={(event) => {

                                            setCurrentCustomerProfile({...currentCustomerProfile, email: event ? '' : null});
                                            setCreateProfile(event);

                                       }}/>
                        </div>
                    
                        <FormGroup name={'title'}
                               value={currentCustomerProfile.title}
                               placeholder={'Title...'}
                               label={'Title/Position'}
                               colSize={6}
                               required
                               type={'text'}
                               options={{data: ['Admin', 'User', 'Accountant'], value: x => x.toLowerCase(), label: x => x}}
                               onTextChange={(event) => setCurrentCustomerProfile({...currentCustomerProfile, title: event })}/>

                        {createProfile && <FormGroup name={'role'}
                               value={currentCustomerProfile.role}
                               placeholder={'--Select a role--'}
                               label={'Role'}
                               colSize={6}
                               required
                               type={'select'}
                               options={{data: ['Admin', 'User', 'Accountant'], value: x => x.toLowerCase(), label: x => x}}
                               onTextChange={(event) => setCurrentCustomerProfile({...currentCustomerProfile, role: event })}/>}
                </div>

            <div className="row my-3">
                <div className="col-12 d-flex justify-content-between align-items-center">
                    <p className="font-12 font-medium">Contacts for this profile</p>
                    <p className="font-10 font-medium cursor-pointer text-light-blue no-select"
                       onClick={() => setCurrentCustomerProfile({...currentCustomerProfile, contact: [...currentCustomerProfile.contact, new Contact()] })}><i className="ti-plus"></i> NEW CONTACT</p>
                </div>
            </div>

            <div className="row mb-3 align-items-start">
                    <div className="col-6 row">
                        <div className="row mb-2 col-12 align-items-start">
                            <h4 className="font-12">Emails</h4>
                            <button className="btn btn-blue-light" onClick={(event) => {
                                const contact = new Contact();
                                contact.type = 'email';
                                contact.title = 'Work';
                                setCurrentCustomerProfile({...currentCustomerProfile, contact: [...currentCustomerProfile.contact, contact]});
                            }}>ADD</button>
                        </div>
                        <div className="col-12">
                            {getInputsByType('email')}
                        </div>
                    </div>

                    <div className="col-6 row">
                        <div className="row mb-2 col-12 align-items-start">
                            <h4 className="font-12">Phones</h4>
                            <button className="btn btn-blue-light" onClick={(event) => {
                                const contact = new Contact();
                                contact.type = 'phone';

                                contact.title = 'Mobile';
                                setCurrentCustomerProfile({...currentCustomerProfile, contact: [...currentCustomerProfile.contact, contact]});
                            }}>ADD</button>
                        </div>
                        <div className="col-12">
                            {getInputsByType('phone')}
                        </div>
                    </div>

                </div>
            </div>
        
            <div className="row  sticky-bottom bg-white">
                <div className="col-6">
                    <button type="button"
                            className="btn rounded-pill btn-block font-medium text-light-blue"
                            onClick={() => onSubmit(undefined) }>CANCEL</button>
                </div>

                <div className="col-6">
                    <button type="button"
                            className="btn rounded-pill btn-blue-light font-medium btn-block"
                            disabled={!isValid()}
                            onClick={() =>{
                                !currentCustomerProfile.id ?saveProfile():updateContact()
                            }   }>{!currentCustomerProfile.id ?"CREATE":"EDIT"}</button>
                </div>
            </div>
            </div>
        </>
    );
}

export default CustomerProfileForm;

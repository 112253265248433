import React, { useState, useRef, useEffect, FC } from "react";
import Status from "../../components/Status/Status";
import ICONS from "../../../assets/svg";
import "./OrderVehicles.scss";
import { Stop } from "../../classes/classes";
import MenuOptionItem from "../MenuOptionItem/MenuOptionItem";
import MenuOption from "../MenuOption/MenuOption";
import { ControlledMenu, MenuItem } from "@szhsin/react-menu";
import Helper from "../../helper/Helper";
import { Vehicle_Order } from "../../services/endpoints/schemas/vehicle_order/vehicle_order";

type OrderVehiclesProps = {
  vehicles: Vehicle_Order[];
  onSelectVehicle: ({ index: number, vehicle: VehicleOrder }) => void;
  onAddNewVehicleClicked: (evt: React.MouseEvent) => void;
  onNewClicked: (data: { leg_id: number; vo_id: number }) => void;
  onCancelClicked: (data: {vo_id: number}) => void;
  index: number;
};

const OrderVehicles = (props: OrderVehiclesProps) => {
  const [vehicles, setVehicles] = useState(props.vehicles);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle_Order>(props.vehicles[0]);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [legID, setLegID] = useState<number>(null);
  const [vorderID, setVorderID] = useState<number>(null);

  const vehiclesLength = vehicles.length;

  useEffect(() => {
    setVehicles(props.vehicles);
    // setSelectedVehicle(props.vehicles[0]);

    // handleOnSelectVehicle(0, props.vehicles[0]);
  }, [props.vehicles]);

  const handleOnSelectVehicle = (index, vehicle) => {
    setSelectedVehicle(vehicle);
    props.onSelectVehicle({ index, vehicle });
  };
  const nodeContainer = useRef();

  const VehicleLeg: FC<any> = ({ vehicle }) => {
    const stops =
      vehicle?.legs
        ?.flatMap((x) => x.stops)
        .sort((x, y) => (x.time_scheduled > y.time_scheduled ? 1 : -1)) || [];
    const firstPickup = new Stop(stops.filter((x) => x.is_pickup)[0]);
    const lastDropoff = new Stop(
      stops.filter((x) => !x.is_pickup).reverse()[0]
    );
    return (
      <>
        <div
          className="card mb-1"
          onContextMenu={(evt) => {
            // evt.stopPropagation();
            // evt.preventDefault();
            // if (vehicle?.legs.length < 2)
            //     return;
            // if (vehicles.length >= 2)
            //     setVorderID(vehicle.id);
            // setLegID(leg.id);

            setAnchorPoint({ x: evt.clientX, y: evt.clientY });
          }}
        >
          <div className="card-body">
            <div className="row d-flex align-items-center">
              <div className="col-5">
                {/* <p className="fs-10 font-medium">{firstPickup?.getScheduled()}</p>
                        <p className="fs-10 font-medium mb-1">{firstPickup?.getTime()}</p> */}
                <p className="fs-10 font-medium">
                  {firstPickup?.location?.address?.getName()}
                </p>
                <p className="fs-10">
                  {firstPickup?.location?.address?.postal_code}
                </p>
              </div>

              <div className="col-2">
                <i className="ti-minus"></i>
              </div>

              <div className="col-5">
                {/* <p className="fs-10 font-medium">{lastDropoff?.getScheduled()}</p>
                        <p className="fs-10 font-medium mb-1">{lastDropoff?.getTime()}</p> */}
                <p className="fs-10 font-medium">
                  {lastDropoff?.location?.address?.getName()}
                </p>
                <p className="fs-10">
                  {lastDropoff?.location?.address?.postal_code}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-12 d-flex justify-content-between align-items-center">
          <p className="fs-12 font-medium">
            {`${vehiclesLength} vehicle${(vehiclesLength > 1 && "s") || ""}`}{" "}
          </p>

          <div className="d-flex justify-content-end align-items-center">
            <span
              onClick={(evt) => {
                evt.stopPropagation();
                props.onAddNewVehicleClicked(evt);
              }}
              className="cursor-pointer fs-11 d-flex align-items-center mr-2 text-light-blue"
            >
              <img
                className={"btn-icon edit bg-transparent"}
                src={ICONS.IconPlus}
                alt=""
              />{" "}
              ADD VEHICLE
            </span>
            {/*<img className={'btn-icon edit mr-2'} src={ICONS.IconPencil} alt=""/>*/}
            <img className={"btn-icon delete"} src={ICONS.IconTrash} alt="" onClick={()=>props.onCancelClicked({ vo_id: selectedVehicle.id})}/>
            <input type="checkbox" />
          </div>
        </div>
      </div>
      <ControlledMenu
        boundingBoxRef={nodeContainer}
        onContextMenu={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
        }}
        anchorPoint={anchorPoint}
        isOpen={vorderID != null || legID != null}
        animation={false}
        onClose={() => {
          setVorderID(null);
          setLegID(null);
        }}
      >
        <MenuItem
          className="my-menuitem"
          onClick={(evt) => {
            props.onNewClicked({ leg_id: null, vo_id: vorderID });
          }}
          style={{ paddingLeft: "0" }}
        >
          <img src={ICONS.IconPlus} alt="" />
          {`Move into new Master order`}
        </MenuItem>
        <MenuItem
          className="my-menuitem"
          onClick={(evt) => {
            props.onCancelClicked({ vo_id: vorderID });
          }}
          style={{ paddingLeft: "0" }}
        >
          <img src={ICONS.IconCancelWhite} alt="" />
          {`Cancel this Vehicle Order`}
        </MenuItem>
      </ControlledMenu>
      {vehicles.map((vehicle, idx) => {
        return (
          <div
            className="row justify-content-normal mt-3"
            style={{ overflow: "visible" }}
            onContextMenu={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();

              setVorderID(vehicle.id);

              setAnchorPoint({ x: evt.clientX, y: evt.clientY });
            }}
          >
            <div
              ref={nodeContainer}
              style={{ boxShadow: "0px 0px 8px #CCC" }}
              onClick={() => handleOnSelectVehicle(idx, vehicle)}
              className={`col-12  cursor-pointer card ${
                props.index == idx ? "bg-light-blue" : "bg-gray"
              }`}
            >
              <div className="card-body p-2">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="fs-12 font-medium">
                    {vehicle?.vehicle?.getName()}
                    <i className="ml-2 fs-12 ti-angle-right"></i>
                  </p>

                  <div className="d-flex justify-content-start align-items-center">
                    <Status statusName={"IN TRANSIT"} />
                    <input checked={props.index == idx} type="checkbox" />
                  </div>
                </div>
                <div className="row col-12">
                  {vehicle?.vehicle?.vin?.trim() != "" && (
                    <p className="fs-12 mb-1">{vehicle?.vehicle?.vin}</p>
                  )}
                  {vehicle?.id && (
                    <p className="fs-12 mb-1">VO-{vehicle?.id}</p>
                  )}
                </div>
                <div>
                  <VehicleLeg vehicle={vehicle} />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default OrderVehicles;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AllStates} from "../../redux/reducers";
import {ICarrierState} from "../../redux/reducers/Carrier.reducer";
import './DriverListOrder.scss';
import {UserSettings} from "../../classes/classes";
import {Simulate} from "react-dom/test-utils";
import {GraphqlService} from "../../services/graphql.service";
import UserSettingsMutations from "../../graphql/mutation/UserSettings.mutation";
import Loading from "../Loading/Loading";
import setUserSettings from "../../redux/actions/UserSettings.action";
import UserSettingsQueries from '../../graphql/query/UserSettings';



const DriverListOrder = ({drivers, onClose, carrier_id = null}) => {

    const carrier = useSelector<AllStates>(states => states.carriers) as ICarrierState;


    const userSettings = useSelector<AllStates>(states => states.userSettings) as UserSettings;

    async function loadSettings()
    {
        try {
            const data = await GraphqlService.SendQuery(UserSettingsQueries.GET_ALL)
            const us = new UserSettings(data);
            dispatch(setUserSettings(us));
            // dispatch();
  
        }
        catch (ex)
        {
            console.log(ex);
        }
    }

    const [orders, setOrders] = useState([]);
    const [ordersMoving, setOrdersMoving] = useState([]);

    const [dragIndex, setDragIndex] = useState({drag: null, drop: null, dropOvered: false});

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    function prepeareNewSettings(){
        let value = userSettings.driver_view_order.filter(x => x.carrier_id != carrier_id);
        value.push({
            carrier_id,
            order: orders.map(x => ({id: x}))
        });
        return value;
    }
    async function updateSettings()
    {
        try {
            setLoading(true);
            const settings = {
                key: 'driver_view_order',
                value: JSON.stringify(prepeareNewSettings())
            }
            
            // console.log()

            // console.log(dvo);
            const data = await GraphqlService.SendMutation(UserSettingsMutations.UPDATE, settings);
            //console.log(data);
            setLoading(false);
            
            // console.log(n);
            loadSettings();
            
            onClose();
        }
        catch (ex)
        {
            setLoading(false);
            console.log(ex.message);
        }
    }

    useEffect(() => {
        const ordersID = (userSettings.driver_view_order.find(x => x.carrier_id == carrier.id)?.order || []).map(x => x.id);
        const driversID = drivers.map(x => x.id);

        let order = [...new Set([...ordersID.filter(x => driversID.includes(x)), ...driversID])];
        setOrdersMoving(order);
        setOrders(order);

    }, [userSettings]);

    const [moveDirectIndex, setMoveDirectIndex] = useState(null);

    const getDriversOrdered = () => {
        const result = [...new Set([...(dragIndex.drag != null ? ordersMoving : orders).map(x => drivers.find(d => d.id == x)).filter(x => x), ...drivers])] || [];
        // if (movingIndex)
        //     result.splice(movingIndex, 1);
        return result;
    }

    const onRowClicked = index => {
        if (moveDirectIndex != null)
        {
            const order = [...orders];
            order[index] = orders[moveDirectIndex];
            order[moveDirectIndex] = orders[index];
            setOrders(order);
            setMoveDirectIndex(null);
        }

    }

    function onDragStart(event: React.DragEvent<HTMLDivElement>, idx) {
        setDragIndex({...dragIndex, drag: idx});
    }

    function onDrag(event: React.DragEvent<HTMLDivElement>, id)
    {

    }

    function onOverDrag(event: React.DragEvent<HTMLDivElement>, idx)
    {
        event.preventDefault();
        if (dragIndex.drop != idx)
        {

            const drag = dragIndex.drag;

            setDragIndex({...dragIndex, drop: idx, drag: idx, dropOvered: true});



            if (drag != idx)
            {
                const original = [...ordersMoving]
                const current = original[drag];

                original.splice(drag, 1);

                const arr = [...original.slice(0, idx), current, ...original.slice(idx, original.length)]

                setOrdersMoving(arr);
            }


        }
        else
            setDragIndex({...dragIndex, dropOvered: true});
    }

    function onDragLeave(event: React.DragEvent<HTMLDivElement>)
    {
        setDragIndex({...dragIndex, dropOvered: false});
    }


    function onDragEnd(event: React.DragEvent<HTMLDivElement>, id)
    {
        event.preventDefault();
        console.log('drop', dragIndex.dropOvered);
        if (!dragIndex.dropOvered)
            setOrdersMoving([...orders]);
        else
            setOrders([...ordersMoving])

        setDragIndex({drag: null, drop: null, dropOvered: false});
    }

    const handleGoDown = (index) => {
        const order = [...orders];

        order[index] = orders[index + 1];
        order[index + 1] = orders[index];
        setOrders(order);
        setMoveDirectIndex(null);
    }

    const handleGoUp = (index) => {
        const order = [...orders];

        order[index] = orders[index - 1];
        order[index - 1] = orders[index];
        setOrders(order);
        setMoveDirectIndex(null);
    }



    return (
        <>

            <div className={'driver-list-order flex-column h-100'}>
                <div className={'sticky-top'}>
                    <h2 className={'font-18 text-center mb-3'}>{carrier.name}</h2>
                    {loading ? <div className={'my-4'}>
                        <Loading/>
                        <h4 className="font-14 font-medium text-center">UPDATING SETTINGS...</h4>
                    </div>
                        :
                    <div className={'row border-bottom py-2'}>
                        <div className="col-3">
                            <p className={'font-12 text-gray font-medium'}>ID</p>
                        </div>
                        <div className="col-6">
                            <p className={'font-12 text-gray font-medium'}>NAME</p>
                        </div>
                        <div className="col-3">
                            <p className={'font-12 text-gray font-medium'}>ORDER</p>
                        </div>
                    </div>}
                </div>

                <div className={'h-100 overflow-auto'} style={{maxHeight: '100%'}}>
                    {!loading &&  getDriversOrdered().map((d, index) => <div onDragOver={(evt) => onOverDrag(evt, index)}
                                                                onDragLeave={(evt) => onDragLeave(evt)}
                                                                draggable={moveDirectIndex == null}
                                                                onDragStart={(evt) => onDragStart(evt, index)}
                                                                onDrag={evt => onDrag(evt, d.id)}
                                                                onDragEnd={(evt) => onDragEnd(evt, d.id)}
                                                                onClick={(evt) => onRowClicked(index)}
                                                                className={'row py-2 border-bottom list-row ' + (index == moveDirectIndex ? ' bg-light-blue' : '') + (null != moveDirectIndex ? ' move-direct' : '') + (dragIndex.drop == index ? ' bg-gray drag-border' : '')}>
                        {dragIndex.drop == index ?
                            <p className={'font-12 col-12 font-medium text-center'}>DROP HERE</p> :
                            <div className={"row col-12 "} style={{opacity: dragIndex.drop == index ? 0 : 1}}>
                                <div className="col-3 no-select">
                                    <p className={'font-12 font-medium'}>{d.id}</p>
                                </div>
                                <div className="col-6">
                                    <p className={'font-12 font-medium'}>{d.name}</p>
                                </div>
                                <div className="col-3 d-flex">
                                    <p onClick={(evt) => handleGoUp(index)} style={{opacity: index > 0 ? '1' : '0'}}
                                       className={'font-12 font-medium text-green cursor-pointer'}><i
                                        className="fas fa-arrow-up"></i></p>
                                    <p onClick={(evt) => handleGoDown(index)}
                                       style={{opacity: index < drivers.length - 1 ? '1' : '0'}}
                                       className={'font-12 font-medium mx-2 text-red cursor-pointer'}><i
                                        className="fas fa-arrow-down"></i></p>

                                    <p className={'font-12 font-medium text-light-blue cursor-pointer'}
                                       onClick={evt => moveDirectIndex == index ? setMoveDirectIndex(null) : setMoveDirectIndex(index)}>
                                        {moveDirectIndex != index ? <i className="fas fa-ellipsis-v"></i> :
                                            <i className="fas fa-times"></i>}
                                    </p>
                                </div>
                            </div>}

                    </div>)}
                </div>

                <div className="row mt-3">
                    <button disabled={loading} className={'col-6 btn btn-danger-outline p-2'} onClick={(evt) => onClose()}>Cancel</button>
                    <button disabled={loading} className={'col-6 btn btn-blue-light-outline p-2'} onClick={(evt) => updateSettings()}>Save</button>
                </div>
            </div>
        </>

    )
}

export default DriverListOrder;
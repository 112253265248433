import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {Check, Payable, Payee} from "../payable";

export const all_payables = (input:{start_time: string, end_time: string}) : Promise<Payable[]> => {
  let query = gql`
    query($start_time: String, $end_time: String) {
      all_payables(start_time: $start_time, end_time: $end_time) {
        id
        time
        type
        reference
        note
        amount
        category
        time_paid
        checks {
          amount
          payable_to
          number
          reference
          memo
          time
          bank_account {
            id
            name
            note
            bank
            active
            last_4
          }
          time_created
          void
        }
        carrier {
          id
          name
        }
        driver {
          id
          name
          email
          phones
          velox_id
        }
        employee {
          id
          name
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          permission
          department
          active
          note
        }
        payee {
          id
          name
          tax_info {
            type
            value
          }
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          notes
        }
        bank_account {
          id
          name
          note
          bank
          active
          last_4
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {start_time, end_time} as 
  Promise<Payable[]>;
}

export const get_all_payees = (input:{}) : Promise<Payee[]> => {
  let query = gql`
    query {
      get_all_payees {
        id
        name
        tax_info {
          type
          value
        }
        address {
          city
          state
          street
          country
          postal_code
          street_number
          postal_code_suffix
        }
        notes
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Payee[]>;
}

export const get_biggest_check = (input:{bank_account_id: number}) : Promise<Check> => {
  let query = gql`
    query($bank_account_id: Int) {
      get_biggest_check(bank_account_id: $bank_account_id) {
        amount
        payable_to
        number
        reference
        memo
        time
        bank_account {
          id
          name
          note
          bank
          active
          last_4
        }
        time_created
        void
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {bank_account_id} as 
  Promise<Check>;
}

export const get_last_category_for_payee = (input:{payee_id: number}) : Promise<string> => {
  let query = gql`
    query($payee_id: Int) {
      get_last_category_for_payee(payee_id: $payee_id)
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {payee_id} as 
  Promise<string>;
}

export const get_last_category_for_payable = (input:{payable_id: number}) : Promise<string> => {
  let query = gql`
    query($payable_id: Int) {
      get_last_category_for_payable(payable_id: $payable_id)
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {payable_id} as 
  Promise<string>;
}

export const all_payables_for_employee = (input:{employee_id: number}) : Promise<Payable[]> => {
  let query = gql`
    query($employee_id: Int) {
      all_payables_for_employee(employee_id: $employee_id) {
        id
        time
        type
        reference
        note
        amount
        category
        time_paid
        checks {
          amount
          payable_to
          number
          reference
          memo
          time
          bank_account {
            id
            name
            note
            bank
            active
            last_4
          }
          time_created
          void
        }
        carrier {
          id
          name
        }
        driver {
          id
          name
          email
          phones
          velox_id
        }
        employee {
          id
          name
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          permission
          department
          active
          note
        }
        payee {
          id
          name
          tax_info {
            type
            value
          }
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          notes
        }
        bank_account {
          id
          name
          note
          bank
          active
          last_4
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {employee_id} as 
  Promise<Payable[]>;
}

export const all_payables_for_driver = (input:{driver_id: number}) : Promise<Payable[]> => {
  let query = gql`
    query($driver_id: Int) {
      all_payables_for_driver(driver_id: $driver_id) {
        id
        time
        type
        reference
        note
        amount
        category
        time_paid
        checks {
          amount
          payable_to
          number
          reference
          memo
          time
          bank_account {
            id
            name
            note
            bank
            active
            last_4
          }
          time_created
          void
        }
        carrier {
          id
          name
        }
        driver {
          id
          name
          email
          phones
          velox_id
        }
        employee {
          id
          name
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          permission
          department
          active
          note
        }
        payee {
          id
          name
          tax_info {
            type
            value
          }
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          notes
        }
        bank_account {
          id
          name
          note
          bank
          active
          last_4
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {driver_id} as 
  Promise<Payable[]>;
}

export const all_payables_for_payee = (input:{payee_id: number}) : Promise<Payable[]> => {
  let query = gql`
    query($payee_id: Int) {
      all_payables_for_payee(payee_id: $payee_id) {
        id
        time
        type
        reference
        note
        amount
        category
        time_paid
        checks {
          amount
          payable_to
          number
          reference
          memo
          time
          bank_account {
            id
            name
            note
            bank
            active
            last_4
          }
          time_created
          void
        }
        carrier {
          id
          name
        }
        driver {
          id
          name
          email
          phones
          velox_id
        }
        employee {
          id
          name
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          permission
          department
          active
          note
        }
        payee {
          id
          name
          tax_info {
            type
            value
          }
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          notes
        }
        bank_account {
          id
          name
          note
          bank
          active
          last_4
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {payee_id} as 
  Promise<Payable[]>;
}

export const all_payables_for_carrier = (input:{carrier_id: number}) : Promise<Payable[]> => {
  let query = gql`
    query($carrier_id: Int) {
      all_payables_for_carrier(carrier_id: $carrier_id) {
        id
        time
        type
        reference
        note
        amount
        category
        time_paid
        checks {
          amount
          payable_to
          number
          reference
          memo
          time
          bank_account {
            id
            name
            note
            bank
            active
            last_4
          }
          time_created
          void
        }
        carrier {
          id
          name
        }
        driver {
          id
          name
          email
          phones
          velox_id
        }
        employee {
          id
          name
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          permission
          department
          active
          note
        }
        payee {
          id
          name
          tax_info {
            type
            value
          }
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          notes
        }
        bank_account {
          id
          name
          note
          bank
          active
          last_4
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {carrier_id} as 
  Promise<Payable[]>;
}

export const all_paid_items = (input:{check_search: string, payee_search: string, employee_search: string, driver_search: string, the_search: string, carrier_search: string}) : Promise<Payable[]> => {
  let query = gql`
    query($check_search: String, $payee_search: String, $employee_search: String, $driver_search: String, $the_search: String, $carrier_search: String) {
      all_paid_items(check_search: $check_search, payee_search: $payee_search, employee_search: $employee_search, driver_search: $driver_search, the_search: $the_search, carrier_search: $carrier_search) {
        id
        time
        type
        reference
        note
        amount
        category
        time_paid
        checks {
          amount
          payable_to
          number
          reference
          memo
          time
          bank_account {
            id
            name
            note
            bank
            active
            last_4
          }
          time_created
          void
        }
        carrier {
          id
          name
        }
        driver {
          id
          name
          email
          phones
          velox_id
        }
        employee {
          id
          name
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          permission
          department
          active
          note
        }
        payee {
          id
          name
          tax_info {
            type
            value
          }
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          notes
        }
        bank_account {
          id
          name
          note
          bank
          active
          last_4
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {check_search, payee_search, employee_search, driver_search, the_search, carrier_search} as 
  Promise<Payable[]>;
}

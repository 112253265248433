const GET_ALL_CUSTOMER_LIST_STARTED = 'GET_ALL_CUSTOMER_LIST_STARTED';
const GET_ALL_CUSTOMER_LIST_SUCCESS = 'GET_ALL_CUSTOMER_LIST_SUCCESS';
const GET_ALL_CUSTOMER_LIST_ERROR = 'GET_ALL_CUSTOMER_LIST_ERROR';

const GET_ALL_CUSTOMER_PROFILE_LIST_STARTED = 'GET_ALL_CUSTOMER_PROFILE_LIST_STARTED';
const GET_ALL_CUSTOMER_PROFILE_LIST_SUCCESS = 'GET_ALL_CUSTOMER_PROFILE_LIST_SUCCESS';
const GET_ALL_CUSTOMER_PROFILE_LIST_ERROR = 'GET_ALL_CUSTOMER_PROFILE_LIST_ERROR';

const GET_ALL_CUSTOMER_LOCATIONS_STARTED = 'GET_ALL_CUSTOMER_LOCATIONS_STARTED';
const GET_ALL_CUSTOMER_LOCATIONS_SUCCESS = 'GET_ALL_CUSTOMER_LOCATIONS_SUCCESS';
const GET_ALL_CUSTOMER_LOCATIONS_ERROR = 'GET_ALL_CUSTOMER_LOCATIONS_ERROR';

const GET_CUSTOMER_INFO_REQUEST = 'GET_CUSTOMER_INFO_REQUEST';
const GET_CUSTOMER_INFO_SUCCESS = 'GET_CUSTOMER_INFO_SUCCESS';
const GET_CUSTOMER_INFO_FAILURE = 'GET_CUSTOMER_INFO_FAILURE';

const CustomerTypes = {
    GET_ALL_CUSTOMER_LIST_STARTED,
    GET_ALL_CUSTOMER_LIST_SUCCESS,
    GET_ALL_CUSTOMER_LIST_ERROR,
    GET_ALL_CUSTOMER_PROFILE_LIST_STARTED,
    GET_ALL_CUSTOMER_PROFILE_LIST_SUCCESS,
    GET_ALL_CUSTOMER_PROFILE_LIST_ERROR,
    GET_ALL_CUSTOMER_LOCATIONS_STARTED,
    GET_ALL_CUSTOMER_LOCATIONS_SUCCESS,
    GET_ALL_CUSTOMER_LOCATIONS_ERROR,

    GET_CUSTOMER_INFO_REQUEST,
    GET_CUSTOMER_INFO_SUCCESS,
    GET_CUSTOMER_INFO_FAILURE
}

export default CustomerTypes;
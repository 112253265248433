import React, { useEffect, useRef, useState } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover";
import {Card, CustomerList, CustomerProfileList, MasterOrder, Stop, StorageSection} from "../../../classes/classes";
import {Reference} from '../../../services/endpoints/schemas/master_order/master_order';
import {Customer} from '../../../services/endpoints/schemas/customer/customer'
import CustomerQueries from "../../../graphql/query/Customer";
import { GraphqlService } from "../../../services/graphql.service";
import FormGroup from "../../FormGroup/FormGroup";
import Searchbar from "../../Searchbar/Searchbar";
// import { FixedSizeList as List } from 'react-window';
import {AutoSizer, List} from 'react-virtualized';
import MultilineEllipsis from "../../MultilineEllipsis/MultilineEllipsis";
import ICONS from "../../../../assets/svg";
import Loading from "../../Loading/Loading";
import ReactModal from "react-modal";
import LocationForm from "../LocationForm/LocationForm";
import EditBillingAddress from "../EditBillingAddress/EditBillingAddress";
import ContactsSearch from "../ContactsSearch/ContactsSearch";
import CustomerSearch from "../CustomerSearch/CustomerSearch";
import CreditCardForm from "../../CreditCardForm/CreditCardForm";
import { gql } from "apollo-boost";
import { Customer_Profile } from "../../../services/endpoints/schemas/customer_profile/customer_profile";
// import CustomerTable from "../../CustomerTable/CustomerTable";


const CustomerCard = ({card, openCustomersByDefault = true, onCardChanged, onEditChanged, onReferencesChanged, customer, customerProfiles, onChangeCustomer, references, onEditReferencesClicked, quickAction}: {onReferencesChanged?, customer: Customer, customerProfiles: Customer_Profile[], onChangeCustomer: (customer?, contacts?) => void, references: Reference[], onEditReferencesClicked?: (evt) => void, onEditChanged, quickAction, card: Card, onCardChanged, openCustomersByDefault?}) => {


    const [cards, setCards] = useState<Card[]>([]);
    const [orderOptionsShown, setOrderOptionsShown] = useState(0);
    const [customers, setCustomers] = useState<CustomerList[]>([]);
    const [customerName, setCustomerName] = useState(null);
    const customerRef = useRef<HTMLInputElement>();
    const customerCardRef = useRef<HTMLDivElement>();
    const [customerLoading, setCustomerLoading] = useState(false);
    const [billingAddressOpen, setBillingAddressOpen] = useState(false);
    const [referencesOpened, setReferencesOpened] = useState(false);
    const [_references, setReferences] = useState<Reference[]>([]);
    const [contactName, setContactName] = useState(undefined);

    const [contacts, setContacts] = useState<CustomerProfileList[]>([]);
    const [contactLoading, setContactLoading] = useState(false);
    const [searchCustomerOpen, setSearchCustomerOpen] = useState(customer.id == null && openCustomersByDefault);

    async function load() {
        try {
            setCustomerLoading(true);
            const data = await GraphqlService.SendQuery(CustomerQueries.GET_ALL_LIST);
            //console.log(data);
            const temp = data.map(x => new CustomerList(x)) as CustomerList[];
            setCustomers(temp.sort((x, y) => x.name.localeCompare(y.name)));
            setCustomerLoading(false);
        } catch (ex) {
            setCustomerLoading(false);
        }
    }

    async function loadContacts() {
        try {
            setContactLoading(true);
            const data = await GraphqlService.SendQuery(CustomerQueries.GET_ALL_PROFILE_LIST, {customerId: customer.id});
            const temp = data.map(x => new CustomerProfileList(x)) as CustomerProfileList[];
            setContacts(temp.sort((x, y) => x.name.localeCompare(y.name)));
            setContactLoading(false);
        } catch (ex) {
            setContactLoading(false);
        }
    }
    const [contactsOpened, setContactsOpened] = useState(false);

    const customersFiltered = () => customers.filter(x => JSON.stringify(x).toLowerCase().includes(customerName?.toLowerCase()));
    const contactsFiltered = () => contacts.filter(x => JSON.stringify(x).toLowerCase().includes(contactName?.toLowerCase()));

    async function changeBillingAddress(customer_id: number) {
        setBillingAddressOpen(true);
        onEditChanged(true);
    }




    useEffect(() => {
        load().then();
    }, [])

    useEffect(() => {
        if(quickAction){
            switch(quickAction){
                case "Esc":

                    setContactsOpened(false);
                if (customer.id)
                    setSearchCustomerOpen(false);
                    break;
                // case "1": setSearchCustomerOpen(true);
                //     break;
                // case "2":
                // if (customer.id)
                //     setContactsOpened(true);
                //     break;

                case "CloseCustomer":
                    if (customer.id)
                        setSearchCustomerOpen(false);
                    setContactsOpened(false);

            }
        }
    }, [quickAction])

    useEffect(() => {

        if (customer.id)
        {
            loadContacts().then();
        }
    }, [customer]);


    async function loadCards() {
        let cardQuery = gql`query($customer_id: Int){
            attributes_by_customerId(customer_id:$customer_id){
              id
              cardholder_name
              number
              type
              expired
            }
          }`;

          try {
            const data = await GraphqlService.SendQuery(cardQuery, {customer_id: customer.id});
            setCards(data);
            //console.log(data);
          } catch (ex) {
              console.log('ex', ex.message);
              setCards([]);
          }
    }



    const getModalOptionsScreen = () => {
        switch (orderOptionsShown)
        {

        case 1:
            return <div className="flex-1-container">
            <div className='row align-items-center mb-3'>
                {/* <button onClick={(evt) => setOrderOptionsShown(0)} className='btn btn-clear'><i className="fas fa-arrow-left mr-1"></i>  BACK</button>     */}
                <h4 className='font-14'>PAYMENT METHOD</h4>
                <h4 onClick={(evt) => setOrderOptionsShown(0)} className="font-14 cursor-pointer">
                    <i className="fas fa-times"></i>
                </h4>

            </div>

            <div className="row align-items-center">
                <h4 className='font-14'>Card List</h4>
                <h4 onClick={(evt) => {setOrderOptionsShown(2);}} className='font-14 text-light-blue d-flex align-items-center cursor-pointer no-select' style={{fontWeight: 400}}>ADD NEW <img src={ICONS.IconPlus} alt="" /></h4>
            </div>

            {/* {master.contacts.map(x => <button className="btn btn-blue-outline btn-block font-14 mt-3">{x.name} ({x.title})</button>)} */}

            {cards == null ? <div>
                <Loading />
                <h4 className="font-14 text-center">LOADING CREDIT CARDS...</h4>
            </div> : <div className="flex-1-container mt-3">{cards.map((x, i) => <button disabled={x.expired} onClick={(evt) => {onCardChanged(x); setOrderOptionsShown(0)}} className={`btn btn-blue-outline btn-block font-14 mt-${i == 0 ? '0' : '3'} px-2 d-flex justify-content-between`}><span>{x.cardholder_name} {x.type} *{x.number}</span>  <span className={(x.expired ? 'text-red' : 'text-green' ) + ' font-bold'}>{x.expired ? 'EXPIRED' : 'ACTIVE'}</span></button>)}</div>}
        </div>

        case 2:
            return <div className="flex-1-container">
            <div className='d-flex align-items-center mb-3'>
                <h4 className='font-16'>CREATE NEW CREDIT CARD</h4>
            </div>



            {/* {master.contacts.map(x => <button className="btn btn-blue-outline btn-block font-14 mt-3">{x.name} ({x.title})</button>)} */}
            <div className="flex-1-container">
                <CreditCardForm customerID={customer.id} onCancelClicked={(evt) => setOrderOptionsShown(1)} onCardSaved={(c) => {onCardChanged(c); setOrderOptionsShown(0);}} />
            </div>
        </div>
        }
    }


    function CustomerPopover()
    {
        return <Popover


        isOpen={customerName != null}
        positions={['right', 'left', 'top', 'bottom']} // if you'd like, you can limit the positions
        padding={16} // adjust padding here!
        reposition={true} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds

        onClickOutside={(evt) => {
            console.log('out', customerName)
            const id = (evt.srcElement as HTMLButtonElement)?.id;

            if (id == 'changebillcustomer')
                return;

            setCustomerName(null);
        }} // handle click events outside of the popover/target here!
        content={({ position, nudgedLeft, nudgedTop, childRect, popoverRect }) => ( // you can also provide a render function that injects some useful stuff!
        <ArrowContainer arrowSize={10} arrowColor={'white'} position={position} childRect={childRect} popoverRect={popoverRect}>
            <div className='popover-box d-flex' style={{minHeight: '50vh', minWidth: '300px'}}>


            </div>
        </ArrowContainer>
        )}
      >
           <div></div>
      </Popover>
    }

    function ContactsPopover()
    {
        return <Popover


        isOpen={contactName != null}
        positions={['right', 'left', 'top', 'bottom']} // if you'd like, you can limit the positions
        padding={16} // adjust padding here!
        reposition={true} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds

        onClickOutside={(evt) => {
            console.log('out', customerName)
            const id = (evt.srcElement as HTMLButtonElement)?.id;
            if (id == 'unselectall' || id == 'selectcontacts' || id == 'selectcontacts_1')
                return;


            onEditChanged(false);
            setContactName(null);
        }} // handle click events outside of the popover/target here!
        content={({ position, nudgedLeft, nudgedTop, childRect, popoverRect }) => ( // you can also provide a render function that injects some useful stuff!
        <ArrowContainer arrowSize={10} arrowColor={'white'} position={position} childRect={childRect} popoverRect={popoverRect}>
            <div className='popover-box d-flex' style={{minHeight: '50vh', minWidth: '300px'}}>


            </div>
        </ArrowContainer>
        )}
      >
            <button onClick={(evt) => {
                onEditChanged(true);
                setContactsOpened(true);
            }} id={'changeordercontacts'} className="btn btn-orange font-6">ADD / REPLACE CONTACTS</button>
      </Popover>

    }

    const contactsRef = useRef<HTMLInputElement>();

    return (
        <div className="p-1" style={{backgroundColor: '#F6F8FA'}}>

            <div className="row col-12 py-3 bg-white position-relative" style={{'borderRadius': '8px', boxShadow: '0px 0px 8px #CCC', }}>
            {searchCustomerOpen &&  <div className='test-customer-search flex-1-container bg-white rounded p-3' style={{boxShadow: '0 3px 6px #00000029', zIndex: 9}}>
                                <div className="flex-1-container">
                                    <CustomerSearch
                                        onCancel={(evt) => setSearchCustomerOpen(false)}
                                    customer_id={customer.id} onChangeCustomer={(myCustomer, contacts) => {
                                        setSearchCustomerOpen(false);
                                        onChangeCustomer(myCustomer, myCustomer?.id == customer.id ? customerProfiles : contacts);
                                        setContactsOpened(false);
                                        setTimeout(() => {
                                            setContactsOpened(true);
                                        })
                                    }} />
                                </div>
                       </div>}
            {contactsOpened && <div className='p-3 d-flex' style={{zIndex: 9, position: 'absolute', top: 0, right: 0, height: '92vh', width: '28%', background: '#FFF', boxShadow: '0 3px 6px #00000029'}}>
                    <ContactsSearch onCancel={(evt) => {
                        setContactsOpened(false);
                        onEditChanged(false);
                    }} customer_id={customer.id} customerProfiles={customerProfiles} onContactsChanged={(contacts) => onChangeCustomer(customer, contacts)} />
                </div>}
                <div className="col-12 px-3">
                    <div className="row col-12">

                        {/*CUSTOMER*/}
                        <div className="col-2">
                            <div className="col-12">
                                <div className="row mb-1">
                                    <p className="font-10 font-medium">#1 Billing Customer</p>
                                    { <button id='changebillcustomer' className="btn btn-orange font-6" onClick={() => {
                                        //  setCustomerName('');
                                            setSearchCustomerOpen(true);

                                    }}>{customer.id ?'CHANGE' : 'SELECT' }</button>}
                                </div>

                                {customer.id && <div ref={customerCardRef} onClick={(evt) => {
                                        setSearchCustomerOpen(true);
                                    }} className={"col-12 cursor-pointer pl-2" + (customer.id ? '' : ' bg-red-light')} >
                                    <>
                                    <div  className="col-12">
                                        <p className="col-12 font-10 font-medium">{customer.name}</p>
                                        <div className="row mt-1">
                                            <p className="font-italic font-9">{customer.corporate_client ? 'CORPORATE':'PRIVATE'}</p>
                                            <p className="font-9">{customer.key}</p>
                                        </div>
                                    </div>
                        </>

                                </div>}
                            </div>
                        </div>


                        {customer.id && <div className="row col-10">
                            {/*BILLING ADDRESS*/}
                            <div className="col-3">
                                <div className="col-12">
                                    <div className="row mb-1">
                                        <p className="font-10 font-medium">Billing Address</p>
                                        {customer.id && <button id='changebillingaddress' className="btn btn-orange font-6" onClick={() => {
                                                //  setCustomerName('');
                                                    changeBillingAddress(customer.id);

                                            }}>CHANGE BILLING ADDRESS</button>}
                                    </div>
                                </div>
                                <div className="col-12 cursor-pointer pl-2" onClick={(evt) => changeBillingAddress(customer.id)}>
                                    <div  className="col-12">
                                        <p className="col-12 font-10 font-medium">{customer.billing_address?.getName()}</p>
                                        <p className="col-12 mt-1 font-italic font-9">{customer?.billing_address.getName(2)+', '+customer.billing_address?.country}</p>

                                    </div>
                                </div>
                            </div>

                            {/*REFERENCES*/}
                            <div className='col-2 cursor-pointer' onClick={(evt) => {
                                        // setReferencesOpened(true);
                                        // onEditChanged(true);
                                    }}>
                                <div className="row mb-1">
                                    <p className="font-10 font-medium">References</p>
                                    {/* <button className="btn btn-orange font-6" onClick={(evt) => {
                                        setReferencesOpened(true);
                                        onEditChanged(true);
                                    }}>{(references.length > 0 ? 'EDIT':'ADD')+' REFERENCES'}</button> */}

                                    <button className="btn btn-danger-light" onClick={(evt) => {
                                        onReferencesChanged(new MasterOrder().references)
                                    }}>CLEAR</button>

                                </div>
                                <div className="col-12 rounded pl-2 cursor-pointer" >
                                    {/* <p className='font-9'>{references.map((x, index) => <><strong>{x.key}: </strong> {x.value}{index == references.length - 1 ? '' : ', '}</>)}</p> */}
                                    {/* {references.length} */}
                                    {references.map((reference, index) => <div className="row mt-2">
                                        <div className="col-3 pt-2">
                                            <p className="font-9">{reference.key}:</p>
                                        </div>
                                        <div className="col-9 form-group mb-0">
                                            <input value={reference.value} onChange={(evt) => {
                                                const temp = [...references];
                                                temp[index].value = evt.target.value;
                                                onReferencesChanged(temp)
                                            }} className="col-12 form-field font-9" style={{padding: '.4rem'}} type="text" placeholder={reference.key} />
                                        </div>
                                    </div>)}
                                </div>
                            </div>

                            {/*CARD*/}
                            <div className="col-3">
                                <div className="row">
                                    <h6 className="font-10">Card on File</h6>
                                    <button onClick={(evt) => {loadCards(); setOrderOptionsShown(1)}} className="btn font-6 btn-orange">{card.id ? 'REPLACE' : 'ADD'}</button>
                                </div>
                                <div className="pl-2">
                                    <div>
                                        {card.id && <h6 className="font-9">{card.type} *{card.number}</h6>}
                                        {!card.id && <h6 className="font-9">-</h6>}
                                        {card.id && <div className="d-flex mt-1">
                                            <h6 onClick={(evt) => onCardChanged(null)} className="font-9 text-red cursor-pointer">Remove</h6>
                                        </div>}
                                    </div>
                                </div>
                            </div>

                            {/*CONTACTS*/}
                            <div className="col-4" onClick={(evt) => evt.stopPropagation()}>
                                <div className="col-12 row mb-1">
                                    <p className="font-10 font-medium ">#2 Order Contacts</p>
                                    {ContactsPopover()}
                                </div>
                                {customerProfiles.length == 0 && <div id='selectcontacts' className='p-2 col-12 rounded cursor-pointer' onClick={(evt) => {
                                    setContactsOpened(true);
                                    onEditChanged(true);

                                }}>
                                        <Searchbar className={'mr-4 ml-1'} background={'#f2f2f2'} reference={contactsRef} />
                                    </div>}

                                {customerProfiles.length > 0 && <div className="col-12 cursor-pointer" onClick={(evt) => {
                                    setContactsOpened(true);
                                    onEditChanged(true);

                                }}>
                                            <div className="col-12 pl-2 pb-2">
                                                <h6 className="col-12 font-10">{customerProfiles.map(x => x.name).join(', ')}</h6>
                                            </div>
                                    </div>}
                            </div>
                        </div>}




                    </div>
                </div>
            </div>


            <ReactModal isOpen={billingAddressOpen}>
                <EditBillingAddress customer={customer} onCancel={(evt) => {

                    setBillingAddressOpen(false);
                    onEditChanged(false);

                }} onSubmit={(evt) => {
                    const temp = new CustomerList(customer as any);
                    temp.billing_address = evt; 
                    onChangeCustomer(temp, customerProfiles);
                    setBillingAddressOpen(false);
                    onEditChanged(false);
                    // te
                }} />
            </ReactModal>


            <ReactModal isOpen={referencesOpened} >
            <div className='row align-items-center mb-3'>
                <h4 className='font-16'>Master References</h4>
                <img src={ICONS.IconCloseBig} onClick={(evt) => {
                    setReferencesOpened(false);
                    onEditChanged(false);

                }} alt="" />
            </div>
            <div className="col-12 row mb-1">
                <h4 className='font-14 col-4'>Key</h4>
                <h4 className='font-14 col-4'>Value</h4>
                <h4 onClick={(evt) => {
                        setReferences([..._references, new Reference()]);
                    }} className='font-12 cursor-pointer text-light-blue d-flex align-items-center' style={{fontWeight: 400}}>ADD NEW <img src={ICONS.IconPlus} alt="" /></h4>
            </div>
            <div style={{ flex: 1, overflow: 'auto'}}>

                    {_references.map((x, i) => <div className='row align-items-start'>
                        <FormGroup placeholder={'Key - ' + (i + 1)} type='combobox' options={{data: ['PO', 'RO', 'STOCK'], label: x=>x, value: x=>x}} colSize={4} name={'r-key-' + i} value={x.key} onTextChange={(evt) => {
                            const temp = [..._references];
                            temp[i].key = evt;
                            setReferences(temp);
                        }} />
                        <FormGroup placeholder={'Value - ' + (i + 1)} colSize={4} name={'r-value-' + i}  value={x.value} onTextChange={(evt) => {
                            const temp = [..._references];
                            temp[i].value = evt;
                            setReferences(temp);
                        }} />
                        <button onClick={(evt) => {
                            const temp = [..._references];
                            temp.splice(i, 1);
                            setReferences(temp);
                        }} className='btn btn-danger py-2'>REMOVE</button>
                    </div>)}
            </div>
            <div className='row align-items-center mt-3'>
                <button onClick={(evt) => {
                    onEditChanged(false);
                    setReferencesOpened(false)}} className="btn btn-danger col-6 py-2">CANCEL</button>
                <button onClick={async (evt) => {
                    if (onReferencesChanged)
                        onReferencesChanged(_references);
                        onEditChanged(false);
                    setReferencesOpened(false);
                }} disabled={_references.length != 0 && _references.some(x => x.key.trim() == '' || x.value.trim() == '')} className="btn btn-blue-light col-6 py-2">SAVE</button>
            </div>
    </ReactModal>


    <ReactModal isOpen={orderOptionsShown > 0}>
        {getModalOptionsScreen()}
    </ReactModal>

        </div>
    );
}

export default CustomerCard;

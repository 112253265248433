import {Carrier} from "../carrier/carrier";
import {Employee} from "../employee/employee";
import {Driver_Distance, Samsara_Truck, T_Location} from "../truck/truck";
import {Equipment} from "../equipment/equipment";
import {Contact} from "../location/location";
import {Vehicle} from "../vehicle/vehicle";
import {Velox_Change_Request} from "../leg/leg";

export class Driver {
    __typename?: "Driver"
    id?: number = null;
    name?: string = '';
    email?: string = '';
    phones?: string[] = [];
    carrier?: Carrier = new Carrier();
    employee?: Employee = new Employee();
    velox_id?: number = null;
    samsara_truck?: Samsara_Truck = new Samsara_Truck();
    equipment?: string;
    active?: boolean = true;
    contacts?: Contact[] = [];
    
    constructor(x?: Driver){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.email = x.email;
            this.phones = x.phones?.map(z => z) || [];
            this.carrier =  new Carrier(x.carrier);
            this.employee =  new Employee(x.employee);
            this.velox_id = x.velox_id;
            this.samsara_truck = new Samsara_Truck(x.samsara_truck);
            this.equipment = x.equipment;
            this.active = x.active;
            this.contacts = x.contacts?.map(z => z) || [];            
        }
    }

}

export class Closest_Driver {
    __typename?: "Closest_driver"
    velox_id?: number = null;
    next_stop?: Next_Stop = new Next_Stop();
    samsara_id?: string = '';
    location?: T_Location = new T_Location();
    driver_id?: number = null;
    name?: string = '';
    gps?: Gps_Driver[] = [];
    google?: Closest_Driver_Google = new Closest_Driver_Google();

    constructor(x?: Closest_Driver) {
        if(x) {
            this.velox_id = x.velox_id;
            this.next_stop =  new Next_Stop(x.next_stop);
            this.samsara_id = x.samsara_id;
            this.location =  new T_Location(x.location);
            this.driver_id = x.driver_id;
            this.name = x.name;
            this.gps = x.gps?.map(z => z) || [];
            this.google =  new Closest_Driver_Google(x.google);
        }
    }
}

export class Next_Stop {
    __typename?: "Next_stop"
    address_string?: string = '';
    vehicle_description?: string = '';
    time?: string = '';

    constructor(x?: Next_Stop){
        if(x){
            this.address_string = x.address_string;
            this.vehicle_description = x.vehicle_description;
            this.time = x.time;            
        }
    }
}

export class Closest_Driver_Google {
    __typename?: "Closest_driver_google"
    regular?: Driver_Distance = new Driver_Distance();
    direct?: Driver_Distance = new Driver_Distance();

    constructor(x?: Closest_Driver_Google) {
        if(x) {
            this.regular = new Driver_Distance(x.regular);
            this.direct = new Driver_Distance(x.direct);

        }
    }
}

export class Gps_Driver {
    __typename?: "GPS_driver"
    Speed?: number = 0;
    Course?: number = 0;
    Altitude?: number = 0;
    Timestamp?: string = '';
    Coordinate?: Coordinate_Driver = new Coordinate_Driver();
    SpeedAccuracy?: number = 0;
    CourseAccuracy?: number = 0;
    VerticalAccuracy?: number = 0;
    HorizontalAccuracy?: number = 0;

    constructor(x?: Gps_Driver){
        if(x){
            this.Speed = x.Speed;
            this.Course = x.Course;
            this.Altitude = x.Altitude;
            this.Timestamp = x.Timestamp;
            this.Coordinate = new Coordinate_Driver(x.Coordinate);
            this.SpeedAccuracy = x.SpeedAccuracy;
            this.CourseAccuracy = x.CourseAccuracy;
            this.VerticalAccuracy = x.VerticalAccuracy;
            this.HorizontalAccuracy = x.HorizontalAccuracy;            
        }
    }
}

export class Coordinate_Driver {
    __typename?: "Coordinate_driver"
    Latitude?: number = 0;
    Longitude?: number = 0;

    constructor(x?: Coordinate_Driver){
        if(x){
            this.Latitude = x.Latitude;
            this.Longitude = x.Longitude;
        }
    }
}

export class Driver_Contact_Input {
    type?: string = '';
    title?: string = '';
    value?: string = '';

    constructor(x?:Driver_Contact_Input){
        if(x){
            this.type = x.type;
            this.title = x.title;
            this.value = x.value;
        }
    }
}

export class Driver_Input {
    id?: number = null;
    name?: string = '';
    equipment?: string = '';
    email?: string = '';
    phones?: string[] = [];

    constructor (x?: Driver_Input){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.equipment = x.equipment;
            this.email = x.email;
            this.phones = x.phones?.map(z => z) || [];            
        }
    }
}

export class Carrier_Driver_Res {
    __typename?: "carrier_driver_res"
    driver?: ScheduleSp = new ScheduleSp();

    constructor(x?: Carrier_Driver_Res){
        if(x){
            this.driver = new ScheduleSp(x.driver);
        }
    }
}

export class ScheduleSp {
    __typename?: "scheduleSP"
    id?: number = null;
    name?: string = '';
    username?: string = '';
    price?: number = 0;
    carrier?: string = '';
    carrier_id?: number = null;
    status?: StatusSp = new StatusSp();
    equipment?: string = '';
    contacts?: Contact[] = [];
    samsara_truck_id?: string = '';
    itinerary?: ItinerarySp[] = [];

    constructor (x?: ScheduleSp){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.username = x.username;
            this.price = x.price;
            this.carrier = x.carrier;
            this.carrier_id = x.carrier_id;
            this.status =  new StatusSp(x.status);
            this.equipment = x.equipment;
            this.contacts = x.contacts?.map(z => z) || [];
            this.samsara_truck_id = x.samsara_truck_id;
            this.itinerary = x.itinerary?.map(z => z) || [];            
        }
    }
}

export class ItinerarySp {
    __typename?: "itinerarySP"
    orders?: OrderSp[] = [];
    address?: string = '';

    constructor(x?: ItinerarySp){
        if(x){
            this.orders = x.orders?.map(z => z) || [];
            this.address = x.address;
        }
    }
}

export class StatusSp {
    __typename?: "statusSP"
    text?: string = '';
    text_color?: string = '';
    background_color?: string = '';
    border_color?: string = '';

    constructor(x?: StatusSp){
        if(x){
            this.text = x.text;
            this.text_color = x.text_color;
            this.background_color = x.background_color;
            this.border_color = x.border_color;            
        }
    }
}

export class OrderSp {
    __typename?: "orderSP"
    id?: number = null;
    vehicle?: Vehicle = new Vehicle();
    order_id?: number = null;
    is_pickup?: boolean = false;
    scheduled?: string = '';
    completed?: boolean = false;
    nofail?: boolean = false;
    timezoneName?: string = '';
    contacts?: OrderContact[] = [];
    address?: string = null;
    pending_change_request?: boolean = false;
    change_requests?: Velox_Change_Request[] = []

    constructor(x?: OrderSp){
        if(x){
            this.id = x.id;
            this.vehicle =  new Vehicle(x.vehicle);
            this.order_id = x.order_id;
            this.is_pickup = x.is_pickup;
            this.scheduled = x.scheduled;
            this.completed = x.completed;
            this.nofail = x.nofail;
            this.timezoneName = x.timezoneName;
            this.contacts = x.contacts?.map(z => z) || [];
            this.address = x.address;
            this.pending_change_request = x.pending_change_request;
            this.change_requests = x.change_requests?.map(z => z) || [];            
        }
    }
}

export class OrderContact {
    __typename?: "OrderContact"
    name?: string = '';
    value?: string[] = [];

    constructor(x?: OrderContact){
        if(x){
            this.name = x.name;
            this.value = x.value
        }
    }
}

export class Locations_Sp {
    __typename?: "locations_sp"
    id?: number = null;
    name?: string = '';
    devices?: Device_Location[] = [];
    username?: string = '';
    samsara_truck_id?: string = '';

    constructor(x?: Locations_Sp){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.devices = x.devices?.map(z => z) || [];
            this.username = x.username;
            this.samsara_truck_id = x.samsara_truck_id;            
        }
    }
}

export class Device_Location {
    __typename?: "device_location"
    time?: string | Date = new Date();
    speed?: number = 0;
    course?: number = 0;
    device?: string = null;
    altitude?: string = null;
    coordinate?: Coordinate_Location = new Coordinate_Location();
    device_info?: Device_Info_Location = new Device_Info_Location();
    background_mode?: boolean = false;

    constructor(x?: Device_Location){
        if(x){
            this.time = new Date(x.time);
            this.speed = x.speed;
            this.course = x.course;
            this.device = x.device;
            this.altitude = x.altitude;
            this.coordinate = new Coordinate_Location(x.coordinate);
            this.device_info = new Device_Info_Location(x.device_info);
            this.background_mode = x.background_mode;           
        }
    }
}

export class Coordinate_Location {
    __typename?: "coordinate_location"
    Latitude?: string = '';
    Longitude?: string = '';

    constructor(x?: Coordinate_Location){
        if(x){
            this.Latitude = x.Latitude;
            this.Longitude = x.Longitude;
        }
    }
}

export class Device_Info_Location {
    __typename?: "device_info_location"
    name?: string = '';
    type?: string = '';
    idiom?: string = '';
    model?: string = '';
    version?: string = '';
    platform?: string = '';
    manufacturer?: string = '';

    constructor(x?: Device_Info_Location){
        if(x){
            this.name = x.name;
            this.type = x.type;
            this.idiom = x.idiom;
            this.model = x.model;
            this.version = x.version;
            this.platform = x.platform;
            this.manufacturer = x.manufacturer;            
        }
    }
}

import { gql } from 'apollo-boost';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import ICONS from '../../../assets/svg';
import Helper from '../../helper/Helper';
import useLoading from '../../hooks/useLoading/useLoading';
import { GraphqlService } from '../../services/graphql.service';
import FormGroup from '../FormGroup/FormGroup';
import Searchbar from '../Searchbar/Searchbar';
import IColumn from '../Table/models/IColumn';
import Table from '../Table/Table';

interface IBatchEdit {
    data: any[],
    onClose: (evt) => any,
    onSubmitted: () => any
}

const columns: IColumn[] = [
    {
        label: 'MASTER',
        active: false,
        orderBy: 'DESC',
        name: 'master_column',
        sort_by: 'master_id'
    },
    {
        label: 'INVOICE #',
        active: false,
        orderBy: 'DESC',
        name: 'invoice_column',
        sort_by: 'first_invoice_id'
    },
    {
        label: 'CUSTOMER',
        active: false,
        orderBy: 'DESC',
        name: 'customer'
    },
    {
        label: 'CURRENT INVOICE PRICE',
        active: false,
        orderBy: 'DESC',
        name: 'last_invoice_price',
        sort_by: 'last_invoice_amount'
    },
    {
        label: 'PO #',
        active: false,
        orderBy: 'DESC',
        name: 'po',
        sort_by: 'po_value'
    },
    {
        label: 'RO #',
        active: false,
        orderBy: 'DESC',
        name: 'ro',
        sort_by: 'ro_value'
    },
    {
        label: 'STOCK #',
        active: false,
        orderBy: 'DESC',
        name: 'stock',
        sort_by: 'stock_value'
    }
];

const BatchEdit: FC<IBatchEdit> = (props) => {

    const invRef = useRef();
    const searchRef = useRef();

    const [data, setData] = useState([]);
    const dataRef = useRef<any[]>([])

    const loading = useLoading();
    const toast = useToasts();

    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermInv, setSearchTermInv] = useState('');

    function handleRefChanged(key, value, master_id) {
        
        console.log(dataRef.current.length, master_id)
        const index = dataRef.current.findIndex(x => x.master_id == master_id);
        console.log(key, value, master_id, index);
        if (index > -1)
        {
            const temp = [...dataRef.current];
            temp[index][key] = value;
            fillData(temp);
            
        }

        
    }

    useEffect(() => {
        fillData(props.data);
    }, [props.data]);

    async function updateBatch() {
        try {
            loading.open('Saving...');
            const mutation = gql`
                mutation($masters: [batch_master_references]){
                    update_master_order_references_batch(masters: $masters){
                    success
                        message
                    }
                }
            `;

            const masters = data.map(x => ({id: x.master_id, po: x.po_value?.trim() || '', ro: x.ro_value?.trim() || '', stock: x.stock_value?.trim() || ''}));
            const result = await GraphqlService.SendMutation(mutation, {masters});

            if (!result?.success)
                throw new Error(result?.message || 'Something Went Wrong');
            loading.close();
            toast.addToast(result.message, {appearance: 'success', autoDismiss: true});
            props.onSubmitted();
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    const getFiltered = () => data.filter((x: any) => {
        // if (search == '' && InvoiceStatuses.ALL == invoiceStatus) return true;
        // console.log(x.invoice_id, searchTermInv);

        const search = Helper.Masks.IntNumbers(searchTermInv);

        return ((x.invoices).map(x => x.id.toString()).includes(search) || search == '')
            &&
            JSON.stringify(x).toLowerCase().includes(searchTerm.trim().toLowerCase());
    });


    function fillData(info) {
        const x = info.map((x, index) => {
            x.po_value = x.po_value != null ? x.po_value : (x.references.find(x => x.key?.toUpperCase() == 'PO')?.value || '').trim();
            x.ro_value = x.ro_value != null ? x.ro_value : (x.references.find(x => x.key?.toUpperCase() == 'RO')?.value || '').trim();
            x.stock_value = x.stock_value != null ? x.stock_value : (x.references.find(x => x.key?.toUpperCase() == 'STOCK')?.value || '').trim();
            return {
                ...x,
                master_id: x.master_id,
                po:    <FormGroup value={x.po_value} onTextChange={text => handleRefChanged('po_value', text, x.master_id)} name={`po_${index}`} placeholder='PO #'></FormGroup>,
                ro:    <FormGroup value={x.ro_value} onTextChange={text => handleRefChanged('ro_value', text, x.master_id)} name={`ro_${index}`} placeholder='RO #'></FormGroup>,
                stock: <FormGroup value={x.stock_value} onTextChange={text => handleRefChanged('stock_value', text, x.master_id)} name={`stock_${index}`} placeholder='STOCK #'></FormGroup>
            }
        });
        dataRef.current = [...x];
        setData(x);
    }

    return <div className='flex-1-container'>
        <div className="row mb-3">
            <div className="col-6">
                <h4 className="font-11">Batch Edit {getFiltered().length} / {data.length}</h4>
            </div>
            <div className='col-6 d-flex align-items-center'>
                
                <Searchbar value={searchTerm}
                                       placeholder='Search All Fields...'
                                       onChange={(evt) => setSearchTerm(evt.target.value)}
                                       background={'#F3F6F8'}
                                       reference={searchRef}
                                       className='col-12 mx-2'
                                       onFocus={(x) => setSearchTermInv('')}/>
                <Searchbar value={searchTermInv}
                                           placeholder={'Invoice #...'}
                                           onChange={(evt) => setSearchTermInv(evt.target.value)}
                                           background={'#F3F6F8'}
                                           reference={invRef}
                                           className='col-12'
                                           onFocus={(x) => setSearchTerm('')}/>
            </div>
        </div>
        <Table columns={columns} rows={getFiltered()} onRowClicked={(evt) => false} />

        <div className="mt-3 row">
            <button className="btn btn-clear text-blue-light py-2 col-6" onClick={props.onClose}>CANCEL</button>
            <button className="btn btn-blue-light py-2 col-6" onClick={(evt) => updateBatch()}>UPDATE</button>
        </div>

    </div>
}

export default BatchEdit;
import React, {useCallback, useEffect, useRef, useState} from 'react';
import QuotesTable from "../../components/QuotesTable/QuotesTable";
import Table from "../../components/Table/Table";
import IColumn from "../../components/Table/models/IColumn";
import ICONS from "../../../assets/svg";
import Searchbar from "../../components/Searchbar/Searchbar";
import FormGroup from '../../components/FormGroup/FormGroup';
import { Address, Warehouse } from '../../classes/classes';
import { GraphqlService } from '../../services/graphql.service';
import { gql } from 'apollo-boost';
import Helper from '../../helper/Helper';
import OrdersTable from '../../components/OrdersTable/OrdersTable';



const OrdersPage = () => {
    



    

    return <>
    
    <div className="mb-3">
        <h4 className="font-14">Orders</h4>
    </div>
    
    <div className={'flex-1-container rounded p-3 rounded bg-white'}>

        <OrdersTable />
        

</div></>
}

export default OrdersPage;

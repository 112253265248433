import gql from "graphql-tag";



const CREATE_VELOX_PROFILE = gql`
    mutation($name: String, $username: String, $password: String,  $email: String, $equipment:String, $phones: [String]){
        create_velox_driver(name: $name, username: $username, password:  $password, equipment:$equipment, email:$email, phones: $phones){
            id
            message
            success
            link
        }
    } 
`;


const UPDATE_VELOX_PROFILE = gql`
    mutation($driver:driver_input){
        update_velox_driver(driver:$driver){
        id
        message
        success
        }
    } 
`;


const VeloxProfileMutations = {
    CREATE_VELOX_PROFILE,
    UPDATE_VELOX_PROFILE

};

export default VeloxProfileMutations


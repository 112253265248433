import React, {useEffect, useState} from "react";
import logo from "../assets/svg/logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useHistory
} from "react-router-dom";
import MainPage from "./pages/MainPage";
import Helper from "./helper/Helper";
import LoginPage from "./pages/LoginPage/LoginPage";
import {GraphqlService} from "./services/graphql.service";
import {gql} from "apollo-boost";
import UserSettingsQueries from "./graphql/query/UserSettings";
import {UserSettings} from "./classes/classes";
import {useDispatch} from "react-redux";
import setUserSettings from "./redux/actions/UserSettings.action";
import ReactModal from "react-modal";
import { setLastID } from "./redux/actions/Notifications.action";
import CheckPrinter from "./components/CheckPrinter/CheckPrinter";
import numberToWords from 'number-to-words';
import InspectionReportPage from "./pages/InspectionReportPage/InspectionReport";
import RedirectPage from "./pages/RedirectPage/RedirectPage";

function App() {

  const history = useHistory();
  
  Helper.SetNavigator(history);
  const dispatch = useDispatch();
  
    useEffect(() => {
        ReactModal.setAppElement('body');
        dispatch(setLastID(+(localStorage.getItem('last_id') || 0)));

        document.addEventListener('scroll', (evt) => {
          console.log(evt.target)
        })
    }, []);

    const [test, setTest] = useState('');


    try
    {
      // console.log();
      // const query = gql`
      // query {test}`;

      // GraphqlService.SendQuery(query).then(d => setTest(d));
    }
    catch(ex) {
      console.log('ex', ex.message);
    }


  return (
      <>
      <Switch>
        <Route path='/login' component={LoginPage} />
        <Route path='/inspection-report/:token' component={InspectionReportPage} />
        <Route path='/r/:token' component={RedirectPage} />
        <Route path='/' component={MainPage} />
      </Switch>

      </>
  );
}

export default App;


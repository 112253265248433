import './BankAccountForm.scss'
import {useEffect, useState} from "react";
import {BankAccount} from "../../../../classes/classes";
import FormGroup from "../../../../components/FormGroup/FormGroup";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../services/graphql.service";
import {useToasts} from "react-toast-notifications";
import useLoading from "../../../../hooks/useLoading/useLoading";

const create_bank_account = gql(`mutation($bank_account: bank_account_input) {
  create_bank_account(bank_account: $bank_account) {
    id
    success
    message
  }
}`);

const update_bank_account = gql(`mutation($bank_account_id: Int, $bank_account_info: bank_account_input) {
 update_bank_account(bank_account_id: $bank_account_id, bank_account_info: $bank_account_info) {
    id
    success
    message
  }
}`);

const BankAccountForm = ({merchantBank, defaultCheck, bankAccount, onCancel, onSubmit}) => {
    const [currentBankAccount, setCurrentBankAccount] = useState<BankAccount>(bankAccount);

    const [isDefault, setDefault] = useState(false);
    const [isMerchant, setMerchant] = useState(false);

    const toast = useToasts();
    const loading = useLoading();

    const [merchantBankAccount, setMerchantBankAccount] = useState(merchantBank);
    const [defaultCheckAccount, setDefaultCheckAccount] = useState(defaultCheck);

    useEffect(() => {
        if (bankAccount && bankAccount.id !== undefined)
            setCurrentBankAccount(bankAccount);

        setMerchantBankAccount(merchantBank);
        setDefaultCheckAccount(defaultCheck);

        setDefault(currentBankAccount?.id != undefined && currentBankAccount?.id == defaultCheckAccount);
        setMerchant(currentBankAccount?.id != undefined && currentBankAccount?.id == merchantBankAccount);
    }, [bankAccount]);

    const updateBankAccount = async () => {
        let bank_account = {
            name: currentBankAccount.name,
            bank: currentBankAccount.bank,
            note: currentBankAccount.note,
            last_4: currentBankAccount.bank.substr(currentBankAccount.bank.length - 4, 4)
        }

        try {
            loading.open('loading....');
            const data = await GraphqlService.SendMutation(update_bank_account, {
                bank_account_id: +currentBankAccount.id,
                bank_account_info: bank_account
            });
            loading.close();

            if (data.success) {
                setCurrentBankAccount({...currentBankAccount, id: data.id});
                toast.addToast(data.message, {appearance: 'success', autoDismiss: true});

                onSubmit({
                    bankAccount: {...currentBankAccount, id: data.id},
                    isDefault: isDefault,
                    isMerchant: isMerchant,
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    const createBankAccount = async () => {
        let bank_account = {
            name: currentBankAccount.name,
            bank: currentBankAccount.bank,
            note: currentBankAccount.note,
            last_4: currentBankAccount.bank.substr(currentBankAccount.bank.length - 4, 4)
        };

        try {
            loading.open('loading....');
            const data = await GraphqlService.SendMutation(create_bank_account, { bank_account: bank_account });
            loading.close();

            if (data.success) {
                setCurrentBankAccount({...currentBankAccount, id: data.id});
                toast.addToast(data.message, {appearance: 'success', autoDismiss: true});

                onSubmit({
                    bankAccount: {...currentBankAccount, id: data.id},
                    isDefault: isDefault,
                    isMerchant: isMerchant,
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    const handleOnSubmitForm = (wasSubmitted) => {
        if (!wasSubmitted) {
            onCancel();
            return;
        }

        if (currentBankAccount.name === '' ||
            currentBankAccount.type === '') {
            return;
        }

        if (currentBankAccount.id !== undefined)
            updateBankAccount().then();
        else
            createBankAccount().then();
    }

    return(
        <>
            <div className="h-100 position-relative">
                <div className="bank-account-form pb-5">
                    <div className="row">

                        <div className="col-12 mb-3">
                            { bankAccount && bankAccount.id === undefined && <span className="font-14 font-medium mb-0" >Add Account</span> }
                            { bankAccount && bankAccount.id !== undefined && <span className="font-14 font-medium mb-0" >Edit Account</span> }
                        </div>

                        <FormGroup name={'name'}
                                   value={currentBankAccount?.name}
                                   label={'Bank name'}
                                   onTextChange={value => setCurrentBankAccount({...currentBankAccount, name: value})}/>

                        <FormGroup name={'bank'}
                                   value={currentBankAccount?.bank}
                                   label={'Account #'}
                                   placeholder={'Account #'}
                                   onTextChange={value => setCurrentBankAccount({...currentBankAccount, bank: value})}/>

                        <FormGroup name={'note'}
                                   value={currentBankAccount?.note}
                                   label={'Note'}
                                   placeholder={'Note'}
                                   onTextChange={value => setCurrentBankAccount({...currentBankAccount, note: value})}/>

                        <div className="col-12">
                            <p className="font-12 mb-3">Type*</p>
                        </div>

                        <div className="col-6">
                            <button className={`btn w-100 rounded-pill py-2 ${currentBankAccount?.type == 'Bank Account' ? 'btn-selected' : 'btn-unselected'}`}
                                    onClick={() => setCurrentBankAccount({...currentBankAccount, type: 'Bank Account'})}>Bank Account</button>
                        </div>

                        <div className="col-6">
                            <button className={`btn w-100 rounded-pill py-2 ${currentBankAccount?.type == 'Credit card' ? 'btn-selected' : 'btn-unselected'}`}
                                    onClick={() => setCurrentBankAccount({...currentBankAccount, type: 'Credit card'})}>Credit card</button>
                        </div>

                        <div className="col-6 mt-3">
                            <button className={`btn w-100 rounded-pill py-2 ${currentBankAccount?.type == 'Debit card' ? 'btn-selected' : 'btn-unselected'}`}
                                    onClick={() => setCurrentBankAccount({...currentBankAccount, type: 'Debit card'})}>Debit card</button>
                        </div>

                        <div className="col-12 d-flex align-items-center justify-content-start mt-3">
                            <input id={'defaultId'}
                                   type="checkbox"
                                   checked={isDefault}
                                   onChange={(evt) => {
                                       if (bankAccount.id !== 0) {
                                           if (currentBankAccount?.id === defaultCheckAccount) return;
                                           else {
                                               // update bank account
                                               setDefault(evt.target.checked);
                                           }
                                       } else
                                           setDefault(evt.target.checked);
                                   }}/>
                            <label htmlFor={'defaultId'} className="font-12 m-0 no-select">Default Account</label>
                        </div>

                        <div className="col-12 d-flex align-items-center justify-content-start mt-3">
                            <input id={'merchantId'}
                                   type="checkbox"
                                   checked={isMerchant}
                                   onChange={(evt) => {
                                       if (bankAccount.id !== 0) {
                                           if (currentBankAccount?.id === merchantBankAccount) return;
                                           else {
                                               // update bank account
                                               setMerchant(evt.target.checked);
                                           }
                                       } else
                                           setMerchant(evt.target.checked);
                                   }}/>
                            <label htmlFor={'merchantId'} className="font-12 m-0 no-select">Merchant Account</label>
                        </div>
                    </div>
                </div>

                <div className="bottom">
                    <div className="row">
                        <div className="col-6 text-right">
                            <button className="btn w-100 py-2 bg-white rounded-pill font-12 text-light-blue"
                                    onClick={() => handleOnSubmitForm(false)}>CANCEL</button>
                        </div>

                        <div className="col-6 text-right">
                            <button className="btn w-100 py-2 btn-blue-light rounded-pill font-12"
                                    onClick={() => handleOnSubmitForm(true)}>
                                    { bankAccount && bankAccount.id === undefined && <span className="font-14 font-medium mb-0" >Add Account</span> }
                                    { bankAccount && bankAccount.id !== undefined && <span className="font-14 font-medium mb-0" >Edit Account</span> }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BankAccountForm;
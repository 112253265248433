import './EmployeeList.scss';
import {useEffect, useState} from "react";
import {BasicUser, Employee} from "../../../../classes/classes";
import EmployeeForm from "../EmployeeForm/EmployeeForm";
import {useDispatch, useSelector} from "react-redux";
import {IEmployeeState} from "../../../../redux/reducers/employee.reducer";
import {AllStates} from "../../../../redux/reducers";
import {fetchEmployees} from "../../../../redux/actions/employee.action";
import ReactModal from 'react-modal';


const EmployeeList = () => {
    const dispatch = useDispatch();
    const {employees, error} = useSelector<AllStates>(state => state.employees) as IEmployeeState

    const [editEmployee, setEditEmployee] = useState(undefined);
    const [editUserData, setEditUser] = useState(undefined);
    const [employeeList, setEmployeeList] = useState<Employee[]>([]);
    const [openModal,setOpenModal]=useState(false)



    useEffect(() => {
        dispatch(fetchEmployees());
    }, []);

    useEffect(() => {
        setEmployeeList([...employees].sort((x, y) =>  (!x.active  ?  'zzzz' + x.name : x.name).localeCompare(y.active ? y.name : 'zzzz' + y.name)

        ));
    }, [employees]);

   


    

    return(
        <>
            { error != '' && <p className="font-12 font-medium">Employees not loaded correctly</p> }

            <div className="row h-100">
                <div className="col-12 d-flex align-items-center justify-content-between mb-3">
                    <p className="font-14 font-medium m-0">Employee</p>
                    <p className="font-12 font-medium text-light-blue cursor-pointer"
                            onClick={() => {setEditUser(new BasicUser());setEditEmployee(new Employee());setOpenModal(!openModal)}}><i className="fa fa-plus"></i> NEW EMPLOYEE</p>
                </div>

                
                <div className="col-12 ml-3">
                    <p className="font-14 font-medium mb-3">{employeeList.length} employees</p>
                </div>

                <div className="col-12 employee-list ml-3">
                    <div className="row">
                        {     
                            employeeList.map((e,index) => {
                                return <div style={{opacity: e.active ? 1 : .5}} key={index} className={`col-6 card mb-3 cursor-pointer ${editEmployee && e.id == editEmployee.id ? 'bg-light-blue' : 'bg-gray'}`}
                                            onClick={() => {setEditEmployee({...e});setOpenModal(!openModal)}}>
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between mb-1">
                                            <p className="font-14 m-0 font-medium">{ e?.name }</p>
                                            <p className="font-12 m-0">{ e?.department }</p>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between mb-1">
                                            <p className="font-12 m-0">{ e?.phone }</p>
                                            <p className="font-12 m-0">{ e?.type }</p>
                                        </div>

                                        <p className="font-12 m-0">{ e?.email }</p>
                                    </div>
                                </div>;
                            })
                        }
                        </div>
                    </div>

            <ReactModal className={""} isOpen={openModal} onRequestClose={evt => {
                setOpenModal(false);
                setEditEmployee(null);
            }}>
               <EmployeeForm  employee={editEmployee} userdata={editUserData}
               onDeleted={() => {
                dispatch(fetchEmployees());
                setEditEmployee(undefined);
                setOpenModal(false);
                
               }}
                                      onCancel={() => {setOpenModal(false);setEditEmployee(undefined)}}
                                      onSubmit={(employee, userdata) => {
                                            console.log('employees');
                                        dispatch(fetchEmployees());
                                        setEditEmployee(undefined);
                                        setOpenModal(false);
                                      }} />
            </ReactModal>
            </div>
        </>
    );
}

export default EmployeeList;
import FormGroup from "../../../../components/FormGroup/FormGroup";
import React, {useEffect, useState} from "react";
import Helper from "../../../../helper/Helper";
import ICONS from "../../../../../assets/svg";
import {useToasts} from "react-toast-notifications";


export class VeloxProfile {
    id: number = null;
    name: string;
    email: string;
    username: string;
    password?: string;
    equipment: string;
    phones?: any
    contacts?: any

    constructor(props?: any) {
        if (props) {
            this.id = props.id ?? null;
            this.name = props.name ?? '';
            this.username = props.username ?? '';
            this.password = props.password ?? '';
            this.equipment = props.equipment ?? '';
            this.contacts = props.contacts ?? [];
            const contacts = this.contacts.filter(x => x.title.toLowerCase() == 'phone');
            this.phones = props.phones || contacts && contacts.map(x => x.value) || [];
            this.email = props.email || this.contacts.find(x => x.title.toLowerCase() == 'email')?.value || '';
        }
    }
}

const VeloxProfileForm = ({profile, onCancel, onSubmit}) => {
    const [currentProfile, setCurrentProfile] = useState<VeloxProfile>(new VeloxProfile());
    const [phones, setPhones] = useState<string[]>(['']);

    const toast = useToasts();

    useEffect(() => {
        setCurrentProfile(profile);

        setPhones(profile.phones || ['']);
    }, [profile]);

    const handleOnSubmitForm = (wasSubmitted) => {
        if (!wasSubmitted) {
            onCancel();
            return;
        }

        if (!currentProfile.id) {
            if (currentProfile.username === '' || 
                currentProfile.password === '' || currentProfile) {
                return toast.addToast( 'Please complete empty fields', {appearance: 'error' , autoDismiss: true});
            }
        }

        if (currentProfile.name === '' ||
            currentProfile.email === '' || currentProfile.equipment === '' || currentProfile) {
            return toast.addToast( 'Please complete empty fields', {appearance: 'error' , autoDismiss: true});
        }

        onSubmit({...currentProfile, ...{phones: phones}});
    }

    return (
        <>
            <div className="" style={{display: 'flex', flexDirection: 'column', flex: 1, overflowY: "hidden"}}>
                <div className="col-12 mb-3 d-flex row">
                    <p className="font-14 font-medium">{currentProfile.id && 'Update' || 'Create new'} Velox profile</p>
                </div>

                <div className={`row d-flex`} style={{flex: 1, overflowY: "auto"}}>
                    <FormGroup name={'name'}
                               value={currentProfile.name}
                               required={true}
                               label={'Account holder name'}
                               placeholder={'Account holder name'}
                               onTextChange={value => setCurrentProfile({...currentProfile, name: value})}/>

                    <FormGroup name={'email'}
                               value={currentProfile.email}
                               required={true}
                               label={'Email'}
                               placeholder={'abc@xyz.com'}
                               onTextChange={value => setCurrentProfile({...currentProfile, email: value})}/>

                    {!currentProfile.id && <>

                        <FormGroup name={'username'}
                                   value={currentProfile.username}
                                   required={true}
                                   label={'Username'}
                                   placeholder={'Username'}
                                   onTextChange={value => setCurrentProfile({...currentProfile, username: value})}/>

                        <FormGroup name={'password'}
                                   value={currentProfile.password}
                                   required={true}
                                   label={'Password'}
                                   type={'password'}
                                   placeholder={'********'}
                                   onTextChange={value => setCurrentProfile({...currentProfile, password: value})}/>

                        <div className="col-12">
                            <p className="font-12 font-medium text-gray"><i
                                className="fa fa-check-circle mr-2 font-11"></i> A least 8 characters long</p>
                        </div>
                    </>
                    }

                    <hr/>

                    <div className="col-12 justify-content-between d-flex">
                        <p className='font-14'>Phones</p>
                        <button className="btn btn-blue-light" onClick={(evt) => setPhones([...phones, ''])}>ADD
                        </button>
                    </div>


                    <div className="col-12">
                        <div style={{flex: 1, overflowY: 'auto', flexWrap: 'nowrap'}} className='row'>
                            <div className='col-12 mt-4' style={{flex: 1, overflowY: 'auto'}}>
                                {phones.map((x, i) =>
                                    <div style={{maxWidth: '100%', overflow: 'hidden'}} key={'phone-' + i}
                                         className='d-flex align-items-center'>
                                        <FormGroup required hintText={'Phone number must be xxx-xxx-xxxx'}
                                                   errorText={'Phone number must be xxx-xxx-xxxx'}
                                                   validation={x => x.length == 12} label={`Phone ${i + 1}`}
                                                   colSize={12}
                                                   name={`phone-` + i} value={phones[i]} onTextChange={(evt) => {
                                            const temp = [...phones];
                                            temp[i] = Helper.Masks.USPhone(evt);
                                            setPhones(temp);
                                        }}/>
                                        {/* <h4><i className='fa fa-times'></i></h4> */}
                                        <button tabIndex={-1} onClick={(evt) => {
                                            const temp = [...phones];
                                            temp.splice(i, 1)
                                            setPhones(temp)
                                        }} className='btn btn-clear'><img src={ICONS.IconDelete} alt=""/></button>
                                    </div>)}
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mt-3">
                        <p className="font-12 mb-3">Equipment*</p>
                    </div>

                    <div className="col-6">
                        <button
                            className={`btn w-100 rounded-pill py-2 ${currentProfile?.equipment == 'open' ? 'btn-selected' : 'btn-unselected'}`}
                            onClick={() => setCurrentProfile({...currentProfile, equipment: 'open'})}>Open
                            carrier
                        </button>
                    </div>

                    <div className="col-6">
                        <button
                            className={`btn w-100 rounded-pill py-2 ${currentProfile?.equipment == 'flatbed' ? 'btn-selected' : 'btn-unselected'}`}
                            onClick={() => setCurrentProfile({...currentProfile, equipment: 'flatbed'})}>Flatbed
                        </button>
                    </div>

                    <div className="col-6 mt-3">
                        <button
                            className={`btn w-100 rounded-pill py-2 ${currentProfile?.equipment == 'enclosed' ? 'btn-selected' : 'btn-unselected'}`}
                            onClick={() => setCurrentProfile({...currentProfile, equipment: 'enclosed'})}>Enclosed
                        </button>
                    </div>
                </div>

                <div className="row mt-3 d-flex">
                    <div className="col-6 text-right">
                        <button className="btn w-100 py-2 bg-white rounded-pill font-12 text-light-blue"
                                onClick={() => handleOnSubmitForm(false)}>CANCEL
                        </button>
                    </div>

                    <div className="col-6 text-right">
                        <button className="btn w-100 py-2 btn-blue-light rounded-pill font-12"
                                onClick={() => handleOnSubmitForm(true)}>
                            {profile && profile.id === null && <span>CREATE PROFILE</span>}
                            {profile && profile.id !== null && <span>EDIT PROFILE</span>}
                        </button>
                    </div>
                </div>

            </div>

        </>
    );
}

export default VeloxProfileForm;

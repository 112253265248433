import React, {useEffect, useState} from "react";
import './DriverSchedule.scss'
import {DriverSchedule, Order} from "../../classes/classes";
import Status from "../Status/Status";
import MultilineEllipsis from '../../components/MultilineEllipsis/MultilineEllipsis'
import Helper from "../../helper/Helper";
import { ControlledMenu, MenuItem, useMenuState } from "@szhsin/react-menu";
import ICONS from "../../../assets/svg";
import ReactModal from "react-modal";
import RequestInspectionForm from "../LegTable/RequestInspection/RequestInspection";
import { gql } from "apollo-boost";
import environments from "../../../environments/environments";
import { GraphqlService } from "../../services/graphql.service";
import useLoading from "../../hooks/useLoading/useLoading";
import { useToasts } from "react-toast-notifications";
import DriverQueries from "../../graphql/query/Driver";
import Loading from "../Loading/Loading";


const DriverScheduleComponent = ({driver, refresh = false, onOrderClicked, veloxOrderID}) => {

    const { toggleMenu, closeMenu, openMenu, ...menuProps } = useMenuState();
    const [anchorPoint, setAnchorPoint] = useState({x: 0, y: 0});
    const [velox, setVelox] = useState(null);
    const [myDriver, setMyDriver] = useState(null);
    const [modalOpen, setModalOpen] = useState(null);
    const loading = useLoading();
    const toast = useToasts();

    async function loadDriverSchedule() {
        setMyDriver(true);
        try {
            const data = await GraphqlService.SendQuery(DriverQueries.GET_DRIVER_SCHEDULE, {driver_id: driver.id});
            if (!data) throw new Error('Driver not found');
            let cont = 0;
            // console.log(data);
            data.itinerary = data?.itinerary?.map((it, index) => {
                // if ()
                let pickup_count = 0;
                let dropoff_count = 0;


                const array = (it.orders[0]?.address.split(',') || []);
                // console.log(array);
                it.state = array[array.length - 1]?.toUpperCase().trim().substring(0, 2) || 'N/A';
                // console.log(it.state);

                it.orders.forEach(o => {
                    pickup_count += o.is_pickup ? 1 : 0;
                    dropoff_count += o.is_pickup ? 0 : 1;
                });

                cont += pickup_count - dropoff_count;
                const _it = {...it};
                _it.pickup_count = pickup_count;
                _it.dropoff_count = dropoff_count;
                _it.line_style = index == data.itinerary.length - 1 ?  'none' :
                    cont == 0 ? 'dashed' : 'solid';

                return _it;
            }) || []
            setMyDriver(data);
        } catch (ex) {
            console.log('ex', ex.message);
            setMyDriver(null)
        }   
    }

    useEffect(() => {

        if (driver)
        {
            loadDriverSchedule();
        }
        else
            setMyDriver(null);
    }, [driver]);

    useEffect(() => {
        if (driver)
        {
            loadDriverSchedule();
        }
        else
            setMyDriver(null);
    }, [refresh]);

    async function getLink() {

        const carrier_id = myDriver?.carrier_id;
        const link = velox?.link;
        const mutation = gql`
            mutation($carrier_id: Int, $link: String){
                create_velox_redirect_link(carrier_id: $carrier_id, link: $link)
            }
        `;
        try {
            loading.open('Getting Link...');
            const r = await GraphqlService.SendMutation(mutation, {carrier_id, link})
            loading.close();

        Object.assign(document.createElement('a'), {
            target: '_blank',
            href: r,
        }).click();
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
                    

    }

    if (!myDriver)
        return <div className='pb-3'>
            <h4 className={'font-12 mb-1'}>No driver selected</h4>
            <h4 className={'font-9 font-normal'}>Please select a driver to see details</h4>
        </div>

    return (

        <>
         {myDriver == true && <div className="col-12">
            <Loading />
            <h4 className="font-11 text-center">Loading Driver...</h4>
         </div>}
            {myDriver != true && <section id="driver-schedule" style={{transition: '.3s all ease', opacity: myDriver == true ? '0' : '1'}} className={'flex-1-container'}>

                <div className="row pb-3">
                    <div>
                        <div className={'font-12'}><span>{myDriver?.name}</span></div>
                        <div><span className={'font-9'}>{myDriver?.carrier}</span></div>
                    </div>
                    <div>
                        <div><Status statusName={myDriver?.status?.text || 'LOADING...'}/></div>
                        <div className={'mb-1'}><span className={'font-12'}>{Helper.Masks.ValidUSMoney(myDriver?.price || '0')}</span></div>
                    </div>
                </div>
                <article className={`schedule-timeline flex-1-container`} style={{marginLeft: '0'}}>
                    <ul className='px-1 itinerary-list'>
                    <ControlledMenu {...menuProps} anchorPoint={anchorPoint}
                onClose={() => closeMenu()}>
                <MenuItem onClick={(evt) => setModalOpen(true)} className='my-menuitem' style={{display: 'flex', alignItems: 'center'}}><img src={ICONS.IconEmail} alt="" /> Send <i
                                                            className={`arrow-${velox?.is_pickup ? 'up' : 'down'} mx-1`}></i> Inspection for VLX-{velox?.velox_order_id}</MenuItem>
                {velox?.pending_change_request && <MenuItem onClick={(evt) => {
                    
                    getLink()
                }} className='my-menuitem' style={{display: 'flex', alignItems: 'center'}}><img src={ICONS.IconDetails} alt="" /> Accept Change</MenuItem>}
                
            </ControlledMenu>
                        {
                            myDriver?.itinerary?.map((job, i) => {
                                return (
                                    <li style={{marginBottom: '15px'}} key={`j-${job?.id || i}`}>
                                        <div className="job-cnt pt-2">

                                        <div className="d-flex">
                                            <MultilineEllipsis className={"location-name p-0 fs-12"} lineLimit={2}>
                                                        <pre>
                                                            {job.orders[0]?.address}
                                                        </pre>
                                                </MultilineEllipsis>

                                                <div className="mt-0">
                                                <h4 className="font-10">{Helper.FORMAT.USDate(job.orders[0]?.scheduled, 'do', job.orders[0]?.timezoneName)}</h4>
                                                    <h4 className="font-10">{Helper.FORMAT.USDate(job.orders[0]?.scheduled, 'to', job.orders[0]?.timezoneName)}</h4>
                                                </div>
                                        </div>

                                            {/*<span className={`filled`}></span>*/}
                                            {
                                                job?.orders.map((order, i, array) => {
                                                    return (
                                                        <div
                                                                
                                                             key={`o-${order?.order_id}`}
                                                             style={{opacity: order?.completed ? '.7' : '1'}}
                                                             className={`${array.length < i - 1 ? 'mb-3' : ''} card-order cursor-pointer` + (veloxOrderID == order?.order_id ? ' bg-gold' : '')}
                                                             onContextMenu={(e) => {
                                                                e.preventDefault();
                                                                setVelox({velox_order_id: order?.order_id, is_pickup: order?.is_pickup, pending_change_request: order?.pending_change_request, link: order?.change_requests?.sort((x, y) => new Date(x) < new Date(y) ? 1 : -1)[0]?.weblink});
                                                                
                                                                setAnchorPoint({ x: e.clientX, y: e.clientY });
                                                                openMenu();
                                                             }}
                                                             onClick={(evt) => {
                                                                // onOrderClicked()
                                                                const velox_id = order.order_id == veloxOrderID ? null : order.order_id;
                                                                onOrderClicked(velox_id);
                                                                if (!velox_id)
                                                                    setTimeout(() => {
                                                                        onOrderClicked(order.order_id);
                                                                    });
                                                             }}>
                                                            <div
                                                                className={`d-flex no-wrap align-items-start justify-content-between`}>
                                                                {/* <span>{order?.hour}</span> */}
                                                                
                                                                <span>VLX-{order?.order_id} {order.completed && <i className="fas fa-check-circle text-green"></i>}</span>
                                                                <div className="d-flex align-items-start">
                                                                    {order?.pending_change_request && <Status className="mt-0" statusName={"CHANGE REQUEST"} />}
                                                                    <i
                                                                className={`ml-1 arrow-${order?.is_pickup ? 'up' : 'down'}`}></i>
                                                                </div>
                                                                

                                                                
                                                            </div>
                                                            <div className="title"><span>{order?.vehicle?.vin}</span> 
                                                            </div>
                                                            <div
                                                                className="info">{order?.vehicle?.year} {order?.vehicle?.make} {order?.vehicle?.model}</div>
                                                            {/*<div className="type">Presentation</div>*/}
                                                        </div>
                                                    )
                                                })
                                            }
                                            <span className={`number ${job?.line_style}`}>
                                            {/*<span className='fs-12'>{job?.orders?.length && <><p>ETA</p><p*/}
                                            {/*    className='fs-12'>{job?.orders[0]?.hour}</p>*/}
                                            {/*    <p>(PST)</p></> || ''}</span>*/}
                                            </span>
                                        </div>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </article>

                
            </section>}

            <ReactModal isOpen={modalOpen}>
                <RequestInspectionForm data={velox} onClose={(evt) => setModalOpen(false)} />
            </ReactModal>

         
        </>
    );
};

export default DriverScheduleComponent;


import {gql} from 'apollo-boost';

const GET_DATA = gql`
    query{
        get_user_from_token{
            id
            name
            level
            active
            employee{
            id
            name
            department
            }
        }
    }
`;

const UserQueries = {
    GET_DATA
}

export default UserQueries;
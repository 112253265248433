import React, {useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import {AllStates} from '../../redux/reducers';
import {ICustomerState} from '../../redux/reducers/Customer.reducer';
import {clearGetAllCustomersListError} from '../../redux/actions/Customer.action';
import {useToasts} from 'react-toast-notifications';
import {CustomerList, CustomerProfile, Location, Stop} from '../../classes/classes';
import CustomerTable from '../CustomerTable/CustomerTable';
import PickupCustomer from "../NewOrder/PickupCustomer/PickupCustomer";

const CustomerSelection = ({
                               onCustomerSelected,
                               onCancel ,
                               topRightDiv = null,
                               newMaster = false,
                               tableGridStyle = null,
                               
                           }: { onCancel:()=>void, newMaster?: boolean, tableGridStyle?: any, onCustomerSelected: (customer: CustomerList, stops: Stop, profiles: CustomerProfile[]) => void, topRightDiv }) => {

    const dispatch = useDispatch();
    const [customerID, setCustomerID] = useState(-1);
    const [refresh, setRefrehs] = useState(false);
    const {loading, customers, error} = useSelector<AllStates>(states => states.customers) as ICustomerState;
    const toast = useToasts();

    if (error) {
        toast.addToast(error, {appearance: 'error'});
        dispatch(clearGetAllCustomersListError())
    }

    const [myStop, setMyStop] = useState(new Stop());

    const handleOnCustomerClicked = evt => {
        setCustomerID(evt.id);

        let l = {...myStop.location};
        if (l.customer.id != evt.id) {
            l = new Location();
            l.customer = evt;
        }

        setMyStop({
            ...myStop,
            contacts: myStop.location.customer.id == evt.id ? myStop.contacts : [],
            location: l
        });
    }

    const handleSelectedPickup = stop => {
        // send values throw the component
        onCustomerSelected(stop.location.customer, stop, stop.contacts);
        setCustomerID(-1);
    }

    return (
        <>
            <PickupCustomer title={'Location and Contact'}
                            showCustomerList={true}
                            stop={myStop}
                            onCancelClicked={() => {setCustomerID(-1); setRefrehs(true);
                                onCancel()
                                setTimeout(() => {
                                    setRefrehs(false)
                                }, 1);
                            }}
                            onConfirmClicked={(stop) => {
                                handleSelectedPickup(stop);
                            }}/>
        </>)
}

export default CustomerSelection;

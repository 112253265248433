import FormGroup from "../FormGroup/FormGroup";
import {useEffect, useRef, useState} from "react";
import Toggle from "../Toggle/Toggle";
import Helper from "../../helper/Helper";
import "./NewCarrierForm.scss";
import { GraphqlService } from "../../services/graphql.service";
import CustomerMutations from "../../graphql/mutation/Customer";
import AddressSearch from '../Google/AddressSearch';
import DropdownCountry from "../DropdownCountry/DropdownCountry";
import {Address, CustomerList, Location} from "../../classes/classes";
import {useToasts} from "react-toast-notifications";
import useLoading from "../../hooks/useLoading/useLoading";
import {useDispatch} from "react-redux";
import CarrierMutation from "../../graphql/mutation/Carrier";

class Contact {
    type: string = '';
    title: string = 'Mobile';
    value: string = '';
    unsubscribed: boolean = false;

    constructor (x?: Contact) {
        if (x) {
            this.type = x.type;
            this.title = x.title;
            this.value = x.value;
            this.unsubscribed = x.unsubscribed;
        }
    }
}

class NewCarrier {
    id: number = null;
    code: string = '';
    name: string = '';
    terms: string = '';
    internal_note: string = '';
    multi_carrier: boolean = false;
    profile: Profile = new Profile();
    location: Location = new Location();
    default_notification_email: string = '';

    constructor(props?: NewCarrier) {
        if (props) {
            this.code = props.code;
            this.name = props.name;
            this.terms = props.terms;
            this.internal_note = props.internal_note;
            this.multi_carrier = props.multi_carrier;
            this.profile = new Profile(props.profile);
            this.location = new Location(props.location);
            this.default_notification_email = props.default_notification_email || '';
            this.id = props.id;
        }
    }
}

class Profile {
    name: string = '';
    email: string = '';
    title: string = '';
    contact: Contact[] = [];

    constructor (x?: Profile) {
        if (x) {
            this.name = x.name;
            this.email = x.email;
            this.title = x.title;
            this.contact = !x.contact ? [] : x.contact.map(z => new Contact(z));
        }
    }
}

const states = Helper.States;

const NewCarrierForm = ({currentCustomer = undefined, onCancel, onSubmit}) => {
    const toast = useToasts();
    const loading = useLoading();
    const dispatch = useDispatch();

    const [address, setAddress] = useState(new Address());
    const [codeError, setCodeError] = useState('');
    const [location, setLocation] = useState(new Location());
    const [carrier, setCarrier] = useState(new NewCarrier());
    const [createOnlineProfile, setCreateOnlineProfile] = useState(true);

    const isValid = () => {
        return carrier.name.trim() != '' && carrier.code.trim() != ''
        && carrier.default_notification_email?.trim() != ''
        && Helper.Validators.IsValidEmail(carrier.default_notification_email)
        && (carrier.id ? true: carrier?.profile?.name?.trim() != ''
        && (!createOnlineProfile || Helper.Validators.IsValidEmail(carrier?.profile?.email)) && carrier?.profile?.title.trim() != ''
        && !carrier?.profile?.contact.some(x => x.title.trim() == '' || x.type == '' ? true : (x.type == 'email' ? !Helper.Validators.IsValidEmail(x.value) : !Helper.Validators.IsValidUSPhone(x.value)) ));
    }

    useEffect(() => {
        if (currentCustomer != undefined) {
            const x = new NewCarrier(currentCustomer);

            setCarrier(x);
            setLocation(currentCustomer?.location);
            setAddress(new Address(currentCustomer.billing_address));
        }
    }, [currentCustomer]);

    const handleRemoveContact = index => {
        const temp = [...carrier?.profile?.contact];
        temp.splice(index, 1);

        setCarrier({...carrier, profile: {...carrier?.profile, contact: temp} });
    }

    const handleUpdateNotificationContact = async (index) => {
        const temp = [...carrier?.profile?.contact];
        const contact = temp[index];

        try {
            loading.open('loading...');
            let data = await GraphqlService.SendMutation(contact.unsubscribed ? CustomerMutations.SUBSCRIBE_CONTACT : CustomerMutations.UNSUBSCRIBE_CONTACT, {value: contact.value});
            loading.close();

            if (data.success) {
                toast.addToast('Notifications updated', {appearance: 'success', autoDismiss: true});

                contact.unsubscribed = !contact.unsubscribed;
                temp[index] = contact;
                setCarrier({...carrier, profile: {...carrier?.profile, contact: temp} });
            } else {
                toast.addToast('Error to update notifications', {appearance: 'error', autoDismiss: true});
            }
        } catch (ex) {
            loading.close();
            toast.addToast(ex, {appearance: 'error', autoDismiss: true});
        }
    }

    const getInputsByType = (type: string) => {
        let j = -1;
        let title = type == 'email' ? 'Email' : 'Phone';
        return carrier?.profile?.contact.map((x, index) =>  {
            if (x.type != type)
                return <></>;
            j++;
            return <div className="p-2 mb-3 rounded" style={{border: '1px solid #ccc'}}>
                <div className="d-flex align-items-start">
                    <FormGroup name={'title'}
                               value={x.title}
                               placeholder={'-- Select --'}
                               label={'Title'}
                               options={{data: type != 'email' ? ['Mobile', 'Office', 'Home', 'Other'] : ['Work', 'Personal', 'Other'], label: x=> x, value: x => x}}
                               type={'select'}
                               required
                               isValid={(valid) => {
                                   validation.current = {...validation.current, [`contact_title_${index + 1}`]: valid};
                               }}
                               onTextChange={(event) => handleUpdateContact(index, 'title', event)}>
                        <button onClick={(evt) => handleRemoveContact(index)} style={{marginBottom: '3.5rem', marginRight: '-1rem'}} className="btn btn-danger-light">REMOVE</button>
                    </FormGroup>


                </div>
                <FormGroup validation={(x) => type == 'email' ? Helper.Validators.IsValidEmail(x) : Helper.Validators.IsValidUSPhone(x) } errorText={`${title} format must be ${type == 'email' ? 'abc@xyz.com' : '000-000-0000'}`} placeholder={title + ' ' + (j + 1)} label={`${title} ${j + 1}`} required name={`${type}_${j + 1}`} value={x.value} onTextChange={(evt) => {
                    handleUpdateContact(index, 'value', evt);
                }}>

                    <div className="d-flex justify-content-end flex-wrap" style={{marginTop: '-.5rem', marginRight: '-.5rem'}}>
                        <div className="col-12 text-right">
                            <span className="font-8 font-normal text-gray text-right">Notifications</span>
                        </div>
                        <div className="">
                            <Toggle id={'toggle'} checked={!x.unsubscribed} label={''} onCheck={(evt) => {
                                handleUpdateNotificationContact(index);
                            }} />
                        </div>
                    </div>
                </FormGroup>
            </div>
        })
    }

    const handleUpdateContact = (index, key, value) => {
        // console.log({index, key, value})
        const temp = [...carrier?.profile.contact];

        const contact = temp[index];
        if (key == 'value' && contact['type'] == 'phone') {
            value = Helper.Masks.USPhone(value);
        }

        if (key == 'type') {
            contact.value = '';
        }

        contact[key] = value;
        temp[index] = contact;

        setCarrier({...carrier, profile: {...carrier?.profile, contact: temp} });
    }

    const handleSubmitForm = (event) => {
        event.stopPropagation();
        event.preventDefault();

        // onSubmit(true);
        handleSaveCarrier();
    }

    const handleCheckCarrierCode = async (code) => {
        try {
            let data = await GraphqlService.SendMutation(CarrierMutation.CHECK_CARRIER_CODE, { code });
            if (data) {
                setCarrier({...carrier, code: code});
            }
        } catch (ex) {

        }
    }

    const validation = useRef<any>({});

    const getContactsFormSection = () => {
        return (
            <>
                <div className="row">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <p className="fs-16 mb-3 mt-3 font-medium">Contacts For This Profile (Optional)</p>
                    </div>
                </div>

                <div className="row mb-3 align-items-start">
                    <div className="col-6 row">
                        <div className="row mb-2 col-12 align-items-start">
                            <h4 className="font-12">Emails</h4>
                            <button className="btn btn-blue-light" onClick={() => setCarrier({...carrier, profile: {...carrier?.profile, contact: [...carrier?.profile.contact, new Contact({title: 'Work', type: 'email', unsubscribed: false, value: ''})]} })}>ADD</button>
                        </div>
                        <div className="col-12">
                            { getInputsByType('email') }
                        </div>
                    </div>

                    <div className="col-6 row">
                        <div className="row mb-2 col-12 align-items-start">
                            <h4 className="font-12">Phones</h4>
                            <button className="btn btn-blue-light" onClick={(event) => setCarrier({...carrier, profile: {...carrier?.profile, contact: [...carrier?.profile.contact, new Contact({title: 'Mobile', type: 'phone', unsubscribed: false, value: ''})]} })}>ADD</button>
                        </div>
                        <div className="col-12">
                            { getInputsByType('phone') }
                        </div>
                    </div>

                </div>

            </>
        );
    };

    const getAddressFormSection = () => {
        return (
            <div className="">
                <div className="row">
                    <AddressSearch className="col-12" onGotAddress={(newAddress) => {
                        setAddress(newAddress);
                    }}/>

                    <div className="col-12 position-relative">
                        <DropdownCountry
                            country={address?.country}
                            onSelect={value=>{setAddress({...address, country: value})}}
                            allCountry={true}
                        />
                    </div>

                    {/*<FormGroup label={'Country'}
                                name={'country'}
                                colSize={9}
                                value={address.country}
                                type={'select'}
                                placeholder={'-- Select a country --'}
                                options={{data: countries, value: x => x, label: x => x}}
                                onTextChange={value => setAddress({...address, country: value})}/>*/}

                    <div className="row col-12">
                        <FormGroup label={'Street #'}
                                   colSize={3}
                                   value={address?.street_number}
                                   name={'streetNumber'}
                                   placeholder={'Street #'}

                                   required
                                   onTextChange={value => setAddress({...address, street_number: value})}/>

                        <FormGroup name={'street'}
                                   colSize={9}
                                   label={'Street address'}
                                   value={address?.street}
                                   required
                                   placeholder={'Street'}
                                   onTextChange={(event) => setAddress({...address, street: event})}/>
                    </div>
                </div>

                <div className="row">
                    <div className="row col-12">
                        <FormGroup name={'city'}
                                   colSize={12}
                                   label={'City'}
                                   required
                                   value={address?.city}
                                   placeholder={'City'}
                                   onTextChange={(event) => setAddress({...address, city: event})}/>

                        <FormGroup name={'state'}
                                   label={'State'}
                                   colSize={6}
                                   required
                                   value={address?.state}
                                   type='select'
                                   placeholder={'--'}
                                   options={{data: states, value: x => x, label: x => x}}
                                   onTextChange={(event) => setAddress({...address, state: event})}/>

                        <FormGroup name={'postalCode'}
                                   label={'Postal Code'}
                                   colSize={6}
                                   value={address?.postal_code}
                                   required
                                   placeholder={'00000'}
                                   onTextChange={(event) => setAddress({...address, postal_code: event})}/>

                    </div>




                </div>
            </div>
        );
    };

    const getNextRowAddressFormSection = () => {
        return (
            <div className="row col-12">
                <FormGroup name={'default_billing_email'}
                           label={'Default notification email'}
                           colSize={12}
                           required={true}
                           errorText="Email is not valid"
                           validation={x => {
                               return Helper.Validators.IsValidEmail(x);
                           }}
                           value={carrier.default_notification_email}
                           placeholder={'abc@xyz.com'}
                           onTextChange={text => setCarrier(new NewCarrier({...carrier, default_notification_email: text}))} />

                <FormGroup name={'internal_note'}
                           type={'textarea'}
                           label={'Internal Notes'}
                           value={location?.internal_note}
                           placeholder={'Internal Notes'}
                           onTextChange={(event) => setLocation({...location, internal_note: event})} />
            </div>
        )
    }

    const getProfileFormSection = () => {
        return(
            <>
                <div className="row col-12">
                    <div className="col-6">
                        <div className="row my-3">
                            <p className="fs-16 font-medium">Carrier Profile</p>
                            <Toggle label={'Create Online Profile'} checked={createOnlineProfile} onCheck={(evt) => {
                                setCarrier({...carrier, profile: { ...carrier?.profile, email: '' }})
                                setCreateOnlineProfile(evt);}} id={'create_online_profile_toggle'} />
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex">
                                <FormGroup name={'profileName'}
                                           value={carrier?.profile?.name}
                                           placeholder={createOnlineProfile ? 'Admin Name...': 'Contact Name...'}
                                           label={createOnlineProfile ? 'Company Admin Full Name': 'Contact'}
                                           required={true}
                                           errorText={'Invalid email format'}
                                           className="mt-3"
                                           onTextChange={(event) => setCarrier({...carrier, profile: { ...carrier?.profile, name: event }})}/>

                            </div>

                            <FormGroup name={'Title'}
                                       value={carrier?.profile?.title}
                                       placeholder={'Position...'}
                                       label={'Position'}
                                       required
                                       type={'text'}
                                       onTextChange={(event) => setCarrier({...carrier, profile: { ...carrier?.profile, title: event }})}/>

                            {createOnlineProfile && <FormGroup name={'email'}
                                                               value={carrier?.profile?.email}
                                                               placeholder={'Email'}
                                                               label={'Email (Also Login Username)'}
                                                               required={true}
                                                               type={'email'}
                                                               validation={Helper.Validators.IsValidEmail}
                                                               onTextChange={(event) => setCarrier({...carrier, profile: { ...carrier?.profile, email: event }})}/>}


                        </div>
                    </div>

                    <div className="col-6">
                        {
                            getContactsFormSection()
                        }
                    </div>
                </div>
            </>
        );
    };

    async function handleCheckCode(code) {
        setCodeError('')
        loading.open('checking code...');
        const checkCarrierCode = await GraphqlService.SendMutation(CarrierMutation.CHECK_CARRIER_CODE, { code });
        loading.close();

        if (!checkCarrierCode.success) {
            setCarrier({...carrier, code: ''});
            return setCodeError(checkCarrierCode.message);
        }
    }

    async function handleSaveCarrier() {
        setCodeError('')
        loading.open('saving carrier...');

        try {
            let newCarrier = {
                "code": carrier.code,
                "name": carrier.name,
                "terms": carrier.terms,
                "is_multi_carrier": carrier.multi_carrier,
                "internal_note": carrier.internal_note,
                "address": {
                    "city": address.city,
                    "state": address.state,
                    "street": address.street,
                    "country": address.country,
                    "street_number": address.street_number,
                    "postal_code": address.postal_code,
                    "postal_code_suffix": address.postal_code_suffix,
                },
                "default_notification_email": carrier.default_notification_email,
                "tax_number": {
                    "type": "ssn",
                    "value": "0000000"
                },
                "carrier_profile": {
                    "email": carrier.profile.email,
                    "name": carrier.profile.name,
                    "title": carrier.profile.title,
                    "contact": carrier.profile.contact.map(e => {
                        return {
                            "type": e.type,
                            "title": e.title,
                            "value": e.value
                        };
                    })
                }
            };

            let data = await GraphqlService.SendMutation(CarrierMutation.CREATE_CARRIER, { carrier: newCarrier });
            console.log(data);
            loading.close();

            toast.addToast(`Carrier created with ID #` + data.id, {appearance: 'success', autoDismiss: true});
            setCarrier({...carrier, id: data.id});
            onSubmit({...carrier, id: data.id});
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error'});
            loading.close();
        }
    }

    return (
        <div className="px-3  flex-1-container">
            <div className="row ">
                { currentCustomer == undefined && <p className="col-6 fs-14 font-medium mb-3">Create New Carrier</p> }
                { currentCustomer != undefined && <p className="col-6 fs-14 font-medium mb-3">Edit Carrier</p> }
            </div>

            <div className={`flex-1-container`} >
                <div className="row">
                    <div className="col-12">
                        <p className="mb-3 fs-16 font-medium">Carrier details</p>
                    </div>

                    <div className="col-12 mb-3">
                        <Toggle id={'multi_carrier'}
                                label={'Multi carrier'}
                                checked={carrier.multi_carrier}
                                onCheck={(evt) => setCarrier({...carrier, multi_carrier: evt})}></Toggle>
                    </div>

                    <div className="row col-12">
                        <div className="col-6">
                            <div className="row col-12">
                                <FormGroup name={'carrierName'}
                                           value={carrier?.name}
                                           placeholder={'Carrier Name'}
                                           label={'Carrier Name'}
                                           required={true}
                                           onTextChange={(event) => setCarrier({...carrier, name: event})}/>
                            </div>

                            <div className="row col-12">
                                <FormGroup name={'code'}
                                           value={carrier?.code}
                                           placeholder={'Code...'}
                                           hintText={'5 letter from [A-Z]'}
                                           label={'5 Letter Code'}
                                           required={true}
                                           colSize={6}
                                           onTextChange={(event) => {
                                               //handleCheckCarrierCode(event).then();
                                               const code = Helper.Masks.Code(event);
                                               setCarrier({...carrier, code: code});

                                               if (code.length >= 5) {
                                                   handleCheckCode(code).then();
                                               }
                                           }}/>

                                <FormGroup name={'terms'}
                                           value={carrier?.terms}
                                           placeholder={'Choose an option'}
                                           label={'Payment terms'}
                                           colSize={6}
                                           type={'select'}
                                           options={{
                                               label: e => e,
                                               value: e => e,
                                               data: ['The same day', '2 Days', '5 Days', '15 Days', '30 Days']
                                           }}
                                           onTextChange={(event) => {
                                               setCarrier({...carrier, terms: event});
                                           }}/>
                            </div>

                            {
                                codeError ?
                                    <div className="row col-12 mb-1">
                                        <div className="alert alert-danger">
                                            <span className={`fs-14`}>{codeError}</span>
                                        </div>
                                    </div>
                                    : // OR
                                    ''
                            }

                            { <div className="col-12">
                                <p className="mb-3 fs-14 font-medium">Billing address</p>

                                <div className="row col-12">
                                    <div className="col-12">
                                        { getAddressFormSection() }
                                    </div>
                                </div>
                            </div>
                            }
                        </div>

                        <div className="col-6">
                            {getNextRowAddressFormSection()}
                        </div>
                    </div>

                    <div className="col-12">
                        <hr className={"mt-4 mb-3"}/>
                    </div>

                    { !carrier.id && getProfileFormSection() }
                </div>
            </div>

            <div className="row col-12 pt-3 row sticky-bottom bg-white">
                <div className="col-6">
                    <button type="button"
                            className="w-100 btn btn-clear text-blue-light rounded-pill btn-block"
                            onClick={(event) => onCancel(event)}>CANCEL
                    </button>
                </div>

                <div className="col-6">
                    {
                        currentCustomer == undefined &&
                        <button type="button"
                                className="w-100  btn btn-blue-light rounded-pill btn-block mr-2"
                                disabled={!isValid()}
                                onClick={(event) => handleSubmitForm(event)}>CREATE CARRIER</button>
                    }

                    {
                        currentCustomer != undefined &&
                        <button type="button"
                                className="w-100   btn btn-blue-light rounded-pill btn-block mr-2"
                                disabled={!isValid()}
                                onClick={(event) => handleSubmitForm(event)}>EDIT CARRIER</button>
                    }
                </div>
            </div>
        </div>
    );
}

export default NewCarrierForm;

import {gql} from "apollo-boost";

const GET_ALL_EMPLOYEES = gql(`query {
  get_all_employees {
    id
    name
    user {
      id
      name
    }
    address {
      city
      state
      street
      country
      postal_code
      street_number
      postal_code_suffix
    }
    last_payable {
        id
        amount
        time
        type
        time_paid
        category
    }
    department
    note
    active
  }
}`);

const employeeQuery = {
    GET_ALL_EMPLOYEES
};

export default employeeQuery;
import {gql} from "apollo-boost";

const GET_AGING = gql`
    query($showempty:Boolean){
        get_velox_aging_carriers_for_broker(showempty: $showempty){
            late
            name
            picked_up
            carrier_id
            orders_dispatched
            drivers_dispatched
            completed
        }
    }
`;

const GET_CARRIER = gql`
query($carrier_id: Int) {
  get_velox_carrier_details(carrier_id: $carrier_id) {
    id
    name
    code
    payment_term {
      carrier_id
      broker_id
      terms
    }
    internal_note
    multi_carrier
    address {
      city
      state
      street
      country
      postal_code
      street_number
      postal_code_suffix
    }
    contact {
      name
      email
      phone
    }
    drivers {
      id
      name
      equipment
    }
    carrier_insurance{
      id
      name
      policy_number
      agent{
        name
        email
        phone
      }
      liability_coverage
      damage_coverage
      deductible
      expiration
      uploaded_documents{
        uid
        name
      }
      carrier_id
    }
    carrier_registration{
      mc_number
      dot_number
      carrier_id
    }
  }
}`;


const CarrierQueries = {
    GET_AGING,
    GET_CARRIER
}

export default CarrierQueries;
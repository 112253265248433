export class Vehicle {
    __typename?: "Vehicle"
    id?: number = null;
    vin: string = '';
    year: string = '';
    make: string = '';
    model: string = '';
    submodel?: string = '';
    type?: string = '';
    no_vin?: boolean = false; // Attention
    special_vin?: boolean = false; // Attention
    incomplete_vin?: boolean = false; // Attention
    color?: string = '';
    license_plate?: string = '';
    internal_note?: string = '';
    time_created?: string | Date = '';
    user_id?: number = null;
    not_running?: boolean = false;

    constructor(x?: Vehicle){
        if(x){
            this.id = x.id;
            this.vin = x.vin;
            this.year = x.year;
            this.make = x.make;
            this.model = x.model;
            this.submodel = x.submodel;
            this.type = x.type;
            this.no_vin = x.no_vin;
            this.special_vin = x.special_vin;
            this.incomplete_vin = x.incomplete_vin;
            this.color = x.color;
            this.license_plate = x.license_plate;
            this.internal_note = x.internal_note;
            this.time_created = x.time_created;
            this.user_id = x.user_id;
            this.not_running =  x.not_running;
        }
    }

    getName?() {
        return `${this.year} ${this.make} ${this.model}`
    }

    fillData?(x: Vehicle) {
        this.year = x.year || this.year;
        this.make = x.make || this.make;
        this.model = x.model || this.model;
        this.submodel = x.submodel || this.submodel;
        this.type = x.type || this.type;
    }
}

export class All_Makes_Res {
    __typename?: "all_makes_res"
    Make_ID?: number = null;
    Make_Name?: string = '';
    
    constructor(x?: All_Makes_Res){
        if(x){
            this.Make_ID = x.Make_ID;
            this.Make_Name = x.Make_Name;
        }
    }
}

export class Model_Make_Res {
    __typename?: "model_make_res"
    Make_ID?: number = null;
    Make_Name?: string = '';
    Model_ID?: string = '';
    Model_Name?: string = '';

    constructor(x?: Model_Make_Res) {
        if(x){
            this.Make_ID = x.Make_ID;
            this.Make_Name = x.Make_Name;
            this.Model_ID = x.Model_ID;
            this.Model_Name = x.Model_Name;           
        }
    }
}

export class Vehicle_Input {
    id?: number = null;
    vin: string = '';
    year: string = '';
    make: string = '';
    model: string = '';
    submodel?: string = '';
    type?: string = '';
    no_vin?: boolean = false;   // Attention
    special_vin?: boolean = false;  // Attention
    incomplete_vin?: boolean = false; // Attention
    color?: string = '';
    license_plate?: string = '';
    internal_note?: string = '';

    constructor(x?: Vehicle_Input){
        if(x){
            this.id = x.id;
            this.vin = x.vin;
            this.year = x.year;
            this.make = x.make;
            this.model = x.model;
            this.submodel = x.submodel;
            this.type = x.type;
            this.no_vin = x.no_vin;
            this.special_vin = x.special_vin;
            this.incomplete_vin = x.incomplete_vin;
            this.color = x.color;
            this.license_plate = x.license_plate;
            this.internal_note = x.internal_note;            
        }
    }
}

import {GraphqlService} from "../services/graphql.service";
import S3Queries from "../graphql/query/S3";
import {FileDocument} from "../pages/CarriersProfilePage/CarriersProfilePage";

export class CarrierProfileHelper {
  static async previewFile(doc: FileDocument) {
    try {
      const key = doc.uid;
      const ext = doc.name.split('.').pop()?.toLocaleLowerCase() || '';
      const acceptedImg = ['jpg', 'jpeg', 'png', 'gif'];
      const isImage = acceptedImg.includes(ext);

      const link = await GraphqlService.SendQuery(S3Queries.GET_TEMP_LINK_VELOX, {is_thumbnail: false, uid: key});
      if (isImage) {
        const html = `<html><head></head><body><img src="${link}" /></body></html>`
        const tab = window.open(link, '_blank');
        tab.document.write(html);
      } else {
        Object.assign(document.createElement('a'), { target: '_blank', href: link }).click();
      }
    } catch (ex) {

    }
  }
}
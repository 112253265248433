import { combineReducers } from "redux";
import CustomerReducer, { ICustomerState } from "./Customer.reducer";
import VehicleReducer, { IVehicleState } from "./Vehicle.reducer";
import DriverReducer, {IDriverState} from "./Driver.reducer";
import CarrierReducer, {ICarrierState} from './Carrier.reducer';
import UserSettingsReducer from "./UserSettings.reducer";
import {UserSettings} from "../../classes/classes";
import NotificationsReducer, { INotifications } from "./Notifications.reducer";
import OrderReceivableReducer, {IOrderReceivablesKeys} from "./OrderReceivables.reducer";
import LegHistoryReducer, {ILegHistoryState} from "./legHistoryReducer";
import AgingReducer, {IAgingState} from "./aging.reducer";
import APReducer, { IAPCarrier, IAPKeys } from "./AP.reducer";
import { ILoading, loadingReducer } from "./Loading.reducer";
import EmployeeReducer, {IEmployeeState} from "./employee.reducer";
import bankAccountReducer, {IBankAccountState} from "./bank-account.reducer";
import { IMessageBox, messageBoxReducer } from "./MessageBox.reducer";
import DriverMapReducer, {IDriverMapState} from "./DriverMap.reducer";
import driverSettingReducer, {IDriverSettingState} from "./DriverSettings.reducer";

const reducers = combineReducers({
    customers: CustomerReducer,
    vehicles: VehicleReducer,
    drivers: DriverReducer,
    driversMap: DriverMapReducer,
    carriers: CarrierReducer,
    userSettings: UserSettingsReducer,
    orderReceivables: OrderReceivableReducer,
    notifications: NotificationsReducer,
    legHistory: LegHistoryReducer,
    agings: AgingReducer,
    AP: APReducer,
    loading: loadingReducer,
    employees: EmployeeReducer,
    bankAccounts: bankAccountReducer,
    messageBox: messageBoxReducer,
    driverSetting: driverSettingReducer
});


export interface AllStates {
    customers: ICustomerState,
    employees: IEmployeeState,
    bankAccounts: IBankAccountState,
    vehicles: IVehicleState,
    drivers: IDriverState,
    driversMap: IDriverMapState,
    carriers: ICarrierState,
    orderReceivables: IOrderReceivablesKeys,
    userSettings: UserSettings,
    notifications: INotifications,
    legHistory: ILegHistoryState,
    agings: IAgingState,
    AP: IAPKeys,
    loading: ILoading,
    messageBox: IMessageBox,
    driverSetting: IDriverSettingState,
}


export default reducers;

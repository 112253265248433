export class AuthenticationResult{
    message: string;
    token: string;
    velox: string;
    data: string;
}

export class UserData{
    id: number;
    name: string;
    level: number;
    permissions: string[];
    warehouses: number[];

    static verify(x: UserData){
        if(!x.id || !x.name || !x.permissions || !Array.isArray(x.permissions)|| !x.warehouses || !Array.isArray(x.warehouses)){
            throw new Error('Incorect User Data');
        }
        return x;
    }
}
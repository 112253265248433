import React from 'react';

const MasterOverview = () => {
    return <div className="p-3 bg-white rounded">
        <div className="row">
            <h6 className="font-12">Master Overview</h6>
            <h6 className="font-12">$20.00</h6>
        </div>
        <hr className="my-2" />

        {/* REFERENCES */}
        <div className="row mb-1">
            <h6 className="font-9">References</h6>
            <button className="btn btn-orange">ADD</button>
        </div>

        <div className="pl-2">
            <div>
                <h6 className="font-9">PO: 12345</h6>
                <div className="d-flex mt-1">
                    <h6 className="font-9 text-blue-light cursor-pointer">Edit</h6>
                    <h6 className="font-9 text-red ml-2 cursor-pointer">Remove</h6>
                </div>
            </div>

        </div>

        {/* CARD ON FILE */}

        <div className="row mt-2">
            <h6 className="font-9">Card on File</h6>
            <button className="btn btn-orange">REPLACE</button>
        </div>

        <div className="pl-2">
            <div>
                <h6 className="font-9">Master Card *9999</h6>
                <div className="d-flex mt-1">
                    <h6 className="font-9 text-red cursor-pointer">Remove</h6>
                </div>
            </div>

        </div>


        {/* CHARGES */}

        <hr className={'my-2'} />

        <div className="row">
            <h6 className="font-9">Charges</h6>
            <button className="btn btn-orange">ADD</button>
        </div>

        <div className="pl-2">
            <div>
                <div className="row mt-1">
                    <h6 className="font-9">Online Payment Fee</h6>
                    <h6 className="font-9">$20.00</h6>
                </div>
                <div className="d-flex mt-1">
                    <h6 className="font-9 text-blue-light cursor-pointer">Edit</h6>
                    <h6 className="font-9 text-red ml-2 cursor-pointer">Remove</h6>
                </div>
            </div>

        </div>


    </div>
}

export default MasterOverview;
import './PickupCustomer.scss'
import {useEffect, useRef, useState} from "react";
import Searchbar from "../../Searchbar/Searchbar";
import LocationForm from "../LocationForm/LocationForm";
import ProfileForm from "../ProfileForm/ProfileForm";
import {useDispatch, useSelector} from 'react-redux';
import {AllStates} from '../../../redux/reducers';
import {ICustomerState} from '../../../redux/reducers/Customer.reducer';
import CustomerTable from '../../CustomerTable/CustomerTable';
import {Customer, CustomerList, CustomerProfile, Location} from '../../../classes/classes';
import Loading from '../../Loading/Loading';
import {getAllCustomerProfilesList, getAllCustomerProfilesListSuccess, getCustomerLocations, getCustomerLocationsSuccess} from '../../../redux/actions/Customer.action';
import ICONS from '../../../../assets/svg';
import CustomerProfileForm from '../../CustomerProfileForm/CustomerProfileForm';
import Modal from 'react-modal';
import {useToasts} from 'react-toast-notifications';
import Toggle from "../../Toggle/Toggle";
import ReactModal from 'react-modal';
import environments from '../../../../environments/environments';
import { Stop } from '../../../services/endpoints/schemas/stop/stop';

const PickupCustomer = ({
    onCancelClicked,
    stop,
    onConfirmClicked,
    title = '',
    currentCustomer,
    showCustomerList = true
}: {
    showCustomerList?: boolean,
    title?: string,
    onCancelClicked: (evt: any) => void,
    stop: Stop,
    currentCustomer?: CustomerList,
    onConfirmClicked: (stop: Stop) => void
}) => {

    const ref = useRef();
    const [showLocationForm, setShowLocationForm] = useState(false);
    const [showProfileForm, setShowProfileForm] = useState(false);
    const [customer, SetCustomer] = useState(currentCustomer || new CustomerList());
    const [myStop, setMyStop] = useState(stop);
    const [newProfileOpened, setNewProfileOpened] = useState(false);
    const [customerLoading, setCustomerLoading] = useState(false);
    const [newLocationOpened, setNewLocationOpened] = useState(false);

    const {
        customers,
        loadingProfiles,
        customerProfiles,
        errorProfiles,
        customerLocations,
        loadingLocations,
        errorLocations
    } = useSelector<AllStates>(states => states.customers) as ICustomerState;
    const toast = useToasts();
    const dispatch = useDispatch();

    enum Screens {
        CustomerSelection,
        LocationDetails
    }

    const [screen, setScreen] = useState(Screens.CustomerSelection)

    function handleSubmitProfile(profile) {
        const s = {...myStop};
        s.contacts.push(profile);

        setMyStop(new Stop(s));
        dispatch(getAllCustomerProfilesList(customer?.id));
    }

    async function handleSubmitLocation(location: Location) {
        setMyStop({...myStop, location});
        setNewLocationOpened(false);

        dispatch(getCustomerLocations(customer?.id));
    }

    function handleSubmitEditProfile(contact) {
        setShowEditContactForm(undefined);
    }

    function handleSubmitEditLocation(location: Location) {
        onSelectLocation(new Location());
        onSelectLocation({...location});
        setCustomerLoading(false);

        dispatch(getCustomerLocations(location?.customer?.id));
        setShowEditLocationForm(undefined);
    }

    function isLocationSelected(location_id: number) {
        return myStop?.location?.id == location_id;
    }

    function onSelectLocation(location: Location) {
        let l = isLocationSelected(location?.id) ? new Location() : location;
        l.customer = new Customer(myStop.location.customer);
        setMyStop({...myStop, location: {...l}});
    }

    function isPOCSelected(profile_id: number) {
        return myStop?.contacts.findIndex(x => x.id == profile_id) > -1;
    }

    function onSelectPOC(profile: CustomerProfile) {
        let temp = [...myStop?.contacts];
        if (!isPOCSelected(profile.id))
            temp.push(profile);
        else
            temp = temp.removeBy(x => x.id == profile.id);

        setMyStop({...myStop, contacts: temp})
    }

    // console.log('here')

    const getCustomerInfo = (customer: Customer) => (<>
        <div className="row mt-3 mb-2 pb-2 border-bottom">
            <div className="col-4">
                <p className="font-12 font-medium px-3">Customer</p>

            </div>
            <div className="col-5">
                <p className="font-12 font-medium px-3">Key</p>
            </div>
            <div className="col-3 text-right"></div>
        </div>

        <div className="row mb-3 pb-3">
            <div className="col-4">
                <p className="font-12 font-medium px-3">{myStop.location?.customer?.name}</p>

                { customer?.corporate_client && <span className="font-9 px-3">CORPORATE</span> }
                { !customer?.corporate_client && <span className="font-9 px-3">PRIVATE</span> }
            </div>
            <div className="col-5">
            <p className="font-12 font-medium px-3">{myStop.location?.customer?.key}</p>
            </div>
            {showCustomerList && <div className="col-3 text-right">
                <button className="btn btn-clear text-gray font-medium font-10"
                        onClick={() => setScreen(Screens.CustomerSelection)}>BACK TO RESULTS
                </button>
            </div>}
        </div>
    </>)

    useEffect(() => {
        if (stop && stop.location?.customer.id) {
            setScreen(Screens.LocationDetails);
            dispatch(getAllCustomerProfilesList(stop.location.customer?.id));
            dispatch(getCustomerLocations(stop.location.customer?.id));
        } else setScreen(Screens.CustomerSelection);
    }, []);


    useEffect(() => {
        if (!loadingLocations && stop?.location?.id == null) {
            let l = (customerLocations.length == 1 ? customerLocations[0] : null);
            let location = !showCustomerList ? customerLocations.find(x => x.title?.toLowerCase().startsWith( 'billing')) || l : l ;
            
            // console.log('Location found: ', location != null, location);
            if (location) {
                onSelectLocation(location);
            }

        }
    }, [customerLocations]);


    useEffect(() => {
        if (!loadingProfiles && stop?.contacts.length == 0) {
            let profile = customerProfiles.length == 1 ? customerProfiles[0] : null;

            if (profile) {
                onSelectPOC(profile);
            }

        }
    }, [customerProfiles]);

    function setCustomer(customerData: CustomerList, where: number){


        console.log('customer wants to be changed: ', where, customerData);
        SetCustomer(customerData);
    }

    useEffect(() => {
        if (currentCustomer) {
            setCustomer({...currentCustomer}, 1);
        }
        else if (stop?.location?.customer?.id){
            setCustomer({...stop?.location?.customer}, 2);
        }
    }, [stop]);

    useEffect(() => {
        // Anything in here is fired on component mount.
        document.addEventListener('mousedown', handleClickOutside)

        // Anything in here is fired on component unmount.
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [])


    const contactRef = useRef();
    const locationRef = useRef();
    const [showEditContactOpt, setShowEditContactOpt] = useState(-1);
    const [showEditLocationOpt, setShowEditLocationOpt] = useState(-1);
    const [showEditContactForm, setShowEditContactForm] = useState(undefined);
    const [showEditLocationForm, setShowEditLocationForm] = useState(undefined);


    const handleClickOutside = (event) => {
        // @ts-ignore
        if (showEditContactOpt && contactRef && contactRef.current && !contactRef.current.contains(event.target))
            setShowEditContactOpt(-1);

        // @ts-ignore
        if (showEditLocationOpt && locationRef && locationRef.current && !locationRef.current.contains(event.target))
            setShowEditLocationOpt(-1);
    }

    const templateContact = () => {
        return (
            <div className="p-0">
                <div className="row">
                    <div className='sticky-top col-12 pb-3'>
                        <div className="col-12 row">
                            <p className="font-12 font-medium">Contact Profile</p>
                            {!showProfileForm &&
                            <a className="font-10 font-medium text-light-blue cursor-pointer"
                               onClick={() => setNewProfileOpened(true)}><i
                                className="font-10 ti-plus"></i> NEW PROFILE</a>}
                        </div>

                        {myStop?.contacts?.length > 0 &&
                        <div className="col-12 mt-3 row align-items-center">
                            <p className="font-12 fw-400">Selected: {myStop?.contacts?.length}</p>
                            <button onClick={(evt) => setMyStop({...myStop, contacts: []})}
                                    className="btn btn-danger-outline"
                                    style={{fontSize: '.5rem'}}>UNSELECT ALL
                            </button>
                        </div>}

                        <div className="col-12 mt-3 d-flex">
                            <div className='col-12'>
                                <Searchbar placeholder={'search...'}
                                           background={'#F3F6F8'}
                                           reference={ref}/>
                            </div>
                            <button
                                onClick={(evt) => dispatch(getAllCustomerProfilesList(customer?.id))}
                                className='btn btn-clear p-0 mx-2'>
                                <img className={loadingProfiles && 'spin'}
                                     src={ICONS.IconRefresh} alt=""/>
                            </button>
                        </div>
                    </div>

                    <div className="col-12">
                        {
                            showProfileForm ?
                                <ProfileForm onCancel={() => setShowProfileForm(false)}
                                             onSubmit={() => setShowProfileForm(false)}/>
                                :
                                <>
                                    {loadingProfiles ? <Loading/> :
                                        <div className='list'>
                                            {customerProfiles.map(profile => {
                                                return (
                                                    <div
                                                        className={"bg-gray p-3 border-radius position-relative pickup-hover " + (isPOCSelected(profile.id) ? 'bg-light-blue' : '')}
                                                        onClick={(evt) => onSelectPOC(profile)}
                                                        onContextMenu={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();

                                                            setShowEditContactOpt(profile.id);
                                                        }}>

                                                        {
                                                            showEditContactOpt == profile.id &&
                                                            <div ref={contactRef} className="card card-right-click">
                                                                <p className="font-12 px-3 py-2"
                                                                   onClick={() => setShowEditContactForm(profile)}><i
                                                                    className="ti-pencil"></i> Edit Contact</p>
                                                            </div>
                                                        }

                                                        <p className="font-12 mb-1 font-medium">{profile?.name}</p>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <p className="font-12 mb-1">{profile?.title}.</p>
                                                            {/* <p style={{color: '#888888'}}
                                                               className="font-12 mb-1">Notifications</p> */}
                                                        </div>

                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <p className="font-12 mb-1">{profile?.email}</p>

                                                            {/* <div className="customer-selection">
                                                                <Toggle id={'toggle'}
                                                                        checked={true} label={''} onCheck={(evt) => {console.log(evt)}} />
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
        )
    }

    const templateLocation = () => {
        return (
            <div className="p-0">
                <div className="row">
                    <div className="col-12 sticky-top pb-3">
                        <div className="col-12 row">
                            <p className="font-12 font-medium">Location</p>
                            {
                                !showLocationForm && <a className="font-10 font-medium text-light-blue cursor-pointer"
                                                        onClick={() => setNewLocationOpened(true)}>
                                    <i className="font-10 ti-plus"></i> NEW LOCATION</a>
                            }
                        </div>

                        <div className="col-12 mt-3 d-flex">
                            <div className='col-12'>
                                <Searchbar placeholder={'search...'}
                                           background={'#F3F6F8'}
                                           reference={ref}/>
                            </div>

                            <button onClick={(evt) => dispatch(getCustomerLocations(customer?.id))}
                                    className='btn btn-clear p-0 mx-2'>
                                <img className={loadingLocations && 'spin'} src={ICONS.IconRefresh} alt=""/>
                            </button>
                        </div>
                    </div>

                    <div className="col-12">
                        {
                            showLocationForm ?
                                <LocationForm customer={customer}
                                              location={new Location()}
                                              onCancel={() => setShowLocationForm(false)}
                                              onSubmit={() => setShowLocationForm(false)}/>
                                :
                                <>
                                    {loadingLocations ? <Loading/> :
                                        <div className='list'>
                                            {customerLocations.map(location => {
                                                return (
                                                    <div
                                                        className={"bg-gray p-3 border-radius position-relative pickup-hover " + (isLocationSelected(location?.id) ? 'bg-light-blue' : '')}
                                                        onClick={(evt) => onSelectLocation(location)}
                                                        onContextMenu={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();

                                                            setShowEditLocationOpt(location?.id);
                                                        }}>

                                                        {
                                                            showEditLocationOpt == location?.id &&
                                                            <div ref={locationRef} className="card card-right-click">
                                                                <p className="font-12 px-3 py-2"
                                                                   onClick={() => setShowEditLocationForm(location)}><i
                                                                    className="ti-pencil"></i> Edit Location</p>
                                                            </div>
                                                        }

                                                        <p className="font-12 mb-1 font-medium">{location?.title}</p>
                                                        <p className="font-12 mb-1">{location?.address.street_number} {location?.address.street}</p>
                                                        <p className="font-12 mb-1">{location?.address.city}, {location?.address.state} {location?.address.postal_code}</p>
                                                    </div>
                                                );
                                            })
                                            }
                                        </div>
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className='flex-1-container'>
                <div className="row">
                    <div className="col-12 mb-1 d-flex align-items-center">
                        {
                            <div className="d-flex align-items-center">
                                <p className="font-14 font-medium">{title ? title : myStop?.is_pickup ? 'Pickup' : 'Dropoff'}</p>
                                {Screens.CustomerSelection == screen && <button className="ml-2 btn btn-orange" onClick={(evt) => {
                                    const temp = new Customer();
                                    temp.id = environments.JP_CUSTOMER_ID;
                                    temp.name = environments.JP_CUSTOMER_NAME;
                                    let l = {...myStop?.location};
                                    if (l.customer?.id !== environments.JP_CUSTOMER_ID) {
                                        l = new Location();
                                        l.customer = temp;
                                        setCustomer(l.customer, 3)
                                        console.log('evt', evt);
                                    }

                                    setMyStop({
                                        ...myStop,
                                        contacts: customer?.id === temp.id ? myStop?.contacts : [],
                                        location: l
                                    });

                                    setCustomer({...temp}, 4);
                                    dispatch(getAllCustomerProfilesList(temp.id));
                                    dispatch(getCustomerLocations(temp.id));
                                    setScreen(Screens.LocationDetails)
                                }}>JP Logistics</button>}
                            </div>

                                
                        }
                    </div>
                </div>

                <div className='flex-1-container'>
                    {
                        screen == Screens.CustomerSelection && showCustomerList ?
                        <CustomerTable
                                           onCustomerClicked={(evt) => {

                                            let l = {...myStop?.location};
                                            if (l.customer?.id !== evt.id) {
                                                l = new Location();
                                                l.customer = evt;
                                                setCustomer(l.customer, 5)
                                                console.log('evt', evt);
                                            }

                                            setMyStop({
                                                ...myStop,
                                                contacts: customer?.id === evt.id ? myStop?.contacts : [],
                                                location: l
                                            });

                                            setCustomer({...evt}, 6);
                                            dispatch(getAllCustomerProfilesList(evt.id));
                                            dispatch(getCustomerLocations(evt.id));
                                            setScreen(Screens.LocationDetails)
                                        }} tableHeight={''}/>
                        :

                        <div className="row flex-column h-100 justify-content-between flex-nowrap">
                            <div className="col-12 mb-1">
                                {getCustomerInfo(customer)}
                            </div>

                            <div className='col-12 d-flex h-100 flex-column overflow-y-auto'
                                 style={{maxHeight: '100%'}}>
                                <div style={{maxHeight: '100%'}} className="col-12 h-100 row">
                                    <div style={{maxHeight: '100%', border: '1rem solid #FFF'}}
                                         className="col-6 h-100 overflow-y-auto border-radius h-67-overflow">
                                        {templateLocation()}
                                    </div>

                                    <div style={{maxHeight: '100%', border: '1rem solid #FFF'}}
                                         className="col-6 h-100 overflow-y-auto border-radius h-67-overflow">
                                        {templateContact()}
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>

                {
                    
                    <div className="row mt-3">
                        <div className="col-12">
                            <button onClick={(evt) => {
                                onCancelClicked(evt);

                                dispatch(getCustomerLocationsSuccess([]));
                                dispatch(getAllCustomerProfilesListSuccess([]));
                            }} className="btn text-light-blue rounded-pill py-2 col-6 mr-3">CANCEL</button>
                            {screen == Screens.LocationDetails && <button onClick={() => {
                                onConfirmClicked(myStop)
                                console.log(myStop);

                                dispatch(getCustomerLocationsSuccess([]));
                                dispatch(getAllCustomerProfilesListSuccess([]));
                            }} className="btn btn-blue-light rounded-pill py-2 col-6"
                                    disabled={
                                        !myStop?.location?.id
                                    }>CONFIRM
                            </button>}
                        </div>
                    </div>
                }
            </div>


           
            <ReactModal isOpen={newProfileOpened} 
                        className={"modal-fit modal-90"}
                        onRequestClose={() => setNewProfileOpened(false)}>

                { customerLoading ? null :  
                    //<div style={{display: customerLoading ? 'none' : 'block'}}>
                        <CustomerProfileForm customerId={customer.id}
                                            onSubmit={(evt) => {
                                                setNewProfileOpened(false);
                                                if (evt) handleSubmitProfile(evt);
                                            }}/>
                    //</div>
                }
                {
                    !customerLoading ? undefined
                        :
                        <>
                            <h4 className='regular-text text-center'>Creating profile...</h4>
                            <Loading/>
                        </>
                }
            </ReactModal>

            <ReactModal isOpen={newLocationOpened} 
                        style={{content: {borderRadius: '12px', width: '496px', minWidth: '496px'}}}
                    >
                    { customerLoading ? null :  
                        //<div className='p-3'>
                        //<div style={{display: customerLoading ? 'none' : 'block'}}>
                            <LocationForm customer={customer}
                                        location={new Location()}
                                        onCancel={(evt) => setNewLocationOpened(false)}
                                        onSubmit={async (evt) => {
                                            await handleSubmitLocation(evt);
                                        }
                                        }/>
                        //</div>
                    }
                    {
                        !customerLoading ? undefined
                            :
                            <>
                                <h4 className='regular-text text-center'>Creating location?...</h4>
                                <Loading/>
                            </>
                    }
               {/* </div> */}
            </ReactModal>

            <ReactModal isOpen={!!showEditContactForm}
                   //style={{content: {borderRadius: '12px', width: '496px', paddingBottom: '0', minWidth: '496px'}}}
                   className={"modal-fit modal-90"}
                   onRequestClose={() => setShowEditContactForm(undefined)} >

                <>
                { customerLoading ? null :  
                    //<div style={{display: customerLoading ? 'none' : 'block'}}>
                        <CustomerProfileForm customerId={customer.id}
                                             customerProfile={showEditContactForm}
                                             onSubmit={(evt) => {
                                                 setShowEditContactForm(undefined);
                                                 if (evt) handleSubmitEditProfile(evt);
                                             }}/>
                    //</div>
                    }

                    {
                        !customerLoading ? undefined :
                            <>
                                <h4 className='regular-text text-center'>Updating profile...</h4>
                                <Loading/>
                            </>
                    }
                </>
            </ReactModal>

            <ReactModal isOpen={!!showEditLocationForm}
                   //style={{content: {borderRadius: '12px', width: '496px', minWidth: '496px'}}}
                   className={""}
                   >
                {/*<div>*/}
                { customerLoading ? null :  
                    //<div style={{display: customerLoading ? 'none' : 'block'}}>
                        <LocationForm customer={customer}
                                      location={showEditLocationForm}
                                      onCancel={(evt) => setShowEditLocationForm(undefined)}
                                      onSubmit={(evt) => {
                                          handleSubmitEditLocation(evt)
                                      }}/>
                    //</div>
                    }
                    {
                        !customerLoading ? undefined :
                        <>
                            <h4 className='regular-text text-center'>Updating location...</h4>
                            <Loading/>
                        </>
                    }
              {/*   </div> */}
            </ReactModal>
        </>
    );
}

export default PickupCustomer;

export {}

declare global {
    
    interface Number {
        toCurrency(currency?: string,useCommaSeparator?: boolean, useDecimalSeparator?: boolean): string;
    }
    interface Array<T> {
        sumBy(predicate: (value: T) => number): number;

        removeBy(predicate: (value: T) => boolean): T[];

        groupBy(predicate: (value: T) => any): T[];

        mergeByProp(arr: any[], prop: string): any[];

        removeAt(index: number): T[];

        last(): T;
    }

    interface Date {
        addDays(days: number): Date
    }

    interface JSON {
        clone<T>(obj: T): T;

        compare<T>(obj1: T, obj2: T): boolean
    }

    interface Window {
        wait(ms: number);
    }
}

(() => {

    //#region JSON

        JSON.clone = (data) => {
            return JSON.parse(JSON.stringify(data));
        }

        JSON.compare = (obj1, obj2) => {
            return JSON.stringify(obj1) == JSON.stringify(obj2);
        }

    //#endregion

    //#region Array
    
        Array.prototype.sumBy = function (params) {
            return (this.reduce((total, current) => total + (isNaN(params(current)) ? 0 : params(current) * 1000), 0) / 1000);
        }

        Array.prototype.removeBy = function (params) {
            
            return this.filter(item => !params(item));
        }

        Array.prototype.groupBy = function (params) {
            return this.reduce((previous, current) => {
                const index = previous.findIndex(x => x.some(y => params(y) == params(current)));
                index > -1 ? previous[index].push(current) : previous.push([current]);
                return previous;
            }, [])
        }

        Array.prototype.removeAt = function (index) {
            this.splice(index, 1);
            return this;
        }

        Array.prototype.last = function() {
            return this[this.length - 1];
        }

    //#endregion
    
    //#region Date
    
        Date.prototype.addDays = function (days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }

       
        // console.log((2.439).toCurrency());


        Window.prototype.wait = function(ms) {
            return new Promise((resolve, reject) => setTimeout(() => resolve(''), ms));
        }

        Number.prototype.toCurrency = function(currency: string = '$', useCommaSeparator: boolean = true, useDecimalSeparator: boolean = true){
            
            if(currency == '$' && useCommaSeparator && useDecimalSeparator){
                let value = isNaN(this) ||this as any == '-0' ? 0 : this;
        
                return new Intl.NumberFormat('en-US',
                        { style: 'currency', currency: 'USD' }
                    ).format(value)
            }
        
            if(this < 0){
                currency = '-' + currency;
            }
            
        
            let txt = '', res = '';
            let temp: string = this.toString();
            for(let i = 0; i< temp.length; i++){
                if('0123456789'.includes(temp[i])){
                    txt += temp[i];
                }
            }
            txt = (+txt).toString();
            if(useDecimalSeparator){
                if(!txt){
                    return currency + '0.00';
                }
                else if(txt.length == 1){
                    return currency + '0.0' + txt;
                }
                else if(txt.length == 2){
                    return currency + '0.' + txt;
                }
                else{
                    res = '.' + txt.substring(txt.length - 2);
                    txt = txt.substring(0, txt.length - 2);
                }
            }
            if(useCommaSeparator){
                if(!txt){
                    return currency + '0' + res;
                }
                
                while (txt.length > 3){
                    res = ',' + txt.substring(txt.length - 3) + res;
                    txt = txt.substring(0, txt.length - 3);
                }
            }
            return currency + txt + res;
        }

        
        
        
    //#endregion

})();
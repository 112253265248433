import Helper from "../../helper/Helper";
import {useEffect, useState} from "react";
import {BreakDown, Charge, Leg, MasterBreakDown, ServiceCharge, StorageOrder} from "../../classes/classes";
import ChargeDiscountForm from "../ChargeDiscountForm/ChargeDiscountForm";
import ReactModal from "react-modal";
import EditServices from "./EditServices";
import gql from "graphql-tag";
import { GraphqlService } from "../../services/graphql.service";
import Loading from "../Loading/Loading";
import { useToasts } from "react-toast-notifications";
import Toggle from "../Toggle/Toggle";
import FormGroup from "../FormGroup/FormGroup";
import moment from "moment";
import StorageOrderModal from "./StorageOrderModal";

const PriceBreakdown = ({breakdown, onChargesUpdated}: { breakdown: MasterBreakDown, onChargesUpdated: () => void }) => {
    const [addCharge, setAddCharge] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [storageSelected, setStorageSelected] = useState({index: null, storage: new StorageOrder(), charges: []});
    const getTotal = () => {
        return (breakdown.breakdowns.sumBy(e => e.totalCost)) + breakdown.charges.sumBy(x => x.amount);
    };
    const getTotalRemaining = () => {
        return (breakdown.breakdowns.sumBy(e => e.totalRemaining)) + breakdown.charges.sumBy(x => x.amount_remaining);
    };

    const master_id = breakdown.id;


    useEffect(() => {


        // s
        let t = [1, 2, 3, null, 2, 3, 4, 5, null, 0];

        // console.log(t.filter(Boolean))

        if (servicesData.master_id || servicesData.vo_id)
        {
            
            const charges = servicesData.vo_id != null ? breakdown.breakdowns.find(x => x.id == servicesData.vo_id).charges.filter(x => !x.storage_charge?.id) : breakdown.charges;
            setServicesData({...servicesData, charges});
        }
    }, [breakdown]);
    

    const toast = useToasts();
    const [servicesData, setServicesData] = useState<{ vo_id: number, breakdown_id: number, master_id: number, legs: Leg[], charges: Charge[] }>({
        vo_id: null,
        breakdown_id: null,
        master_id: null,
        legs: [],
        charges: []
    });

    return (
        <>
            <ReactModal isOpen={loading} >
                <Loading />
                <h4 className='fs-14'>SAVING SERVICE CHARGES...</h4>
            </ReactModal>
            <div className="flex-1-container">
                <div className="col-12 pb-3 row ">
                    <p className="font-12 font-medium">Price breakdown</p>
                </div>

                <div className="flex-1-container">
                {
                    breakdown?.breakdowns.map((breakdown, index) => {
                        return (
                            <div key={"breakdown-" + index} className="col-12 px-3 mb-2">
                                <div className="card bg-gray">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="row col-12">
                                                    <p className="font-10 font-medium mb-3 mt-3">Vehicle {index + 1}</p>
                                                    {breakdown.id && <p className="font-10 font-medium mb-3 mt-3">VO-{breakdown.id}</p>}
                                                </div>

                                                <p className="font-10 font-medium mb-1">{`${breakdown.vehicle.year} ${breakdown.vehicle.make} ${breakdown.vehicle.model}`}</p>
                                                <p className="font-10 mb-3">{`${breakdown.vehicle.vin}`}</p>

                                                {
                                                    breakdown.equipments.filter(x => x.count != 0).map(x => {
                                                        return <p key={x.equipment}
                                                                  className="font-10 font-medium mb-2">X {x.count} {x.equipment}</p>;
                                                    })
                                                }

                                                {
                                                    breakdown?.noFailCount == 0 ? null :
                                                        <p className="font-10 font-medium mb-2 text-red">X {breakdown?.noFailCount} No
                                                            fail</p>
                                                }

                                                <div className="mt-3">
                                                    <button onClick={(evt) => setStorageSelected({storage: new StorageOrder({...breakdown.storage_order, vehicle_order_id: breakdown.id}), index, charges: breakdown.charges.filter(x => x.storage_charge?.id)})} className={`btn font-9 btn-blue-light${breakdown?.storage_order?.active ? '' : '-outline'}`}>{breakdown.storage_order?.active ? 'This is Storage' : 'Mark as Storage'} {breakdown.storage_order?.active && <i className="far fa-check-circle"></i>}</button>
                                                </div>
                                            </div>

                                            <div className="col-8">
                                                <div className="d-flex justify-content-end mb-3">
                                                    <button onClick={() => {
                                                        setServicesData({
                                                            charges: breakdown.charges.filter(x => !x.storage_charge?.id),
                                                            master_id: master_id,
                                                            breakdown_id: breakdown.id,
                                                            vo_id: breakdown.id,
                                                            legs: [...breakdown.legs]
                                                        });
                                                    }} className='btn btn-blue-light rounded-pill'>EDIT SERVICES FOR
                                                        VO-{breakdown.id}</button>
                                                </div>

                                                <div className="card">
                                                    <div className="card-body">

                                                        <div className="row">
                                                            <div className="col-2">
                                                                <p className="font-10 font-medium text-gray mb-2"></p>
                                                            </div>

                                                            <div className="col-6">
                                                            </div>

                                                            <div className="col-2">
                                                                <p className="font-10 text-right font-medium mb-2">Price</p>
                                                            </div>

                                                            <div className="col-2">
                                                                <p className="font-10 text-red text-right font-medium mb-2">Remaining</p>
                                                            </div>

                                                        </div>
                                                        <hr className='mt-1 mb-3'/>
                                                        {
                                                            breakdown.legs.map((leg, legIndex) => {
                                                                return (
                                                                    <div key={legIndex} className="row mb-1">
                                                                        <div className="col-2">
                                                                            <p className="font-10 font-medium text-gray mb-2">{leg.equipment}</p>
                                                                        </div>

                                                                        <div className="col-6">
                                                                            <p className="font-10 font-medium mb-2">
                                                                                {`${leg?.getPickup()?.location?.address?.city}, ${leg?.getPickup()?.location?.address?.state}`}
                                                                                <i className="ti-minus mx-1"></i>
                                                                                {`${leg?.getDropoff()?.location?.address?.city}, ${leg?.getDropoff()?.location?.address?.state}`}
                                                                            </p>
                                                                        </div>

                                                                        <div className="col-2">
                                                                            <p className="font-10 text-right font-medium mb-2">{Helper.FORMAT.USCurrency(leg?.getServiceChargesTotal())}</p>
                                                                        </div>

                                                                        <div className="col-2">
                                                                            <p className="font-10 text-red text-right font-medium mb-2">{Helper.FORMAT.USCurrency(leg?.service_charges[0]?.amount_remaining)}</p>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }

                                                        {
                                                            breakdown?.charges.map((charge, index) => {
                                                                let idx = index + 1;
                                                                return (
                                                                    <div key={`vh-${idx}`} className="row mb-1">
                                                                        <div className="col-2">
                                                                            <p className={`font-10 font-medium text-${charge.storage_charge?.id ? 'blue-light' : 'blue-light'} mb-2`}>{charge.storage_charge?.id ? 'Storage' : (charge.inventory_service.service_charge_id ? 'Inventory' : '')}</p>
                                                                        </div>

                                                                        <div className="col-6">
                                                                            <p className="font-10 font-medium mb-2">{charge?.name}</p>
                                                                        </div>

                                                                        <div className="col-2">
                                                                            <p className="font-10 text-right font-medium mb-2">{Helper.FORMAT.USCurrency(charge?.amount)}</p>
                                                                        </div>

                                                                        <div className="col-2">
                                                                            <p className="font-10 text-red text-right font-medium mb-2">{Helper.FORMAT.USCurrency(charge?.amount_remaining)}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                        <div className="row mb-2">
                                                            <div className="col-2"></div>

                                                            <div className="col-6 border-top pt-2">
                                                                <p className="font-12 font-medium">Vehicle Total</p>
                                                            </div>

                                                            <div className="col-2 border-top pt-2">
                                                                <p className="font-12 text-right font-medium">{Helper.FORMAT.USCurrency(BreakDown.getTotal(breakdown))}</p>
                                                            </div>
                                                            <div className="col-2 border-top pt-2">
                                                                <p className="font-12 text-red text-right font-medium">{Helper.FORMAT.USCurrency(breakdown?.totalRemaining)}</p>
                                                            </div>
                                                        </div>

                                                        <div className="row pt-2">
                                                            {/* <div className="col-12 text-right">
                                                                <p className="font-12 text-right text-light-blue cursor-pointer"
                                                                   onClick={() => setAddCharge(index)}>
                                                                    <i className="font-10 ti-plus mr-2"></i>
                                                                    <span className="font-medium">ADD</span>
                                                                </p>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 pt-3">
                                                {
                                                    addCharge == index ?
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <ChargeDiscountForm 
                                                                    vehicle_order_id={servicesData.master_id}
                                                                    master_order_id={servicesData.vo_id}
                                                                charge={new Charge()}
                                                                                    onCancel={() => {
                                                                                        setAddCharge(-1);
                                                                                    }}
                                                                                    onSubmit={(serviceCharge) => {
                                                                                        let charges = [...breakdown.charges];
                                                                                        charges.push(serviceCharge);

                                                                                        breakdown.charges = [...charges];
                                                                                        setAddCharge(-1);
                                                                                    }}/>
                                                            </div>
                                                        </div> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }

                <div key={"breakdown-master"} className="col-12 px-3 mt-3">
                    <div className="card bg-gray">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-4">
                                    <p className="font-10 font-medium mb-3 mt-3">Master {breakdown.id}</p>
                                </div>

                                <div className="col-8">
                                    <div className="d-flex justify-content-end mb-3">
                                        <button onClick={(evt) => {
                                            setServicesData({
                                                charges: [...breakdown.charges],
                                                master_id: breakdown.id,
                                                breakdown_id: breakdown.id,
                                                vo_id: null,
                                                legs: []
                                            });
                                        }} className='btn btn-blue-light'>EDIT SERVICES FOR M-{breakdown.id}</button>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="row">
                                                <div className="col-2">
                                                    <p className="font-10 font-medium text-gray mb-2"></p>
                                                </div>

                                                <div className="col-6">
                                                </div>

                                                <div className="col-2">
                                                    <p className="font-10 text-right font-medium mb-2">Price</p>
                                                </div>

                                                <div className="col-2">
                                                    <p className="font-10 text-red text-right font-medium mb-2">Remaining</p>
                                                </div>

                                            </div>
                                            <hr className='mt-1 mb-3'/>


                                            {
                                                breakdown?.charges.map((charge, index) => {
                                                    let idx = index + 1;
                                                    return (
                                                        <div key={`vh-${idx}`} className="row mb-2">
                                                            <div className="col-2">
                                                                <p className="font-10 font-medium text-gray mb-2">M-{idx}</p>
                                                            </div>

                                                            <div className="col-6">
                                                                <p className="font-10 font-medium mb-2">{charge?.name}</p>
                                                            </div>

                                                            <div className="col-2">
                                                                <p className="font-10 text-right font-medium mb-2">{Helper.FORMAT.USCurrency(charge?.amount)}</p>
                                                            </div>

                                                            <div className="col-2">
                                                                <p className="font-10 text-red text-right font-medium mb-2">{Helper.FORMAT.USCurrency(charge?.amount_remaining)}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                            <div className="row mb-2">
                                                <div className="col-2"></div>

                                                <div className="col-6 border-top pt-2">
                                                    <p className="font-12 font-medium">Master Charges Total</p>
                                                </div>

                                                <div className="col-2 border-top pt-2">
                                                    <p className="font-12 text-right font-medium">{Helper.FORMAT.USCurrency(breakdown?.charges?.sumBy(x => x.amount))}</p>
                                                </div>
                                                <div className="col-2 border-top pt-2">
                                                    <p className="font-12 text-red text-right font-medium">{Helper.FORMAT.USCurrency(breakdown?.charges.sumBy(x => x.amount_remaining))}</p>
                                                </div>
                                            </div>

                                            <div className="row pt-2">
                                                {/* <div className="col-12 text-right">
                                                    <p className="font-12 text-right text-light-blue cursor-pointer"
                                                       onClick={() => setAddCharge(0)}>
                                                        <i className="font-10 ti-plus mr-2"></i>
                                                        <span className="font-medium">ADD</span>
                                                    </p>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <div key={"breakdown-test"} className="col-12 px-3">
                    <div className="card">
                        <div className="card-body py-0">
                            <div className="row">
                                <div className="col-4">

                                </div>

                                <div className="col-8">
                                    <div className="card">
                                        <div className="card-body py-0">


                                            <div className="row">
                                                <div className="col-2"></div>

                                                <div className="col-6 pt-2">
                                                    <p className="font-12 font-medium">Order Total</p>
                                                </div>

                                                <div className="col-2 pt-2">
                                                    <p className="font-12 text-right font-medium">{Helper.FORMAT.USCurrency(getTotal())}</p>
                                                </div>
                                                <div className="col-2 pt-2">
                                                    <p className="font-12 text-red text-right font-medium">{Helper.FORMAT.USCurrency(getTotalRemaining())}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <ReactModal className={'modal-fit modal-90'} isOpen={servicesData.vo_id != null || servicesData.master_id != null} >


                <EditServices 
                                onClose={(reload, keepOpen) => {
                                    if (!keepOpen)
                                        setServicesData({...servicesData, vo_id: null, master_id: null});
                                    if (reload)
                                    {
                                        onChargesUpdated();
                                    }
                                }}
                                servicesData={servicesData}
                              onCancel={() => setServicesData({...servicesData, vo_id: null, master_id: null})}
                              onSubmit={async (service) => {
                                  let breakd = JSON.parse(JSON.stringify(breakdown));
                                  const breakdowns = breakd.breakdowns;

                                  // update breakdowns
                                  console.log(service);
                                if (servicesData.vo_id)
                                {
                                    let index = breakdowns.findIndex(e => e.id == servicesData.breakdown_id);
                                    breakdowns[index].charges = [...service.charges];
                                    breakd.breakdowns = [...breakdowns];
                                }

                                else if (servicesData.master_id)
                                {
                                    breakd.charges = [...service.charges];
                                }



                                  const mutation = gql`
                                    mutation($master:charge_input){
                                            manage_service_charges(master: $master){
                                            id
                                            message
                                            success
                                        }
                                    }
                                  `;





                                    setLoading(true);
                                    setServicesData({...servicesData, vo_id: null, master_id: null});

                                
                                    const master = {
                                        id: breakd.id,
                                        charges: breakd.charges.map(x => ({id: x.id, name: x.name, base_amount: +x.base_amount, tax_amount: +x.tax_amount, amount: +x.amount})),
                                        vehicle_orders: breakd.breakdowns.map(x => ({
                                            id: x.id,
                                            charges: x.charges.map(y => ({id: y.id, name: y.name, base_amount: +y.base_amount, tax_amount: +y.tax_amount, amount: +y.amount})),
                                            legs: x.legs.map(leg => ({
                                                id: leg.id,
                                                equipment_price: +leg.equipment_price,
                                                equipment_name: leg.equipment,
                                                charge_id: leg.service_charges[0]?.id,
                                                stops: leg.stops.map(stop => ({
                                                    "id":stop.id,
                                                    "charge_id":stop.service_charges && stop.service_charges.length > 0 ? stop.service_charges[0]?.id : null,
                                                    "is_pickup":stop.is_pickup,
                                                    "nofail":stop.nofail,
                                                    "nofail_price":stop.nofail_price
                                                }))
                                            }))
                                        }))
                                    }

                                    try {
                                        const data = await GraphqlService.SendMutation(mutation, {master});
                                        if (!data?.success)
                                            throw new Error(data?.message || 'Something went wrong');
                                        
                                        onChargesUpdated();
                                        setTimeout(() => {
                                            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
                                            setLoading(false);
                                        }, 500);
                                    } catch (ex) {
                                        setLoading(false);
                                        toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
                                        console.log('ex', ex.message);
                                    }

                                //   setServicesData({...servicesData, charges: service.charges, vo_id: null, master_id: null});
                              }} />

                              

            </ReactModal>

            <ReactModal className={'modal-fit modal-90'} isOpen={storageSelected.index != null}>
                   <StorageOrderModal chargesData={storageSelected.charges} data={storageSelected.storage} onClose={(evt) => {
                       setStorageSelected({index: null, storage: null, charges: []});
                       if (evt.reload)
                        onChargesUpdated();
                   }} />
            </ReactModal>
        </>
    );
};

export default PriceBreakdown;

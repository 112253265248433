import {Employee_Input, Employee_Input_Update} from "../employee";
import {ResultWithLink} from "../../customer_profile/customer_profile";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {Tax_Info_Input} from "../../customer/customer";

export const create_employee = (input:{employee: Employee_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($employee: employee_input) {
      create_employee(employee: $employee) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, { employee } as 
  Promise<ResultWithLink>;
}

export const update_employee = (input:{employee: Employee_Input_Update}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($employee: employee_input_update) {
      update_employee(employee: $employee) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, { employee } as 
  Promise<ResultWithLink>;
}

export const update_employee_tax_info = (input:{employee_id: number, tax_info: Tax_Info_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($employee_id: Int, $tax_info: tax_info_input) {
      update_employee_tax_info(employee_id: $employee_id, tax_info: $tax_info) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, { employee_id, tax_info } as 
  Promise<ResultWithLink>;
}

export const activate_or_deactivate_employee = (input:{employee_id: number, active: boolean}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($employee_id: Int, $active: Boolean) {
      activate_or_deactivate_employee(employee_id: $employee_id, active: $active) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, { employee_id, active } as 
  Promise<ResultWithLink>;
}
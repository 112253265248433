import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ICONS from '../../../assets/svg';
import { ChangeRequest, Leg, Location, VehicleOrder } from '../../classes/classes';
import VehicleOrderQueries from '../../graphql/query/VehicleOrder';
import Helper from '../../helper/Helper';
import { GraphqlService } from '../../services/graphql.service';
import LegTrip from '../LegTrip/LegTrip';
import Loading from '../Loading/Loading';
import Modal from 'react-modal';
import Status from '../Status/Status';
import LocationForm from '../NewOrder/LocationForm/LocationForm';
import Trip from './Trip/Trip';
import './VehicleOrderDispatch.scss';
import ReactModal from 'react-modal';
import DispatchLeg from '../DispatchLeg/DispatchLeg';
import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu';

const VehicleOrderDispatch = ({vehicleOrderId, legId, veloxOrderId, refresh, onRefreshRequested, onUndispatchLeg, onChangeScheduleClicked}) => {

    const [logsOpened, setLogsOpened] = useState(false);
    const { closeMenu, openMenu, ...menuProps } = useMenuState();
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [order, setOrder] = useState(new VehicleOrder());
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState(new Location());
    const orderID = useRef<number>();
    const veloxID = useRef<number>();
    const [shadowLeft, setShadowLeft] = useState(false);
    const [shadowRight, setShadowRight] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);

    

    const container = useRef();

    

    const [legInfo, setLegInfo] = useState<{ id: number, price: number, driver_pay: number, carrier: any, driver: any, hours: string, change?: boolean, leg: Leg }>({
        id: null,
        price: null,
        driver_pay: null,
        carrier: null,
        driver: null,
        hours: null,
        change: false,
        leg: null
    });
    
    const tripRef = useRef<HTMLDivElement>();

    async function load()
    {
        setLoading(true);
        try {
          const data = await GraphqlService.SendQuery(VehicleOrderQueries.GET_BY_ID, {order_id: vehicleOrderId});
        //   console.log(new VehicleOrder(data))
        if (orderID.current == null || vehicleOrderId != orderID.current)
            return;
          setOrder(new VehicleOrder(data));
          setLoading(false);
        } catch (ex) {
            console.log('ex', ex.message);
            setLoading(false);
        }
    }

    async function loadVeloxOrder()
    {
        setLoading(true);
        try {
          const data = await GraphqlService.SendQuery(VehicleOrderQueries.GET_BY_VELOX_ID, {velox_order_id: veloxOrderId});
        //   console.log(new VehicleOrder(data))
        // console.log(veloxID.current == null, vehicleOrderId != veloxID.current)
        if (veloxID.current == null || veloxOrderId != veloxID.current)
            return;
          setOrder(new VehicleOrder(data));
          
          setLoading(false);
        } catch (ex) {
            console.log('ex', ex.message);
            setLoading(false);
        }
    }

    useEffect(() => {

        orderID.current = vehicleOrderId;
        if (orderID.current != null)
            load();
        else if (!veloxOrderId)
        {
            setLoading(false);
            setOrder(new VehicleOrder());
        }
    }, [vehicleOrderId]);

    useEffect(() => {

        veloxID.current = veloxOrderId;
        if (veloxID.current != null)
            loadVeloxOrder();
        else if (!vehicleOrderId)
        {
            setLoading(false);
            setOrder(new VehicleOrder());
        }
    }, [veloxOrderId]);

    useEffect(() => {
        setTimeout(() => {
            
            updateTripContent();
        }, 1);
    }, [order]);

    useEffect(() => {
        if (refresh)
        {
            if (veloxOrderId)
                loadVeloxOrder();
            else if (vehicleOrderId)
                load();
        }
    }, [refresh]);

    function updateTripContent() {
        if (!tripRef.current) return;
                
        const left = tripRef.current.scrollLeft;
        const width = tripRef.current.scrollWidth;
        

        const parentWidth = tripRef.current.parentElement.scrollWidth;
        const diff = width - parentWidth - left;
        setShadowLeft(left != 0);
        setShadowRight(diff > 0);
    }



    function getChangeRequest(l: Leg) {
        const temp = l.velox_order.change_requests.filter(x => x.active && !x.accepted);
        const x = temp.sort((x, y) => x.time < y.time ? 1 : -1)[0];
        return x;
    }

    function getVeloxOrderStatus(l: Leg) {
        if (l.velox_order?.id && !l.velox_order.accepted)
        {
            if (l.velox_order.expired)
                return 'EXPIRED';
            if (l.velox_order.rejected)
                return 'REJECTED';

            return 'PENDING';
        
        }

        
        if (l.vehicle_order_id && l.velox_order.change_requests.length > 0)
        {
            
            const x = getChangeRequest(l);
            if (x)
            {
                if (x.expired)
                    return 'EXPIRED';
                if (x.rejected)
                    return 'REJECTED';

                return 'PENDING';
            }

            return '';
            
        }

        
        
        return '';
    }

    

    const getOrderTotal = () => order.legs.sumBy(x => x.equipment_price + x.stops.sumBy(y => y.nofail_price)) + order.charges.sumBy(x => x.amount);
    // return (
    //     <div className='row'>

           
    //         <div className='row col-12 mb-1 align-items-center'>
    //             <h4 className="fs-14">VEHICLE / LEG DETAILS</h4>
    //             <img src={ICONS.IconDots} alt="" />
    //         </div>

            

    //         {
    //         loading ?
    //         <div className="col-12 4">
    //             <Loading />
    //             <h4 className="font-9 text-center">Loading details for {vehicleOrderId ? 'V': 'VLX'}-{vehicleOrderId || veloxOrderId}...</h4>
    //         </div>
    //         :
    //         <>
    //         {order.id == null ?
    //             <div className="col-12 m-5">
    //                 <h4 className="fs-16 text-center">NO DATA TO DISPLAY</h4>
    //             </div>
    //         : <div className="row col-12">
    //             <div className='row col-12 mb-3'>
    //                 <div className="col-6 row  align-items-start">
    //                     <div>
    //                         <h5 className="font-9 mb-0-5">{order.vehicle.vin}</h5>
    //                         <h5 className="font-9 font-light">{order.vehicle.year} {order.vehicle.make} {order.vehicle.model}</h5>
    //                     </div>

    //                     {/* <Status statusName='IN PROGRESS' /> */}
    //                 </div>
                        
    //                 <div className="col-6 row align-items-start">
    //                     <div className="col-4">
    //                         <h5 className="font-9 mb-0-5">{order.master_order.customer.name}</h5>
    //                         <h5 className="font-9 font-light">M-{order.master_order.id} / V-{order.id}</h5>
    //                     </div>
    //                     <div className="col-8 d-flex justify-content-end align-items-start">
    //                         <button className='btn btn-outline font-9 mr-2'>{order.master_order.customer.corporate_client ? 'Corporate' : 'Private'}</button>
    //                         <div className='ml-1'>
    //                             <h5 className="font-9 mb-0-5 text-red">{Helper.FORMAT.USCurrency(order.master_order.price)}</h5>
    //                             <h5 className="font-9 text-red">Total</h5>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>

    //             <div className="col-12 d-flex no-wrap pb-0" style={{overflow: 'auto', paddingLeft: '.25rem', paddingRight: '.25rem'}}>
    //                 {order.legs.map((leg, i) => <LegTrip current={leg.id ? leg.id == legId : leg.velox_order_id == veloxOrderId} onStopClicked={(stop) => setLocation({...stop.location})} leg={leg} showStart={i > 0 && order.legs.length > 1} showEnd={i < order.legs.length - 1} />)}
    //             </div>
    //         </div>}</>}

    //         <Modal isOpen={location.id != null} >
    //                 <LocationForm location={location} onCancel={(evt) => setLocation(new Location())} onSubmit={(evt) => setLocation(new Location())} />
    //         </Modal>
    //     </div>);
    return <div ref={container} className='vehicle-order-dispatch-component'>
        <div className='row mb-3'>
            <h4 className="font-9 font-regular">Vehicle & Leg Details</h4>
            <h4 style={{opacity: (order.id && !loading) ? 1 : .3}} onClick={e => {

                if (!(order.id && !loading)) return;
    e.preventDefault();
    setAnchorPoint({ x: e.clientX, y: e.clientY });
    openMenu();
}} className="font-9 cursor-pointer"><i className="fas fa-ellipsis-h"></i></h4>
        </div>
    {loading ? <Loading /> :
    
    <>
        {order.id == null ? <div>
            <h4 className="font-9 text-center">No Order Selected</h4>
        </div> : <>
        <div className="d-flex justify-content-between align-items-start mb-3">
            <div className='col-4'>

                <h4 className="font-9">{order.vehicle.vin || '-'}</h4>
                <h4 className="font-9 font-normal">{order.vehicle.getName()}</h4>
            </div>
            
            <div className='col-6 d-flex align-items-start'>
                <Status statusName={'TBD'} />
                <div className='ml-3'>
                    <h4 className="font-9">{order.master_order.customer.name}</h4>
                    <h4 className="font-9 font-normal">M-{order.master_order.id} / V-{order.id}</h4>
                </div>
            </div>
            <div className='col-4 d-flex align-items-start'>
                <button className="btn btn-outline">{order.master_order.customer.terms?.toUpperCase() || 'NONE'}</button>
                <div className="ml-3">
                    <h4 className="font-9">{Helper.FORMAT.USCurrency(getOrderTotal())}</h4>
                    <h4 className="font-9 font-normal">Total</h4>
                </div>
            </div>
        </div>

        

        <div className={`d-flex trip-container ${shadowLeft ? 'shadow-left' : ''} ${shadowRight ? 'shadow-right' : ''}`} style={{overflowX: 'hidden', maxWidth: '100%'}}>
            <div ref={tripRef} onScroll={(evt) => {   
                
                updateTripContent();
                // setShadowLeft()

            }} className='trip-content d-flex' style={{overflowX: 'auto'}}>
               {order.legs.map((l, i) => <Trip changeRequestLink={getChangeRequest(l)?.weblink} status={getVeloxOrderStatus(l)} onChangeScheduleClicked={onChangeScheduleClicked} onUndispatchLeg={onUndispatchLeg} onSelectDriverClicked={(evt) => {
                   setLegInfo({...evt, vehicle: order?.vehicle});
                   setModalOpened(true)
               }} current={veloxOrderId != null ? l.velox_order_id == veloxOrderId : legId == l.id} data={l} last={i == order.legs.length - 1} />)}
            </div>
            {/* <Trip />
            <Trip /> */}
        </div>
    
        </>
            }
    </>
    }

    <ReactModal isOpen={modalOpened} className={'modal-1016'}>
        <DispatchLeg data={legInfo} tab={'LEG'} onCancel={() => setModalOpened(false)} onSubmitted={() => {
                        setModalOpened(false);
                        onRefreshRequested()
                    }} />
    </ReactModal>

    <ControlledMenu boundingBoxRef={container} {...menuProps} anchorPoint={anchorPoint}
        onClose={() => closeMenu()}>
        <MenuItem onClick={(evt) => setLogsOpened(true)} className={'my-menuitem'}><img src={ICONS.IconClock} /> See Logs</MenuItem>
        <MenuItem onClick={(evt) => Helper.Navigation.NavigateTo(`/order/${order.master_order.id}`)} className={'my-menuitem'}><img src={ICONS.IconDetails} /> See Master Order Details</MenuItem>
    </ControlledMenu>


    <ReactModal isOpen={logsOpened}>
        <div className="flex-1-container">
            <div className="row mb-3">
                <h4 className="font-12">Logs: {order.master_order.logs.length}</h4>
                <h4 onClick={(evt) => setLogsOpened(false)} className="font-12 cursor-pointer"><i className='fas fa-times'></i></h4>
            </div>
            <hr className='mb-1' />
            <div className="flex-1-container">
                    <ul>
                        {order.master_order.logs.map((log, index) =>  <li key={'log-' + index} className='mb-1 pt-2 d-flex' style={{borderTop: index == 0 ? 'none' : '1px solid #eee'}}>
                            <h6 className="font-11 mr-2">{index + 1}.)</h6>
                            <div className="col-12">
                                <p className='font-11'>{log.message || '-'}</p>
                                <p className='font-11 my-1 font-bold'>{Helper.FORMAT.USDate(log.time)}</p>
                                <p className='font-11 text-blue-light font-bold'>User #{log.user_id}</p>
                            </div>
                        </li>)}
                    </ul>
                </div>
        </div>
    </ReactModal>
    </div>
}

export default VehicleOrderDispatch;
import React, {useEffect, useRef, useState} from "react";
import {Circle, GoogleApiWrapper, Map, Marker, Polyline} from 'google-maps-react';
import environments from "../../../../environments/environments";
import googleMapStyles from "./QuoteMap.style";
import Helper from "../../../helper/Helper";
// const SymbolPath = window.google.maps.SymbolPath;
import { MAP } from 'react-google-maps/lib/constants';
import moment from "moment";
import DirectionsRenderer from "react-google-maps/lib/components/DirectionsRenderer";

// SymbolPath.


const mapStyles = {
    width: '100%',
    height: '100%'
};


// (Map as any).defaultProps = googleMapStyles;
const QuoteMap = (props) => {




    const [distance, setDistance] = useState('');
    const [time, setTime] = useState('');

    // const contextTypes = { [MAP]: any };
    const [directions, setDirecctions] = useState(null);
    const map = useRef<any>();
    const [coords, setCords] = useState([]);
    useEffect(() => {
        convertAddresses();

        // console.log('here inside x2');
        // alert('here')
    }, [props.pickupLocation, props.dropoffLocation]);



    function getDistance(from, to) {
        let directionsService = new google.maps.DirectionsService();
        let directionsRenderer = new google.maps.DirectionsRenderer();
        // directionsRenderer.setMap(map.current); // Existing map object displays directions
        // Create route from existing points used for markers
        directionsRenderer.setMap(map.current?.map);
        const route = {
            origin: from,
            destination: to,
            travelMode: 'DRIVING',
            waypoints: [],
                    optimizeWaypoints: true,
        } as any


        var trafficLayer = new google.maps.TrafficLayer();
        trafficLayer.setMap(map?.current?.map);

        directionsService.route(route,
            function(response, status) { // anonymous function to capture directions
            if (status !== 'OK') {
                window.alert('Directions request failed due to ' + status);
                return;
            } else {
                
                var directionsData = response.routes[0].legs[0]; // Get data about the mapped route
                if (!directionsData) {
                window.alert('Directions request failed');
                return;
                }
                else {
                    setTime(directionsData.duration.text);
                    setDistance(directionsData.distance.text);
                    directionsRenderer.setDirections(response);
                    // document.getElementById('msg').innerHTML += " Driving distance is " + directionsData.distance.text + " (" + directionsData.duration.text + ").";
                    console.log(directionsData);
                }
            }
            });
    }

    async function convertAddresses()
    {
        try
        {
            const address1 = await Helper.Maps.AddresToLatLng(props.pickupLocation);
            const address2 = await Helper.Maps.AddresToLatLng(props.dropoffLocation);
            console.log([address1, address2]);
            // setCords([address1, address2]);
            const bounds = new google.maps.LatLngBounds();

            bounds.extend(address1);
            bounds.extend(address2);

            map.current?.map?.fitBounds(bounds);

            getDistance(address1, address2);
        }
        catch (ex)
        {
            console.log('error', ex.message);
        }
    }


    // console.log('here inside');

    // return <h2>{moment(new Date()).format('hh:mm:ss A')}</h2>

    function renderMap()
    {
        return <>
        <div className="row col-12 mb-3">
            <div className="col-6">
                
                <h5 className="font-12 mb-1">Distance</h5>
                <h5 className="font-11 font-medium">{distance}</h5>
            </div>
            <div className="col-6">
                <h5 className="font-12 mb-1">Duration</h5>
                <h5 className="font-11 font-medium">{time}</h5>
            </div>
        </div>
        <Map
            ref={map} google={props.google}
            styles={googleMapStyles}
            style={mapStyles}
            zoomControl={true}
            scrollwheel={false}
            fullscreenControl={false}
            streetViewControl={false}
            mapTypeControl={false}
            centerAroundCurrentLocation={false}
        >
            {/*   MAP CODE HERE     */}

            {/* {
                <Marker
                    position={coords[0]}
                    icon={{
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 10,
                        fillColor: "#74C336",
                        fillOpacity: 1,
                        strokeWeight: 0
                    }}

                    zIndex={5}
                />
            } */}
            {/*74C336*/}
            {/* {
                <Marker
                    position={coords[1]}
                    icon={{
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 10,
                        fillColor: "#C50532",
                        fillOpacity: 1,
                        strokeWeight: 0
                    }}
                    zIndex={5}
                />
            } */}
            
            
            {/* {coords.length >0 &&

            <Polyline
                path={coords}
                key={1}
                strokeOpacity={0}
                icons={[{
                    icon: {
                        path: 'M 0,-1 0,1',
                        strokeOpacity: 1,
                        strokeColor: '#112C55',
                        scale: 3
                    },
                    offset: '0',
                    repeat: '15px'
                }]}
                editable={false}
            />} */}


        </Map></>
    }

    return renderMap();

};

export default  GoogleApiWrapper({
    apiKey: environments.GOOGLE_API
})(React.memo(QuoteMap));
import gql from "graphql-tag";

const ADD_NEW_PAYEE = gql`
    mutation($name: String!, $tax_info:Tax_input, $address: address_input){
        create_payee(name:$name, tax_info:$tax_info, address:$address){
            id
            message
            success
        }
    }
`;


const PayeeMutations = {
    ADD_NEW_PAYEE
}

export default PayeeMutations;

import './DriversAgingTable.scss'
import {useEffect, useRef, useState} from "react";
import Searchbar from "../Searchbar/Searchbar";
import AgingTable from "../AgingTable/AgingTable";
import {GraphqlService} from "../../services/graphql.service";
import CarrierQueries from "../../graphql/query/Carrier";
import gql from 'graphql-tag';

const DriversAgingTable = ({onDriverSelected, showHeader = true}) => {
    const ref = useRef();
    const [tab, setTag] = useState('Aging');


    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    async function load()
    {
        try {

            const query = gql`
                query($showempty:Boolean){
  get_driver_aging_for_carrier(showempty:$showempty){
    late
    name
    picked_up
    orders_dispatched
    valid
    driver_id
    completed
  }
}
            `;
            setLoading(true);


            const data = await GraphqlService.SendQuery(query, {showempty: false})
            console.log('driver_data', data);
            // console.log(JSON.stringify(data));
            setRows(data);
            setLoading(false);
        }
        catch (ex)
        {
            setLoading(false);
            console.log(ex.message);
        }
    }

    useEffect(() => {
        load().then();
        // console.log('here');
    }, []);

    return(
        <>
            <div className="row flex-1-container">
                <div className={'mb-3'}>
                    {showHeader && <div className="col-12 mb-3">
                        <p className="font-medium font-12">Drivers</p>
                    </div>}


                    {showHeader && <div className="col-12 d-flex justify-content-between align-items-center">
                        <p className="font-medium font-12">{rows.length} active drivers</p>

                        <Searchbar reference={ref}
                                   background={'#F3F6F8'}
                                   placeholder={'Search for carrier'} value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)}/>
                    </div>}

                </div>
                {
                    tab == 'Aging' ? <AgingTable excludeDrivers menuOptions={[]} preventSelected={!showHeader} onCarrierClicked={(evt) => onDriverSelected(evt)} loading={loading} data={rows.filter(x => x.name.toLowerCase().trim().includes(searchTerm.toLowerCase().trim()))} /> : null
                }
            </div>
        </>
    );
}

export default DriversAgingTable;

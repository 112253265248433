import {gql} from "apollo-boost";
import {Dispatch_Response, Leg} from "../leg";
import {GraphqlService} from "../../../../graphql.service";

export const get_all_dispatching = (input:{}) : Promise<Dispatch_Response[]> => {
  let query = gql`
    query {
      get_all_dispatching {
        driver {
          id
          name
          username
          equipment
          carrier_code
          photos {
            uid
            name
          }
          contacts {
            type
            title
            value
          }
        }
        leg_id
        price
        origin {
          nofail
          address
          scheduled
          time_completed
          completed
          contacts {
            type
            title
            value
          }
        }
        carrier {
          id
          name
          code
          internal_note
          active
          multi_carrier
          contact {
            type
            title
            value
          }
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          payment_term {
            carrier_id
            broker_id
            terms
          }
        }
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
        }
        customer {
          id
        }
        equipment
        destination {
          nofail
          address
          scheduled
          time_completed
          completed
          contacts {
            type
            title
            value
          }
        }
        velox_order_id
        master_order_id
        vehicle_order_id
        velox_order {
          id
          accepted
          rejected
          canceled
          expired
          expires
        }
        pending_change_request
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Dispatch_Response[]>;
}

export const get_leg_history = (input:{vehicle_search: string, pickup_search: string, dropoff_search: string, customer_search: string, leg_id_search: string, master_order_id_search: string, velox_id_search: string}) : Promise<Dispatch_Response[]> => {
  let query = gql`
    query($vehicle_search: String, $pickup_search: String, $dropoff_search: String, $customer_search: String, $leg_id_search: String, $master_order_id_search: String, $velox_id_search: String) {
      get_leg_history(vehicle_search: $vehicle_search, pickup_search: $pickup_search, dropoff_search: $dropoff_search, customer_search: $customer_search, leg_id_search: $leg_id_search, master_order_id_search: $master_order_id_search, velox_id_search: $velox_id_search) {
        driver {
          id
          name
          username
          equipment
          carrier_code
          photos {
            uid
            name
          }
          contacts {
            type
            title
            value
          }
        }
        leg_id
        price
        origin {
          nofail
          address
          scheduled
          time_completed
          completed
          contacts {
            type
            title
            value
          }
        }
        carrier {
          id
          name
          code
          internal_note
          active
          multi_carrier
          contact {
            type
            title
            value
          }
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          payment_term {
            carrier_id
            broker_id
            terms
          }
        }
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
        }
        customer {
          id
        }
        equipment
        destination {
          nofail
          address
          scheduled
          time_completed
          completed
          contacts {
            type
            title
            value
          }
        }
        velox_order_id
        master_order_id
        vehicle_order_id
        velox_order {
          id
          accepted
          rejected
          canceled
          expired
          expires
        }
        pending_change_request
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vehicle_search, pickup_search, dropoff_search, customer_search, leg_id_search, master_order_id_search, velox_id_search} as 
  Promise<Dispatch_Response[]>;
}

export const get_leg_history_by_customer = (input:{customer_id: number, vehicle_search: string, pickup_search: string, dropoff_search: string, contact_search: string, leg_id_search: string, master_order_id_search: string, velox_id_search: string}) : Promise<Dispatch_Response[]> => {
  let query = gql`
    query($customer_id: Int, $vehicle_search: String, $pickup_search: String, $dropoff_search: String, $contact_search: String, $leg_id_search: String, $master_order_id_search: String, $velox_id_search: String) {
      get_leg_history_by_customer(customer_id: $customer_id, vehicle_search: $vehicle_search, pickup_search: $pickup_search, dropoff_search: $dropoff_search, contact_search: $contact_search, leg_id_search: $leg_id_search, master_order_id_search: $master_order_id_search, velox_id_search: $velox_id_search) {
        driver {
          id
          name
          username
          equipment
          carrier_code
          photos {
            uid
            name
          }
          contacts {
            type
            title
            value
          }
        }
        leg_id
        price
        origin {
          nofail
          address
          scheduled
          time_completed
          completed
          contacts {
            type
            title
            value
          }
        }
        carrier {
          id
          name
          code
          internal_note
          active
          multi_carrier
          contact {
            type
            title
            value
          }
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          payment_term {
            carrier_id
            broker_id
            terms
          }
        }
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
        }
        customer {
          id
        }
        equipment
        destination {
          nofail
          address
          scheduled
          time_completed
          completed
          contacts {
            type
            title
            value
          }
        }
        velox_order_id
        master_order_id
        vehicle_order_id
        velox_order {
          id
          accepted
          rejected
          canceled
          expired
          expires
        }
        pending_change_request
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vehicle_search, pickup_search, dropoff_search, contact_search, leg_id_search, master_order_id_search, velox_id_search} as 
  Promise<Dispatch_Response[]>;
}

export const leg_by_id = (input:{leg_id: number}) : Promise<Leg> => {
  let query = gql`
    query(leg_id: Int) {
      leg_by_id($leg_id: leg_id) {
        id
        vehicle_order_id
        vehicle_order {
          id
        }
        time
        status
        time_completed
        completed
        canceled
        equipment
        velox_order_id
        stops {
          id
          location {
            id
            customer_id
            title
            internal_note
            driver_note
            timezoneName
          }
          is_pickup
          time_scheduled
          time_completed
          completed
          started
          canceled
          nofail
          contacts {
            id
            email
            verified
            name
            role
          }
          service_charges {
            id
            amount
            tax_amount
            base_amount
            name
            note
            voided
            amount_remaining
          }
          time_frame {
            end
            start
          }
          leg_id
          log {
            user_id
            message
            time
            vehicle_order_id
            leg_id
            stop_id
          }
        }
        service_charges {
          id
          amount
          tax_amount
          base_amount
          name
          note
          voided
          amount_remaining
        }
        driver {
          id
          name
          username
          equipment
          carrier_code
        }
        carrier {
          id
        }
        carrier_pay
        driver_pay
        remaining_carrier_receivable
        remaining_driver_payable
        velox_order {
          id
          accepted
          rejected
          canceled
          expired
          expires
        }
        pending_change_request
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {leg_id} as 
  Promise<Leg>;
}
import React,{useEffect, useRef, useState} from 'react';
import { useParams } from 'react-router';
import { PendingInspection, Vehicle } from '../../../../classes/classes';
import Helper from '../../../../helper/Helper';
import { GraphqlService } from '../../../../services/graphql.service';
import Content from '../../components/Content/Content';
import Toolbar from '../../components/Toolbar/Toolbar';
import VehicleDetailsForm from '../../components/VehicleDetailsForm/VehicleDetailsForm';
import InspectionObservable from '../../observables/InspectionObservable';
import {gql} from 'apollo-boost';
import { useToasts } from 'react-toast-notifications';

const VehicleDetails = () => {

    const [originalData, setOriginalData] = useState(new PendingInspection());
    const [currentData, setCurrentData] = useState(new PendingInspection());
    const originalDataRef = useRef<PendingInspection>(new PendingInspection());
    const toast = useToasts();

    const {token} = useParams<any>();

    const forwardLink = `/pending-inspection/${token}/vehicle-damages`; 

    const isVehicleDiferent = (original, neworiginal) => {
        let v = new Vehicle(original.vehicle_data);
        delete v.time;
        delete v.time_created;

        let v2 = new Vehicle(neworiginal.vehicle_data);
        delete v2.time;
        delete v2.time_created;


        return JSON.stringify(v) != JSON.stringify(v2);
    }



    async function updateVehicle() {
        try {
            const mutation = gql`
                mutation($vehicle_data: vehicle_input, $pending_inspection_id: Int){
                    update_inspection_vehicle_data(vehicle_data: $vehicle_data, pending_inspection_id: $pending_inspection_id){
                        id
                        time
                    }
                }
            `

            const vehicle_data = new Vehicle(currentData.vehicle_data);
            delete vehicle_data.not_running;
            delete vehicle_data.time;
            delete vehicle_data.time_created;
            delete vehicle_data.user_id;

            const variables = {vehicle_data, pending_inspection_id: currentData.pending_inspection_id};

            const data = await GraphqlService.SendMutation(mutation, variables);
            //console.log(data);
            return true;
        } catch (ex) {
            alert('ex: ' + ex.message);
            return false;
        }
    }

    useEffect(() => {
        originalDataRef.current.vehicle_data = originalData.vehicle_data;
        originalDataRef.current.pending_inspection_id = originalData.pending_inspection_id;
    }, [originalData]);

    useEffect(() => {
        let query = InspectionObservable.GetObservable(token);
        
        let subs = query.onResults.subscribe(result => {
            if (result.error){
                toast.addToast(result.error.message, {appearance: 'error', autoDismiss: true})
            }
            else
            {
                let o = new PendingInspection(result.data);
                if (isVehicleDiferent(originalDataRef.current, o))
                {
                    setCurrentData(new PendingInspection(result.data));
                    
                    setOriginalData(o);
                    if (originalDataRef.current.pending_inspection_id != null)
                        toast.addToast(`User #${o.vehicle_data.user_id} updated vehicle data`, {appearance: 'warning', autoDismiss: true});
                }
            }
        });

        return () => {
            subs.unsubscribe();
            query.stopPolling();
        }
    }, []);

    return <>
                <Toolbar vehicle={originalData.vehicle_data} />

                <Content>
                    <div className="mb-3">
                        <h4 className="font-11">Edit Vehicle Details</h4>
                    </div>
                    <div className='flex-1-container'>
                        {/* <div className=""></div> */}
                        {/*<h4 className="font-14 text-center flex-1-container justify-content-center align-items-center">CONTENT</h4>*/}
                        <VehicleDetailsForm  data={currentData.vehicle_data} onCancel={()=>console.log('cancel')} onSubmit={(data)=> setCurrentData({...currentData, vehicle_data: data})}/>
                    </div>

                    <div className="row justify-content-end mt-3">
                        <button onClick={async (evt) => {
                            if (isVehicleDiferent(originalDataRef.current, currentData))
                            {
                                let result = await updateVehicle();
                                console.log('result', result);
                                if (!result)
                                    return;
                            }
                            Helper.Navigation.NavigateTo(forwardLink)
                        }} className="btn btn-blue-light col-6">NEXT: DAMAGES</button>
                    </div>
                </Content>
            </>
}

export default VehicleDetails;

import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {
  Carrier_Input, Velox_Carrier,
  Velox_Carrier_Insurance_Input,
  Velox_Carrier_Registration_Input, Velox_Carrier_Tax_Info_Input,
  Velox_Carrier_Update_Input
} from "../carrier";
import {StandardResult} from "../../leg/leg";
import {Upload} from "../../upload";
import {ResultWithLink} from "../../customer_profile/customer_profile";

export const update_velox_carrier_information = (input:{carrier: Velox_Carrier_Update_Input}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($carrier: velox_carrier_update_input) {
        update_velox_carrier_information(carrier: $carrier) {
            success
            message
        }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {carrier} as 
  Promise<StandardResult>;
}

export const update_velox_carrier_registration = (input:{registration: Velox_Carrier_Registration_Input}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($registration: velox_carrier_registration_input) {
        update_velox_carrier_registration(registration: $registration) {
            id
            message
            success
        }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {registration} as 
  Promise<StandardResult>;
}

export const update_velox_carrier_insurance = (input:{insurance: Velox_Carrier_Insurance_Input}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($insurance: velox_carrier_insurance_input) {
        update_velox_carrier_insurance(insurance: $insurance) {
            id
            message
            success
        }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {insurance} as 
  Promise<StandardResult>;
}

export const generate_carrier_report = (input:{carrier_id: number, velox_ids: number[], title: string}) : Promise<string> => {
  let mutation = gql`
    mutation($carrier_id: Int, $velox_ids: Int[], $title: String) {
      generate_carrier_report(carrier_id: $carrier_id, velox_ids: $velox_ids, title: $title)
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {carrier_id, velox_ids, title} as 
  Promise<string>;
}

export const create_carrier = (input:{carrier: Carrier_Input}) : Promise<Velox_Carrier> => {
  let mutation = gql`
    mutation($carrier: carrier_input) {
        create_carrier(carrier: $carrier) {
            id
            name
            code
        }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {carrier} as 
  Promise<Velox_Carrier>;
}

export const upload_velox_carrier_registration_file = (input:{carrier_id: number, registration_file: Upload}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($carrier_id: Int, $file: Upload) {
      upload_velox_carrier_registration_file(carrier_id: $carrier_id, registration_file: $file) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {carrier_id, registration_file} as 
  Promise<ResultWithLink>;
}

export const remove_velox_carrier_registration_file = (input:{carrier_id: number, uid: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($carrier_id: Int, $uid: String) {
      remove_velox_carrier_registration_file(carrier_id: $carrier_id, uid: $uid) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {carrier_id, uid} as 
  Promise<ResultWithLink>;
}

export const upload_velox_carrier_insurance_file = (input:{carrier_id: number, insurance_file: Upload}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($carrier_id: Int, $file: Upload) {
      upload_velox_carrier_insurance_file(carrier_id: $carrier_id, insurance_file: $file) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {carrier_id, insurance_file} as 
  Promise<ResultWithLink>;
}

export const remove_velox_carrier_insurance_file = (input:{carrier_id: number, uid: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($carrier_id:Int, $uid:String) {
      remove_velox_carrier_insurance_file(carrier_id: $carrier_id, uid: $uid) {
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {carrier_id, uid} as 
  Promise<ResultWithLink>;
}

export const update_velox_carrier_tax_info = (input:{tax_information: Velox_Carrier_Tax_Info_Input}) : Promise<StandardResult> => {
  let mutation = gql`
  mutation($tax_information: velox_carrier_tax_info_input) {
    update_velox_carrier_tax_info(tax_information: $tax_information) {
      id
      message
      success
    }
  }
  `;

  return GraphqlService.SendQuery(mutation, input) as  //, {tax_information} as 
  Promise<StandardResult>;
}

export const upload_velox_carrier_tax_info_file = (input:{carrier_id: number, tax_info_file: Upload}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($carrier_id: Int, $file: Upload) {
      upload_velox_carrier_tax_info_file(carrier_id: $carrier_id, tax_info_file: $file) {
        id
        link
        message
        success
      }
    }
  `;

  return GraphqlService.SendQuery(mutation, input) as  //, {carrier_id, tax_info_file} as 
  Promise<ResultWithLink>;
}

export const remove_velox_carrier_tax_info_file = (input:{carrier_id: number, uid: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($carrier_id: Int, $uid: String) {
      remove_velox_carrier_tax_info_file(carrier_id: $carrier_id, uid: $uid) {
        id
        message
        success
      }
    }
  `;

  return GraphqlService.SendQuery(mutation, input) as  //, {carrier_id, uid} as 
  Promise<ResultWithLink>;
}

import gql from "graphql-tag";

const TO_CARRIER = gql`
    mutation($legId: Int,$legPrice: Float, $carrierId: Int, $expires: String!){
        dispatch_to_carrier(legid:$legId, legprice:$legPrice, carrier_id:$carrierId, needs_confirmation:true, expires: $expires){
            id
            message
            success
        }
    }
`;

const TO_DRIVER = gql`
    mutation($legId: Int,$legPrice: Float, $driverId:Int!, $driverPay:Float){
        dispatch_to_driver(legid:$legId, legprice:$legPrice, driver_id:$driverId, driver_pay:$driverPay){
            id
            message
            success
        }
    }
`;

const DispatchMutations = {
    TO_CARRIER,
    TO_DRIVER
}

export default DispatchMutations;
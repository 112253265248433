import React, {useState, useRef, useEffect} from 'react'
import './Leg.scss'
import {Leg as L, VehicleOrder} from "../../classes/classes";
import ICONS from "../../../assets/svg";
import MenuOptionItem from "../MenuOptionItem/MenuOptionItem";
import MenuOption from "../MenuOption/MenuOption";
import {ControlledMenu, MenuItem} from "@szhsin/react-menu";
import Helper from "../../helper/Helper";
import {GraphqlService} from "../../services/graphql.service";
import InspectionMutations from "../../graphql/mutation/Inspection";
import InspectionQueries from "../../graphql/query/Inspection";
import ReactModal from "react-modal";
import Loading from "../Loading/Loading";
import useLoading from '../../hooks/useLoading/useLoading';
import useMessageBox from '../../hooks/useMessageBox/useMessageBox';
import Trip from '../VehicleOrderDispatch/Trip/Trip';
import IMenuOption from '../Table/models/IMenuOption';
import SplitRoundtripLeg from '../SplitRoundtripLeg/SplitRoundtripLeg';
import DispatchLeg from '../DispatchLeg/DispatchLeg';

type LegProps = {
    legs: L[],
    onEditLegBtnClicked: () => any,
    onRountripLegBtnClicked: (data?: {legNumber: number, leg: L}) => any,
    parentRef: any,
    onDeleteLegClicked,
    onMoveLegClicked: (leg: L) => any
}

/*
    handleEditLegBtn(idx, leg);
handleEditLegBtn(idx, leg, true)
props.onRountripLegBtnClicked({legNumber: idx, leg});
 props.onDeleteLegClicked(leg?.id);
*/

const Leg = (props: LegProps) => {

    const [splitOpen, setSplitOpen] = useState(false);
    const [selectedLeg, setSelectedLeg] = useState(new L());
    const [editOpen, setEditOpen] = useState(false);
    const [roundtripOpen, setRoundtripOpen] = useState(false);
    
    const [legs, setLegs] = useState(props.legs);
    // const messageBox = useMessageBox();
    const loading = useLoading();

    useEffect(() => {
        setLegs(props.legs);
    }, [props.legs])


    async function seeInspection(stop_id: number)
    {
        loading.open('Getting Inspection...');
        try {

            const link = await GraphqlService.SendQuery(InspectionQueries.GET_LINK, {stop_id});
            
            loading.close();
            Object.assign(document.createElement('a'), {
                target: '_blank',
                href: link,
            }).click();
        }
        catch (ex)
        {
            loading.close();
            console.log(ex);
        }

    }



    // const handleEditLegBtn = (legNumber, leg, split  = false) => {
    //     props.onEditLegBtnClicked({legNumber, leg, split});
    // }

    const [openedMenu, setOpenedMenu] = useState(false);
    const [anchorPoint, setAnchorPoint] = useState({x: 0, y: 0});
    const [legIndex, setLegIndex] = useState(null);



    const rightClickOptions: IMenuOption[] = [
        {
            label: 'Edit Leg',
            icon: ICONS.IconEdit,
            action: (leg) => {
                setSelectedLeg(leg);
                setEditOpen(true);
            }
        },
        {
            label: 'Move Leg Into New Master',
            icon: ICONS.IconPlus,
            action: (leg) => {
                props.onMoveLegClicked(leg);
            }
        },
        {
            label: 'Split Leg',
            icon: ICONS.CUSTOM,
            action: (leg: L) => {
                
                setSelectedLeg(leg);
                setSplitOpen(true);
                // props.handleEditLegBtn(index, leg, true)
            }
        },
        {
            label: 'Roundtrip Leg',
            icon: ICONS.IconMoveLanes,
            action: (leg) => {
                // props.onRountripLegBtnClicked({legNumber: index, leg});
                setSelectedLeg(leg);
                setRoundtripOpen(true);
            }
        },
        {
            label: 'Delete Leg',
            icon: ICONS.IconDelete,
            action: (leg) => {
                
                 props.onDeleteLegClicked(leg?.id);
            }
        }
    ];


    return (
        <>

            <div className={'row mt-5 align-items-center'}>
                <h4 className={'font-12'}>Moves</h4>
                <h4 onClick={(evt) => props.onEditLegBtnClicked()} className="btn bg-transparent no-select text-light-blue rounded-pill d-flex align-items-center">ADD NEW LEG <img className={'m-0 p-0'} src={ICONS.IconPlus} /></h4>
            </div>
            <hr className='mb-2' />
            <div className="col-12" style={{overflowX: 'auto'}}>
                <div className='d-flex' style={{width: 'fit-content'}}>
                    {legs?.map((leg, idx) => <Trip  onSelectDriverClicked={(evt) => {
                        // setSelectedLeg({id
                        
                        setSelectedLeg(evt);
                        setEditOpen(true);
                    }} rightClickOptions={rightClickOptions} data={leg} last={idx == legs.length - 1} />)}
                </div>
            </div>

            {/* {(splitOpen || roundtripOpen) && <SplitRoundtripLeg roundtrip={roundtripOpen} onLegSplitted={(legs) => {
                setSplitOpen(false);
                setRoundtripOpen(false);
                
            }} leg={selectedLeg} onClose={evt => {
                setSplitOpen(false);
                setRoundtripOpen(false)
            }}/>} */}

            <ReactModal className={'modal-large'} isOpen={editOpen}>
                <DispatchLeg tab={'LEG'} data={{id: selectedLeg?.id}} onCancel={() => setEditOpen(false)} onSubmitted={() => {
                    setEditOpen(false);
                }} />
            </ReactModal>
           
            
            
        </>
    )
}

export default React.memo(Leg)

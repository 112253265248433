import FormGroup from "../FormGroup/FormGroup";
import {useEffect, useRef, useState} from "react";
import Toggle from "../Toggle/Toggle";
import Helper from "../../helper/Helper";
import "./CustomerForm.scss";
import { GraphqlService } from "../../services/graphql.service";
import CustomerMutations from "../../graphql/mutation/Customer";
import AddressSearch from '../Google/AddressSearch';
import DropdownCountry from "../DropdownCountry/DropdownCountry";
import ICONS from "../../../assets/svg";
import LocationFormWorkHours from "../LocationFormWorkHours/LocationFormWorkHours";
import {Address, CustomerList, Location} from "../../classes/classes";
import {getAllCustomersList} from "../../redux/actions/Customer.action";
import {useToasts} from "react-toast-notifications";
import useLoading from "../../hooks/useLoading/useLoading";
import {useDispatch} from "react-redux";
import { gql } from "apollo-boost";
import { getByPlaceholderText } from "@testing-library/react";
import { Mutations } from "../../services/endpoints/mutations";

class Contact {
    type: string = '';
    title: string = 'Mobile';
    value: string = '';
    unsubscribed: boolean = false;

    constructor (x?: Contact)
    {
        if (x)
        {
            this.type = x.type;
            this.title = x.title;
            this.value = x.value;
            this.unsubscribed = x.unsubscribed;
        }
    }
}

class TaxNumber
{
    type: string = '';
    value: string = '';

    constructor(props?: TaxNumber)
    {
        if (props)
        {
            this.type = props.type;
            this.value = props.value;
        }
    }
}

class Customer {
    id: number = null;
    key: string = '';
    name: string = '';
    terms: string = '';
    internal_note: string = '';
    corporate_client: boolean = false;
    customer_profile: CustomerProfile = new CustomerProfile();
    location: Location = new Location();
    tax_number: TaxNumber = new TaxNumber();
    default_invoice_email: string = '';

    constructor(x?: Customer)
    {
        if (x)
        {
            this.key = x.key;
            this.name = x.name;
            this.terms = x.terms;
            this.internal_note = x.internal_note;
            this.corporate_client = x.corporate_client;
            this.customer_profile = new CustomerProfile(x.customer_profile);
            this.location = new Location(x.location);
            this.tax_number = new TaxNumber(x.tax_number);
            this.default_invoice_email = x.default_invoice_email || '';
            this.id = x.id;
        }
    }
}

class CustomerProfile {
    name: string = '';
    email: string = '';
    title: string = '';
    contact: Contact[] = [];

    constructor (x?: CustomerProfile)
    {
        if (x)
        {
            this.name = x.name;
            this.email = x.email;
            this.title = x.title;
            this.contact = !x.contact ? [] : x.contact.map(z => new Contact(z));
        }
    }
}

const countries = ['United States','Canada'];
const contactType = ['Phone Number', 'Email'];
const terms = ['COP', 'COD', 'Term 15', 'Term 30', 'Term 60'];
const states = Helper.States;



const CustomerForm = ({currentCustomer = undefined, onCancel, onSubmit}) => {

    const toast = useToasts();
    const loading = useLoading();
    const dispatch = useDispatch();

    const [address, setAddress] = useState(new Address());
    const [location, setLocation] = useState(new Location());
    const [customer, setCustomer] = useState(new Customer());
    const [editTaxID, setEditTaxID] = useState('');
    const [createOnlineProfile, setCreateOnlineProfile] = useState(true);


    const isValid = () => {
        return customer.name.trim() != '' && customer.terms.trim() != '' &&
        (customer.id ? true: customer?.customer_profile?.name?.trim() != ''
        && (!createOnlineProfile || Helper.Validators.IsValidEmail(customer?.customer_profile?.email)) && customer?.customer_profile?.title.trim() != ''
        && !customer?.customer_profile?.contact.some(x => x.title.trim() == '' || x.type == '' ? true : (x.type == 'email' ? !Helper.Validators.IsValidEmail(x.value) : !Helper.Validators.IsValidUSPhone(x.value)) ));
    }

    useEffect(() => {
        if (currentCustomer != undefined) {
            const x = new Customer(currentCustomer);


            setEditTaxID(currentCustomer.tax_number?.value);
            x.tax_number.value = '';

            setCustomer(x);
            setLocation(currentCustomer?.location);
            setAddress(new Address(currentCustomer.billing_address));
        }
    }, [currentCustomer]);

    const handleRemoveContact = index => {
        const temp = [...customer?.customer_profile?.contact];
        temp.splice(index, 1);

        setCustomer({...customer, customer_profile: {...customer?.customer_profile, contact: temp} });
    }

    const handleUpdateNotificationContact = async (index) => {
        const temp = [...customer?.customer_profile?.contact];
        const contact = temp[index];

        try {
            loading.open('loading...');
            let data = await GraphqlService.SendMutation(contact.unsubscribed ? CustomerMutations.SUBSCRIBE_CONTACT : CustomerMutations.UNSUBSCRIBE_CONTACT, {value: contact.value});
            loading.close();

            if (data.success) {
                toast.addToast('Notifications updated', {appearance: 'success', autoDismiss: true});

                contact.unsubscribed = !contact.unsubscribed;
                temp[index] = contact;
                setCustomer({...customer, customer_profile: {...customer?.customer_profile, contact: temp} });
            } else {
                toast.addToast('Error to update notifications', {appearance: 'error', autoDismiss: true});
            }
        } catch (ex) {
            loading.close();
            toast.addToast(ex, {appearance: 'error', autoDismiss: true});
        }
    }
    const getInputsByType = (type: string) => {
        let j = -1;
        let title = type == 'email' ? 'Email' : 'Phone';
        return customer?.customer_profile?.contact.map((x, index) =>  {
            if (x.type != type)
                return <></>;
            j++;
            return <div className="p-2 mb-3 rounded" style={{border: '1px solid #ccc'}}>
                <div className="d-flex align-items-start">
                    <FormGroup name={'title'}
                                                    value={x.title}
                                                    placeholder={'-- Select --'}
                                                    label={'Title'}
                                                    options={{data: type != 'email' ? ['Mobile', 'Office', 'Home', 'Other'] : ['Work', 'Personal', 'Other'], label: x=> x, value: x => x}}
                                                    type={'select'}
                                                    required
                                                    isValid={(valid) => {
                                                        validation.current = {...validation.current, [`contact_title_${index + 1}`]: valid};
                                                    }}
                                                    onTextChange={(event) => handleUpdateContact(index, 'title', event)}>
                                                            <button onClick={(evt) => handleRemoveContact(index)} style={{marginBottom: '3.5rem', marginRight: '-1rem'}} className="btn btn-danger-light">REMOVE</button>
                                                        </FormGroup>


                </div>
                <FormGroup validation={(x) => type == 'email' ? Helper.Validators.IsValidEmail(x) : Helper.Validators.IsValidUSPhone(x) } errorText={`${title} format must be ${type == 'email' ? 'abc@xyz.com' : '000-000-0000'}`} placeholder={title + ' ' + (j + 1)} label={`${title} ${j + 1}`} required name={`${type}_${j + 1}`} value={x.value} onTextChange={(evt) => {
                    handleUpdateContact(index, 'value', evt);
                }}>

                    <div className="d-flex justify-content-end flex-wrap" style={{marginTop: '-.5rem', marginRight: '-.5rem'}}>
                                <div className="col-12 text-right">
                                    <span className="font-8 font-normal text-gray text-right">Notifications</span>
                                </div>
                                <div className="">
                                    <Toggle id={'toggle'} checked={!x.unsubscribed} label={''} onCheck={(evt) => {
                                        handleUpdateNotificationContact(index);
                                    }} />
                                </div>
                            </div>
                </FormGroup>
            </div>
        })
    }

    const handleUpdateContact = (index, key, value) => {
        // console.log({index, key, value})
        const temp = [...customer?.customer_profile.contact];

        const contact = temp[index];
        if (key == 'value' && contact['type'] == 'phone') {
            value = Helper.Masks.USPhone(value);
        }

        if (key == 'type') {
            contact.value = '';
        }

        contact[key] = value;
        temp[index] = contact;

        setCustomer({...customer, customer_profile: {...customer?.customer_profile, contact: temp} });
    }

    const handleSubmitForm = (event) => {
        event.stopPropagation();
        event.preventDefault();

        location.address = new Address(address);
        customer.location = location;

        if (currentCustomer != undefined)
            handleUpdateCustomer(customer);
        else handleSaveCustomer(customer).then();
    }

    function goHere(text: string){
        //console.log(text);
        let newCustomer = new Customer({...customer, default_invoice_email: text});
        //console.log(newCustomer);
        setCustomer(newCustomer);
    }

    const validation = useRef<any>({});

    const getContactsFormSection = () => {
        return (
            <>
                <div className="row">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <p className="fs-14 mb-3 mt-3 font-medium">Contacts for this profile</p>
                        {/* <p className="fs-12 mb-3 mt-3 font-medium cursor-pointer text-light-blue"
                           ><i className="ti-plus"></i> ADD MORE CONTACT</p> */}
                    </div>
                </div>

                <div className="row mb-3 align-items-start">
                    <div className="col-6 row">
                        <div className="row mb-2 col-12 align-items-start">
                            <h4 className="font-12">Emails</h4>
                            <button className="btn btn-blue-light" onClick={(event) => setCustomer({...customer, customer_profile: {...customer?.customer_profile, contact: [...customer?.customer_profile.contact, new Contact({title: 'Work', type: 'email', unsubscribed: false, value: ''})]} })}>ADD</button>
                        </div>
                        <div className="col-12">
                            {getInputsByType('email')}
                        </div>
                    </div>

                    <div className="col-6 row">
                        <div className="row mb-2 col-12 align-items-start">
                            <h4 className="font-12">Phones</h4>
                            <button className="btn btn-blue-light" onClick={(event) => setCustomer({...customer, customer_profile: {...customer?.customer_profile, contact: [...customer?.customer_profile.contact, new Contact({title: 'Mobile', type: 'phone', unsubscribed: false, value: ''})]} })}>ADD</button>
                        </div>
                        <div className="col-12">
                            {getInputsByType('phone')}
                        </div>
                    </div>

                </div>

            </>
        );
    };


    const getAddressFormSection = () => {
        return (
            <div className="">
                <div className="row">
                    <AddressSearch className="col-12" onGotAddress={(newAddress) => {
                        setAddress(newAddress);
                    }}/>

                    <div className="col-12 position-relative mt-3">
                        <DropdownCountry
                                    country={address?.country}
                                    onSelect={value=>{setAddress({...address, country: value})}}
                                    allCountry={true}
                                    />
                    </div>

                    {/*<FormGroup label={'Country'}
                                name={'country'}
                                colSize={9}
                                value={address.country}
                                type={'select'}
                                placeholder={'-- Select a country --'}
                                options={{data: countries, value: x => x, label: x => x}}
                                onTextChange={value => setAddress({...address, country: value})}/>*/}

                    <div className="row col-12">
                        <FormGroup label={'Street #'}
                            colSize={3}
                            value={address?.street_number}
                            name={'streetNumber'}
                            placeholder={'Street #'}

                            required
                            onTextChange={value => setAddress({...address, street_number: value})}/>

                        <FormGroup name={'street'}
                            colSize={9}
                            label={'Street address'}
                            value={address?.street}
                            required
                            placeholder={'Street'}
                            onTextChange={(event) => setAddress({...address, street: event})}/>
                    </div>
                </div>

                <div className="row">
                    <div className="row col-12">
                    <FormGroup name={'city'}
                                   colSize={12}
                                   label={'City'}
                                   required
                                   value={address?.city}
                                   placeholder={'City'}
                                   onTextChange={(event) => setAddress({...address, city: event})}/>

                    <FormGroup name={'state'}
                                   label={'State'}
                                   colSize={6}
                                   required
                                   value={address?.state}
                                   type='select'
                                   placeholder={'--'}
                                   options={{data: states, value: x => x, label: x => x}}
                                   onTextChange={(event) => setAddress({...address, state: event})}/>

                    <FormGroup name={'postalCode'}
                                label={'Postal Code'}
                                colSize={6}
                                value={address?.postal_code}
                                required
                                placeholder={'00000'}
                                onTextChange={(event) => setAddress({...address, postal_code: event})}/>

                    </div>




                </div>
            </div>
        );
    };


    const getNextRowAddressFormSection = ()=> {
        return (
            <div className="row col-12 form-group">


                <div className="col-12">
                    <label className="">Tax ID {customer?.id && <span className="text-blue-light font-bold">{editTaxID ? '(Encrypted)' : '(Empty)'}</span>}</label>
                </div>

                <>
                    <FormGroup label={''}
                            name={'tax_Id'}
                            colSize={3}
                            type={'select'}
                            placeholder={' Tax ID'}
                            value={customer.tax_number.type}
                            options={{data: ['SSN', 'EIN'], value: x => x, label: x => x}}
                            onTextChange={value => {
                                setCustomer(new Customer({...customer, tax_number: {...customer.tax_number, type: value}}));
                            }}/>

                    <FormGroup name={'tax_IDNumber'}
                            label={''}
                            colSize={9}
                            placeholder={'XX-XXXXX'}
                            value={customer.tax_number.value}
                            onTextChange={value => {
                                setCustomer(new Customer({...customer, tax_number: {...customer.tax_number, value}}));
                            }}/>

                    {customer?.id && <div className="row col-12 justify-content-end">
                        {/* <button className="btn btn-clear text-blue-light py-2 col-6">CANCEL</button> */}

                        <button onClick={async (evt) => {
                            const mutation = gql`
                                mutation($customer_id: Int, $tax_info: tax_info_input){
                                    update_customer_tax_info(customer_id: $customer_id, tax_info:$tax_info){
                                        success
                                        message
                                    }
                                }
                            `
                            try
                            {
                                const data = await GraphqlService.SendMutation(mutation, {customer_id: customer?.id, tax_info: customer.tax_number})
                                console.log(data)
                                if (!data?.success)
                                    throw new Error(data?.message || 'Something went wrong');
                                const c = new Customer(customer);
                                setEditTaxID(c.tax_number.value);
                                c.tax_number.value = '';
                                setCustomer(c);
                                toast.addToast(data?.message, {appearance: 'success', autoDismiss: true});
                            }
                            catch(ex) {
                                toast.addToast(ex?.message, {appearance: 'error', autoDismiss: true});
                            }
                        }} className="btn btn-blue-light py-2 col-6">UPDATE</button>
                    </div>}
                </>

                <FormGroup name={'default_invoice_email'}
                        label={'Default invoice email'}
                        colSize={12}
                        errorText="Email is not valid"
                        validation={x => {
                            return x == '' || Helper.Validators.IsValidEmail(x);
                        }}
                        value={customer.default_invoice_email}
                        className={'mt-3'}
                        placeholder={'abc@xyz.com'}
                            onTextChange={text => goHere(text)}
                        />

<FormGroup name={'internal_note'}
                        type={'textarea'}
                        label={'Internal Notes'}
                        value={location?.internal_note}

                        className={'mt-5'}
                        placeholder={'Internal Notes'}
                        onTextChange={(event) => setLocation({...location, internal_note: event})}/>

          {!currentCustomer &&  <>



                <FormGroup name={'driver_note'}
                        type={'textarea'}
                        label={'Driver Notes'}
                        value={location?.driver_note}

                        placeholder={'Driver Notes'}
                        onTextChange={(event) => setLocation({...location, driver_note: event})}/>




                <div className="col-12">
                    <label className="fs-12 font-normal">Work hours</label>
                </div>

                <LocationFormWorkHours workHours={location?.work_hours}
                                       onChange={(workHours) => setLocation({...location, work_hours: workHours})}/>
            </>}

            </div>
        )
    }





    const getProfileFormSection = () => {
        return(
            <>
                <div className="row col-12">
                    <div className="col-6 ">
                        <div className="row my-3">
                            <p className="fs-14 font-medium">Profile details</p>
                            <Toggle label={'Create Online Profile'} checked={createOnlineProfile} onCheck={(evt) => {
                                setCustomer({...customer, customer_profile: { ...customer?.customer_profile, email: '' }})
                                setCreateOnlineProfile(evt);}} id={'create_online_profile_toggle'} />
                        </div>

                        <div className="row">
                                <div className="col-12 d-flex">
                                <FormGroup name={'profileName'}
                                       value={customer?.customer_profile?.name}
                                       placeholder={createOnlineProfile ? 'Profile Name':'Contact Name'}
                                       label={createOnlineProfile ? 'Online Profile Name':'Contact'}
                                       required={true}
                                       errorText={'Invalid email format'}
                                       className="mt-3"
                                       onTextChange={(event) => setCustomer({...customer, customer_profile: { ...customer?.customer_profile, name: event }})}/>

                                </div>

                            <FormGroup name={'Title'}
                                       value={customer?.customer_profile?.title}
                                       placeholder={'Title...'}
                                       label={'Title/Position'}
                                       required
                                       type={'text'}
                                       onTextChange={(event) => setCustomer({...customer, customer_profile: { ...customer?.customer_profile, title: event }})}/>

                            {createOnlineProfile && <FormGroup name={'email'}
                                       value={customer?.customer_profile?.email}
                                       placeholder={'Login Email'}
                                       label={'Login Email'}
                                       required={true}
                                       type={'email'}
                                       validation={Helper.Validators.IsValidEmail}
                                       onTextChange={(event) => setCustomer({...customer, customer_profile: { ...customer?.customer_profile, email: event }})}/>}


                        </div>
                    </div>

                    <div className="col-6">
                        {
                            getContactsFormSection()
                        }
                    </div>
                </div>
            </>
        );
    };

    async function handleUpdateCustomer(customer: any)
    {
        try {
            loading.open('saving customer...');

            let customer_info = {
                "name": customer.name,
                "corporate_client": customer.corporate_client,
                "key": customer.key?.trim() != '' ? customer.key : null,
                "terms": customer.terms,
                "internal_note": location.internal_note,
                "default_invoice_email": customer.default_invoice_email,
                default_billing_address: customer.default_billing_address,
                "billing_address": {
                    "city": address.city,
                    "state": address.state,
                    "street":  address.street,
                    "country":  address.country,
                    "postal_code":  address.postal_code,
                    "street_number":  address.street_number,
                    "postal_code_suffix":  address.postal_code_suffix
                }
            }

            let data = await Mutations.update_customer({customer_id: currentCustomer?.id, customer_info}) //await GraphqlService.SendMutation(CustomerMutations.EDIT_CUSTOMER, { customer_info, customer_id: currentCustomer?.id });

            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');

            loading.close();
            toast.addToast(data?.message, {appearance: 'success', autoDismiss: true});
                //console.log(data);
                let temp = new CustomerList(data as any);

                // temp.billing_address = new Address(data.billing_address);

                // alert('Temp');
                //console.log(temp);
                // alert('Test')

                // customer.location = location;
                // customer.id = data.customer_id;

                onSubmit(temp);
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error'});
            loading.close();
        }
    }

    async function handleSaveCustomer(customer: any) {
        try {
            loading.open('saving customer...');

            let newCustomer = {
                "name": customer.name,
                "corporate_client": customer.corporate_client,
                "key": customer.key?.trim() != '' ? customer.key : null,
                "terms": customer.terms,
                "internal_note": location.internal_note,
                tax_number: customer.tax_number,
                default_billing_address: customer.default_billing_address,
                "location": {
                    "address": {
                        "city": location.address.city,
                        "state": location.address.state,
                        "street": location.address.street,
                        "country": location.address.country,
                        "postal_code": location.address.postal_code,
                        "street_number": location.address.street_number,
                        "postal_code_suffix": location.address.postal_code_suffix
                    },
                    "driver_note": location.driver_note,
                    "internal_note": location.internal_note,
                    "work_hours": {
                        "weekends": {
                            "from": location.work_hours?.weekends?.from || '',
                            "to": location.work_hours?.weekends?.to || '',
                        },
                        "weekdays": {
                            "from": location.work_hours?.weekdays?.from || '',
                            "to": location.work_hours?.weekdays?.to || ''
                        }
                    }
                },
                "customer_profile": {
                    "name": customer.customer_profile.name,
                    "email": createOnlineProfile ? customer.customer_profile.email : null,
                    "title": customer.customer_profile.title,
                    "contact": customer.customer_profile?.contact.map(e => {
                        return { "title": e.title, "value": e.value, "type": e.type }
                    }) || []
                }
            }
            // loading.close();
            // return console.log(newCustomer)
            let data = await GraphqlService.SendMutation(CustomerMutations.ADD_NEW_CUSTOMER, { customer: newCustomer });
            loading.close();


            toast.addToast(`Customer created with ID #` + data.id, {appearance: 'success', autoDismiss: true});
                //console.log(data);
                let temp = new CustomerList(data);

                // temp.billing_address = new Address(data.billing_address);

                // alert('Temp');
                console.log(temp);
                // alert('Test')

                // customer.location = location;
                // customer.id = data.customer_id;

                onSubmit(temp);
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error'});
            loading.close();
        }
    }


    return (
        <div className="px-3  flex-1-container" onClick={(evt) => evt.stopPropagation()}>

             <div className="row ">
                { currentCustomer == undefined && <p className="col-6 fs-14 font-medium mb-3">Create New Customer</p> }
                { currentCustomer != undefined && <p className="col-6 fs-14 font-medium mb-3">Edit Customer</p> }
            </div>



            <div className={`flex-1-container`} >

                <div className="row">


                <div className="col-12">
                    <p className="mb-3 font-14 font-medium">Customer details</p>
                </div>

                <div className="col-12">
                    <p className="font-12 font-normal mb-3">Customer type*</p>
                </div>

                <div className="col-6">
                    <div className="row">
                        <div className="col-6 mb-3">
                            <button className={`btn w-100 rounded-pill py-2 ${ !customer?.corporate_client  ? 'btn-selected' : 'btn-unselected'}`}
                                   onClick={() => {setCustomer({...customer, corporate_client: false})}}>Private</button>
                        </div>

                        <div className="col-6 mb-3">
                            <button className={`btn w-100 rounded-pill py-2 ${ customer?.corporate_client ? 'btn-selected' : 'btn-unselected'}`}
                                onClick={() => {setCustomer({...customer, corporate_client: true})}}>Corporate</button>
                        </div>
                    </div>
                </div>

                <div className="row col-12">
                    <div className="col-6">

                        <div className="row col-12">
                            <FormGroup name={'customerName'}
                                       value={customer?.name}
                                       placeholder={'Customer Name'}
                                       label={'Customer Name'}
                                       required={true}
                                       onTextChange={(event) => setCustomer({...customer, name: event})}/>
                        </div>

                        <div className="row col-12">
                            <FormGroup name={'key'}
                                       value={customer?.key}
                                       placeholder={'Key'}
                                       label={'Key'}
                                       colSize={6}
                                       onTextChange={(event) => setCustomer({...customer, key: event.toUpperCase()})}/>

                            <FormGroup
                                label='Terms'
                                name='terms'
                                required
                                colSize={6}
                                placeholder='--Select a term--'
                                value={customer?.terms}
                                type='select'
                                options={{data: terms, label: x => x, value: x => x}}
                                onTextChange={text => setCustomer({...customer, terms: text})}/>

                        </div>

                        {/*<button className={`btn font-24 ${customer?.corporate_client ? 'btn-blue' : 'btn-blue-outline'} py-4 mt-3 col-12`}
                        onClick={() => {
                            setCustomer({...customer, corporate_client: !customer.corporate_client});
                        }}>
                        <span>{customer?.corporate_client ? 'CORPORATE' : 'PRIVATE'}</span>
                        </button>*/}

                        { <div className="col-12">
                            <p className="mb-3 fs-14 font-medium">Billing address</p>

                            <div className="row col-12">
                                <div className="col-12">
                                    { getAddressFormSection() }
                                </div>
                            </div>
                        </div>
}
                    </div>


                    {/*<div className="col-6">
                        <p className="mb-3 fs-14 font-medium">Billing address</p>

                        <div className="row col-12">
                            <div className="col-12">
                                { getAddressFormSection() }
                            </div>
                        </div>
                    </div>*/}

                        <div className="col-6">
                            {getNextRowAddressFormSection()}
                        </div>

                </div>

                    <div className="col-12">
                        <hr className={"mt-4 mb-3"}/>
                    </div>

                {
                   !customer.id &&  getProfileFormSection()
                }
                </div>



            </div>

            <div className="row col-12 pt-3 row sticky-bottom bg-white">
                        <div className="col-6">
                            <button type="button"
                                    className="w-100 btn btn-clear text-blue-light rounded-pill btn-block"
                                    onClick={(event) => onCancel(event)}>CANCEL
                            </button>
                        </div>

                        <div className="col-6">
                        {
                            currentCustomer == undefined &&
                            <button type="button"
                                    className="w-100  btn btn-blue-light rounded-pill btn-block mr-2"
                                    disabled={!isValid()}
                                    onClick={(event) => handleSubmitForm(event)}>CREATE CUSTOMER</button>
                        }

                        {
                            currentCustomer != undefined &&
                            <button type="button"
                                    className="w-100   btn btn-blue-light rounded-pill btn-block mr-2"
                                    disabled={!isValid()}
                                    onClick={(event) => handleSubmitForm(event)}>SAVE</button>
                        }
                        </div>
                </div>
        </div>
    );
}

export default CustomerForm;

import {Vehicle_Order} from "../vehicle_order/vehicle_order";
import {Users} from "../users/users";
import {Vehicle} from "../vehicle/vehicle";

export class Inspection {
    __typename?: "Inspection"
    id?: number = null;
    performed_by?: Signed_By = new Signed_By();
    signed_by?: Signed_By = new Signed_By();
    user_id?: number = null;
    note?: string = '';
    odometer?: number = 0;
    device_type?: string = '';
    vehicle?: Vehicle = new Vehicle();
    accessories?: Accessories[] = [];
    additional_emails?: string[] = [];
    damages?: Damages[] = [];
    photos?: Photos[] = [];
    device_info?: Device_Info = new Device_Info();
    flags?: Flags[] = []; 
    origin_inspection?: Origin_Inspection = new Origin_Inspection();
    destination_inspection?: Destination_Inspection = new Destination_Inspection();
    vehicle_data?: Vehicle = new Vehicle();
    pending_inspection_id?: number = null;
    user?: Users = new Users();
    time?: string | Date = new Date();
    type?: string = '';
    vehicle_order?: Vehicle_Order = new Vehicle_Order();

    constructor(x?: Inspection){
        if(x){
            this.id = x.id;
            this.performed_by = new Signed_By(x.performed_by);
            this.signed_by = new Signed_By(x.signed_by);
            this.user_id = x.user_id;
            this.note = x.note;
            this.odometer = x.odometer;
            this.device_type = x.device_type;
            this.vehicle = new Vehicle(x.vehicle);
            this.accessories = x.accessories?.map(z => z) || [];
            this.additional_emails = x.additional_emails?.map(z => z) || [];
            this.damages = x.damages?.map(z => z) || [];
            this.photos = x.photos?.map(z => z) || [];
            this.device_info = new Device_Info(x.device_info);
            this.flags = x.flags?.map(z => z) || [];
            this.origin_inspection =  new Origin_Inspection(x.origin_inspection);
            this.destination_inspection = new Destination_Inspection(x.destination_inspection);
            this.vehicle_data =  new Vehicle(x.vehicle_data);
            this.pending_inspection_id = x.pending_inspection_id;
            this.user =  new Users(x.user);
            this.time = new Date(x.time );
            this.type = x.type;
            this.vehicle_order = new Vehicle_Order(x.vehicle_order);          
        }
    }
}

export class Photos {
    __typename?: "Photos"
    uid?: string = '';
    type?: string = '';
    user_id?: number = null;

    constructor(x?: Photos){
        if(x){
            this.uid = x.uid;
            this.type = x.type;
            this.user_id = x.user_id;            
        }
    }
}

export class Signed_By {
    __typename?: "Signed_by"
    time?: string | Date = new Date();
    name?: string = '';
    uid?: string = '';
    user_id?: number = null;

    constructor(x?: Signed_By){
        if(x){
            this.time = new Date(x.time);
            this.name = x.name;
            this.uid = x.uid;
            this.user_id = x.user_id;            
        }
    }
}

export class Device_Info {
    __typename?: "Device_info"
    name?: string = '';
    type?: string = '';
    idiom?: string = '';
    model?: string = '';
    version?: string = '';
    platform?: string = '';
    manufacturer?: string = '';

    constructor(x?: Device_Info){
        if(x){
            this.name = x.name;
            this.type = x.type;
            this.idiom = x.idiom;
            this.model = x.model;
            this.version = x.version;
            this.platform = x.platform;
            this.manufacturer = x.manufacturer; 
        }
    }
}

export class Required_Photos {
    __typename?: "Required_photos"
    type?: string = '';
    uid?: string = '';

    constructor(x?: Required_Photos){
        if(x){
            this.type = x.type;
            this.uid = x.uid;
        }
    }
}

export class Accessories {
    __typename?: "Accessories"
    name?: string = '';
    count?: number = 0;
    user_id?: number = null;

    constructor(x?: Accessories) {
        if(x){
            this.name = x.name;
            this.count = x.count;
            this.user_id = x.user_id;
        }

    }
}

export class Damages {
    __typename?: "Damages"
    stop_id?: number = null;
    point?: Point_Damages = new Point_Damages();
    code?: string = '';
    uid?: string = '';
    is_pickup?: boolean = false;

    constructor(x?: Damages){
        if(x){
            this.stop_id = x.stop_id;
            this.point = new Point_Damages(x.point);
            this.code = x.code;
            this.uid = x.uid;
            this.is_pickup = x.is_pickup;             
        }
    }
}

export class Point_Damages {
    __typename?: "Point_damages"
    x: number = 0;
    y: number = 0;

    constructor(x?: Point_Damages){
        if(x){
            this.x = x.x;
            this.y = x.y;
        }
    }
}

export class Flags {
    __typename?: "Flags"
    too_dirty?: boolean = false;
    too_dark?: boolean = false;
    raining?: boolean = false;

    constructor(x?: Flags){
        if(x){
            this.too_dirty = x.too_dirty;
            this.too_dark = x.too_dark;
            this.raining = x.raining;            
        }
    }
}

export class Origin_Inspection {
    __typename?: "Origin_inspection"
    origin_id?: number = null;
    driver_name?: string = '';
    driver_signature?: string = '';
    contact_name?: string = '';
    inspections?: Inspection[] = [];

    constructor(x?: Origin_Inspection){
        if(x){
            this.origin_id = x.origin_id;
            this.driver_name = x.driver_name;
            this.driver_signature = x.driver_signature;
            this.contact_name = x.contact_name;
            this.inspections = x.inspections?.map(z => z) || [];            
        }
    }
}

export class Destination_Inspection {
    __typename?: "Destination_inspection"
    destination_id?: number = null;
    driver_name?: string = '';
    driver_signature?: string = '';
    contact_name?: string = '';
    inspections?: Inspection[] = [];

    constructor (x?: Destination_Inspection){
        if(x){
            this.destination_id = x.destination_id;
            this.driver_name = x.driver_name;
            this.driver_signature = x.driver_signature;
            this.contact_name = x.contact_name;
            this.inspections = x.inspections?.map(z => z) || [];            
        }
    }
}

export class Point_Damage {
    x?: number = 0;
    y?: number = 0;

    constructor(x?: Point_Damage){
        if(x){
            this.x = x.x;
            this.y = x.y;
        }
    }
}

export class Accessories_Input {
    name?: string = '';
    count?: number = 0;

    constructor(x?: Accessories_Input){
        if(x){
            this.name = x.name;
            this.count = x.count;
        }
    }
}

export class Flags_Input {
    too_dirty?: boolean = false;
    too_dark?: boolean = false;
    raining?: boolean = false;

    constructor(x?: Flags_Input){
        if(x){
            this.too_dirty = x.too_dirty;
            this.too_dark = x.too_dark;
            this.raining = x.raining;
        }
    }
}

import React, { useState } from 'react';
import { MasterOrder, VehicleOrder } from '../../classes/classes';
import CustomerCard from './components/CustomerCard/CustomerCard';
import MasterOverview from './components/MasterOverview/MasterOverview';
import VehicleContainer from './components/VehicleContainer/VehicleContainer';
import VehicleList from './components/VehicleList/VehicleList';
import VehiclesOverview from './components/VehiclesOverview/VehiclesOverview';
import './BookOrderPage.scss';


enum Tabs
{
    DETAILS,
    LEGS,
    CHARGES,
    STORAGE
}

const BookOrderPage = () => {


    const [master, setMaster] = useState<MasterOrder>(new MasterOrder());

    const [vehiclesIndex, setVehiclesIndex] = useState<number[]>([]);

    const [vehicleIndex, setVehicleIndex] = useState<number>(null);


    const [vehicleTab, setVehicleTab] = useState(Tabs.DETAILS)



    return <div className={'book-order-page flex-1-container'}>
        <h4 className="font-16 mb-3">New Order, index: {vehicleIndex}; indexes: [{vehiclesIndex.join(',')}]</h4>
        <div className="flex-1-container row-container">
            <div className="col-3 col-left d-flex">
                <div className="flex-1-container">
                    <CustomerCard />

                    <div className="mt-3 d-flex" style={{height: '100%'}}>
                        <VehicleList
                            onOrderChanged={(order, index) => {
                                const temp = new MasterOrder(master);
                                temp.vehicle_orders[index] = order;
                                setMaster(temp);
                            }}
                            orders={master.vehicle_orders} 
                            index={vehicleIndex}
                            indexes={vehiclesIndex}
                            onAddVehicleClicked={(count) => {
                                const temp = new MasterOrder(master);
                                let index;
                                for (let i = 0; i < count; i++)
                                {
                                    index =  temp.vehicle_orders.push(new VehicleOrder()) - 1;
                                }
                                

                                console.log(count)

                                setMaster(temp);
                                
                                setVehiclesIndex([index]);
                                setVehicleIndex(index);
                                

                            }}
                            onCheckChanged={(data) => {
                                //console.log(data);
                                if (!data.checkbox)
                                {
                                    setVehiclesIndex([data.index]);

                                    setVehicleIndex(data.index)
                                }
                                else if (data.checkbox && !data.checked)
                                {
                                    if (vehiclesIndex.length == 1) return;
                                    
                                    const temp = vehiclesIndex.removeBy(x => x== data.index);
                                    setVehiclesIndex(temp);
                                    setVehicleIndex(temp[temp.length - 1]);

                                    
                                    return;
                                }
                                else if (data.checkbox && data.checked)
                                {
                                    
                                    setVehicleIndex(data.index);
                                    if (!vehiclesIndex.includes(data.index))
                                    {
                                        const temp = [...vehiclesIndex];
                                        temp.push(data.index);
                                        setVehiclesIndex(temp);
                                    }
                                }
                                

                            }}
                            onAllCheckChanged={checked => {
                                if (checked)
                                {
                                    const temp = [...vehiclesIndex];
                                    master.vehicle_orders.forEach((_, index) => {
                                        if (!temp.includes(index))
                                            temp.push(index);
                                    });
                                    setVehiclesIndex(temp);
                                }
                                else
                                {
                                    setVehiclesIndex([vehicleIndex]);
                                }
                            }}
                            onRemoveVehiclesClicked={(indexes) => {
                                const temp = new MasterOrder(master);
                                console.log(indexes);
                                for (let i = temp.vehicle_orders.length - 1; i >= 0; i--)
                                {
                                    console.log('i', i)
                                    if (indexes.includes(i))
                                    {
                                        console.log('Im here')
                                        temp.vehicle_orders.splice(i, 1);
                                    }

                                }

                                if (temp.vehicle_orders.length > 0)
                                {
                                    setVehiclesIndex([0])
                                    setVehicleIndex(0);
                                }
                                else
                                {
                                    setVehicleIndex(null);
                                    setVehiclesIndex([]);
                                }
                                setMaster(temp);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="col-6 col-middle d-flex bg-white">
                <div className="flex-1-container">
                    <VehicleContainer index={vehicleIndex} order={master.vehicle_orders[vehicleIndex]} />
                </div>
            </div>
            <div className="col-3 col-right d-flex">
                <div className="flex-1-container">

                    <MasterOverview />

                    <div className="flex-1-container my-3">
                        <VehiclesOverview />
                    </div>

                    <div className="p-3 bg-white rounded">
                        <div className="row">
                            <h6 className="font-10">Subtotal</h6>
                            <h6 className="font-10">$840.00</h6>
                        </div>

                        <div className="row my-1">
                            <h6 className="font-10">Tax</h6>
                            <h6 className="font-10">$0.00</h6>
                        </div>

                        <div className="row">
                            <h6 className="font-10">Total</h6>
                            <h6 className="font-10">$840.00</h6>
                        </div>

                        <hr className="my-2" />
                        <div className="row">
                            <button className={'btn btn-blue-light py-2 col-6 rounded-pill'}>PROCESS ORDER</button>
                            <button className={'btn btn-orange py-2 col-6 rounded-pill'}>PROCESS SEPARATE</button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
}

export default BookOrderPage;
import React, { FC } from 'react';


import styles from './Checkbox.module.scss';

interface ICheckbox
{
    labelPosition?: 'left' | 'right',
    label?: string,
    checked?: boolean,
    onCheckChange?: (value: boolean) => any,
    name?: string,
    disabled?: boolean,
    onClick?: (evt) => any
}

const Checkbox: FC<ICheckbox> = (props) => {
    
    const position = props.labelPosition || 'left';

    return <div className={'d-flex align-items-center'}>
        {(position == 'left' && props.label) && <label className='no-select font-10 m-0 mr-1 font-normal cursor-pointer' htmlFor={props.name}>{props.label}</label>}
        <input onClick={props.onClick} disabled={props.disabled} id={props.name} name={props.name} style={{scale: 1}} checked={props.checked} onChange={(evt) => props.onCheckChange && props.onCheckChange(evt.target.checked)} className={styles.checkbox} type="checkbox"  />
        {(position == 'right' && props.label) && <label className='no-select font-10 m-0 ml-1 font-normal cursor-pointer' htmlFor={props.name}>{props.label}</label>}
    </div>
}

export default Checkbox;
import {useEffect, useRef, useState} from "react";

import './SideActionManager.scss';
import * as React from "react";
import ICONS from "../../../assets/svg";


const SideActionManager = props => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(props.opened);
    const [title, setTitle] = useState(props.title);

    useEffect(
        () => {
            setIsSidebarOpen(props.opened)
        }, [props.opened],
    );
    useEffect(
        () => {
            setTitle(props.title)
        }, [props.title],
    );

    return (
        <>
            {
                <section id="sidebarAction" className={'p-0 flex-1-container ' + (isSidebarOpen ? `active` : '')}>
                  
                    <div className="flex-1-container">
                        {props.children}
                    </div>

                </section>
            }
        </>
    );
};

export default SideActionManager;

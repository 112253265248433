import React,{useState,useRef} from 'react'
import './ArPage.scss'


const ArPage =()=>{
    return(
        <>
            <h1>Ar</h1>
        </>
    )
}

export default ArPage
import React, { Children, useReducer } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/Loading/Loading';
import useLoading from '../hooks/useLoading/useLoading';
import { AllStates } from '../redux/reducers';
import { ILoading } from '../redux/reducers/Loading.reducer';
import { IMessageBox } from '../redux/reducers/MessageBox.reducer';
import messageBoxTypes from '../redux/types/MessageBox.type';





const MessageBoxProvider = ({children}) => {

    const messageBox = useSelector<AllStates>(x => x.messageBox) as IMessageBox;
    const dispatch = useDispatch();

    return <>
        {children}
         {messageBox.open &&  <ReactModal
         isOpen={true} className='p-3 modal-fit modal-messagebox modal-90' style={{content: {maxWidth: '420px', width: '90%', background: '#FFF'}}}>
                    <h4 className="font-12 mb-1">{messageBox.title}</h4>
                    <h5 style={{whiteSpace: 'pre-line'}} className="font-11 font-normal">{messageBox.message}</h5>

                    <div className={"mt-3 row " + (messageBox.buttons.length == 1 ? 'justify-content-end' : '')}>
                        {messageBox.buttons.map(btn => <button className={"py-2 rounded-pill " +  (messageBox.buttons.length  == 1 ? 'col-6' : 'col-' + (12 / messageBox.buttons.length)) + (btn.css ? ' ' + btn.css : ' btn btn-blue-light')}
                            onClick={(evt) => {
                                dispatch({type: messageBoxTypes.CLOSE});
                                btn.action && btn.action(btn.data);
                            }}
                        >{btn.text}</button>)}
                    </div>
                </ReactModal>}
    </>
}

export default MessageBoxProvider;
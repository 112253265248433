import { gql } from 'apollo-boost';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useToasts } from 'react-toast-notifications';
import ICONS from '../../../../assets/svg';
import { CustomerList, Location, Quote, Stop } from '../../../classes/classes';
import CustomerSelection from '../../../components/CustomerSelection/CustomerSelection';
import PickupCustomer from '../../../components/NewOrder/PickupCustomer/PickupCustomer';
import QuoteDetail from '../../../components/QuoteDetail/QuoteDetail';
import QuotesTable from '../../../components/QuotesTable/QuotesTable';
import SideActionManager from '../../../components/SideActionManager/SideActionManager';
import QuoteMutations from '../../../graphql/mutation/Quote';
import useLoading from '../../../hooks/useLoading/useLoading';
import { GraphqlService } from '../../../services/graphql.service';

enum CustomerPicker {
    NONE,
    CUSTOMER,
    PICKUP,
    PICKUP_TIME,
    DROPOFF,
    DROPOFF_TIME
}


interface INewOrderInfo {
    customer: CustomerList,
    profiles_id: { customer_profile_id: number }[],
    quote_id: number,
    pickup: Stop,
    dropoff: Stop,
    location: Location
}

const QuotesTableByCustomer = (props) => {

    const [reload, setReload] = useState(false);
    const [newOrderInfo, setNewOrderInfo] = useState<INewOrderInfo>({
        customer: new CustomerList(),
        profiles_id: [],
        quote_id: null,
        pickup:  new Stop(),
        dropoff: new Stop({is_pickup: false} as any),
        location: new Location()
    });
    const [picker, setPicker] = useState(CustomerPicker.NONE);
    const [quote, setQuote] = useState(new Quote());
    const toast = useToasts();
    const loading = useLoading();

    function handleReload() {
        setReload(true);
        setTimeout(() => {
            setReload(false);
        });
    }


    async function createNewMaster(order){
        // console.log(order);
        // setPicker(CustomerPicker.LOADING);
        loading.open(`Creating order from quote #${newOrderInfo.quote_id}...`)
        const mutation = gql`
                mutation($quote_id:Int, $billing:Address_new_quote_order, $contacts: [quote_order_contacts], $pickup:quote_stop_input, $dropoff: quote_stop_input){
                        create_master_order_from_quote_by_user(
                        quote_id:$quote_id,
                            contacts: $contacts
                            pickup:$pickup
                            dropoff:$dropoff
                            billing_address:$billing
                        ){
                            id
                            message
                            success
                }
                }
        `;

        const variables = {
            "quote_id":newOrderInfo.quote_id,
            "billing": newOrderInfo.location.address,
            "contacts":newOrderInfo.profiles_id.map(x => ({id: x.customer_profile_id})),
            "pickup":{
               "contacts": newOrderInfo.pickup.contacts.map(x => ({id: x.id})),
               "location":{
                  "title":newOrderInfo.pickup.location.title,
                  "address": newOrderInfo.pickup.location.address,
                  "driver_note": newOrderInfo.pickup.location.driver_note
               }
            },
            "dropoff":{
                "contacts": newOrderInfo.dropoff.contacts.map(x => ({id: x.id})),
                "location":{
                   "title":newOrderInfo.dropoff.location.title,
                   "address": newOrderInfo.dropoff.location.address,
                   "driver_note": newOrderInfo.dropoff.location.driver_note
                }
            }
         }


         try {
             const data = await GraphqlService.SendMutation(mutation, variables);
             if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            setPicker(CustomerPicker.NONE);
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            setReload(true);
         } catch (ex) {
             setPicker(CustomerPicker.DROPOFF);
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
         }
    }
    async function updatePrice(quote)
    {
        try {
            // setLoadingText('Updating price for quote #' + quote.id + '...');
            loading.open('Updating price for quote #' + quote.id + '...');
            const data = await GraphqlService.SendMutation(QuoteMutations.UPDATE_PRICE, {quote});

            if (!data.success)
                throw new Error(data.message);
            loading.close();
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            setReload(true);

        }
        catch (ex)
        {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: false});
        }
    }

    async function archiveQuote(quote_id: number)
    {
        try {
            loading.open('Archiving quote #' + quote.id + '...');
            const data = await GraphqlService.SendMutation(QuoteMutations.ARCHIVE, {quote_id});

            if (!data.success)
                throw new Error(data.message);
            // setLoadingText('');
            loading.close();
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            setReload(true);

        }
        catch (ex)
        {
            loading.close();
            // setLoadingText('');
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: false});
        }
    }


    return <>
        <div className='flex-1-container bg-white p-3'>
            <QuotesTable customer_id={props.customer_id} onConvertToOrderClicked={(quote_id) => {
            setNewOrderInfo({...newOrderInfo, quote_id});
            setPicker(CustomerPicker.CUSTOMER)
        }} reload={reload} onReloadAccepted={evt => setReload(false)} quoteSelected={quote} onQuoteSelected={quote => setQuote(quote)} />
    </div>

    <ReactModal onRequestClose={(evt) => {


setNewOrderInfo({

    profiles_id: [],
    customer: new CustomerList(),
    quote_id: null,
    location: new Location(),
    dropoff: new Stop({is_pickup: false} as any),
    pickup: new Stop()
});
setPicker(CustomerPicker.NONE)
}
} isOpen={picker != CustomerPicker.NONE}>
{picker == CustomerPicker.CUSTOMER && <CustomerSelection onCancel={()=> setPicker(CustomerPicker.NONE)} topRightDiv={<img className='cursor-pointer' onClick={(evt) => {

    setPicker(CustomerPicker.NONE);
    setNewOrderInfo({
        profiles_id: [],
        customer: new CustomerList(),
        quote_id: null,
        location: new Location(),
        dropoff: new Stop({is_pickup: false} as any),
        pickup: new Stop()
    });
    setPicker(CustomerPicker.NONE)
}} src={ICONS.IconCloseBig} style={{marginTop: '-.45rem'}}/>}
                   onCustomerSelected={(c, stops, profiles) => {
                       console.log(stops)
                       const no = {
                           ...newOrderInfo,
                           location: stops.location,
                           customer: new CustomerList(c),
                           profiles_id: profiles.map(x => ({customer_profile_id: x.id}))
                       };
                       setNewOrderInfo(no);
                       setPicker(CustomerPicker.PICKUP);
                   }}/>}


{picker == CustomerPicker.PICKUP &&  <PickupCustomer onConfirmClicked={(pickup) => {setNewOrderInfo({...newOrderInfo, pickup: new Stop(pickup)}); setPicker(CustomerPicker.DROPOFF)}} stop={newOrderInfo.pickup} onCancelClicked={(evt) => setPicker(CustomerPicker.CUSTOMER)} />}
{picker == CustomerPicker.DROPOFF &&  <PickupCustomer onConfirmClicked={(dropoff) => {

let no = {...newOrderInfo, dropoff: new Stop(dropoff)};
setNewOrderInfo(no);
createNewMaster(no);

}} stop={newOrderInfo.dropoff} onCancelClicked={(evt) => setPicker(CustomerPicker.PICKUP)} />}


</ReactModal>

    <SideActionManager onCloseManager={(evt) => setQuote(new Quote())} opened={quote.id != null}>
        <QuoteDetail onArchive={archiveQuote} onSendPrice={updatePrice} reloadRequired={() => handleReload()} quote={quote} />
    </SideActionManager>
    </>
}

export default QuotesTableByCustomer;
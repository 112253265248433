import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import ICONS from '../../../../../assets/svg';
import Helper from '../../../../helper/Helper';
import useLoading from '../../../../hooks/useLoading/useLoading';
import { Queries } from '../../../../services/endpoints/queries';
import { GraphqlService } from '../../../../services/graphql.service';
import InventoryMoveForm from '../InventoryMoveForm/InventoryMoveForm';
import InventoryMoves from './InventoryMoves/InventoryMoves';
import InventoryServices from './InventoryServices/InventoryServices';



enum Tabs {
    MOVES,
    SERVICES
}

const InventoryOrderDetails = ({vehicle_order, onRefresh, onClose, warehouse_id}) => {

    const [data, setData] = useState(null);
    const [formOpened, setFormOpened] = useState(false);

    const tabs = [
        {
            label: 'Moves',
            value: Tabs.MOVES
        },
        {
            label: 'Services',
            value: Tabs.SERVICES
        }
    ];


    const [tabSelected, setTabSelected] = useState(Tabs.MOVES);
    
    const getCount = (tab = Tabs.MOVES) => {
        return Tabs.MOVES == tab ? data?.inventory_orders.length : data?.charges.filter(x => x.inventory_service?.service_charge_id).length;
    }


    const loading = useLoading();
    const toast = useToasts();


    async function loadData() {
        
        loading.open('Getting information...');
        try {
            const result = await Queries.get_inventory_details_by_vehicle_order_id({vehicle_order_id: vehicle_order.id, warehouse_id});
            result.inventory_orders = result.inventory_orders.sort((x, y) => new Date(x.completed ? x.time_completed : x.time_due) > new Date(y.completed ? y.time_completed : y.time_due) ? 1 : -1);
            // console.log(result);
            setData(result);
            loading.close();
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    useEffect(() => {
        if (vehicle_order)
            loadData();
    }, [vehicle_order])

    if (!vehicle_order) return null;


    return <div className='flex-1-container'>
        <div className="row mb-3">
            <h4 className="font-14">Vehicle Order Inventory Details</h4>
            <h4 onClick={(evt) => onClose(evt)} className="font-14 cursor-pointer"><i className='fas fa-times'></i></h4>
        </div>
        <div className="mb-3">
            <h4 className="fs-14 mb-2">Vehicle Order #{vehicle_order.id}, Master #{vehicle_order.master_order_id}</h4>
            <h4 className="fs-14 mb-1">{vehicle_order.vehicle.vin || '-'}</h4>
            <h4 className="fs-14 font-normal">{`${vehicle_order.vehicle.year} ${vehicle_order.vehicle.make} ${vehicle_order.vehicle.model}`}</h4>
            {/* <h4 className="fs-16 cursor-pointer"><i className='fas fa-times'></i></h4> */}
        </div>


        <div className="d-flex col-12 mb-3">
            {tabs.map(x => <h4 onClick={(evt) => setTabSelected(x.value)} className={'font-11 cursor-pointer mr-3 ' + (x.value == tabSelected ? '' : 'text-gray')}>{x.label} ({getCount(x.value)})</h4>)}
        </div>


        <div className="flex-1-container">
           {tabSelected == Tabs.MOVES && <InventoryMoves 
            data={data}
            vehicle_order_id={vehicle_order?.id}
            warehouse_id={warehouse_id}
           onRefresh={(evt) => {
               loadData();
               onRefresh();
           }} />}

           {tabSelected == Tabs.SERVICES && <InventoryServices
            vehicle_order={vehicle_order}
            onReload={() => {
                loadData();
                onRefresh();
            }}
           data={data} />}
        </div>

        {/* {!formOpened && <div className="row mt-3">
            <button className="btn btn-clear text-blue-light col-6 py-2">CANCEL</button>
            <button className="btn btn-blue-light col-6 py-2">UPDATE</button>
        </div>} */}
    </div>
}

export default InventoryOrderDetails;
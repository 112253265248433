import './QuoteDetail.scss'
import React, {useEffect, useMemo, useRef, useState, memo} from "react";
import QuoteMap from "../../pages/QuotesPage/QuoteMap/QuoteMap";
import {Quote, QuoteVehicleInfo} from "../../classes/classes";
import Helper from "../../helper/Helper";
import Status from "../Status/Status";
import Modal from "../Modal/Modal";
import VehicleRequestForm from "../VehicleRequestForm/VehicleRequestForm";
import LocationEditForm from "../LocationEditForm/LocationEditForm";
import CustomerContactQuoteEdit from "../CustomerContactQuoteEdit/CustomerContactQuoteEdit";
import ICONS from "../../../assets/svg";
import ReactModal from 'react-modal'

type TQuoteDetail = {
    quote: Quote,
    onArchive: (id: number) => void,
    onSendPrice: (quote: Quote) => void,
    reloadRequired: () => void
}

const QuoteDetail = ({quote, onArchive, onSendPrice, reloadRequired}: TQuoteDetail) => {
// memo()
    const [myQuote, setMyQuote] = useState(new Quote());
    const quoteContainerRef = useRef<HTMLDivElement>();
    const [buttonsFocused, setButtonsFocused] = useState(false);

    const [showVehicleModal, setShowVehicleModal] = useState(undefined);
    const [showContactModal, setShowContactModal] = useState(undefined);
    const [showLocationModal, setShowLocationModal] = useState(undefined);

    useEffect(() => {
        // setMyQuote(new Quote());
        setMyQuote(new Quote(quote));
        quoteContainerRef.current?.scrollTo({top: 0, behavior: 'smooth'});
    }, [quote]);



    const onPriceChanged = (price, index, pos = 0) => {
        const dict = {0: 'price', 1: 'nofail_pickup_price', 2: 'nofail_dropoff_price'};
        const temp = [...myQuote.vehicle_info];
        temp[index][dict[pos]] = Helper.Masks.DecimalNumbers(price) as any;
        setMyQuote({...myQuote, vehicle_info: temp});
    }

    // @ts-ignore
    return(

        <>
            {
                showVehicleModal == undefined ? null :
                    <ReactModal isOpen={showVehicleModal != undefined} className={"modal-fit modal-90"}>
                        <VehicleRequestForm
                                            quote={quote}
                                            index={showVehicleModal}
                                            onCancel={() => setShowVehicleModal(undefined)}
                                            onSaved={() => {
                                                setShowVehicleModal(undefined);
                                                reloadRequired();
                                            }} />
                    </ReactModal>
            }
            {
                !showContactModal ? null :
                    <ReactModal isOpen={showContactModal} className={"modal-fit modal-90"}>
                        <CustomerContactQuoteEdit contactQuote={showContactModal}
                                                  onCancel={() => setShowContactModal(undefined)}
                                                  onSaved={() => setShowContactModal(undefined)} />
                    </ReactModal>
            }
            {
                !showLocationModal ? null :
                    <ReactModal isOpen={showLocationModal} className={"modal-fit modal-90"}>
                        <LocationEditForm quote={showLocationModal}
                                          onCancel={() => setShowLocationModal(undefined)}
                                          onSaved={() => {
                                              setShowLocationModal(undefined);
                                              reloadRequired();
                                          }} />
                    </ReactModal>
            }
            {
                myQuote.id == null ?
                    <div className={'p-3'}>
                        <h4 className={'fs-16 mb-1'}>No quote selected</h4>
                        <p className={'fs-14'}>Please select a quote to see details</p>
                    </div>
                    :
                <div className="flex-1-container" ref={quoteContainerRef}>
                    <div className="row p-3 pt-0 pb-0">
                        <div className="col-12 pb-3 sticky-top pt-4">
                            <div className="row align-items-baseline">
                                <div>
                                    <p className="fs-16 font-medium mb-1">Quote #{myQuote.id}</p>
                                    <p className="fs-12">{Helper.FORMAT.USDate(myQuote.time_created)}</p>
                                </div>
                                <Status statusName={myQuote.status} />
                            </div>
                        </div>

                        <div className="col-12 mt-1 mb-3">
                            <p className="fs-12 font-medium">Billing customer</p>
                        </div>

                        <div className="col-12 mb-3">
                            <div className="card bg-gray">
                                <div className="card-body">
                                    <p className="fs-14 font-medium">{myQuote.contact_info.full_name}</p>
                                    {/*<p className="fs-12 font-italic mb-1">Corporate</p>*/}
                                    <p className="fs-12">{myQuote.contact_info.company_name}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-1 mb-3">
                            <p className="fs-12 font-medium">Contact</p>
                        </div>

                        <div className="col-12 mb-3">
                            <div className="card bg-gray cursor-pointer" onClick={() => setShowContactModal(myQuote.contact_info)}>
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="fs-14 font-medium">{myQuote.contact_info.full_name}</p>
                                        <p className="fs-10 font-medium text-light-blue">VERIFIED</p>
                                    </div>
                                    {/*<p className="fs-12 font-italic mb-1">Admin</p>*/}
                                    <p className="fs-12 my-1">{myQuote.contact_info.phone_1}</p>
                                    <p className="fs-12">{myQuote.contact_info.email}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-1 mb-3">
                            <p className="fs-12 font-medium">Locations</p>
                        </div>

                        <div className={'position-relative mb-3'} style={{height: '400px', width: '100%'}}>
                            <QuoteMap pickupLocation={myQuote.pickup.toStringAddress()} dropoffLocation={myQuote.dropoff.toStringAddress()}  />
                        </div>

                        <div className="col-12 mb-3">
                            <div className="card bg-gray cursor-pointer" onClick={() => setShowLocationModal(myQuote)}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-5">
                                            <p className="fs-12 font-medium">{Helper.FORMAT.USDate(myQuote.pickup.pickup_date, 'do')}</p>
                                            <p className="mt-1 fs-12 mb-2">{Helper.FORMAT.USDate(myQuote.pickup.pickup_date, 'to')}</p>

                                            <p className="fs-12 font-medium">{myQuote.pickup.city}, {myQuote.pickup.state}</p>
                                            <p className="mt-1 fs-12 d-flex align-items-center">{myQuote.pickup.postal_code}{myQuote.pickup.guaranteed && <i className="text-red ml-1 fas fa-star"></i>}</p>
                                        </div>

                                        <div className="col-2 mt-3">
                                            <span>__</span>
                                        </div>

                                        <div className="col-5">
                                            <p className="fs-12 font-medium">{Helper.FORMAT.USDate(myQuote.dropoff.dropoff_date, 'do')}</p>
                                            <p className="mt-1 fs-12 mb-2">{Helper.FORMAT.USDate(myQuote.dropoff.dropoff_date, 'to')}</p>

                                            <p className="fs-12 font-medium">{myQuote.dropoff.city}, {myQuote.dropoff.state}</p>
                                            <p className="mt-1 fs-12 d-flex align-items-center">{myQuote.dropoff.postal_code}{myQuote.dropoff.guaranteed && <i className="text-red ml-1 fas fa-star"></i>}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-1 mb-3 d-flex justify-content-between align-items-center">
                            <p className="fs-12 font-medium">Vehicle{myQuote.vehicle_info.length == 1 ? '' : 's'} ({myQuote.vehicle_info.length})</p>
                            <p onClick={(evt) => {
                                const vehicle_info = [...myQuote.vehicle_info];
                                vehicle_info.forEach(x => x.price = 0);
                                setMyQuote({...myQuote, vehicle_info})
                            }
                            } className="cursor-pointer fs-12 font-medium text-light-blue">CLEAR PRICES</p>
                        </div>

                        <div className="col-12">
                            {myQuote.vehicle_info.map((v, index) => <div className="card bg-gray mb-3 cursor-pointer"
                                                                         onClick={() => setShowVehicleModal(index)}>
                                <div className="card-body">
                                    <div className="row">
                                        <div>

                                            <p className={`fs-12 font-medium mb-1${!v.vin ? ' text-gray' : ''}`}>{v.vin || 'NO VIN'}</p>
                                            <p className="fs-12">{v.year} {v.make} {v.model}</p>
                                        </div>
                                        <div>
                                            <p className="fs-12 text-right">{v.equipment}</p>
                                        </div>
                                    </div>




                                    {/*<p className="fs-10 font-italic mt-3">*/}
                                    {/*    "Special instruction for this delivery goes here. Lorem ipsum dolor sit amet,*/}
                                    {/*    consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt."*/}
                                    {/*</p>*/}

                                    {/*<p className="fs-14 text-center">{JSON.stringify(v)}</p>*/}
                                    {<div className="w-100 row justify-content-end mt-1">
                                        <div className="col-12 row">
                                            {!myQuote.pickup.guaranteed ? <div className={'col-6'}></div> : <div className="col-6">
                                                <label className={'font-10 mb-1 font-medium text-red'} htmlFor="">No fail for Pickup</label>
                                                <input onClick={(evt) => evt.stopPropagation()}
                                                       onChange={evt => onPriceChanged(evt.target.value, index, 1)}
                                                       value={v.nofail_pickup_price} onFocus={evt => {
                                                    evt.target.select();
                                                }} placeholder={'0.00'}
                                                       className={'input-prices col-12 py-1 pl-3 dollar-sign border-radius'}/>
                                            </div>}
                                            {quote.dropoff.guaranteed && <div className="col-6">
                                                <label className={'font-10 mb-1 font-medium text-red'} htmlFor="">No
                                                    fail for Dropoff</label>
                                                <input onClick={(evt) => evt.stopPropagation()}
                                                       onChange={evt => onPriceChanged(evt.target.value, index, 2)}
                                                       value={v.nofail_dropoff_price} onFocus={evt => {
                                                    evt.target.select();
                                                }} placeholder={'0.00'}
                                                       className={'input-prices col-12 py-1 pl-3 dollar-sign border-radius'}/>
                                            </div>}
                                        </div>
                                        <div className="col-12 justify-content-end row mt-2">
                                            {/*{myQuote.id}*/}
                                            <div className="col-6">
                                                <label className={'font-10 mb-1'} htmlFor="">Price</label>
                                                <input onClick={(evt) => evt.stopPropagation()} onChange={evt => onPriceChanged(evt.target.value, index)}
                                                       value={v.price} onFocus={evt => {
                                                    evt.target.select();
                                                }} placeholder={'0.00'}
                                                       className={'input-prices col-12 py-1 pl-3 dollar-sign border-radius'}/>
                                            </div>
                                        </div>
                                        {+v.price <= 0 &&
                                        <p className="fs-12 font-medium  text-red mt-1">Price is required</p>}
                                    </div>}
                                </div>
                            </div>)}

                        </div>

                        <div className="col-12 d-flex justify-content-end mb-3">
                            <p className={'font-11 text-light-blue d-flex align-items-center'}><img src={ICONS.IconPlus} alt=""/> ADD VEHICLE</p>
                        </div>
                        <div className="col-12 d-flex justify-content-end align-items-center mb-3">
                            <div className="card bg-gold">
                                <div className="card-body px-3 py-2">
                                    <p className="fs-12 font-medium text-right">Total {Helper.FORMAT.USCurrency(myQuote.vehicle_info.sumBy(x => +x.price + (+x.nofail_pickup_price) + (+x.nofail_dropoff_price)))}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <p className="fs-10 font-medium">Notes</p>
                            <textarea value={myQuote.note} name="notes" placeholder="Notes" id="notes" rows={15}
                            onChange={(el) => setMyQuote({...myQuote, note: el.target.value})}></textarea>
                        </div>

                        {(!quote.archived && !quote.master_order_id) &&  <div className={'row col-12  sticky-bottom'}>
                            <div className={'row col-12 py-3'}>
                                <div className="col-6">
                                    <button className="btn btn-clear text-blue-light btn-block rounded-pill  fs-10"
                                            onClick={(evt) => onArchive(myQuote.id)}>ARCHIVE QUOTE
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button disabled={myQuote.vehicle_info.some(x => +x.price <= 0)} className="btn btn-blue-light btn-block rounded-pill fs-10"
                                            onClick={(evt) => {
                                                const q = new Quote(myQuote);
                                                q.vehicle_info.forEach(x => {
                                                    x.price = +x.price;
                                                    x.nofail_pickup_price = +x.nofail_pickup_price;
                                                    x.nofail_dropoff_price = +x.nofail_dropoff_price;
                                                });
                                                q.price = q.vehicle_info.sumBy(x => x.price + x.nofail_pickup_price + x.nofail_dropoff_price);
                                                setMyQuote(q);
                                                onSendPrice(q);
                                            }}>SEND PRICE
                                    </button>
                                </div>
                            </div>


                        </div>}
                    </div>
                </div>
            }
        </>
    );
}

export default QuoteDetail;

import { gql } from "apollo-boost";
import moment from "moment";
import { FC, useState, useEffect } from "react";
import { Stop } from "../../../../classes/classes";
import { GraphqlService } from "../../../../services/graphql.service";
import Loading from "../../../Loading/Loading";

const queryTimeAtAddress = gql`query($address: address_input, $atTime: String, $format:String){
    get_time_at_address(address: $address, atTime:$atTime, format:$format){
      time_formatted
       timezone {
            code
            name
        }
    }
  } `;

class TimeAtAddressResult {
    time_formatted: string = '';
    timezone: {
        code: string;
        name: string;
    };
}
  const queryUTCAtAddress = gql`query($address: address_input, $time_string: String!){
    get_utc_time_at_address(address: $address, time_string:$time_string)
  }`;
const Scheduled: FC<{stop: Stop}> = ({stop}) => {
    const [data, setData] = useState<TimeAtAddressResult>(new TimeAtAddressResult());
    const [_loading, setLoading] = useState(false);

    async function getDate() {
        try {
            const address = stop.location.address;
            setLoading(true);
            const result = await GraphqlService.SendQuery(queryTimeAtAddress, {address, atTime: stop.time_scheduled, format: 'MM/DD/YYYY HH12:MI AM'});

            setData(result);
            setLoading(false);

        } catch (ex) {
            console.log('Could not get date, ', ex.message);
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!stop.location)
        {
            setData({time_formatted: moment(stop.time_scheduled).format('MM/DD/YYYY hh:mm A'), timezone: null});
        }
        else
            getDate()
    }, [stop.location]);

    if (_loading)
        return <Loading />
    else if (!data?.time_formatted)
        return <p className="font-10">Incorrect address</p>
    return <>
        <p className="font-10">{data.time_formatted.split(' ')[0]}</p>
        <p className="font-10">{data.time_formatted.split(' ')[1]} {data.time_formatted.split(' ')[2]}</p>
        {(data && data.timezone) && <p className="font-10">({data.timezone.code.replace('Standard ', '')})</p>}
    </>
}



export default Scheduled;
import { useState } from "react";
import { useEffect } from "react";
import { Invoice, ServiceCharge } from "../../../classes/classes";
import Helper from "../../../helper/Helper";
import "./OrderInvoicePdf.scss";

const OrderInvoicePdf = ({
  data,
  onPayClicked,
  master_id,
}: {
  data: Invoice;
  onPayClicked: (data: any) => void;
  master_id: number;
}) => {
  const [invoice, setInvoice] = useState(new Invoice());

  useEffect(() => {
    setInvoice(new Invoice(data));
  }, [data]);

  function onTransportationAmountChanged(index, amount) {
    const temp = { ...invoice };
    temp.vehicle_orders[index].transportation_charges.amount_to_charge = amount;
    setInvoice(temp);
  }

  function onVChargeAmountChanged(vo_index, s_index, amount) {
    const temp = { ...invoice };
    temp.vehicle_orders[vo_index].service_charges[s_index].amount_to_charge =
      amount;
    setInvoice(temp);
  }

  function onMChargeAmountChanged(index, amount) {
    const temp = { ...invoice };
    temp.service_charges[index].amount_to_charge = amount;
    setInvoice(temp);
  }

  function handleAllRBalanceClicked() {
    const temp = { ...invoice };
    temp.service_charges.forEach(
      (x) => (x.amount_to_charge = x.amount_remaining)
    );
    temp.vehicle_orders.forEach((vo) => {
      vo.transportation_charges.amount_to_charge =
        vo.transportation_charges.amount_remaining;
      vo.service_charges.forEach(
        (sc) => (sc.amount_to_charge = sc.amount_remaining)
      );
    });
    setInvoice(temp);
  }

  function handleVOrderBalanceClicked(index) {
    const temp = { ...invoice };
    temp.vehicle_orders[index].transportation_charges.amount_to_charge =
      temp.vehicle_orders[index].transportation_charges.amount_remaining;
    temp.vehicle_orders[index].service_charges.forEach(
      (x) => (x.amount_to_charge = x.amount_remaining)
    );

    setInvoice(temp);
  }

  function handleVOrderTransportationBalanceClicked(index) {
    const temp = { ...invoice };
    temp.vehicle_orders[index].transportation_charges.amount_to_charge =
      temp.vehicle_orders[index].transportation_charges.amount_remaining;

    setInvoice(temp);
  }

  function handleVOderServiceBalanceClicked(index, s_index) {
    const temp = { ...invoice };
    temp.vehicle_orders[index].service_charges[s_index].amount_to_charge =
      temp.vehicle_orders[index].service_charges[s_index].amount_remaining;
    setInvoice(temp);
  }

  function handleMServicesBalanceClicked() {
    const temp = { ...invoice };
    temp.service_charges.forEach(
      (x) => (x.amount_to_charge = x.amount_remaining)
    );
    setInvoice(temp);
  }

  function handleMServiceBalanceClicked(index) {
    const temp = { ...invoice };
    temp.service_charges[index].amount_to_charge =
      temp.service_charges[index].amount_remaining;
    setInvoice(temp);
  }

  function handleClearAllBalance() {
    const temp = { ...invoice };
    temp.service_charges.forEach(
      (x) =>
        (x.amount_to_charge = x.amount_remaining < 0 ? x.amount_remaining : 0)
    );
    temp.vehicle_orders.forEach((vo) => {
      vo.transportation_charges.amount_to_charge = 0;
      vo.service_charges.forEach(
        (x) =>
          (x.amount_to_charge = x.amount_remaining < 0 ? x.amount_remaining : 0)
      );
    });
    setInvoice(temp);
  }

  function handleClearOrder(index) {
    const temp = { ...invoice };
    temp.vehicle_orders[index].transportation_charges.amount_to_charge = 0;
    temp.vehicle_orders[index].service_charges.forEach(
      (x) => (x.amount_to_charge = 0)
    );
    setInvoice(temp);
  }

  function handleClearMasterServices() {
    const temp = { ...invoice };
    temp.service_charges.forEach(
      (x) =>
        (x.amount_to_charge = x.amount_remaining < 0 ? x.amount_remaining : 0)
    );
    setInvoice(temp);
  }

  function isValid() {
    let total =
    (invoice.service_charges.sumBy((x) => x.amount_to_charge||0)||0) +
    (invoice.vehicle_orders.sumBy(
      (x) =>
        +(x.transportation_charges.amount_to_charge||0) +
        (x.service_charges.sumBy((y) => y.amount_to_charge||0)||0)
    )||0);
    if (total <= 0) return false;

    for (let x of invoice.service_charges) {
      if (x.amount_to_charge > x.amount_remaining) return false;
    }

    for (let x of invoice.vehicle_orders) {
      if (
        x.transportation_charges.amount_to_charge >
        x.transportation_charges.amount_remaining
      )
        return false;
    }

    for (let x of invoice.vehicle_orders.flatMap((x) => x.service_charges)) {
      if (x.amount_to_charge > x.amount_remaining) return false;
    }
    return true;
  }

  return (
    <>
      <div className="mx-auto order-invoice-pdf" style={{ maxWidth: "1440px" }}>
        <div className="row mt-4">
          <div className="col-2"></div>

          <div className="col-3">
            <p className="font-11">11582 Sheldon St.</p>
            <p className="font-11 mb-2">Sun Valley, CA 91352</p>

            <p className="font-11 mb-1">P | 818-381-8313</p>
            <p className="font-11">F | 818-381-8315</p>
          </div>

          <div className="col-2">
            <p className="font-11">EIN: 80-0472956</p>
          </div>

          <div className="col-5">
            <p className="font-18 font-medium">
              INVOICE For Master #{master_id}
            </p>

            <div className="row mt-3">
              <div className="col-7">
                <p className="font-10 font-medium text-gray mb-1">
                  DATE ISSUED
                </p>
                <p className="font-11">
                  {Helper.FORMAT.USDate(invoice.date_issued, "do")}
                </p>
              </div>

              <div className="col-5">
                <p className="font-10 font-medium text-red mb-1">DUE</p>
                <p className="font-11 font-medium text-red">
                  {Helper.FORMAT.USDate(invoice.date_due, "do")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-2 text-center">
            <p className="font-10 font-medium text-gray">BILLED TO</p>
          </div>

          <div className="col-5">
            <p className="font-10 font-medium text-gray mb-3">CUSTOMER</p>

            <p className="font-12 font-medium mb-1">{invoice.customer.name}</p>
            {/* <p className="font-12 font-medium mb-1">Customer</p>
                    <p className="font-12 font-medium mb-2">3 lines max</p> */}

            <p className="font-11 mb-2">Customer ID: #{invoice.customer.id}</p>

            <p className="font-11 font-medium mb-1">
              {invoice.customer.billing_address.street_number}{" "}
              {invoice.customer.billing_address.street}.
            </p>
            <p className="font-11 font-medium mb-1">
              {invoice.customer.billing_address.city},{" "}
              {invoice.customer.billing_address.state}{" "}
              {invoice.customer.billing_address.postal_code}
            </p>
          </div>

          <div className="col-5">
            <p className="font-10 font-medium text-gray mb-3">
              POINT OF CONTACT
            </p>
            <p className="font-12 font-medium mb-2">
              {invoice.contacts[0]?.name}
            </p>

            <p className="font-11 mb-1">{invoice.contacts[0]?.email}</p>
            {invoice.contacts[0]?.contact.map((x, i) => (
              <p key={"cont-" + i} className="font-11 mb-2">
                {x.value}
              </p>
            ))}

            {invoice.references.map((x) => (
              <p className="font-11 mb-1">
                {x.key} #{x.value}
              </p>
            ))}
          </div>
        </div>

        <div
          className="row mt-2 mb-3 py-3 border-bottom"
          style={{ position: "sticky", top: 48, background: "#F5F8FA" }}
        >
          <div className="col-2 text-center">
            <p className="font-10 font-medium text-gray">ORDER #</p>
          </div>

          <div className="col-4">
            <p className="font-10 font-medium text-gray">
              VEHICLES & DELIVERY DETAILS
            </p>
          </div>

          <div className="col-2">
            <p className="font-10 font-medium text-gray">PRICE</p>
          </div>
          <div className="col-2">
            <p className="font-10 font-medium text-gray">REMAINING BALANCE</p>
          </div>

          <div className="col-2 d-flex justify-content-between">
            <p className="font-10 font-medium text-gray">PAYMENT AMOUNT</p>
            <button
              onClick={(evt) => handleClearAllBalance()}
              className="btn btn-danger p-1"
              style={{ fontSize: ".4rem" }}
            >
              CLEAR ALL
            </button>
          </div>
        </div>

        {invoice.vehicle_orders.map((vo, index) => {
          return (
            <>
              <div className="row">
                <div className="col-2 text-center">
                  <p className="font-16 font-medium mb-1">
                    #{vo.vehicle.vehicle_order_id}
                  </p>

                  {/* <p className="font-10 mb-1">PO #00000</p>
                                    <p className="font-10 mb-1">REF #00000</p> */}
                </div>

                <div className="col-4">
                  <p className="font-16 font-medium mb-1">{vo.vehicle.vin}</p>
                  <p className="font-11 mb-1">{vo.vehicle.description}</p>
                </div>

                <div className="col-2">
                  <p className="font-16 font-medium mb-1">
                    {Helper.FORMAT.USCurrency(
                      vo.transportation_charges.amount +
                        vo.service_charges.sumBy((x) => x.amount)
                    )}
                  </p>
                </div>

                <div className="col-2">
                  <button
                    onClick={(evt) => handleVOrderBalanceClicked(index)}
                    className="font-16 btn-pay text-red cursor-pointer font-medium mb-1"
                  >
                    {Helper.FORMAT.USCurrency(
                      vo.transportation_charges.amount_remaining +
                        vo.service_charges.sumBy((x) => x.amount_remaining)
                    )}
                  </button>
                </div>

                <div className="col-2 d-flex justify-content-end align-items-start">
                  {/* <input type="text" style={{maxWidth: '100%', width: '100%'}} /> */}
                  <button
                    onClick={(evt) => handleClearOrder(index)}
                    className="btn btn-danger p-1"
                    style={{ fontSize: ".4rem" }}
                  >
                    CLEAR ORDER
                  </button>
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-2"></div>
                <div className="col-8">
                  <p className="font-10 font-medium text-gray mb-3">
                    TRANSPORTATION CHARGE
                  </p>
                </div>
                <div className="col-2"></div>
              </div>

              <div className="row">
                <div className="col-2"></div>
                <div className="col-4 row">
                  <div className="col-6">
                    <p className="font-12 font-medium mb-1">
                      {vo.transportation_charges.pickup.title}
                    </p>

                    <p className="font-11 mb-2">
                      {vo.transportation_charges.pickup.address.city},{" "}
                      {vo.transportation_charges.pickup.address.postal_code}
                    </p>
                    <p className="font-12 font-medium">
                      {Helper.FORMAT.USDate(
                        vo.transportation_charges.pickup.time_scheduled,
                        "do"
                      )}{" "}
                      {Helper.FORMAT.USDate(
                        vo.transportation_charges.pickup.time_scheduled,
                        "to"
                      )}
                    </p>
                  </div>

                  <div className="col-6">
                    <p className="font-12 font-medium mb-1">
                      {vo.transportation_charges.dropoff.title || "NO TITLE"}
                    </p>

                    <p className="font-11 mb-2">
                      {vo.transportation_charges.dropoff.address.city},{" "}
                      {vo.transportation_charges.dropoff.address.postal_code}
                    </p>
                    <p className="font-12 font-medium">
                      {Helper.FORMAT.USDate(
                        vo.transportation_charges.dropoff.time_scheduled,
                        "do"
                      )}{" "}
                      {Helper.FORMAT.USDate(
                        vo.transportation_charges.dropoff.time_scheduled,
                        "to"
                      )}
                    </p>
                  </div>
                </div>

                <div className="col-2">
                  <p className="font-12 font-medium mb-1">
                    {Helper.FORMAT.USCurrency(vo.transportation_charges.amount)}
                  </p>
                </div>

                <div className="col-2">
                  {vo.transportation_charges.amount_remaining > 0 && (
                    <button
                      onClick={(evt) =>
                        handleVOrderTransportationBalanceClicked(index)
                      }
                      className="font-12 btn-pay cursor-pointer font-medium mb-1 text-red"
                    >
                      {Helper.FORMAT.USCurrency(
                        vo.transportation_charges.amount_remaining
                      )}
                    </button>
                  )}
                </div>

                <div className="col-2">
                  {vo.transportation_charges.amount_remaining > 0 && (
                    <>
                      <input
                        value={vo.transportation_charges.amount_to_charge}
                        onFocus={(evt) => evt.target.select()}
                        onChange={(evt) =>
                          onTransportationAmountChanged(
                            index,
                            Helper.Masks.DecimalNumbers(evt.target.value)
                          )
                        }
                        type="text"
                        className={
                          "mb-1" +
                          (vo.transportation_charges.amount_to_charge >
                          vo.transportation_charges.amount_remaining
                            ? " invalid"
                            : "")
                        }
                        style={{ maxWidth: "100%", width: "100%" }}
                      />
                      {vo.transportation_charges.amount_to_charge >
                        vo.transportation_charges.amount_remaining && (
                        <p className="fs-14 text-red font-bold">
                          You can't pay more than remaminig balance
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                  <p className="font-10 font-medium text-gray mt-3 mb-3">
                    SERVICE CHARGE
                  </p>
                </div>
                <div className="col-2"></div>
              </div>

              {vo.service_charges.map((sc, j) => (
                <div className="row mb-2">
                  <div className="col-2"></div>
                  <div className="col-4">
                    <p className="font-12 font-medium mb-2">{sc.name}</p>
                  </div>
                  <div className="col-2">
                    <p className="font-12 font-medium mb-2">
                      {Helper.FORMAT.USCurrency(sc.amount)}
                    </p>
                  </div>
                  <div className="col-2">
                    {sc.amount_remaining > 0 && (
                      <button
                        onClick={(evt) =>
                          handleVOderServiceBalanceClicked(index, j)
                        }
                        className="font-12 btn-pay cursor-pointer font-medium mb-2 text-red"
                      >
                        {Helper.FORMAT.USCurrency(sc.amount_remaining)}
                      </button>
                    )}
                  </div>

                  <div className="col-2">
                    {sc.amount_remaining > 0 && (
                      <>
                        <input
                          value={sc.amount_to_charge}
                          onFocus={(evt) => evt.target.select()}
                          onChange={(evt) =>
                            onVChargeAmountChanged(
                              index,
                              j,
                              Helper.Masks.DecimalNumbers(evt.target.value)
                            )
                          }
                          type="text"
                          className={
                            "mb-1" +
                            (sc.amount_to_charge > sc.amount_remaining
                              ? " invalid"
                              : "")
                          }
                          style={{ maxWidth: "100%", width: "100%" }}
                        />
                        {sc.amount_to_charge > sc.amount_remaining && (
                          <p className="fs-14 text-red font-bold">
                            You can't pay more than remaminig balance
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}

              <div className="border-bottom mt-1 mb-3"></div>
            </>
          );
        })}

        <div className="row">
          <div className="row col-12 mb-2">
            <div className="col-2"></div>
            <div className="col-4">
              <p className="font-10 font-medium text-gray mb-3">
                MASTER SERVICE CHARGE
              </p>
            </div>
            <div className="col-2">
              <p className="font-12 font-medium mb-3">
                {Helper.FORMAT.USCurrency(
                  invoice.service_charges.sumBy((x) => x.amount)
                )}
              </p>
            </div>
            <div className="col-2">
              <button
                onClick={(evt) => handleMServicesBalanceClicked()}
                className="font-12 btn-pay cursor-pointer font-medium mb-2 text-red"
              >
                {Helper.FORMAT.USCurrency(
                  invoice.service_charges.sumBy((x) => x.amount_remaining)
                )}
              </button>
            </div>
            {/* <div className="col-2"></div> */}
            <div className="col-2 d-flex justify-content-end align-items-start">
              {/* <input type="text" style={{maxWidth: '100%', width: '100%'}} /> */}
              <button
                onClick={(evt) => handleClearMasterServices()}
                className="btn btn-danger p-1"
                style={{ fontSize: ".4rem" }}
              >
                CLEAR SERVICES
              </button>
            </div>
          </div>
          {invoice.service_charges.map((sc, index) => (
            <div className="row col-12 mb-2">
              <div className="col-2"></div>
              <div className="col-4">
                <p className="font-12 font-medium mb-2">{sc.name}</p>
              </div>
              <div className="col-2">
                <p className="font-12 font-medium mb-2">
                  {Helper.FORMAT.USCurrency(sc.amount)}
                </p>
              </div>
              <div className="col-2">
                {sc.amount_remaining > 0 && (
                  <button
                    onClick={(evt) => handleMServiceBalanceClicked(index)}
                    className="font-12 btn-pay cursor-pointer font-medium mb-2 text-red"
                  >
                    {Helper.FORMAT.USCurrency(sc.amount_remaining)}
                  </button>
                )}
              </div>
              <div className="col-2">
                {sc.amount_remaining > 0 && (
                  <>
                    <input
                      value={sc.amount_to_charge}
                      onFocus={(evt) => evt.target.select()}
                      onChange={(evt) =>
                        onMChargeAmountChanged(
                          index,
                          Helper.Masks.DecimalNumbers(evt.target.value)
                        )
                      }
                      type="text"
                      className={
                        "mb-1" +
                        (sc.amount_to_charge > sc.amount_remaining
                          ? " invalid"
                          : "")
                      }
                      style={{ maxWidth: "100%", width: "100%" }}
                    />
                    {sc.amount_to_charge > sc.amount_remaining && (
                      <p className="fs-14 text-red font-bold">
                        You can't pay more than remaminig balance
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* {invoice.service_charges.length > 0 && <div className='border-bottom mt-1'></div>} */}
      </div>

      <div
        className="py-4"
        style={{
          position: "sticky",
          bottom: 0,
          background: "#F5F8FA",
          boxShadow: "0px -3px 6px #00000029",
          width: "100%",
        }}
      >
        <div className="row order-invoice-pdf">
          <div className="col-12 row pb-3">
            <div className="col-2"></div>
            <div className="col-4">
              <p className="text-red font-16 font-bold">Total to pay</p>
            </div>
            <div className="col-6">
              {invoice && <h4
                className="font-18 text-left text-blue"
                style={{ background: "transparent", border: "none" }}
              >
                {Helper.FORMAT.USCurrency(
                  (invoice.service_charges.sumBy((x) => x.amount_to_charge||0)||0) +
                    (invoice.vehicle_orders.sumBy(
                      (x) =>
                        +(x.transportation_charges.amount_to_charge||0) +
                        (x.service_charges.sumBy((y) => y.amount_to_charge||0)||0)
                    )||0)
                )}
              </h4>}
            </div>
          </div>
          <div className="col-2"></div>
          <div className="col-4 d-flex align-items-center">
            <p className="text-red font-18 font-bold">Total</p>
          </div>

          <div className="col-2 d-flex justify-content-start align-items-end">
            <p className="font-18 font-medium">
              {Helper.FORMAT.USCurrency(
                (invoice.service_charges.sumBy((x) => x.amount || 0) || 0) +
                  (invoice.vehicle_orders.sumBy(
                    (x) =>
                      (x.transportation_charges?.amount || 0) +
                      (x.service_charges?.sumBy((y) => y.amount || 0) || 0))
                   || 0)
              )}
            </p>
          </div>
          <div className="col-2 d-flex justify-content-start align-items-end">
            <button
              onClick={(evt) => handleAllRBalanceClicked()}
              className="font-18 cursor-pointer btn-pay text-red font-medium"
            >
              {Helper.FORMAT.USCurrency(
                (invoice.service_charges.sumBy((x) => x.amount_remaining || 0) || 0) +
                  (invoice.vehicle_orders.sumBy(
                    (x) =>
                      (x.transportation_charges?.amount_remaining || 0) +
                      (x.service_charges?.sumBy((y) => y.amount_remaining || 0) || 0))
                   || 0)
              )}
            </button>
          </div>
          <div className="col-2">
            {/* <input type="text" className='mb-1' style={{maxWidth: '100%', width: '100%'}} /> */}
            <button
              onClick={(evt) => {
                const serviceCharges = invoice.service_charges
                  .filter((x) => +x.amount_to_charge > 0)
                  .map((x) => ({
                    service_charge_id: x.id,
                    name: x.name,
                    amount_to_charge: +x.amount_to_charge,
                  }));
                const transportationCharges = invoice.vehicle_orders
                  .filter((x) => +x.transportation_charges.amount_to_charge > 0)
                  .map((x) => ({
                    id: x.transportation_charges.vehicle_order_id,
                    name: `Tranportation for ${(
                      x.vehicle.vin +
                      " " +
                      x.vehicle.description
                    ).trim()}`,
                    amount_to_charge:
                      +x.transportation_charges.amount_to_charge,
                  }));
                serviceCharges.push(
                  ...invoice.vehicle_orders
                    .flatMap((x) => x.service_charges)
                    .filter((x) => +x.amount_to_charge > 0)
                    .map((y) => ({
                      service_charge_id: y.id,
                      name: y.name,
                      amount_to_charge: +y.amount_to_charge,
                    }))
                );
                serviceCharges.push(
                  ...invoice.service_charges
                    .filter((x) => +x.amount_remaining < 0)
                    .map((y) => ({
                      service_charge_id: y.id,
                      name: y.name,
                      amount_to_charge: +y.amount_remaining,
                    }))
                );
                serviceCharges.push(
                  ...invoice.vehicle_orders
                    .flatMap((x) => x.service_charges)
                    .filter((x) => +x.amount_remaining < 0)
                    .map((y) => ({
                      service_charge_id: y.id,
                      name: y.name,
                      amount_to_charge: +y.amount_remaining,
                    }))
                );
                const totalToCharge =
                (invoice.service_charges.sumBy((x) => x.amount_to_charge||0)||0) +
                (invoice.vehicle_orders.sumBy(
                  (x) =>
                    +(x.transportation_charges.amount_to_charge||0) +
                    (x.service_charges.sumBy((y) => y.amount_to_charge||0)||0)
                )||0);
                onPayClicked({
                  service_payments: serviceCharges,
                  transportation_payments: transportationCharges,
                  totalToCharge,
                });
              }}
              disabled={!isValid()}
              className="btn btn-danger-outline col-12"
            >
              PAY
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderInvoicePdf;

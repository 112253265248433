import {gql} from "apollo-boost";

const GET_ALL = gql`
    query{
        quotes{
            id
            master_order_id
            time_created
            note
            price
            archived
            user {
                id
                name
            }
            contact_info{
                full_name
                email
                phone_1
                phone_2
                company_name
            }
            pickup{
                city
                state
                postal_code
                guaranteed
                pickup_date
            }
            dropoff {
                city
                state
                postal_code
                guaranteed
                dropoff_date
            }
            vehicle_info{
                make
                model
                year
                vin
                equipment
                price
                nofail_pickup_price
                nofail_dropoff_price
            }
        }
    }
`


const GET_BY_CUSTOMER = gql`
    query($customer_id:Int){
        quotes_by_customer(customer_id:$customer_id){
            id
            master_order_id
            time_created
            note
            price
            archived
            user {
                id
                name
            }
            contact_info{
                full_name
                email
                phone_1
                phone_2
                company_name
            }
            pickup{
                city
                state
                postal_code
                guaranteed
                pickup_date
            }
            dropoff {
                city
                state
                postal_code
                guaranteed
                dropoff_date
            }
            vehicle_info{
                make
                model
                year
                vin
                equipment
                price
                nofail_pickup_price
                nofail_dropoff_price
            }
        }
    }
`


const QuoteQueries = {
    GET_ALL,
    GET_BY_CUSTOMER
};

export default QuoteQueries;
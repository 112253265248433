const distribution = true;

const notificationsOn = true;

const SERVER_LINK = distribution ? 'https://api.jplogistics.net' : 'http://38.75.18.145:31000';

const environments = {
    prod: distribution,
    enableQuotesListener: notificationsOn,
    enableNotificationsListener: notificationsOn,
    SERVER_LINK,
    LOGIN_LINK: SERVER_LINK + '/authenticate',
    GOOGLE_API: 'AIzaSyCrvUVLjfkK04rzVHVyDHmx6Ry-24qZEVM',
    JP_CARRIER_ID: 1,
    JP_CARRIER_NAME: 'JP Logistics Hq',
    DEFAULT_BANK_ACCOUNT_ID: 7,
    DEFAULT_CC_BANK_ACCOUNT_ID: 1,
    JP_CUSTOMER_ID: 1363,
    VELOX_LINK: 'https://portal.velox.solutions',
    JP_CUSTOMER_NAME: 'JP Logistics Hq'
}

export default environments;

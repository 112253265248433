import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import Map from "../../libs/map/map";
import {DriverLocation, TrackingDevice} from "../../classes/classes";
import GMap from "../../libs/beta/Map";
import GMarker from "../../libs/gmap/Marker";
import DriverMarkers from "../../libs/gmap/DriverMarkers";
import {useToasts} from "react-toast-notifications";
import {MarkerClass} from "../../libs/beta/MarkerClass";
import RouteClass, {Stop} from "../../libs/beta/RouteClass";
import { PointClass } from "../../libs/beta/PointClass";

const google = window.google;

type IDriverMap = {
    drivers: DriverLocation[],
    trackingData: TrackingDevice[],
    driverOrderRoute?: any,
    showTraffic: boolean,
    selectedDriver: any,
    onDriverSelected: (marker: MarkerClass) => any,
}

const DriverMap = (props: IDriverMap) => {

    const mapRef = useRef<GMap>(null);


    const toast = useToasts();


    useEffect(() => {
        const m = new GMap({
            isDrawable: true,
            loadPlaces: true,
            onMapInit: () => {
                mapRef.current = m
            }
        });
    }, []);

    useEffect(() => {
        mapRef?.current?.toggleTraffic(props.showTraffic);
    }, [props.showTraffic]);

    function fillData() {
        const markers = props.drivers.filter(x => x.devices.length && x.devices[0].coordinate).map((driver) => {
            return new MarkerClass({
                id: driver.id,
                tag: driver.name.split(' ').map(x => x[0]).join('').toUpperCase(),
                position: {
                    lat: driver.devices.length ? driver.devices[0].coordinate.Latitude : '',
                    lng: driver.devices.length ? driver.devices[0].coordinate.Longitude : '',
                },
                text: driver.name,
                selected: false,
                onClick: (marker: MarkerClass) => {
                    selectPoint(marker)
                }
            })
        })

        const markersSamsara = props.trackingData.map(x => new MarkerClass({
            id: x.id,
            tag: x.name.split(' ').map(x => x[0]).join('').toUpperCase(),
            position: {
                lat: x.location.latitude,
                lng: x.location.longitude
            },
            text: x.name,
            onClick: (marker) => {
                selectPoint(marker)
            },
            selected: false,
            color: 'green',
            ref: 'samsara'
        }))

        mapRef.current.drawMarkers([...markers, ...markersSamsara]);
    }

    useEffect(() => {
        if (mapRef.current) {
            fillData();
        }
    }, [props.drivers]);


    const selectPoint = (marker) => {
        props.onDriverSelected(marker);
    }

    useEffect(() => {
        mapRef.current?.markersCollection.map(x => x.info.close())
        if (props.selectedDriver && !props.selectedDriver.show_route) {
            driverCardClick(props.selectedDriver)
        }
    }, [props.selectedDriver]);

    useEffect(() => {

        if(mapRef.current){
            mapRef.current.removeAllRoutes();
        }
        mapRef.current?.markersCollection.map(x => x.info.close())

        if (props.driverOrderRoute?.id) {
            // Let's draw the driver route
            let addresses = props.driverOrderRoute.addresses ?? [];
            let stops: Stop[] = [];
            if (addresses.length == 0)
            {
                const valid_itinerary = props.driverOrderRoute.itinerary?.filter(x => x.orders.length > 0 && x.orders.some(o => !o.completed) && x.orders[0].address);

                if(!valid_itinerary || !valid_itinerary[0]?.orders[0]?.address){
                    throw new Error('Could not find the locatin of next stop');
                }

                let thisDriver = props.drivers.find(x=>x.id == props.driverOrderRoute.id);
                if(!thisDriver){
                    throw new Error('Driver not on map');
                }

                let samsara = props.trackingData.find(x => x.id == thisDriver.samsara_truck_id);

                if(samsara){
                    stops.push(new Stop({
                        address: {
                            lat: samsara.location.latitude,
                            lng: samsara.location.longitude
                        }
                    }));
                }
                else if (thisDriver.devices.length > 0){
                    stops.push(new Stop({
                        address: {
                            lat: thisDriver.devices[0].coordinate.Latitude,
                            lng: thisDriver.devices[0].coordinate.Longitude
                        }
                    }));
                }
                else{
                    throw new Error('Driver not on map');
                }

                stops.push(new Stop({addressString: valid_itinerary[0].orders[0].address}));

            }
            else{
                addresses.forEach((val: string) => {
                    stops.push(new Stop({addressString: typeof val == 'string' ? val : null, address: typeof val !='string' ? new PointClass(val as any) : null}))
                })
            }


            const route = new RouteClass({
                id: props.driverOrderRoute.id,
                stops: stops
            })

            try {
                //console.log('will draw')
                mapRef.current.drawRoute(route);
              

            } catch (e) {
                console.log(e.message);
            }
        }


    }, [props.driverOrderRoute]);

    const driverCardClick = (driver) => {
        let marker = mapRef.current?.getMarkerById(driver?.samsara_truck_id)
        if (marker?.marker?.ref != 'samsara')
            marker = null;



        if (!marker)
        {
            marker = mapRef.current.getMarkerById(driver?.id);
        }




        if (marker)
        {
            mapRef.current.removeAllRoutes();
            marker.toggleInfoWindow(true);
        }
        else
            toast.addToast('Driver is not at the map', {appearance: 'warning', autoDismiss: true})
        return;
        // if (mapRef.current) {
        //     console.log('trigger here??');
        //     const point = mapRef.current.getMarkerById(+driverID);

        //     if (!point) {
        //         return toast.addToast('This driver is not in the map', {appearance: 'error', autoDismiss: true});
        //     }

        //     mapRef.current.triggerMarkerClickById(+driverID)
        // }
    }

    return (
        <>
            {
                <div className={`map`} id="map"></div>
            }
        </>
    )
}

export default DriverMap;

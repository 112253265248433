import React, {useState} from 'react';
import {useEffect} from 'react';
import ICONS from '../../../assets/svg';
import {Charge, Leg, Stop} from '../../classes/classes';
import Helper from '../../helper/Helper';
import ChargeDiscountForm from "../ChargeDiscountForm/ChargeDiscountForm";
import ReactModal from "react-modal";
import useMessageBox from '../../hooks/useMessageBox/useMessageBox';
import { gql } from 'apollo-boost';
import { GraphqlService } from '../../services/graphql.service';
import useLoading from '../../hooks/useLoading/useLoading';
import { useToasts } from 'react-toast-notifications';


interface IServicesData {
    vo_id: number,
    master_id: number,
    charges: Charge[],
    legs: Leg[],
}

type EditServicesProps = {
    servicesData: IServicesData,
    onCancel: () => void,
    onSubmit: (servicesData: IServicesData) => void,
    onClose: (reload: boolean, keepOpen?: boolean) => void
}

const EditServices = (props: EditServicesProps) => {
    const [showAddChargeForm, setShowAddChargeForm] = useState(false);
    const [showEditChargeForm, setShowEditChargeForm] = useState(false);
    const [currentServiceCharge, setCurrentServiceCharge] = useState(new Charge());
    const messageBox = useMessageBox();
    const [listCharges, setListCharges] = useState(props.servicesData.charges);

    // console.log(props.servicesData)
    const loading = useLoading();
    const toast = useToasts();

    const [servicesData, setServicesData] = useState<IServicesData>({
        charges: [],
        vo_id: null,
        master_id: null,
        legs: []
    });

    function handleEditServiceCharge(c) {

        console.log(c.inventory_service)
        setCurrentServiceCharge(new Charge(c));
        setShowEditChargeForm(true);
    }


    async function deleteCharge(charge_id)
    {
        const mutation = gql`
            mutation($charge_id:Int){
                delete_service_charge(charge_id:$charge_id)
            }
        `;

        try {
            loading.open('Deleting charge #' + charge_id + '...');
            const data = await GraphqlService.SendMutation(mutation, {charge_id})
            loading.close();
            props.onClose(true, true);
            const temp = servicesData.charges.removeBy(x => x.id == charge_id);
            setServicesData({...servicesData, charges: temp});
            toast.addToast(`Charge #${charge_id} was deleted`, {appearance: 'success', autoDismiss: true});
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    function handleOnDeleteServiceCharge(currentServiceCharge) {
        // let charges = [...listCharges];
        // charges = charges.filter(e => e.id != currentServiceCharge.id);

        // setListCharges([...charges]);
        // setServicesData({...servicesData, charges: charges});

        messageBox.open({title: 'Are you sure?', message: 'Delete service charge #' + currentServiceCharge.id + ', this action cannot be reverted', buttons: [{text: 'No', css: 'btn btn-clear text-blue-light'}, {text: 'Yes', css: 'btn btn-danger', action: (id) => deleteCharge(id), data: currentServiceCharge.id}]})
    }

    function handleOnEditServiceCharge(newServiceCharge) {
        // let charges = [...servicesData.charges];
        // let index = servicesData.charges.findIndex(e => e.name == currentServiceCharge.name);

        // charges[index] = newServiceCharge;
        // setServicesData({...servicesData, charges: charges});

        // setShowEditChargeForm(false);
        // setCurrentServiceCharge(new Charge());
        setShowEditChargeForm(false);
        props.onClose(true, true);
        
    }

    function handleOnAddServiceCharge(newServiceCharge) {
        // setShowAddChargeForm(false);
        // setCurrentServiceCharge(new Charge());

        // // new service value
        // let charges = [...servicesData.charges];
        // charges.push(newServiceCharge);
        // setServicesData({...servicesData, charges: charges});
        setShowAddChargeForm(false);
        props.onClose(true, true);
    }

    function onLegPriceChange(index, price, is_pickup?) {
        price = Helper.Masks.DecimalNumbers(price);
        const legs = [...servicesData.legs];
        if (is_pickup == null)
            legs[index].equipment_price = price;
        else {
            const stop = new Stop(legs[index].stops.find(x => x.is_pickup == is_pickup));
            stop.nofail_price = price;
            legs[index].setStop(stop);
            // console.log(stop)
        }
        setServicesData({...servicesData, legs});
    }


    function handleChangeNoFail(index, is_pickup: boolean) {
        const legs = [...servicesData.legs];
        const stop = legs[index].stops.find(x => x.is_pickup == is_pickup);
        stop.nofail = !stop.nofail;
        stop.nofail_price = 0;
        legs[index].setStop(stop);

        setServicesData({...servicesData, legs});

    }

    useEffect(() => {
        console.log(props.servicesData);
        setServicesData({...props.servicesData});
    }, [props.servicesData]);


    function getLegsServices() {
        return servicesData.legs.map((leg, index) => {
            return <div key={index} className='row my-2 pb-2 border-bottom'>
                <div className='col-3'>
                    <p className='font-12 text-gray font-medium'>LEG-{leg.id}</p>
                    <p className='font-12 mt-1'>{leg.equipment}</p>
                </div>
                <div className='col-7'>
                    <div className="row">
                        <div onClick={(evt) => handleChangeNoFail(index, true)}
                             className={`cursor-pointer no-select col-6 font-11${leg.getPickup().nofail ? ' text-red' : ''}`}>
                            <p>{leg.getPickup().location.address.street_number} {leg.getPickup().location.address.street}</p>
                            <p>{leg.getPickup().location.address.city}, {leg.getPickup().location.address.state} {leg.getPickup().location.address.postal_code}</p>
                            {leg.getPickup().nofail && <p className='mt-1 font-medium'>NO FAIL</p>}
                        </div>
                        <div onClick={(evt) => handleChangeNoFail(index, false)}
                             className={`cursor-pointer no-select col-6 font-11${leg.getDropoff().nofail ? ' text-red' : ''}`}>
                            <p>{leg.getDropoff().location.address.street_number} {leg.getDropoff().location.address.street}</p>
                            <p>{leg.getDropoff().location.address.city}, {leg.getDropoff().location.address.state} {leg.getDropoff().location.address.postal_code}</p>
                            {leg.getDropoff().nofail && <p className='mt-1 font-medium'>NO FAIL</p>}
                        </div>

                    </div>
                </div>
                <div className='col-2 d-flex align-items-center'>
                    <span className='font-11 mr-1'>$</span><input
                    onChange={(evt) => onLegPriceChange(index, evt.target.value)} onFocus={(evt) => evt.target.select()}
                    value={leg.equipment_price} style={{maxWidth: '100%', width: '100%'}} type="text"/>
                </div>

                {leg.stops.filter(x => x.nofail).sort((x, y) => !x.is_pickup ? 1 : -1).map((s, i) => {
                    return <div className={`col-12 row mt-2`}>
                        <div className='col-3'>
                            {/* LEG-{leg.id} */}
                        </div>
                        <div className='col-7'>
                            <p className='font-11'>NO FAIL FOR {s.is_pickup ? 'PICKUP' : 'DROPOFF'}</p>
                        </div>
                        <div className='col-2 d-flex align-items-center'>
                            <span className='font-11 mr-1'>$</span><input
                            onChange={(evt) => onLegPriceChange(index, evt.target.value, s.is_pickup)}
                            onFocus={(evt) => evt.target.select()} value={s.nofail_price}
                            style={{maxWidth: '100%', width: '100%'}} type="text"/>
                        </div>
                    </div>
                })}
            </div>
        })
    }

    return <div style={{height: '100%'}} className='d-flex flex-column flex-nowrap'>
        <div className="mb-3 row">
            <h4 className="font-12">EDIT SERVICES
                FOR {props.servicesData.vo_id != null ? `VO-${props.servicesData.vo_id}` : `M-${props.servicesData.master_id}`}</h4>
            <h4 className="font-12 d-flex align-items-center text-light-blue cursor-pointer"
                onClick={() => setShowAddChargeForm(true)}><img src={ICONS.IconPlus} alt=""/> ADD NEW SERVICE</h4>
        </div>
        <div style={{height: '100%', maxHeight: '100%', overflow: 'auto'}}>
            {getLegsServices()}

            {servicesData.charges.map((c, index) => {
                return <div className='row my-2 pb-2 border-bottom'>
                    <div className="col-3">
                        <p className='font-11 mb-1 text-gray font-medium'>SC-{c.id} {c.inventory_service?.service_charge_id  && <span className='text-light-blue'>(Inventory)</span>}</p>
                        <div className="d-flex">
                            <p className='font-9 mt-1 font-medium cursor-pointer no-select mr-2 btn btn-blue-light'
                               onClick={() => handleEditServiceCharge(c)}>EDIT</p>
                            <p className='font-9 mt-1 btn btn-danger font-medium cursor-pointer no-select'
                               onClick={() => handleOnDeleteServiceCharge(c)}>DELETE</p>
                        </div>
                    </div>
                    <div className="col-7">
                        <p className="font-11 mb-1 font-bold">{c.name}</p>
                        {c.inventory_service?.service_charge_id && <p className="font-11"><span> {c.inventory_service.completed ? 'Completed on: ' + (Helper.FORMAT.USDate(c.inventory_service.time_completed)): 'Due by: '  + (Helper.FORMAT.USDate(c.inventory_service.time_completed))}</span></p>}
                        
                        
                    </div>
                    <div className="col-2">
                        <p className="font-11">{Helper.FORMAT.USCurrency(c.amount)}</p>
                    </div>
                </div>
            })}
        </div>

        <div className='mt-3 row'>
            <button className="btn btn-clear text-blue-light col-6 py-2" onClick={props.onCancel}>CLOSE</button>
            {/* <button className="btn btn-blue-light col-6 py-2" onClick={() => props.onSubmit(servicesData)}>CONFIRM</button> */}
        </div>

        <ReactModal isOpen={showEditChargeForm} className="modal-fit modal-90">
            <ChargeDiscountForm 
                                vehicle_order_id={servicesData.vo_id}
                                master_order_id={servicesData.master_id}
                                charge={currentServiceCharge ? currentServiceCharge : new Charge()}
                                onSubmit={(evt) => handleOnEditServiceCharge(evt) }
                                onCancel={() => setShowEditChargeForm(false) }/>
        </ReactModal>

        <ReactModal isOpen={showAddChargeForm} className="modal-fit modal-90">
            <ChargeDiscountForm
                                vehicle_order_id={servicesData.vo_id}
                                master_order_id={servicesData.master_id}
                                charge={new Charge()}
                                onSubmit={(evt) => handleOnAddServiceCharge(evt) }
                                onCancel={() => setShowAddChargeForm(false) }/>
        </ReactModal>
    </div>
}

export default EditServices;

import Table from "../Table/Table";
import IColumn from "../Table/models/IColumn";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {AllStates} from "../../redux/reducers";
import {ICarrierState} from "../../redux/reducers/Carrier.reducer";




const AgingTable = ({data, loading, onCarrierClicked, menuOptions, preventSelected = false, excludeDrivers = false}) => {

    const carrier = useSelector<AllStates>(states => states.carriers) as ICarrierState;
    const columns: IColumn[] = [

        { label: 'ID #', active: false, orderBy: 'DESC', name: '_id', sort_by: 'id' },
        { label: excludeDrivers ? 'DRIVER' : 'CARRIER', active: false, orderBy: 'DESC', name: 'name' },
        { label: 'DRIVERS DISPATCHED', active: false, orderBy: 'DESC', name: 'drivers_dispatched' },
        { label: 'LEGS DISPATCHED', active: false, orderBy: 'DESC', name: 'orders_dispatched' },
        { label: 'LATE', active: false, orderBy: 'DESC', name: 'late' },
        { label: 'ON TIME', active: false, orderBy: 'DESC', name: 'on_time' },
        { label: 'PICKED UP', active: false, orderBy: 'DESC', name: 'picked_up' },
        { label: 'COMPLETED', active: false, orderBy: 'DESC', name: 'completed' },
    ]

    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows([...data].map((e, i) => {
            return {...e, _id: '#' + (e.carrier_id || e.driver_id), on_time: e.orders_dispatched - e.late, border_left: e['valid'], selected: e.carrier_id == carrier.id && !preventSelected, id: e.carrier_id, driver_id: e.driver_id }
        }))
    }, [data]);

    return(
        <>
            <Table firstColumnNerrow
                   columns={columns.filter(x => !(x.name == 'drivers_dispatched' && excludeDrivers))}
                   loading={loading} rows={rows}
                   tableHeight={'81vh'}
                   menuOptions={menuOptions}
                   onRowClicked={({id, name, driver_id}) => onCarrierClicked({id: id ?? driver_id, name})}/>
        </>
    );
}

export default AgingTable;

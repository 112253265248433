import React, {useState, useRef} from 'react'
import Status from '../../components/Status/Status';
import ICONS from "../../../assets/svg";
import './VehicleDetails.scss'
import { StorageOrder } from '../../classes/classes';


const VehicleDetails = ({index, vehicle, onEditVehicleBtnClicked, onMarkAsStorageClicked}) => {

    const handleEditVehicleBtn = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        onEditVehicleBtnClicked(evt);
    }

    return (
        <>
            

            <div className="row ">
                <div className="col-12 d-flex justify-content-between align-items-start mb-4">
                    <p className="fs-14 font-medium">Vehicle details</p>
                    <button className="btn btn-blue-light rounded-pill px-3 py-2"
                            onClick={handleEditVehicleBtn}>EDIT VEHICLE
                    </button>
                </div>

                <div className="col-12">
                    <div className="card bg-gray">
                        <div className="card-body">
                            <div className="row col-12">
                                <p className="fs-14 font-medium">{vehicle?.vehicle?.getName()}</p>
                                <p className="fs-14 font-medium">VO-{vehicle?.id}</p>
                            </div>
                            <p className="fs-12 mb-3">{vehicle?.vehicle?.vin}</p>

                            <p className="fs-12 font-medium d-flex justify-content-between align-items-center">
                                <span><span className="dot"></span> {vehicle?.vehicle?.color}</span>
                                {/* {vehicle?.storage_order.active && <span className='d-flex align-items-center mt-0'><img className='mr-1' src={ICONS.INVENTORY} alt="" /> <p className='pt-1'>This is Storage</p></span>} */}
                                <button onClick={(evt) => onMarkAsStorageClicked({storage: new StorageOrder({...vehicle?.storage_order, vehicle_order_id: vehicle?.id}), index, charges: vehicle?.charges.filter(x => x.storage_charge?.id)})} className={`btn font-9 btn-blue-light${vehicle?.storage_order?.active ? '' : '-outline'}`}>{vehicle.storage_order?.active ? 'This is Storage' : 'Mark as Storage'} {vehicle?.storage_order?.active && <i className="far fa-check-circle"></i>}</button>
                            </p>
                        </div>
                    </div>
                </div>


                <div className="col-6">
                    {
                        vehicle?.driver_note &&
                        <>
                            <p className="fs-12 font-medium mb-2">Vehicle notes</p>
                            <p className="fs-14 font-italic mb-4">{vehicle?.driver_note}</p>
                        </>
                    }
                    {
                        vehicle?.bol_comment &&
                        <>
                            <p className="fs-12 font-medium mb-2">BOL comment</p>
                            <p className="fs-14 font-italic">{vehicle?.bol_comment}</p>
                        </>
                    }
                </div>

            </div>
        </>
    )
}

export default VehicleDetails

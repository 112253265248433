import './CarriersDriversTable.scss'
import React, {useEffect, useRef, useState} from "react";
import Searchbar from "../Searchbar/Searchbar";
import AgingTable from "../AgingTable/AgingTable";
import {GraphqlService} from "../../services/graphql.service";
import CarrierQueries from "../../graphql/query/Carrier";
import ICONS from "../../../assets/svg";
import Helper from "../../helper/Helper";
import ReactModal from "react-modal";
import NewCarrierForm from "../NewCarrierForm/NewCarrierForm";
import { gql } from 'apollo-boost';
import { useToasts } from 'react-toast-notifications';

const CarriersDriversTable = ({onCarrierSelected, showHeader = true, preventSelected = false}) => {
    const ref = useRef();
    const [tab, setTag] = useState('Aging');
    const toast = useToasts();


    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [openCarrierForm, setOpenCarrierForm] = useState(false);

    const menuOptions = [
        {
            label: 'See carrier details',
            icon: ICONS.IconSearch,
            condition: () => true,
            action: (row) => {
                Helper.Navigation.NavigateTo('/carriers/' + row.id + '/profile');
            }
        },
        {
            label: 'Send signup email',
            icon: ICONS.IconEmail,
            condition: () => true,
            action: async (row) => {
                try {
                    let carrier_id = row.carrier_id;
                    const data: {success: boolean, message: string} = await GraphqlService.SendQuery(gql`
                    mutation($carrier_id: Int!){
                        send_signup_email(carrier_id: $carrier_id){
                            message
                            success
                        }
                    }`, {carrier_id});

                    if(!data?.success){
                        throw new Error(data?.message || 'No response from the server');
                    }
                    toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
                } catch (error) {
                    toast.addToast(error.message, {appearance: 'error', autoDismiss: true});
                    
                }
            }
        }
    ];

    async function load()
    {
        try {
            setLoading(true);
            const data = await GraphqlService.SendQuery(CarrierQueries.GET_AGING, {showempty: true})
            //console.log(data);
            // console.log(JSON.stringify(data));
            setRows(data || []);
            setLoading(false);
        }
        catch (ex)
        {
            setLoading(false);
            console.log(ex.message);
        }
    }

    useEffect(() => {
        load().then();
        // console.log('here');
    }, []);

    return(
        <>
            <div className="row flex-1-container">
                <div className={'mb-3'}>
                    {showHeader && <div className="col-12 mb-3 d-flex justify-content-between">
                        <p className="m-0 font-medium font-12">Carriers</p>

                        <h4 onClick={(evt) => {setOpenCarrierForm(true)}}
                            className='m-0 btn-text'>
                            <img src={ICONS.IconPlus} alt=""/> New Carrier
                        </h4>
                    </div>}


                    {showHeader && <div className="col-12 d-flex justify-content-between align-items-center">
                        <p className="font-medium font-12">{rows?.length} active carriers</p>

                        <Searchbar reference={ref}
                                   background={'#F3F6F8'}
                                   placeholder={'Search for carrier'} value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)}/>
                    </div>}

                </div>
                {
                    tab == 'Aging' ? <AgingTable menuOptions={menuOptions}
                                                 preventSelected={!showHeader || preventSelected}
                                                 onCarrierClicked={(evt) => onCarrierSelected(evt)}
                                                 loading={loading}
                                                 data={rows.filter(x => x.name.toLowerCase().trim().includes(searchTerm.toLowerCase().trim()))} /> : null
                }
            </div>

            <ReactModal isOpen={openCarrierForm} className={"modal-fit modal-large modal-h"}>
                {
                    <NewCarrierForm onCancel={(evt) => { setOpenCarrierForm(false) }}
                                    onSubmit={(evt) => {
                                        setOpenCarrierForm(false);
                                        load().then();
                                    } } />

                }
            </ReactModal>
        </>
    );
}

export default CarriersDriversTable;

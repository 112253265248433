import {Leg_Request_Input} from "../change_request";
import {StandardResult} from "../../leg/leg";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";

export const create_change_request = (input:{current: Leg_Request_Input, pending: Leg_Request_Input, carrier_message: string, hours: number}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation ($current: Leg_request_input, $pending: Leg_request_input, $hours: Int, $carrier_message: String) {
      create_change_request(current: $current, pending: $pending, hours: $hours, carrier_message: $carrier_message) {
          id
          message
          success
      }
    }
  `;

  return GraphqlService.SendQuery(mutation, input) as  //, {current, pending, carrier_message, hours} as 
  Promise<StandardResult>;
}
import {Address, LocationInput} from "../location/location";
import {Carrier, Velox_Carrier, Velox_Driver} from "../carrier/carrier";
import {Customer} from "../customer/customer";
import {Stop, StopInput, Time_Frame_Input} from "../stop/stop";
import {Service_Charge} from "../service_charge/service_charge";
import {Vehicle_Order} from "../vehicle_order/vehicle_order";
import {Vehicle} from "../vehicle/vehicle";
import {Broker} from "../invoice/invoice";
import { Driver } from "../driver/driver";
import moment from "moment";

// export class Leg {
//     __typename?: "Leg"
//     id?: number;
//     vehicle_order_id?: number;
//     vehicle_order?: Vehicle_Order;
//     time?: string;
//     status?: string;
//     time_completed?: string;
//     completed?: boolean;
//     canceled?: boolean;
//     equipment?: string;
//     velox_order_id?: number;
//     stops?: Stop[]
//     service_charges?: Service_Charge[]
//     driver?: Velox_Driver;
//     carrier?: Velox_Carrier;
//     carrier_pay?: number;
//     driver_pay?: number;
//     remaining_carrier_receivable?: number;
//     remaining_driver_payable?: number;
//     velox_order?: Velox_Status;
//     pending_change_request?: boolean;
// }

export class Leg {
    id?: number = null;
    vehicle_order_id?: number = null;
    time?: string | Date = new Date();
    time_completed?: string | Date = new Date();
    status?: string = '';
    driver?: Driver = new Driver();
    carrier?: Carrier = new Carrier();
    completed?: boolean = false;
    canceled?: boolean = false;
    equipment?: string = '';
    stops?: Stop[] = [new Stop(), new Stop(null, false)];
    equipment_price?: number = 0.00;
    service_charges?: Service_Charge[] = [];
    velox_order_id?: number = null;
    carrier_pay?: number = 0;
    driver_pay?: number = 0;
    remaining_carrier_receivable?: number = 0;
    remaining_driver_payable?: number = 0;
    vehicle_order?: Vehicle_Order;
    velox_order?: Velox_Order = new Velox_Order();


    constructor(x?: Leg) {
        if (x) {
            this.id = x.id;
            this.vehicle_order_id = x.vehicle_order_id;
            this.time = new Date(x.time);
            this.time_completed = new Date(x.time_completed);
            // this.status = x.status;
            // alert(x.status)
            // console.log('driver', x.driver);
            this.driver = new Driver(x.driver);
            this.carrier = new Carrier(x.carrier);
            this.completed = x.completed;
            this.canceled = x.canceled;
            this.equipment = x.equipment;
            this.equipment_price = x.equipment_price || 0;




            this.equipment_price = x.service_charges?.[0]?.amount || x.equipment_price || 0;
            this.equipment = x.service_charges?.[0]?.name || x.equipment || '';
            
            this.service_charges = x.service_charges?.map(z => {
                z.leg_id = x.id;
                return new Service_Charge(z);
            }) || [];
            // console.log('here inside leg')
            this.stops = !x.stops ? [new Stop(), new Stop(null, false)] : x.stops.map(s => (new Stop(s)));
            x.stops.sort((x, y) => x.is_pickup ? 1 : -1);

            this.velox_order_id = x.velox_order_id || x.velox_order?.id;
            this.velox_order = new Velox_Order(x.velox_order);
            this.driver_pay = x.driver_pay;
            this.remaining_carrier_receivable = x.remaining_carrier_receivable;
            this.remaining_driver_payable = x.remaining_driver_payable;
            this.vehicle_order = x.vehicle_order;

            this.carrier_pay = x.carrier_pay || (x as any).price || 0;
        } else {
            this.stops = [new Stop(), new Stop(null, false)];
            this.stops.sort((x, y) => x.is_pickup ? 1 : -1);
        }
    }

    getDate() {
        return moment(this.time).format('MM/DD/YYYY');
    }

    getTime() {
        return moment(this.time).format('hh:mm a');
    }

    getServiceChargesTotal() {
        return this.equipment_price;
    }

    getPickup() {
        return this.stops.find(x => x.is_pickup == true);
    }

    getDropoff() {
        return this.stops.find(x => x.is_pickup == false);
    }

    getJobInterval?() {
        const init = moment(this.getPickup()?.time_scheduled)
        const end = moment(this.getDropoff()?.time_scheduled)
        const duration = moment.duration(end.diff(init));
        const h = parseInt(String(duration.asHours()));
        const m = parseInt(String(duration.asMinutes())) % 60;
        return `${h > 0 ? h : '00'}h ${m > 0 ? m : '00'}m`;
    }

    getNoFailCount() {
        return this.stops.filter(e => e.nofail).length;
    }

    setStop(stop: Stop) {
        const index = this.stops.findIndex(x => x.is_pickup == stop.is_pickup);
        if (index > -1)
            this.stops[index] = new Stop(stop);
    }
}

export class Velox_Order {
    __typename?: "velox_Order"
    id?: number = null;
    price?: number = 0;
    carrier_pay?: number = 0;
    vehicle?: Vehicle = new Vehicle();
    broker?: Broker = new Broker();
    driver?: Velox_Driver = new Velox_Driver();
    origin?: Velox_Destination = new Velox_Destination();
    destination?: Velox_Destination = new Velox_Destination();
    completed?: boolean = false;
    driver_pay?: number = 0;
    accepted?: boolean = false;
    rejected?: boolean = false;
    canceled?: boolean = false;
    expired?: boolean = false;
    expires?: string | Date = new Date();
    carrier?: Velox_Carrier = new Velox_Carrier();
    time?: string | Date = new Date();
    remaining_carrier_receivable?: number = 0;
    remaining_driver_payable?: number = 0;
    velox_order_id?: number = null;
    pending_change_request?: boolean = false;
    change_requests?: Velox_Change_Request[] = [];
    broker_note?: string = '';

    constructor(x?: Velox_Order){
        if(x){
            this.id = x.id;
            this.price = x.price;
            this.carrier_pay = x.carrier_pay;
            this.vehicle =  new Vehicle(x.vehicle);
            this.broker = new Broker(x.broker);
            this.driver = new Velox_Driver(x.driver);
            this.origin =  new Velox_Destination(x.origin);
            this.destination = new Velox_Destination( x.destination);
            this.completed = x.completed;
            this.driver_pay = x.driver_pay;
            this.accepted = x.accepted;
            this.rejected = x.rejected;
            this.canceled = x.canceled;
            this.expired = x.expired;
            this.expires =  new Date(x.expires);
            this.carrier =  new Velox_Carrier(x.carrier);
            this.time =  new Date(x.time);
            this.remaining_carrier_receivable = x.remaining_carrier_receivable;
            this.remaining_driver_payable = x.remaining_driver_payable;
            this.velox_order_id = x.velox_order_id;
            this.pending_change_request = x.pending_change_request;
            this.change_requests = x.change_requests?.map(z => z) || [];
            this.broker_note = x.broker_note;            
        }
    }
}

export class Velox_Status {
    __typename?: "Velox_status"
    id?: number = null;
    accepted?: boolean = false;
    rejected?: boolean = false;
    canceled?: boolean = false;
    expired?: boolean = false;
    expires?: string = '';
    change_requests?: Velox_Change_Request[] = [];

    constructor (x?: Velox_Status){
        if(x){
            this.id = x.id;
            this.accepted = x.accepted;
            this.rejected = x.rejected;
            this.canceled = x.canceled;
            this.expired = x.expired;
            this.expires = x.expires;
            this.change_requests = x.change_requests?.map(z => z) || [];
        }
    }
}

export class Velox_Destination {
    __typename?: "Velox_destination"
    nofail?: boolean = false;
    address?: Address = new Address();
    scheduled?: string = '';
    time_completed?: string = '';
    completed?: boolean = false;
    contacts?: Velox_Contacts[] = [];

    constructor (x?: Velox_Destination){
        if(x){
            this.nofail = x.nofail;
            this.address =  new Address(x.address);
            this.scheduled = x.scheduled;
            this.time_completed = x.time_completed;
            this.completed = x.completed;
            this.contacts = x.contacts?.map(z => z) || [];            
        }
    }
}

export class Velox_Contacts {
    __typename?: "velox_Contacts"
    name?: string = '';
    value?: string[] = [];
    id?: number = null;

    constructor (x?: Velox_Contacts){
        if(x){
            this.name = x.name;
            this.value = x.value;
            this.id = x.id;
        }
    }
}

export class Velox_Change_Request {
    __typename?: "Velox_change_request"
    id?: number = null;
    order_id?: number = null;
    accepted?: boolean = false;
    rejected?: boolean = false;
    time?: string | Date = new Date();
    payout?: number = 0;
    note?: string = '';
    active?: boolean = false;
    expired?: boolean = false;
    expires_at?: string | Date = new Date();
    weblink?: string = '';
    code?: string = '';

    constructor (x?: Velox_Change_Request){
        if(x){
            this.id = x.id;
            this.order_id = x.order_id;
            this.accepted = x.accepted;
            this.rejected = x.rejected;
            this.time = new Date(x.time);
            this.payout = x.payout;
            this.note = x.note;
            this.active = x.active;
            this.expired = x.expired;
            this.expires_at = new Date(x.expires_at);
            this.weblink = x.weblink;
            this.code = x.code;            
        }
    }
}

export class LegInput {
    stops?: StopInput[] = [];
    status?: string = '';
    vehicle_order_id?: number = null;
    equipment?: string = '';
    equipment_price?: number = 0;
    dispatch?: NewDispatch = new NewDispatch();

    constructor (x?: LegInput){
        if(x){
            this.stops = x.stops?.map(z => z) || [];
            this.status = x.status;
            this.vehicle_order_id = x.vehicle_order_id;
            this.equipment = x.equipment;
            this.equipment_price = x.equipment_price;
            this.dispatch = new NewDispatch(x.dispatch);            
        }
    }
}

export class Leg_Update_Input {
    id?: number = null;
    price?: number = 0.00;
    equipment?: string = '';
    equipment_price?: number = 0.00;
    vehicle_order_id?: number = null;
    master_order_id?: number = null;
    velox_order_id?: number = null;
    pickup?: Pickup_Dropoff_Stop = new Pickup_Dropoff_Stop();
    dropoff?: Pickup_Dropoff_Stop = new Pickup_Dropoff_Stop(null, false);
    carrier_id?: number = null;
    driver_id?: number = null;

    constructor (x?: Leg_Update_Input){
        if(x){
            this.id = x.id;
            this.price = x.price;
            this.equipment = x.equipment;
            this.equipment_price = x.equipment_price;
            this.vehicle_order_id = x.vehicle_order_id;
            this.master_order_id = x.master_order_id;
            this.velox_order_id = x.velox_order_id;
            this.pickup = new Pickup_Dropoff_Stop(x.pickup);
            this.dropoff = new Pickup_Dropoff_Stop(x.dropoff);
            this.carrier_id = x.carrier_id;
            this.driver_id = x.driver_id;            
        }
    }
}

export class NewDispatch {
    driver_id?: number = null;
    carrier_id: number = null;
    price: number = 0;

    constructor (x?: NewDispatch){
        if(x){
            this.driver_id = x.driver_id;
            this.carrier_id = x.carrier_id;
            this.price = x.price;           
        }
    }
}

export class Basic_Id_Name_Input {
    id?: number = null;
    name?: string = '';
    key?: string = '';

    constructor(x?: Basic_Id_Name_Input){
        if(x) {
            this.id = x.id;
            this.name = x.name;
            this.key   = x.key;
        }

    }
}

export class Dispatch_Response {
    __typename?: "dispatch_response"
    driver?: Velox_Driver = new Velox_Driver();
    leg_id?: number = null;
    price?: number = 0;
    origin?: Destination = new Destination();
    carrier?: Velox_Carrier = new Velox_Carrier();
    vehicle?: Vehicle = new Vehicle();
    customer?: Customer = new Customer();
    equipment?: string = '';
    destination?: Destination = new Destination();
    velox_order_id?: number = null;
    master_order_id?: number = null;
    vehicle_order_id?: number = null;
    velox_order?: Velox_Status = new Velox_Status();
    pending_change_request?: boolean = false;

    constructor (x?: Dispatch_Response){
        if(x){
            this.driver = new Velox_Driver(x.driver);
            this.leg_id = x.leg_id;
            this.price = x.price;
            this.origin = new Destination(x.origin);
            this.carrier = new Velox_Carrier(x.carrier);
            this.vehicle =  new Vehicle(x.vehicle);
            this.customer = new Customer( x.customer);
            this.equipment = x.equipment; 
            this.destination =  new Destination(x.destination);
            this.velox_order_id = x.velox_order_id; 
            this.master_order_id = x.master_order_id;
            this.vehicle_order_id = x.vehicle_order_id;
            this.velox_order = new Velox_Status(x.velox_order);
            this.pending_change_request = x.pending_change_request;            
        }
    }
}

export class Pickup_Dropoff_Stop {
    id?: number = null;
    time_scheduled?: string | Date = new Date();
    is_pickup?: boolean = true;
    location?: LocationInput = new LocationInput();
    nofail?: boolean = false;
    nofail_price?: number = 0;
    contacts?: Conctacts_Pickup_Dropoff[] = [];
    time_frame?: Time_Frame_Input = new Time_Frame_Input();

    constructor(x?: Pickup_Dropoff_Stop, isPickup: boolean = true ){
        if(x){
            this.id = x.id;
            this.time_scheduled = new Date(x.time_scheduled );
            this.is_pickup = x.is_pickup;
            this.location = new LocationInput(x.location );
            this.nofail = x.nofail;
            this.nofail_price = x.nofail_price;
            this.contacts = x.contacts?.map(z => z) || [];
            this.time_frame = new Time_Frame_Input(x.time_frame);  
        }
        else {
            this.is_pickup = isPickup;
        }
    }
}

export class Conctacts_Pickup_Dropoff {
    id: number = null;

    constructor(x?: Conctacts_Pickup_Dropoff){
        if(x){
            this.id = x.id;
        }
    }
}

export class StandardResult {
    __typename?: "StandardResult"
    id?: number = null;
    message?: string = '';
    success?: boolean = false;

    constructor (x?:StandardResult){
        if(x){
            this.id = x.id;
            this.message = x.message;
            this.success = x.success;
        }
    }
}

export class Destination {
    __typename?: "Destination"
    address?: Address = new Address();
    completed?: boolean = false;
    time_completed?: string = '';
    time_scheduled?: string = '';
    nofail?: boolean = false;
    timezoneName?: string = '';
    contacts?: Dest_Cont[] = [];

    constructor (x?: Destination){
        if(x){
            this.address = new Address(x.address);
            this.completed = x.completed;
            this.time_completed = x.time_completed;
            this.time_scheduled = x.time_scheduled;
            this.nofail = x.nofail;
            this.timezoneName = x.timezoneName;
            this.contacts = x.contacts?.map(z => z) || [];            
        }
    }
}

export class Dest_Cont {
    __typename?: "dest_cont"
    name?: string = '';
    value?: string = '';

    constructor (x?: Dest_Cont){
        if(x){
            this.name = x.name;
            this.value = x.value;
        }
    }
}

import {Vehicle_Order} from "../vehicle_order/vehicle_order";
import {Service_Charge} from "../service_charge/service_charge";
import {Vehicle} from "../vehicle/vehicle";
import {Master_Order} from "../master_order/master_order";
import {Customer} from "../customer/customer";
import {Address} from "../location/location";
import {Point_Damages} from "../inspection/inspection";
import {Stop} from "../stop/stop";

export class Storage_Order {
    __typename?: "Storage_order"
    warehouse_id?: number = null;
    id?: number = null;
    vehicle_order_id?: number = null;
    vehicle_order?: Vehicle_Order;
    billing_cycle?: string = '';
    rate?: number = 0;
    date_last_issued?: string | Date = new Date();
    active?: boolean = false;
    departure_date?: string | Date = new Date();
    note?: string = '';
    log?: Log[] = [];
    time?: string | Date = new Date();
    storage_charges?: Storage_Charge[] = [];
    warehouse?: Warehouse = new Warehouse();

    constructor(x?: Storage_Order){
        if(x){
            this.id = x.id;
            this.vehicle_order_id = x.vehicle_order_id;
            this.vehicle_order = x.vehicle_order;
            this.billing_cycle = x.billing_cycle;
            this.rate = x.rate;
            this.date_last_issued =  new Date(x.date_last_issued);
            this.active = x.active;
            this.departure_date = new Date(x.departure_date);
            this.note = x.note;
            this.log = x.log?.map(z => z) || [];
            this.time =  new Date(x.time);
            this.storage_charges = x.storage_charges?.map(z => z) || [];
            this.warehouse =  new Warehouse(x.warehouse);
        }
    }
}

export class Log {
    __typename?: "Log"
    user_id?: number = null;
    message: string = '';
    time: string | Date = new Date();
    vehicle_order_id?: number = null;
    leg_id?: number = null;
    stop_id?: number = null;

    constructor (x?: Log){
        if(x){
            this.user_id = x.user_id;
            this.message = x.message;
            this.time =  new Date(x.time);
            this.vehicle_order_id = x.vehicle_order_id;
            this.leg_id = x.leg_id;
            this.stop_id = x.stop_id;            
        }
    }
}

export class Storage_Charge {
    __typename?: "Storage_charge"
    id?: number = null;
    period_start?: string | Date = new Date();
    period_end?: string | Date = new Date();
    time?: string | Date = new Date();
    price?: number = 0;
    service_charge_id?: number = null;
    service_charge?: Service_Charge = new Service_Charge();
    storage_order_id?: number = null;
    storage_order?: Storage_Order = new Storage_Order();
    note?: string = '';

    constructor (x?: Storage_Charge) {
        if(x){
            this.id = x.id;
            this.period_start = new Date(x.period_start );
            this.period_end = new Date(x.period_end );
            this.time = new Date(x.time );
            this.price = x.price;
            this.service_charge_id = x.service_charge_id;
            this.service_charge =  new Service_Charge(x.service_charge);
            this.storage_order_id = x.storage_order_id;
            this.storage_order =  new Storage_Order(x.storage_order);
            this.note = x.note;            
        }
    }
}

export class Storage_Orders_Sp {
    __typename?: "Storage_orders_sp"
    id?: number = null;
    started?: boolean = false;
    vehicle?: Vehicle = new Vehicle();
    delivered?: boolean = false;
    master_order?: Master_Order = new Master_Order();
    service_charges?: Service_Charge[] = [];
    storage_order?: Storage_Order = new Storage_Order();

    constructor (x?:Storage_Orders_Sp){
        if(x){
            this.id = x.id;
            this.started = x.started;
            this.vehicle = new Vehicle(x.vehicle);
            this.delivered = x.delivered;
            this.master_order =  new Master_Order(x.master_order);
            this.service_charges = x.service_charges?.map(z => z) || [];
            this.storage_order =  new Storage_Order(x.storage_order);           
        }
    }
}

export class Storage_Vehicle_Order_Sp {
    __typename?: "Storage_vehicle_order_sp"
    id?: number = null;
    price?: number = 0;
    canceled?: boolean = false;
    customer?: Customer = new Customer();
    payment_left?: number = 0;
    vehicle_order?: Vehicle_Order = new Vehicle_Order();
    last_invoice_amount?: number = 0;

    constructor (x?: Storage_Vehicle_Order_Sp){
        if(x){
            this.id = x.id;
            this.price = x.price;
            this.canceled = x.canceled;
            this.customer =  new Customer(x.customer);
            this.payment_left = x.payment_left;
            this.vehicle_order = new Vehicle_Order(x.vehicle_order);
            this.last_invoice_amount = x.last_invoice_amount;            
        }
    }
}

export class Warehouse {
    __typename?: "Warehouse"
    id?: number = null;
    name: string;
    address: Address = new Address();
    storage_sections?: Storage_Section[] = [];

    constructor (x?: Warehouse){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.address = new Address(x.address);
            this.storage_sections = x.storage_sections?.map(z => z) || [];
        }
    }
}

export class Storage_Section {
    __typename?: "Storage_section"
    id?: number = null;
    columns?: number = 0;
    rows?: number = 0;
    columns_format?: Columns_Format = new Columns_Format();
    rows_format?: Columns_Format = new Columns_Format();
    name?: string = '';
    warehouse_id?: number = null;
    warehouse?: Warehouse = new Warehouse();
    position?: Point_Damages = new Point_Damages();
    storage_bays?: Storage_Bay[] = [];

    constructor (x?:Storage_Section){
        if(x){
            this.id = x.id;
            this.columns = x.columns;
            this.rows = x.rows;
            this.columns_format = new Columns_Format(x.columns_format);
            this.rows_format =  new Columns_Format(x.rows_format);
            this.name = x.name;
            this.warehouse_id = x.warehouse_id;
            this.warehouse =  new Warehouse(x.warehouse);
            this.position =  new Point_Damages(x.position);
            this.storage_bays = x.storage_bays?.map(z => z) || [];            
        }
    }
}

export class Columns_Format {
    __typename?: "Columns_format"
    format?: string = '';
    reverse?: boolean = false;
    starting_value?: string = '';

    constructor (x?: Columns_Format){
        if(x){
            this.format = x.format;
            this.reverse = x.reverse;
            this.starting_value = x.starting_value;            
        }
    }
}

export class Storage_Bay {
    __typename?: "Storage_bay"
    id?: number = null;
    label?: string = null;
    storage_section_id?: number = null;
    position?: Point_Damages = new Point_Damages();
    storage_section?: Storage_Section = new Storage_Section();
    vehicle_order_id?: number = null;
    vehicle_order?: Vehicle_Order;

    constructor (x?: Storage_Bay){
        if(x){
            this.id = x.id;
            this.label = x.label;
            this.storage_section_id = x.storage_section_id;
            this.position =  new Point_Damages(x.position);
            this.storage_section = new Storage_Section(x.storage_section);
            this.vehicle_order_id = x.vehicle_order_id;
            this.vehicle_order =  x.vehicle_order;
        }
    }
}

export class Inventory_Service {
    __typename?: "Inventory_service"
    service_charge_id?: number = null;
    service_charge?: Service_Charge = new Service_Charge();
    completed?: boolean = false;
    time_completed?: string | Date = new Date();
    time_start?: string | Date = new Date();
    time_end?: string | Date = new Date();

    constructor (x?: Inventory_Service){
        if(x){
            this.service_charge_id = x.service_charge_id;
            this.service_charge = new Service_Charge(x.service_charge );
            this.completed = x.completed;
            this.time_completed = new Date(x.time_completed);
            this.time_start =  new Date(x.time_start);
            this.time_end = new Date(x.time_end);            
        }
    }
}

export class Inventory_Order {
    __typename?: "Inventory_order"
    id?: number = null;
    note?: string = '';
    stop_id?: number = null;
    incoming?: boolean = false;
    time_due?: string = '';
    time_completed?: string = '';
    completed?: boolean = false;
    warehouse_id?: number = null;
    vehicle_order_id?: number = null;
    carrier?: Inventory_Carrier = new Inventory_Carrier();
    vehicle_order?: Vehicle_Order = new Vehicle_Order();
    warehouse?: Warehouse = new Warehouse();
    stop?: Stop = new Stop();

    constructor (x?: Inventory_Order){
        if(x){
            this.id = x.id;
            this.note = x.note;
            this.stop_id = x.stop_id;
            this.incoming = x.incoming;
            this.time_due = x.time_due;
            this.time_completed = x.time_completed;
            this.completed = x.completed;
            this.warehouse_id = x.warehouse_id;
            this.vehicle_order_id = x.vehicle_order_id;
            this.carrier =  new Inventory_Carrier(x.carrier);
            this.vehicle_order =  new Vehicle_Order(x.vehicle_order);
            this.warehouse = new Warehouse(x.warehouse );
            this.stop = new Stop(x.stop);
            
        }
    }
}

export class Inventory_Carrier {
    __typename?: "Inventory_carrier"
    name?: string = '';
    driver?: string = '';
    velox_order_id?: number = null;
    velox_id?: number = null;

    constructor(x?: Inventory_Carrier){
        if(x){
            this.name = x.name;
            this.driver = x.driver;
            this.velox_order_id = x.velox_order_id;
            this.velox_id = x.velox_id;            
        }
    }
}

export class Inventory_Carrier_Input {
    name?: string = '';
    driver?: string = '';
    velox_order_id?: number = null;
    velox_id?: number = null;

    constructor (x?: Inventory_Carrier_Input){
        if(x){
            this.name = x.name;
            this.driver = x.driver;
            this.velox_order_id = x.velox_order_id;
            this.velox_id = x.velox_id;           
        }
    }
}

export class Inventory_Service_Charge_Input {
    id?: number = null;
    name?: string = '';
    amount?: number = 0;
    base_amount?: number = 0;
    tax_amount?: number = 0;
    note?: string = '';
    master_order_id?: number = null;
    vehicle_order_id?: number = null;
    inventory_service?: Inventory_Service_Input = new Inventory_Service_Input();

    constructor (x?: Inventory_Service_Charge_Input){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.amount = x.amount;
            this.base_amount = x.base_amount;
            this.tax_amount = x.tax_amount;
            this.note = x.note;
            this.master_order_id = x.master_order_id;
            this.vehicle_order_id = x.vehicle_order_id;
            this.inventory_service = new Inventory_Service_Input(x.inventory_service);          
        }
    }
}

export class Inventory_Service_Input {
    completed?: boolean = false;
    time_start?: string = '';
    time_end?: string = '';
    time_completed?: string = '';

    constructor (x?: Inventory_Service_Input){
        if(x){
            this.completed = x.completed;
            this.time_start = x.time_start;
            this.time_end = x.time_end;
            this.time_completed = x.time_completed;            
        }
    }
}

export class Section_Input {
    col_count: number = 0;
    row_count: number = 0;
    row_format: string = '';
    row_value: string = '';
    row_reverse: boolean = false;
    col_format: string = '';
    col_value: string = '';
    col_reverse: boolean = false;
    title: string = '';
    posx: number = 0;
    posy: number = 0;
    wareh_id: number = 0;

    constructor (x?: Section_Input){
        if(x){
            this.col_count = x.col_count;
            this.row_count = x.row_count;
            this.row_format = x.row_format;
            this.row_value = x.row_value;
            this.row_reverse = x.row_reverse;
            this.col_format = x.col_format;
            this.col_value = x.col_value;
            this.col_reverse = x.col_reverse;
            this.title = x.title;
            this.posx = x.posx;
            this.posy = x.posy;
            this.wareh_id = x.wareh_id;            
        }
    }
}

export class Section_Edit_Input {
    col_count: number = 0;
    row_count: number = 0;
    row_format: string = '';
    row_value: string = '';
    row_reverse: boolean = false;
    col_format: string = '';
    col_value: string = '';
    col_reverse: boolean = false;
    title: string = '';
    posx: number = 0;
    posy: number = 0;
    storagesectionid: number = 0;

    constructor (x?: Section_Edit_Input){
        if(x){
            this.col_count = x.col_count;
            this.row_count = x.row_count;
            this.row_format = x.row_format;
            this.row_value = x.row_value;
            this.row_reverse = x.row_reverse;
            this.col_format = x.col_format;
            this.col_value = x.col_value;
            this.col_reverse = x.col_reverse;
            this.title = x.title;
            this.posx = x.posx;
            this.posy = x.posy;
            this.storagesectionid = x.storagesectionid;            
        }
    }
}

const Modal = props => {
    if (!props.show) return null;

    return (
        <>
            <div className="modal" onClick={(evt) => {
                !props.closeAllowed && props.onClose && props.onClose(evt);
            }}>
                <div>
                    { props.title ?
                        <div className="modal-title">
                            { props.title }

                            <i className="ti-close font-10 cursor-pointer"
                               onClick={(evt) => props.onClose && props.onClose(evt)}></i>
                        </div>
                        : null
                    }

                    <div className={`${props.center ? 'modal-scroll' : ''}`}>
                        <div className={`modal-content ${props.rounded ? 'modal-rounded' : '' } ${props.center ? 'content-center' : ''}`}
                             style={{width: props.width ? props.width : '40vw'}}
                             onClick={e => e.stopPropagation()}>
                            <div className="modal-body">
                                { props.children }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Modal;

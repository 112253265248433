import React from 'react';

const VehicleOverview = ({storage = false, index = 1}) => {
    return <div>
        <div className="row pb-2" style={{position: 'sticky', top: 0, background: '#FFF'}}>
            <h6 className="font-11">Vehicle {index}</h6>
            <h6 className="font-11">$420.00</h6>
        </div>

        <div className={'pl-2'}>
            <h6 className="font-10">Leg 1 for Vehicle {index}</h6>
            <div className="pl-2 pt-2">
                <div className="d-flex">
                    <div>
                        <h6 className="font-9 text-gray mb-1">Origin</h6>
                        <h6 className="font-9 mb-0-5">1000 Main ST</h6>
                        <h6 className="font-9 mb-1">Beverly Hills, CA 90000</h6>
                        <p className="font-9">12/24/2021 10:00 AM</p>
                        {index == 1 && <p className="font-9 font-bold mt-1 text-red">NO FAIL</p>}

                    </div>
                    <div className={'ml-3'}>
                        <h6 className="font-9 text-gray mb-1">Destination</h6>
                        <h6 className="font-9 mb-0-5">1000 Main ST</h6>
                        <h6 className="font-9 mb-1">Beverly Hills, CA 90000</h6>
                        <p className="font-9">12/24/2021 10:00 AM</p>
                    </div>
                </div>

                <div className='mt-2'>
                    <div className="row mt-1">
                        <h6 className="font-9">Open Carrier</h6>
                        <h6 className="font-9">$100.00</h6>
                    </div>
                    <div className="d-flex mt-1">
                        <h6 className="font-9 cursor-pointer text-blue-light">Edit</h6>
                    </div>
                </div>

                <div className='mt-2'>
                    <div className="row mt-1">
                        <h6 className="font-9">NO FAIL for Pickup</h6>
                        <h6 className="font-9">$20.00</h6>
                    </div>
                    <div className="d-flex mt-1">
                        <h6 className="font-9 cursor-pointer text-blue-light">Edit</h6>
                        <h6 className="font-9 cursor-pointer text-red ml-2">Remove</h6>
                    </div>
                </div>
            </div>
            

            <h6 className="font-10 mt-2">
                Charges for Vehicle {index}
            </h6>
            <div className="pl-2">
                <div className='mt-2'>
                        <div className="row mt-1">
                            <h6 className="font-9">Carwash</h6>
                            <h6 className="font-9">$100.00</h6>
                        </div>
                        <div className="d-flex mt-1">
                            <h6 className="font-9 cursor-pointer text-blue-light">Edit</h6>
                        </div>
                    </div>

                    <div className='mt-2'>
                        <div className="row mt-1">
                            <h6 className="font-9">Wax</h6>
                            <h6 className="font-9">$100.00</h6>
                        </div>
                        <div className="d-flex mt-1">
                            <h6 className="font-9 cursor-pointer text-blue-light">Edit</h6>
                            <h6 className="font-9 cursor-pointer text-red ml-2">Remove</h6>
                        </div>
                    </div>

                    <div className='mt-2'>
                        <div className="row mt-1">
                            <h6 className="font-9">Detail</h6>
                            <h6 className="font-9">$100.00</h6>
                        </div>
                        <div className="d-flex mt-1">
                            <h6 className="font-9 cursor-pointer text-blue-light">Edit</h6>
                            <h6 className="font-9 cursor-pointer text-red ml-2">Remove</h6>
                        </div>
                    </div>
            </div>
        </div>


    </div>
}

export default VehicleOverview;
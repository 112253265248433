import {Address_Input, Customer} from "../customer/customer";
import {Basic_Id_Name_Input} from "../leg/leg";

export class Address {
    __typename?: "Address"
    city: string = '';
    state: string = '';
    street: string = '';
    country: string = '';
    postal_code: string = '';
    street_number: string = '';
    postal_code_suffix?: string = '';

    constructor(x?: Address) {
        if (x) {
            this.city = x.city;
            this.state = x.state;
            this.street = x.street;
            this.country = x.country;
            this.postal_code = x.postal_code;
            this.street_number = x.street_number;
            this.postal_code_suffix = x.postal_code_suffix;
        }
    }

    public getName?(part = 1) {
        return part == 1 ? `${this.street_number} ${this.street}` : `${this.city}, ${this.state} ${this.postal_code}`;
    } 
}

export class Location {
    __typename?: "Location"
    id?: number;
    customer_id?: number;
    customer?: Customer;
    address?: Address = new Address();
    title?: string;
    internal_note?: string;
    driver_note?: string;
    work_hours?:Work_Hours;
    timezoneName?: string;

    constructor(x?: Location){
        if(x){
            this.id = x.id;
            this.customer_id = x.customer_id;
            this.customer = x.customer;
            this.address = new Address(x.address);
            this.title = x.title;
            this.internal_note = x.internal_note;
            this.driver_note = x.driver_note;
            this.work_hours = x.work_hours;
            this.timezoneName = x.timezoneName;
        }
    }
}

export class Work_Hours {
    __typename?: "Work_hours"
    weekdays: Week_Frame = new Week_Frame();
    weekends: Week_Frame = new Week_Frame();

    constructor(x?: Work_Hours){
        if(x){
            this.weekdays = new Week_Frame(x.weekdays);
            this.weekends = new Week_Frame(x.weekends);
        }
    }
}

export class Week_Frame {
    __typename?: "Week_frame"
    from: string = '';
    to: string = '';
    constructor(x?: Week_Frame){
        if(x){
            this.from = x.from;
            this.to = x.to;
        }
    }
}

export class At_Location_Time {
    __typename?: "At_location_time"
    time_formatted?: string = '';
    timezone?: TimeZone = new TimeZone();
    place?: Place = new Place();
    constructor(x?: At_Location_Time){
        if(x){
            this.time_formatted = x.time_formatted;
            this.timezone = new TimeZone(x.timezone); 
            this.place = new Place(x.place);       
        }
    }
}

export class TimeZone {
    __typename?: "TimeZone"
    code?: string = '';
    name?: string = '';
    daylight_offset?: string = '';
    utc_offset?: string = '';

    constructor(x?: TimeZone) {
        if(x){
            this.code = x.code;
            this.name = x.name;
            this.daylight_offset = x.daylight_offset;
            this.utc_offset = x.utc_offset;            
        }
    }
}

export class Place {
    __typename?: "Place"
    formatted_address?: string = '';
    place_id?: string = '';
    location?: Location_Place = new Location_Place();

    constructor(x?: Place){
        if(x){
            this.formatted_address = x.formatted_address;
            this.place_id = x.place_id;
            this.location = new Location_Place(x.location);           
        }
    }
}

export class Location_Place {
    __typename?: "Location_place"
    latitude?: string = '';
    longitude?: string = '';
    constructor(x?: Location_Place) {
        if(x){
            this.latitude = x.latitude;
            this.longitude = x.longitude
        }
    }
}

export class GPlaceRes {
    __typename?: "gPlaceRes"
    address?: Address = new Address();
    formatted?: string = '';

    constructor(x?: GPlaceRes) {
        if(x){
            this.address = new Address(x.address);
            this.formatted = x.formatted
        }
    }
}

export class Contact {
    __typename?: "Contact"
    type?: string = '';
    title?: string = '';
    value?: string = '';
    unsubscribed?: boolean = false;

    constructor(x?: Contact){
        if(x){
            this.type = x.type;
            this.title = x.title;
            this.value = x.value;
            this.unsubscribed = x.unsubscribed;            
        }
    }
}

export class LocRes {
    __typename?: "locRes"
    success?: boolean = false;  // Attention
    message?: string = '';
    constructor(x?: LocRes){
        if(x) {
            this.success = x.success
            this.message = x.message
        }
    }
}

export class LocationInput {
    id?: number = null;
    customer_id?: number = null;
    customer?: Basic_Id_Name_Input = new Basic_Id_Name_Input();
    title?: string = '';
    address?: Address_Input = new Address_Input();
    driver_note?: string = '';
    internal_note?: string = '';
    work_hours?: Work_Hour_Input = new Work_Hour_Input();
    constructor(x?: LocationInput){
        if(x){
            this.id = x.id
            this.customer_id = x.customer_id;
            this.customer = new Basic_Id_Name_Input(x.customer);
            this.title =  x.title;
            this.address =  new Address_Input(x.address);
            this.driver_note = x.driver_note;
            this.internal_note = x.internal_note;
            this.work_hours = new Work_Hour_Input(x.work_hours);    
        }
    }
}

export class Work_Hour_Input {
    weekends?: Work_Hour_Frame =  new Work_Hour_Frame();
    weekdays?: Work_Hour_Frame = new Work_Hour_Frame();
    constructor(x?: Work_Hour_Input) {
        if(x){
            this.weekends = new Work_Hour_Frame(x.weekends);
            this.weekdays = new Work_Hour_Frame(x.weekdays);
        }
    }
}

export class Work_Hour_Frame {
    from?: string = '';
    to?: string = '';

    constructor(x?: Work_Hour_Frame) {
        if(x) {
            this.from = x.from;
            this.to = x.to;
        }
    }
}

export class LocationInput_Update {
    location_id?: number = null;
    title?: string = '';
    driver_note?: string = '';
    internal_note?: string = '';
    address?: Location_Address_Update = new Location_Address_Update();
    customer_id?: number = null;
    work_hours?: Work_Hour_Input = new Work_Hour_Input();

    constructor(x?: LocationInput_Update) {
        if(x){
            this.location_id = x.location_id;
            this.title = x.title;
            this.driver_note = x.driver_note;
            this.internal_note = x.internal_note;
            this.address = new Location_Address_Update(x.address);
            this.customer_id = x.customer_id;
            this.work_hours = new Work_Hour_Input(x.work_hours );       
        }
    }
}

export class Location_Address_Update {
    city?: string = '';
    state?: string = '';
    street?: string = '';
    country?: string = '';
    postal_code?: string = '';
    street_number?: string = '';
    postal_code_suffix?: string = '';

    constructor(x?: Location_Address_Update){
        if(x) {
            this.city = x.city;
            this.state = x.state;
            this.street = x.street;
            this.country = x.country;
            this.postal_code = x.postal_code;
            this.street_number = x.street_number;
            this.postal_code_suffix = x.postal_code_suffix;            
        }
    }
}

import FormGroup from "../../FormGroup/FormGroup";
import {useEffect, useState} from "react";
import {Address, Customer, Location} from "../../../classes/classes";
import Helper from "../../../helper/Helper";
import AddressSearch from '../../Google/AddressSearch';
import {GraphqlService} from "../../../services/graphql.service";
import {gql} from "apollo-boost";
import useLoading from "../../../hooks/useLoading/useLoading";
import {useToasts} from "react-toast-notifications";
import CustomerMutations from "../../../graphql/mutation/Customer";
import LocationFormWorkHours from "../../LocationFormWorkHours/LocationFormWorkHours";

const states = Helper.States;

const LocationForm = ({customer, location, onCancel, onSubmit}:{customer: Customer, location: Location, onCancel, onSubmit}) => {
    const [address, SetAddress] = useState<Address>(new Address());
    const [myLocation, SetMyLocation] = useState<Location>(location);

    const toast = useToasts();
    const loading = useLoading();

    useEffect(() => {
        setMyLocation({...myLocation, customer});
    }, [customer]);

    useEffect(() => {
        console.log(location);
        if(location){
            setAddress(location.address)
            setMyLocation({...location, customer});
        }
    }, [location]);

    function setMyLocation(locData: Location){
        console.log('location',locData);
        SetMyLocation(locData);
    }

    function setAddress(addressData: Address){
        console.log('address: ', addressData);
        
        SetAddress(addressData);

    }

    const saveLocation = async (location: Location) => {
        try {
            location.address = {...address};
            location.customer = new Customer(customer);

            const l = {
                "customer_id": customer?.id,
                "title": location?.title,
                "address": address,
                "driver_note": location?.driver_note,
                "internal_note": location?.internal_note,
                "work_hours": {
                    "weekdays": {
                        "from": location?.work_hours?.weekdays?.from,
                        "to": location?.work_hours?.weekdays?.to,
                    },
                    "weekends": {
                        "from": location?.work_hours?.weekends?.from,
                        "to": location?.work_hours?.weekends?.to,
                    }
                }
            }

            loading.open('Saving location...');
            const data = await GraphqlService.SendMutation(CustomerMutations.ADD_LOCATION_TO_CUSTOMER, {location: l});
            
            location.id = data.id;

            loading.close();
            toast.addToast('Location Created with ID: #' + data.id, {appearance: 'success', autoDismiss: true});

            setMyLocation({...location});
            onSubmit({...location});
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error'});
        }
    }

    async function updateLocation(location: Location){
        try {
            let updateLocation = gql`
                mutation($location: locationInput_update) {
                    update_location_by_id(location: $location) {
                        id
                    }
                }
            `;

            loading.open('Updating location...');
            console.log('Address to update: ', address);

            const data = await GraphqlService.SendMutation(updateLocation, {
                "location": {
                    "location_id": location?.id,
                    "title": location?.title,
                    "driver_note": location?.driver_note,
                    "internal_note": location?.internal_note,
                    "customer_id": customer?.id,
                    "address": {
                        "city": address?.city,
                        "state": address?.state,
                        "street": address?.street,
                        "country": address?.country,
                        "postal_code": address?.postal_code,
                        "street_number": address?.street_number,
                        "postal_code_suffix": address?.postal_code_suffix
                    },
                    "work_hours": {
                        "weekdays": {
                            "from": location?.work_hours?.weekdays?.from,
                            "to": location?.work_hours?.weekdays?.to,
                        },
                        "weekends": {
                            "from": location?.work_hours?.weekends?.from,
                            "to": location?.work_hours?.weekends?.to,
                        }
                    }
                }
            });

            loading.close();
            toast.addToast('Location Updated', {appearance: 'success', autoDismiss: true});

            setMyLocation({...location});
            onSubmit({...location});
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error'});
        }
    }

    const isValid = () => myLocation.title != '' && address.city != '' && address.street != '' &&
        address.street_number != '' && address.state != '' && address.postal_code != '' && address.country != '';

    return(
        <>
            <div className="flex-1-container">
                <div className="row col-12">
                    { myLocation.id == null && <p className="font-12 font-medium mb-3">NEW LOCATION</p> }
                    { myLocation.id != null && <p className="font-12 font-medium mb-3">UPDATE LOCATION</p> }
                </div>
                <div className="flex-1-container">
                    <div className="row">
                <div className="col-12 mb-2">
                    <label className="font-10">Country*</label>
                </div>

                <div className="col-6 mb-2">
                    <button className={`btn btn-block ${address.country == 'United States' ? 'btn-country-selected' : 'btn-country'}`}
                            onClick={() => setAddress({...address, country: 'United States'})}>United States</button>
                </div>
                <div className="col-6 mb-2">
                    <button className={`btn btn-block ${address.country == 'Canada' ? 'btn-country-selected' : 'btn-country'}`}
                            onClick={() => setAddress({...address, country: 'Canada'})}>Canada</button>
                </div>

                <FormGroup label={'Location Title'}
                           name={'locationTitle'}
                           value={myLocation.title}
                           placeholder={'Location Title'}
                           onTextChange={value => {
                            setMyLocation({...myLocation, title: value})
                           }}/>

                <AddressSearch className="col-12" onGotAddress={(newAddress) =>{
                    setAddress(newAddress);
                }}/>

                <FormGroup label={'Street #'}
                           colSize={4}
                           value={address.street_number}
                           name={'streetNumber'}
                           placeholder={'Street #'}
                           onTextChange={value => setAddress({...address, street_number: value})}/>

                <FormGroup label={'Street'}
                           colSize={8}
                           value={address.street}
                           name={'street'}
                           placeholder={'Street'}
                           onTextChange={value => setAddress({...address, street: value})}/>

                <FormGroup label={'City'}
                           name={'city'}
                           value={address.city}
                           placeholder={'City'}
                           onTextChange={value => setAddress({...address, city: value})}/>

                <FormGroup label={'State'}
                           name={'state'}
                           colSize={6}
                           value={address.state}
                           type={'select'}
                           placeholder={'-- Select a state --'}
                           options={{data: states, value: x => x, label: x => x}}
                           onTextChange={value => setAddress({...address, state: value})}/>
                        
                <FormGroup label={'Zip'}
                           colSize={6}
                           name={'zip'}
                           value={address.postal_code}
                           placeholder={'XXXXX'}
                           onTextChange={value => setAddress({...address, postal_code: value})}/>
            </div>

            <FormGroup label={'Driver notes'}
                       name={'driver_notes'}
                       value={myLocation.driver_note}
                       type={'textarea'}
                       placeholder={'Driver notes...'}
                       onTextChange={value => setMyLocation({...myLocation, driver_note: value})}/>

            <FormGroup label={'Internal notes'}
                       name={'internal_notes'}
                       value={myLocation.internal_note}
                       type={'textarea'}
                       placeholder={'Internal notes...'}
                       onTextChange={value => setMyLocation({...myLocation, internal_note: value})}/>

            <LocationFormWorkHours workHours={myLocation?.work_hours}
                                   onChange={(workHours) => setMyLocation({...myLocation, work_hours: workHours})}/>
            </div>
            </div>
            <div className="row">
                <div className="col-6 mr-3">
                    <button className="btn btn-block rounded-pill text-light-blue" onClick={event => onCancel(event)}>CANCEL</button>
                </div>

                <div className="col-6">
                    <button className="btn btn-block btn-blue-light rounded-pill"
                            disabled={!isValid()}
                            onClick={() => {
                                if (myLocation.id == null) saveLocation({...myLocation}).then();
                                else updateLocation({...myLocation}).then();
                            }}>{ myLocation.id == null ? 'SAVE' : 'UPDATE' }</button>
                </div>
            </div>
        </>
    );
}

export default LocationForm;

import gql from "graphql-tag";

const GET_ALL = gql`
    query{ 
        all_taxes{
            id
            amount
            name
        }
    }
`;

const TaxQueries = {
    GET_ALL
};

export default TaxQueries;
import {gql} from "apollo-boost";
import {Leg_Update_Input, StandardResult} from "../leg";
import {GraphqlService} from "../../../../graphql.service";
import {Leg_Request_Input} from "../../change_request/change_request";
import {ResultWithLink} from "../../customer_profile/customer_profile";

export const dispatch_to_driver = (input:{legid: number, legprice: number, driver_id: number, driver_pay: number, broker_id: number}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($legId: Int, $legPrice: Float, $driverId: Int!, $driverPay: Float) {
      dispatch_to_driver(legid: $legId, legprice: $legPrice, driver_id: $driverId, driver_pay: $driverPay) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {legid, legprice, driver_id, driver_pay, broker_id} as 
  Promise<StandardResult>;
}

export const dispatch_to_carrier = (input:{legid: number, legprice: number, carrier_id: number, needs_confirmation: boolean, note: string, expires: string}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($legid: Int, $legprice: Float, $carrier_id: Int, $needs_confirmation: Boolean, $note: String, $expires: String!) {
      dispatch_to_carrier(legid: $legid, legprice: $legprice, carrier_id: $carrier_id, needs_confirmation: $needs_confirmation, note: $note, expires: $expires) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {legid, legprice, carrier_id, needs_confirmation, note, expires} as 
  Promise<StandardResult>;
}

export const update_leg = (input:{leg: Leg_Update_Input}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($leg: leg_update_input) {
      update_leg(leg: $leg) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {leg} as 
  Promise<StandardResult>;
}

export const split_leg = (input:{legs: Leg_Request_Input[], carrier_message: string, hours: number}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($legs: Leg_request_input[], $carrier_message: String, $hours: Int) {
      split_leg(legs: $legs, carrier_message: $carrier_message, hours: $hours) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {legs, carrier_message, hours} as 
  Promise<StandardResult>;
}
export const cancel_velox_order = (input:{leg_id: number, note: string, message: string}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($leg_id: Int, $note: String, $message: String) {
      cancel_velox_order(leg_id: $leg_id, $note: String, message: $message) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {leg_id, note, message} as 
  Promise<StandardResult>;
}

export const assign_driver = (input:{leg_id: number, driver_id: number, price: number}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($leg_id: Int, $driver_id: Int, $price: Float) {
      assign_driver(leg_id: $leg_id, driver_id: $driver_id, price: $price) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {leg_id, driver_id, price} as 
  Promise<StandardResult>;
}

export const unassign_driver = (input:{leg_id: number}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($leg_id: Int) {
      unassign_driver(leg_id: $leg_id) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {leg_id} as 
  Promise<StandardResult>;
}

export const create_velox_pending_origin_inspection = (input:{velox_order_id: number, velox_driver_id: number, phones: string[], emails: string[]}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($velox_order_id: Int, $velox_driver_id: Int, $phones: String[], $emails: String[]) {
      create_velox_pending_origin_inspection(velox_order_id: $velox_order_id, velox_driver_id: $velox_driver_id, phones: $phones, emails: $emails) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {velox_order_id, velox_driver_id, phones, emails} as 
  Promise<ResultWithLink>;
}

export const create_velox_pending_destination_inspection = (input:{velox_order_id: number, velox_driver_id: number, phones: string[], emails: string[]}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($velox_order_id: Int, $velox_driver_id: Int, $phones: String[], $emails: String[]) {
      create_velox_pending_destination_inspection(velox_order_id: $velox_order_id, velox_driver_id: $velox_driver_id, phones: $phones, emails: $emails) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {velox_order_id, velox_driver_id, phones, emails} as 
  Promise<ResultWithLink>;
}

export const delete_leg = (input:{leg_id: number, note: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($leg_id: Int, $note: String) {
      delete_leg(leg_id: $leg_id, note: $note) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {leg_id, note} as 
  Promise<ResultWithLink>;
}

export const create_velox_redirect_link = (input:{carrier_id: number, link: string}) : Promise<string> => {
  let mutation = gql`
    mutation($carrier_id: Int, $link: String) {
      create_velox_redirect_link(carrier_id: $carrier_id, link: $link)
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {carrier_id, link} as 
  Promise<string>;
}

export const resend_velox_order_request_email = (input:{leg_id: number, velox_id: number, emails: string[]}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($leg_id: Int, $velox_id: Int, $emails: String[]) {
      resend_velox_order_request_email(leg_id: $leg_id, velox_id: $velox_id, emails: $emails) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {leg_id, velox_id, emails} as 
  Promise<StandardResult>;
}

export const add_leg = (input:{leg: Leg_Update_Input, carrier_message: string, hours: number}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($leg: leg_update_input, $carrier_message: String, $hours: Int) {
      add_leg(leg: $leg, carrier_message: $carrier_message, hours: $hours) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {leg, carrier_message, hours} as 
  Promise<StandardResult>;
}
import gql from "graphql-tag";

const ADD_NEW_CUSTOMER = gql`
    mutation ($customer: customer_input) {
        create_customer(customer: $customer){
            
            id
            name
            billing_address {
                street
                street_number
                postal_code
                country
                city
                state
            }
            key
            terms
            corporate_client
        }
    }
`;

const EDIT_CUSTOMER = gql(`mutation($customer_id:Int, $customer_info: update_customer_input) {
    update_customer(customer_id: $customer_id, customer_info: $customer_info) {
        message
        success
        customer_id
    }
}`);

const ADD_PROFILE_TO_CUSTOMER = gql`
    mutation ($customerid:Int!, $newname: String! $title: String! $newrole: String! $newemail:String $contact: [contactInput] $redirectlink:String) {
        add_profile_to_customer(customerid: $customerid, title: $title,  new_name:$newname new_email:$newemail new_role:$newrole contact:$contact redirect_link:$redirectlink){
            message
            success
            id
        }
    }
`;

const ADD_LOCATION_TO_CUSTOMER = gql`
    mutation($location: locationInput){
        create_location(location: $location){
            id
        }
    }
`;

const SUBSCRIBE_CONTACT = gql`
mutation($value: String) {
  resubscribe_contact(value: $value) {
    id
    message
    success
  }
}`;

const UNSUBSCRIBE_CONTACT = gql`
mutation($value: String) {
  unsubscribe_contact(value: $value) {
    id
    message
    success
  }
}`;

const CustomerMutations = {
    ADD_NEW_CUSTOMER,
    EDIT_CUSTOMER,
    ADD_PROFILE_TO_CUSTOMER,
    ADD_LOCATION_TO_CUSTOMER,
    SUBSCRIBE_CONTACT,
    UNSUBSCRIBE_CONTACT
}

export default CustomerMutations;
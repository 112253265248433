import {gql} from "apollo-boost";
import {FuRes, User_Settings, Users} from "../users";
import {GraphqlService} from "../../../../graphql.service";
import {Employee} from "../../employee/employee";

export const find_user = (input:{username: string, password: string}) : Promise<FuRes> => {
  let query = gql`
    query($username: String, $password: String) {
      find_user(username: $username, password: $password) {
        message
        user {
          id
          name
          level
        }
        success
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {username, password} as 
  Promise<FuRes>;
}

export const get_user_settings = (input:{}) : Promise<User_Settings> => {
  let query = gql`
    query {
      get_user_settings {
        driver_view_order {
          carrier_id
          order {
            id
          }
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<User_Settings>;
}

export const get_users = (input:{}) : Promise<Users[]> => {
  let query = gql`
    query {
      get_users {
        id
        name
        level
        active
        employee {
          id
          name
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          permission
          department
          active
          note
          documents {
            uid
            name
          }
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Users[]>;
}

export const get_user = (input:{user_id: number}) : Promise<Users> => {
  let query = gql`
    query($user_id: Int) {
      get_user(user_id: $user_id) {
        id
        name
        level
        active
        employee {
          id
          name
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          permission
          department
          active
          note
          documents {
            uid
            name
          }
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {user_id} as 
  Promise<Users>;
}

export const get_employee_by_user_id = (input:{user_id: number}) : Promise<Employee> => {
  let query = gql`
    query($user_id: Int) {
      get_employee_by_user_id(user_id: $user_id) {
        id
        name
        address {
          city
          state
          street
          country
          postal_code
          street_number
          postal_code_suffix
        }
        permission
        department
        active
        note
        user {
          id
          name
          level
          active
        }
        documents {
          uid
          name
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {user_id} as 
  Promise<Employee>;
}

export const get_user_from_token = (input:{}) : Promise<Users> => {
  let query = gql`
    query {
      get_user_from_token {
        id
        name
        level
        active
        employee {
          id
          name
          address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          permission
          department
          active
          note
          documents {
            uid
            name
          }
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Users>;
}
import {Leg_Request_Data} from "../change_request";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";

export const get_leg_change_request_data = (input:{leg_id: number}) : Promise<Leg_Request_Data> => {
  let query = gql`
    query($leg_id: Int) {
      get_leg_change_request_data(leg_id: $leg_id) {
        master_order {
          id
          customer {
            id
            name
            corporate_client
            key
            terms
            internal_note
            billing_address {
              city
              state
              street
              country
              postal_code
              street_number
              postal_code_suffix
            }
          }
          time
          started
          completed
          price
          payment_left
          terms
          canceled
          contacts {
            id
            name
            title
          }
          delivered
          picked_up
          non_move
          payment_due
        }
        current {
          id
          driver_pay
          carrier_pay
          vehicle_order {
            id
            vehicle {
              year
              make
              model
              vin
            }
          }
          carrier {
            id
            name
          }
          driver {
            id
            name
          }
          velox_order {
            id
            accepted
            rejected
            canceled
            expired
            expires
            change_requests {
              active
              accepted
              rejected
              expired
              expires_at
              payout
            }
          }
          service_charges {
            id
            name
            amount
          }
          stops {
            id
            is_pickup
            completed
            time_scheduled
            time_frame {
              start
              end
            }
            nofail
            location {
              id
              title
              customer {
                id
                name
                key
              }
              address {
                street_number
                street
                city
                state
                postal_code
                country
              }
            }
            contacts {
              id
              name
            }
          }
        }
        pending {
          id
          driver_pay
          carrier_pay
          vehicle_order {
            id
            vehicle {
              year
              make
              model
              vin
            }
          }
          carrier {
            id
            name
          }
          driver {
            id
            name
          }
          velox_order {
            id
            accepted
            rejected
            canceled
            expired
            expires
            change_requests {
                active
                accepted
                rejected
                expired
                expires_at
                payout
            }
          }
          service_charges {
            id
            name
            amount
          }
          stops {
            id
            is_pickup
            completed
            time_scheduled
            time_frame {
              start
              end
            }
            nofail
            location {
              id
              title
              customer {
                id
                name
                key
              }
              address {
                street_number
                street
                city
                state
                postal_code
                country
              }
            }
            contacts {
              id
              name
            }
          }
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {leg_id} as 
  Promise<Leg_Request_Data>;
}
import {useState} from "react";
import './LocationEditForm.scss'
import {Quote, QuoteStop} from "../../classes/classes";
import FormGroup from "../FormGroup/FormGroup";
import Helper from "../../helper/Helper";
import Loading from "../Loading/Loading";
import {GraphqlService} from "../../services/graphql.service";
import QuoteMutations from "../../graphql/mutation/Quote";

interface LocationEditFormParams {
    quote: Quote,
    onCancel: () => void,
    onSaved: (quote) => void
}
const states = Helper.States;
const LocationEditForm = ({quote, onCancel, onSaved}: LocationEditFormParams) => {
    const [tab, setTab] = useState('Pickup');
    const [country, setCountry] = useState('United States');

    const [currentQuote, setCurrentQuote] = useState(quote);
    const [currentQuoteStop, setCurrentQuoteStop] = useState(quote.pickup);
    const [loading, setLoading] = useState(false);

    async function updateQuote()
    {
        try {
            setLoading(true);
            const obj: any = {
                pickup:  currentQuote.pickup,
                dropoff: currentQuote.dropoff,
                id:      quote.id
            };


            console.log(obj);
            const data = await  GraphqlService.SendMutation(QuoteMutations.UPDATE, {quote: obj});
            if (!data.success)
                throw new Error(data.message);
            onSaved(currentQuote)
            //console.log(data);
            setLoading(false);
        }
        catch (ex)
        {
            console.log(ex);
            setLoading(false);
        }
    }

    const handleSetTab = (tab) => {
        setTab(tab);

        if (tab == 'Pickup') setCurrentQuoteStop(currentQuote.pickup);
        else setCurrentQuoteStop(currentQuote.dropoff);
    }

    const handleEditQuoteStop = (value, key) => {
        let temp = new QuoteStop(currentQuoteStop);
        temp[key] = value;

        // edit quote stop
        // setCurrentQuoteStop(temp);

        // edit quote
        let tempQuote = {...currentQuote};

        if (tab == 'Pickup') tempQuote.pickup = new QuoteStop(temp);
        else tempQuote.dropoff = new QuoteStop(temp);

        setCurrentQuoteStop(new QuoteStop(temp));
        setCurrentQuote(tempQuote);
    }

    return (
        <>

            {loading ? <div className="col-12">
                <Loading/>
                <h4 className={'font-12 text-center mb-3'}>UPDATING QUOTE INFORMATION...</h4>
            </div>
                :<>

            <div className="flex-1-container">

                <div className="col-12 d-flex justify-content-between align-items-center">
                    <p className="font-medium font-14">Edit location & time</p>
                    <i className="ti-close font-12 cursor-pointer" onClick={() => onCancel()}></i>
                </div>

                <div className="col-12 mt-4 d-flex justify-content-center align-items-center">
                    <p className={`cursor-pointer font-medium w-50 text-center font-14 ${tab == 'Dropoff' ? 'text-gray' : ''}`}
                       onClick={() => handleSetTab('Pickup')}>
                        <span className={`pb-1 ${tab == 'Pickup' ? 'border-bottom-2 border-light-blue' : ''}`}>Pickup</span>
                    </p>
                    <p className={`cursor-pointer font-medium w-50 text-center font-14 ${tab == 'Pickup' ? 'text-gray' : ''}`}
                       onClick={() => handleSetTab('Dropoff')}>
                        <span className={`pb-1 ${tab == 'Dropoff' ? 'border-bottom-2 border-light-blue' : ''}`}>Dropoff</span>
                    </p>
                </div>

                <div className="flex-1-container">
                    <div className="row">
                        <div className="col-12 mt-5">
                            <p className="font-14 font-medium">{tab} date/time frame</p>
                        </div>

                        <div className="col-12 mt-3">

                        </div>

                        <div className="col-12 mt-4">
                            <p className="font-14 font-medium">{tab} location</p>
                        </div>

                        <div className="col-12 mt-3">
                            <label className="font-10">Country*</label>
                        </div>

                        <div className="col-6 mt-1">
                            <button className={`btn btn-block ${country == 'United States' ? 'btn-country-selected' : 'btn-country'}`}
                                    onClick={() => setCountry('United States')}>United States</button>
                        </div>
                        <div className="col-6 mt-1">
                            <button className={`btn btn-block ${country == 'Canada' ? 'btn-country-selected' : 'btn-country'}`}
                                    onClick={() => setCountry('Canada')}>Canada</button>
                        </div>

                        <FormGroup name={'street_number'}
                                label={'Street Number'}
                                className={'mt-3'}
                                placeholder={'1234'}
                                colSize={6}
                                disabled
                                value={''}/>

                        <FormGroup name={'street'}
                                label={'Street'}
                                placeholder={'Main St'}
                                className={'mt-3'}
                                colSize={6}
                                disabled
                                value={''}/>

                        <FormGroup name={'city'}
                                label={'City'}
                                value={currentQuoteStop.city}
                                onTextChange={(value) => handleEditQuoteStop(value, 'city')}/>

                        <FormGroup name={'state'}
                                label={'State'}
                                value={currentQuoteStop.state}
                                colSize={6}
                                type='select'
                                placeholder={'--'}
                                options={{data: states, value: x => x, label: x => x}}
                                onTextChange={(value) => handleEditQuoteStop(value, 'state')}/>

                        <FormGroup name={'postal_code'}
                                label={'Zip code'}
                                value={currentQuoteStop.postal_code}
                                colSize={6}
                                onTextChange={(value) => handleEditQuoteStop(value, 'postal_code')}/>

                        <div className="row col-12 align-items-center">
                            <FormGroup name={'guarantee'}
                                    label={'Guarantee ' + tab}
                                    checked={currentQuoteStop.guaranteed}
                                    colSize={6}
                                    type={'checkbox'}
                                    onCheckChanged={(value) => handleEditQuoteStop(value, 'guaranteed')}/>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-6 mt-1">
                        <button className={`btn btn-block rounded-pill btn-clear`}
                                onClick={() => onCancel()}>CANCEL</button>
                    </div>
                    <div className="col-6 mt-1">
                        <button className={`btn btn-block rounded-pill btn-blue-light`}
                                onClick={() => updateQuote()}>SAVE CHANGES</button>
                    </div>
                </div>
            </div></>}
        </>
    );
}

export default LocationEditForm;

import moment from 'moment';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { StorageCharge } from '../../classes/classes';
import Helper from '../../helper/Helper';
import { GraphqlService } from '../../services/graphql.service';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import FormGroup from '../FormGroup/FormGroup';
import {gql} from 'apollo-boost';
import useLoading from '../../hooks/useLoading/useLoading';

const StorageChargeForm = ({onCancel, onSaved, charge}) => {

    const [myCharge, setMyCharge] = useState(new StorageCharge(charge));

    const loading = useLoading();

    const [pickerOpened, setPickerOpened] = useState(0);
    const toast = useToasts();

    async function submit(evt) {

        const query = myCharge.id ? gql`
            mutation($storage_charge_id: Int!, $price:Float!, $start:String!, $end:String!, $note:String!){
                update_storage_charge(storage_charge_id: $storage_charge_id, price:$price, start:$start, end:$end, note:$note){
                    id
                    message
                    success
                }
            }
        ` : gql`
            mutation($storage_order_id: Int, $start: String, $end: String, $price: Float, $note: String){
                create_new_storage_charge(storage_order_id:$storage_order_id, start:$start end:$end, price:$price, note:$note){
                    id
                    message
                    success
                }
            }
        `;

        const t = new StorageCharge(myCharge);
        t.price = +t.price;

        const variables = {
            [t.id ? "storage_charge_id" : "storage_order_id"]: t.id ?? t.storage_order_id, 
            start: t.period_start,
            end: t.period_end, 
            price: t.price,
            note:t.note
        }
        
        try {
            loading.open((!myCharge.id ? 'Creating' : 'Updating') + ' storage charge...');
            const data = await GraphqlService.SendQuery(query, variables);
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');

            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            
            t.id = data.id;
            onSaved(t);
            loading.close();

        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            loading.close();
        }
    }

    return <div className='service-charge-form col-12'>
        <h4 className='font-11 mb-3'>Period</h4>

        <div className="row">
            <FormGroup readOnly onClick={(evt) => setPickerOpened(1)} className='position-relative'
                    value={moment(myCharge.period_start).format('MM/DD/YYYY')} required
                    colSize={6} name='period_start' type='text'
                    label={`Period Start`} placeholder='Period Start...'>
                <i className="far fa-calendar-alt font-10"></i>
            </FormGroup>

            <FormGroup readOnly onClick={(evt) => setPickerOpened(2)} className='position-relative'
                    value={moment(myCharge.period_end).format('MM/DD/YYYY')} required
                    colSize={6} name='period_end' type='text'
                    label={`Period End`} placeholder='Period End...'>
                <i className="far fa-calendar-alt font-10"></i>
            </FormGroup>
            
            <FormGroup placeholder={'0.00'} value={myCharge.price.toString()} onTextChange={(evt) => setMyCharge({...myCharge, price: Helper.Masks.DecimalNumbers(evt) as any})} label={'Price'} name={'price'} colSize={6} />
            <FormGroup placeholder={'Type notes...'} value={myCharge.note} onTextChange={(evt) => setMyCharge({...myCharge, note: evt})} type='textarea' textAreaHeight={'4rem'} label={'Note'} name={'start_date'} colSize={12} />
        </div>

        <div className='row mt-3'>
                       <button onClick={onCancel} className="btn btn-clear text-blue-light col-6 py-2">CANCEL</button>
                       <button id='test_button' onClick={submit} className="btn btn-blue-light col-6 py-2">CONFIRM</button>
        </div> 

        {pickerOpened != 0 && <DateTimePicker hideTime date={pickerOpened == 1 ? new Date(myCharge.period_start) : new Date(myCharge.period_end)} onCancelClicked={(evt) => setPickerOpened(0)} onDateSubmitted={(evt) => {
            if (pickerOpened == 1)
                setMyCharge({...myCharge, period_start: evt});
            else
                setMyCharge({...myCharge, period_end: evt});

            setPickerOpened(0);
        }} />}
    </div>

    
}

export default StorageChargeForm;
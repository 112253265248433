import React, { FC, useState } from 'react';
import { VehicleOrder } from '../../../../classes/classes';
import OrderDetailsEntry from '../OrderDetailsEntry/OrderDetailsEntry';

enum Tabs
{
    DETAILS,
    LEGS,
    CHARGES,
    STORAGE
}

interface IVehicleContainer
{
    order: VehicleOrder,
    index: number
}

const VehicleContainer: FC<IVehicleContainer> = (props) => {

    
    const tabs = [
        {
            label: 'Details',
            value: Tabs.DETAILS
        },
        {
            label: 'Legs',
            value: Tabs.LEGS,
        },
        {
            label: 'Charges',
            value: Tabs.CHARGES
        },
        {
            label: 'Storage',
            value: Tabs.STORAGE
        }
    ];

    const [tabSelected, setTabSelected] = useState(Tabs.DETAILS);


    const getPrefix = (value) => {
        switch (value)
        {
            case Tabs.LEGS:
                return ` (${props.order?.legs.length})`
            case Tabs.CHARGES:
                return ` (${props.order?.charges.length})`
            case Tabs.STORAGE:
                return ` (${props.order?.storage_order.active ? 'Yes' : 'No'})`
        }
        return '';
    }


    return <div className='p-3 flex-1-container'>

        {props.order && <>
            
            <h4 className="font-12">Information for Vehicle {props.index + 1}</h4>
            <div className="d-flex mt-3">
                {tabs.map((tab, i) => <div>
                        <h4 style={{transition: '.3s all ease'}} onClick={(evt) => setTabSelected(tab.value)} key={'key-' + i} className={"font-11 cursor-pointer pb-1 mr-3 " + (tabSelected == tab.value ? 'text-black' : 'text-gray')}>{tab.label}{getPrefix(tab.value)}</h4>
                        <div className={tabSelected == tab.value ? 'bg-blue-light' : ''} style={{width: '14px', height: '3px', transition: '.3s all ease'}} />
                    </div>)}


            </div>

            <div className="flex-1-container mt-3">
                <OrderDetailsEntry index={props.index} />
            </div>
        </>}

        {!props.order && <h4 className={'font-12 text-center text-gray'}>No Vehicle Selected</h4>}
        
    </div>
}

export default VehicleContainer;
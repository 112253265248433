import {PointClass} from "./PointClass";

export class MarkerClass{
    id: any;
    position: PointClass = new PointClass();
    text: string = '';
    tag: string = '';
    selected: boolean = false;
    ref?: any;
    onClick: (marker: MarkerClass) => void;
    color?: string = '#FF1277';

    constructor(props?: MarkerClass) {
        if(props){
            this.id = props.id;
            this.position = new PointClass(props.position);
            this.text = props.text;
            this.tag = props.tag;
            this.selected = props.selected;
            this.ref = props.ref;
            this.onClick = props.onClick;
            this.color = props.color || '#FF1277';
        }
    }
}

import {GraphqlService} from "../../../../graphql.service";
import {Attribute, inputAttribute} from "../attribute";
import {gql} from "apollo-boost";

export const create_attribute = (input:{attribute: inputAttribute, online: Boolean}) : Promise<Attribute> => {
    let mutation = gql`
        mutation($card: inputAttribute){
            create_attribute(attribute: $card){
                id
                cardholder_name
                number
                type
                expired
            }
        }
    `;
    return GraphqlService.SendQuery(mutation, input) as  //, {attribute, online} as 
  Promise<Attribute>;
}


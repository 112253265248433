import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../components/Loading/Loading';
import Helper from '../../helper/Helper';
import { GraphqlService } from '../../services/graphql.service';
import InfiniteScroll from 'react-infinite-scroll-component';


const query = gql`
    query($offset:Int, $limit:Int){
  get_storage_logs(offset:$offset, limit:$limit){
    time
    message
    user{
      id
      name
    }
    master_id
    vehicle_order_id
    customer_profile_id
    customer_profile{
      id
      name
    }
  }
}
`;

const StorageLogs = ({onClose}) => {

    const [logs, setLogs] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);

    const toast = useToasts();

    async function loadLogs()
    {
        try {
            setLoading(true);
            const data = await GraphqlService.SendQuery(query, {offset, limit});
            //console.log(data);
            setLogs(data);
            setLoading(false);
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            setLoading(false);
        }
    }

    useEffect(() => {
        loadLogs();
    }, [limit]);

    return <div  className='flex-1-container'>
        <div className="row mb-3">
            <h4 className="font-12">Logs: {logs.length}</h4>
            <h4 onClick={onClose} className="font-12 cursor-pointer"><i className='fas fa-times'></i></h4>
        </div>
        <hr className='mb-1' />
        {/* {loading && <Loading />} */}
        <div id="scrollableDiv" className='flex-1-container'>
                <ul>
                    <InfiniteScroll
                        dataLength={logs.length}
                        next={()=> setLimit(limit+10) }
                        style={{ display: 'flex', flexDirection: 'column' }} 
                        hasMore={true}
                        loader={loading&&<Loading />}
                        scrollableTarget="scrollableDiv">
                            {logs.map((log, index) =>  <li key={'log-' + index} className='mb-1 pt-2 d-flex' style={{borderTop: index == 0 ? 'none' : '1px solid #eee'}}>
                                <h6 className="font-11 mr-2">{index + 1}.)</h6>
                                <div className="col-12">
                                    <p className='font-11'>{log.message || '-'}</p>
                                    <p className='font-11 my-1 font-bold'>{Helper.FORMAT.USDate(log.time)}</p>
                                    {log.user?.id && <p className='font-11 text-blue-light font-bold'>User: #{log.user.id} {log.user.name}</p>}
                                </div>
                            </li>)}
                    </InfiniteScroll>
                </ul>
    </div>
    </div>
}

export default StorageLogs;
import { gql } from "apollo-boost";
import React, { FC, useEffect, useState } from "react";
import ICONS from "../../../../assets/svg";
import Loading from "../../../components/Loading/Loading";
import Status from "../../../components/Status/Status";
import Helper from "../../../helper/Helper";
import { Queries } from "../../../services/endpoints/queries";
import { Invoice } from "../../../services/endpoints/schemas/invoice/invoice";
import { GraphqlService } from "../../../services/graphql.service";

const queryInv = gql`
  query ($master_order_id: Int) {
    get_invoices_for_master(master_order_id: $master_order_id) {
      id
      master_order_id
      time_due
      time
      amount
      logs {
        time
        message
        user_id
      }
    }
  }
`;

const queryPay = gql`
  query ($master_order_id: Int) {
    get_payments_by_master(master_order_id: $master_order_id) {
      id
      paid_for {
        name
      }
      reference
      note
      type
      credit_card {
        type
        number
      }
      time_paid
      amount
      bank_account {
        name
        bank
        last_4
      }
      masters {
        id
      }
    }
  }
`;

interface IInvoicesPayments {
  master_id: number;
  needs_invoice: boolean;
  payment_left: number;
  onSendNewClick: () => any;
  onSeeInvoiceClick: (invoice_id: number, canSend: boolean) => any;
}

const InvoicesPayments: FC<IInvoicesPayments> = (props) => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [invoicesLoading, setInvoicesLoading] = useState(false);

  const [payments, setPayments] = useState([]);
  const [paymentsLoading, setPaymentsLoading] = useState(false);

  async function loadInvoices() {
    setInvoicesLoading(true);
    try {
      let data = await Queries.get_invoices_for_master({
        master_order_id: props.master_id,
      }); // GraphqlService.SendQuery(queryInv, {master_order_id: props.master_id});
      let sorted = data.sort((x, y) => (x.id < y.id ? 1 : -1));
      setInvoices(sorted);
      setInvoicesLoading(false);
    } catch (ex) {
      console.log(ex.message);
      setInvoicesLoading(false);
    }
  }

  async function loadPayments() {
    setPaymentsLoading(true);
    try {
      let data = await GraphqlService.SendQuery(queryPay, {
        master_order_id: props.master_id,
      });
      setPayments(data.sort((x, y) => x.id < y.id));
      setPaymentsLoading(false);
    } catch (ex) {
      console.log(ex.message);
      setPaymentsLoading(false);
    }
  }

  useEffect(() => {
    if (props.master_id) {
      loadInvoices();
      loadPayments();
    }
  }, [props.master_id]);

  return (
    <div className="flex-1-container row-container">
      <div className="col-4 d-flex">
        <div className="flex-1-container">
          <div className="row mb-3">
            <div className="d-flex">
              <h4 className="font-10 mr-2">Invoices</h4>
              {props.needs_invoice && <Status statusName="NEEDS INVOICE" />}
            </div>
            <div className="d-flex">
              <button
                onClick={(evt) => {
                  loadInvoices();
                }}
                className="mr-3 btn btn-icon-only"
              >
                <img
                  className={invoicesLoading ? "spin" : ""}
                  src={ICONS.IconRefresh}
                  alt=""
                />
              </button>
              <button
                onClick={(evt) => props.onSendNewClick()}
                className="btn btn-blue-light"
              >
                SEND NEW
              </button>
            </div>
          </div>

          <div className="flex-1-container">
            {invoicesLoading && (
              <div className="col-12">
                <Loading />
              </div>
            )}
            {!invoicesLoading &&
              invoices.map((inv, index) => (
                <div
                  className={
                    "bg-gray position-relative cursor-pointer p-3 rounded" +
                    (index == invoices.length - 1 ? "" : " mb-3")
                  }
                  onClick={(evt) => {
                    //Download and show existing invoice using ENPOINT download_existing_invoice(invoice_id)
                    props.onSeeInvoiceClick(
                      inv.id,
                      index == 0 && props.payment_left != 0
                    );
                  }}
                >
                  <div className="row align-items-start">
                    <div className="row col-12 mb-1">
                      <div className="mr-3">
                        <h4 className="font-10">I-{inv.id}</h4>
                      </div>
                      <Status
                        statusName={
                          index == 0
                            ? props.payment_left != 0
                              ? inv.time_due &&
                                new Date(inv.time_due) < new Date()
                                ? "LATE"
                                : "ACTIVE"
                              : "PAID"
                            : "INACTIVE"
                        }
                      />
                    </div>
                    <div className="d-flex ml-1">
                      <div className="mr-3">
                        <h4 className="font-9 mb-1">Sent On</h4>
                        <p className="font-9">
                          {Helper.FORMAT.USDate(inv.time, "do")}
                        </p>
                        <p className="font-9">
                          {Helper.FORMAT.USDate(inv.time, "to")}
                        </p>
                      </div>
                      <div className="mr-3">
                        <h4 className="font-9 mb-1">Due On</h4>
                        <p className="font-9">
                          {!inv.time_due
                            ? "-"
                            : Helper.FORMAT.USDate(inv.time_due, "do")}
                        </p>
                        {inv.time_due && (
                          <p className="font-9">
                            {Helper.FORMAT.USDate(inv.time_due, "to")}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <p
                    className="font-9 font-bold position-absolute"
                    style={{ right: "1rem", bottom: "1rem" }}
                  >
                    {Helper.FORMAT.USCurrency(inv.amount)}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="col-8 d-flex">
        <div className="flex-1-container">
          <div className="row mb-3">
            <div className="d-flex">
              <h4 className="font-10 mr-2">Payments</h4>
            </div>
            <button
              onClick={(evt) => {
                loadPayments();
              }}
              className="ml-3 btn btn-icon-only"
            >
              <img
                className={paymentsLoading ? "spin" : ""}
                src={ICONS.IconRefresh}
                alt=""
              />
            </button>
          </div>

          <div className="flex-1-container">
            {paymentsLoading && (
              <div>
                <Loading />
              </div>
            )}
            {!paymentsLoading &&
              payments.map((pay, index) => (
                <div
                  className={
                    "bg-gray cursor-pointer p-3 rounded" +
                    (index == invoices.length - 1 ? "" : " mb-3")
                  }
                >
                  <div className="row align-items-start">
                    <div className="d-flex">
                      <div className="mr-3">
                        <h4 className="font-9">P-{pay.id}</h4>
                      </div>

                      <div className="mr-3">
                        <h4 className="font-9 mb-1">Paid On</h4>
                        <p className="font-9">
                          {!pay.time_paid
                            ? "-"
                            : Helper.FORMAT.USDate(pay.time_paid, "do")}
                        </p>
                        {pay.time_paid && (
                          <p className="font-9">
                            {Helper.FORMAT.USDate(pay.time_paid, "to")}
                          </p>
                        )}
                      </div>
                      <div className="mr-3">
                        <h4 className="font-9 mb-1">Masters</h4>
                        <p className="font-9">
                          M-{pay.masters[0]?.id}{" "}
                          {pay.masters.length > 1 && (
                            <span style={{ display: "block" }}>
                              +{pay.masters.length - 1} more
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="mr-3">
                        <h4 className="font-9 mb-1">Type</h4>
                        <p className="font-9">{pay.type}</p>
                        {pay.credit_card && (
                          <p className="font-9">
                            {pay.credit_card.type} {pay.credit_card.number}
                          </p>
                        )}
                      </div>
                      <div className="mr-3">
                        <h4 className="font-9 mb-1">Amount</h4>
                        <p className="font-9">{pay.amount.toCurrency()}</p>
                      </div>

                      <div>
                        <h4 className="font-9 mb-1">Bank Account</h4>
                        <p className="font-9">
                          {pay.bank_account?.last_4} - {pay.bank_account?.name}{" "}
                          {pay.bank_account?.bank}
                        </p>
                      </div>
                    </div>

                    {/* <Status statusName='INACTIVE' /> */}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicesPayments;

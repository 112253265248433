import React, {useEffect, useState} from "react";
import FormGroup from "../FormGroup/FormGroup";
import {Driver} from "../../classes/classes";
import './DriverForm.scss';
import ICONS from "../../../assets/svg";
import CardDetailDriver from "../CardDetailDriver/CardDetailDriver";
import {
    createDriver,
    initDriver, removeEquipmentFileSingle,
    updateDriver,
    updateDriverPassword,
    updateDriverUsername
} from "../../redux/actions/DriverSetting.action";
import {useDispatch, useSelector} from "react-redux";
import {useToasts} from "react-toast-notifications";
import useLoading from "../../hooks/useLoading/useLoading";
import {AllStates} from "../../redux/reducers";
import {IDriverState} from "../../redux/reducers/Driver.reducer";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../services/graphql.service";
import {IDriverSettingState} from "../../redux/reducers/DriverSettings.reducer";
import {getDrivers} from "../../redux/actions/Driver.action";
import DriverQueries from "../../graphql/query/Driver";
import Helper from "../../helper/Helper";
import useMessageBox from "../../hooks/useMessageBox/useMessageBox";

const deleteMutation = gql`mutation($velox_driver_id:Int){
    remove_driver(velox_driver_id:$velox_driver_id){
      id
      message
      success
    }
  }`;
class Contact {
    type: string = '';
    value: string = '';

    constructor(props?: any) {
        if (props) {
            this.type = props.type;
            this.value = props.value;
        }
    }
}

const DriverSettingForm = ({driver, onSubmit, carrier, onUpdated = (id) => {}}) => {
    const dispatch = useDispatch();
    const loading = useLoading();
    const toast = useToasts();

    const {
        loadingForm,
        response,
        errorForm
    } = useSelector<AllStates>(state => state.driverSetting) as IDriverSettingState;

    const [driverList, setDriverList] = useState<Driver[]>([]);

    const [isNew, setIsNew] = useState(true);
    const [email, setEmail] = useState(new Contact());
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [checkSms, setCheckSms] = useState('');
    const [checkEmail, setCheckEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(new Contact());
    const [currentDriver, setCurrentDriver] = useState(new Driver());

    const [equipments, setEquipments] = useState([]);

    const [confirmPassword, setConfirmPassword] = useState('');
    const handleUsername = (value) => setUsername(value);
    const handlePassword = (value) => setPassword(value);
    const handleConfirmPassword = (value) => setConfirmPassword(value);
    const handleCheckSMS = (evt) => setCheckSms(evt.target.checked);
    const handleCheckEmail = (evt) => setCheckEmail(evt.target.checked);

    const handleEmail = (value) => setEmail({...email, type: 'email', value: value});
    const handlePhoneNumber = (value) => setPhoneNumber({...phoneNumber, type: 'phone', value: value});

    const handleOnClose = () => onSubmit(undefined);

    const messageBox = useMessageBox();

    useEffect(() => {
        // load().then();
        // loadDriver().then();
    }, [carrier]);

    async function deleteDriver() {
        try {
            loading.open('Deleting Driver...');
            const data = await GraphqlService.SendMutation(deleteMutation, {velox_driver_id: driver?.id});
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            loading.close();
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            onSubmit(driver);
        }
        catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    const handleDeleteDriver = () => {
        messageBox.open({
            title: 'Are you sure?',
            message: `Delete driver "${driver?.name}", this action can't be reverted!`,
            buttons: [{
                text: 'Cancel',
                css: 'btn btn-clear text-light-blue'
            }, {
                text: 'Delete',
                css: 'btn btn-danger-light',
                action: () => {
                    deleteDriver();
                }
            }]

        })
    }

    async function loadDriver(driver_id) {
        const query = gql(`
            query($driver_id: Int) {
                get_driver_by_velox_id(velox_id: $driver_id) {
                id
                name
                username
                equipment
                photos {
                    uid
                    name
                }
                contacts {
                    title
                    type
                    value
                }
            }
            }
        `);

        try {
            // loading.open('Getting Info...');


            const data = await GraphqlService.SendQuery(query, {driver_id});
            loading.close();
            return data;
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            onSubmit(undefined);
        }
    }


    useEffect(() => {
        if (driver && driver?.id) onSetDriver(driver).then();
        else onSetDriver().then();
    }, []);

    useEffect(() => {

        if (errorForm && errorForm != '')
        {
            toast.addToast(errorForm, {appearance: 'error', autoDismiss: true});
            loading.close();
        }

            
        if (response) {
            if (response.success) {
                if (isNew) setEquipments([]);

                loading.close();
                toast.addToast(response.message, {appearance: 'success', autoDismiss: true});
                if (isNew) {
                    onSubmit(response.id);
                }
                else {
                    onSetDriver(driver);
                    onUpdated(driver.id)
                }
            } else {
                toast.addToast(response.message, {appearance: 'error', autoDismiss: true});

            loading.close();
            }
        }

        dispatch(initDriver());
        
    }, [errorForm, loadingForm, response]);

    const getDriverById = async (driver_id) => {
        try {
            loading.open('Loading driver...');
            const data = await GraphqlService.SendQuery(DriverQueries.GET_DRIVER_BY_VELOX_ID, {velox_id: driver_id});
            loading.close();
            return data;
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            return onSubmit(undefined);
        }
    }

    const onSetDriver = async (driver: any = undefined) => {
        setIsNew(!driver?.id);
        // console.log(driver);
        if (!driver) driver = new Driver();
        else driver = await getDriverById(driver.id);

        

        setUsername(driver.username);
        setPassword('');
        setConfirmPassword('');
        setCurrentDriver(driver);

        if (driver && driver.contacts && driver.contacts.length > 0) {
            let phone = driver.contacts.filter((e: any) => e.type?.trim().toLowerCase() === 'phone')[0];
            let email = driver.contacts.filter((e: any) => e.type?.trim().toLowerCase() === 'email')[0];

            setEmail(email);
            setPhoneNumber(phone || '');
        } else {
            setEmail(new Contact());
            setPhoneNumber(new Contact());
        }
        if (driver?.photos && driver?.photos?.length) {
            let photos = await Promise.all(driver.photos.map(async (data, index) => {
                return {
                    id: index,
                    saved: true,
                    uid: data.uid,
                    files: undefined,
                    file: await getTempLink(data.uid)
                }
            }));

            setEquipments([...photos]);
        } else {
            setEquipments([]);
        }


        dispatch(initDriver());
    }

    const handleCurrentDriver = (key, value) => {
        let temp = {...currentDriver};
        temp[key] = value;

        setCurrentDriver({...temp});
    }

    const isValid = () => {
        return currentDriver?.name != '' && Helper.Masks.IntNumbers(phoneNumber?.value || '').length >= 10
        && ((username.trim() != '' && password.trim() != '' && (password == confirmPassword)) || currentDriver?.id != null)
        // && 
        // !currentDriver?.id  ?
        // (username.trim() != '' && password.trim() != '' && (password == confirmPassword))
        // : 
        // (true)
    }

    const handleOnSubmit = () => {
        if (!isValid()) return;

        console.warn('HEREEEEE');
        loading.open(`${isNew ? 'Creating' : 'Updating'} Driver...'`)
        let contacts = [];
        contacts.push({...email});
        contacts.push({...phoneNumber});

        if (isNew) {
            dispatch(createDriver({
                carrier_id: carrier.id,
                name: currentDriver.name,
                username: username,
                password: password,
                // contacts: contacts,
                email: email?.value || '',
                phones: [phoneNumber.value],
                equipment: currentDriver.equipment,
            }, equipments.filter(e => !e.saved)));
        } else {
            dispatch(updateDriver({
                driver: {
                    id: currentDriver.id,
                    name: currentDriver.name,
                    // contacts: contacts,
                    email: email?.value || '',
                    phones: [phoneNumber.value],
                    equipment: currentDriver.equipment,
                }
            }, equipments.filter(e => !e.saved)));
        }
    }

    const onChangePassword = () => {

        if (password && password !== confirmPassword) {
            return toast.addToast('Password and confirm password must match.', {
                appearance: 'error',
                autoDismiss: true
            });
        }

        if (password) {
            loading.open('Changing Password...')
            dispatch(updateDriverPassword({
                velox_driver_id: currentDriver.id,
                password: password,
                confirm_password: password
            }));
        }
    }


    const onChangeUsername = async () => {
        try {
            loading.open('Checking username...');
            const data = await GraphqlService.SendQuery(DriverQueries.CHECK_USERNAME, {username});


            if (!data.success) {
                throw new Error(data?.message || 'Something went wrong');
            }

            // loading.close();
            loading.open('Changing Username...');

            dispatch(updateDriverUsername({
                velox_driver_id: currentDriver.id,
                new_username: username
            }));
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    const handleRemoveEquipmentPhoto = (equipment) => {
        let temp = [...equipments];
        let newArray = temp.filter(e => e.id !== equipment.id);

        setEquipments([...newArray]);

        if (!isNew)
            dispatch(removeEquipmentFileSingle(currentDriver.id, equipment.uid));
    }


    async function getTempLink(uid, is_thumbnail = false) {
        if (!uid) return null;
        const query = gql`
            query($uid:String, $is_thumbnail:Boolean){
                get_velox_temp_link(uid:$uid is_thumbnail:$is_thumbnail)
            }
        `;

        const variables = {uid, is_thumbnail};

        try {
            return await GraphqlService.SendQuery(query, variables);
        } catch (ex) {
            return '';
        }
    }

    return (
        <div className="flex-1-container">
            <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                <p className="m-0 font-14 font-medium">{driver?.id ? 'Edit' : 'Add'} Driver on Velox</p>
                <i className="font-12 font-medium ti-close cursor-pointer" onClick={handleOnClose}></i>
            </div>

            <div className="flex-1-container row-container sm-column-container">

                <div className="col-12 d-flex">
                    <div className="flex-1-container">
                        {/* <div className={`pb-2`}>
                            <p className="font-12 font-medium">{isNew ? 'New' : 'Edit'} driver</p>
                        </div> */}

                        <div className="flex-1-container row-container sm-column-container">
                            <div className="col-6 col-sm-12 d-flex">
                                <div className="flex-1-container">

                                    <FormGroup name={'name'}
                                               value={currentDriver.name}
                                               label={'Driver name'}
                                               required
                                               placeholder={'Driver Name'}
                                               onTextChange={(value) => handleCurrentDriver('name', value)}/>

                                    <FormGroup name={'equipment'}
                                               type={'select'}
                                               required
                                               placeholder={'Select one'}
                                               options={{
                                                   data: ['enclosed', 'flatbed', 'open'],
                                                   value: x => x,
                                                   label: x => x
                                               }}
                                               value={currentDriver?.equipment || 'open'}
                                               label={'Equipment Type'}
                                               onTextChange={(value) => handleCurrentDriver('equipment', value)}/>


                                    <FormGroup name={'phone'}
                                               value={phoneNumber.value}
                                               label={'Phone #'}
                                               required
                                               colSize={6}
                                               placeholder={'000-000-0000'}
                                               onTextChange={e => handlePhoneNumber(Helper.Masks.USPhone(e))}/>

                                    <FormGroup name={'email'}
                                               value={email?.value}
                                               
                                               label={'Email address'}
                                               placeholder={'abc@xyz.com'}
                                               onTextChange={handleEmail}/>
                                    {!isNew && <div className="row pb-3 col-12 justify-content-end">
                                        {/* <div className="col-6">
                                <button className="btn btn-clear btn-block rounded-pill font-12 text-light-blue"
                                        onClick={() => onSubmit(undefined)}>CANCEL
                                </button>
                            </div> */}
                                        <div className="col-12">
                                            <button disabled={!isValid()}
                                                    className="btn btn-blue-light col-12 rounded-pill py-2 font-10"
                                                    onClick={handleOnSubmit}>{isNew ? 'ADD' : 'UPDATE'} INFO
                                            </button>
                                        </div>


                                    </div>}

                                    <div className="col-12 mb-3">
                                        <FormGroup name={'driver_name'}
                                                   value={username}
                                                   required
                                                   label={'Username*'}
                                                   colSize={12}
                                                   placeholder={'Username'}
                                                   onTextChange={handleUsername}/>

                                        {driver?.id &&
                                        <button disabled={username.trim() == currentDriver?.username?.trim()} onClick={(evt) => onChangeUsername()} className="btn btn-blue-light font-10 col-12 py-2 rounded-pill">CHANGE
                                            USERNAME</button>}
                                    </div>


                                    <div className="col-12">
                                        <FormGroup name={'password'}
                                                   value={password}
                                                   type={'password'}
                                                   label={'Password'}
                                                   required={!driver?.id}
                                                   placeholder={'Password...'}
                                                   onTextChange={handlePassword}/>

                                        <FormGroup name={'confirm_password'}
                                                   value={confirmPassword}
                                                   type={'password'}
                                                   errorText={'Passwords does not match'}
                                                   validation={x => x == password}
                                                   required={!driver?.id}
                                                   label={'Confirm Password'}
                                                   placeholder={'Confirm Password...'}
                                                   onTextChange={setConfirmPassword}/>




                                        {driver?.id &&
                                        <button onClick={(evt) => onChangePassword()} disabled={password.trim().length == 0 || password != confirmPassword} className="btn btn-blue-light font-10 col-12 py-2 rounded-pill">CHANGE
                                            PASSWORD</button>}
                                    </div>

                                    {driver?.id && <div className="row mt-4 mb-2 col-12">
                                        <div className="col-12 text-center">
                                            <button onClick={handleDeleteDriver}
                                                    className={`btn btn-block btn-danger`}>DELETE DRIVER
                                            </button>
                                        </div>
                                    </div>}

                                </div>
                            </div>


                            <div className="col-6 col-sm-12">
                                <label className="font-12 mt-3 mb-3">Upload equipment photo (optional)</label>

                                <div className="col-12 mt-3">
                                    <div className="row">
                                        {
                                            [...equipments]?.map((equipment, key) => {
                                                return (
                                                    <div key={key} className="col-6 mb-3">
                                                        <div className="btn-upload-equipment-image">
                                                            <img src={equipment.file} alt=""/>
                                                            <button
                                                                className="btn-circle btn shadow-sm bg-white btn-sm font-medium p-3 text-light-blue"
                                                                onClick={() => {
                                                                    handleRemoveEquipmentPhoto(equipment);
                                                                }}>
                                                                <i className="ti-close font-16"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                        <div className="col-6">
                                            <button className="btn btn-upload-equipment"
                                                    onClick={() => {
                                                        document.getElementById("input-file").click();
                                                    }}>
                                                <i className="ti-plus mr-2"></i> ADD PHOTO
                                                <input id="input-file"
                                                       type="file"
                                                       multiple={false}
                                                       accept="image/png, image/jpeg"
                                                       className="d-none"
                                                       onChange={(event) => {
                                                           let files = event.target.files;

                                                           if (files && files.length) {
                                                               let temp = [...equipments];
                                                               temp.push({
                                                                   id: equipments.length + 1,
                                                                   files: files,
                                                                   saved: false,
                                                                   file: URL.createObjectURL(files[0])
                                                               });

                                                               setEquipments([...temp]);
                                                           }
                                                       }}/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                           

                           
                        </div>
                    </div>
                </div>
            </div>

            <div className="row col-12">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    
                                    

                                    {isNew && <div className="row pt-3 justify-content-end col-12">
                                        {/* <div className="col-6">
                                <button className="btn btn-clear btn-block rounded-pill font-12 text-light-blue"
                                        onClick={() => onSubmit(undefined)}>CANCEL
                                </button>
                            </div> */}
                                        <div className="col-6">
                                            <button disabled={!isValid()}
                                                    className="btn btn-blue-light btn-block rounded-pill font-12"
                                                    onClick={handleOnSubmit}>{isNew ? 'ADD' : 'EDIT'} DRIVER
                                            </button>
                                        </div>
                                    </div>}
                                </div>
                            </div>
        </div>
    );
}

export default DriverSettingForm;

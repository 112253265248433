import {gql} from "apollo-boost";

const GET_RECEIVABLE_AGING = gql(`query {
  get_receivable_aging {
    id
    name
    current
    plus30
    plus60
    plus90
    total
    not_picked_up
    not_delivered
    non_move
    internal_note
  }
}`);

const AgingQuery = {
    GET_RECEIVABLE_AGING
}

export default AgingQuery;
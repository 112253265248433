export class Samsara_Truck {
    __typename?: "Samsara_truck"
    id?: string = '';
    vin?: string = '';
    serial?: string = '';
    make?: string = '';
    model?: string = '';
    year?: string = '';
    licensePlate?: string = '';
    tags?: Tag[] = [];
    staticAssignedDriver?: Tag = new Tag();
    velox_driver_id?: number = null;
    driver_id?: number = null;

    constructor(x?: Samsara_Truck) {
        if(x) {
            this.id = x.id;
            this.vin = x.vin;
            this.serial = x.serial;
            this.make = x.make;
            this.model = x.model;
            this.year = x.year;
            this.licensePlate = x.licensePlate;
            this.tags = x.tags?.map(z => z) || [];
            this.staticAssignedDriver = new Tag(x.staticAssignedDriver);
            this.velox_driver_id = x.velox_driver_id;
            this.driver_id = x.driver_id;            
        }
    }
}

export class Tag {
    __typename?: "TAG"
    id?: string = '';
    name?: string = '';

    constructor(x?: Tag) {
        if(x){
            this.id = x.id
            this.name = x.name
        }
    }
}

export class Truck_Driver {
    __typename?: "Truck_driver"
    id?: string = '';
    name?: string = '';
    location?: T_Location = new T_Location();
    image_url?: string = '';
    fuel_percentage?: string = '';

    constructor(x?: Truck_Driver){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.location = new T_Location(x.location); 
            this.image_url = x.image_url;
            this.fuel_percentage = x.fuel_percentage;            
        }
    }
}

export class T_Location {
    __typename?: "t_location"
    time?: string | Date = new Date();
    latitude?: number = 0;
    longitude?: number = 0;
    heading?: number = 0;
    speed?: number = 0;
    reverseGeo?: T_ReverseGeo = new T_ReverseGeo();

    constructor(x?: T_Location){
        if(x){
            this.time = new Date(x.time);
            this.latitude = x.latitude;
            this.longitude = x.longitude;
            this.heading = x.heading;
            this.speed = x.speed;
            this.reverseGeo = new T_ReverseGeo(x.reverseGeo);            
        }
    }
}

export class T_ReverseGeo {
    __typename?: "t_reverseGeo"
    formattedLocation?: string = '';

    constructor(x?: T_ReverseGeo){
        if(x){
            this.formattedLocation = x.formattedLocation;
        }
    }
}

export class Driver_Distance {
    __typename?: "Driver_distance"
    distance?: Google_Value = new Google_Value();
    duration?: Google_Value = new Google_Value();
    duration_in_traffic?: Google_Value = new Google_Value();
    driver?: Truck_Driver = new Truck_Driver();

    constructor(x?: Driver_Distance) {
        if(x){
            this.distance = new Google_Value(x.distance);
            this.duration = new Google_Value(x.duration);
            this.duration_in_traffic = new Google_Value(x.duration_in_traffic);
            this.driver = new Truck_Driver(x.driver);         
        }
    }
}

export class Google_Value {
    __typename?: "google_value"
    text?: string = '';
    value?: number = 0;

    constructor(x?: Google_Value){
        if(x) {
            this.text = x.text;
            this.value = x.value
        }
    }
}

export class Jurisdiction_Report {
    __typename?: "Jurisdiction_report"
    jurisdictionReports?: JurisdictionReports[] = [];
    troubleshooting?: Troubleshooting = new Troubleshooting();
    month?: string = '';
    year?: string = '';
    message?: string = '';

    constructor(x?: Jurisdiction_Report) {
        if(x){
            this.jurisdictionReports = x.jurisdictionReports?.map(z => z) || [];
            this.troubleshooting = new Troubleshooting(x.troubleshooting); 
            this.month = x.month;
            this.year = x.year;
            this.message = x.message;            
        }
    }
}

export class Truck_Jurisdiction_Report {
    __typename?: "Truck_jurisdiction_report"
    vehicleReports?: VehicleReport[] = [];
    troubleshooting?: Troubleshooting = new Troubleshooting();
    month?: string = '';
    year?: string = '';
    message?: string = '';

    constructor(x?: Truck_Jurisdiction_Report) {
        if(x){
            this.vehicleReports = x.vehicleReports?.map(z => z) || [];
            this.troubleshooting = new Troubleshooting(x.troubleshooting);
            this.month = x.month;
            this.year = x.year;
            this.message = x.message;            
        }
    }
}

export class VehicleReport {
    __typename?: "VehicleReport"
    vehicle?: Samsara_Vehicle = new Samsara_Vehicle();
    jurisdictions?: JurisdictionReports[] = [];

    constructor (x?: VehicleReport) {
        if(x){
            this.vehicle = new Samsara_Vehicle(x.vehicle);
            this.jurisdictions = x.jurisdictions?.map(z => z) || [];
        }
    }
}

export class Samsara_Vehicle {
    __typename?: "Samsara_vehicle"
    id?: string = '';
    name?: string = '';

    constructor(x?: Samsara_Vehicle) {
        if(x) {
            this.id = x.id
            this.name = x.name
        }
    }
}

export class JurisdictionReports {
    __typename?: "JurisdictionReports"
    jurisdiction?: string = '';
    totalMeters?: number = 0;
    taxableMeters?: number = 0;
    taxPaidLiters?: number = 0;

    constructor(x?: JurisdictionReports) {
        if(x){
            this.jurisdiction = x.jurisdiction;
            this.totalMeters = x.totalMeters;
            this.taxableMeters = x.taxableMeters;
            this.taxPaidLiters = x.taxPaidLiters; 
        }
    }
}

export class Troubleshooting {
    __typename?: "Troubleshooting"
    unassignedFuelTypePurchases?: number = 0;
    unassignedFuelTypeVehicles?: number = 0;
    unassignedVehiclePurchases?: number = 0;
    noPurchasesFound?: boolean = false;

    constructor(x?: Troubleshooting){
        if(x){
            this.unassignedFuelTypePurchases = x.unassignedFuelTypePurchases;
            this.unassignedFuelTypeVehicles = x.unassignedFuelTypeVehicles;
            this.unassignedVehiclePurchases = x.unassignedVehiclePurchases;
            this.noPurchasesFound  = x.noPurchasesFound;
        }
    }
}

import { gql } from 'apollo-boost';
import moment from 'moment';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import ICONS from '../../../../assets/svg';
import { CustomerProfile } from '../../../classes/classes';
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import Searchbar from '../../../components/Searchbar/Searchbar';
import IColumn from '../../../components/Table/models/IColumn';
import IMenuOption from '../../../components/Table/models/IMenuOption';
import Table from '../../../components/Table/Table';
import Helper from '../../../helper/Helper';
import useLoading from '../../../hooks/useLoading/useLoading';
import { GraphqlService } from '../../../services/graphql.service';

const query = gql`
    query($customer_id: Int!, $from_date: String!, $to_date: String!){
  get_customer_paid_statement_data(customer_id:$customer_id, from_date:$from_date, to_date:$to_date){
    id
    customer{
      id
      name
      key
      terms
      corporate_client
    }
    time
    started
    completed
    price
    payment_left
    terms
    canceled
    contacts{
      id
      name
            email
      contact{
        type
        title
        value
      }
    }
    references{
      key
      value
    }
    delivered
    picked_up
    non_move
    payment_due
    invoices{
      id
      amount
      time_due
    }
    last_invoice_amount
    needs_invoice

  }
}
`;

const queryOpen = gql`
    query($customer_id: Int!){
  get_customer_open_statement_data(customer_id:$customer_id){
    id
    customer{
      id
      name
      key
      terms
      corporate_client
    }
    time
    started
    completed
    price
    payment_left
    terms
    canceled
    contacts{
      id
      name
            email
      contact{
        type
        title
        value
      }
    }
    references{
      key
      value
    }
    delivered
    picked_up
    non_move
    payment_due
    invoices{
      id
      amount
      time_due
    }
    last_invoice_amount
    needs_invoice

  }
}
`;

const COLUMNS: IColumn[] = [
    {
        label: 'MASTER',
        active: false,
        orderBy: 'DESC',
        name: 'master_column'
    },
    {
        label: 'INVOICE #',
        active: false,
        orderBy: 'DESC',
        name: 'invoice_column',
        sort_by: 'first_invoice_id'
    },
    {
        label: 'REFERENCES',
        active: false,
        orderBy: 'DESC',
        name: '_ref'
    },
    {
        label: 'CONTACT',
        active: false,
        orderBy: 'DESC',
        name: 'contact'
    },
    {
        label: 'SENT ON',
        active: false,
        orderBy: 'DESC',
        name: 'sent_on'
    },

    {
        label: 'DUE',
        active: false,
        orderBy: 'DESC',
        name: 'due'
    },

    {
        label: 'TERMS',
        active: false,
        orderBy: 'DESC',
        name: 'terms'
    },
    {
        label: 'CURRENT INVOICE PRICE',
        active: false,
        orderBy: 'DESC',
        name: 'last_invoice_price'
    },
    {
        label: 'BALANCE AMOUNT',
        active: false,
        orderBy: 'DESC',
        name: 'balance_left'
    },
    {
        label: 'STATUS',
        active: false,
        orderBy: 'DESC',
        name: 'status'
    }];


interface ICustomerStatments {
    openOrders: boolean,
    customer_id: number,
    onClose: (evt) => any,
    onPDFReceived: (pdf: string) => any
}

const CustomerStatments: FC<ICustomerStatments> = (props) => {

    const ref = useRef();
    const [searchTerm, setSearchTerm] = useState('');
    const [rows, setRows] = useState<any[]>([]);
    const toast = useToasts();
    const [loadingTable, setLoadingTable] = useState(false);
    const loading = useLoading();

    const [fromDate, setFromDate] = useState(new Date(moment().add(-1, 'day').format('YYYY/MM/DD 00:00:00')));
    const [toDate, setToDate] = useState(new Date(moment().format('YYYY/MM/DD 23:59:59')));

    const [pickerOpen, setPickerOpen] = useState(false);

    useEffect(() => {
        setPickerOpen(!props.openOrders);
        if (props.openOrders)
            loadOpenOrders();
    }, []);

    async function loadPaid(date1, date2) {
        try {
            setLoadingTable(true);
            const data = await GraphqlService.SendQuery(query, {customer_id: props.customer_id, from_date: date1, to_date: date2});
            fillData(data);
            setFromDate(date1);
            setToDate(date2);
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            setLoadingTable(false);
        }
    }

    async function loadOpenOrders() {
        try {
            setLoadingTable(true);
            const data = await GraphqlService.SendQuery(queryOpen, {customer_id: props.customer_id});
            fillData(data);
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            setLoadingTable(false);
            
        }
    }

    async function fillData(data) {
        setLoadingTable(false);
        data = data.map(x => {
            if (x.invoices == null)
                x.invoices = [];
            // console.log(e.invoice_id)

            x.invoices = x.invoices.sort((x, y) => x.id < y.id ? 1 : -1);
            x.first_invoice_id = x.invoices[0]?.id ?? 0;
            x._ref = x.references.map(x => `${x.key}: ${x.value}`);

            if (x.invoices.length > 2) {

                x.due = [Helper.FORMAT.USDate(x.invoices[0].time_due, 'do'), Helper.FORMAT.USDate(x.invoices[1].time_due, 'do'), '+' + (x.invoices.length - 2)];
                x.sent_on = [Helper.FORMAT.USDate(x.invoices[0].time, 'do'), Helper.FORMAT.USDate(x.invoices[1].time, 'do'), '+' + (x.invoices.length - 2)];
                x.invoices_formatted = ['I-' + x.invoices[0].id, 'I-' + x.invoices[1].id, '+' + (x.invoices.length - 2)];
            }

            // if (x.invoices.length > 2)


            else if (x.invoices.length >= 1) {

                x.due = x.invoices.map(y => Helper.FORMAT.USDate(y.time_due, 'do'));
                x.sent_on = x.invoices.map(y => Helper.FORMAT.USDate(y.time, 'do'));
                x.invoices_formatted = x.invoices.map(x => 'I-' + x.id);
            }
            else if (x.invoices.length == 0) {
                x.due = ['-'];
                x.sent_on = ['-'];
                x.invoices_formatted = ['-'];
            }

            if (!x.contacts)
                x.contacts = [];
            let defaultEmails = [];

            try {
                defaultEmails = (x.contacts as CustomerProfile[]).filter(x => x.email).map((x: CustomerProfile) => [x.email, ...x.contact?.filter(x => x.type?.toLowerCase() == 'email').map(x => x.value)]).flatMap(x => x);
            } catch (ex) {
                console.log(ex.message, x);
            }

            return  {
                ...x,
                needs_invoice: x.needs_invoice,
                master_column: ['M-' + x.id, x.non_move ? 'NON MOVE' : (x.delivered ? 'DELIVERED' : (x.picked_up ? 'PICKED UP' : 'NOT PICKED UP'))],
                master_id: x.id,
                invoice_column: x.invoices_formatted,
                first_invoice_id: x.first_invoice_id,
                time_in: [Helper.FORMAT.USDate(x.time, 'do'), Helper.FORMAT.USDate(x.time, 'to')],
                contact: [x.contacts[0]?.name, x.contacts[0]?.email, (x.contacts.length > 1 ?  '+' + (x.contacts.length - 1) : '') ],
                terms: x.terms || '-',
                status: x.needs_invoice ? 'NEEDS INVOICE':'SENT',
                price: Helper.FORMAT.USCurrency(x.price),
                price_amount: +x.price,
                balance_left: Helper.FORMAT.USCurrency(x.payment_left),
                balance_left_amount: +x.payment_left,
                references: x.references,
                last_invoice_price: Helper.FORMAT.USCurrency(x.last_invoice_amount),
                last_invoice_amount: x.last_invoice_amount,

                default_emails: defaultEmails
            }});

            setRows(data);
    }


    async function getPDF(master_ids) {
        const mutation = gql`
            mutation($customer_id: Int!, $master_ids: [Int], $title: String){
                generate_customer_statement(customer_id:$customer_id, master_ids:$master_ids, title:$title)
            }
        `;

        try {
            loading.open('Getting report...');
            const data = await GraphqlService.SendMutation(mutation, {customer_id: props.customer_id, master_ids, title: props.openOrders ? 'Open Orders' : 'Paid Orders'})
            loading.close();
            props.onPDFReceived(data);
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }


    const menuOptions: IMenuOption[] = [
        {
            label: 'See Order Details',
            icon: ICONS.IconDetails,
            action: (x) => {
                Object.assign(document.createElement('a'), {
                    target: '_blank',
                    href: '/order/' + x.master_id,
                }).click();
            }
        },
        {
            label: 'Print Statment',
            icon: ICONS.IconPrintYellow,
            multipleRows: true,
            action: (x) => {
                const ids = Array.isArray(x) ? x.map(x => x.row.master_id) : [x.master_id];

                getPDF(ids).then();
            }
        }
    ];

    return <div className='flex-1-container'>
        
        <div className="row mb-3">
            <h4 className="font-11">{props.openOrders ? 'Open' : 'Paid'} Orders {((!props.openOrders && !pickerOpen)) && <span className='font-normal'>for: {moment(fromDate).format('MM/DD/YYYY')} - {moment(toDate).format('MM/DD/YYYY')}</span>}</h4>
            <div className='col-4 d-flex align-items-center'>
                <button onClick={(evt) => props.openOrders ? loadOpenOrders() : loadPaid(fromDate, toDate)} className="btn btn-icon-only mr-2">
                    <img className={loadingTable ? 'spin' : ''} src={ICONS.IconRefresh} alt="" />
                </button>
                <Searchbar className='col-12' background='#f8f8f8' placeholder='Search...' value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)} reference={ref} />
                <button onClick={props.onClose} className="btn btn-icon-only ml-2">
                    <h4 className="font-10">
                        <i className='fas fa-times'></i>
                    </h4>
                </button>
            </div>
        </div>

        <Table menuOptions={menuOptions} showCheckbox columns={COLUMNS} loading={loadingTable} rows={rows.filter(x => JSON.stringify(x).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase().trim()))} onRowClicked={(evt) => {}} />

        {pickerOpen && <DateTimePicker hideTime date={[fromDate, toDate]} modal onCancelClicked={(evt) => props.onClose(evt)} onDateSubmitted={(evt) => {
            (evt[0] as Date).setHours(0, 0, 0);
            (evt[1] as Date).setHours(23, 59, 59);
            console.log(evt);
            loadPaid(evt[0], evt[1]);
            setPickerOpen(false);
        }} />}
    </div>
}

export default CustomerStatments;
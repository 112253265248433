import IconUser from './Icon_User.svg';
import IconBusiness from './Icon_Business_16px.svg';
import IconLocked from './Icon_Locked.svg';
import IconDelete from './Icon_Delete.svg';
import IconSearch from './Icon_Search.svg';
import IconRefresh from './Icon_Refresh.svg';
import IconDetails from './Icon_Details.svg';
import IconCreateInvoice from './Icon_Create_Invoice.svg';
import IconCircleCheck from './Icon_circle_check.svg';
import IconReceipt from './Icon_Receipt.svg';
import IconDots from './Icon_Dots.svg';
import IconPlus from './Icon_Plus.svg';
import IconReverse from './Icon_Reverse.svg';
import IconEdit from './Icon_Edit.svg';
import ButtonDelete from './Button_Delete.svg';
import IconRenameLane from './Icon_Rename_Lane.svg';
import IconAddLane from './Icon_Add_New_Lane.svg';
import IconClock from './Icon_Clock.svg';
import IconUserOption from './Icon_User_Option.svg';
import IconSeeInvoice from './Icon_See_Invoice.svg';
import IconProcessPayment from './Icon_Process_Payment.svg';
import IconMoveLanes from './Icon_Move_Lanes.svg';
import IconCancelWhite from './Icon_Cancel_White.svg';
import IconConfirmBlue from './Icon_Confirm_Blue.svg';
import IconLeftBlue from './Icon_Left_Blue.svg';
import IconRightBlue from './Icon_Right_Blue.svg';
import IconTrash from './Icon_Trash.svg';
import IconPencil from './Icon_Pencil.svg';
import IconCloseBig from './Icon_Close_Big.svg';
import IconButtonEditBlue from './Button_Edit_Blue.svg';
import IconButtonEditYellow from './Button_Edit_Yellow.svg';
import IconPencilEdit from './Icon_Pencil_Edit.svg';
import IconDownloadYellow from './Icon_Download_Yellow.svg';
import IconSmsYellow from './Icon_Sms_Yellow.svg';
import IconEmailYellow from './Icon_Email_Yellow.svg';
import IconPrintYellow from './Icon_Print_Yellow.svg';
import IconCheck from './Icon_Check.svg';
import IconCheckDouble from './Icon_Check_Double.svg';
import IconDryCleaning from './Icon_Dry_Cleaning.svg';
import IconLaundry from './Icon_Laundry.svg';
import IconFluff from './Icon_Fluff.svg';
import BLOUSE from './Blouse.svg';
import CUSTOM from './Custom.svg';
import DRESS from './Dress.svg';
import EVENING_DRESS from './EveningDress.svg';
import HAT from './Hat.svg';
import JACKET from './Jacket.svg';
import JEANS from './Jeans.svg';
import JUMP_SUIT from './Jumpsuit.svg';
import OVERCOAT from './Overcoat.svg';
import PLEATED_SKIRT from './PleatedSkirt.svg';
import POLO from './Polo.svg';
import SCARF from './Scarf.svg';
import SHIRT from './Shirt.svg';
import SKIRT from './Skirt.svg';
import SLACKS from './Slacks.svg';
import SUIT from './Suit.svg';
import SWEATER from './Sweater.svg';
import TIE from './Tie.svg';
import WAIST_COAT from './Waistcoat.svg';
import ADD from './Add.svg';
import REMOVE from './Remove.svg';
import IconNotes from './Icon_notes.svg';
import IconFilter from './Icon_Filters_Off.svg'
import IconArrowRight from './Icon_Arrow_Right.svg'
import IconArrowLeft from './Icon_Arrow_Left.svg'
import IconArchive from './Icon_archive.svg';
import IconCheckBlue from './Group 9403.svg'
import IconPhone from './Icon_phone.svg'
import IconEmail from './Icon_Email.svg'
import IConCheckBlue from './Group 9403.svg'
import IconMapView from './map_view.svg';
import IconDriver from './Icon_Driver.svg';
// SIDEBAR
import DASHBOARD from './sidebar/dashboard.svg';
import ORDERS from './sidebar/receipt.svg';
import DISPATCH from './sidebar/truck.svg';
import INVENTORY from './sidebar/inventory.svg';
import AR from './sidebar/ar.svg';
import AP from './sidebar/ap.svg';
import HISTORY from './sidebar/clock.svg';
import NETWORK from './sidebar/share.svg';
import ANALYTICS from './sidebar/Icon_analytics.svg'
import CUSTOMER from './sidebar/Icon_person.svg'
import SETTING from './sidebar/Icon_settings.svg'
import LEG_HISTORY from './sidebar/Icon_history.svg';
import NEW_ORDER from './sidebar/Icon_new_order.svg';
import NOTIFICATIONS from './sidebar/Icon_notifications.svg';
import MapPin from './map-pin.svg';

const ICONS = {
    IconUser,
    IconBusiness,
    IconLocked,
    IconDelete,
    IconSearch,
    IconRefresh,
    IconDetails,
    IconCreateInvoice,
    IconReceipt,
    IconDots,
    IconPlus,
    IconReverse,
    IconEdit,
    ButtonDelete,
    IconRenameLane,
    IconAddLane,
    IconClock,
    IconUserOption,
    IconSeeInvoice,
    IconProcessPayment,
    IconMoveLanes,
    IconCancelWhite,
    IconConfirmBlue,
    IconLeftBlue,
    IconCircleCheck,
    IconRightBlue,
    IconCloseBig,
    IconButtonEditBlue,
    IconButtonEditYellow,
    IconPencilEdit,
    IconDownloadYellow,
    IconSmsYellow,
    IconEmailYellow,
    IconPrintYellow,
    IconCheck,
    IconTrash,
    IconPencil,
    IconCheckDouble,
    IconDryCleaning,
    IconFluff,
    IconLaundry,
    BLOUSE,
    CUSTOM,
    DRESS,
    EVENING_DRESS,
    HAT,
    JACKET,
    JEANS,
    JUMP_SUIT,
    OVERCOAT,
    PLEATED_SKIRT,
    POLO,
    SCARF,
    SHIRT,
    SKIRT,
    SLACKS,
    SUIT,
    SWEATER,
    TIE,
    WAIST_COAT,
    ADD,
    REMOVE,
    IconNotes,
    IconArrowRight,
    IconArrowLeft,
    IconCheckBlue,
    IconEmail,
    IconPhone,
    // SIDEBAR
    DASHBOARD,
    ORDERS,
    DISPATCH,
    INVENTORY,
    AR,
    AP,
    HISTORY,
    NETWORK,
    ANALYTICS,
    CUSTOMER,
    SETTING,
    IconFilter,
    IconArchive,
    LEG_HISTORY,
    NEW_ORDER,
    NOTIFICATIONS,
    IconMapView,
    IconDriver,
    MapPin
};

export default ICONS;

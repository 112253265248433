import React, { useEffect, useState } from 'react';
import ICONS from '../../../../../assets/svg';
import { User } from '../../../../classes/classes';
import UserQueries from '../../../../graphql/query/User.query';
import { GraphqlService } from '../../../../services/graphql.service';
import './Toolbar.scss';

const Toolbar = ({vehicle = {vin: 'GW675250', year: '2016', make: 'McLaren', model: '675LT'}}) => {

    const [user, setUser] = useState(new User());

    useEffect(() => {

        GraphqlService.SendQuery(UserQueries.GET_DATA)
        .then(d => setUser(new User(d)))
        .catch(err => console.log(err.message));
    }, []);
    
    return <div className='p-3 bg-gray-dark toolbar-component'>
        <div className="row">
            <div>
                <h4 className="font-11">{vehicle.vin}</h4>
                <h4 className="font-11 font-light">{vehicle.year} {vehicle.make} {vehicle.model}</h4>
            </div>
            <div>
                {/* <h4 className="font-11 text-right">{user.name?.toUpperCase()}</h4> */}
                <h4 className="font-11 font-light text-right">USER ID {user.id?.toString()}</h4>
            </div>
        </div>

    </div>
}

export default Toolbar;
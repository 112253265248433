import './EmployeeForm.scss';
import FormGroup from "../../../../components/FormGroup/FormGroup";
import {Address, Employee ,BasicUser} from "../../../../classes/classes";
import {useEffect, useState} from "react";
import AddressSearch from "../../../../components/Google/AddressSearch";
import Helper from "../../../../helper/Helper";
import useLoading from "../../../../hooks/useLoading/useLoading";
import {useToasts} from "react-toast-notifications";
import {GraphqlService} from "../../../../services/graphql.service";
import EmployeeMutations from "../../../../graphql/mutation/Employee";
import {Payee} from "../../../../components/PayeeForm/PayeeForm";
import useMessageBox from '../../../../hooks/useMessageBox/useMessageBox';
import { gql } from 'apollo-boost';


const states = Helper.States;
const departments = Helper.getDepartments();

const mutationDeleteEmployee = gql`
    mutation($employee_id:Int, $active:Boolean){ 
  activate_or_deactivate_employee(employee_id:$employee_id, active:$active){
    id
    message
    success
  }
}
`;

const EmployeeForm = ({showTitle = false, employee, userdata, onCancel, onSubmit, onDeleted = () => {}}) => {
    const [address, setAddress] = useState<Address>(new Address());
    const [currentEmployee, setCurrentEmployee] = useState<Employee>(employee);
    const [userData, setUserData] = useState<BasicUser>(new BasicUser());

    const loading = useLoading();
    const toast = useToasts();

    const messageBox = useMessageBox();

    useEffect(() => {
        if (employee && employee.id !== null) {
            setAddress({...employee.address});
            setCurrentEmployee(employee);
            const temp = new BasicUser(employee.user);
            setUserData(temp);
        }
    }, [employee]);

    async function deleteEmployee() {
        try {
            loading.open((employee.active ? 'Deactivating' : 'Activating') + ' Employee...');
            const data = await GraphqlService.SendMutation(mutationDeleteEmployee, {employee_id: employee.id, active: !employee.active});
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            loading.close();
            toast.addToast(data?.message, {appearance: 'success', autoDismiss: true});
            onDeleted();
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    function handleDelete() {
        messageBox.open({
            title: 'Are you sure?',
            message: `${employee?.active ? 'Deactivate' : 'Activate'} this employee`,
            buttons: [
                {
                    text: 'CANCEL',
                    css: 'btn btn-clear text-light-blue'
                },
                {
                    text: 'CONFIRM',
                    css: 'btn btn-blue-light',
                    action: deleteEmployee
                }
            ]
        })
    }

    const handleOnSubmitForm = async (wasSubmitted) => {
        if (!wasSubmitted) {
            onCancel();
            return;
        }

        // if (address.city === '' || address.country == '' ||
        //     address.postal_code === '' || address.state === '' ||
        //     address.street === '' || address.postal_code === '') {
        //     return  toast.addToast( 'Please complete empty fields', {appearance: 'error' , autoDismiss: true})
        // }

        // if (currentEmployee.name === '' ||
        //     currentEmployee.type === '' || currentEmployee.department === '') {
        //     return  toast.addToast( 'Please complete empty fields', {appearance: 'error' , autoDismiss: true})
        // }

        
        
        let tempEmployee = {...currentEmployee, address: {...address}};

        try {
            if (employee && employee.id !== null) {
                loading.open('Submitting employee...');
const variables =  { employee: {
    employee_id: tempEmployee.id,
    name: tempEmployee.name,
    address: tempEmployee.address,
    department: tempEmployee.department,
    note: tempEmployee.note,
    user: {
        id: userData.id,
        name: userData.name,
        password: userData.password
    }
}};

                const data = await GraphqlService.SendMutation(EmployeeMutations.UPDATE_EMPLOYEE, variables);
                

                loading.close();
                if (!data.success)
                    throw new Error(data.message);

                toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            } else {
                loading.open('Submitting employee...');
            
                

                

                loading.open('Submitting employee...');
                const data = await GraphqlService.SendMutation(EmployeeMutations.ADD_NEW_EMPLOYEE, {employee: {
                    
                        user: {
                            name: userData.name,
                            password: userData.password
                        },
                        name: tempEmployee.name,
                        address: tempEmployee.address,
                        department: tempEmployee.department,
                        note: tempEmployee.note,
                        tax_info: tempEmployee.tax_info,
                    }
                });

                loading.close();
                if (!data.success)
                    throw new Error(data.message);

                toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
                tempEmployee.id = data.id;


            }

            setCurrentEmployee(tempEmployee);
            console.log('got here?');
            onSubmit({...tempEmployee}, {...userData, userData: {...userData}});
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            loading.close();
        }

    }

    return(
        <>
            <div className="" style={{display: 'flex', flexDirection: 'column', flex: 1, overflowY: "hidden"}}>

                        <div className="col-12 mb-3 row">
                            { employee && employee.id === null && <span className="fs-14 font-medium mb-0" >Add Employee</span> }
                            { employee && employee.id !== null && <span className="fs-14 font-medium mb-0" >Edit Employee</span> }

                            <h4 onClick={(evt) => handleOnSubmitForm(false)} className='fs-14 cursor-pointer'><i className='fas fa-times'></i></h4>
                            {/* <span className="text-gray fs-10 font-medium">CLEAR ALL</span> */}
                        </div>

                        <div className={`row d-flex`} style={{flex: 1, overflowY: "auto"}}>

                        {/*
                            showTitle &&
                            <div className="col-12 mb-3">
                                <p className="font-14 font-medium">Create new Employee profile</p>
                            </div>
                        */}
                      
                        <FormGroup name={'name'}
                                   value={currentEmployee?.name}
                                   required={true}
                                   label={'Employee name'}
                                   onTextChange={value => setCurrentEmployee({...currentEmployee, name: value})}/>


                        <FormGroup name={'department'}
                                   required={true}
                                   value={currentEmployee?.department}
                                   label={'Department'}
                                   type={'select'}
                                   placeholder={'--'}
                                   options={{data: departments, value: x => x, label: x => x}}
                                   onTextChange={value => setCurrentEmployee({...currentEmployee, department: value})}/>

                        <div className="col-12">
                            <p className="font-12 mb-3">Employee type*</p>
                        </div>

                        <div className="col-6 mb-3">
                            <button className={`btn w-100 rounded-pill py-2 ${currentEmployee?.type == 'Payroll' ? 'btn-selected' : 'btn-unselected'}`}
                                    onClick={() => setCurrentEmployee({...currentEmployee, type: 'Payroll'})}>Payroll</button>
                        </div>

                        <div className="col-6 mb-3">
                            <button className={`btn w-100 rounded-pill py-2 ${currentEmployee?.type == 'Contractor' ? 'btn-selected' : 'btn-unselected'}`}
                                    onClick={() => setCurrentEmployee({...currentEmployee, type: 'Contractor'})}>Contractor</button>
                        </div>

                        <div className='col-12'>
                            <p className="font-14 font-medium mb-3">User Information</p>
                            <FormGroup onTextChange={(evt) => setUserData({...userData, name: evt})} value={userData?.name} placeholder='Username...' name='username' label='User Name' />
                            <FormGroup onTextChange={(evt) => setUserData({...userData, password: evt})}  value={userData?.password} placeholder='Password...' name='user_password' label='Password' type='password' autoComplete={'new-password'} />
                        </div>


                        <div className="col-12 mt-3">
                            <p className="font-14 font-medium mb-3">Contacts</p>
                        </div>

                        <AddressSearch className="col-12"
                                       onGotAddress={(newAddress) => {
                                           setAddress(newAddress);
                                       }}/>

                        <FormGroup name={'country'}
                                   label={'Country'}
                                   required={true}
                                   value={address?.country}
                                   type={'select'}
                                   placeholder={'--'}
                                   options={{
                                       data: ['United States', 'Canada'],
                                       value: x => x,
                                       label: x => x
                                   }}
                                   onTextChange={(value) => setAddress({...address, country: value})}/>

                        <FormGroup name={'street'}
                                 required={true}
                                   label={'Street address'}
                                   value={address?.street}
                                   onTextChange={(value) => setAddress({...address, street: value})}/>

                        <FormGroup name={'city'}
                                 required={true}
                                   label={'City'}
                                   value={address?.city}
                                   onTextChange={(value) => setAddress({...address, city: value})}/>

                        <div className="col-12">
                            <div className="row">
                                <FormGroup name={'state'}
                                           label={'State'}
                                           colSize={6}
                                           required={true}
                                           type={'select'}
                                           value={address?.state}
                                           placeholder={'--'}
                                           options={{data: states, value: x => x, label: x => x}}
                                           onTextChange={(value) => setAddress({...address, state: value})}/>

                                <FormGroup name={'zip'}
                                           colSize={6}
                                           label={'Zip code'}
                                           value={address?.postal_code}
                                           onTextChange={(value) => setAddress({...address, postal_code: value})}/>
                            </div>
                        </div>

                        <div className="col-12 mb-2">
                            <label className="fs-12 font-normal ">Tax ID*</label>
                        </div>

                        <FormGroup label={''}
                                   name={'taxType'}
                                   colSize={3}
                                   required={true}
                                   value={currentEmployee?.tax_info?.type}
                                   type={'select'}
                                   placeholder={'tax type'}
                                   options={{data: ['SSN', 'EIN'], value: x => x, label: x => x}}
                                   onTextChange={value => {
                                       let taxInfo = {...currentEmployee.tax_info};
                                       taxInfo.type = value;

                                       setCurrentEmployee({...currentEmployee, tax_info: {...taxInfo}});
                                   }}/>

                        <FormGroup label={''}
                                   name={'taxValue'}
                                   colSize={9}
                                   required={true}
                                   placeholder={'00-00000000'}
                                   value={currentEmployee?.tax_info?.value}
                                   onTextChange={value => {
                                       let taxInfo = {...currentEmployee.tax_info};
                                       taxInfo.value = value;

                                       setCurrentEmployee({...currentEmployee, tax_info: {...taxInfo}});
                                   }}/>
                        
                        <FormGroup required hintText={'Phone number must be xxx-xxx-xxxx'}
                                                   errorText={'Phone number must be xxx-xxx-xxxx'}
                                                   placeholder={'XXX-XXX-XXXX'}
                                                   validation={x => x.length == 12} 
                                                   colSize={5}
                                                   name={'phone'}
                                                   label={'Phone #'}
                                                   value={currentEmployee?.phone} 
                                                   onTextChange={(value) => {
                                            let temp = Helper.Masks.USPhone(value);
                                            setCurrentEmployee({...currentEmployee, phone: temp})
                                            console.log(currentEmployee)
                                        }}/>

                        <FormGroup name={'email'}
                                 required={true}
                                   label={'Email'}
                                   placeholder={'abc@xyz.com'}
                                   value={currentEmployee?.email}
                                   onTextChange={(value) => setCurrentEmployee({...currentEmployee, email: value})}/>

                       {employee?.id &&  <button
                       onClick={(evt) => handleDelete()}
                       className={'btn btn-block' + (employee?.active ? ' btn-danger-light ' : ' btn-shy-light')}>
                            {employee.active ? 'DEACTIVE EMPLOYEE' : 'ACTIVE EMPLOYEE'}
                        </button>}
                    </div>
         

                <div className="row mt-3 d-flex ">
   
                        <div className="col-6 text-right">
                            <button className="btn w-100 py-2 bg-white rounded-pill fs-12 font-medium text-light-blue"
                                    onClick={() => handleOnSubmitForm(false)}>CANCEL</button>
                        </div>
                 

                        <div className="col-6 text-right">
                            <button className="btn w-100 py-2 btn-blue-light rounded-pill fs-12 font-medium"
                                    onClick={() => {handleOnSubmitForm(true)}}>
                                { employee && employee.id === null && <span>ADD EMPLOYEE</span> }
                                { employee && employee.id !== null && <span>EDIT EMPLOYEE</span> }
                            </button>

                    </div>
                </div>
                </div>

        </>
    );
}

export default EmployeeForm;
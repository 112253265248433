import React, {useState, useRef, useEffect} from 'react'
import './InspectionReport.scss'
import LegDescription from "./components/LegDescription";
import {useParams} from "react-router";
import {GraphqlService} from "../../services/graphql.service";
import InspectionQueries from "../../graphql/query/Inspection";

import Loading from "../../components/Loading/Loading";
import { Queries } from '../../services/endpoints/queries';


const InspectionReportPage = () => {

    const {token} = useParams<{token}>();
    const [inspection, setInspection] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');


    const [height, setHeight] = useState(0);

    function onResize()
    {
        setHeight(window.innerHeight);
    }
 useEffect(() => {

        onResize();
        
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);

    async function getInspection()
    {
        try
        {
            setLoading(true);
            localStorage.setItem('use_api_key', 'true');
            setTimeout(() => {
                localStorage.removeItem('use_api_key');
            });
            const data = await Queries.get_inspections_by_token({token: token});
            //const data = await GraphqlService.SendQuery(InspectionQueries.GET_BY_TOKEN, {token: token});
            //throw new Error(JSON.stringify(data.signed_by));
            if (!data)
                throw new Error('Inspection Not found :(');
            const temp = {...data};
            temp.vehicle = temp.vehicle_data;
            setInspection(temp);
            

            setLoading(false)
        }
        catch (ex)
        {
            alert(ex.message);
            setError('Inspection Not found');
            setLoading(false);
        }
    }


    useEffect(() => {
        getInspection();

        // let z = S3Service.GetTempLink('inspections/inspection_3/photos/damage_order_4_pickup_3_132677583450794790_1623284750678.jpg.thumbnail.jpeg');
        // console.log(z);
    }, [])


    return(
        <>
           <div style={{maxHeight: '100vh'}} className="flex-1-container">
           {loading && <>
                <Loading />
                <p className={'text-center font-medium font-16 mt-3'}>Loading inspection...</p></>}
            {error && <div>
                    <h4 className='font-24 text-red text-center mb-1'>Something went wrong!</h4>
                    <h4 className='font-16 text-red text-center'>Error: {error}</h4>
                </div>}
            {inspection && <div style={{display: loading ? 'none' : 'block' }} className="col-12 inspection-detail-container">
                <div className="row">
                    <div className="col-12 mb-5">
                        <p className="font-medium font-16">Inspection report</p>
                    </div>

                    <div className="col-6">
                        <p className="font-medium font-12 text-gray mb-3">Vehicle</p>
                        <p className="font-medium font-16 mb-1">{inspection.vehicle.year} {inspection.vehicle.make} {inspection.vehicle.model}</p>
                        <p className="font-14">{inspection.vehicle.vin}</p>

                        <div className="d-flex mt-4 mb-4">
                            <p className="font-14 mr-5"><span className="dot"></span> {inspection.vehicle.color}</p>
                            <p className="font-14">{inspection.vehicle.type}</p>
                        </div>
                    </div>

                    {/*<div className="col-6 text-right">*/}
                    {/*    <p className="font-medium font-12 text-gray mb-3">Delivered by</p>*/}
                    {/*    <p className="font-medium font-16 mb-1">{inspection?.driver?.carrier?.name}</p>*/}
                    {/*    <p className="font-14">{inspection.performed_by}</p>*/}
                    {/*</div>*/}

                    <div className="col-12">
                        <LegDescription dataResolved={(d) => setLoading(false)} inspection={inspection} />
                    </div>

                    {/*<div className="col-6">*/}
                    {/*    <LegDescription title={'Dropoff'}/>*/}
                    {/*</div>*/}
                </div>

            </div>}
           </div>
        </>
    )
}

export default InspectionReportPage;

import { gql } from "apollo-boost";
import moment from "moment";
import React, { FC, useEffect, useRef, useState } from "react";
import { useToasts } from "react-toast-notifications";
import FormGroup from "../../../../components/FormGroup/FormGroup";
import Loading from "../../../../components/Loading/Loading";
import S3Queries from "../../../../graphql/query/S3";
import VehicleQueries from "../../../../graphql/query/Vehicle";
import useLoading from "../../../../hooks/useLoading/useLoading";
import useMessageBox from "../../../../hooks/useMessageBox/useMessageBox";
import { GraphqlService } from "../../../../services/graphql.service";

interface IProps {
  onCancel: (evt) => any;
  onSubmitted: (equipment: Equipment) => any;
  data: Equipment;
  onPhotosUpdated: (photos: any[], equipment_id) => any;
}

const mutationEquipment = gql`
  mutation ($equipment: equipment_input) {
    create_or_update_equipment(equipment: $equipment) {
      id
      type
      year
      make
    }
  }
`;

const mutationDeletePhoto = gql`
  mutation ($equipment_id: Int, $uid: String) {
    remove_equipment_photo(equipment_id: $equipment_id, uid: $uid) {
      id
      message
      success
    }
  }
`;

const mutationAddPhoto = gql`
  mutation ($equipment_id: Int, $photo: Upload) {
    upload_jp_equipment_photo(equipment_id: $equipment_id, photo: $photo) {
      id
      link
      message
      success
    }
  }
`;

class Equipment {
  id?: number = null;
  vin?: string = "";
  year?: string = "";
  make?: string = "";
  model?: string = "";
  type?: "open" | "enclosed" | "flatbed" | "" = "";
  photos?: any[] = [];
  registration_expiration?: Date = new Date();
  insurance_expiration?: Date = new Date();

  constructor(props?: Equipment) {
    if (props) {
      this.id = props.id;
      this.vin = props.vin;
      this.year = props.year;
      this.make = props.make;
      this.model = props.model;
      this.type = props.type;
      this.photos = props.photos ? [...props.photos] : [];
      this.registration_expiration = new Date(props.registration_expiration);
      this.insurance_expiration = new Date(props.insurance_expiration);
    }
  }
}

const EquipmentForm: FC<IProps> = (props) => {
  const [tempPhotos, setTempPhotos] = useState<{ file: any; image: any }[]>([]);
  const [equipment, setEquipment] = useState(new Equipment());
  const loading = useLoading();
  const toast = useToasts();
  const [decodingVin, setDecodingVin] = useState(false);

  const [photosUploaded, setPhotosUploaded] = useState([]);
  const messageBox = useMessageBox();

  async function getPhoto(uid) {
    return GraphqlService.SendQuery(S3Queries.GET_TEMP_LINK, { uid });
  }

  async function fillPhotos(photos) {
    try {
      const temp = [];
      for (let photo of photos) {
        const img = await getPhoto(photo.uid);
        temp.push(img);
      }
      setPhotosUploaded(temp);
    } catch (ex) {
      console.log(ex.message);
    }
  }

  useEffect(() => {
    let data = props.data || new Equipment();
    setEquipment(new Equipment(data));
    fillPhotos(data.photos);
  }, [props.data]);

  async function deleteFile(photo, index) {
    loading.open(`Deleting file ${photo.name}...`);
    try {
      const data = await GraphqlService.SendMutation(mutationDeletePhoto, {
        uid: photo.uid,
        equipment_id: props.data.id,
      });
      if (!data?.success)
        throw new Error(data?.message || "Something went wrong");
      const temp = new Equipment(equipment);
      temp.photos.splice(index, 1);

      const x = [...photosUploaded];
      x.splice(index, 1);
      setEquipment(temp);
      setPhotosUploaded(x);
      loading.close();
      toast.addToast(`Photo ${photo.name} was deleted successfully!`, {
        appearance: "success",
        autoDismiss: true,
      });
      props.onPhotosUpdated(temp.photos, props.data.id);
    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
    }
  }
  function handleDelete(photo, index) {
    messageBox.open({
      title: "Are you sure?",
      message: `Delete photo ${photo.name}, you won't be able to revert this!`,
      buttons: [
        {
          text: "Cancel",
          css: "btn btn-clear text-light-blue",
        },
        {
          text: "Yes, Delete",
          css: "btn btn-blue-light",
          action: () => {
            deleteFile(photo, index);
          },
        },
      ],
    });
  }

  function handleUpdateEquipment(key, value) {
    const temp = new Equipment({ ...equipment, [key]: value });

    setEquipment(temp);
  }

  async function uploadPhoto(equipment_id, photo) {
    return GraphqlService.SendMutation(mutationAddPhoto, {
      equipment_id,
      photo,
    });
  }

  async function uploadPhotos(equipment_id: number) {
    try {
      for (let img of tempPhotos) {
        loading.open(`Uploading photo ${img.file.name}...`);
        await uploadPhoto(equipment_id, img.file);
        toast.addToast(`Photo ${img.file.name} uploaded successfully`, {
          appearance: "success",
          autoDismiss: true,
        });
      }
    } catch (ex) {
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
    } finally {
      loading.close();
      props.onSubmitted(new Equipment({ ...equipment, id: equipment_id }));
    }
  }

  async function handleSubmit() {
    const updating = equipment.id != null;
    loading.open(`${updating ? "Updating" : "Creating"} equipment...`);
    try {
      const data = await GraphqlService.SendMutation(mutationEquipment, {
        equipment: {
          type: equipment.type,
          year: equipment.year,
          make: equipment.make,
          model: equipment.model,
          vin: equipment.vin,
          registration_expiration: equipment.registration_expiration,
          insurance_expiration: equipment.insurance_expiration,
          id: equipment.id,
        },
      });
      const temp = new Equipment({ ...equipment, id: data.id });
      setEquipment(temp);
      if (!updating && tempPhotos.length > 0) {
        toast.addToast(
          `Equipment ${updating ? "updated" : "created"} successfully`,
          { appearance: "success", autoDismiss: true }
        );
        uploadPhotos(temp.id);
      } else {
        loading.close();
        props.onSubmitted(equipment);
      }
    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
    }
  }

  const isExpired = (date: Date) => {
    const temp = new Date();
    date.setHours(0, 0, 0, 0);
    temp.setHours(0, 0, 0, 0);

    return date.getTime() < temp.getTime();
  };

  async function decodeVin() {
    try {
      setDecodingVin(true);
      const data = await GraphqlService.SendMutation(
        VehicleQueries.DECODE_VIN,
        { vin: equipment.vin }
      );
      delete data.type;

      const temp = new Equipment({ ...equipment, ...data });
      console.log(data);
      setDecodingVin(false);
      setEquipment(temp);
    } catch (ex) {
      setDecodingVin(false);
    }
  }

  async function handlePhotoChanged(evt: React.ChangeEvent<HTMLInputElement>) {
    try {
      const file = evt.target.files[0];

      inputFileRef.current.value = "";
      if (props.data.id) return uploadSingleImage(file);
      const img = URL.createObjectURL(file);
      console.log(img);
      const temp = [...tempPhotos];
      temp.push({ file: file, image: img });
      setTempPhotos(temp);
    } catch (ex) {}
  }

  async function uploadSingleImage(file) {
    try {
      loading.open(`Uploading photo ${file.name}`);
      const data = await uploadPhoto(props.data.id, file);
      toast.addToast(`Photo ${file.name} uploaded successfully`, {
        appearance: "success",
        autoDismiss: true,
      });
      const temp = new Equipment(equipment);
      temp.photos.push({ uid: data.link, name: file.name });
      setEquipment(temp);

      const img = await getPhoto(data.link);
      const x = [...photosUploaded];
      x.push(img);
      setPhotosUploaded(x);

      loading.close();
      props.onPhotosUpdated(temp.photos, props.data.id);
    } catch (ex) {
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
      loading.close();
    }
  }

  const inputFileRef = useRef<HTMLInputElement>();
  return (
    <div className="flex-1-container">
      <div className="row mb-3">
        <h4 className="font-12">
          {props.data?.id ? "Update" : "Add"} Equipment
        </h4>
        <h4 onClick={props.onCancel} className="font-12 cursor-pointer">
          <i className="fas fa-times"></i>
        </h4>
      </div>

      <div className="flex-1-container row-container">
        <div className="d-flex col-6">
          <div className="flex-1-container">
            <h4 className="font-10 mb-3">Information</h4>
            <div className="flex-1-container">
              <div className="row">
                <FormGroup
                  value={equipment.type}
                  onTextChange={(evt) => handleUpdateEquipment("type", evt)}
                  placeholder="-- Select --"
                  options={{
                    data: ["open", "enclosed", "flatbed"],
                    label: (x) => x,
                    value: (x) => x,
                  }}
                  type={"select"}
                  name={"type"}
                  label={"Type"}
                  required
                />
                <FormGroup
                  value={equipment.vin}
                  onTextChange={(evt) =>
                    handleUpdateEquipment(
                      "vin",
                      evt.substring(0, 17).toUpperCase()
                    )
                  }
                  placeholder="8 digits min."
                  type={"text"}
                  name={"vin"}
                  label={"Vin #"}
                >
                  <h4
                    onClick={(evt) => decodeVin()}
                    style={{
                      opacity:
                        decodingVin || equipment.vin.length < 17 ? 0.5 : 1,
                    }}
                    className="d-flex  align-items-center font-10 text-light-blue cursor-pointer"
                  >
                    {!decodingVin ? "DECODE" : "DECODING..."}{" "}
                    {decodingVin && (
                      <div className="ml-1">
                        <Loading size={"16px"} />
                      </div>
                    )}
                  </h4>
                </FormGroup>

                <FormGroup
                  value={equipment.year}
                  onTextChange={(evt) => handleUpdateEquipment("year", evt)}
                  required
                  colSize={6}
                  placeholder="XXXX"
                  type={"text"}
                  name={"year"}
                  label={"Year"}
                />
                <FormGroup
                  value={equipment.make}
                  onTextChange={(evt) => handleUpdateEquipment("make", evt)}
                  required
                  colSize={6}
                  placeholder="Make..."
                  type={"text"}
                  name={"make"}
                  label={"Make"}
                />
                <FormGroup
                  value={equipment.model}
                  onTextChange={(evt) => handleUpdateEquipment("model", evt)}
                  required
                  placeholder="Model..."
                  type={"text"}
                  name={"model"}
                  label={"Model"}
                />

                <FormGroup
                  errorText="Expiration must be greather than today"
                  date={equipment.registration_expiration}
                  validation={(x) => !isExpired(new Date(x))}
                  onDateChanged={(evt) =>
                    handleUpdateEquipment("registration_expiration", evt)
                  }
                  placeholder="MM/DD/YYYY"
                  type={"date"}
                  name={"registration_exp"}
                  label={"Registration Expiration"}
                  required
                />

                <FormGroup
                  errorText="Expiration must be greather than today"
                  date={equipment.insurance_expiration}
                  validation={(x) => !isExpired(new Date(x))}
                  onDateChanged={(evt) =>
                    handleUpdateEquipment("insurance_expiration", evt)
                  }
                  placeholder="MM/DD/YYYY"
                  type={"date"}
                  name={"insurance_exp"}
                  label={"Insurance Expiration"}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex col-6">
          <div className="flex-1-container">
            <div className="row mb-3">
              <h4 className="font-10">Photos</h4>
              <button
                onClick={(evt) => inputFileRef.current?.click()}
                className="font-8 btn btn-blue-light py-1"
              >
                Add Photo <i className="fas fa-plus ml-1"></i>
              </button>
            </div>
            <input
              onChange={(evt) => handlePhotoChanged(evt)}
              type="file"
              ref={inputFileRef}
              style={{ display: "none" }}
            />
            <div className="flex-1-container">
              {equipment.photos.map((x, index) => (
                <div
                  className="position-relative mb-3"
                  style={{
                    paddingTop: "50%",
                    border: "1px solid #ccc",
                    borderRadius: ".5rem",
                    overflow: "hidden",
                  }}
                >
                  <div
                    className="flex-1-container"
                    style={{
                      position: "absolute",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div className="flex-1-container">
                      <img
                        className="d-block mx-auto"
                        style={{ height: "100%", objectFit: "contain" }}
                        src={
                          photosUploaded[index] ??
                          "https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif"
                        }
                        alt=""
                      />
                    </div>
                    <div className="row">
                      <button
                        disabled={!photosUploaded[index]}
                        onClick={(evt) => {
                          handleDelete(x, index);
                        }}
                        className="btn btn-danger-light py-1 radius-0"
                        style={{ width: "100%" }}
                      >
                        DELETE<i className="fas fa-times ml-1"></i>
                      </button>
                      {/* <button className='btn btn-shy-light py-1 radius-0' style={{width: '50%'}}>SEE IMAGE<i className='fas fa-eye ml-1'></i></button> */}
                    </div>
                  </div>
                </div>
              ))}
              {tempPhotos.map((x, index) => (
                <div
                  className="position-relative mb-3"
                  style={{
                    paddingTop: "50%",
                    border: "1px solid #ccc",
                    borderRadius: ".5rem",
                    overflow: "hidden",
                  }}
                >
                  <div
                    className="flex-1-container"
                    style={{
                      position: "absolute",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div className="flex-1-container">
                      <img
                        className="d-block mx-auto"
                        style={{ height: "100%", objectFit: "contain" }}
                        src={x.image}
                        alt=""
                      />
                    </div>
                    <div className="row">
                      <button
                        onClick={(evt) => {
                          const temp = [...tempPhotos];
                          temp.splice(index, 1);
                          setTempPhotos(temp);
                        }}
                        className="btn btn-danger-light py-1 radius-0"
                        style={{ width: "100%" }}
                      >
                        DELETE<i className="fas fa-times ml-1"></i>
                      </button>
                      {/* <button className='btn btn-shy-light py-1 radius-0' style={{width: '50%'}}>SEE IMAGE<i className='fas fa-eye ml-1'></i></button> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3 row">
        <button
          className="col-6 btn btn-clear text-light-blue py-2"
          onClick={props.onCancel}
        >
          CANCEL
        </button>
        <button
          className="col-6 btn btn-blue-light py-2"
          onClick={(evt) => handleSubmit()}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
};

export default EquipmentForm;

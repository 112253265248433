import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {
  Aging_Carrier_Sp,
  Carrier, Carrier_Tax_Info,
  Velox_Carrier,
  Velox_Carrier_Insurance,
  Velox_Carrier_Registration,
  Velox_Driver
} from "../carrier";
import {StandardResult, Velox_Order} from "../../leg/leg";

export const get_velox_carriers = (input:{}) : Promise<Velox_Carrier[]> => {
  let query = gql`
    query {
      get_velox_carriers {
        id
        name
        code
        internal_note
        active
        multi_carrier
        contact {
          name
          email
          contact
        }
        drivers {
          id
          name
          username
          equipment
          carrier_code
          photos {
            uid
            name
          }
          contacts {
            email
            name
            phone
          }
          carrier {
            id
            name
            code
            internal_note
            active
            multi_carrier
            contact {
              type
              title
              value
            }
            address {
              city
              state
              street
              country
              postal_code
              street_number
              postal_code_suffix
            }
            payment_term {
              carrier_id
              broker_id
              terms
            }
          }
        }
        carrier_insurance {
          id
          name
          policy_number
          agent {
            name
            email
            phone
            fax
          }
          liability_coverage
          damage_coverage
          deductible
          expiration
          uploaded_documents {
            uid
            name
          }
          carrier_id
        }
        carrier_registration {
          id
          mc_number
          dot_number
          uploaded_documents {
            uid
            name
          }
          carrier_id
        }
        address {
          city
          state
          street
          country
          postal_code
          street_number
          postal_code_suffix
        }
        payment_term {
          carrier_id
          broker_id
          terms
        }
      }
    }
  `;

  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Velox_Carrier[]>;
}

export const get_velox_drivers_by_carrier = (input:{carrier_id: number}) : Promise<Velox_Driver[]> => {
  let query = gql`
    query {
      get_velox_drivers_by_carrier {
        id
        name
        username
        equipment
        contacts {
          title
          type
          value
        }
        photos {
          uid
          name
        }
        carrier {
          id
          name
          code
          internal_note
          active
          multi_carrier
          contact {
            name
            email
            contact
          }
        }
      }
    }
  `;

  return GraphqlService.SendQuery(query, input) as  //, {carrier_id} as 
  Promise<Velox_Driver[]>;
}

export const get_velox_aging_carriers_for_broker = (input:{showempty: boolean}) : Promise<Aging_Carrier_Sp[]> => {
  let query = gql`
    query($showempty: Boolean) {
      get_velox_aging_carriers_for_broker(showempty: $showempty) {
        late
        name
        picked_up
        carrier_id
        orders_dispatched
        drivers_dispatched
        completed
      }
    }
  `;

  return GraphqlService.SendQuery(query, input) as  //, {showempty} as 
  Promise<Aging_Carrier_Sp[]>;
}

export const get_velox_carrier_details = (input:{carrier_id: number}) : Promise<Velox_Carrier> => {
  let query = gql`
    query($carrier_id: Int) {
      get_velox_drivers_by_carrier(carrier_id: $carrier_id) {
        id
        name
        username
        equipment
        contacts {
          title
          type
          value
        }
        photos {
          uid
          name
        }
        carrier {
          id
          name
          code
          internal_note
          active
          multi_carrier
          contact {
            name
            email
            contact
          }
        }
      }
    }
  `;

  return GraphqlService.SendQuery(query, input) as  //, {carrier_id} as 
  Promise<Velox_Carrier>;
}

export const get_carrier_orders_by_date = (input:{carrier_id: number, from_date: string, to_date: string}) : Promise<Velox_Order[]> => {
  let query = gql`
    query($carrier_id: Int, $from_date: String!, $to_date: String!) {
      get_carrier_orders_by_date(carrier_id: $carrier_id, from_date: $from_date, to_date: $to_date) {
        id
        price
        completed
        accepted
        canceled
        expired
        rejected
        expires
        driver_pay
        remaining_driver_payable
        remaining_carrier_receivable
        pending_change_request
        change_requests {
          id
          order_id
          accepted
          rejected
          time
          payout
          note
          active
          expired
          expires_at
          weblink
          code
        }
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
        }
        driver {
          id
          name
          contacts {
            title
            type
            value
          }
        }
        carrier {
          id
          name
          contact {
            name
            email
            phone
          }
        }
        origin {
          scheduled
          time_completed
          completed
          nofail
          address {
            street_number
            street
            city
            state
            postal_code
            postal_code_suffix
            country
          }
        }
        destination {
          scheduled
          time_completed
          completed
          nofail
          address {
            street_number
            street
            city
            state
            postal_code
            postal_code_suffix
            country
          }
        }
      }
    }
  `;

  return GraphqlService.SendQuery(query, input) as  //, {carrier_id, from_date, to_date} as 
  Promise<Velox_Order[]>;
}

export const get_carrier_outstanding_orders = (input:{carrier_id: number}) : Promise<Velox_Order[]> => {
  let query = gql`
    query($carrier_id: Int) {
      get_carrier_orders_by_date(carrier_id: $carrier_id) {
        id
        price
        completed
        accepted
        canceled
        expired
        rejected
        expires
        driver_pay
        remaining_driver_payable
        remaining_carrier_receivable
        pending_change_request
        change_requests {
          id
          order_id
          accepted
          rejected
          time
          payout
          note
          active
          expired
          expires_at
          weblink
          code
        }
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
        }
        driver {
          id
          name
          contacts {
            title
            type
            value
          }
        }
        carrier {
          id
          name
          contact {
            name
            email
            phone
          }
        }
        origin {
          scheduled
          time_completed
          completed
          nofail
          address {
            street_number
            street
            city
            state
            postal_code
            postal_code_suffix
            country
          }
        }
        destination {
          scheduled
          time_completed
          completed
          nofail
          address {
            street_number
            street
            city
            state
            postal_code
            postal_code_suffix
            country
          }
        }
      }
    }
  `;

  return GraphqlService.SendQuery(query, input) as  //, {carrier_id} as 
  Promise<Velox_Order[]>;
}

export const generate_carrier_report = (input:{carrier_id: number}) : Promise<Carrier> => {
  let query = gql`
    query($carrier_id: Int) {
      generate_carrier_report(carrier_id: $carrier_id) {
        id
        name
      }
    }
  `;

  return GraphqlService.SendQuery(query, input) as  //, {carrier_id} as 
  Promise<Carrier>;
}

export const check_carrier_code = (input:{code: string}) : Promise<StandardResult> => {
  let query = gql`
    query($code: String) {
      check_carrier_code(code: $code) {
        success
        message
      }
    }
  `;

  return GraphqlService.SendQuery(query, input) as  //, {code} as 
  Promise<StandardResult>;
}

export const get_velox_carrier_insurance = (input:{carrier_id: number}) : Promise<Velox_Carrier_Insurance> => {
  let query = gql`
    query($carrier_id: Int) {
      get_velox_carrier_insurance(carrier_id: $carrier_id) {
        id
        name
        policy_number
        agent {
          name
          email
          phone
          fax
        }
        liability_coverage
        damage_coverage
        deductible
        expiration
        uploaded_documents {
          uid
          name
        }
        carrier_id
      }
    }
  `;

  return GraphqlService.SendQuery(query, input) as  //, {carrier_id} as 
  Promise<Velox_Carrier_Insurance>;
}

export const get_velox_carrier_registration = (input:{carrier_id: number}) : Promise<Velox_Carrier_Registration> => {
  let query = gql`
    query($carrier_id: Int) {
      get_velox_carrier_registration(carrier_id: $carrier_id) {
        id
        mc_number
        dot_number
        uploaded_documents {
          uid
          name
        }
        carrier_id
      }
    }
  `;

  return GraphqlService.SendQuery(query, input) as  //, {carrier_id} as 
  Promise<Velox_Carrier_Registration>;
}

export const get_velox_carrier_tax_information = (input:{carrier_id: number}) : Promise<Carrier_Tax_Info> => {
  let query = gql`
    query($carrier_id: Int) {
      get_velox_carrier_tax_information(carrier_id: $carrier_id) {
        carrier_id
        id
        name
        ssn
        ein
        business_name
        type 
        signature
        time_signed
        address {
          street
          city
          state
          postal_code
          country
          street_number

        }
        uploaded_documents{
          uid
          name
        }
      }
    }
  `;

  return GraphqlService.SendQuery(query, input) as  //, {carrier_id} as 
  Promise<Carrier_Tax_Info>;
}

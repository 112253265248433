import './DropdownCountry.scss'
import React, { useState,useRef,useEffect } from 'react';
import FormGroup from '../FormGroup/FormGroup';
import ReactModal from 'react-modal';
import ICONS from '../../../assets/svg';
import Helper from '../../helper/Helper';

const DropdownCountry=({onSelect,allCountry, country = ''})=> {
  const [search, setSearch] = useState('');
  const [countryList, setCountryList] = useState(Helper.country);
  const [show,setShow]=useState(false)
  const [data,setData]=useState('')
  const [visible,setVisible]=useState(true)

  const ref = useRef<HTMLDivElement>();

    useEffect(() => {
      let handleClickOut = (event: any) => {
       
              if (!ref.current.contains(event.target)) {
                  setSearch('')
                  setCountryList(Helper.country)
                  setShow(false);
          }
      }
      document.addEventListener("mousedown", handleClickOut)

      return () => {
          document.removeEventListener("mousedown", handleClickOut)
      }
  }, [])

  useEffect(() => {
    setData(country)
  }, [country]);
  
  const filter = (evt) => {
    const keyword = evt.target.value;


    if (keyword !== '') {
      const results = countryList.filter((user) => {
        return user.toLowerCase().startsWith(keyword.toLowerCase()) ;
      });
      setCountryList(results);
      !countryList.includes("United States") && !countryList.includes("Canada") && setVisible(false)


    } else {
      setVisible(true)
      setCountryList(Helper.country);
    }

    setSearch(keyword);
  };

  const handleSelectData = (item) =>{
    setData(item)
    setSearch('')
    setCountryList(Helper.country)
    setShow(false)
    onSelect(item)
  }

  return (
    <>
      <div ref={ref} className="form-group">
        <label htmlFor="">Country</label>
          {
            show ?
            <div className="container-dropdown-country ">
                  <input  name={'test'}
                          value={search}
                          required={false}
                          autoComplete={'off'}
                          placeholder={'Search by country name'}
                          className="mb-4 input-seacrhcountry pl-2 pt-2 w-100"
                          onChange={(evt)=>filter(evt)}
                        />         
                    
              <div className={`container-list `}>
                <div className={'overflox-list'}>
                    { visible &&
                      <div className="other-list">
                        {
                          countryList.filter(x=> x === 'United States' || x === 'Canada').map(item=>(
                                <li onClick={()=>handleSelectData(item)} className="item-list">
                                  <span className="user-name font-14 font-normal">{item}</span>
                                  {
                                    data === item && <img  style={{height:"20px"}} src={ICONS.IconCheckBlue}/>
                                  }
                                </li>
                          ))
                        }
                      </div>
                    }
                    {allCountry &&
                    (countryList && countryList.length > 0 ? (
                      countryList.filter(x=> x != 'United States' && x != 'Canada').map((country) => (
                        <li onClick={()=>handleSelectData(country)}  className="item-list">
                      
                          <span className="user-name font-14 font-normal">{country}</span>
                            {
                              data === country && <img  style={{height:"20px"}} src={ICONS.IconCheckBlue}/>
                            }
                        </li>
                      ))) 
                      :(<p className="text-center p-2">No results found</p>))}
                </div>
              </div>
            </div>
            
            :<>
              <div onClick={()=>setShow(true)} className="d-flex justify-content-center card bg-gray card-field mt-1 mb-4 ">
                <div className="d-flex align-items-center justify-content-between p-3">
                    <p className={`font-11 no-select ${!data?'text-gray':''}`} >
                      {!data?'Country':data}</p>
                      <i className="fa fa-chevron-down font-12"></i>
                </div>
              </div>
            </>
        }
    </div>
  </>
  );
}

export default DropdownCountry;
import FormGroup from "../FormGroup/FormGroup";
import Helper from "../../helper/Helper";
import {getAllMakes, getAllModelsMake, resetModels} from "../../redux/actions/Vehicle.action";
import {useEffect, useState} from "react";
import {QuoteVehicleInfo, Vehicle} from "../../classes/classes";
import {useDispatch, useSelector} from "react-redux";
import {AllStates} from "../../redux/reducers";
import {IVehicleState} from "../../redux/reducers/Vehicle.reducer";
import {GraphqlService} from "../../services/graphql.service";
import VehicleQueries from "../../graphql/query/Vehicle";
import {useToasts} from "react-toast-notifications";
import QuoteMutations from "../../graphql/mutation/Quote";
import Loading from "../Loading/Loading";

const colors = ['Flatbed', 'Enclosed', 'Enclosed Premium', 'Open Carrier'];

const VehicleRequestForm = ({onCancel, onSaved, quote, index}) => {
    const [vehicle, setVehicle] = useState(new QuoteVehicleInfo());
    const {makes, models} = useSelector<AllStates>(x => x.vehicles) as IVehicleState;
    const [loading, setLoading] = useState(false);

    const toast = useToasts();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllMakes());
    }, []);

    useEffect(() => {
        setVehicle(new QuoteVehicleInfo(quote.vehicle_info[index]));
    }, [index]);

    async function updateQuote()
    {
        try {
            setLoading(true);

            const vehicle_info = [...quote.vehicle_info];
            vehicle_info[index] = new QuoteVehicleInfo(vehicle);

            console.log(vehicle_info);
            const obj: any = {
                vehicle_info,
                id: quote.id
            };
            console.log(obj);

            const data = await  GraphqlService.SendMutation(QuoteMutations.UPDATE, {quote: obj});
            if (!data.success)
                throw new Error(data.message);

            //console.log(data);
            // onSaved();
            setLoading(false);
        }
        catch (ex)
        {
            console.log(ex);
            setLoading(false);
        }
    }

    const changeVinType = (key, checked = true) => {
        let temp = {...vehicle};
        // if (!temp.special_vin && 'incomplete_vin' == key) return;
        //
        // temp.no_vin = 'no_vin' == key ? checked : temp.no_vin && key == 'incomplete_vin';
        // temp.special_vin = 'special_vin' == key ? checked : temp.no_vin || key == 'incomplete_vin';
        // temp.incomplete_vin = 'incomplete_vin' == key ? !checked : true;
        //
        // temp.incomplete_vin = !
        //     (temp.no_vin ||
        //         (temp.special_vin && !temp.incomplete_vin) ||
        //         (!temp.special_vin && temp.vin.length == 17));
        // temp.no_vin = temp.special_vin && temp.vin.length == 0 && !temp.incomplete_vin;

        setVehicle(temp);
    }
    
    const getVinTypeSection = () => {
        return (
            <>
                {/*<div className="col-12 mb-2">*/}
                {/*    <p className="fs-12">VIN Type*</p>*/}
                {/*</div>*/}

                {/*<div className="col-12 vin-container">*/}
                {/*    <button className={`btn-vin ${!vehicle.special_vin && !vehicle.no_vin ? 'selected-vin' : ''}`}*/}
                {/*            onClick={() => changeVinType('special_vin', false)}>*/}
                {/*        <span className="d-flex justify-content-between align-items-center">*/}
                {/*            <p>US Standard</p>*/}
                {/*            <p>17 DIGITS</p>*/}
                {/*        </span>*/}
                {/*    </button>*/}

                {/*    <button className={`btn-vin ${vehicle.special_vin && !vehicle.no_vin ? 'selected-vin' : ''}`}*/}
                {/*            onClick={() => changeVinType('special_vin')}>*/}
                {/*        <span className="d-flex justify-content-between align-items-center">*/}
                {/*            <p>Special</p>*/}
                {/*            <p>ANY DIGITS</p>*/}
                {/*        </span>*/}
                {/*    </button>*/}

                {/*    <button className={`btn-vin ${vehicle.no_vin ? 'selected-vin' : ''}`}*/}
                {/*            onClick={() => changeVinType('no_vin')}>*/}
                {/*        <span className="d-flex justify-content-between align-items-center">NO VIN</span>*/}
                {/*    </button>*/}
                {/*</div>*/}
            </>
        );
    }

    const handleOnChanged = (key, value) => {
        let temp = {...vehicle};

        temp[key] = value;
        // if (!temp.special_vin) {
        //     temp.vin = temp.vin.substring(0, 17);
        // }
        //
        // if (key == 'vin') {
        //     temp.incomplete_vin = !
        //         (temp.no_vin ||
        //             (temp.special_vin && !temp.incomplete_vin) ||
        //             (!temp.special_vin && temp.vin.length == 17));
        //     temp.no_vin = temp.special_vin && temp.vin.length == 0 && !temp.incomplete_vin;
        // }

        setVehicle(temp);
    }

    async function decodeVin() {
        try {
            const data = await GraphqlService.SendQuery(VehicleQueries.DECODE_VIN, {vin: vehicle.vin});

            const temp = {...vehicle};
            //temp.fillData(data);
            onSaved(temp);
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error'});
        }
    }
    
    return(
        <>

            {loading ? <div className={'col-12'}>
                            <Loading/>
                            <h4 className={'font-12 text-center mb-3'}>UPDATING VEHICLE INFORMATION...</h4>
                        </div> : <div className="row">
                <div className="col-12 d-flex justify-content-between mb-4 z-index-5">
                    <p className="fs-14 font-medium">Edit vehicle request</p>
                    <p className="fs-10 font-medium cursor-pointer"
                       onClick={(evt) => onCancel(evt)}><i className="ti-close"></i></p>
                </div>

                <div className={"col-lg-12"}>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                {getVinTypeSection()}

                                <div className="col-12 mt-3 d-flex justify-content-between align-items-center position-relative">
                                    <FormGroup name={'vin'}
                                               value={vehicle.vin}
                                               label={'VIN'}
                                               placeholder={'Vin number...'}
                                               onTextChange={value => handleOnChanged('vin', value)}/>

                                    {/*<span onClick={(evt) => decodeVin()}*/}
                                    {/*      className="btn-decode text-light-blue font-10 cursor-pointer">DECODE VIN*/}
                                    {/*</span>*/}
                                </div>

                                <div className="col-12">
                                    <div className="row">
                                        <FormGroup colSize={6}
                                                   name={'year'}
                                                   value={vehicle.year}
                                                   label={'Year'}
                                                   placeholder={'XXXX'}
                                                   onTextChange={value => handleOnChanged('year', Helper.Masks.IntNumbers(value, 4))}/>

                                        <FormGroup colSize={6}
                                                   type='combobox'
                                                   name={'make'}
                                                   value={vehicle.make}
                                                   label={'Make'}
                                                   placeholder={'Make'}
                                                   options={{
                                                       data: makes,
                                                       value: x => x.Make_Name,
                                                       label: y => y.Make_Name
                                                   }}
                                                   onTextChange={value => handleOnChanged('make', value)}/>

                                        <FormGroup name={'model'}
                                                   value={vehicle.model}
                                                   label={'Model'}
                                                   type='combobox'
                                                   options={{
                                                       data: models,
                                                       value: x => x.Model_Name,
                                                       label: y => y.Model_Name
                                                   }}
                                                   onBlur={(evt) => {
                                                       dispatch(resetModels());
                                                   }}
                                                   onFocus={(evt) => {
                                                       if (vehicle.make.trim() != '')
                                                           dispatch(getAllModelsMake(vehicle.make.trim()))

                                                   }}
                                                   placeholder={'Model'}
                                                   onTextChange={value => handleOnChanged('model', value)}/>

                                        {/*<FormGroup name={'submodel'}*/}
                                        {/*           value={vehicle.submodel}*/}
                                        {/*           label={'Sub Model'}*/}
                                        {/*           placeholder={'sub model'}*/}
                                        {/*           onTextChange={value => handleOnChanged('submodel', value)}/>*/}
                                    </div>

                                    <div className="row d-flex justify-content-between align-items-center">
                                        <FormGroup name={'equipment'}
                                                   label={'Equipment'}
                                                   value={vehicle.equipment}
                                                   type='select'
                                                   colSize={6}
                                                   placeholder={'-- Select a equipment --'}
                                                   options={{data: colors, value: x => x, label: x => x}}
                                                   onTextChange={(event) => handleOnChanged('equipment', event)}/>

                                        {/*<FormGroup name={'equipment'} className="m-0"*/}
                                        {/*           label={'Expedited Shipping'}*/}
                                        {/*           value={vehicle.color}*/}
                                        {/*           checked={true}*/}
                                        {/*           onCheckChanged={() => {}}*/}
                                        {/*           type='checkbox'*/}
                                        {/*           colSize={6}*/}
                                        {/*           onTextChange={(event) => handleOnChanged('equipment', event)}/>*/}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-6">
                    <button className="btn btn-clear btn-block rounded-pill px-3 py-2 mr-3"
                            onClick={(evt) => onCancel(evt)}>CANCEL
                    </button>
                </div>

                <div className="col-6">
                    <button className="btn btn-blue-light btn-block rounded-pill px-3 py-2"
                            onClick={(evt) => updateQuote()}>SAVE CHANGES
                    </button>
                </div>
            </div>
            }
        </>
    );
}

export default VehicleRequestForm;

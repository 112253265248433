import { gql } from "apollo-boost";
import moment from "moment";
import React, { FC, useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import { ArrowContainer, Popover } from "react-tiny-popover";
import { useToasts } from "react-toast-notifications";
import environments from "../../../environments/environments";
import {
  Address,
  Carrier,
  Customer,
  Driver,
  Leg,
  MasterOrder,
  Stop,
  Vehicle,
} from "../../classes/classes";
import DispatchMutations from "../../graphql/mutation/Dispatch";
import VehicleQueries from "../../graphql/query/Vehicle";
import Helper from "../../helper/Helper";
import useLoading from "../../hooks/useLoading/useLoading";
import useMessageBox from "../../hooks/useMessageBox/useMessageBox";
import { GraphqlService } from "../../services/graphql.service";
import CarrierDriverPicker from "../CarrierDriverPicker/CarrierDriverPicker";
import CarrierDriverSelector from "../CarrierDriverSelector/CarrierDriverSelector";
import DriverCardJob from "../DriverCardJob/DriverCardJob";
import FormGroup from "../FormGroup/FormGroup";
import Loading from "../Loading/Loading";
import StopLegEntry from "../NewOrder/StopLegEntry/StopLegEntry";
import Trip from "../VehicleOrderDispatch/Trip/Trip";

const queryLeg = gql`
  query ($leg_id: Int) {
    get_leg_change_request_data(leg_id: $leg_id) {
      master_order {
        id
        customer {
          id
          name
          corporate_client
          key
          terms
          internal_note
          billing_address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
        }
        time
        started
        completed
        price
        payment_left
        terms
        canceled
        contacts {
          id
          name
          title
        }
        delivered
        picked_up
        non_move
        payment_due
      }
      current {
        id
        driver_pay
        carrier_pay
        vehicle_order {
          id
          vehicle {
            year
            make
            model
            vin
          }
        }
        carrier {
          id
          name
        }
        driver {
          id
          name
        }
        velox_order {
          id
          accepted
          rejected
          canceled
          expired
          expires
          change_requests {
            active
            accepted
            rejected
            expired
            expires_at
            payout
          }
        }
        service_charges {
          id
          name
          amount
        }
        stops {
          id
          is_pickup
          completed
          time_scheduled
          time_frame {
            start
            end
          }
          nofail
          location {
            id
            title
            customer {
              id
              name
              key
            }
            address {
              street_number
              street
              city
              state
              postal_code
              country
            }
          }
          contacts {
            id
            name
          }
        }
      }
      pending {
        id
        driver_pay
        carrier_pay
        vehicle_order {
          id
          vehicle {
            year
            make
            model
            vin
          }
        }
        carrier {
          id
          name
        }
        driver {
          id
          name
        }
        velox_order {
          id
          accepted
          rejected
          canceled
          expired
          expires
          change_requests {
            active
            accepted
            rejected
            expired
            expires_at
            payout
          }
        }
        service_charges {
          id
          name
          amount
        }
        stops {
          id
          is_pickup
          completed
          time_scheduled
          time_frame {
            start
            end
          }
          nofail
          location {
            id
            title
            customer {
              id
              name
              key
            }
            address {
              street_number
              street
              city
              state
              postal_code
              country
            }
          }
          contacts {
            id
            name
          }
        }
      }
    }
  }
`;

const mutationLeg = gql`
  mutation (
    $current: Leg_request_input
    $pending: Leg_request_input
    $hours: Int
    $carrier_message: String
  ) {
    create_change_request(
      current: $current
      pending: $pending
      hours: $hours
      carrier_message: $carrier_message
    ) {
      id
      message
      success
    }
  }
`;

interface IData {
  id: number;
  vehicle?: any;
  price?: number;
  driver_pay?: number;
  carrier?: any;
  driver?: any;
  hours?: string;
  change?: boolean;
  leg?: Leg;
}

interface IProps {
  onCancel: () => void;
  data: IData;
  onSubmitted: () => void;
  tab: "LEG" | "DISPATCH" | "VEHICLE";
}

const DispatchLeg = ({ onCancel, data, onSubmitted, tab }: IProps) => {
  const [carrierDriverOpen, setCarrierDriverOpen] = useState(false);

  const [hours, setHours] = useState("1");

  const [originalData, setOriginalData] = useState(new Leg());
  const [currentData, setCurrentData] = useState(new Leg());
  const [master, setMaster] = useState(new MasterOrder());

  const carrierRef = useRef<HTMLInputElement>();
  const driverRef = useRef<HTMLInputElement>();

  const [loadingData, setLoadingData] = useState(false);
  const [decodingVIN, setDecodingVIN] = useState(false);

  const [changeReason, setChangeReason] = useState(null);

  const [selectedTab, setSelectedTab] = useState<
    "LEG" | "DISPATCH" | "VEHICLE"
  >(tab);

  const textareaRef = useRef<HTMLTextAreaElement>();

  const messageBox = useMessageBox();

  const [legInfo, setLegInfo] = useState<{
    id: number;
    vehicle;
    price: number;
    driver_pay: number;
    carrier: any;
    driver: any;
    hours: string;
    change?: boolean;
    leg: Leg;
  }>({
    id: null,
    price: null,
    driver_pay: null,
    carrier: null,
    driver: null,
    hours: null,
    change: false,
    leg: null,
    vehicle: null,
  });

  const toast = useToasts();
  const loading = useLoading();

  useEffect(() => {
    setLegInfo(data as any);
  }, [data]);

  function handleVehicleChanged(key, value): void {
    const temp = new Leg({ ...(currentData as any) });
    temp.vehicle_order.vehicle[key] = value;
    setCurrentData(temp);
  }

  function handleEquipmentChanged(key, value) {
    const temp = new Leg({ ...(currentData as any) });
    temp[key] = value;
    temp.service_charges = [];
    setCurrentData(temp);
  }

  function getInvalidMessage() {
    const vehicle = currentData.vehicle_order.vehicle;

    if (
      vehicle.year.trim() == "" ||
      vehicle.make.trim() == "" ||
      vehicle.model.trim() == ""
    )
      return "Vehicle has missing information";

    const pickup = currentData.getPickup();

    if (pickup.contacts.length == 0 || !pickup.location.customer.id)
      return "Pickup has missing information";

    const dropoff = currentData.getDropoff();

    if (dropoff.contacts.length == 0 || !dropoff.location.customer.id)
      return "Dropoff has missing information";

    if (currentData.equipment.trim() == "") return "Equipment is missing";

    if (+currentData.equipment_price == 0) return "Equipment price is missing";

    if (
      currentData.driver?.id &&
      +currentData?.driver_pay == 0 &&
      currentData?.carrier?.id == environments.JP_CARRIER_ID
    )
      return "Driver pay is missing";

    if (
      currentData.carrier?.id &&
      (!currentData?.driver?.id ||
        currentData?.carrier?.id != environments.JP_CARRIER_ID)
    ) {
      if (+currentData?.carrier_pay == 0) return "Carrier pay is missing";

      if (+hours == 0) return "Expires In (Hours) information is missing";
    }
  }

  const StopDetail: FC<{ stop: Stop }> = (props) => {
    return (
      <div
        className="p-2 bg-gray"
        style={{ height: "100%", border: `1px solid #ccc`, borderRadius: 8 }}
      >
        <h6 className="font-12 mb-2 font-normal">
          Current {props.stop.is_pickup ? "Origin" : "Destination"} Data
        </h6>

        <div className={"col-12 px-1 mb-2"}>
          <div className="row">
            <p className="font-10 font-medium">
              {props.stop.location.customer.name}
            </p>
            <p className="font-10">{props.stop.location.customer.key}</p>
          </div>
          <p className="font-10">
            {props.stop.location.address.getName(1)}{" "}
            {props.stop.location.address.getName(2)}
            {props.stop.location.address.country.toLocaleLowerCase() ==
            "united states"
              ? ""
              : props.stop.location.address.country}
          </p>
        </div>

        <div className="px-1">
          <p className="font-10 ">Contacts</p>
          <p className="font-10 font-medium">
            {props.stop.contacts.map((x) => x.name).join(", ")}
          </p>
        </div>

        <div className="row">
          <div className=" col-6 m-0">
            <div className="p-0 p-1 m-0">
              <div className="row col-12">
                <div className="col-12">
                  <div className="row">
                    <p className="font-10 font-medium mb-1">Scheduled</p>
                  </div>
                  <p className="font-10">
                    {Helper.FORMAT.USDate(props.stop.time_scheduled, "do")}
                  </p>
                  <p className="font-10">
                    {Helper.FORMAT.USDate(props.stop.time_scheduled, "to")}
                  </p>
                  {props.stop.nofail && (
                    <p className="font-10 font-medium text-red mt-1">NO FAIL</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className=" col-6 m-0">
            <div className="p-1 ">
              {/* <p className="font-12 mb-1">Instructions for the location</p>
                        <p className="font-8 ml-2">...</p> */}

              <div className="row mb-1">
                <p className="font-10 font-medium">Time Frame</p>
              </div>
              <p className="font-10">
                {Helper.FORMAT.USDate(props.stop.time_frame.start, "do")} -{" "}
                {Helper.FORMAT.USDate(props.stop.time_frame.end, "d")}
              </p>
              <p className="font-10">
                {Helper.FORMAT.USDate(props.stop.time_frame.start, "to")} -{" "}
                {Helper.FORMAT.USDate(props.stop.time_frame.end, "to")}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function reviewSubmitChange() {
    const msg = getInvalidMessage();

    if (msg)
      return toast.addToast(msg, { appearance: "warning", autoDismiss: true });

    if (currentData?.carrier?.id) {
      setChangeReason("");
      return setTimeout(() => {
        textareaRef.current?.focus();
      });
    }
    submitChange();
  }

  function getLegFormatted(leg: Leg) {
    let res = {
      id: leg?.id,
      carrier_pay:
        environments.JP_CARRIER_ID == leg?.carrier?.id && leg.driver?.id != null
          ? +leg.driver_pay
          : +leg.carrier_pay,
      driver_pay: +leg.driver_pay,
      vehicle_order: {
        id: leg.vehicle_order.id,
        vehicle: {
          year: leg.vehicle_order.vehicle.year,
          make: leg.vehicle_order.vehicle.make,
          model: leg.vehicle_order.vehicle.model,
          vin: leg.vehicle_order.vehicle.vin,
        },
      },
      carrier: {
        id: leg.carrier?.id,
        name: leg.carrier?.name,
      },
      driver: {
        id: leg.driver?.id,
        name: leg.driver?.name,
      },
      service_charges: [
        {
          id: originalData.service_charges[0]?.id,
          name: leg.equipment,
          amount: +leg.equipment_price,
        },
      ],
      stops: leg.stops.map((stop) => ({
        id: stop.id,
        is_pickup: stop.is_pickup,
        completed: stop.completed,
        time_scheduled: stop.time_scheduled,
        time_frame: stop.time_frame,
        nofail: stop.nofail,
        location: {
          id: stop.location.id,
          title: stop.location.title,
          customer: {
            id: stop.location.customer.id,
            name: stop.location.customer.name,
            key: stop.location.customer.key,
          },
          address: {
            street_number: stop.location.address.street_number,
            street: stop.location.address.street,
            city: stop.location.address.city,
            state: stop.location.address.state,
            postal_code: stop.location.address.postal_code,
            country: stop.location.address.country,
          },
        },
        contacts: stop.contacts.map((contact) => ({
          id: contact.id,
          name: contact.name,
        })),
      })),
      velox_order: leg.velox_order,

      //  legPrice: +leg.equipment_price
    };
    return res;
  }

  async function submitChange() {
    console.log("Here");
    setChangeReason(null);

    // MUTATION COMES HERE
    loading.open("Submitting Change...");
    try {
      const data = await GraphqlService.SendMutation(mutationLeg, {
        current: getLegFormatted(originalData),
        pending: getLegFormatted(currentData),
        hours: +hours,
        carrier_message: changeReason,
      });

      if (!data?.success)
        throw new Error(data?.message || "Something went wrong");

      loading.close();

      toast.addToast(data.message, {
        appearance: "success",
        autoDismiss: true,
      });

      onSubmitted();
    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
    }
  }

  async function changeDriver(driver_id, leg_id) {
    loading.open("Changing Driver...");
    const mutation = gql`
      mutation ($leg_id: Int, $driver_id: Int) {
        change_driver(leg_id: $leg_id, driver_id: $driver_id) {
          message
          success
        }
      }
    `;
    try {
      const data = await GraphqlService.SendMutation(mutation, {
        driver_id,
        leg_id,
      });
      if (!data.success) throw new Error(data.message);

      // setLegInfo({id: null, price: null, driver_pay: null, carrier: null, driver: null, hours: ''});
      loading.close();
      toast.addToast(data.message, {
        appearance: "success",
        autoDismiss: true,
      });
      onSubmitted();
    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, { appearance: "error" });
    }
  }

  const areVehiclesSame = () => {
    const x = `${
      originalData?.vehicle_order.vehicle.vin
    } ${originalData.vehicle_order.vehicle.getName()}`;
    const y = `${
      currentData?.vehicle_order.vehicle.vin
    } ${currentData.vehicle_order.vehicle.getName()}`;

    return x == y;
  };

  function areEquipmentsSame() {
    return originalData.equipment == currentData.equipment;
  }

  function arePricesSame() {
    return +originalData.equipment_price == +currentData.equipment_price;
  }

  function areCarrierPayoutsSame() {
    return +originalData?.carrier_pay == +currentData?.carrier_pay;
  }

  function areDriverPayoutsSame() {
    return +originalData?.driver_pay == +currentData?.driver_pay;
  }

  function areLegStopsSame() {
    return (
      areStopsSame(originalData.getPickup(), currentData.getPickup()) &&
      areStopsSame(originalData.getDropoff(), currentData.getDropoff())
    );
  }

  async function decodeVIN() {
    try {
      setDecodingVIN(true);
      const data = await GraphqlService.SendQuery(VehicleQueries.DECODE_VIN, {
        vin: currentData.vehicle_order.vehicle.vin,
      });
      const temp = new Leg(currentData);
      temp.vehicle_order.vehicle.fillData(data);
      setCurrentData(temp);
    } catch (ex) {
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
    } finally {
      setDecodingVIN(false);
    }
  }

  function areStopsSame(stop1: Stop, stop2: Stop) {
    // console.log(JSON.stringify(stop1.contacts.sort((x, y) => x.id > y.id ? 1 : -1).map(x => x.id)), JSON.stringify(stop2.contacts.sort((x, y) => x.id > y.id ? 1 : -1).map(x => x.id)))
    // console.log(JSON.stringify(stop1.contacts.sort((x, y) => x.id > y.id ? 1 : -1).map(x => x.id)) == JSON.stringify(stop2.contacts.sort((x, y) => x.id > y.id ? 1 : -1).map(x => x.id)));
    return (
      stop1.nofail == stop2.nofail &&
      JSON.stringify(stop1.location.address) ==
        JSON.stringify(stop2.location.address) &&
      moment(stop1.time_scheduled).format("YYYY/MM/DD HH:mm") ==
        moment(stop2.time_scheduled).format("YYYY/MM/DD HH:mm") &&
      JSON.stringify(
        stop1.contacts.sort((x, y) => (x.id > y.id ? 1 : -1)).map((x) => x.id)
      ) ==
        JSON.stringify(
          stop2.contacts.sort((x, y) => (x.id > y.id ? 1 : -1)).map((x) => x.id)
        )
    );
  }

  async function dispatchTo() {
    const isCarrier = legInfo.driver == null;
    loading.open(`Dispatching To ${isCarrier ? "Carrier" : "Driver"}...`);
    let mutation = isCarrier
      ? DispatchMutations.TO_CARRIER
      : DispatchMutations.TO_DRIVER;
    let variables = isCarrier
      ? {
          legId: legInfo.id,
          legPrice: +legInfo.price,
          carrierId: legInfo.carrier.id,
          expires: moment()
            .add(legInfo.hours, "hours")
            .add(1, "minute")
            .toDate(),
        }
      : {
          legId: legInfo.id,
          legPrice: +legInfo.driver_pay,
          driverId: legInfo.driver.id,
          driverPay: +legInfo.driver_pay,
        };

    try {
      const data = await GraphqlService.SendMutation(mutation, variables);

      if (!data.success) throw new Error(data.message);

      loading.close();
      toast.addToast(data.message, {
        appearance: "success",
        autoDismiss: true,
      });
      onSubmitted();
    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, { appearance: "error" });
    }
  }

  async function loadLeg() {
    try {
      setLoadingData(true);
      const data = await GraphqlService.SendQuery(queryLeg, {
        leg_id: legInfo?.id,
      });
      setOriginalData(new Leg(data.current));
      setCurrentData(new Leg(data.pending));
      setMaster(new MasterOrder(data.master_order));
      setLoadingData(false);
    } catch (ex) {
      setLoadingData(false);
      toast.addToast(ex.message, { appearance: "error" });
    }
  }

  useEffect(() => {
    if (legInfo?.id) {
      loadLeg();
    }

    // loading.open('Submitting Request...');
    // setTimeout(() => {
    //     loading.close();
    // }, 10000);
  }, [legInfo]);
  const tabsData = [
    {
      label: "Vehicle info",
      key: "VEHICLE",
    },
    {
      label: "Leg info",
      key: "LEG",
    },
    {
      label: "Dispatching info",
      key: "DISPATCH",
    },
  ];

  return (
    <>
      {
        <>
          <div className="mb-3 row">
            <h4 className="font-12">Leg-{legInfo?.id}</h4>
            <h4 onClick={onCancel} className="font-12 cursor-pointer">
              <i className="fas fa-times"></i>
            </h4>
          </div>

          <div className={"d-flex mb-2"}>
            {tabsData.map((opt, index) => (
              <div className={"mr-3"} key={"key-" + index}>
                <p
                  onClick={(evt) => setSelectedTab(opt.key as any)}
                  className={`fs-12 cursor-pointer mb-1 font-medium${
                    opt.key != selectedTab ? " text-gray" : ""
                  }`}
                >
                  {opt.label}
                </p>
                <div
                  style={{
                    width: "24px",
                    height: "2px",
                    background:
                      opt.key == selectedTab ? "#42ACDE" : "transparent",
                  }}
                />
              </div>
            ))}
          </div>
          {loadingData && (
            <div>
              <Loading />
              <h4 className="font-12 text-center">
                Loading Data for Leg-{legInfo?.id}...
              </h4>
            </div>
          )}
          {!loadingData && (
            <div className="flex-1-container">
              <div className="flex-1-container">
                {selectedTab == "LEG" && (
                  <>
                    <div className="row mb-3">
                      <div className="col-6">
                        <StopDetail stop={originalData.getPickup()} />
                      </div>
                      <div className="col-6">
                        <StopLegEntry
                          gold={
                            !areStopsSame(
                              originalData.getPickup(),
                              currentData.getPickup()
                            )
                          }
                          popupFit
                          sameAsBillingClicked={(evt) => {
                            const temp = new Leg(currentData);

                            const stop = temp.getPickup();
                            stop.location.address = new Address(
                              master.customer.billing_address
                            );
                            stop.location.customer = new Customer(
                              master.customer
                            );
                            temp.setStop(stop);

                            setCurrentData(temp);
                          }}
                          stop={currentData?.getPickup()}
                          stopChanged={(evt) => {
                            const temp = new Leg(currentData);

                            temp.setStop(evt);

                            setCurrentData(temp);
                          }}
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-6">
                        <StopDetail stop={originalData.getDropoff()} />
                      </div>
                      <div className="col-6">
                        <StopLegEntry
                          gold={
                            !areStopsSame(
                              originalData.getDropoff(),
                              currentData.getDropoff()
                            )
                          }
                          popupFit
                          sameAsBillingClicked={(evt) => {
                            const temp = new Leg(currentData);

                            const stop = temp.getDropoff();
                            stop.location.address = new Address(
                              master.customer.billing_address
                            );
                            stop.location.customer = new Customer(
                              master.customer
                            );
                            temp.setStop(stop);

                            setCurrentData(temp);
                          }}
                          stop={currentData?.getDropoff()}
                          stopChanged={(evt) => {
                            const temp = new Leg(currentData);

                            temp.setStop(evt);

                            setCurrentData(temp);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row col-12 mb-3">
                      <div
                        className="col-6 d-flex h-100"
                        style={{ flexWrap: "wrap" }}
                      >
                        <div className="flex-1-container col-12">
                          <h4 className="font-10 mb-2 col-12">
                            Equipment & Price
                          </h4>
                          <div
                            className="p-2 flex-1-container col-12"
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: 8,
                            }}
                          >
                            <h4 className="font-10 mb-1">
                              {originalData?.equipment}{" "}
                              {!areEquipmentsSame() && (
                                <i className="fas fa-exclamation-triangle ml-1 text-orange"></i>
                              )}
                            </h4>
                            <h4 className="font-10 font-normal">
                              {originalData?.equipment_price?.toCurrency()}{" "}
                              {!arePricesSame() && (
                                <i className="fas fa-exclamation-triangle ml-1 text-orange"></i>
                              )}
                            </h4>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h4 className="font-10 mb-2 col-12">
                          Equipment & Price
                        </h4>
                        <div
                          className={`row align-item-start`}
                          style={{
                            border: "1px solid #CCC",
                            borderRadius: 12,
                          }}
                        >
                          <div className="col-12">
                            <div className="row">
                              <div
                                style={{
                                  borderTopLeftRadius: 8,
                                  borderBottomLeftRadius: 8,
                                  width: "70%",
                                }}
                                className={
                                  "p-2 " +
                                  (!areEquipmentsSame() ? " bg-gold" : "")
                                }
                              >
                                <FormGroup
                                  label={"Equipment"}
                                  value={currentData?.equipment}
                                  onTextChange={(evt) =>
                                    handleEquipmentChanged("equipment", evt)
                                  }
                                  placeholder={"-- Select --"}
                                  name={"equipment"}
                                  type="select"
                                  options={{
                                    data: [
                                      "Flatbed",
                                      "Enclosed",
                                      "Enclosed Premium",
                                      "Open",
                                    ],
                                    label: (x) => x,
                                    value: (x) => x,
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  borderTopRightRadius: 8,
                                  borderBottomRightRadius: 8,
                                  width: "30%",
                                }}
                                className={
                                  "p-2" + (!arePricesSame() ? " bg-gold" : "")
                                }
                              >
                                <FormGroup
                                  type="text"
                                  moneySymbol
                                  label={"Charge"}
                                  onTextChange={(evt) =>{
                                    handleEquipmentChanged(
                                      "equipment_price",
                                      evt ? +(evt.replaceAll(',',''))
                                      : ''
                                    )
                                  }}
                                  value={Helper.FORMAT.MoneyString(Number(currentData?.equipment_price).toFixed(2).toString())}
                                  placeholder={"$0.00"}
                                  name={"charge"}
                                  onFocus={(evt) => {
                                    evt.target.select();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {(selectedTab as any) == "DISPATCH" && (
                  <>
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="d-flex mb-2">
                          <h4 className="font-10">Carrier / Driver</h4>
                          <button
                            className="btn btn-orange"
                            style={{ opacity: 0, pointerEvents: "none" }}
                          >
                            TEST
                          </button>
                        </div>
                        {/* <p className='font-11 py-1 px-2 mb-0-5'>{originalData?.carrier?.name || '-'}</p>
                                        <p className='font-11 py-1 px-2'>{originalData?.driver?.name || '-'}</p> */}
                        <div
                          className="p-2"
                          style={{ border: "1px solid #ccc", borderRadius: 8 }}
                        >
                          <div className={"py-1 px-2 mb-0-5"}>
                            {
                              <p className={"font-11"}>
                                {originalData?.carrier?.name || "-"}{" "}
                                {originalData.carrier.id !=
                                  currentData.carrier.id && (
                                  <i className="fas fa-exclamation-triangle ml-1 text-orange"></i>
                                )}
                              </p>
                            }
                          </div>

                          <div className={"py-1 px-2"}>
                            <p className={"font-11"}>
                              {originalData?.driver?.name || "-"}{" "}
                              {originalData.driver.id !=
                                currentData.driver.id && (
                                <i className="fas fa-exclamation-triangle ml-1 text-orange"></i>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <CarrierDriverSelector
                          isDriverDifferent={
                            currentData.driver.id != originalData.driver.id
                          }
                          isCarrierDifferent={
                            currentData.carrier.id != originalData.carrier.id
                          }
                          leg={currentData}
                          onLegChanged={(evt) => {
                            setCurrentData(evt);
                            setTimeout(() => {
                              if (carrierRef?.current) {
                                carrierRef?.current.scrollIntoView({
                                  behavior: "smooth",
                                });
                                carrierRef?.current.select();
                              } else if (driverRef?.current) {
                                driverRef?.current?.scrollIntoView({
                                  behavior: "smooth",
                                });
                                driverRef?.current.select();
                              }
                            });
                          }}
                        />
                      </div>
                    </div>

                    {currentData.carrier?.id &&
                      (!currentData?.driver?.id ||
                        currentData?.carrier?.id !=
                          environments.JP_CARRIER_ID) && (
                        <div className="row col-12">
                          <div className="row col-12">
                            <div className="col-6 box">
                              <h4 className="label-style">
                                Carrier Payout{" "}
                                {!areCarrierPayoutsSame() && (
                                  <i className="fas fa-exclamation-triangle ml-1 text-orange"></i>
                                )}
                              </h4>
                              <h4 className="font-10 font-normal">
                                {(+(
                                  originalData.carrier_pay || 0
                                )).toCurrency()}
                              </h4>
                            </div>
                            <div
                              className={
                                "col-6 box " +
                                (!areCarrierPayoutsSame() ? " bg-gold" : "")
                              }
                            >
                              <FormGroup
                                innerRef={carrierRef}
                                required
                                errorText={"Carrier Payout must be more than 0"}
                                validation={(x) => +x > 0}
                                onTextChange={(evt) => {
                                  const temp = new Leg(currentData);
                                  temp.carrier_pay =
                                    Helper.Masks.DecimalNumbers(evt) as any;
                                  setCurrentData(temp);
                                }}
                                value={currentData.carrier_pay?.toString()}
                                moneySymbol
                                label="Carrier Payout"
                                name="payout"
                                colSize={12}
                                placeholder={"Carrier payout..."}
                              />
                            </div>
                          </div>
                          <div className="col-12 row mt-3">
                            <div className="col-6">
                              {/* <h4 className="label-style">Expires In (Hours)</h4>
                                            <h4 className="font-10 font-normal">{legInfo.hours}</h4> */}
                            </div>

                            <div className="col-6">
                              <FormGroup
                                required
                                errorText="Hours must be more than 0"
                                validation={(evt) => +evt > 0}
                                name="expires"
                                label="Must respond to this request within (Hours)"
                                placeholder="Hours"
                                value={hours}
                                onTextChange={(text) =>
                                  setHours(
                                    !text
                                      ? ""
                                      : Helper.Masks.IntNumbers(text).toString()
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}

                    {currentData.driver?.id &&
                      currentData?.carrier?.id ==
                        environments.JP_CARRIER_ID && (
                        <div className="row col-12">
                          <div className="col-6 box">
                            <h4 className="label-style">
                              Driver Payout{" "}
                              {!areDriverPayoutsSame() && (
                                <i className="fas fa-exclamation-triangle ml-1 text-orange"></i>
                              )}
                            </h4>
                            <h4 className="font-10 font-normal">
                              {(+(originalData?.driver_pay || 0)).toCurrency()}
                            </h4>
                          </div>
                          <div
                            className={
                              "col-6 box" +
                              (!areDriverPayoutsSame() ? " bg-gold" : "")
                            }
                          >
                            <FormGroup
                              innerRef={driverRef}
                              required
                              errorText={"Driver Payout must be more than 0"}
                              onTextChange={(evt) => {
                                const temp = new Leg(currentData);
                                if (evt) evt = Helper.Masks.DecimalNumbers(evt);
                                else evt = "";
                                temp.driver_pay = Helper.Masks.DecimalNumbers(
                                  evt
                                ) as any;
                                setCurrentData(temp);
                              }}
                              validation={(x) => +x > 0}
                              value={currentData.driver_pay?.toString() || "0"}
                              moneySymbol
                              label="Driver Payout"
                              name="payout"
                              colSize={12}
                              placeholder={"Driver payout..."}
                            />
                          </div>
                        </div>
                      )}
                  </>
                )}

                {(selectedTab as any) == "VEHICLE" && (
                  <div className="row col-12 mt-3">
                    <div className="col-6">
                      <div className="d-flex mb-2">
                        <h4 className="font-12 mr-2">
                          Vehicle Info{" "}
                          {!areVehiclesSame() && (
                            <i className="fas fa-exclamation-triangle ml-1 text-orange"></i>
                          )}
                        </h4>
                      </div>

                      <div
                        className="p-2"
                        style={{ border: "1px solid #CFDBE5", borderRadius: 8 }}
                      >
                        <p className="font-11 font-medium mb-0-5">
                          {originalData?.vehicle_order.vehicle?.vin || "-"}
                        </p>
                        <p className="font-10 mb-0-5">
                          {originalData?.vehicle_order.vehicle?.getName() ||
                            "-"}
                        </p>
                        <p className="font-10">
                          {originalData?.vehicle_order.vehicle?.color || "-"}
                        </p>
                      </div>
                    </div>
                    <div className={"col-6 "}>
                      <h4 className="font-12 mb-2">Vehicle Info</h4>
                      <div
                        className={
                          "row px-2 py-1" +
                          (!areVehiclesSame() ? " bg-gold" : "")
                        }
                      >
                        <div className="col-12 row">
                          <FormGroup
                            value={currentData?.vehicle_order.vehicle.vin}
                            onTextChange={(evt) =>
                              handleVehicleChanged(
                                "vin",
                                Helper.Masks.NoSpecialCharacters(
                                  evt?.toUpperCase()?.substring(0, 17).trim()
                                )
                              )
                            }
                            colSize={12}
                            label="Vin"
                            name={"vehicle_vin"}
                            placeholder="Vin..."
                          >
                            {!decodingVIN && (
                              <h4
                                onClick={(evt) => decodeVIN()}
                                style={{
                                  opacity:
                                    currentData?.vehicle_order?.vehicle?.vin
                                      ?.length == 17
                                      ? 1
                                      : 0.45,
                                }}
                                className="cursor-pointer font-10 text-blue-light"
                              >
                                DECODE
                              </h4>
                            )}
                            {decodingVIN && (
                              <div className="d-flex align-items-center">
                                <h4
                                  style={{ opacity: 0.75 }}
                                  className="font-8 text-blue-light mr-2"
                                >
                                  DECODING VIN...
                                </h4>
                                <Loading size="16px" margin="" />
                              </div>
                            )}
                          </FormGroup>
                        </div>
                        <FormGroup
                          value={currentData?.vehicle_order.vehicle.year}
                          onTextChange={(evt) =>
                            handleVehicleChanged("year", evt)
                          }
                          placeholder="XXXX"
                          name="vehicle_year"
                          label="Year"
                          colSize={6}
                        />
                        <FormGroup
                          value={currentData?.vehicle_order.vehicle.make}
                          onTextChange={(evt) =>
                            handleVehicleChanged("make", evt)
                          }
                          colSize={6}
                          label="Make"
                          name={"vehicle_make"}
                          placeholder="Make..."
                        />
                        <FormGroup
                          value={currentData?.vehicle_order.vehicle.model}
                          onTextChange={(evt) =>
                            handleVehicleChanged("model", evt)
                          }
                          colSize={12}
                          label="Model"
                          name={"vehicle_model"}
                          placeholder="Model..."
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="row col-12 mt-3">
                <button
                  className="btn btn-clear text-blue-light py-2 col-6"
                  onClick={(evt) => onCancel()}
                >
                  CANCEL
                </button>
                <button
                  className="btn btn-blue-light py-2 col-6"
                  onClick={(evt) => reviewSubmitChange()}
                >
                  SUBMIT CHANGE
                </button>
              </div>
            </div>
          )}

          <ReactModal isOpen={changeReason != null} className={"modal-fit"}>
            <div className="d-flex" style={{ width: "300px", height: "auto" }}>
              <div className="flex-1-container">
                <div className="flex-1-container">
                  <FormGroup
                    innerRef={textareaRef}
                    value={changeReason}
                    onTextChange={(evt) => setChangeReason(evt)}
                    placeholder="Explain the reason for the change...."
                    name="reason"
                    label="Message to carrier / driver"
                    type="textarea"
                  />
                </div>

                <div className="row">
                  <button
                    onClick={(evt) => setChangeReason(null)}
                    className="btn btn-clear text-blue-light col-6 py-2"
                  >
                    CANCEL
                  </button>
                  <button
                    onClick={(evt) => submitChange()}
                    className="btn btn-blue-light col-6 py-2"
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </ReactModal>
        </>
      }
    </>
  );
};

export default DispatchLeg;

import {ResultWithLink} from "../../customer_profile/customer_profile";
import {GraphqlService} from "../../../../graphql.service";
import {gql} from "apollo-boost";
import {
  Inventory_Carrier_Input,
  Inventory_Service_Charge_Input,
  Section_Edit_Input,
  Section_Input
} from "../storage_order";

export const update_or_create_storage_order = (input:{vehicle_order_id: number, warehouse_id: number, active: boolean, billing_cycle: string, rate: number, note: string, departure_date: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($vehicle_order_id: Int, $warehouse_id: Int, $active: Boolean, $billing_cycle: String, $rate: Float, $note: String, $departure_date: String) {
      update_or_create_storage_order(vehicle_order_id: $vehicle_order_id, warehouse_id: $warehouse_id, active: $active, billing_cycle: $billing_cycle, rate: $rate, note: $note, departure_date: $departure_date) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {vehicle_order_id, warehouse_id, active, billing_cycle, rate, note, departure_date} as 
  Promise<ResultWithLink>;
}

export const create_new_storage_charge = (input:{storage_order_id: number, start: string, end: string, price: number, note: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($storage_order_id: Int, $start: String, $end: String, $price: Float, $note: String) {
      create_new_storage_charge(storage_order_id: $storage_order_id, start: $start, end: $end, price: $price, note: $note) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {storage_order_id, start, end, price, note} as 
  Promise<ResultWithLink>;
}

export const delete_storage_charge = (input:{storage_charge_id: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($storage_charge_id: Int) {
      delete_storage_charge(storage_charge_id: $storage_charge_id) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {storage_charge_id} as 
  Promise<ResultWithLink>;
}

export const update_storage_charge = (input:{storage_charge_id: number, price: number, start: string, end: string, note: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($storage_charge_id: Int!, $price: Float!, $start: String!, $end: String!, $note: String!) {
      update_storage_charge(storage_charge_id: $storage_charge_id!, price: $price, start: $start, end: $end, note: $note) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {storage_charge_id, price, start, end, note} as 
  Promise<ResultWithLink>;
}

export const delete_section = (input:{storage_section_id: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($storage_section_id: Int) {
      delete_section(storage_section_id: $storage_section_id) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {storage_section_id} as 
  Promise<ResultWithLink>;
}

export const create_storage_section = (input:{section: Section_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($section: section_input) {
      create_storage_section(section: $section) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {section} as 
  Promise<ResultWithLink>;
}

export const update_storage_section = (input:{section: Section_Edit_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($section: section_edit_input) {
      update_storage_section(section: $section) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {section} as 
  Promise<ResultWithLink>;
}

export const move_inventory_vehicle_to_bay = (input:{vehicle_order_id: number, bay_id: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($vehicle_order_id: Int, $bay_id: Int) {
      move_inventory_vehicle_to_bay(vehicle_order_id: $vehicle_order_id, bay_id: $bay_id) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {vehicle_order_id, bay_id} as 
  Promise<ResultWithLink>;
}

export const insert_or_update_inventory_service = (input:{charge: Inventory_Service_Charge_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($charge: inventory_service_charge_input) {
      insert_or_update_inventory_service(charge: $charge) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {charge} as 
  Promise<ResultWithLink>;
}

export const delete_inventory_order = (input:{inventory_order_id: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($inventory_order_id: Int!) {
      delete_inventory_order(inventory_order_id: $inventory_order_id) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {inventory_order_id} as 
  Promise<ResultWithLink>;
}

export const update_inventory_order = (input:{inventory_order_id: number, time_due: string, incoming: boolean, warehouse_id: number, carrier: Inventory_Carrier_Input, note: string, completed: boolean, time_completed: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($inventory_order_id: Int, $time_due: String, $incoming: Boolean, $warehouse_id: Int, $carrier: inventory_carrier_input, $note: String, $completed: Boolean, $time_completed: String) {
      update_inventory_order(inventory_order_id: $inventory_order_id, time_due: $time_due, incoming: $incoming, warehouse_id: $warehouse_id, carrier: $carrier, note: $note, completed: $completed, time_completed: $time_completed) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {inventory_order_id, time_due, incoming, warehouse_id, carrier, note, completed, time_completed} as 
  Promise<ResultWithLink>;
}

export const add_inventory_order = (input:{warehouse_id: number, vehicle_order_id: number, time_due: string, completed: boolean, incoming: boolean, carrier: Inventory_Carrier_Input, note: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($warehouse_id: Int, $vehicle_order_id: Int, $time_due: String, $completed: Boolean, $incoming: Boolean, $carrier: inventory_carrier_input, $note: String) {
      add_inventory_order(warehouse_id: $warehouse_id, vehicle_order_id: $vehicle_order_id, time_due: $time_due, completed: $completed, incoming: $incoming, carrier: $carrier, note: $note) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {warehouse_id, vehicle_order_id, time_due, completed, incoming, carrier, note} as 
  Promise<ResultWithLink>;
}

export const move_storage_section = (input:{section_id: number, x: number, y: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($section_id: Int, $x: Float, $y: Float) {
      move_storage_section(section_id: $section_id, x: $x, y: $y) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {section_id, x, y} as 
  Promise<ResultWithLink>;
}
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {LocationInput, LocationInput_Update, LocRes} from "../location";

export const create_location = (input:{location: LocationInput}) : Promise<Location> => {
  let mutation = gql`
    mutation($location: locationInput) {
      create_location(location: $location) {
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {location} as 
  Promise<Location>;
}

export const update_location_by_id = (input:{location: LocationInput_Update}) : Promise<Location> => {
  let mutation = gql`
    mutation($location: locationInput_update) {
      update_location_by_id(location: $location) {
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {location} as 
  Promise<Location>;
}

export const delete_location_by_id = (input:{location_id: number, customer_id: number}) : Promise<LocRes> => {
  let mutation = gql`
    mutation($location_id: Int, $customer_id: Int) {
      delete_location_by_id(location_id: $location_id, $customer_id: Int) {
        success
        message
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {location_id, customer_id} as 
  Promise<LocRes>;
}
import {CustomerById, CustomerList, CustomerProfile, CustomerProfileList, Location} from "../../classes/classes";
import IReducerAction from "../models/IReducerAction";
import CustomerTypes from "../types/Customer.type";

export interface ICustomerState {
    customers: CustomerList[];
    customerProfiles: CustomerProfileList[];
    customerLocations: Location[];
    customerById: CustomerById;
    loading: boolean;
    loadingProfiles: boolean;
    loadingLocations: boolean;
    error: string;
    errorProfiles: string;
    errorLocations: boolean;
    customer_id: number;
}

const initialState: ICustomerState = {
    customers: [],
    customerProfiles: [],
    customerLocations: [],
    customerById: undefined,
    loading: false,
    loadingProfiles: false,
    loadingLocations: false,
    error: null,
    errorProfiles: null,
    errorLocations: null,
    customer_id: null,
}

export default function CustomerReducer(state: ICustomerState = initialState, action: IReducerAction): ICustomerState
{
    switch (action.type)
    {
        case CustomerTypes.GET_CUSTOMER_INFO_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CustomerTypes.GET_CUSTOMER_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case CustomerTypes.GET_CUSTOMER_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                customerById: action.payload
            };
        case CustomerTypes.GET_ALL_CUSTOMER_LIST_STARTED:
            return {
                ...state,
                loading: true,
                error: null
            }
        case CustomerTypes.GET_ALL_CUSTOMER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                customers: action.payload
            }
        case CustomerTypes.GET_ALL_CUSTOMER_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case CustomerTypes.GET_ALL_CUSTOMER_PROFILE_LIST_STARTED:
            return {
                ...state,
                customer_id: action.payload,
                loadingProfiles: true,
                errorProfiles: null
            }
        case CustomerTypes.GET_ALL_CUSTOMER_PROFILE_LIST_SUCCESS:
            return {
                ...state,
                loadingProfiles: false,
                customerProfiles: action.payload
            }
        case CustomerTypes.GET_ALL_CUSTOMER_PROFILE_LIST_ERROR:
            return {
                ...state,
                loadingProfiles: false,
                errorProfiles: action.payload
            }
        case CustomerTypes.GET_ALL_CUSTOMER_LOCATIONS_STARTED:
            return {
                ...state,
                customer_id: action.payload,
                loadingLocations: true,
                errorLocations: null
            }
        case CustomerTypes.GET_ALL_CUSTOMER_LOCATIONS_SUCCESS:
                return {
                    ...state,
                    loadingLocations: false,
                    customerLocations: action.payload
                }
        case CustomerTypes.GET_ALL_CUSTOMER_LOCATIONS_ERROR:
            return {
                ...state,
                loadingLocations: false,
                errorLocations: action.payload
            }
        default:
            return state;
    }
}

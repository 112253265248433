
import { ApolloClient, ApolloCurrentResult, gql, NormalizedCache, NormalizedCacheObject, Observable, ObservableQuery } from "apollo-boost";
import { useApolloClient } from "react-apollo";
import Helper from "../helper/Helper";
import { Subject, Subscription } from 'rxjs';


export class ObservableQueryT extends ObservableQuery {

    onResults: Subject<{error: Error, data: any}> = new Subject();
    subscription: Subscription;
    constructor(x)
    {
        super(x);

        this.subscription = x.subscribe(data => this.handleResponse(data),
        err => this.handleError(err));
    }


    handleResponse = (result) =>
    {
        if (result.errors)
            this.onResults.next({error: GraphqlService.ErrorFormated(new Error(result.errors.map((x, i) => `${i + 1}.) ${x.message}`).join('\n'))), data: null});
        else
        {
            const firstKey = Object.keys(result.data)[0];
            this.onResults.next({error: null, data: result.data[firstKey]});
        }
    }

    handleError = (error) => {
        this.onResults.next({error: GraphqlService.ErrorFormated(error), data: null});
        // this.refetch();
        this.startPolling(500);
    }

    async refetch(variables?): Promise<any> {
        try {
            let data = await super.refetch(variables);
   
            this.handleResponse(data);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async stopPolling() {
        try {
            super.stopPolling();
            this.subscription.unsubscribe();
        } catch (ex) {
            
        }
    }

}

export class GraphqlService
{


    static client: ApolloClient<any>;

    static SetClient(client: ApolloClient<any>)
    {
        if (this.client)
            return;
        this.client = client
    }
    private static fromObservableQuery<T = any>(observableQuery: ObservableQuery<T>): Observable<ApolloCurrentResult<T>> {
        return new Observable((subscriber) => {
            const subscription: ZenObservable.Subscription = observableQuery.subscribe(
                (value) => subscriber.next(value),
                (error) => subscriber.error(error),
                () => subscriber.complete());
            return () => {
                subscription.unsubscribe();
            };
        });
    }
    static SendQueryObservable(query: import('graphql').DocumentNode, variables?: any, preventRedirect = false): ObservableQueryT
    {
        return new ObservableQueryT(this.client.watchQuery({query, variables, pollInterval: 500, fetchPolicy: 'no-cache'}));
        
        // this.fromObservableQuery(result).pipe
        
        // result.map(d => d);
        // result.map((r) => {
            
        //     let errors = r.errors ? null : new Error(r.errors.map((x, i) => `${i + 1}.) ${x.message}`).join('\n')) as any;
            
        //     let firstKey = Object.keys(r.data)[0];
        //     const data = r.data[firstKey];
        //     return {data, errors: errors, loading: r.loading, networkStatus: r.networkStatus, stale: r.stale};
        // })
        
        
    }

    static async SendQuery(query: import('graphql').DocumentNode, variables?: any, preventRedirect = false)
    {
        try {
            
            let result = await this.client.query({query, variables, fetchPolicy: 'no-cache',fetchResults: false});

            if (result.errors) throw new Error(result.errors.map((x, i) => `${i + 1}.) ${x.message}`).join('\n'));

            let firstKey = Object.keys(result.data)[0];
            


            return result.data[firstKey];
        } catch (ex) {
            throw this.ErrorFormated(ex);
        }

    }

    static async SendMultipleQuery(query: import('graphql').DocumentNode, variables?: any)
    {


        try {
            const { data, errors } = await this.client.query({query, variables, fetchPolicy: 'no-cache'});


            if (errors) throw new Error(errors.map((x, i) => `${i + 1}.) ${x.message}`).join('\n'));

            let keys  = Object.keys(data);


            return keys.map(key => data[key]);
        } catch (ex) {
            throw this.ErrorFormated(ex);
        }

    }

    static async SendMultipleMutation(mutation: import('graphql').DocumentNode, variables?: any)
    {


        try {
            const { data, errors } = await this.client.mutate({mutation, variables, fetchPolicy: 'no-cache'});


            if (errors) throw new Error(errors.map((x, i) => `${i + 1}.) ${x.message}`).join('\n'));

            let keys  = Object.keys(data);


            return keys.map(key => data[key]);
        } catch (ex) {
            throw this.ErrorFormated(ex);
        }

    }

    static async SendMutation(mutation: import('graphql').DocumentNode, variables?: any)
    {
        try {
            const { data, errors } = await this.client.mutate({mutation, variables, fetchPolicy: 'no-cache',
            context: 
                {
                    useMultipart: true
                }});

            if (errors) throw new Error(errors.map((x, i) => `${i + 1}.) ${x.message}`).join('\n'));
                
            let firstKey = Object.keys(data)[0];
            
            return data[firstKey];
        } catch (ex) {
            throw this.ErrorFormated(ex);
        }


    }

    static ErrorFormated(ex){
        let msg: string = (ex.networkError ? (ex.networkError?.result?.errors ? ex.networkError.result.errors.map((x: any, i: number) => `${i + 1}.) ${x.message}`).join('\n') : ex.networkError?.message) : ex.message || ex) || '';

        if (msg.toLowerCase().includes('not authenticated'))
        {
            setTimeout(() => {
                
                Helper.Session.DoLogout(true);
            }, 10);
        }
        
        return new Error(msg);
    }
}
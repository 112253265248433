import React, {useState, useEffect, useRef, ChangeEvent} from 'react'
import Helper from '../../helper/Helper';
import FormGroup from '../FormGroup/FormGroup';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import {
  CarrierInfo,
  CarrierInsurance, deleteMutationsCarrierProfile, FileDocument,
  mutationsCarrierProfile
} from "../../pages/CarriersProfilePage/CarriersProfilePage";
import {GraphqlService} from "../../services/graphql.service";
import CarrierMutation from "../../graphql/mutation/Carrier";
import {useToasts} from "react-toast-notifications";
import useLoading from "../../hooks/useLoading/useLoading";
import moment from 'moment';
import {gql} from "apollo-boost";
import useMessageBox from "../../hooks/useMessageBox/useMessageBox";
import {CarrierProfileHelper} from "../../helper/CarrierProfileHelper";

const InsuranceInfoForm = ({onCancel, onSubmit, data, carrier_insurance}) => {
    const toast = useToasts();
    const loading = useLoading();
  const messageBox = useMessageBox();
    const fileRef = useRef<HTMLInputElement>();
    const [carrier, setCarrier] = useState<CarrierInfo>(new CarrierInfo());
    const [carrierInsurance, setCarrierInsurance] = useState<CarrierInsurance>(new CarrierInsurance());

    const [openModal, setOpenModal] = useState(false)

    const onHandleSubmit = async () => {
        try {
            let insurance = {
                "name": carrierInsurance.name,
                "policy_number": carrierInsurance.policy_number,
                "agent": {
                    "name": carrierInsurance.agent.name,
                    "email": carrierInsurance.agent.email,
                    "phone": carrierInsurance.agent.phone,
                    "fax": carrierInsurance.agent.fax
                },
                "liability_coverage": +carrierInsurance.liability_coverage,
                "damage_coverage": +carrierInsurance.damage_coverage,
                "deductible": +carrierInsurance.deductible,
                "expiration": carrierInsurance.expiration,
                "carrier_id": carrier.id
            };

            loading.open('saving...');
            await GraphqlService.SendMutation(CarrierMutation.CARRIER_INSURANCE, { insurance });
            onSubmit();

                toast.addToast('Insurance information saved successfully', {appearance: 'success', autoDismiss: true});
                loading.close();
        } catch (ex) {
            loading.close();
        }
    }

  async function loadCarrierInsurance(carrier_id: number) {
    try {
      if(!carrier_id || carrier_id < 1){
        throw new Error('Missing carrier_id in insurance form')
      }
      const query = gql`
      query  ($carrier_id: Int) {
          get_velox_carrier_insurance (carrier_id: $carrier_id) {
            id
            name
            policy_number
            agent {
              name
              email
              phone
              fax
            }
            liability_coverage
            damage_coverage
            deductible
            expiration
            carrier_id
            uploaded_documents{
              uid
              name
            }
          }
        }
        
      `;

const data = await GraphqlService.SendQuery(query, {carrier_id});
      setCarrierInsurance(new CarrierInsurance(data));
    } catch (ex) {
      toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
    }
  }

  async function handleInputChange(evt: ChangeEvent<HTMLInputElement>) {
    try {
      const file = evt.target.files[0];
      loading.open(`Uploading insurance file: ${file.name}`);
      const data = await GraphqlService.SendMutation(mutationsCarrierProfile['insurance'], {carrier_id: carrier.id, file});
      if (!data?.success)
        throw new Error(data?.message || 'Something went wrong');
      toast.addToast(data?.message, {appearance: 'success',autoDismiss: true});
      loadCarrierInsurance(carrier?.id).then();
    } catch (ex) {
      toast.addToast(ex.message, {appearance: 'error',autoDismiss: true});
    } finally {
      loading.close();
    }
  }

  async function deleteFile(document: FileDocument) {
    try {
      loading.open(`Deleting insurance document: ${document.name}...`);
      const data = await GraphqlService.SendMutation(deleteMutationsCarrierProfile['insurance'], {carrier_id: carrier.id, uid: document.uid});
      if (!data?.success)
        throw new Error(data?.message || 'Something went wrong');
      loading.close();
      loadCarrierInsurance(carrier?.id).then();
      toast.addToast(data.message, {appearance: 'success',autoDismiss: true});
    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, {appearance: 'error',autoDismiss: true});
    }
  }

  async function previewFile(doc: FileDocument) {
    await CarrierProfileHelper.previewFile(doc);
  }

  async function removeFile(document: FileDocument) {
    messageBox.open({
      title: 'Delete file: ' + document.name,
      message: `Are you sure you want to delete this file?, you won't be able to revert this!`,
      buttons: [
        {
          text: 'Cancel',
          css: 'btn btn-shy-light'
        },
        {
          text: 'Confirm',
          css: 'btn btn-blue-light',
          action: () => deleteFile(document)
        }
      ]
    })
  }

    useEffect(() => {
        setCarrier(data);
        setCarrierInsurance(carrier_insurance);
    }, [data]);

    return (
        <>
            <div className="flex-1-container">
                <div className="col-12 mb-3 d-flex row align-items-center">
                    <span className="fs-14 font-medium mb-0">Edit insurance info</span>
                    <span onClick={onCancel} className="fs-14 font-medium cursor-pointer"><i className='fas fa-times'></i></span>
                </div>

                <div className="flex-1-container">
                    <div className={`row `}>

                        <div className="col-12 mb-3 d-flex row align-items-center">
                            <span className="fs-14 font-medium mb-0">Liability coverage</span>
                            {/* <span className="text-gray fs-10 font-medium cursor-pointer no-select">CLEAR ALL</span> */}
                        </div>

                        <FormGroup moneySymbol name={'liabilityCoverage'}
                                   value={`${carrierInsurance.liability_coverage}`}
                                   required={true}
                                   colSize={6}
                                  //  type={'number'}
                                   noDecimal
                                   label={'Liability coverage'}

                                   errorText={'Value must be greater than zero'}
                                    validation={x => +Helper.Masks.IntNumbers(x) > 0}
                                   placeholder={"0.00"}
                                   onTextChange={value => {
                                       value = Helper.Masks.DecimalNumbers(value);
                                       
                                       setCarrierInsurance({
                                         ...carrierInsurance,
                                         liability_coverage: value as any
                                       })
                                   }}
                        />
                         <FormGroup onClick={() => setOpenModal(true)}
                         name={'expirationDate'}
                                   className={"position-relative"}
                                   value={`${moment(carrierInsurance.expiration).format('MM/DD/YYYY')}`}
                                   required={true}
                                   colSize={6}
                                   label={'Expiration date'}
                                   placeholder={"00 / 00 / 0000"}
                                   onTextChange={() => {}}>
                            <div
                                 className="cursor-pointer no-select"
                                 
                            ><i className="far fa-calendar-alt"></i></div>
                        </FormGroup>


                        <FormGroup moneySymbol name={'Cargo_coverage'}
                                   value={`${carrierInsurance.damage_coverage}`}
                                   required={true}
                                  //  type={'number'}
                                   noDecimal
                                   colSize={6}
                                   errorText={'Value must be greater than zero'}
                                    validation={x => +Helper.Masks.IntNumbers(x) > 0}
                                   label={'Cargo coverage'}
                                   placeholder={'0.00'}
                                   onTextChange={value => {
                                       value = Helper.Masks.DecimalNumbers(value);
                                       setCarrierInsurance({...carrierInsurance, damage_coverage: value as any})
                                   }}
                        />

                       
                       


                        <FormGroup moneySymbol name={'deductible'}
                                   value={`${carrierInsurance.deductible}`}
                                   required={true}
                                   noDecimal
                                   colSize={6}
                                  //  type={'number'}
                                   label={'Deductible'}
                                   errorText={'Value must be greater than zero'}
                                    validation={x => +Helper.Masks.IntNumbers(x) > 0}
                                   placeholder={'0.00'}
                                   onTextChange={value => {
                                       value = Helper.Masks.DecimalNumbers(value);
                                       
                                       setCarrierInsurance({...carrierInsurance, deductible: value as any})
                                   }}
                        />


                        <FormGroup name={'insuranceCompanyName'}
                                   value={carrierInsurance.name}
                                   required={true}
                                   label={'Insurance company name'}
                                   placeholder={"Company Name..."}
                                   onTextChange={value => setCarrierInsurance({...carrierInsurance, name: value})}
                        />

                        <FormGroup name={'Policynumber'}
                                   value={carrierInsurance.policy_number}
                                   required={true}
                                   label={'Policy #'}
                                   placeholder={"XXXXXXXX"}
                                   onTextChange={value => setCarrierInsurance({...carrierInsurance, policy_number: value})}
                        />

                        <FormGroup name={'agentName*'}
                                   value={carrierInsurance.agent.name}
                                   required={true}
                                   label={'Agent name'}
                                   placeholder={"Agent name"}
                                   onTextChange={value => setCarrierInsurance({
                                     ...carrierInsurance,
                                     agent: {...carrierInsurance.agent, name: value}
                                   })}
                        />

                        <FormGroup required hintText={'Phone number must be 10 digitis'}
                                   errorText={'Phone number must be 10 digits'}
                                   validation={x => Helper.Masks.IntNumbers(x).length >= 10}
                                   colSize={12}
                                   name={'agentPhone'}
                                   label={'Agent phone #'}
                                   placeholder={"XXX-XXX-XXXX "}
                                   value={carrierInsurance.agent.phone}
                                   onTextChange={(value) => {
                                       let temp = Helper.Masks.USPhone(value);
                                       setCarrierInsurance({
                                         ...carrierInsurance,
                                         agent: {...carrierInsurance.agent, phone: temp}
                                       })
                                   }} />

                 

                        <FormGroup name={'Agent_email_address'}
                                   label={'Agent email address'}
                                   required
                                   colSize={12}
                                   value={carrierInsurance.agent.email}
                                   className={'mt-3'}
                                   type={'email'}
                                   placeholder={'abc@xyz.com'}
                                   onTextChange={value => setCarrierInsurance({
                                     ...carrierInsurance,
                                     agent: {...carrierInsurance.agent, email: value}
                                   })}

                                
                        />
 <FormGroup required hintText={'Fax number must be 10 digitis'}
                                   errorText={'Phone number must be 10 digits'}
                                   validation={x => Helper.Masks.IntNumbers(x).length >= 10}
                                   colSize={12}
                                   name={'agentFax'}
                                   label={'Agent fax'}
                                   placeholder={"XXX-XXX-XXXX "}
                                   value={carrierInsurance.agent.fax}
                                   onTextChange={(value) => {
                                       let temp = Helper.Masks.USPhone(value);
                                       setCarrierInsurance({
                                         ...carrierInsurance,
                                         agent: {...carrierInsurance.agent, fax: temp}
                                       })
                                   }} />



<div className="col-12 bg-white main-radius ">
                        <input type='file' style={{display: 'none'}} ref={fileRef} onChange={evt => handleInputChange(evt)} />
                        <div className="col-12 d-flex justify-content-between align-items-center my-2">
                          <label className={`font-10`}>Files</label>
                          <span onClick={() => fileRef.current?.click()}
                                className={`btn btn-blue-light py-1`}>Upload</span>
                        </div>
                        <div className="col-12">
                          {carrierInsurance.uploaded_documents.length == 0 && <h4 className='text-red font-12 text-left cursor-pointer'
                                                                                   onClick={() => fileRef.current?.click()}>Upload a document showing your insurance</h4>}
                          {carrierInsurance.uploaded_documents.map((document, index) => <div style={{maxWidth: '100%'}} className={"document-row align-items-center" + (index == carrierInsurance.uploaded_documents.length - 1 ? '' : ' mb-3')}>
                            <h4 style={{textOverflow: 'clip', overflow: 'auto', whiteSpace: 'nowrap'}} className="font-12">{document.name}</h4>
                            <div className="d-flex ">
                              <h4 className="font-16 mr-2 cursor-pointer text-light-blue text-right" onClick={() => previewFile(document)}>
                                <i className='fas fa-eye'></i>
                              </h4>
                              <h4 className="font-16 cursor-pointer text-right" onClick={() => removeFile(document)}>
                                <i className='fas fa-times'></i>
                              </h4>
                            </div>
                          </div>)}
                        </div>
                      </div>
                    </div>
                </div>


                <div className="row mt-3 d-flex ">

                    <div className="col-6 text-right">
                        <button className="btn w-100 py-2 bg-white rounded-pill fs-12 font-medium text-light-blue"
                                onClick={onCancel}>CANCEL
                        </button>
                    </div>


                    <div className="col-6 text-right">
                        <button className="btn w-100 py-2 btn-blue-light rounded-pill fs-12 font-medium"
                                onClick={() => onHandleSubmit()}>
                            SAVE CHANGES
                        </button>
                    </div>

                </div>
            </div>

            {openModal && <DateTimePicker hideTime date={carrierInsurance.expiration}
                                onCancelClicked={() => setOpenModal(false)}
                                onDateSubmitted={(e) => {
                                    let temp = new Date(e);

                                    setCarrierInsurance({
                                        ...carrier_insurance,
                                        expiration: temp
                                    });
                                    setOpenModal(false);
                                }}
                />}
        </>
    )
}

export default InsuranceInfoForm
import bankAccountTypes from "../types/bank-account.type";
import {BankAccount} from "../../classes/classes";
import {GraphqlService} from "../../services/graphql.service";
import bankAccountQuery from "../../graphql/query/bank-account";

const fetchBankAccountRequest = () => {
    return {
        type: bankAccountTypes.FETCH_BANK_ACCOUNT_REQUEST
    }
}

const fetchBankAccountSuccess = (bankAccounts: BankAccount[]) => {
    return {
        type: bankAccountTypes.FETCH_BANK_ACCOUNT_SUCCESS,
        payload: bankAccounts
    }
}

const fetchBankAccountFailure = (error: string) => {
    return {
        type: bankAccountTypes.FETCH_BANK_ACCOUNT_FAILURE,
        payload: error
    }
}

export const fetchBankAccounts = () => {
    return async dispatch => {
        dispatch(fetchBankAccountRequest());
        GraphqlService.SendQuery(bankAccountQuery.GET_BANK_ACCOUNTS).then(bankAccounts => {
            dispatch(fetchBankAccountSuccess(bankAccounts));
        }).catch(error => {
            dispatch(fetchBankAccountFailure(error));
        })
    }
}
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import Helper from '../../helper/Helper';
import useLoading from '../../hooks/useLoading/useLoading';
import { GraphqlService } from '../../services/graphql.service';
import FormGroup from '../FormGroup/FormGroup';
import {gql} from 'apollo-boost';
import ICONS from '../../../assets/svg';

const SendInvoices = ({mastersID, defaultEmails, onClose}) => {

    const [emails, setEmails] = useState<string[]>([]);

    const loading = useLoading();
    const toast = useToasts();

    async function handleSendInvoice()
    {
        if (emails.length > 0 && emails.some(x => !Helper.Validators.IsValidEmail(x))) return;
        try {

            loading.open(`Sending invoice${mastersID.length == 1 ? '' : 's'}...`);
            const mutation = gql`
                mutation($master_order_ids: [Int], $emails: [String]){
                    send_master_order_invoices(master_order_ids: $master_order_ids, emails:$emails){
                        message
                        success
                    }
                }
            `;
            const data = await GraphqlService.SendMutation(mutation, {master_order_ids: mastersID, emails});
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            // //console.log(data);
            loading.close();
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            onClose(true);
            
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    return <div className='flex-1-container'>
        <div className="row mb-3">
                    <h4 className="font-16 font-medium">Send invoice{mastersID.length > 1 ? 's' : ''}</h4>
                    <h4 onClick={(evt) => onClose()} className="font-16 font-medium text-gray cursor-pointer"><i className='fas fa-times'></i></h4>
                </div>


                <h6 className="font-12 font-medium mb-3">MASTER{mastersID.length > 1 ? 'S' : ''}: {mastersID.map(x => `M-${x}`).join(', ')}</h6>

                <h4 className="font-12 font-medium">Default Email{defaultEmails.length > 1 ? 's' : ''}:</h4>
                <ul className='mb-3 pl-2'>
                    {defaultEmails.filter(x => x).map((email, index) => <li key={'default-email-' + index}>
                        <p className="font-11">• {email}</p>
                    </li>)}
                </ul>



                <div className="col-12 row align-items-center">
                        <h4 className='font-14'>Emails</h4>
                        <button className="btn btn-blue-light" onClick={(evt) => {
                            // const temp = [...mastersInfo];
                            // const emails = [...temp[masterModalIndex].emails, ''];
                            // temp[masterModalIndex].emails = emails;
                            // setMastersInfo(temp);
                            const temp = [...emails];
                            temp.push('');
                            setEmails(temp);
                        }}>ADD
                        </button>
                    </div>

                <div className="flex-1-container my-2">
                        <div>
                        {emails.map((email, i) =>
                                    <div style={{maxWidth: '100%', overflow: 'hidden'}} key={'email-' + i}
                                         className='d-flex align-items-start'>
                                        <FormGroup 
                                        placeholder={'Email ' + (i + 1)}
                                        required validation={x => Helper.Validators.IsValidEmail(x)}
                                                   hintText={'Email format must be abc@xyz.com'}
                                                   errorText={'Email format must be abc@xyz.com'}
                                                   label={`Email ${i + 1}`} colSize={12} name={`email-` + i}
                                                   value={email}
                                                   onTextChange={(evt) => {
                                                       const temp = [...emails];
                                                       temp[i] = evt;
                                                       setEmails(temp);
                                                   }}/>
                                        {/* <h4><i className='fa fa-times'></i></h4> */}
                                        <button tabIndex={-1} onClick={(evt) => {
                                            const temp = [...emails];
                                            temp.splice(i, 1);
                                            setEmails(temp);

                                        }} className='btn btn-clear'><img src={ICONS.IconDelete} alt=""/></button>
                                    </div>)}
                        </div>
                </div>

                <div className="row">
                    <button className="btn btn-danger-outline col-6 py-2" onClick={(evt) => onClose()}>CANCEL</button>
                    <button onClick={(evt) => handleSendInvoice()} disabled={emails.length > 0 && emails.some(x => !Helper.Validators.IsValidEmail(x))} className="btn btn-blue-outline col-6 py-2">SEND IT</button>
                </div>
    </div>
}

export default SendInvoices;
import {gql} from 'apollo-boost';
import { GraphqlService, ObservableQueryT } from '../../../services/graphql.service';

const query = gql`
    query($token:String){
            get_pending_inspection(token:$token){
            id
            pending_inspection_id
            signed_by{
            time
            name
            uid
            user_id
            }
            performed_by{
            time
            name
            uid
            user_id
            }
            time
            note
            flags{
            too_dirty
            too_dark
            raining
            }
            accessories{
                name
                count
                user_id
            }
            damages{
                point{
                    x
                    y
                }
                code
                uid
            }
            odometer
            photos {
                uid
                type
                user_id
            }
            vehicle_data{
                id
                vin 
                make
                type
                year
                color
                model
                no_vin
                submodel
                special_vin
                time_created
                internal_note
                license_plate
                incomplete_vin
                user_id
            }
        }
    }
`;

export default class InspectionObservable
{
    static GetObservable(token: string): ObservableQueryT {
        return GraphqlService.SendQueryObservable(query, {token});
    }
}
import {BankAccount} from "../../classes/classes";
import IReducerAction from "../models/IReducerAction";
import bankAccountTypes from "../types/bank-account.type";

export interface IBankAccountState {
    bankAccounts: BankAccount[];
    loading: boolean;
    error: string;
}

const initialState: IBankAccountState = {
    bankAccounts: [],
    loading: false,
    error: ''
};

export default function bankAccountReducer(state: IBankAccountState = initialState, action: IReducerAction): IBankAccountState {
    switch (action.type) {
        case bankAccountTypes.FETCH_BANK_ACCOUNT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case bankAccountTypes.FETCH_BANK_ACCOUNT_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                bankAccounts: action.payload
            };
        case bankAccountTypes.FETCH_BANK_ACCOUNT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
import {Address} from "../location/location";
import {Address_Input, Tax_Info_Input} from "../customer/customer";
import {Driver_Contact_Input} from "../driver/driver";
import { Company } from "../company/company";

export class Carrier {
    __typename?: "carrier"
    id?: number = null;
    name?: string = '';
    company?: Company;
    code?: string = '';
    contact?: CarrierContact = new CarrierContact();
    constructor(x?: Carrier ) {
        if(x) {
            this.id = x.id
            this.name = x.name
            this.company = x.company
            this.code = x.code
            this.contact = new CarrierContact(x.contact);
        }
    }
}

export class CarrierContact {
    __typename?: "CarrierContact"
    email?: string = '';
    name?: string = '';
    phone?: string = '';

    constructor(x?: CarrierContact){
        if(x) {
            this.email = x.email
            this.name = x.name
            this.phone = x.phone            
        }
    }
}

export class Velox_Carrier {
    __typename?: "Velox_carrier"
    id?: number = null;
    name?: string = '';
    code?: string = '';
    internal_note?: string = '';
    active?: boolean = false;
    multi_carrier?: boolean = false;
    contact?: CarrierContact = new CarrierContact();
    drivers?: Velox_Driver[] = [];
    carrier_insurance?: Velox_Carrier_Insurance = new Velox_Carrier_Insurance();
    carrier_registration?: Velox_Carrier_Registration = new Velox_Carrier_Registration();
    address?: Address = new Address();
    payment_term?: Payment_Term = new Payment_Term();

    constructor(x?: Velox_Carrier) {
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.code = x.code;
            this.internal_note = x.internal_note;
            this.active = x.active;
            this.multi_carrier = x.multi_carrier;
            this.contact = new CarrierContact(x.contact);
            this.drivers = x.drivers?.map(z => z) || [];
            this.carrier_insurance = new Velox_Carrier_Insurance(x.carrier_insurance)
            this.carrier_registration = new Velox_Carrier_Registration(x.carrier_registration);
            this.address = new Address(x.address);
            this.payment_term = new Payment_Term(x.payment_term);    
        }

    }
}

export class Payment_Term {
    __typename?: "Payment_term"
    carrier_id?: number = null;
    broker_id?: number = null;
    terms?: string = '';

    constructor(x?:Payment_Term) {
        if(x) {
            this.carrier_id = x.carrier_id
            this.broker_id = x.broker_id
            this.terms = x.terms
        }
    }
}

export class Velox_Carrier_Insurance {
    __typename?: "Velox_carrier_insurance"
    id?: number = null;
    name?: string = '';
    policy_number?: string = '';
    agent?: Velox_Insurance_Agent = new Velox_Insurance_Agent();
    liability_coverage?: number = 0;
    damage_coverage?: number = 0;
    deductible?: number = 0;
    expiration?: string = '';
    uploaded_documents?: Driver_Photo[] = [];
    carrier_id?: number = null;

    constructor(x?: Velox_Carrier_Insurance){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.policy_number = x.policy_number;
            this.agent = new Velox_Insurance_Agent(x.agent);
            this.liability_coverage = x.liability_coverage
            this.damage_coverage = x.damage_coverage
            this.deductible = x.deductible
            this.expiration = x.expiration
            this.uploaded_documents = x.uploaded_documents?.map(z => new Driver_Photo(z)) || [];
            this.carrier_id = x.carrier_id            
        }
    }
}

export class Velox_Insurance_Agent {
    __typename?: "Velox_insurance_agent"
    name?: string = '';
    email?: string = '';
    phone?: string = '';
    fax?: string = '';

    constructor(x?: Velox_Insurance_Agent) {
        if(x) {
            this.name = x.name;
            this.email = x.email;
            this.phone = x.phone;
            this.fax = x.fax;            
        }
    }
}

export class Velox_Carrier_Registration {
    __typename?: "Velox_carrier_registration"
    id?: number = null;
    mc_number?: string = '';
    dot_number?: string = '';
    uploaded_documents?: Driver_Photo[] = [];
    carrier_id?: number = null;

    constructor(x?: Velox_Carrier_Registration) {
        if(x) {
            this.id = x.id
            this.mc_number = x.mc_number
            this.dot_number = x.dot_number
            this.uploaded_documents = x.uploaded_documents?.map(z => new Driver_Photo(z)) || [];
            this.carrier_id = x.carrier_id
        }
    }
}

export class Velox_Driver {
    __typename?: "Velox_driver"
    id?: number = null;
    name?: string = '';
    username?: string = '';
    equipment?: string = '';
    carrier_code?: string = '';
    photos?: Driver_Photo[] = [];
    contacts?: Velox_Contact[] = [];
    carrier?:  Velox_Carrier = new Velox_Carrier();

    constructor(x?: Velox_Driver) {
        if(x) {
            this.id = x.id;
            this.name = x.name;
            this.username = x.username;
            this.equipment = x.equipment;
            this.carrier_code = x.carrier_code;
            this.photos = x.photos?.map(z => z) || [];
            this.contacts = x.contacts?.map(z => z) || [];
            this.carrier = new Velox_Carrier(x.carrier);          
        }
    }
}

export class Driver_Photo {
    __typename?: "Driver_photo"
    uid?: string = '';
    name?: string = '';

    constructor(x?: Driver_Photo) {
        if(x) {
            this.uid = x.uid;
            this.name = x.name;
        }
    }
}

export class Velox_Contact {
    __typename?: "Velox_contact"
    title?: string = '';
    type?: string = '';
    value?: string = '';

    constructor(x?: Velox_Contact){
        if(x){
            this.title = x.title;
            this.type = x.type;
            this.value = x.value;           
        }
    }
}

export class Aging_Carrier_Sp {
    __typename?: "Aging_carrier_sp"
    late?: number= 0;
    name?: string = '';
    picked_up?: number = 0;
    carrier_id?: number = null;
    orders_dispatched?: number = 0;
    drivers_dispatched?: number = 0;
    valid?: boolean = false; // Attention
    driver_id?: number;
    completed?: number;

    constructor(x?: Aging_Carrier_Sp ) {
        if(x) {
            this.late = x.late;
            this.name = x.name;
            this.picked_up = x.picked_up;
            this.carrier_id = x.carrier_id;
            this.orders_dispatched = x.orders_dispatched;
            this.drivers_dispatched = x.drivers_dispatched;
            this.valid = x.valid;
            this.driver_id = x.driver_id;
            this.completed = x.completed;            
        }
    }
}


export class Insurance_Agent {
    __typename?: "Insurance_agent"
    name?: string = '';
    email?: string = '';
    phone?: string = '';
    fax?: string = '';

    constructor(x?: Insurance_Agent){
        if(x) {
            this.name = x.name;
            this.email = x.email;
            this.phone = x.phone;
            this.fax = x.fax;           
        }
    }
}

export class Carrier_Insurance {
    __typename?: "Carrier_insurance"
    id?: number = null;
    name?: string = '';
    policy_number?: string = '';
    agent?: Insurance_Agent = new Insurance_Agent();
    liability_coverage?: number = 0;
    damage_coverage?: number = 0;
    deductible?: number = 0;
    expiration?: string = '';
    uploaded_documents?: Driver_Photo[] = [];
    carrier_id?: number = null;

    constructor(x?: Carrier_Insurance){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.policy_number = x.policy_number;
            this.agent = new Insurance_Agent(x.agent);
            this.liability_coverage = x.liability_coverage;
            this.damage_coverage = x.damage_coverage;
            this.deductible = x.deductible;
            this.expiration = x.expiration;
            this.uploaded_documents = x.uploaded_documents?.map(z => z) || [];
            this.carrier_id = x.carrier_id;            
        }
    }
}

export class Carrier_Registration {
    __typename?: "Carrier_registration"
    id?: number = null;
    mc_number?: string = '';
    dot_number?: string = '';
    uploaded_documents?: Driver_Photo[] = [];
    carrier_id?: number = null;

    constructor(x?: Carrier_Registration) {
        if(x){
            this.id = x.id;
            this.mc_number = x.mc_number;
            this.dot_number = x.dot_number;
            this.uploaded_documents = x.uploaded_documents?.map(z => z) || [];
            this.carrier_id = x.carrier_id;           
        }
    }
}

export class Carrier_Tax_Info {
    __typename?: "Carrier_tax_info"
    carrier_id?: number = null;
    id?: number = null;
    name?: string = '';
    business_name?: string = '';
    type?: string = '';
    address?: Address = new Address();
    ssn?: string = '';
    ein?: string = '';
    signature?: string = '';
    time_signed?: string = '';
    uploaded_documents?: Driver_Photo[] = [];

    constructor(x?: Carrier_Tax_Info){
        if(x) {
            this.carrier_id = x.carrier_id;
            this.id = x.id;
            this.name = x.name;
            this.business_name = x.business_name;
            this.type = x.type;
            this.address = new Address(x.address);
            this.ssn = x.ssn;
            this.ein = x.ein;
            this.signature = x.signature;
            this.time_signed = x.time_signed;
            this.uploaded_documents = x.uploaded_documents?.map(z => z) || [];           
        }
    }
}

export class Velox_Carrier_Tax_Info_Input {
    carrier_id?: number = null;
    name?: string = '';
    business_name?: string = '';
    type?: string = '';
    address?: Address_Input = new Address_Input();
    ssn?: string = '';
    ein?: string = '';
    signature?: string = '';
    time_signed?: string = '';

    constructor(x?: Velox_Carrier_Tax_Info_Input){
        if(x){
            this.carrier_id = x.carrier_id;
            this.name = x.name;
            this.business_name = x.business_name;
            this.type = x.type;
            this.address =  new Address(x.address);
            this.ssn = x.ssn;
            this.ein = x.ein;
            this.signature = x.signature;
            this.time_signed = x.time_signed;            
        }
    }


}

export class Carrier_Input {
    name?: string = '';
    is_multi_carrier?: boolean = false; // Attention
    code?: string = '';
    terms?: string = ''; 
    internal_note?: string = '';
    carrier_profile?: Velox_Carrier_Profile_Input = new Velox_Carrier_Profile_Input();
    address?: Address_Input = new Address_Input();
    tax_number?: Tax_Info_Input = new Tax_Info_Input();
    default_notification_email?: string = '';
    contact?: Carrier_Update_Contact = new Carrier_Update_Contact();

    constructor(x?: Carrier_Input){
        if(x){
            this.name = x.name;
            this.is_multi_carrier = x.is_multi_carrier;
            this.code = x.code;
            this.terms = x.terms;
            this.internal_note = x.internal_note;
            this.carrier_profile = new Velox_Carrier_Profile_Input(x.carrier_profile);
            this.address = new Address_Input(x.address);
            this.tax_number = new Tax_Info_Input(x.tax_number);
            this.default_notification_email = x.default_notification_email;
            this.contact = new Carrier_Update_Contact(x.contact);            
        }
    }
}

export class Velox_Carrier_Profile_Input {
    name?: string = '';
    email?: string = '';
    title?: string = '';
    contact?: Driver_Contact_Input[] = []

    constructor(x?: Velox_Carrier_Profile_Input) {
        if(x){
            this.name = x.name;
            this.email = x.email;
            this.title = x.title;
            this.contact = x.contact?.map(z => z) || [];            
        }
    }
}

export class Carrier_Report {
    __typename?: "carrier_report"
    title?: string = '';
    subtitle?: string = '';
    carrier?: Velox_Carrier = new Velox_Carrier();
    orders?: Carrier_Report_Order[] = []

    constructor(x?: Carrier_Report) {
        if(x) {
            this.title = x.title
            this.subtitle = x.subtitle
            this.carrier = new Velox_Carrier(x.carrier);
            this.orders = x.orders?.map(z => new Carrier_Report_Order(z)) || [];          
        }
    }
}

export class Carrier_Report_Order {
    __typename?: "carrier_report_order"
    velox_order_id?: number = null;
    origin?: string[] = [];
    destination?: string[] = [];
    price?: number = 0;
    remaining_carrier_receivable?: number = 0;

    constructor(x?: Carrier_Report_Order){
        if(x){
            this.velox_order_id = x.velox_order_id;
            this.origin = x.origin?.map(z => z) || [];
            this.destination = x.destination?.map(z => z) || [];
            this.price = x.price;
            this.remaining_carrier_receivable = x.remaining_carrier_receivable;
        }
    }
}

export class Velox_Carrier_Registration_Input {
    mc_number?: string= '';
    dot_number?: string = '';
    carrier_id?: number = null;

    constructor(x?: Velox_Carrier_Registration_Input){
        if(x){
            this.mc_number = x.mc_number
            this.dot_number = x.dot_number
            this.carrier_id = x.carrier_id
        }
    }
}

export class Velox_Carrier_Insurance_Input {
    name?: string = '';
    policy_number?: string = '';
    agent?: Agent_Insurance_Input = new Agent_Insurance_Input();
    liability_coverage?: number = 0;
    damage_coverage?: number = 0;
    deductible?: number = 0;
    expiration?: string = '';
    carrier_id?: number = null;

    constructor(x?: Velox_Carrier_Insurance_Input){
        if(x){
            this.name = x.name;
            this.policy_number = x.policy_number;
            this.agent = new Agent_Insurance_Input(x.agent);
            this.liability_coverage = x.liability_coverage;
            this.damage_coverage = x.damage_coverage;
            this.deductible = x.deductible;
            this.expiration = x.expiration;
            this.carrier_id = x.carrier_id;
        }
    }
}

export class Agent_Insurance_Input {
    name?: string = '';
    email?: string = '';
    phone?: string = '';
    fax?: string = '';

    constructor(x?: Agent_Insurance_Input){
        if(x){
            this.name = x.name;
            this.email = x.email;
            this.phone = x.phone;
            this.fax = x.fax;           
        }
    }
}

export class Carrier_Update_Contact {
    name?: string = '';
    email?: string = '';
    phone?: string = '';
    
    constructor(x?: Carrier_Update_Contact){
        if(x){
            this.name = x.name;
            this.email = x.email;
            this.phone = x.phone
        }
    }
}

export class Velox_Carrier_Update_Input {
    id: number = null;
    name?: string = '';
    multi_carrier?: boolean = false; // Attention
    code?: string = '';
    active?: boolean = false; // Attention
    contact?: Carrier_Update_Contact = new Carrier_Update_Contact();
    address?: Address_Input = new Address_Input();

    constructor(x?: Velox_Carrier_Update_Input){
        if(x){
            this.id = x.id
            this.name = x.name
            this.multi_carrier = x.multi_carrier
            this.code = x.code
            this.active = x.active
            this.contact = new Carrier_Update_Contact(x.contact)
            this.address = new Address_Input(x.address)           
        }
    }
}



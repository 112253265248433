import {useEffect, useRef, useState} from "react";
import './CustomerLocation.scss';
import ICONS from "../../../assets/svg";
import React from "react";
import Loading from "../Loading/Loading";
import LocationForm from "../NewOrder/LocationForm/LocationForm";
import {DriverLocation, Location} from "../../classes/classes";
import {GraphqlService} from "../../services/graphql.service";
import CustomerMutations from "../../graphql/mutation/Customer";
import {getCustomerLocations} from "../../redux/actions/Customer.action";
import {useToasts} from "react-toast-notifications";
import {useDispatch} from "react-redux";
import ReactModal from "react-modal";
import { gql } from "apollo-boost";
import InspectionObservable from "../../pages/PendingInspectionPage/observables/InspectionObservable";



const CustomerLocation = ({customerId = 0, customer, customerLocations, onLocationEditClick, isCustomerLocationsLoading}) => {
    const toast = useToasts();
    const dispatch = useDispatch();

    const [editLocation, setEditLocation] = useState<Location>(undefined);
    const [locations, setLocations] = useState([...customerLocations]);
    const [showLocationForm, setShowLocationForm] = useState(false);

    



    

    const toggleSelectedLocations = (id: any) => {
        setLocations(locations.map((location: any) => {
            if (location.id === id) {
                location.selected = !location.selected
            }
            return location
        }))
    }

    

    useEffect(() => {
        setLocations(customerLocations);
    }, [customerLocations]);

    async function handleSubmitLocation(location: Location) {
        setShowLocationForm(false);

        try {
            const l = {
                "customer_id": customerId == 0 ? customerLocations[0]?.customer?.id : customerId,
                "title": location?.title,
                "address": location?.address,
                "driver_note": location?.driver_note,
                "internal_note": location?.internal_note
            }

            const data = await GraphqlService.SendMutation(CustomerMutations.ADD_LOCATION_TO_CUSTOMER, {location: l});
            location.id = data.id;

            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            dispatch(getCustomerLocations(customerLocations?.customer?.id));
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error'});
        }
    }

    return (
        <>
            <div className="p-0" id="customer-locations-cnt">
                <section className="location-list">
                    {
                        isCustomerLocationsLoading ?
                            <div><Loading/></div> :
                            <>
                                <header
                                    className={`location-header d-flex justify-content-between mb-3 align-items-center fs-12`}>
                                    <h4>Locations ({locations.length})</h4>
                                    <span className={`d-flex align-items-center`}
                                          onClick={() => setEditLocation(new Location())}><img src={ICONS.IconPlus}
                                                                                       alt=""/> <small className="font-10 font-medium">NEW LOCATION</small></span>
                                </header>
                                {
                                    locations.map(location => {
                                        return (
                                            <section key={location?.id} className="row location-row"
                                                     onClick={() => toggleSelectedLocations(location.id)}>
                                                <article className={`col-12 location-item ${location.selected ? 'active' : ''}`}>
                                                    <header className={`location-title d-flex justify-content-between align-items-center mb-2`}>
                                                        <h5 className="fs-14">{location.title}</h5>
                                                        <small className={`d-flex align-items-center fs-10`}><img
                                                            src={ICONS.IconNotes} alt=""/>Notes</small>
                                                    </header>
                                                    <div className="location-info d-flex justify-content-between align-items-center no-wrap">
                                                        <p className="fs-12">{location.address.street_number}, {location.address.street}, {location.address.state} {location.address.postal_code}</p>
                                                        <button onClick={(ev) => {
                                                            ev.preventDefault();
                                                            ev.stopPropagation();

                                                            setEditLocation(location);
                                                        }}
                                                           className={`btn btn-edit-location `}>EDIT</button>
                                                    </div>
                                                </article>
                                            </section>
                                        );
                                    })
                                }
                            </>
                    }
                </section>
            </div>

            <ReactModal isOpen={editLocation ? true:false}
                        style={{content: {borderRadius: '12px', width: '496px', minWidth: '496px'}}}>
                <LocationForm customer={customer}
                              location={editLocation}
                              onCancel={() => setEditLocation(undefined)}
                              onSubmit={(location) => {
                                  setEditLocation(undefined);
                                  onLocationEditClick(location);
                              }}/>
            </ReactModal>
        </>
    );
};

export default CustomerLocation;

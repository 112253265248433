import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {ResultWithLink} from "../../customer_profile/customer_profile";
import {Vehicle_Input} from "../../vehicle/vehicle";
import {Accessories_Input, Flags_Input, Inspection, Point_Damage} from "../inspection";
import {Upload} from "../../upload";

export const upload_photo_fragment = (input:{photo_name: string, photo_fragment: string, fragment_number: number, total_fragments: number, data: string}) : Promise<string> => {
  let mutation = gql`
    mutation($photo_name: String, $photo_fragment: String, $fragment_number: Int, $total_fragments: Int, $data: String) {
      upload_photo_fragment(photo_name: $photo_name, photo_fragment: $photo_fragment, fragment_number: $fragment_number, total_fragments: $total_fragments, data: $data)
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {photo_name, photo_fragment, fragment_number, total_fragments, data} as 
  Promise<string>;
}

export const create_inspection_report_link = (input:{order_id: number}) : Promise<string> => {
  let mutation = gql`
    mutation($order_id: Int) {
      create_inspection_report_link(order_id: $order_id)
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {order_id} as 
  Promise<string>;
}

export const create_pending_inspection = (input:{vehicle_order_id: number, type: string, emails: string[], phones: string[], warehouse_id: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($vehicle_order_id: Int, $type: String, $emails: String[], $phones: String[], $warehouse_id: Int) {
      create_pending_inspection(vehicle_order_id: $vehicle_order_id, type: $type, emails: $emails, phones: $phones, warehouse_id: $warehouse_id) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {vehicle_order_id, type, emails, phones, warehouse_id} as 
  Promise<ResultWithLink>;
}

export const update_inspection_vehicle_data = (input:{vehicle_data: Vehicle_Input, pending_inspection_id: number}) : Promise<Inspection> => {
  let mutation = gql`
    mutation($vehicle_data: vehicle_input, $pending_inspection_id: Int) {
      update_inspection_vehicle_data(vehicle_data: $vehicle_data, pending_inspection_id: $pending_inspection_id) {
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {vehicle_data, pending_inspection_id} as 
  Promise<Inspection>;
}

export const upload_inspection_damage_photo = (input:{pending_inspection_id: number, code: string, point: Point_Damage, file: Upload}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($pending_inspection_id: Int, $code: String, $point: point_damage, $file: Upload) {
      upload_inspection_damage_photo(pending_inspection_id: $pending_inspection_id, code: $code, point: $point, file: $file) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {pending_inspection_id, code, point, file} as 
  Promise<ResultWithLink>;
}

export const delete_damage_pending_inspection = (input:{pending_inspection_id: number, uid: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($pending_inspection_id: Int, $uid: String) {
      delete_damage_pending_inspection(pending_inspection_id: $pending_inspection_id, uid: $uid) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {pending_inspection_id, uid} as 
  Promise<ResultWithLink>;
}

export const add_update_accessorie_pending_inspection = (input:{pending_inspection_id: number, accessorie: Accessories_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($pending_inspection_id: Int, $accessorie: Accessories_input) {
      add_update_accessorie_pending_inspection(pending_inspection_id: $pending_inspection_id, accessorie: $accessorie) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {pending_inspection_id, accessorie} as 
  Promise<ResultWithLink>;
}

export const remove_accesorie_pending_inspection = (input:{pending_inspection_id: number, name: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($pending_inspection_id: Int, $name: String) {
      remove_accesorie_pending_inspection(pending_inspection_id: $pending_inspection_id, name: $name) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {pending_inspection_id, name} as 
  Promise<ResultWithLink>;
}

export const update_flag_pending_inspection = (input:{pending_inspection_id: number, flags: Flags_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($pending_inspection_id: Int, $flags: Flags_input) {
      update_flag_pending_inspection(pending_inspection_id: $pending_inspection_id, flags: $flags) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {pending_inspection_id, flags} as 
  Promise<ResultWithLink>;
}

export const update_note_pending_inspection = (input:{pending_inspection_id: number, note: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($pending_inspection_id: Int, $note: String) {
      update_note_pending_inspection(pending_inspection_id: $pending_inspection_id, note: $note) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {pending_inspection_id, note} as 
  Promise<ResultWithLink>;
}

export const update_damage_pending_inspection = (input:{pending_inspection_id: number, uid: string, code: string, point: Point_Damage, file: Upload}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($pending_inspection_id: Int, $uid: String, $code: String, $point: point_damage, $file: Upload) {
      update_damage_pending_inspection(pending_inspection_id: $pending_inspection_id, uid: $uid, code: $code, point: $point, file: $file) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {pending_inspection_id, uid, code, point, file} as 
  Promise<ResultWithLink>;
}

export const upload_mandatory_photo_pending_inspection = (input:{pending_inspection_id: number, type: string, file: Upload}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($pending_inspection_id: Int, $type: String, $file: Upload) {
      upload_mandatory_photo_pending_inspection(pending_inspection_id: $pending_inspection_id, type: $type, file: $file) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {pending_inspection_id, type, file} as 
  Promise<ResultWithLink>;
}

export const delete_mandatory_photo_pending_inspection = (input:{pending_inspection_id: number, uid: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($pending_inspection_id: Int, $uid: String) {
      delete_mandatory_photo_pending_inspection(pending_inspection_id: $pending_inspection_id, uid: $uid) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {pending_inspection_id, uid} as 
  Promise<ResultWithLink>;
}

export const update_odometer_pending_inspection = (input:{pending_inspection_id: number, odometer: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($pending_inspection_id: Int, $odometer: Float) {
      update_odometer_pending_inspection(pending_inspection_id: $pending_inspection_id, odometer: $odometer) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {pending_inspection_id, odometer} as 
  Promise<ResultWithLink>;
}

export const upload_user_signature_pending_inspection = (input:{pending_inspection_id: number, name: string, file: Upload}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($pending_inspection_id: Int, $name: String, $file: Upload) {
      upload_user_signature_pending_inspection(pending_inspection_id: $pending_inspection_id, name: $name, file: $file) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {pending_inspection_id, name, file} as 
  Promise<ResultWithLink>;
}

export const upload_customer_signature = (input:{pending_inspection_id: number, name: string, file: Upload}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($pending_inspection_id: Int, $name: String, $file: Upload) {
      upload_customer_signature(pending_inspection_id: $pending_inspection_id, name: $name, file: $file) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {pending_inspection_id, name, file} as 
  Promise<ResultWithLink>;
}

export const finish_inspection = (input:{pending_inspection_id: number, gps: string, device_info: string, device_type: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($pending_inspection_id: Int, $gps: String, $device_info: String, $device_type: String) {
      finish_inspection(pending_inspection_id: $pending_inspection_id, gps: $gps, device_info: $device_info, device_type: $device_type) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {pending_inspection_id, gps, device_info, device_type} as 
  Promise<ResultWithLink>;
}

export const generate_inventory_inspection_link = (input:{inspection_id: number}) : Promise<string> => {
  let mutation = gql`
    mutation($inspection_id: Int) {
      generate_inventory_inspection_link(inspection_id: $inspection_id)
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {pending_inspection_id} as 
  Promise<string>;
}

export const resend_inventory_inspection = (input:{pending_inspection_id: number, emails: string[], phones: string[]}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($pending_inspection_id: Int, $emails: String[], $phones: String[]) {
      resend_inventory_inspection(pending_inspection_id: $pending_inspection_id, emails: $emails, phones: $phones) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {pending_inspection_id, emails, phones} as 
  Promise<ResultWithLink>;
}

export const log_inventory_order = (input:{warehouse_id: number, vehicle_order_id: number, incoming: boolean}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($warehouse_id: Int, $vehicle_order_id: Int, $incoming: Boolean) {
      log_inventory_order(warehouse_id: $warehouse_id, vehicle_order_id: $vehicle_order_id, incoming: $incoming) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {warehouse_id, vehicle_order_id, incoming} as 
  Promise<ResultWithLink>;
}

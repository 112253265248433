import { CustomerList, CustomerProfileList } from "../../classes/classes";
import CustomerQueries from "../../graphql/query/Customer";
import VehicleQueries from "../../graphql/query/Vehicle";
import { GraphqlService } from "../../services/graphql.service";
import VehicleTypes from "../types/Vehicle.type";


export function getAllMakes()
{
    return async dispatch => {
        dispatch(getAllMakesStarted());
        try {
            let data = await GraphqlService.SendQuery(VehicleQueries.GET_ALL_MAKES);
            dispatch(getAllMakesSuccess(data.map(x => ({Make_ID: x.Make_ID, Make_Name: x.Make_Name}))));
        } catch (ex) {
            dispatch(getAllMakesError(ex.message));
        }
    }
}


const getAllMakesStarted = () => ({
    type: VehicleTypes.GET_ALL_MAKES_STARTED,
    payload: true
});

const getAllMakesSuccess = (data) => ({
    type: VehicleTypes.GET_ALL_MAKES_SUCCESS,
    payload: data
});

const getAllMakesError = (error) => ({
    type: VehicleTypes.GET_ALL_MAKES_ERROR,
    payload: error
});


export function getAllModelsMake(make)
{
    return async dispatch => {
        dispatch(getAllModelsMakeStarted(make));
        try {
            let data = await GraphqlService.SendQuery(VehicleQueries.GET_MODELS_MAKE, {make});
            
            dispatch(getAllModelsMakeSuccess(data.map(x =>  ({Make_ID: x.Make_ID, 
                Make_Name: x.Make_Name,
                Model_ID: x.Model_ID,
                Model_Name: x.Model_Name}))));
        } catch (ex) {
            dispatch(getAllModelsMakeError(ex.message));
        }
    }
}


const getAllModelsMakeStarted = (make) => ({
    type: VehicleTypes.GET_MODELS_MAKE_STARTED,
    payload: make
});

const getAllModelsMakeSuccess = (data) => ({
    type: VehicleTypes.GET_MODELS_MAKE_SUCCESS,
    payload: data
});

const getAllModelsMakeError = (error) => ({
    type: VehicleTypes.GET_MODELS_MAKE_ERROR,
    payload: error
});

export function resetModels() {
    return dispatch => dispatch({type: VehicleTypes.RESET_MODELS_MAKE})
}




export function clearGetAllCustomersListError()
{
    return dispatch => dispatch(getAllMakesError(null));
}
import IReducerAction from "../models/IReducerAction";
import VehicleTypes from "../types/Vehicle.type";

export interface IVehicleState
{
    loadingMakes: boolean,
    makes: {Make_ID: number, Make_Name: string}[],
    errorMakes: string,
    loadingModels: boolean,
    models: {Make_ID: number, Make_Name: string, Model_ID: number, Model_Name: string}[],
    errorModels: string,
    make: string
}

const initialState: IVehicleState = {
    loadingMakes: false,
    makes: [],
    errorMakes: null,
    errorModels: null,
    loadingModels: false,
    models: [],
    make: null
}


export default function VehicleReducer(state: IVehicleState = initialState, action: IReducerAction): IVehicleState
{
    switch (action.type)
    {
        case VehicleTypes.GET_ALL_MAKES_STARTED:
            return {
                ...state,
                loadingMakes: true,
                errorMakes: null
            }

        case VehicleTypes.GET_ALL_MAKES_SUCCESS:
            return {
                ...state,
                loadingMakes: false,
                makes: action.payload,
            }
        case VehicleTypes.GET_ALL_MAKES_ERROR:
            return {
                ...state,
                loadingMakes: false,
                errorMakes: action.payload,
            }

        case VehicleTypes.GET_MODELS_MAKE_STARTED:
            return {
                ...state,
                loadingModels: true,
                errorMakes: null,
                make: action.payload
            }

        case VehicleTypes.GET_MODELS_MAKE_SUCCESS:
            return {
                ...state,
                loadingModels: false,
                models: action.payload,
            }
        case VehicleTypes.GET_MODELS_MAKE_ERROR:
            return {
                ...state,
                loadingModels: false,
                errorModels: action.payload,
            }
        case VehicleTypes.RESET_MODELS_MAKE:
            return {
                ...state,
                models: []
            }
        default:
            return state;
    }
}
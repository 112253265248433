import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {All_Makes_Res, Model_Make_Res, Vehicle} from "../vehicle";

export const get_all_vehicles = (input:{}) : Promise<Vehicle[]> => {
  let query = gql`
    query {
      get_all_vehicles {
        id
        vin
        year
        make
        model
        submodel
        type
        no_vin
        special_vin
        incomplete_vin
        color
        license_plate
        internal_note
        time_created
        user_id
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Vehicle[]>;
}

export const decode_vin = (input:{vin: string}) : Promise<Vehicle> => {
  let query = gql`
    query($vin: String!) {
      decode_vin(vin: $vin) {
        id
        vin
        year
        make
        model
        submodel
        type
        no_vin
        special_vin
        incomplete_vin
        color
        license_plate
        internal_note
        time_created
        user_id
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vin} as 
  Promise<Vehicle>;
}

export const find_vin = (input:{vin: string}) : Promise<Vehicle> => {
  let query = gql`
    query($vin: String!) {
      find_vin(vin: $vin) {
        id
        vin
        year
        make
        model
        submodel
        type
        no_vin
        special_vin
        incomplete_vin
        color
        license_plate
        internal_note
        time_created
        user_id
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vin} as 
  Promise<Vehicle>;
}

export const get_all_makes = (input:{}) : Promise<All_Makes_Res[]> => {
  let query = gql`
    query {
      get_all_makes {
        Make_ID
        Make_Name
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<All_Makes_Res[]>;
}

export const get_models_for_make = (input:{make: String}) : Promise<Model_Make_Res[]> => {
  let query = gql`
    query($make: String) {
      get_models_for_make(make: $make) {
        Make_ID
        Make_Name
        Model_ID
        Model_Name
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {make} as 
  Promise<Model_Make_Res[]>;
}

import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {ResultWithLink} from "../../customer_profile/customer_profile";
import {
  Address_New_Quote_Order, Batch_Master_References,
  Master_Order_Input,
  Quote_Order_Contacts,
  Quote_Stop_Input,
  Reference_Input
} from "../master_order";
import {StandardResult} from "../../leg/leg";

export const create_master_order = (input:{master_order: Master_Order_Input, separate: Boolean}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($master_order: master_order_input, $separate: Boolean) {
      create_master_order(master_order: $master_order, separate: $separate) {
        id
        link
        success
        message
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {master_order, separate} as 
  Promise<ResultWithLink>;
}

export const send_invoice = (input:{order_id: number, emails: string[]}) : Promise<boolean> => {
  let mutation = gql`
    mutation($order_id: Int, $emails: String[]) {
      send_invoice(order_id: $order_id, emails: $emails)
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {order_id, emails} as 
  Promise<boolean>;
}

export const create_master_order_from_quote_by_customer = (input:{quote_id: number, profile_id: number, contacts: Quote_Order_Contacts[], pickup: Quote_Stop_Input, dropoff: Quote_Stop_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($quote_id: Int, $profile_id: Int, $contacts: quote_order_contacts[], $pickup: quote_stop_input, $dropoff: quote_stop_input) {
      create_master_order_from_quote_by_customer(quote_id: $quote_id, profile_id: $profile_id, contacts: $contacts, pickup: $pickup, dropoff: $dropoff) {
        id
        link
        success
        message
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {quote_id, profile_id, contacts, pickup, dropoff} as 
  Promise<ResultWithLink>;
}

export const create_master_order_from_quote_by_user = (input:{quote_id: number, billing_address: Address_New_Quote_Order, contacts: Quote_Order_Contacts[], pickup: Quote_Stop_Input, dropoff: Quote_Stop_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($quote_id: Int, $billing_address: Address_new_quote_order, $contacts: quote_order_contacts[], $pickup: quote_stop_input, $dropoff: quote_stop_input) {
      create_master_order_from_quote_by_user(quote_id: $quote_id, billing_address: $billing_address, contacts: $contacts, pickup: $pickup, dropoff: $dropoff) {
        id
        link
        success
        message
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {quote_id, billing_address, contacts, pickup, dropoff} as 
  Promise<ResultWithLink>;
}

export const send_order_confirmation = (input:{order_id: number, emails: string[], phones: string[]}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($order_id: Int, $emails: String[], $phones: String[]) {
      send_order_confirmation(order_id: $order_id, emails: $emails, phones: $phones) {
        id
        link
        success
        message
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {order_id, emails, phones} as 
  Promise<ResultWithLink>;
}

export const update_master_order_references = (input:{order_id: number, references: Reference_Input[], customer_id: number, contacts: number[], card_id: number}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($order_id: Int, $references: reference_input[], $customer_id: Int, $contacts: Int[], $card_id: Int) {
      update_master_order_references(order_id: $order_id, references: $references, customer_id: $customer_id, contacts: $contacts, card_id: $card_id) {
        id
        success
        message
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {order_id, references, customer_id, contacts, card_id} as 
  Promise<StandardResult>;
}

export const update_master_order_references_batch = (input:{masters: Batch_Master_References[]}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($masters: batch_master_references[]) {
      update_master_order_references_batch(masters: $masters) {
        id
        success
        message
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {masters} as 
  Promise<StandardResult>;
}


export const cancel_master_order = (input:{master_order_id: number, note: string}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($master_order_id: Int!, $note:String!) {
      cancel_master_order(master_order_id: $master_order_id, note: $note) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {vehicle_order_id, vehicle_order} as 
  Promise<StandardResult>;
}
// import CarrierTypes from "../types/Carrier.action";

import {UserSettings} from "../../classes/classes";
import UserSettingsType from "../types/UserSettings.type";

export default function setUserSettings(userSettings: UserSettings)
{
    return dispatch => dispatch(setUserSettingsAction(userSettings));
}

const setUserSettingsAction = (userSettings) => ({type: UserSettingsType.SET_USER_SETTINGS, payload: userSettings});

import {ResultWithLink} from "../../customer_profile/customer_profile";
import {gql} from "apollo-boost";
import {Bank_Account_Input, Service_Payment_Input, Transportation_Payments_Input} from "../payment";
import {inputAttribute} from "../../attribute/attribute";
import {GraphqlService} from "../../../../graphql.service";
import {Upload} from "../../upload";

export const pay_invoice = (input:{master_order_id: number, service_payments: Service_Payment_Input[], payment: inputAttribute, transportation_payments: Transportation_Payments_Input[]}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($master_order_id: Int, $service_payments: service_payment_input[], $payment: inputAttribute, $transportation_payments: transportation_payments_input[]) {
      pay_invoice(master_order_id: $master_order_id, service_payments: $service_payments, payment: $payment, transportation_payments: $transportation_payments) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {master_order_id, service_payments, payment, transportation_payments} as 
  Promise<ResultWithLink>;
}

export const post_manual_payment = (input:{master_order_ids: number[], total: number, payment: number, type: string, bank_account_id: number, previous_payment_id: number, note: string, time_paid: string, reference: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($master_order_ids: Int[], $total: Float, $payment: Int, $type: String, $bank_account_id: Int, $previous_payment_id: Int, $note: String, $time_paid: String, $reference: String) {
      post_manual_payment(master_order_ids: $master_order_ids, total: $total, payment: $payment, type: $type, bank_account_id: $bank_account_id, previous_payment_id: $previous_payment_id, note: $note, time_paid: $time_paid, reference: $reference) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {master_order_ids, total, payment, type, bank_account_id, previous_payment_id, note, time_paid, reference} as 
  Promise<ResultWithLink>;
}

export const send_payment_confirmation = (input:{payment_id: number, emails: string[]}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($payment_id: Int, $emails: String[]) {
      send_payment_confirmation(payment_id: $payment_id, emails: $emails) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {payment_id, emails} as 
  Promise<ResultWithLink>;
}

export const post_manual_single_payment = (input:{master_order_id: number, service_payments: Service_Payment_Input[], type: string, used_payment_id: number, payment: number, transportation_payments: Transportation_Payments_Input[], bank_account_id: number, previous_payment_id: number, note: string, time_paid: string, reference: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($master_order_id: Int, $service_payments: service_payment_input[], $type: String, $used_payment_id: Int, $payment: Int, $transportation_payments: transportation_payments_input[], $bank_account_id: Int, $previous_payment_id: Int, $note: String, $time_paid: String, $reference: String) {
      post_manual_single_payment(master_order_id: $master_order_id, service_payments: $service_payments, type: $type, used_payment_id: $used_payment_id, payment: $payment, transportation_payments: $transportation_payments, bank_account_id: $bank_account_id, previous_payment_id: $previous_payment_id, note: $note, time_paid: $time_paid, reference: $reference) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {master_order_id, service_payments, type, used_payment_id, payment, transportation_payments, bank_account_id, previous_payment_id, note, time_paid, reference} as 
  Promise<ResultWithLink>;
}

export const upload_payment_file = (input:{payment_id: number, file: Upload}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($payment_id: Int, $file: Upload) {
      upload_payment_file(payment_id: $payment_id, file: $file) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {payment_id, file} as 
  Promise<ResultWithLink>;
}

export const update_bank_account = (input:{bank_account_id: number, bank_account_info: Bank_Account_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($bank_account_id: Int, $bank_account_info: Upload) {
      update_bank_account(bank_account_id: $bank_account_id, bank_account_info: $bank_account_info) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {bank_account_id, bank_account_info} as 
  Promise<ResultWithLink>;
}

export const create_bank_account = (input:{bank_account: Bank_Account_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($bank_account: bank_account_input) {
      create_bank_account(bank_account: $bank_account) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {bank_account} as 
  Promise<ResultWithLink>;
}

export const post_online_payment = (input:{data: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($data: string) {
      post_online_payment(data: $data) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {data} as 
  Promise<ResultWithLink>;
}

export const void_payment = (input:{payment_id: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($payment_id: Int) {
      void_payment(payment_id: $payment_id) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {payment_id} as 
  Promise<ResultWithLink>;
}
import { gql } from 'apollo-boost';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import ICONS from '../../../assets/svg';
import { Warehouse, Address } from '../../classes/classes';
import Helper from '../../helper/Helper';
import columns from '../../pages/InventoryPage/TableColumns';
import { GraphqlService } from '../../services/graphql.service';
import FormGroup from '../FormGroup/FormGroup';
import Searchbar from '../Searchbar/Searchbar';
import IColumn from '../Table/models/IColumn';
import Table from '../Table/Table';

enum OrderStatutes {
    OPEN,
    COMPLETED
}

const ordersQuery = gql`
    query($vehicle_search:String, $order_search:String,$warehouseID:Int ){
  get_all_vehicle_orders(vehicle_search:$vehicle_search, order_search:$order_search, warehouseID:$warehouseID){
    id
    time
    started
    non_move
    legs {
        equipment
        stops {
            is_pickup
            time_scheduled
            location {
                timezoneName
                address {
                    
                    street
                    street_number
                    postal_code
                    state
                    country
                    city
                }
            }
        }
    }
    charges {
        amount
        amount_remaining
    }
    vehicle{
      vin
      year
      make
      model
      color
    }
    delivered
    bol_comment
    time_changed
    warehouse{
      name
    }
    master_order{
      id
      time
      price
      terms
      started
      non_move
      canceled
      completed
      delivered
      payment_due
      payment_left
      customer{
        name
        key
        terms
        active
        internal_note
        billing_address{
          city
          state
          street
          country
          postal_code
          street_number
          postal_code_suffix
        }
        corporate_client

      }
      references{
        key
        value
      }
    }
  }
}
`;

const customerOrdersQuery = gql`
    query($customer_id: Int, $vehicle_search:String, $order_search:String,$warehouseID:Int ){
    get_all_vehicle_orders_by_customer(customer_id: $customer_id, vehicle_search:$vehicle_search, order_search:$order_search, warehouseID:$warehouseID){
    id
    time
    started
    non_move
    legs {
        equipment
        stops {
            is_pickup
            time_scheduled
            location {
                timezoneName
                address {
                    
                    street
                    street_number
                    postal_code
                    state
                    country
                    city
                }
            }
        }
    }
    charges {
        amount
        amount_remaining
    }
    vehicle{
      vin
      year
      make
      model
      color
    }
    delivered
    bol_comment
    time_changed
    warehouse{
      name
    }
    master_order{
      id
      time
      price
      terms
      started
      non_move
      canceled
      completed
      delivered
      payment_due
      payment_left
      contacts {
          id
          name
          email
      }
      customer{
        name
        key
        terms
        active
        internal_note
        billing_address{
          city
          state
          street
          country
          postal_code
          street_number
          postal_code_suffix
        }
        corporate_client

      }
      references{
        key
        value
      }
    }
  }
}
`;

const OrdersTable = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [orderStatus, setOrderStatus] = useState(OrderStatutes.OPEN);
    

    const ref = useRef();
    const ref1 = useRef();
    const ref2 = useRef();


    const [vehicleSearch, setVehicleSearch] = useState('');
    const [orderSearch, setOrderSearch] = useState('');

    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);

    const [warehouseID, setWarehouseID] = useState<number>(-1);
    const [searchTerm, setSearchTerm] = useState('');
    const columns: IColumn[] = [
        {
            label: 'ORDER #',
            active: false,
            orderBy: 'DESC',
            name: 'number'
        },
        {
            label: 'STATUS',
            active: false,
            orderBy: 'DESC',
            name: 'status'
        },
        {
            label: props.customer_id ? 'CONTACTS' : 'CUSTOMER',
            active: false,
            orderBy: 'DESC',
            name: 'customer_column',
            sort_by: 'customer'
        },
        {
            label: 'VEHICLE',
            active: false,
            orderBy: 'DESC',
            name: 'vehicle'
        },
        {
            label: 'PICKUP',
            active: false,
            orderBy: 'DESC',
            name: 'pickup'
        },
        {
            label: 'DROPOFF',
            active: false,
            orderBy: 'DESC',
            name: 'dropoff'
        },

        {
            label: 'CHARGES',
            active: false,
            orderBy: 'DESC',
            name: 'charges'
        }
        ];


    const moves = ['ALL MOVES', 'NON MOVE'];

    const [move, setMove] = useState('ALL MOVES');
    const statusesOptions = [
        {
            label: 'Open',
            value: OrderStatutes.OPEN
        },
        {
            label: 'Completed',
            value: OrderStatutes.COMPLETED
        }
    ]

    async function loadWarehouses()
    {
        const query  = gql`
            query{
                get_warehouses{
                    id
                    name
                    
                    }
                }

        `;
        try {
            const data = await GraphqlService.SendQuery(query);
            setWarehouses(data);
        } catch (ex) {
            console.log('ex', ex.message)
        }
    }

    async function loadOrders()
    {
        setIsLoading(true);
        try {
            console.log(warehouseID);
            let variables: any = {vehicle_search: vehicleSearch, order_search: orderSearch, warehouseID};
            if (props.customer_id)
                variables.customer_id = props.customer_id;
            const data = await GraphqlService.SendQuery(props.customer_id ? customerOrdersQuery :  ordersQuery, variables);
            // console.log(data.filter(x => x.non_move));
            setOrders(data.map(order => {

                const stops = [...((order.legs || [])[0]?.stops || [])].filter(Boolean);

                const equipments = ((order.legs || [])[0]?.equipment || '').split(', ').filter(x => x).groupBy(x => x);
                
                if (equipments.length == 0)
                    equipments.push(['-'])

                
                // console.log(stops);
                
                const firstStop = stops.filter(x => x.is_pickup).sort((x, y) => new Date(x.time_scheduled) > new Date(y.time_scheduled) ? 1 : -1)[0];
                const lastStop = stops.filter(x => !x.is_pickup).sort((x, y) => new Date(x.time_scheduled) < new Date(y.time_scheduled) ? 1 : -1)[0];

                const pickupAddress = new Address(firstStop?.location?.address);
                const dropoffAddress = new Address(lastStop?.location?.address);

                const charges = order.charges.filter(Boolean);

                const status = order.canceled ? 'CANCELLED'
                : (order.delivered ? 'DELIVERED' : 
                ( order.started ? 'STARTED' : (
                    order.non_move ? 'NON MOVE' : 'NOT STARTED'
                )));

                const customer_column = !props.customer_id ? [order.master_order.customer.name, order.master_order.customer.corporate_client ? 'Corporate' : 'Private'] : 
                [   
                    order.master_order.contacts?.[0]?.name || '-',
                    order.master_order.contacts?.[0]?.email,
                    order.master_order.contacts?.length > 1 ? `+${order.master_order.contacts?.length} more`  : ''
                ];

                const customer = (!props.customer_id ? order.master_order.customer.name : order.master_order.contacts?.[0]?.name || '-')?.trim();

                
                return {
                    customer_column: customer_column,
                    customer,
                    vehicle: [order.vehicle.vin || '-', order.vehicle.year + ' ' + order.vehicle.make, order.vehicle.model],
                    status: [status, ...equipments.map(x => x[0] + (x.length > 1 ? ` (x${x.length - 1})` : ''))],
                    time: [Helper.FORMAT.USDate(order.time, 'do'), Helper.FORMAT.USDate(order.time, 'to')],
                    number: [`VO-${order.id}`, `M-${order.master_order.id}`],
                    search_criteria: [order.vehicle.vin?.toLowerCase(), order.vehicle.year.toLowerCase(), order.vehicle.make.toLowerCase(), order.vehicle.model.toLowerCase(), order.master_order.customer.name.toLowerCase(), order.master_order.customer.corporate_client ? 'corporate' : 'client', Helper.FORMAT.USDate(order.time, 'do'), Helper.FORMAT.USDate(order.time, 'to'),
                                    `vo-${order.id}`, `m-${order.master_order.id}`, 'tbd'],
                    non_move: order.non_move,
                    pickup: !firstStop ?  ['-'] : [pickupAddress.getName(1), pickupAddress.getName(2), Helper.FORMAT.USDate(firstStop.time_scheduled, 'do', firstStop?.location?.timezoneName), Helper.FORMAT.USDate(firstStop.time_scheduled, 'to', firstStop?.location?.timezoneName)],
                    dropoff: !lastStop ?  ['-'] : [dropoffAddress.getName(1), dropoffAddress.getName(2), Helper.FORMAT.USDate(lastStop.time_scheduled, 'do', lastStop?.location.timezoneName), Helper.FORMAT.USDate(lastStop.time_scheduled, 'to', lastStop?.location?.timezoneName)],
                    equipment: ['-'],
                    charges: [Helper.FORMAT.USCurrency(charges.sumBy(x => x.amount)), 'Remaining Balance', Helper.FORMAT.USCurrency(charges.sumBy(x => x.amount_remaing))],
                    master_id: order.master_order.id
                }
            }))
        } catch (ex) {
            console.log('ex', ex.message)
        }
        finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        loadWarehouses().then();
    }, []);

    async function changeWarehouseSelection(id: number){
        if(warehouseID != id){
            setWarehouseID(id);
        }
    }

    useEffect(() => {
        if(warehouseID != -1){
            loadOrders().then();
        }
    }, [warehouseID]);

    const [orders, setOrders] = useState([]);

    const ordersfiltered = useCallback(() => {
        
        if (move == 'ALL MOVES')
            return orders;

        return orders.filter(x => x.non_move);
        
    }, [move, orders]);

    return <div className='flex-1-container'>
        <div className="row">
            <div className="col-12 d-flex align-items-center">
                <h4 className="fs-12">{ordersfiltered().length} / {orders.length} Orders</h4>
                {/* <button className="btn btn-outline mx-2">THIS WEEK <i className="fas fa-chevron-down"></i></button> */}

            </div>


        </div>

        <div className="col-12 row align-items-center d-flex my-3">
            {/*{JSON.stringify(statusesOptions)}*/}
            {/*<div className="col-6">*/}
           
            <div className="d-flex align-items-center justify-content-between col-12 ">
                

                <div className="col-12 row">
                    <div className="col-2">
                        <FormGroup value={move} onTextChange={(evt) => setMove(evt)} type={'select'} name={'move'} options={{data: moves, label: x=>x, value: x=>x}} />
                    </div>
                    <div className="col-10 row">
                    <div className="col-4">
                        <Searchbar onKeyEnter={(evt) => loadOrders()} placeholder={`${props.customer_id ? 'Contact' : 'Customer'} Name / Master Order #`} value={orderSearch} onChange={(evt) => setOrderSearch(evt.target.value)} background={'#F8F8F8'} reference={ref} />
                    </div>
                    <div className="col-4">
                        <Searchbar onKeyEnter={(evt) => loadOrders()} placeholder={'Make or Model or VIN'} value={vehicleSearch} onChange={(evt) => setVehicleSearch(evt.target.value)} background={'#F8F8F8'} reference={ref1} />
                    </div>
                    

                    <FormGroup options={{data: warehouses, label: x=> x.name, value: x=> x.id}} className='m-0' name={'warehouse'} type={'select'} colSize={4} placeholder={'-- Select a Warehouse --'} value={warehouseID?.toString()} onTextChange={(evt) => changeWarehouseSelection(evt? +evt:null)} />
                    </div>

                </div>
                {/* <button className='btn btn-icon-only ml-2' onClick={(evt) => false}><img className={isLoading ? 'spin' : ''} src={ICONS.IconRefresh} alt=""/></button> */}
                <button onClick={(evt) => loadOrders()} className="btn btn-blue-light py-1 px-3 mb-3 font-12 ml-2 rounded-pill">Search</button>
            </div>

        </div>

        <Table menuOptions={[{
            label: 'See Order Details',
            icon: ICONS.IconDetails,
            action: (x: any) => {
                Helper.Navigation.NavigateTo('/order/' + x.master_id);
            }
        }]} loading={isLoading} rowHeight={80} columns={columns} rows={ordersfiltered()} onRowClicked={(evt) => {}} />
    </div>
}



export default OrdersTable;
import IReducerAction from "../models/IReducerAction";
import driverTypes from "../types/DriverSetting.type";

export interface IDriverSettingState {
    response: any;
    errorForm: string;
    loadingForm: boolean;
}

const initialState: IDriverSettingState = {
    errorForm: '',
    loadingForm: false,
    response: undefined,
}

const driverSettingReducer = (state = initialState, action: IReducerAction) => {
    switch (action.type) {
        case driverTypes.UPDATE_DRIVER_REQUEST:
            return { ...state, errorForm: '', loadingForm: true }
        case driverTypes.UPDATE_DRIVER_SUCCESS:
            return { ...state, errorForm: '', response: action.payload, loadingForm: false }
        case driverTypes.REMOVE_EQUIPMENT_SUCCESS:
            return { ...state, errorForm: '', equipmentDeleted: action.payload, loadingForm: false }
        case driverTypes.UPDATE_DRIVER_FAILURE:
            return { ...state, errorForm: action.payload, loadingForm: false }
        case driverTypes.INIT_DRIVER_FORM:
            return initialState;
        default:
            return state;
    }
}

export default driverSettingReducer;

import './ProfileSelectField.scss';
import {useEffect, useRef, useState} from "react";
import Modal from "../../../../components/Modal/Modal";
import EmployeeForm from "../EmployeeForm/EmployeeForm";
import {Driver, Employee, User} from "../../../../classes/classes";
import VeloxProfileForm, {VeloxProfile} from "../VeloxProfileForm/VeloxProfileForm";
import {getDrivers, getVeloxDrivers} from "../../../../redux/actions/Driver.action";
import {useDispatch, useSelector} from "react-redux";
import {AllStates} from "../../../../redux/reducers";
import {IDriverState} from "../../../../redux/reducers/Driver.reducer";
import {fetchEmployees} from "../../../../redux/actions/employee.action";
import {IEmployeeState} from "../../../../redux/reducers/employee.reducer";
import ICONS from '../../../../../assets/svg';
import useLoading from "../../../../hooks/useLoading/useLoading";
import {useToasts} from "react-toast-notifications";
import {GraphqlService} from "../../../../services/graphql.service";
import VeloxProfileMutations from '../../../../graphql/mutation/VeloxProfile';
import ReactModal from 'react-modal';
import EmployeeMutations from "../../../../graphql/mutation/Employee";
import DriverSettingForm from '../../../../components/DriverSettingForm/DriverForm';
import environments from '../../../../../environments/environments';
import { gql } from 'apollo-boost';
import EquipmentForm from '../EquipmentForm/EquipmentForm';

const queryEquipments = gql`
    query{
  get_all_equipments{
    id
    type
    year
    make
    model
    vin
    registration_expiration
    insurance_expiration
    photos {
        name
        uid
    }
  }
}
`;
const query = gql`
    query{
    trucks_info{
    id
    vin
    serial
    make
    model
    year
    licensePlate
    tags{
      id
      name
    }
    staticAssignedDriver{
      id
      name
    }
  }
}
`;

class IProfileSelectField {
    showListUp = false;
    type: 'velox' | 'employee' | 'samsara' | 'equipment' = "velox";
    onProfileSelected: (profile: any) => void;
    data2: any
}

class ProfileList {
    id: string;
    type: string;
    name: string;

    constructor(id, type, name) {
        this.id = id;
        this.type = type;
        this.name = name;
    }
}

const ProfileSelectField = ({showListUp, type, onProfileSelected, data2}: IProfileSelectField) => {
    const ref = useRef<HTMLDivElement>();

    const dispatch = useDispatch();

    const {veloxDrivers, errorList} = useSelector<AllStates>(state => state.drivers) as IDriverState

    const [profile, setProfile] = useState<ProfileList>(data2);
    const [openProfileList, setOpenProfileList] = useState<boolean>(false);
    const [data, setData] = useState<any>()
    const [showForm, setShowForm] = useState<boolean>(false);
    const [veloxList, setVeloxList] = useState<Driver[]>([]);
    const [employeeList, setEmployeeList] = useState<Employee[]>([]);
    const [samsaraList, setSamsaraList] = useState([]);
    const [equipmentList, setEquipmentList] = useState([]);
    const {employees, error} = useSelector<AllStates>(state => state.employees) as IEmployeeState
    const [openModal, setOpenModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [editType, setEditType] = useState('')
    const loading = useLoading();
    const toast = useToasts();

    const autoSelectVeloxID = useRef<number>(null);


    async function loadSamsaraTrucks() {
        try {
            let data = await GraphqlService.SendQuery(query);
            data = data.map(x => ({...x, name:  x.staticAssignedDriver?.name + ' - ' + x.licensePlate}));
            setSamsaraList(data);
            // console.log()
            const truck = data.find(x => x.id == data2?.id);
            console.log(type);
            console.log(truck)
            if (type == 'samsara')
            {
                setProfile(truck);
                // onProfileSelected({
                //     profile: profile,
                //     veloxProfile: null,
                //     employeeProfile: null,
                //     samsaraProifle: truck
                // });
            }
            console.log(data);
        } catch (ex) {
            console.log(ex.message);
        }
    }

    async function loadEquipments() {
        try {
            let data = await GraphqlService.SendQuery(queryEquipments);
            data = data.map(x => ({...x, name:  `${x.type} - ${x.year} ${x.make} ${x.model}`}));
            setEquipmentList(data);
            // console.log()
            const equipment = data.find(x => x.id == data2?.id);
            console.log(type);
            // console.log(truck)
            if (type == 'equipment' && equipment)
            {
                setProfile(equipment);
               
            }
            console.log(data);
        } catch (ex) {
            console.log(ex.message);
        }
    }

    useEffect(() => {
        if (type == 'velox')
            dispatch(getVeloxDrivers());
        if (type == 'employee')
            dispatch(fetchEmployees());
        if (type == 'samsara')
            loadSamsaraTrucks();
        if (type == 'equipment')
            loadEquipments();
        // console.log(data2)
    }, []);

    const autoClose = useRef(true);

    useEffect(() => {
        setVeloxList([...veloxDrivers]);
        console.log(veloxDrivers);
        if (autoSelectVeloxID.current)
        {
            console.log(autoSelectVeloxID.current);
            
            const driver = veloxDrivers.find(x => x.id == autoSelectVeloxID.current);
            console.log(driver);
            if (driver)
            {
                
                const profile = new ProfileList(driver.id, 'velox', driver.name);
                setProfile(profile);

                console.log({
                    profile: profile,
                    veloxProfile: new VeloxProfile(veloxDrivers.find(e => +e.id === +profile.id)),
                    employeeProfile: null
                });

                onProfileSelected({
                    profile: profile,
                    veloxProfile: new VeloxProfile(veloxDrivers.find(e => +e.id === +profile.id)),
                    employeeProfile: null
                });
            }
            setOpenProfileList(false);
            console.warn('autoClose', autoClose.current)
            setShowForm(!autoClose.current);
            autoClose.current = true;
        }
        autoSelectVeloxID.current = null;
    }, [veloxDrivers]);

    useEffect(() => {
        setEmployeeList([...employees]);
    }, [employees]);

    const getVeloxList = () => {
        let profile = veloxList.map(velox => {
            return new ProfileList(velox.id, type, velox.name);
        });

        return profile.sort((x, y) => x.name > y.name ? 1 : -1).map((profile, index) => {
            return (
                <div className="d-flex aling-item-center justify-content-between">
                    <p key={index}
                       className="font-12 font-medium profile-item w-100"
                       onClick={() => {
                           setProfile(profile);
                           setOpenProfileList(false);

                           onProfileSelected({
                               profile: profile,
                               veloxProfile: new VeloxProfile(veloxList.find(e => +e.id === +profile.id)),
                               employeeProfile: null
                           });
                       }}>{profile.name}</p>

                    <img onClick={() => {
                        setEdit(true);
                        setEditType('VELOX');
                        setShowForm(!showForm);
                        setData(veloxList.find(e => +e.id === +profile.id))
                    }} src={ICONS.IconPencil} className="cursor-pointer mr-3" alt=""/>

                </div>
            );
        })
    }

    const getEmployeeList = () => {
        let profile = employeeList.map(employee => {
            return new ProfileList(employee.id, type, employee.name);
        });

        return profile.sort((x, y) => x.name > y.name ? 1 : -1).map((profile, index) => {
            return (
                <div className="d-flex align-item-center justify-content-between">
                    <p key={index}
                       className="font-12 font-medium profile-item w-100"
                       onClick={() => {
                           setProfile(profile);
                           setOpenProfileList(false);

                           onProfileSelected({
                               profile: profile,
                               veloxProfile: null,
                               employeeProfile: employeeList.find(e => +e.id === +profile.id)
                           });
                       }}>{profile.name}</p>
                    <img onClick={() => {
                        setEdit(true);
                        setEditType('EMPLOYEE');
                        setShowForm(!showForm);
                        setData(employeeList.find(e => +e.id === +profile.id))
                    }} src={ICONS.IconPencil} className="cursor-pointer mr-3" alt=""/>
                </div>


            );
        });
    }

    const getSamsaraList = () => {
        let profile = [...samsaraList]

        return profile.sort((x, y) => x.name > y.name ? 1 : -1).map((profile, index) => {
            return (
                <div className="d-flex align-item-center justify-content-between">
                    <p key={index}
                       className="font-12 font-medium profile-item w-100"
                       onClick={() => {
                           setProfile(profile);
                           setOpenProfileList(false);

                           onProfileSelected({
                               profile: profile,
                               veloxProfile: null,
                               employeeProfile: null,
                               samsaraProfile: profile
                           });
                       }}><span style={{display: 'inline-block', width: '154px'}}>{profile.name.split('-')[0]}</span> License Plate: {profile.name.split('-')[1]}</p>
                       
                </div>


            );
        });
    }


    const getEquipmentList = () => {
        let profile = [...equipmentList]
        console.log(equipmentList)

        return profile.sort((x, y) => x.name > y.name ? 1 : -1).map((profile, index) => {
            return (
                <div className="d-flex align-item-center justify-content-between">
                    <p key={index}
                       className="font-12 font-medium profile-item w-100"
                       onClick={() => {
                           setProfile(profile);
                           setOpenProfileList(false);

                           onProfileSelected({
                               profile: profile,
                               veloxProfile: null,
                               employeeProfile: null,
                               samsaraProfile: null,
                               equipment: profile
                           });
                       }}>{profile.name}</p>
                          <img onClick={() => {
                        setEdit(true);
                        setEditType('EQUIPMENT');
                        setShowForm(!showForm);
                        console.log(equipmentList.find(e => +e.id === +profile.id));
                        console.log(equipmentList, profile.id);
                        setData(equipmentList.find(e => +e.id === +profile.id))
                    }} src={ICONS.IconPencil} className="cursor-pointer mr-3" alt=""/>
                </div>


            );
        });
    }


    useEffect(() => {
        let handleClickOut = (event: any) => {
            if (openProfileList) {
                if (!ref.current.contains(event.target)) {
                    setOpenProfileList(false);
                }
            }
        }
        document.addEventListener("mousedown", handleClickOut)

        return () => {
            document.removeEventListener("mousedown", handleClickOut)
        }
    },)


    async function handleSubmit(item) {
        setShowForm(false);
        setOpenProfileList(true);

        if (item.id) {
            try {
                loading.open('Updating driver...');
                console.log(item);
                const data = await GraphqlService.SendMutation(VeloxProfileMutations.UPDATE_VELOX_PROFILE, {
                    driver: {
                        id: item.id,
                        name: item.name,
                        email: item.email,
                        equipment: item.equipment,
                        phones: item.phones
                    }
                });

                loading.close();

                if (!data.success)
                    throw new Error(data.message);

                toast.addToast(data.message, {appearance: 'success', autoDismiss: true});


                const temp = [...veloxList];

                const index = temp.findIndex(x => x.id == item.id);

                temp[index] = item;

                let profile = new ProfileList(item.id, type, item.name);

                setProfile(profile)
                setVeloxList([...temp]);

                onProfileSelected({
                    profile: profile,
                    veloxProfile: item,
                    employeeProfile: null
                });

                setOpenProfileList(false);
                setOpenModal(false)

            } catch (ex) {
                toast.addToast(ex.message, {appearance: 'error'});
                loading.close()
            }


        } else {
            try {
                loading.open('Submitting driver...');
                const data = await GraphqlService.SendMutation(VeloxProfileMutations.CREATE_VELOX_PROFILE, {
                    name: item.name,
                    username: item.username,
                    password: item.password,
                    equipment: item.equipment,
                    email: item.email,
                    phones: item.phones
                });

                loading.close();

                if (!data.success)
                    throw new Error(data.message);

                toast.addToast(data.message, {appearance: 'success', autoDismiss: true});

                item.id = data.id

                const temp = [...veloxList];
                temp.push(item);

                let profile = new ProfileList(item.id, type, item.name);

                setProfile(profile)
                setVeloxList([...temp]);


                onProfileSelected({
                    profile: profile,
                    veloxProfile: item,
                    employeeProfile: null
                });

                // setEditDriver(null);
                setOpenProfileList(false);
                setOpenModal(false)
            } catch (ex) {
                toast.addToast(ex.message, {appearance: 'error'});
                loading.close()
            }

        }

    }

    const getAddText = () => {
        if (type == 'velox')    
            return 'NEW VELOX ACCOUNT';
        if (type == 'employee')
            return 'NEW EMPLOYEE ACCOUNT';

        if (type == 'equipment')
            return 'NEW EQUIPMENT';
    }

    const handleSubmitFormEmployee = (employee, userdata) => {
        console.log(employee);
        console.log(edit);

        if (edit) {
            let index = employeeList.findIndex(e => e.id == employee.id);
            employeeList[index] = {...employee};

            setEmployeeList([...employeeList]);
        } else {
            employeeList.push(employee);
            setEmployeeList([...employeeList]);
        }

        let profile = {
            profile: new ProfileList(employee.id, 'EMPLOYEE', employee.name),
            veloxProfile: null,
            employeeProfile: employee
        };

        onProfileSelected(profile);
        setProfile(new ProfileList(employee.id, 'EMPLOYEE', employee.name));

        setEdit(false);
        setEditType('');
        setShowForm(false);
        setOpenProfileList(false);
    }


    return (
        <>
            <div className="row mb-3">
                <div className="col-12">
                    <p className="font-12 mb-2">{type.substring(0, 1).toUpperCase() + type.substring(1)} {type == 'equipment' ? '' : 'profile'}</p>

                    <div className="profile-content cursor-pointer" 
                             onClick={() => setOpenProfileList(!openProfileList)}>
                    <i className="fa fa-chevron-down font-12"></i>

                        <div className={`card bg-gray card-field`}>

                            <div className="card-body padding-input">
                                <p className={`font-11 no-select ${profile && profile.id ? '' : 'text-gray'}`}>
                                    {(profile?.id ? profile.name : 'Select a ' + (type == 'equipment' ? 'equipment' : 'profile'))}
                                </p>
                            </div>
                        </div>

                        {
                            openProfileList &&
                            <div ref={ref}
                                 className="card card-profile-list"
                                 onClick={(evt) => {
                                     evt.preventDefault();
                                     evt.stopPropagation();
                                 }}>

                                <div className="profile-list mt-2">
                                    {type === 'velox' && getVeloxList()}
                                    {type === 'employee' && getEmployeeList()}
                                    {type == 'samsara' && getSamsaraList()}
                                    {type == 'equipment' && getEquipmentList()}
                                </div>

                               {type != 'samsara' && <div className="footer w-100 text-right py-3">
                                    <p className="font-12 no-select font-medium text-light-blue cursor-pointer mr-3"
                                       onClick={() => {
                                           setShowForm(true);
                                           setEdit(false)
                                           // eslint-disable-next-line no-lone-blocks
                                           {
                                               if (type == 'velox')
                                                    setData(new VeloxProfile());
                                                else if (type == 'employee')
                                                    setData(new Employee());
                                                else
                                                    setData(null);
                                            
                                           }
                                       }}>
                                        <i className="fa fa-plus"></i>{getAddText()}
                                    </p>
                                </div>}
                            </div>
                        }
                    </div>
                </div>
            </div>

            <ReactModal className={""} isOpen={showForm}>
                {
                    type === 'velox' &&
                    <DriverSettingForm driver={data}
                    onUpdated={(id) => {
                        autoClose.current = false;
                                            dispatch(getVeloxDrivers());
                                            autoSelectVeloxID.current = id;
                    }}
                                        carrier={{id: environments.JP_CARRIER_ID}}                                    
                                      onSubmit={id => {
                                          //handleSubmit(veloxProfile)
                                          if (id)
                                          {

                                            dispatch(getVeloxDrivers());
                                            autoSelectVeloxID.current = id;
                                          }
                                          else
                                            setShowForm(false);
                                          
                                          
                                      }}/>
                }

                {
                    type === 'employee' &&
                    <EmployeeForm showTitle={true}
                                    onDeleted={() => {
                                        
                                        dispatch(fetchEmployees());
                                             

                                        setEdit(false);
                                        setEditType('');
                                        setShowForm(false);
                                        setOpenProfileList(false);
                                        if (data?.id == profile?.id) {
                                            setProfile(null);
                                            onProfileSelected({
                                                profile: profile,
                                            veloxProfile: null,
                                            employeeProfile: null,
                                            samsaraProifle: null
                                            })
                                        }
                                    }}
                                  userdata={new User()}
                                  employee={data && new Employee(data) || new Employee()}
                                  onCancel={() => setShowForm(false)}
                                  onSubmit={(employee, userdata) => {
                                      handleSubmitFormEmployee(employee, userdata);
                                  }}/>
                }
                {
                    type == 'equipment' && <EquipmentForm
                    onPhotosUpdated={(photos, id ) => {
                        const index = equipmentList.findIndex(x => x.id == id);
                        if (index >= 0)
                        {
                            const temp = [...equipmentList];
                            temp[index] = {...temp[index], photos: [...photos]};
                            setEquipmentList(temp);
                        }
                    }}
                    data={data}
                        onSubmitted={(equipment) => {
                            const index = equipmentList.findIndex(x => x.id == equipment.id);
                            const temp = [...equipmentList];
                            if (index < 0)
                                temp.push({...equipment, name:  `${equipment.type} - ${equipment.year} ${equipment.make} ${equipment.model}`})
                            else
                            {
                                temp[index] = {...equipment, name:  `${equipment.type} - ${equipment.year} ${equipment.make} ${equipment.model}`};

                            }
                            let data = temp.find(x => x.id == equipment.id);
                            console.log(data);
                            setEquipmentList(temp);
                            // if (!equipment) {
                            //     equipment = {...equipment, name:  `${equipment.type} - ${equipment.year} ${equipment.make} ${equipment.model}`}
                            // }
                            setProfile(data as any);
                            onProfileSelected({
                                profile: data,
                                equipment: data
                            })
                            setShowForm(false);

                        }}
                        onCancel={(evt) => setShowForm(false)}
                    />
                }
            </ReactModal>
        </>
    );
}

export default ProfileSelectField

import {gql} from "apollo-boost";
import {Notification} from "../notification";
import {GraphqlService} from "../../../../graphql.service";

export const get_all_notifications = (input:{}) : Promise<Notification[]> => {
  let query = gql`
    query {
      get_all_notifications {
        id
        title
        time
        message
        type
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Notification[]>;
}
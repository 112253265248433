import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { GraphqlService } from "./app/services/graphql.service";
import client from "./app/config/graphql.client";
import { Provider } from "react-redux";
import store from "./app/redux/store";
import { ToastProvider } from "react-toast-notifications";
import { BrowserRouter } from "react-router-dom";
import "./Extensions";
import "@szhsin/react-menu/dist/index.css";
// import 'react-tiny-popover/dist/'
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import LoadingProvider from "./app/providers/LoadingProvider";
import MessageBoxProvider from "./app/providers/MessageBoxProvider";

GraphqlService.SetClient(client);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastProvider>
        <BrowserRouter>
          <LoadingProvider>
            <MessageBoxProvider>
                <App />
              </MessageBoxProvider>
          </LoadingProvider>
        </BrowserRouter>
      </ToastProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

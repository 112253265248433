import {Master_Order} from "../master_order/master_order";
import {Vehicle, Vehicle_Input} from "../vehicle/vehicle";
import {Charge, New_Charge_Input, Service_Charge} from "../service_charge/service_charge";
import {Inventory_Order, Storage_Bay, Storage_Order, Warehouse} from "../storage_order/storage_order";
import {Destination, Leg, LegInput} from "../leg/leg";
import {Inspection} from "../inspection/inspection";
import {Driver} from "../driver/driver";
import {Carrier} from "../carrier/carrier";
import {Location} from "../location/location";
import { VehicleOrder } from "../../../../classes/classes";

export class Vehicle_Order {
    __typename?: "Vehicle_order"
    id?: number = null;
    time?: string | Date = new Date();
    master_order?: Master_Order = new Master_Order();
    bol_comment?: string = '';
    time_changed?: string | Date = new Date();
    driver_note?: string = '';
    started?: boolean = false;
    delivered?: boolean = false;
    vehicle?: Vehicle = new Vehicle();
    charges?: Service_Charge[] = [];
    storage_order?: Storage_Order = new Storage_Order();
    legs?: Leg[] = [];
    storage_bay?: Storage_Bay = new Storage_Bay();
    inspections?: Inspection[] = [];
    inventory_orders?: Inventory_Order[] = [];
    pending_inspection_id?: number = null;
    master_order_id?: number = null;
    vehicle_order_files?: Vehicle_Order_File[] = [];
    warehouse_id?: number = null;
    warehouse?: Warehouse = new Warehouse();
    non_move?: boolean = true;
    canceled?: boolean = false;

    constructor(x?: Vehicle_Order) {
        if (x) {
            this.id = x.id;
            this.vehicle = new Vehicle(x.vehicle);
            this.time = new Date(x.time);
            this.master_order_id = x.master_order_id;
            this.time_changed = new Date(x.time_changed);
            this.driver_note = x.driver_note;
            this.started = x.started;
            this.delivered = x.delivered;
            this.bol_comment = x.bol_comment;
            this.legs = !x.legs ? [] : x.legs.map(l => new Leg({...l, vehicle_order_id: x.id} as any));
            this.legs.sort((x, y) => x.getPickup().time_scheduled > y.getPickup().time_scheduled ? 1 : -1);
            this.inspections = x.inspections?.map(y => new Inspection(y)) || [];
            this.inventory_orders = x.inventory_orders?.map(z => z) || [];
            this.pending_inspection_id = x.pending_inspection_id;
            this.vehicle_order_files = x.vehicle_order_files?.map(z => z) || [];
            this.warehouse_id = x.warehouse_id;
            this.warehouse = new Warehouse(x.warehouse);
            this.non_move = x.non_move;
            this.canceled = x.canceled;
            this.charges = !x.charges ? [] : x.charges.map(c => {
                c.vehicle_order_id = x.id;
                return {...c};
            });
            this.storage_bay = new Storage_Bay(x.storage_bay);
            this.master_order = new Master_Order(x.master_order);
            this.storage_order = new Storage_Order(x.storage_order);
        }
    }

    getVehicleStringify?(){
        return JSON.stringify({
            vin: this.vehicle.vin,
            year: this.vehicle.year,
            make: this.vehicle.make,
            model: this.vehicle.model,
            submodel: this.vehicle.submodel,
            color: this.vehicle.color,
            type: this.vehicle.type,
            bol_comment: this.bol_comment,
            driver_note: this.driver_note,
        })
    }
}

export class Vehicle_Order_Input {
    id?: number = null;
    legs?: LegInput[] = [];
    vehicle?: Vehicle_Input = new Vehicle_Input();
    bol_comment?: string = '';
    driver_note?: string = '';
    master_order_id?: number = null;
    charges?: New_Charge_Input[] = [];
    storage_order?: Storage_Order_Input = new Storage_Order_Input();
    non_move?: boolean = false; // Attention

    constructor(x?: Vehicle_Order_Input){
        if(x){
            this.id = x.id
            this.legs = x.legs?.map(z => z) || [];
            this.vehicle = new Vehicle(x.vehicle);
            this.bol_comment = x.bol_comment
            this.driver_note = x.driver_note
            this.master_order_id = x.master_order_id
            this.charges = x.charges?.map(z => new New_Charge_Input(z)) || [];
            this.storage_order = new Storage_Order_Input(x.storage_order)
            this.non_move = x.non_move           
        }
    }



}

export class Storage_Order_Input {
    billing_cycle?: string = '';
    rate?: number = 0;
    note?: string = '';
    active?: boolean = false;  // Attention
    warehouse_id?: number = null;
    departure_date?: string = '';

    constructor(x?: Storage_Order_Input) {
        if(x){
            this.billing_cycle = x.billing_cycle;
            this.rate = x.rate;
            this.note = x.note;
            this.active = x.active;
            this.warehouse_id = x.warehouse_id;
            this.departure_date = x.departure_date;           
        }
    }
}

export class Vehicle_Order_File {
    __typename?: "Vehicle_order_file"
    id?: number = null;
    name?: string = '';
    uid?: string = '';
    time?: string = '';
    link?: string = '';

    constructor(x?: Vehicle_Order_File) {
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.uid = x.uid;
            this.time = x.time;
            this.link = x.link;            
        }
    }
}

export class Vehicle_Orders_By_Profile_Sp {
    __typename?: "vehicle_orders_by_profile_SP"
    order?: OdSp = new OdSp();
    pickup?: PdVeh_OdSp = new PdVeh_OdSp();
    dropoff?: PdVeh_OdSp = new PdVeh_OdSp();

    constructor(x?:Vehicle_Orders_By_Profile_Sp){
        if(x){
            this.order= new OdSp(x.order);
            this.pickup= new PdVeh_OdSp(x.pickup);
            this.dropoff= new PdVeh_OdSp(x.dropoff);            
        }
    }
}

export class OdSp {
    __typename?: "odSP"
    vehicle?: string = '';
    placed_by?: string[] = [];
    placed_for?: string = '';
    master_order_id?: number = null;
    vehicle_order_id?: number = null;

    constructor(x?:OdSp ){
        if(x){
            this.vehicle = x.vehicle;
            this.placed_by = x.placed_by?.map(z => z) || [];
            this.placed_for = x.placed_for;
            this.master_order_id = x.master_order_id;
            this.vehicle_order_id = x.vehicle_order_id;            
        }
    }
}

export class PdVeh_OdSp {
    __typename?: "PDVeh_odSP"
    location?: Location = new Location();
    completed?: boolean = false;
    time_completed?: string = '';
    time_scheduled?: string = '';

    constructor(x?:PdVeh_OdSp){
        if(x){
            this.location = new Location(x.location);
            this.completed = x.completed;
            this.time_completed = x.time_completed;
            this.time_scheduled = x.time_scheduled; 
        }
    }
}

export class Vehicle_Order_Update_Input {
    bol_comment?: string = '';
    driver_note?: string = '';
    started?: boolean = false;
    delivered?: boolean = false;

    constructor(x?: Vehicle_Order_Update_Input) {
        if(x){
            this.bol_comment = x.bol_comment;
            this.driver_note = x.driver_note;
            this.started = x.started;
            this.delivered = x.delivered;            
        }
    }
}

export class Order_Details_Res {
    __typename?: "order_details_res"
    legs?: LegRes[] = [];
    vehicle?: Vehicle = new Vehicle();
    total_price?: number = 0;
    master_order_id?: number = null;
    vehicle_order_id?: number = null;

    constructor(x?: Order_Details_Res){
        if(x){
            this.legs = x.legs?.map(z => z) || [];
            this.vehicle = new Vehicle(x.vehicle );
            this.total_price = x.total_price;
            this.master_order_id = x.master_order_id;
            this.vehicle_order_id = x.vehicle_order_id;            
        }
    }
}

export class LegRes {
    __typename?: "legRes"
    driver?: Driver = new Driver();
    leg_id?: number = null;
    origin?: Destination = new Destination();
    charges?: Charge[] = [];
    carrier?: Carrier = new Carrier();
    leg_price?: number = 0;
    destination?: Destination = new Destination();
    equipment_name?: string = '';
    velox_order_id?: number = null;

    constructor(x?: LegRes){
        if(x){
            this.driver = new Driver(x.driver);
            this.leg_id = x.leg_id;
            this.origin =  new Destination(x.origin);
            this.charges = x.charges;
            this.carrier = x.carrier;
            this.leg_price = x.leg_price;
            this.destination = x.destination;
            this.equipment_name = x.equipment_name;
            this.velox_order_id = x.velox_order_id;            
        } 
    }
}

import {Vehicle, Vehicle_Input} from "../vehicle";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";

export const create_vehicle = (input:{vehicle: Vehicle_Input}) : Promise<Vehicle> => {
  let mutation = gql`
    mutation($vehicle: vehicle_input) {
      create_vehicle(vehicle: $vehicle) {
        id
        vin
        year
        make
        model
        submodel
        type
        no_vin
        special_vin
        incomplete_vin
        color
        license_plate
        internal_note
        time_created
        user_id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {vehicle} as 
  Promise<Vehicle>;
}

export const update_vehicle = (input:{vehicle: Vehicle_Input}) : Promise<Vehicle> => {
  let mutation = gql`
    mutation($vehicle: vehicle_input) {
      update_vehicle(vehicle: $vehicle) {
        id
        vin
        year
        make
        model
        submodel
        type
        no_vin
        special_vin
        incomplete_vin
        color
        license_plate
        internal_note
        time_created
        user_id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {vehicle} as 
  Promise<Vehicle>;
}

import {ResultWithLink} from "../../customer_profile/customer_profile";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {Update_Stop_Contacts} from "../stop";

export const update_stop_scheduled = (input:{stop_id: number, time_scheduled: string, note: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($stop_id: Int, $time_scheduled: String, $note: String) {  
      update_stop_scheduled(stop_id: $stop_id, time_scheduled: $time_scheduled, note: $note) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {stop_id, time_scheduled, note} as 
  Promise<ResultWithLink>;
}

export const update_stop_nofail = (input:{stop_id: number, nofail: boolean, note: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($stop_id: Int, $nofail: Boolean, $note: String) {  
      update_stop_nofail(stop_id: $stop_id, nofail: $nofail, note: $note) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {stop_id, nofail, note} as 
  Promise<ResultWithLink>;
}

export const update_stop_contacts = (input:{stop_id: number, contacts: Update_Stop_Contacts[], note: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($stop_id: Int, $contacts: update_stop_contacts[], $note: String) {  
      update_stop_contacts(stop_id: $stop_id, contacts: $contacts, note: $note) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {stop_id, contacts, note} as 
  Promise<ResultWithLink>;
}

export const update_stop_location = (input:{stop_id: number, location_id: number, note: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($stop_id: Int, $location_id: Int, $note: String) {  
      update_stop_location(stop_id: $stop_id, location_id: $location_id, note: $note) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {stop_id, location_id, note} as 
  Promise<ResultWithLink>;
}
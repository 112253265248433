import { gql } from 'apollo-boost';
import React, { FC, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Card, Customer, CustomerProfile, Leg, MasterOrder, Stop, VehicleOrder } from '../../classes/classes';
import CustomerCard from '../../components/NewOrder/CustomerCard/CustomerCard';
import Status from '../../components/Status/Status';
import Trip from '../../components/VehicleOrderDispatch/Trip/Trip';
import Helper from '../../helper/Helper';
import useLoading from '../../hooks/useLoading/useLoading';
import { GraphqlService } from '../../services/graphql.service';

interface IProps {
    vehicle_orders: VehicleOrder[],
    legs: Leg[],
    onCancel: (evt: any) => any,
    onSubmitted: () => any
}

const mutationMoveVehiclesOrders = gql`mutation($vehicle_order_ids:[Int] $master_order:master_order_input){
    create_master_from_vehicle_order(vehicle_order_ids:$vehicle_order_ids,  master_order:$master_order){
      id
      message
      success
    }
  }`;

  const mutationMoveLegs = gql`
    mutation($leg_ids:[Int] $master_order:master_order_input){
        create_master_from_leg(leg_ids:$leg_ids,  master_order:$master_order){
            id
            message
            success
        }
    }
  `;



const MoveVehicles: FC<IProps> = (props) => {

    const [tempMaster, setTempMaster] = useState(new MasterOrder());
    const [quickAction, setQuickAction] = useState(0);

    const loading = useLoading();

    const [selectedIDS, setSelectedIDS] = useState<Number[]>([]);

    const toast = useToasts();

    useEffect(() => {
        
    }, []);

    const VehicleLeg: FC<any> = ({vehicle}) => {
        const stops = vehicle?.legs?.flatMap(x => x.stops).sort((x, y) => x.time_scheduled > y.time_scheduled ? 1 : -1) || [];
        const firstPickup =  new Stop(stops.filter(x => x.is_pickup)[0]);
        const lastDropoff = new Stop(stops.filter(x => !x.is_pickup).reverse()[0]);
        return <>
            <div className="card mb-1" >
            <div className="card-body">
                <div className="row d-flex align-items-center">
                    <div className="col-5">
                        {/* <p className="fs-10 font-medium">{firstPickup?.getScheduled()}</p>
                        <p className="fs-10 font-medium mb-1">{firstPickup?.getTime()}</p> */}
                        <p className="fs-10 font-medium">{firstPickup?.location?.address?.getName()}</p>
                        <p className="fs-10">{firstPickup?.location?.address?.postal_code}</p>
                    </div>

                    <div className="col-2">
                        <i className="ti-minus"></i>
                    </div>

                    <div className="col-5">
                        {/* <p className="fs-10 font-medium">{lastDropoff?.getScheduled()}</p>
                        <p className="fs-10 font-medium mb-1">{lastDropoff?.getTime()}</p> */}
                        <p className="fs-10 font-medium">{lastDropoff?.location?.address?.getName()}</p>
                        <p className="fs-10">{lastDropoff?.location?.address?.postal_code}</p>
                    </div>
                </div>
            </div>

        </div>


        </>
    }

    const getMasterInput = () => {
        return {

            "references": tempMaster.references.filter(x => x.value.trim() != ''),
            "contacts": tempMaster.contacts.map(x => ({customer_profile_id: x.id})),
            "customer_id": tempMaster.customer.id,

            attribute_id: tempMaster.card.id
        }
    }

    async function moveVehicleOrders() {
        return GraphqlService.SendMutation(mutationMoveVehiclesOrders, {vehicle_order_ids: selectedIDS, master_order: getMasterInput()})
    }
    
    async function moveLegs() {
        
        return GraphqlService.SendMutation(mutationMoveLegs, {leg_ids: selectedIDS, master_order: getMasterInput()})
    }
    
    async function handleSubmit() {
        if (!isValid()) return;

        loading.open('Moving...');

        try {
            const data = await (props.legs.length > 0 ? moveLegs() : moveVehicleOrders());
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            loading.close();
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            Helper.Navigation.NewTab('/order/' + data.id);
            props.onSubmitted();

            
        } catch (ex) {
            loading.close()
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    const isValid = () => {
        return tempMaster.customer.id && tempMaster.contacts.length > 0 && selectedIDS.length > 0;
    }


    const getLegs = () => {
        const items = Math.ceil(props.legs.length / 3) * 3;
        const emptyBox = items - props.legs.length == 1;

        return <>
            <div className="col-12">
                {getVehicles(false)}
            </div>
            {props.legs.map((leg, index) => <div 
            onClick={(evt) => {
                let temp = [...selectedIDS];
                if (temp.includes(leg.id))
                    temp = temp.removeBy(x => x == leg.id)
                else
                    temp.push(leg.id);
                    setSelectedIDS(temp);
            }}
            className={`col-4 cursor-pointer mb-3 bg-${selectedIDS.includes(leg.id) ? 'light-blue' : 'gray'} px-2 pt-2 rounded d-flex justify-content-between`}>
            <Trip onSelectDriverClicked={(evt) => {
                let temp = [...selectedIDS];
                if (temp.includes(leg.id))
                    temp = temp.removeBy(x => x == leg.id)
                else
                    temp.push(leg.id);
                    setSelectedIDS(temp);
            }} hideButton data={leg} last={true} />
            
            <input checked={selectedIDS.includes(leg.id)}  type="checkbox"/>
            </div>)}
            {emptyBox && <div className='col-4 mb-3' />}
        </>
    }
 

    const getVehicles = (showCheckBox = true) => {

        const items = Math.ceil(props.vehicle_orders.length / 3) * 3;
        const emptyBox = items - props.vehicle_orders.length == 1;
        return <>
            {props.vehicle_orders.map((vehicle, idx) => {
            return (
                <div className="row justify-content-normal mb-3 col-4" onContextMenu={(evt) => {
                    
                    
                }}>
                    
                    <div  onClick={() => {
                        if (!showCheckBox)
                            return;
                        let temp = [...selectedIDS];
                        if (temp.includes(vehicle.id))
                            temp = temp.removeBy(x => x == vehicle.id)
                        else
                            temp.push(vehicle.id);
                        setSelectedIDS(temp);
                    }} className={`col-12  cursor-pointer card ${selectedIDS.includes(vehicle.id) ? 'bg-light-blue' : 'bg-gray'}`}>
                        <div className="card-body p-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="fs-12 font-medium">{vehicle?.vehicle?.getName()}
                                    <i className="ml-2 fs-12 ti-angle-right"></i>
                                </p>

                                <div className="d-flex justify-content-start align-items-center">
                                    <Status statusName={'IN TRANSIT'}/>
                                    {showCheckBox && <input checked={selectedIDS.includes(vehicle.id)}  type="checkbox"/>}
                                </div>
                            </div>

                            {vehicle?.vehicle?.vin?.trim() != '' && <p className="fs-12 mb-1">{vehicle?.vehicle?.vin}</p>}

                            <div>
                                <VehicleLeg vehicle={vehicle} />
                            </div>

                        </div>
                    </div>
                </div>
            )
            })}
            {emptyBox && <div className='col-4 mb-3' />}
        </>
}
    

    return <div className='flex-1-container'>
        <div className="row">
            <h4 className="font-14">Move {props.legs.length > 0 ? 'Leg(s)' : 'Vehicle(s)'} Into New Master Order</h4>
            <h4 onClick={props.onCancel} className="font-14 cursor-pointer"><i className='fas fa-times'></i></h4>
        </div>
        <div className="mb-3">
        <CustomerCard
                    openCustomersByDefault={false}
                        references={tempMaster.references}
                        
                        card={tempMaster.card} customer={tempMaster.customer} customerProfiles={tempMaster.contacts}
                        quickAction={quickAction}
                        
                        onReferencesChanged={evt => {
                            const temp = new MasterOrder(tempMaster);
                            temp.references = [...evt];
                            setTempMaster(temp);
                        }}
                        onEditChanged={evt => {

                        }}
                        onChangeCustomer={(evt, contacts) => {
                            const temp = new MasterOrder(tempMaster);
                            temp.customer = new Customer(evt);
                            temp.contacts = contacts.map(c => new CustomerProfile(c));
                            
                            setTempMaster(temp);
                        }} onCardChanged={(evt) => {
                            const temp = new MasterOrder(tempMaster);
                            temp.card = new Card(evt);
                            setTempMaster(temp);
                        }} />    
        </div>
        <div className="flex-1-container">
            <div className="row">
                        {props.legs.length == 0 && getVehicles()}
                        {props.legs.length > 0 && getLegs()}
            </div>
        </div>

        <div className="mt-3 row">
                        <button onClick={props.onCancel} className="btn btn-clear text-blue-light col-6 py-2">CANCEL</button>
                        <button onClick={(evt) => handleSubmit()} className="btn btn-blue-light col-6 py-2" disabled={!isValid()}>SUBMIT</button>
        </div>
    </div>
}

export default MoveVehicles;
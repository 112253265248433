import moment from 'moment';
import Helper from "../helper/Helper";
import { Equipment, Velox_driver } from './classes.backend';

export class CustomerList {
    id?: number = null;
    name?: string = '';
    key?: string = null;
    corporate_client?: boolean = false;
    billing_address?: Address = new Address();

    constructor(x?: CustomerList) {
        if (x) {
            this.id = x.id;
            this.name = x.name;
            // this.address = x.address [...x.address];
            this.key = x.key;
            this.corporate_client = x.corporate_client;
            this.billing_address = new Address(x.billing_address);
        }
    }
}


export class CustomerProfileList {
    id?: number = null;
    name: string = '';
    email: string = '';
    title: string = '';
    numbers: string[] = [];
    verified: boolean = false;
    role: string = '';
    contact: Contact[] = [];

    constructor(x?: CustomerProfileList) {
        if (x) {
            this.id = x.id;
            this.name = x.name;
            this.email = x.email;
            this.title = x.title;
            this.numbers = [...x.numbers];
            this.verified = x.verified;
            this.role = x.role;
            this.contact = [...x.contact];
        }
    }
}

export class Contact {
    title?: string = '';
    type?: string = '';
    value?: string = '';
    unsubscribed?: boolean = false;

    constructor(x?: Contact) {
        if (x) {
            this.title = x.title;
            this.type = x.type;
            this.value = x.value;
            this.unsubscribed = x.unsubscribed;
        }
    }
}

export class Customer {
    id?: number = null;
    name?: string = '';
    // address: string[] = [];
    key?: string = null;
    corporate_client?: boolean = false;
    billing_address?: Address = new Address();
    terms?: string;

    constructor(x?: Customer) {
        if (x) {
            this.id = x.id;
            this.name = x.name;
            // if (x.address)
            //     this.address = [...x.address];
            this.key = x.key;
            this.corporate_client = x.corporate_client;
            this.billing_address = new Address(x.billing_address);
            this.terms = x.terms;
        }
    }

    getType?() {
        return `${this.corporate_client ? 'Corporate' : 'Private'}`
    }
}

export class Vehicle {
    id?: number = null;
    vin: string = '';
    no_vin?: boolean = false;
    special_vin?: boolean = false;
    incomplete_vin?: boolean = false;
    not_running?: boolean = false;
    year: string = '';
    make: string = '';
    model: string = '';
    submodel?: string = '';
    type?: string = '';
    color?: string = '';
    license_plate?: string = '';
    internal_note?: string = '';
    time_created?: string | Date = new Date();
    time?: string | Date = new Date();
    user_id?: number = null;

    constructor(x?: Vehicle) {
        if (x) {
            this.id = x.id;
            this.vin = x.vin;
            this.no_vin = x.no_vin;
            this.special_vin = x.special_vin;
            this.incomplete_vin = x.incomplete_vin;
            this.year = x.year;
            this.make = x.make;
            this.model = x.model;
            this.submodel = x.submodel;
            this.type = x.type;
            this.color = x.color;
            this.license_plate = x.license_plate;
            this.internal_note = x.internal_note;
            this.time_created = new Date(x.time_created);
            this.time = new Date(x.time);
            this.user_id = x.user_id;
        }
    }

    getName?() {
        return `${this.year} ${this.make} ${this.model}`
    }

    fillData?(x: Vehicle) {
        this.year = x.year || this.year;
        this.make = x.make || this.make;
        this.model = x.model || this.model;
        this.submodel = x.submodel || this.submodel;
        this.type = x.type || this.type;
    }
}

export class Address {
    city: string = '';
    state: string = '';
    street: string = '';
    country: string = '';
    postal_code: string = '';
    street_number: string = '';
    postal_code_suffix?: string = '';

    constructor(x?: Address) {
        if (x) {
            this.city = x.city;
            this.state = x.state;
            this.street = x.street;
            this.country = x.country;
            this.postal_code = x.postal_code;
            this.street_number = x.street_number;
            this.postal_code_suffix = x.postal_code_suffix;
        }
    }

    public getName?(part = 1) {
        return part == 1 ? `${this.street_number} ${this.street}` : `${this.city}, ${this.state} ${this.postal_code}`;
    }


}

export class FromTo {
    from: string;
    to: string;

    constructor(props?: any) {
        if (props) {
            this.from = props.from;
            this.to = props.to;
        }
    }
}

export class WorkHours {
    weekdays: FromTo;
    weekends: FromTo;

    constructor(props?: any) {
        if (props) {
            this.weekdays = new FromTo(props.weekdays);
            this.weekends = new FromTo(props.weekends);
        } else {
            this.weekdays = new FromTo();
            this.weekends = new FromTo();
        }
    }
}

export class Location {
    id?: number = null;
    title?: string = '';
    customer?: Customer = new Customer();
    address?: Address = new Address();
    driver_note?: string = '';
    internal_note?: string = '';
    work_hours?: WorkHours;
    selected?: boolean;
    timezoneName?: string = '';

    constructor(x?: Location) {
        if (x) {
            this.id = x.id;
            this.title = x.title;
            this.customer = new Customer(x.customer);
            this.address = new Address(x.address);
            this.driver_note = x.driver_note;
            this.internal_note = x.internal_note;
            this.work_hours = new WorkHours(x.work_hours);
            this.selected = x.selected || false;
            this.timezoneName = x.timezoneName;
        }
    }
}

class TaxNumber
{
    type: string = '';
    value: string = '';

    constructor(props?: TaxNumber)
    {
        if (props)
        {
            this.type = props.type;
            this.value = props.value;
        }
    }
}

export class CustomerById {
    id?: number;
    key: string;
    name: string;
    terms: string;
    internal_note: string;
    active: boolean;
    corporate_client: boolean;
    location: Location;
    customer_profile: CustomerProfile;
    tax_number: TaxInfo = new TaxInfo();
    billing_address: Address = new Address();
    default_invoice_email: string = '';

    constructor(x?: CustomerById)
    {
        if (x)
        {
            this.key = x.key;
            this.name = x.name;
            this.terms = x.terms;
            this.internal_note = x.internal_note;
            this.corporate_client = x.corporate_client;
            this.customer_profile = new CustomerProfile(x.customer_profile);
            this.location = new Location(x.location);
            this.tax_number = new TaxNumber(x.tax_number);
            this.default_invoice_email = x.default_invoice_email || '';
            this.id = x.id;
            this.billing_address = new Address(x.billing_address);
        }
    }
}


export class CustomerProfile {
    id?: number = null;
    name?: string = '';
    email?: string = '';
    title?: string = '';
    contact?: Contact[] = [];
    role?: string = '';
    verified?: boolean = false;
    numbers?: String[];

    constructor(x?: CustomerProfile) {
        if (x) {
            this.id = x.id;
            this.name = x.name;
            this.email = x.email;
            this.title = x.title;
            this.contact = !x.contact ? [] : [...x.contact];
            this.role = x.role;
            this.verified = x.verified;
            this.numbers = x.numbers;
        }
    }
}

export class Inspection
{
    id?: number = null;
    type?: string = '';
    time?: string | Date = new Date();
    user?: User = new User();

    constructor(props?: Inspection)
    {
        if (props)
        {
            this.id = props.id;
            this.type = props.type;
            this.time = new Date(props.time);
            this.user = new User(props.user);
        }
    }
}

export class VehicleOrder {
    id?: number = null;
    vehicle?: Vehicle = new Vehicle();
    time?: string | Date = new Date();
    master_order_id?: number = null;
    master_order?: MasterOrder = new MasterOrder();
    time_changed?: string | Date = new Date();
    driver_note?: string = '';
    started?: boolean = false;
    delivered?: boolean = false;
    canceled?: boolean = false;
    bol_comment?: string = '';
    legs?: Leg[] = [];
    charges?: Charge[] = [];
    storage_order?: StorageOrder = new StorageOrder();
    inspections?: Inspection[] = [];

    constructor(x?: VehicleOrder) {
        if (x) {
            this.id = x.id;
            this.vehicle = new Vehicle(x.vehicle);
            this.time = new Date(x.time);
            this.master_order_id = x.master_order_id;
            this.time_changed = new Date(x.time_changed);
            this.driver_note = x.driver_note;
            this.started = x.started;
            this.delivered = x.delivered;
            this.canceled = x.canceled;
            this.bol_comment = x.bol_comment;
            this.legs = !x.legs ? [] : x.legs.map(l => new Leg({...l, vehicle_order_id: x.id} as any));
            this.legs.sort((x, y) => x.getPickup().time_scheduled > y.getPickup().time_scheduled ? 1 : -1);
            this.inspections = x.inspections?.map(y => new Inspection(y)) || [];
            this.charges = !x.charges ? [] : x.charges.map(c => {
                c.vehicle_order_id = x.id;
                return new Charge(c);
            });
            this.master_order = new MasterOrder(x.master_order);
            this.storage_order = new StorageOrder(x.storage_order);
        }
    }

    getVehicleStringify?(){
        return JSON.stringify({
            vin: this.vehicle.vin,
            year: this.vehicle.year,
            make: this.vehicle.make,
            model: this.vehicle.model,
            submodel: this.vehicle.submodel,
            color: this.vehicle.color,
            type: this.vehicle.type,
            bol_comment: this.bol_comment,
            driver_note: this.driver_note,
        })
    }
}

export class BasicUser {
    id?: number = null
    name: string = ''
    password: string = ''

    constructor(props?: any) {
        if (props) {
            this.id = props.id ?? null;
            this.name = props.name ?? '';
            this.password = props.password ?? '';
        }
    }
}


export class Employee {
    id?: number = null;
    name?: string = '';
    address?: Address = new Address();
    type?: string = '';
    note?: string = '';
    department?: string = '';
    phone?: string = '';
    email?: string = '';
    tax_info?: TaxInfo = new TaxInfo();
    active?: boolean = true;

    constructor(props?: any) {
        if (props) {
            this.name = props.name ?? '';
            this.type = props.type ?? '';
            this.note = props.note ?? '';
            this.department = props.department ?? '';
            this.phone = props.phone ?? '';
            this.email = props.email ?? '';

            this.tax_info = new TaxInfo(props.tax_info);
            this.address = new Address(props.address);
            this.active = props.active;
        }
    }
}

class TaxInfo {
    type: string = '';
    value: string = '';

    constructor(props?) {
        if(props){
            this.type = props.type;
            this.value = props.value;
        }
    }
}

export class User {
    id?: number = null;
    name?: string = '';
    password?: string = '';
    level?: number = 0;
    employee?: Employee = new Employee();

    constructor(x?: User) {
        if (x) {
            this.id = x.id;
            this.name = x.name;
            this.password = x.password;
            this.level = x.level;
            this.employee = new Employee(x.employee);
        }
    }
}

export class Company {
    id?: number = null;
    name?: string = '';
    active?: boolean = false;

    constructor(x?: Company) {
        if (x) {
            this.id = x.id;
            this.name = x.name;
            this.active = x.active;
        }
    }
}

export class Carrier {
    id?: number = null;
    name?: string = '';
    company?: Company = new Company();
    code?: string = '';
    contact? = {
        name: '',
        email: '',
        phone: ''
    }


    constructor(x?: Carrier) {
        if (x) {
            this.id = x.id;
            this.name = x.name;
            this.company = new Company(x.company);
            this.code = x.code;
            this.contact = x.contact ? {...x.contact} : {name: '', phone: '', email: ''};
        }
    }
}

export class Driver {
    id?: number = null;
    name?: string = '';
    email?: string = '';
    phones?: string[] = [];
    velox_id?: number = 0;
    veloxProfile?: Velox_driver = new Velox_driver();
    carrier?: Carrier = new Carrier();
    employee?: Employee = new Employee();
    active?: boolean = false;
    contacts?: Contact[] = [];
    equipment?: string;
    photos?: DriverPhoto[] = [];
    username?: string = '';
    constructor(x?: Driver) {
        if (x) {
            this.id = x.id;
            this.name = x.name;
            this.email = x.email;
            this.username = x.username;
            this.velox_id = x.velox_id;
            if (x.phones)
                this.phones = [...x.phones];
            this.carrier = new Carrier(x.carrier);
            this.employee = new Employee(x.employee);
            this.active = x.active;
            this.contacts = x.contacts?.map(x => new Contact(x)) || [];
            this.username = x.username ?? '';
            this.equipment = x.equipment;
            this.photos = x.photos ? x.photos.map(e => new DriverPhoto(e)) : [];
            this.veloxProfile = new Velox_driver(x.veloxProfile);
        }
    }
}


export class DriverPhoto {
    uid?: string;
    name: string;

    constructor(props?: any) {
        if (props) {
            this.uid = props.uid;
            this.name = props.name;
        }
    }
}

export class VeloxOrder
{
    id?: number = null;
    accepted?: boolean = false;
    rejected?: boolean = false;
    cancelled?: boolean = false;
    expired?: boolean = false;
    expires?: string | Date = new Date();
    change_requests?: ChangeRequest[] = [];

    constructor(props?: VeloxOrder)
    {
        if (props)
        {
            this.id = props.id;
            this.accepted = props.accepted;
            this.rejected = props.rejected;
            this.cancelled = props.cancelled;
            this.expired = props.expired;
            this.expires = new Date(props.expires)
            this.change_requests = props.change_requests?.map(x => new ChangeRequest(x)) || [];
        }
    }
}

export class ChangeRequest
{
    id?: number = null;
    order_id?: number = null;
    accepted?: boolean = false;
    rejected?: boolean = false;
    time?: string | Date = new Date();
    payout?: number = 0;
    note?: string = '';
    active?: boolean = false;
    expired?: boolean = false;
    expires_at?: string | Date = new Date();
    weblink?: string = '';
    code?: string = '';

    constructor(props?: ChangeRequest)
    {
        if (props)
        {
            this.id = props.id;
            this.order_id = props.id;
            this.accepted = props.accepted;
            this.rejected = props.rejected;
            this.time = new Date(props.time);
            this.payout = props.payout;
            this.note = props.note;
            this.active = props.active;
            this.expired = props.expired;
            this.expires_at = new Date(props.expires_at)
            this.weblink = props.weblink;
            this.code = props.code;
        }
    }
}

export class Leg {
    id?: number = null;
    vehicle_order_id?: number = null;
    time?: string | Date = new Date();
    time_completed?: string | Date = new Date();
    status?: string = '';
    driver?: Driver = new Driver();
    carrier?: Carrier = new Carrier();
    completed?: boolean = false;
    canceled?: boolean = false;
    equipment?: string = '';
    stops?: Stop[] = [];
    equipment_price?: number = 0.00;
    service_charges?: ServiceCharge[];
    velox_order_id?: number = null;
    carrier_pay?: number = 0;
    driver_pay?: number = 0;
    remaining_carrier_receivable?: number = 0;
    remaining_driver_payable?: number = 0;
    vehicle_order?: VehicleOrder = new VehicleOrder();
    velox_order?: VeloxOrder = new VeloxOrder();


    constructor(x?: Leg) {
        if (x) {
            this.id = x.id;
            this.vehicle_order_id = x.vehicle_order_id;
            this.time = new Date(x.time);
            this.time_completed = new Date(x.time_completed);
            // this.status = x.status;
            // alert(x.status)
            // console.log('driver', x.driver);
            this.driver = new Driver(x.driver);
            this.carrier = new Carrier(x.carrier);
            this.completed = x.completed;
            this.canceled = x.canceled;
            // this.equipment = x.equipment;
            // this.equipment_price = x.equipment_price || 0;




            this.equipment_price = x?.service_charges?.[0]?.amount || x.equipment_price || 0;
            this.equipment = x?.service_charges?.[0]?.name || x.equipment || '';
            this.service_charges = x?.service_charges?.map(z => {
                z.leg_id = x.id;
                return new ServiceCharge(z);
            }) || [];
            // console.log('here inside leg')
            this.stops = !x.stops ? [] : x.stops.map(s => new Stop(s));
            x.stops.sort((x, y) => x.is_pickup ? 1 : -1);

            this.velox_order_id = x.velox_order_id || x.velox_order?.id;
            this.velox_order = new VeloxOrder(x.velox_order);
            this.driver_pay = x.driver_pay;
            this.remaining_carrier_receivable = x.remaining_carrier_receivable;
            this.remaining_driver_payable = x.remaining_driver_payable;
            this.vehicle_order = new VehicleOrder(x.vehicle_order);

            this.carrier_pay = x.carrier_pay || (x as any).price || 0;
        } else {
            let s = new Stop();
            s.is_pickup = false;

            this.stops = [new Stop(), s];
        }
    }

    getDate() {
        return moment(this.time).format('MM/DD/YYYY');
    }

    getTime() {
        return moment(this.time).format('hh:mm a');
    }

    getServiceChargesTotal() {
        return this.equipment_price;
    }

    getPickup() {
        return this.stops.find(x => x.is_pickup == true);
    }

    getDropoff() {
        return this.stops.find(x => x.is_pickup == false);
    }

    getJobInterval?() {
        const init = moment(this.getPickup()?.time_scheduled)
        const end = moment(this.getDropoff()?.time_scheduled)
        const duration = moment.duration(end.diff(init));
        const h = parseInt(String(duration.asHours()));
        const m = parseInt(String(duration.asMinutes())) % 60;
        return `${h > 0 ? h : '00'}h ${m > 0 ? m : '00'}m`;
    }

    getNoFailCount() {
        return this.stops.filter(e => e.nofail).length;
    }

    setStop(stop: Stop) {
        const index = this.stops.findIndex(x => x.is_pickup == stop.is_pickup);
        if (index > -1)
            this.stops[index] = new Stop(stop);
    }
}

export class TimeFrame
{
    start: string | Date = new Date();
    end: string | Date = new Date();

    constructor (props?: TimeFrame)
    {
        if (props)
        {
            this.start = new Date(props.start);
            this.end = new Date(props.end);
        }
    }
}

export class Stop {
    id?: number = null;
    location?: Location = new Location();
    leg_id?: number = null;
    is_pickup?: boolean = true;
    time_scheduled?: string | Date = new Date();
    time_completed?: string | Date = new Date();
    completed?: boolean = false;
    started?: boolean = false;
    canceled?: boolean = false;
    nofail?: boolean = false;
    contacts?: CustomerProfile[] = [];
    nofail_price?: number = 0.00;
    service_charges?: ServiceCharge[] = [];
    time_frame?: TimeFrame = new TimeFrame();
    log?: Log[] = [];

    constructor(x?: Stop) {
        if (x) {
            this.id = x.id;
            this.location = new Location(x.location);
            this.leg_id = x.leg_id;
            this.is_pickup = x.is_pickup;
            this.time_scheduled = new Date(x.time_scheduled);
            this.completed = x.completed;
            this.started = x.started;
            this.canceled = x.canceled;
            this.nofail = x.nofail;
            this.contacts = !x.contacts ? [] : x.contacts.map(c => new CustomerProfile(c));
            this.nofail_price = x.nofail_price || 0;
            this.time_frame = new TimeFrame(x.time_frame);
            this.log = x.log?.map(x => new Log(x)) || [];
            // console.log('nofail_orice', x.service_charges);

            // console.log(x.location);
            if (x.service_charges?.length > 0 && x.nofail_price == null) {
                this.nofail_price = x.service_charges.find(y => y.name == `NOFAIL for ${x.is_pickup ? 'PICKUP' : 'DROPOFF'}`)?.amount || 0;
                this.service_charges = x.service_charges.map(z => {
                    z.stop_id = x.id;
                    return new ServiceCharge(z);
                }) || [];
            }
        }
    }

    public getScheduled?() {
        return moment(this.time_scheduled).format('MM/DD/YYYY');
    }

    getTime?() {
        return moment(this.time_scheduled).format('hh:mm a');
    }

    // Datetime for Pickup can not be higher than Dropoff Datetime
    validateLegDateAssignment?(leg: Leg, date: string | Date){
        let isValidDate = true;
        let msg = '';
        if (this.is_pickup) {
            msg = 'Pick up date can not be higher than Drop off date';
            isValidDate = Helper.validateMaxLegDate(date, leg.getDropoff().time_scheduled)
        } else {
            msg = 'Drop off date can not be lower than Pick up date';
            isValidDate = Helper.validateMaxLegDate(leg.getPickup().time_scheduled, date)
        }
        return {isValidDate, msg};
    }
}

export class StorageCharge {
    id?: number = null;
    period_start?: string | Date = new Date();
    period_end?: string | Date = new Date();
    price?: number = 0;
    note?: string = '';
    storage_order_id?: number = null;

    constructor(props?: StorageCharge) {
        if (props)
        {
            this.id = props.id;
            this.period_start = new Date(props.period_start);
            this.period_end = new Date(props.period_end);
            this.price = props.price;
            this.note = props.note || '';
            this.storage_order_id = props.storage_order_id;
        }
    }
}

export class Log {
    user_id?: number = null;
    message: string = '';
    time: string | Date = new Date();
    vehicle_order_id?: number;
    leg_id?: number;
    stop_id?: number;

    constructor(props?: Log) {
        this.user_id = props.user_id;
        this.message = props.message;
        this.time = new Date(props.time);
        this.vehicle_order_id = props.vehicle_order_id;
        this.leg_id = props.leg_id;
        this.stop_id = props.stop_id;
    }
}


export class StoragePosition{
    x: number = 0;
    y: number = 0;

    constructor(props?: StoragePosition)
    {
        if (props)
        {
            this.x = props.x;
            this.y = props.y;
        }
    }
}

export class StorageBay{
    id?: number = null;
    label?: string = '';
    position?: StoragePosition = new StoragePosition();
    vehicle_order_id?: number = null;
    vehicle_order?: VehicleOrder = new VehicleOrder();

    constructor(props?: StorageBay)
    {
        if (props)
        {
            this.id = props.id;
            this.label = props.label;
            this.position = props.position;
            this.vehicle_order_id = props.vehicle_order_id;
            this.vehicle_order = new VehicleOrder(props.vehicle_order);
        }
    }
}

export class StorageFormat{
    format?: string = 'letter';
    reverse?: boolean = false;
    starting_value?: string = 'A';

    constructor(props?: StorageFormat)
    {
        if (props)
        {
            this.format = props.format;
            this.reverse = props.reverse;
            this.starting_value = props.starting_value;
        }
    }
}



export class StorageSection{
    id?: number = null;
    columns?: number = 1;
    rows?: number = 1;
    columns_format?:  StorageFormat = new StorageFormat();
    rows_format?: StorageFormat = new StorageFormat();
    name?: string = '';
    position?: StoragePosition = new StoragePosition();
    storage_bays?: StorageBay[] = [];
    warehouse_id?: number = null;
    warehouse?: Warehouse = new Warehouse();

    constructor(props?: StorageSection)
    {
        if (props)
        {
            this.id = props.id;
            this.columns = props.columns;
            this.rows = props.rows;
            this.columns_format = new StorageFormat(props.columns_format);
            this.rows_format = new StorageFormat(props.rows_format);
            this.name = props.name;
            this.position = new StoragePosition(props.position);
            this.storage_bays = props.storage_bays.map(x => new StorageBay(x));
            this.warehouse = new Warehouse(props.warehouse);
            this.warehouse_id = props.warehouse_id;
        }
    }
}

export class Warehouse
{
    id?: number = null;
    name: string = '';
    address: Address = new Address();
    storage_sections?: StorageSection[] = [];

    constructor(props?: Warehouse)
    {
        if (props)
        {
            this.id = props.id;
            this.name = props.name;
            this.address = new Address(props.address);
            this.storage_sections = props.storage_sections?.map(x => new StorageSection(x)) || [];
        }
    }
}

export class StorageOrder {
    id?: number = null;
    billing_cycle?: string = '';
    rate?: number = 0;
    date_last_issued?: string | Date = new Date();
    departure_date?: string | Date = new Date();
    note?: string = '';
    log?: Log[] = [];
    time?: string | Date = new Date();
    active?: boolean = false;
    vehicle_order_id?: number = null;
    warehouse?: Warehouse = new Warehouse();

    constructor(props?: StorageOrder) {
        if (props)
        {
            this.id = props.id;
            this.billing_cycle = props.billing_cycle;
            this.rate = props.rate;
            this.date_last_issued = new Date(props.date_last_issued);
            if (props.departure_date)
                this.departure_date = new Date(props.departure_date);
            else
                this.departure_date = null;
            this.note = props.note;
            this.log = props.log?.map(x => new Log(x)) || [];
            this.time = new Date(props.time);
            this.active = props.active;
            this.vehicle_order_id = props.vehicle_order_id;
            this.warehouse = new Warehouse(props.warehouse);
        }
    }
}




export class ServiceCharge {
    id?: number = null;
    name?: string = '';
    master_order_id?: number = null;
    vehicle_order_id?: number = null;
    leg_id?: number = null;
    stop_id?: number = null;
    payment_id?: number = null;
    refund_id?: number = null;
    base_amount?: number = 0;
    tax_amount?: number = 0;
    amount?: number = 0;
    voided?: boolean = false;
    note?: string = '';
    amount_remaining?: number = 0;
    storage_charge?: StorageCharge = new StorageCharge();

    constructor(x?: ServiceCharge) {
        if (x) {
            this.id = x.id;
            this.name = x.name;
            this.master_order_id = x.master_order_id;
            this.vehicle_order_id = x.vehicle_order_id;
            this.leg_id = x.leg_id;
            this.payment_id = x.payment_id;
            this.refund_id = x.refund_id;
            this.base_amount = x.base_amount;
            this.tax_amount = x.tax_amount;
            this.amount = x.amount;
            this.amount_remaining = x.amount_remaining;
            this.voided = x.voided;
            this.note = x.note;
            this.stop_id = x.stop_id;
            this.storage_charge = x.storage_charge;
        }
    }

}

export class InventoryService {
    service_charge_id?: number = null;
    completed?: boolean = false;
    time_completed?: string | Date = new Date();
    time_start?: string | Date = new Date();
    time_end?: string | Date = new Date();

    constructor(props?: InventoryService)
    {
        // console.log(props);
        if (props && props.time_start)
        {
            // console.log()
            this.service_charge_id = props.service_charge_id;
            this.completed = props.completed;
            this.time_completed = new Date(props.time_completed);
            this.time_start = new Date(props.time_start);
            this.time_end = new Date(props.time_end);
        }
    }
}

export class Charge {
    id?: number = null;
    name?: string = '';
    base_amount?: number = 0;
    tax_amount?: number = 0;
    tax_id?: number = null;
    amount?: number = 0;
    amount_remaining?: number = 0;
    voided?: boolean = false;
    note?: string = '';
    master_order_id?: number = null;
    vehicle_order_id?: number = null;
    leg_id?: number = null;
    stop_id?: number = null;
    storage_charge?: StorageCharge = new StorageCharge();
    inventory_service?: InventoryService = new InventoryService();

    constructor(x?: Charge) {
        if (x) {
            this.id = x.id;
            this.name = x.name;
            this.base_amount = +x.base_amount;
            this.tax_amount = +x.tax_amount;
            this.tax_id = +x.tax_id;
            this.amount = +x.amount;
            this.amount_remaining = x.amount_remaining;
            this.voided = x.voided;
            this.note = x.note;
            this.master_order_id = x.master_order_id;
            this.vehicle_order_id = x.vehicle_order_id;
            this.leg_id = x.leg_id;
            this.stop_id = x.stop_id;
            this.storage_charge = new StorageCharge(x.storage_charge);
            this.inventory_service = new InventoryService(x.inventory_service);
        }
    }

}

export class MasterOrder {
    id?: number = null;
    customer?: Customer = new Customer();
    time?: string | Date = new Date();
    user?: User = new User();
    notes?: string[] = [];
    started?: boolean = false;
    completed?: boolean = false;
    price?: number = 0;
    payment_left?: number = 0;
    paid?: boolean = false;
    terms?: string = '';
    canceled?: boolean = false;
    references?: Reference[] = [{key: 'PO', value: ''}, {key: 'RO', value: ''}, {key: 'STOCK', value: ''}];
    service_charges?: Charge[] = [];
    contacts?: CustomerProfile[] = [];
    vehicle_orders?: VehicleOrder[] = [];
    billing_address?: Location = new Location();
    card?: Card = new Card();
    logs?: Log[] = [];
    needs_invoice?: boolean = true;

    constructor(x?: MasterOrder) {
        if (x) {
            // console.log('here 1')
            this.id = x.id;
            this.customer = new Customer(x.customer);
            this.time = new Date(x.time);
            this.user = new User(x.user);
            if (x.notes)
                this.notes = [...x.notes];
            this.started = x.started;
            this.completed = x.completed;
            this.price = x.price;
            this.payment_left = x.payment_left;
            this.paid = x.paid;
            if (x.references)
                this.references = x.references?.map(r => new Reference(r));
            this.service_charges = !x.service_charges ? [] : x.service_charges.map(c => {
                c.master_order_id = x.id;
                return new Charge(c)
            });
            this.contacts = !x.contacts ? [] : x.contacts.map(c => new CustomerProfile(c));
            this.vehicle_orders = !x.vehicle_orders ? [] : x.vehicle_orders.map(c => new VehicleOrder(c));
            this.billing_address = new Location(x.billing_address);
            this.card = new Card(x.card);
            this.logs = x.logs?.map(log => new Log(log)) || [];
            this.needs_invoice = x.needs_invoice == null ? true : x.needs_invoice;
        }
    }

    getServiceChargesBreakDown(): MasterBreakDown {
        let vehiclesList = [];
        this.vehicle_orders.map((vehicles) => {
            const serviceAmount = vehicles.charges.sumBy(x => x.amount);
            const legsAmount = vehicles.legs.sumBy(x => x.getServiceChargesTotal());


            const serviceAmountR = vehicles.charges.sumBy(x => x.amount_remaining);
            const legsAmountR = vehicles.legs.sumBy(x => (x.service_charges && x.service_charges[0]?.amount_remaining) || 0);

            const equipments = vehicles.legs.reduce((previousValue, currentValue) => {
                if (!previousValue[currentValue.equipment]) {
                    previousValue[currentValue.equipment] = 1;
                } else {
                    previousValue[currentValue.equipment] += 1;
                }

                return previousValue;
            }, {});

            vehiclesList.push(
                new BreakDown({
                    id: vehicles.id,
                    legs: vehicles.legs,
                    charges: vehicles.charges,
                    noFailCount: vehicles.legs.sumBy(x => x.getNoFailCount()),
                    equipments: Object.keys(equipments).map(key => {
                        return {equipment: key, count: equipments[key]}
                    }),
                    totalLegs: legsAmount,
                    vehicle: vehicles.vehicle,
                    amount: serviceAmount,
                    totalCost: serviceAmount + legsAmount,
                    totalRemaining: serviceAmountR + legsAmountR,
                    storage_order: new StorageOrder(vehicles.storage_order)
                }))
        });

        const x = new MasterBreakDown({
            breakdowns: vehiclesList,
            id: this.id,
            charges: this.service_charges
        });

        return x;

    }

    getTotalAmount() {
        return this.getServiceChargesBreakDown().breakdowns.sumBy(e => e.totalCost)
    }

    getTotalAmountRemainig() {
        return this.getServiceChargesBreakDown().breakdowns.sumBy(e => e.totalRemaining)
    }

}

class EquipmentCount {
    equipment: string;
    count: number;
}

export class MasterBreakDown {
    id: number = null;
    charges: Charge[] = [];
    breakdowns: BreakDown[] = [];

    constructor (props?: MasterBreakDown)
    {
        if (props)
        {
            this.id = props.id;
            this.charges = props.charges.map(x => new Charge(x));
            this.breakdowns = props.breakdowns.map(x => new BreakDown(x));
        }
    }
}

export class BreakDown {
    id?: number = null;
    legs: Leg[];
    charges: Charge[];
    noFailCount: number;
    equipments: EquipmentCount[];
    totalLegs: number;
    vehicle: Vehicle;
    amount: number;
    totalCost: number;
    totalRemaining: number;
    storage_order: StorageOrder = new StorageOrder();

    constructor(props: BreakDown) {
        this.id = props.id;
        this.legs = props.legs;
        this.charges = props.charges?.sort((x, y) => x.storage_charge.id ? 1 : -1);
        this.noFailCount = props.noFailCount;
        this.totalLegs = props.totalLegs;
        this.vehicle = props.vehicle;
        this.amount = props.amount;
        this.totalCost = props.totalCost;
        this.equipments = props.equipments;
        this.totalRemaining = props.totalRemaining;
        this.storage_order = new StorageOrder(props.storage_order);
    }

    static getTotalCharges = (breakdown) => {
        return breakdown.charges.sumBy(e => e.amount);
    }

    static getTotalLegs = breakdown => {
        return breakdown.legs.sumBy(e => e.getServiceChargesTotal())
    }

    static getTotal = breakdown => {
        return BreakDown.getTotalCharges(breakdown) + BreakDown.getTotalLegs(breakdown);
    }
}

export class Tax {
    id?: number = null;
    name: string = '';
    amount: number = 0;
    active: boolean = true;

    constructor(x?: Tax) {
        if (x) {
            this.id = x.id;
            this.name = x.name;
            this.amount = x.amount;
            this.active = x.active;
        }
    }
}

export class ScheduleStatus {
    text: string;
    text_color: string;
    border_color: string;
    background_color: string;
}

export class ScheduleVehicle {
    id?: number;
    vin: string;
    make: string;
    type: string;
    year: string;
    color: string;
    model: string;
    no_vin: boolean;
    submodel: string;
    special_vin: boolean;
    internal_note: string;
    license_plate: string;
    incomplete_vin: boolean;

    constructor(props) {
        this.id = props.id;
        this.vin = props.vin;
        this.make = props.make;
        this.type = props.type;
        this.year = props.year;
        this.color = props.color;
        this.model = props.model;
        this.no_vin = props.no_vin;
        this.submodel = props.submodel;
        this.special_vin = props.special_vin;
        this.internal_note = props.internal_note;
        this.license_plate = props.license_plate;
        this.incomplete_vin = props.incomplete_vin;
    }

    get name() {
        return `${this.year} ${this.model}`
    }

    get formattedVIN() {
        return `${this.vin.slice(0, 8)}`
    }
}

export class Order {
    id?: number;
    nofail: boolean;
    vehicle: ScheduleVehicle;
    order_id?: number;
    completed: boolean;
    is_pickup: boolean;
    scheduled: string;

    constructor(props) {
        this.id = props.id;
        this.nofail = props.nofail;
        this.vehicle = new ScheduleVehicle(props.vehicle);
        this.order_id = props.order_id;
        this.completed = props.completed;
        this.is_pickup = props.is_pickup;
        this.scheduled = props.scheduled;
    }

    public get hour() {
        return moment(this.scheduled).format('hh:mm A');
    }

    public get date() {
        return moment(this.scheduled).format('MM/DD');
    }
}

export class Itinerary {
    orders: Order[];
    address: string;

    constructor(props) {
        this.orders = props.orders.map(x => new Order(x));
        this.address = props.address;
    }

    // TODO: we need an estimated time by job, currently we only have scheduled field
    get orderETA() {
        return '';
    }
}

export class DriverSchedule {
    name: string;
    price: number;
    status: ScheduleStatus;
    carrier: string;
    itinerary: Itinerary[];

    constructor(props) {
        this.name = props.name;
        this.price = props.price;
        this.status = props.status;
        this.carrier = props.carrier;
        this.itinerary = props.itinerary.map(x => new Itinerary(x));
    }
}


export class QuoteContactInfo {
    full_name: string = '';
    email: string = '';
    phone_1: string = '';
    phone_2: string = '';
    company_name: string = '';

    constructor(x?: QuoteContactInfo) {
        if (x) {
            this.full_name = x.full_name;
            this.email = x.email;
            this.phone_1 = x.phone_1;
            this.phone_2 = x.phone_2;
            this.company_name = x.company_name;
        }
    }
}

export class QuoteStop {
    city: string = '';
    state: string = '';
    postal_code: string = '';
    guaranteed: boolean = false;
    pickup_date?: string | Date = new Date();
    dropoff_date?: string | Date = new Date();
    country?: string = 'United States';


    constructor(x?: QuoteStop) {
        if (x) {
            this.city = x.city || '';
            this.state = x.state || '';
            this.postal_code = x.postal_code || '';
            this.guaranteed = x.guaranteed;
            this.country = x.country || 'United States';
            if (x.pickup_date)
                this.pickup_date = new Date(x.pickup_date);
            if (x.dropoff_date)
                this.dropoff_date = new Date(x.dropoff_date);
        }

    }

    toStringAddress() {
        return this.city + ' ' + this.state + ' ' + this.postal_code;
    }

}

export class QuoteVehicleInfo {
    make: string = '';
    model: string = '';
    year: string = '';
    vin: string = '';
    equipment: string = '';
    price: number = 0;
    nofail_pickup_price: number = 0;
    nofail_dropoff_price: number = 0;

    constructor(x?: QuoteVehicleInfo) {
        if (x) {
            this.make = x.make;
            this.model = x.model;
            this.year = x.year;
            this.vin = x.vin;
            this.equipment = x.equipment;
            this.price = x.price;
            this.nofail_pickup_price = x.nofail_pickup_price;
            this.nofail_dropoff_price = x.nofail_dropoff_price;
        }
    }
}

export class Quote {
    id?: number = null;
    contact_info: QuoteContactInfo = new QuoteContactInfo();
    pickup: QuoteStop = new QuoteStop();
    dropoff: QuoteStop = new QuoteStop();
    vehicle_info: QuoteVehicleInfo[] = [];
    user: User = new User();
    archived: boolean = false;
    time_created: string | Date = new Date();
    note: string = '';
    status: string = '';
    price: number = 0;
    master_order_id?: number = null;

    constructor(x?: Quote) {
        if (x) {
            this.id = x.id;
            this.contact_info = new QuoteContactInfo(x.contact_info);
            this.pickup = new QuoteStop(x.pickup);
            this.dropoff = new QuoteStop(x.dropoff);
            this.vehicle_info = x.vehicle_info.map(y => new QuoteVehicleInfo(y));
            this.user = new User(x.user);
            this.archived = x.archived;
            this.time_created = new Date(x.time_created);
            // console.log(x.time_created);
            this.note = x.note;
            this.master_order_id = x.master_order_id;
            // this.status = x.archived ? 'ARCHIVED' : (this.user.id ? 'QUOTED' : 'RECEIVED');

            if (x.master_order_id)
                this.status = 'ACCEPTED';
            else if (this.archived)
                this.status = 'ARCHIVED';
            else if (x.vehicle_info.filter(x => +x.price == 0).length == x.vehicle_info.length)
                this.status = 'NEEDS QUOTE';
            else if (x.vehicle_info.some(x => +x.price == 0))
                this.status = 'IN PROGRESS';
            else
                this.status = 'QUOTED';
        }
    }

    getTotal?() {
        return this.vehicle_info.sumBy(x => x.price + x.nofail_pickup_price + x.nofail_dropoff_price);
    }
}

class Coord {
    Latitude: number;
    Longitude: number;

    constructor(props) {
        this.Latitude = props.Latitude;
        this.Longitude = props.Longitude;
    }
}

class Device {
    time: string;
    speed: string;
    course: string;
    device: string;
    altitude: string;
    coordinate: Coord;
    background_mode: boolean = false;

    constructor(props) {
        this.time = props.time;
        this.speed = props.speed;
        this.course = props.course;
        this.altitude = props.altitude;
        this.coordinate = new Coord(props.coordinate);
        this.background_mode = props.background_mode;
    }
}


export class TrackingDeviceGeo
{
    formattedLocation: string = '';

    constructor (props?: TrackingDeviceGeo)
    {
        if (props)
            this.formattedLocation = props.formattedLocation;
    }
}

export class TrackingDeviceLocation
{
    time: string | Date = new Date();
    latitude: number = 0;
    longitude: number = 0;
    heading: number = 0;
    speed: number = 0;
    reverseGeo: TrackingDeviceGeo = new TrackingDeviceGeo();

    constructor (props?: TrackingDeviceLocation)
    {
        if (props)
        {
            this.time = new Date(props.time);
            this.latitude = props.latitude;
            this.longitude = props.longitude;
            this.heading = props.heading;
            this.speed = props.speed;
            // console.log(props);
            // console.log(props.reverseGeo);
            this.reverseGeo = new TrackingDeviceGeo(props.reverseGeo);
        }
    }
}

export class TrackingDevice
{
    id?: string = '';
    name: string = '';
    location: TrackingDeviceLocation = new TrackingDeviceLocation();

    constructor (props?: TrackingDevice)
    {
        if (props)
        {
            this.id = props.id;
            this.name = props.name;
            this.location = new TrackingDeviceLocation(props.location);
        }
    }

}

export class DriverLocation {
    id?: number
    samsara_truck_id?: string;
    name: string
    devices: Device[];
    username: string;

    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.username = props.username;
        this.devices = props.devices.map((x) => new Device(x));
        this.samsara_truck_id = props.samsara_truck_id;
    }
}


export class TruckDetail
{
    id?: string = '';
    name: string = '';
    image_url: string = '';
    location: TrackingDeviceLocation = new TrackingDeviceLocation();
    fuel_percentage: string = '';

    constructor (props?: TruckDetail)
    {
        if (props)
        {
            this.id = props.id;
            this.name = props.name;
            this.image_url = props.image_url;
            this.location = new TrackingDeviceLocation(props.location);
            this.fuel_percentage = props.fuel_percentage;
        }
    }
}

export class UserSettings
{
    driver_view_order: {carrier_id?: number, order: {id?: number}[]}[] = [];

    constructor(props?: UserSettings) {
        if (props)
        {
            this.driver_view_order = props.driver_view_order?.map(x => ({carrier_id: x.carrier_id, order: x.order.map(y => ({id: y.id}))}));
        }

    }

}


export class Card
{
    id: number = null;
    cardholder_name: string = '';
    number: string = '';
    exp: string = '';
    expired: boolean = false;
    cvv: string = '';
    type: string = '';
    zip: string = '';
    country: string = "United States";
    street: string = "";
    city: string = "";
    state: string = "CA";

    constructor(props?: Card)
    {
        if (props)
        {
            this.id = props.id;
            this.cardholder_name = props.cardholder_name;
            this.number = props.number;
            this.type = props.type;
            this.expired = props.expired;
            if (props.exp)
                this.exp = props.exp;
            if (props.cvv)
                this.cvv = props.cvv;
            this.country = props.country;
            this.state = props.state;
            this.city = props.city;
            this.zip = props.zip;
            this.street = props.street;
        }
    }
}


export class ServiceChargeInvoice extends ServiceCharge
{
    amount_to_charge: number = 0;
    constructor (props?: ServiceChargeInvoice)
    {
        super(props)
        if (props)
        {
            this.amount_to_charge = props.amount_remaining < 0 ? props.amount_remaining : (props.amount_to_charge || 0);
        }
    }
}

export class StopInvoice
{
    address: Address = new Address();
    is_pickup: boolean = true;
    time_scheduled:Date = new Date();
    title: string = '';

    constructor(props?: StopInvoice)
    {
        if (props)
        {
            this.address = new Address(props.address);
            this.is_pickup = props.is_pickup;
            this.time_scheduled = new Date(props.time_scheduled);
            this.title = props.title;
        }
    }
}

export class TransportationInvoice
{
    vehicle_order_id?: number = null;
    amount: number = 0;
    pickup: StopInvoice = new StopInvoice();
    dropoff: StopInvoice = new StopInvoice();
    amount_to_charge: number = 0;
    amount_remaining: number = 0;

    constructor(props?: TransportationInvoice)
    {
        if (props)
        {
            this.vehicle_order_id = props.vehicle_order_id;
            this.amount = props.amount;
            this.pickup = new StopInvoice(props.pickup);
            this.dropoff = new StopInvoice(props.dropoff);
            this.amount_to_charge = props.amount_to_charge || 0;
            this.amount_remaining = props.amount_remaining;
        }
    }
}

export class VehicleInvoice
{
    vin: string = '';
    description: string = '';
    vehicle_order_id?: number = null;

    constructor(props?: VehicleInvoice)
    {
        if (props)
        {
            this.vin = props.vin;
            this.description = props.description;
            this.vehicle_order_id = props.vehicle_order_id;

        }
    }
}

export class VehicleOrderInvoice
{
    service_charges: ServiceChargeInvoice[] = [];
    transportation_charges: TransportationInvoice = new TransportationInvoice();
    vehicle: VehicleInvoice = new VehicleInvoice();

    constructor(props?: VehicleOrderInvoice)
    {
        if (props)
        {
            this.service_charges = props.service_charges.map(x => new ServiceChargeInvoice(x));
            this.transportation_charges = new TransportationInvoice({...props.transportation_charges, vehicle_order_id: props.vehicle.vehicle_order_id});
            this.vehicle = new VehicleInvoice(props.vehicle);
        }
    }
}

export class CustomerInvoice extends Customer
{
    billing_address: Address = new Address();

    constructor(props?: CustomerInvoice)
    {
        super(props);
        if (props)
        {
            this.billing_address = new Address(props.billing_address);
        }
    }
}

export class Reference
{
    key: string = '';
    value: string = '';

    constructor(props?: Reference)
    {
        if (props)
        {
            this.key = props.key;
            this.value = props.value;
        }
    }
}


export class Invoice
{
    id?: number = null;
    customer: CustomerInvoice = new CustomerInvoice();
    contacts: CustomerProfile[] = [];
    service_charges: ServiceChargeInvoice[] = [];
    vehicle_orders: VehicleOrderInvoice[] = [];
    references: Reference[] = [];
    date_due: string | Date = new Date();
    date_issued: string | Date = new Date();

    constructor(props?: Invoice)
    {
        if (props)
        {
            this.id = props.id;
            this.customer = new CustomerInvoice(props.customer);
            this.references = props.references.filter(x => x.value != '' || x.key != '').map(x => new Reference(x));
            this.service_charges = props.service_charges.map(x => new ServiceChargeInvoice(x));
            this.contacts = props.contacts.map(x => new CustomerProfile(x));
            this.vehicle_orders = props.vehicle_orders.map(x => new VehicleOrderInvoice(x));
            this.date_due = new Date(props.date_due);
            this.date_issued = new Date(props.date_issued);
        }
    }
}

export class BankAccount {
    id?: number;
    type: string;
    name: string;
    bank: string;
    note: string;
    active: boolean;

    constructor(props?: any) {
        if (props) {
            this.id = props.id || 0;
            this.type = props.type || '';
            this.name = props.name || '';
            this.bank = props.bank || '';
            this.note = props.note || '';
            this.active = props.active || false;
        }
    }
}

export class Flag
{
    too_dirty:  boolean = false;
    too_dark: boolean = false;
    raining: boolean = false;

    constructor(props?: Flag)
    {
        if (props)
        {
            this.too_dirty = props.too_dirty;
            this.too_dark = props.too_dark;
            this.raining = props.raining;
        }
    }
}

export class Signature
{
    time: string | Date = new Date();
    name: string = '';
    uid?: string = '';
    user_id?: number = null;

    constructor(props?: Signature)
    {
        if (props)
        {
            this.time = new Date(props.time);
            this.name = props.name;
            this.uid = props.uid;
            this.user_id = props.user_id;
        }
    }
}

export class RequiredPhoto
{
    uid?: string = '';
    type: string = '';
    user_id?: string  = '';

    constructor(props?: RequiredPhoto)
    {
        if (props)
        {
            this.uid = props.uid;
            this.type = props.type;
            this.user_id = props.user_id;
        }
    }
}

export class Point
{
    x: number = 0;
    y: number =  0;

    constructor(props?: Point) {
        if (props)
        {
            this.x = props.x;
            this.y = props.y;
        }
    }
}

export class Damage
{
    point: Point = new Point();
    code: string = '';
    uid?: string = '';

    constructor(props?: Damage)
    {
        if (props)
        {
            this.point = new Point(props.point);
            this.code = props.code;
            this.uid = props.uid;
        }
    }
}

export class Accessorie
{
    name: string = '';
    count: number = 0;
    user_id?: number = null;

    constructor(props?: Accessorie)
    {
        if (props)
        {
            this.name = props.name;
            this.count = props.count;
            this.user_id = props.user_id;
        }
    }
}

export class PendingInspection
{
    id?: number = null;
    pending_inspection_id?: number = null;
    note: string = '';
    flags: Flag[] = [];
    odometer: number = 0;
    performed_by: Signature = new Signature();
    signed_by: Signature = new Signature();
    time: string | Date = new Date();
    vehicle_data: Vehicle = new Vehicle();
    photos: RequiredPhoto[] = [];
    damages: Damage[] = [];
    accessories: Accessorie[] = [];

    constructor(props?: PendingInspection) {
        if (props)
        {
            this.id = props.id;
            this.pending_inspection_id = props.pending_inspection_id;
            this.note = props.note;
            this.flags = props.flags.map(x => new Flag(x));
            this.odometer = props.odometer;
            this.performed_by = new Signature(props.performed_by);
            this.signed_by = new Signature(props.signed_by);
            this.vehicle_data = new Vehicle(props.vehicle_data);
            this.photos = props.photos.map(photo => new RequiredPhoto(photo));
            this.damages = props.damages.map(d => new Damage(d));
            this.accessories = props.accessories.map(a => new Accessorie(a));
        }
    }
}


export class CarrierMove {
    name: string = '';
    driver: string = '';
    velox_order_id?: number = null;
    velox_id?: number = null;

    constructor (props?: CarrierMove)
    {
        if (props)
        {
            this.name = props.name;
            this.driver = props.driver;
            this.velox_order_id = props.velox_order_id;
            this.velox_id = props.velox_id;
        }
    }
}
export class InventoryMove
{
    id?: number = null;
    incoming: boolean = true;
    time_due: string | Date = new Date();
    completed: boolean = false;
    warehouse_id?: number = null;
    carrier: CarrierMove = new CarrierMove();
    note: string = '';
    vehicle_order_id?: number = null;
    stop_id?: number = null;

    constructor(props?: InventoryMove)
    {
        if (props)
        {
            this.incoming = props.incoming;
            this.time_due = new Date(props.time_due);
            this.completed = props.completed;
            this.warehouse_id = props.warehouse_id;
            this.carrier = new CarrierMove(props.carrier);
            this.note = props.note;
            this.id = props.id;
            this.vehicle_order_id = props.vehicle_order_id;
            this.stop_id = props.stop_id;
        }
    }
}

import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import ICONS from "../../../assets/svg";
import { Card, Invoice } from "../../classes/classes";
import Helper from "../../helper/Helper";
import CreditCardForm from "../CreditCardForm/CreditCardForm";
import FormGroup from "../FormGroup/FormGroup";

import OrderInvoicePdf from "../../pages/ReceivablesPage/Invoice/OrderInvoicePdf";
import useLoading from "../../hooks/useLoading/useLoading";
import { gql } from "apollo-boost";
import { GraphqlService } from "../../services/graphql.service";
import { useToasts } from "react-toast-notifications";
import { PaymentInfo, PaymentSelecction } from "./classes/classes";
import DateTimePicker from "../DateTimePicker/DateTimePicker";
import './classes/style.scss';

const ProcessPayment = ({ onClose, onPaymentSubmitted, data }) => {
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>(
    new PaymentInfo()
  );
  const [invoiceData, setInvoiceData] = useState(null);
  const [pickerOpen, setPickerOpen] = useState(false);

  const [bankAccounts, setBankAccounts] = useState([]);
  const [cards, setCards] = useState([]);
  const [previousBalances, setPreviousBalances] = useState([]);
  const [dragOver, setDragOver] = useState(false);

  const loading = useLoading();
  const toast = useToasts();

  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (data.mastersID.length == 0) return;
    const p = new PaymentInfo(data);
    p.mastersID = data?.mastersID;
    setPaymentInfo(p);
    if (!p.id) {
      loadBankAccounts().then();
      loadPreviousBalance(data.customer_id, data.payment_left).then();
      loadCards(data.customer_id).then();
      if (data.mastersID.length == 1)
        getInvoice(data.mastersID[0], data).then();
      else loadDefaultValues(data).then();
    }
  }, [data]);

  async function loadDefaultValues(pInfo = paymentInfo) {
    let settingQuery = gql(`query {
            all_settings {
              key
              value
            }
          }`);

    //   alert(+null as any);

    try {
      loading.open("Getting default bank account...");
      const data = await GraphqlService.SendQuery(settingQuery);

      let p = { ...pInfo };

      // //console.log(data);
      p.bank_account_id = data.find(
        (x) => x.key == "MERCHANT_BANK_ACCOUNT"
      )?.value;

      if (p.bank_account_id) p.bank_account_id = +p.bank_account_id;

      // console.log(p);

      setPaymentInfo(p);
      //   alert('here');

      loading.close();
    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
      setPaymentInfo(pInfo);
    }
  }

  async function uploadPaymentFile() {
    const mutation = gql`
      mutation ($payment_id: Int, $file: Upload) {
        upload_payment_file(payment_id: $payment_id, file: $file) {
          message
          success
        }
      }
    `;

    try {
      loading.open("Uploading file...");
      // console.log({payment_id: paymentInfo.id, file: files[0]});
      const data = await GraphqlService.SendMutation(mutation, {
        payment_id: paymentInfo.id,
        file: files[0],
      });
      // loading.close();
      // //console.log(data);
      loading.close();
      if (!data?.success) {
        throw new Error(data?.message || "Something went wrong");
      }
      onClose();
      setPaymentInfo(new PaymentInfo());
      setFiles([]);

      toast.addToast(data.message, {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (ex) {
      console.log("ex", ex.message);
      loading.close();
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
    }
  }

  async function dropHandler(ev) {
    // console.log('File(s) dropped');
    setDragOver(false);

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    if (files.length == 1) {
      return toast.addToast(`You can't upload more than one file.`, {
        appearance: "warning",
        autoDismiss: true,
      });
    }
    // if
    const temp = [...files];

    if (ev.dataTransfer.items && ev.dataTransfer.items.length > 0) {
      if (ev.dataTransfer.items.length > 1) {
        toast.addToast(
          `You can't upload more than one file, (It will take just one).`,
          { appearance: "warning", autoDismiss: true }
        );
      }
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < 1; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === "file") {
          var file = ev.dataTransfer.items[i].getAsFile();
          const blob = new Blob([file], { type: file.type });
          (blob as any).name = file.name;

          temp.push(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < 1; i++) {
        // console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
        temp.push(file);
      }
    }
    setFiles(temp);
  }

  async function loadCards(customer_id) {
    let cardQuery = gql`
      query ($customer_id: Int) {
        attributes_by_customerId(customer_id: $customer_id) {
          id
          cardholder_name
          number
          type
          expired
        }
      }
    `;

    // console.log('here');
    setCards([]);
    try {
      const data = await GraphqlService.SendQuery(cardQuery, { customer_id });
      setCards(data);
    } catch (ex) {
      console.log("ex", ex.message);
      setCards([]);
    }
  }

  async function loadBankAccounts() {
    try {
      setBankAccounts([]);

      const query = gql`
        query {
          get_bank_accounts {
            id
            name
            bank
            note
            last_4
            active
          }
        }
      `;
      const data = await GraphqlService.SendQuery(query);
      setBankAccounts([...data]);
    } catch (ex) {
      console.log("ex.message", ex.message);
    }
  }

  async function loadPreviousBalance(customer_id, totalLeft) {
    setPreviousBalances([]);
    try {
      const query = gql`
        query ($customer_id: Int) {
          get_customer_remaining_credits(customer_id: $customer_id) {
            id
            time
            type
            amount
            amount_left
            bank_account_id
          }
        }
      `;
      const data = await GraphqlService.SendQuery(query, { customer_id });

      // console.log(totalLeft);
      setPreviousBalances([...data].filter((x) => x.amount_left >= +totalLeft));

      // data

      // console.log(data)
      // //console.log(data);
    } catch (ex) {
      console.log("ex.message", ex.message);
    }
  }

  async function getInvoice(order_id, pInfo) {
    try {
      loading.open("Getting invoice...");
      setInvoiceData(null);
      const mutation = gql`
        query ($order_id: Int) {
          get_master_order_invoice_charge(master_order_id: $order_id) {
            customer {
              id
              name
              billing_address {
                city
                state
                street
                country
                postal_code
                street_number
                postal_code_suffix
              }
            }
            date_issued
            date_due
            references {
              key
              value
            }
            contacts {
              id
              email
              name
              title
              contact {
                title
                value
                type
              }
            }
            vehicle_orders {
              service_charges {
                id
                amount
                tax_amount
                base_amount
                amount_remaining
                name
              }

              vehicle {
                vin
                description
                vehicle_order_id
              }
              transportation_charges {
                amount
                amount_remaining
                pickup {
                  title
                  address {
                    city
                    state
                    street
                    country
                    postal_code
                  }
                  is_pickup
                  time_scheduled
                }
                dropoff {
                  title
                  address {
                    city
                    state
                    street
                    country
                    postal_code
                  }
                  is_pickup
                  time_scheduled
                }
              }
            }
            service_charges {
              id
              amount
              tax_amount
              base_amount
              name
              amount_remaining
            }
          }
        }
      `;
      const data = await GraphqlService.SendQuery(mutation, { order_id });
      setInvoiceData(new Invoice(data));

      loading.close();
      loadDefaultValues(pInfo);
    } catch (ex) {
      console.log("ex", ex.message);
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
      loading.close();
    }
  }

  async function submitPayment() {
    try {
      loading.open("Submitting payment...");
      const mutation =
        paymentInfo.mastersID.length == 1
          ? gql`
              mutation (
                $master_order_id: Int
                $service_payments: [service_payment_input]
                $type: String
                $payment: Int
                $transportation_payments: [transportation_payments_input]
                $bank_account_id: Int
                $previous_payment_id: Int
                $reference: String
                $time_paid: String
              ) {
                post_manual_single_payment(
                  master_order_id: $master_order_id
                  payment: $payment
                  service_payments: $service_payments
                  type: $type
                  transportation_payments: $transportation_payments
                  bank_account_id: $bank_account_id
                  previous_payment_id: $previous_payment_id
                  reference: $reference
                  time_paid: $time_paid
                ) {
                  id
                  message
                  success
                }
              }
            `
          : gql`
              mutation (
                $master_order_ids: [Int]
                $total: Float
                $payment: Int
                $type: String
                $bank_account_id: Int
                $previous_payment_id: Int
                $reference: String
                $time_paid: String
              ) {
                post_manual_payment(
                  master_order_ids: $master_order_ids
                  total: $total
                  payment: $payment
                  type: $type
                  bank_account_id: $bank_account_id
                  previous_payment_id: $previous_payment_id
                  reference: $reference
                  time_paid: $time_paid
                ) {
                  id
                  message
                  success
                }
              }
            `;

      let card =
        paymentInfo.type == "CREDIT CARD" && paymentInfo.credit_id == null
          ? {
              customer_id: paymentInfo.customer_id,
              cardholder_name: paymentInfo.cardInfo.cardholder_name,
              number: paymentInfo.cardInfo.number,
              type: paymentInfo.cardInfo.type,
              expiration: paymentInfo.cardInfo.exp,
              cvc: paymentInfo.cardInfo.cvv,
              zip: paymentInfo.cardInfo.zip,
              country: paymentInfo.cardInfo.country,
              street: paymentInfo.cardInfo.street,
              city: paymentInfo.cardInfo.city,
              state: paymentInfo.cardInfo.state,
              id: paymentInfo.cardInfo.id,
            }
          : null;

      const variables =
        paymentInfo.mastersID.length == 1
          ? {
              master_order_id: paymentInfo.mastersID[0],
              service_payments: paymentInfo.paymentSelections.service_payments,
              type: paymentInfo.type,
              payment: card?.id,
              transportation_payments:
                paymentInfo.paymentSelections.transportation_payments,
              bank_account_id: +paymentInfo.bank_account_id,
              previous_payment_id:
                paymentInfo.credit_id != null ? +paymentInfo.credit_id : null,
            }
          : {
              master_order_ids: paymentInfo.mastersID,
              total: +paymentInfo.amount_to_pay,
              payment: card?.id,
              type: paymentInfo.type,
              note: paymentInfo.notes,
              bank_account_id: paymentInfo.bank_account_id,
              previous_payment_id:
                paymentInfo.credit_id != null ? +paymentInfo.credit_id : null,
            };

      const data = await GraphqlService.SendMutation(mutation, variables);

      if (!data?.success)
        throw new Error(data?.message || "Something went wrong");
      toast.addToast(data.message, {
        appearance: "success",
        autoDismiss: true,
      });

      // //console.log(data);
      let p = new PaymentInfo(paymentInfo);
      p.id = data.id;

      setPaymentInfo(p);
      onPaymentSubmitted();
      loading.close();
    } catch (ex) {
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
      loading.close();
    }
  }

  return (
    <>
      {!paymentInfo.id && (
        <div className="flex-1-scroll">
          {paymentInfo.cardModalOpened ? (
            <div className="pt-3 px-3">
              <CreditCardForm
                customerID={paymentInfo.customer_id}
                onCancelClicked={(evt) => {
                  setPaymentInfo({
                    ...paymentInfo,
                    cardModalOpened: false,
                    cardInfo: evt,
                  });
                }}
                onCardSaved={(evt) => {
                  setPaymentInfo({
                    ...paymentInfo,
                    cardInfo: evt,
                    cardModalOpened: false,
                  });
                  setCards([...cards, evt]);
                }}
              />
            </div>
          ) : (
            <>
              <div className="row p-3" style={{position: "sticky", top: -1}}>
                <h4>
                  Pay for masters:{" "}
                  {paymentInfo.mastersID.map((x) => "M-" + x).join(", ")}
                </h4>
                <button
                  onClick={onClose}
                  className="btn btn-clear text-gray p-0 align-items-center"
                >
                  <img src={ICONS.IconCloseBig} alt="" />
                </button>
              </div>

              <div className="flex-1-scroll">
                <div className="row">
                  {paymentInfo.mastersID.length == 1 && invoiceData && (
                    <div
                      className="col-12"
                      style={{
                        display: paymentInfo.paymentPickerOpened
                          ? "block"
                          : "none"
                      }}
                    >
                      <OrderInvoicePdf
                        master_id={paymentInfo.mastersID[0]}
                        data={invoiceData}
                        onPayClicked={(evt) =>
                          setPaymentInfo({
                            ...paymentInfo,
                            paymentPickerOpened: false,
                            paymentSelections: new PaymentSelecction(evt),
                            amount_to_pay: evt.totalToCharge.toString(),
                          })
                        }
                      />
                    </div>
                  )}

                  {!paymentInfo.paymentPickerOpened && (
                    <div className="row col-12 px-3">
                      {previousBalances.length > 0 && (
                        <FormGroup
                          value={paymentInfo.credit_id?.toString()}
                          onTextChange={(evt) => {
                            const obj = {
                              ...paymentInfo,
                              credit_id: evt == "" ? null : +evt,
                            };
                            // setPaymentInfo({...paymentInfo, credit_id: evt == ''? null : +evt});
                            if (evt != "") {
                              const pb = previousBalances.find(
                                (x) => x.id == +evt
                              );
                              obj.type = pb.type ?? paymentInfo.type;
                              obj.bank_account_id =
                                pb.bank_account_id ?? paymentInfo.type;
                              console.log(pb);
                            }

                            setPaymentInfo(obj);
                          }}
                          colSize={12}
                          name="credit"
                          type="select"
                          label="Pay with previous balance"
                          required
                          placeholder="-- Select --"
                          options={{
                            data: previousBalances,
                            label: (x) =>
                              `P-${x.id}, ${Helper.FORMAT.USDate(
                                x.time
                              )}, Original Amount: ${Helper.FORMAT.USCurrency(
                                x.amount
                              )}, Credit Left: ${Helper.FORMAT.USCurrency(
                                x.amount_left
                              )}`,
                            value: (x) => x.id.toString(),
                          }}
                        />
                      )}
                      <FormGroup
                        disabled={paymentInfo.credit_id != null}
                        value={paymentInfo.type}
                        onTextChange={(evt) => {
                          const pi = { ...paymentInfo, type: evt };

                          if (evt == "CREDIT CARD") loadDefaultValues(pi);
                          else setPaymentInfo(pi);
                        }}
                        colSize={6}
                        name="type"
                        type="select"
                        label="Type"
                        required
                        placeholder="-- Select --"
                        options={{
                          data: [
                            { label: "CASH", value: "CASH" },
                            { label: "CREDIT CARD", value: "CREDIT CARD" },
                            { label: "CHECK", value: "CHECK" },
                          ],
                          label: (x) => x.label,
                          value: (x) => x.value,
                        }}
                      />
                      <FormGroup
                        disabled={
                          bankAccounts.length == 0 ||
                          paymentInfo.credit_id != null
                        }
                        value={paymentInfo.bank_account_id?.toString()}
                        onTextChange={(evt) =>
                          setPaymentInfo({
                            ...paymentInfo,
                            bank_account_id: evt == "" ? null : +evt,
                          })
                        }
                        colSize={6}
                        name="bank_account"
                        type="select"
                        label="Bank Account / Credit Card Account"
                        required
                        placeholder="-- Select --"
                        options={{
                          data: bankAccounts,
                          label: (x) => `${x.name} **${x.last_4} - ${x.bank}`,
                          value: (x) => x.id,
                        }}
                      />

                      {/* {paymentInfo.cardInfo && <h4 className='font-16'>*{paymentInfo.cardInfo.number.substring(paymentInfo.cardInfo.number.length - 4)} ({paymentInfo.cardInfo.type})</h4>} <img src={ICONS.IconPlus} alt="" /> */}
                      {/* {(paymentInfo.type == 'CREDIT CARD' && paymentInfo.credit_id == null) && <button  onClick={(evt) => setPaymentInfo({...paymentInfo, cardModalOpened: true})} className='btn btn-blue-light-outline font-12 row col-12 align-items-center mb-3' style={{marginTop: '-1rem'}}>

                    <FormGroup name={'CC'} />
            </button>} */}
                      {paymentInfo.type == "CREDIT CARD" &&
                        paymentInfo.credit_id == null && (
                          <FormGroup
                            label={"Credit Card"}
                            required
                            type="select"
                            name="credit_card"
                            value={paymentInfo.cardInfo.id?.toString() || ""}
                            placeholder="-- SELECT --"
                            options={{
                              data: ["** Add New Card **", ...cards],
                              label: (x, i) =>
                                i == 0
                                  ? x
                                  : `${x.cardholder_name} ${x.type} *${x.number}`,
                              value: (x, i) =>
                                i == 0 ? x : x.id ? x.id.toString() : null,
                            }}
                            onTextChange={(evt) => {
                              if (evt == "** Add New Card **") {
                                setPaymentInfo({
                                  ...paymentInfo,
                                  cardModalOpened: true,
                                });
                              } else if (evt == "") {
                                setPaymentInfo({
                                  ...paymentInfo,
                                  cardInfo: new Card(),
                                });
                              } else {
                                setPaymentInfo({
                                  ...paymentInfo,
                                  cardInfo: {
                                    ...paymentInfo.cardInfo,
                                    id: +evt,
                                  },
                                });
                              }
                            }}
                          />
                        )}

                      <FormGroup
                        value={paymentInfo.reference}
                        onTextChange={(evt) =>
                          setPaymentInfo({ ...paymentInfo, reference: evt })
                        }
                        colSize={6}
                        name="reference"
                        type="text"
                        label="Reference"
                        placeholder="Reference..."
                      />
                      <FormGroup
                        readOnly
                        onClick={(evt) => setPickerOpen(true)}
                        className="position-relative"
                        value={moment(paymentInfo.time_paid).format(
                          "MM/DD/YYYY hh:mm:ss A"
                        )}
                        required
                        colSize={6}
                        name="reference"
                        type="text"
                        label="Paid Time"
                        placeholder="Paid on..."
                      >
                        <i className="far fa-calendar-alt"></i>
                      </FormGroup>

                      <>
                        <FormGroup
                          disabled={paymentInfo.mastersID.length == 1}
                          value={paymentInfo.amount_to_pay}
                          hintText={
                            "Amount to pay must be more or equals than " +
                            (paymentInfo.mastersID.length == 1
                              ? "$0.00"
                              : Helper.Masks.ValidUSMoney(
                                  +paymentInfo.payment_left
                                ))
                          }
                          errorText={
                            "Amount to pay must be more or equals than " +
                            Helper.Masks.ValidUSMoney(+paymentInfo.payment_left)
                          }
                          validation={(evt) =>
                            paymentInfo.mastersID.length == 1 ||
                            +evt >= +paymentInfo.payment_left
                          }
                          onTextChange={(evt) =>
                            setPaymentInfo({
                              ...paymentInfo,
                              amount_to_pay: Helper.Masks.DecimalNumbers(evt),
                            })
                          }
                          colSize={6}
                          name="type"
                          type="text"
                          label="Amount to pay"
                          required
                          placeholder="0.00"
                          moneySymbol
                        />
                        <FormGroup
                          moneySymbol
                          colSize={6}
                          name="payment_left"
                          type="text"
                          value={paymentInfo.payment_left}
                          disabled
                          label="Payment Left"
                          required
                          placeholder="0.00"
                        />
                      </>

                      {paymentInfo.mastersID.length == 1 && (
                        <FormGroup
                          className="mb-0"
                          colSize={12}
                          name="notes"
                          type="textarea"
                          value={paymentInfo.notes}
                          onTextChange={(notes) =>
                            setPaymentInfo({ ...paymentInfo, notes })
                          }
                          label="Notes (optional)"
                          placeholder="Notes..."
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>

              {!paymentInfo.paymentPickerOpened && (
                <div className="row p-3">
                  <button
                    className="btn btn-danger col-6 py-2"
                    onClick={(evt) =>
                      paymentInfo.mastersID.length == 1
                        ? setPaymentInfo({
                            ...paymentInfo,
                            paymentPickerOpened: true,
                          })
                        : onClose(evt)
                    }
                  >
                    {paymentInfo.mastersID.length == 1 ? "GO BACK" : "CANCEL"}
                  </button>
                  <button
                    className="btn btn-blue-light col-6 py-2"
                    disabled={
                      (paymentInfo.mastersID.length == 1
                        ? +paymentInfo.amount_to_pay < 0
                        : +paymentInfo.amount_to_pay <
                          +paymentInfo.payment_left) ||
                      paymentInfo.type == "" ||
                      paymentInfo.bank_account_id == null ||
                      (paymentInfo.type == "CREDIT CARD" &&
                        paymentInfo.cardInfo.id == null)
                    }
                    onClick={(evt) => submitPayment()}
                  >
                    SUBMIT
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      )}

      {paymentInfo.id && paymentInfo.type == "CHECK" && (
        <div className="flex-1-container p-3">
          <div className="row mb-3">
            <h4>Upload file for payment #{paymentInfo.id}</h4>
            <button
              onClick={onClose}
              className="btn btn-clear text-gray p-0 align-items-center"
            >
              <img src={ICONS.IconCloseBig} alt="" />
            </button>
          </div>

          <div className="flex-1-container">
            <div>
              {files.length == 0 && (
                <div
                  className={
                    "border-drag-files" + (dragOver ? " drag-over" : "")
                  }
                  style={{ width: "100%", borderRadius: "1rem" }}
                  id="drop_zone"
                  onDrop={dropHandler}
                  onDragOver={(evt) => {
                    evt.preventDefault();
                    setDragOver(true);
                  }}
                  onDragLeave={(evt) => {
                    evt.preventDefault();
                    setDragOver(false);
                  }}
                >
                  <p className="text-center py-4">
                    {dragOver
                      ? "Drop here to receive the file"
                      : "Drag one file to this Drop Zone..."}
                  </p>
                </div>
              )}

              <div className="mt-3">
                <h4 className="font-14 mb-3">Files</h4>

                {files.map((f, i) => (
                  <h4 className="font-14 mb-3 d-flex align-items-center">
                    <span
                      onClick={(evt) => {
                        const temp = [...files];
                        temp.splice(i, 1);
                        setFiles(temp);
                      }}
                    >
                      <i className="fas fa-times text-red cursor-pointer mr-2"></i>
                    </span>{" "}
                    {f.name}
                  </h4>
                ))}
              </div>
            </div>
          </div>

          {
            <div className="row mt-3">
              <button className="btn btn-danger col-6 py-2" onClick={onClose}>
                CANCEL
              </button>
              <button
                className="btn btn-blue-light col-6 py-2"
                onClick={(evt) => uploadPaymentFile()}
              >
                SUBMIT
              </button>
            </div>
          }
        </div>
      )}

      {pickerOpen && (
        <DateTimePicker
          date={paymentInfo.time_paid}
          onCancelClicked={(evt) => setPickerOpen(false)}
          onDateSubmitted={(evt) => {
            setPaymentInfo({ ...paymentInfo, time_paid: evt });
            setPickerOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ProcessPayment;

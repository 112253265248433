import {ResultWithLink} from "../../customer_profile/customer_profile";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";

export const update_setting = (input:{key: string, value: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($key: String, $value: String) {
      update_setting(key: $key, value: $value) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {key, value} as 
  Promise<ResultWithLink>;
}
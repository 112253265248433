import IReducerAction from "../models/IReducerAction";
import {UserSettings} from "../../classes/classes";
import UserSettingsType from "../types/UserSettings.type";





const initialState: UserSettings = {
    driver_view_order: []
}

export default function UserSettingsReducer(state: UserSettings = initialState, action: IReducerAction): UserSettings
{
    switch (action.type)
    {
        case UserSettingsType.SET_USER_SETTINGS:
            return {
                ...action.payload
            }
        default:
            return state;
    }
}
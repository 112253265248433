import React from 'react';
import CarriersDriversTable from '../../components/CarriersDriversTable/CarriersDriversTable';

const CarriersPage = () => {
    return <div className='flex-1-container bg-white p-3 rounded'>
        <CarriersDriversTable preventSelected onCarrierSelected={(evt) => {

        }} />
    </div>
}

export default CarriersPage;
import React, { useReducer } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { closeLoading, openLoading } from '../../redux/actions/Loading.action';
import { AllStates } from '../../redux/reducers';
// import { reducerLoading } from '../../providers/LoadingProvider';

let initialState = {open: false, text: ''};

export function reducerLoading(state = initialState, action) {
         
        switch (action.type) {
            case 'open':
                initialState = {open: true, text: action.payload};
                break;
            case 'close':
                initialState = {open: false, text: ''};
                break;
            default:
             return state;
        }
        return initialState;
    }
const useLoading = () => {


    // const dispatch = useSelector<AllStates>();
    const dispatch = useDispatch();

    const open = (text?: string) => {
        dispatch(openLoading(text));
    }

    const close = () => {
        dispatch(closeLoading())
    }
    return  {
        open,
        close
    }

}

export default useLoading;
import {GraphqlService} from "../../../../graphql.service";
import {gql} from "apollo-boost";
import {Payable_Request} from "../payable_request";

export const get_all_payable_requests = (input:{}) : Promise<Payable_Request[]> => {
  let query = gql`
    query {
      get_all_payable_requests {
        id
        time
        time_due
        type
        reference
        note
        amount
        category
        payable_to
        payable {
          id
          time
          type
          reference
          note
          amount
          category
          time_paid
          checks {
            amount
            payable_to
            number
            reference
            memo
            time
            bank_account {
              id
              name
              note
              bank
              active
              last_4
            }
            time_created
            void
          }
          carrier {
            id
            name
          }
          driver {
            id
            name
            email
            phones
            velox_id
          }
          employee {
            id
            name
            address {
              city
              state
              street
              country
              postal_code
              street_number
              postal_code_suffix
            }
            permission
            department
            active
            note
          }
          payee {
            id
            name
            tax_info {
              type
              value
            }
            address {
              city
              state
              street
              country
              postal_code
              street_number
              postal_code_suffix
            }
            notes
          }
          bank_account {
            id
            name
            note
            bank
            active
            last_4
          }
        }
        user {
          id
          name
          level
          active
        }
        payee {
          id
          name
          order_ids
        }
        bank_account_id
        payable_id
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Payable_Request[]>;
}
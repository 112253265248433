import {StandardResult} from "../../leg/leg";
import {gql} from "apollo-boost";
import {Vehicle_Order_Input, Vehicle_Order_Update_Input} from "../vehicle_order";
import {GraphqlService} from "../../../../graphql.service";
import {Master_Order_Input} from "../../master_order/master_order";
import {Upload} from "../../upload";

export const update_vehicle_order = (input:{vehicle_order_id: number, vehicle_order: Vehicle_Order_Update_Input}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($vehicle_order_id: Int, $vehicle_order: vehicle_order_update_input) {
      update_vehicle_order(vehicle_order_id: $vehicle_order_id, vehicle_order: $vehicle_order) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {vehicle_order_id, vehicle_order} as 
  Promise<StandardResult>;
}

export const create_master_from_vehicle_order = (input:{vehicle_order_ids: number[], master_order: Master_Order_Input}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($vehicle_order_ids: Int[], $master_order: master_order_input) {
      create_master_from_vehicle_order(vehicle_order_ids: $vehicle_order_ids, master_order: $master_order) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {vehicle_order_ids, master_order} as 
  Promise<StandardResult>;
}

export const create_master_from_leg = (input:{leg_ids: number[], master_order: Master_Order_Input}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($leg_ids: Int[], $master_order: master_order_input) {
      create_master_from_leg(leg_ids: $leg_ids, master_order: $master_order) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {leg_ids, master_order} as 
  Promise<StandardResult>;
}

export const upload_vehicle_order_file = (input:{vehicle_order_id: number, name: String, file: Upload}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($vehicle_order_id: Int, $name: String, $file: Upload) {
      create_master_from_leg(vehicle_order_id: $vehicle_order_id, name: $name, file: $file) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {vehicle_order_id, name, file} as 
  Promise<StandardResult>;
}

export const delete_vehicle_order_file = (input:{vehicle_order_file_id: number}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($vehicle_order_file_id: Int) {
      delete_vehicle_order_file(vehicle_order_file_id: $vehicle_order_file_id) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {vehicle_order_file_id} as 
  Promise<StandardResult>;
}

export const add_vehicle_order_to_master_order = (input:{master_order_id: number, vehicle_order: Vehicle_Order_Input}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($master_order_id: Int, $vehicle_order: vehicle_order_input) {
      add_vehicle_order_to_master_order(master_order_id: $master_order_id, vehicle_order: $vehicle_order) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {master_order_id, vehicle_order} as 
  Promise<StandardResult>;
}

export const cancel_vehicle_order = (input:{vehicle_order_id: number, note: string}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($vehicle_order_id: Int!, $note:String!) {
      cancel_vehicle_order(vehicle_order_id: $vehicle_order_id, note: $note) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {vehicle_order_id, vehicle_order} as 
  Promise<StandardResult>;
}

import React, { useRef } from 'react';
import Searchbar from '../../../../components/Searchbar/Searchbar';

const CustomerContacts = () => {

    const contactRef = useRef();

    return <div>
        <div className="row mb-2 align-items-center">
            <h4 className="font-11">Contacts (2)</h4>
            <button className="btn btn-danger-light">CLEAR</button>
        </div>

        <Searchbar placeholder={'Search contacts...'} background={'#F3F6F8'} value={''} reference={contactRef} />

        <ul className='mt-2-5' style={{listStyle: 'none'}}>
                <li>
                    <p className="font-9 font-bold">• BMW Agent</p>
                </li>
                <li>
                    <p className="font-9 font-bold">• BMW Agent</p>
                </li>
            </ul>
    </div>
}

export default CustomerContacts;
import {Carrier} from "../carrier/carrier";
import {Driver} from "../driver/driver";
import {Employee} from "../employee/employee";
import {Address} from "../location/location";
import {Tax_Info} from "../customer/customer";
import {Bank_Account} from "../payment/payment";

export class Payable {
    __typename?: "Payable"
    id?: number = null;
    time?: string = '';
    type?: string = '';
    reference?: string = '';
    note?: string = '';
    amount?: number = 0;
    category?: string = '';
    time_paid?: string = '';
    checks?: Check[] = [];
    carrier?: Carrier = new Carrier();
    driver?: Driver = new Driver();
    employee?: Employee = new Employee();
    payee?: Payee = new Payee();
    bank_account?: Bank_Account = new Bank_Account();

    constructor(x?: Payable) {
        if(x){
            this.id = x.id;
            this.time = x.time;
            this.type = x.type;
            this.reference = x.reference;
            this.note = x.note;
            this.amount = x.amount;
            this.category = x.category;
            this.time_paid = x.time_paid;
            this.checks = x.checks?.map(z => z) || [];
            this.carrier = new Carrier(x.carrier);
            this.driver =  new Driver(x.driver);
            this.employee =  new Employee(x.employee);
            this.payee =  new Payee(x.payee);
            this.bank_account = x.bank_account;            
        }
    }
}

export class Check {
    __typename?: "Check"
    amount?: number = 0;
    payable_to?: string = '';
    number?: number = 0;
    reference?: string = '';
    memo?: string = '';
    time?: string = '';
    bank_account?: Bank_Account = new Bank_Account();
    time_created?: string ='';
    void?: boolean = false;

    constructor(x?: Check){
        if(x){
            this.amount = x.amount;
            this.payable_to = x.payable_to;
            this.number = x.number;
            this.reference = x.reference;
            this.memo = x.memo;
            this.time = x.time;
            this.bank_account = new  Bank_Account(x.bank_account);
            this.time_created = x.time_created;
            this.void = x.void;            
        }
    }
}

export class Payee {
    __typename?: "Payee"
    id?: number = null;
    name?: string = '';
    tax_info?: Tax_Info = new Tax_Info();
    address?: Address = new Address();
    notes?: string = '';

    constructor(x?: Payee){
        if(x) {
            this.id = x.id;
            this.name = x.name;
            this.tax_info =  x.tax_info;
            this.address = x.address;
            this.notes = x.notes;            
        }
    }
}

export class Tax_Input {
    type?: string = '';
    value?: string = '';
    constructor(x?: Tax_Input) {
        if(x) {
            this.type = x.type;
            this.value = x.value;
        }
    }
}

export class Check_Input {
    amount?: number = 0;
    payable_to?: string = '';
    number?: number = 0;
    reference?: string = '';
    memo?: string = '';
    time?: string = '';

    constructor(x?: Check_Input){
        if(x){
            this.amount = x.amount;
            this.payable_to = x.payable_to;
            this.number = x.number;
            this.reference = x.reference;
            this.memo = x.memo;
            this.time = x.time;            
        }
    }
}

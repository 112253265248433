import React, {useState, useRef, useEffect} from 'react'
import {Customer, CustomerList, Leg, Location, Quote, Stop} from '../../classes/classes'
import DriverCardJob from '../../components/DriverCardJob/DriverCardJob'
import DriverScheduleComponent from '../../components/DriverSchedule/DriverSchedule'
import LegTable from '../../components/LegTable/LegTable'
import LegTrip from '../../components/LegTrip/LegTrip'
import SideActionManager from '../../components/SideActionManager/SideActionManager'
import VehicleOrderDispatch from '../../components/VehicleOrderDispatch/VehicleOrderDispatch'
import QuoteDetail from "../../components/QuoteDetail/QuoteDetail";
import {GraphqlService} from "../../services/graphql.service";
import QuoteQueries from "../../graphql/query/Quote";
import {Simulate} from "react-dom/test-utils";
import Helper from "../../helper/Helper";
import QuotesTable from "../../components/QuotesTable/QuotesTable";
import Loading from "../../components/Loading/Loading";
import ReactModal from "react-modal";
import QuoteMutations from "../../graphql/mutation/Quote";
import {useToasts} from "react-toast-notifications";
import CustomerSelection from '../../components/CustomerSelection/CustomerSelection'
import ICONS from '../../../assets/svg'
import PickupCustomer from '../../components/NewOrder/PickupCustomer/PickupCustomer'
import gql from 'graphql-tag'
import useLoading from '../../hooks/useLoading/useLoading'



interface INewOrderInfo {
    customer: CustomerList,
    profiles_id: { customer_profile_id: number }[],
    quote_id: number,
    pickup: Stop,
    dropoff: Stop,
    location: Location
}

enum CustomerPicker {
    NONE,
    CUSTOMER,
    PICKUP,
    PICKUP_TIME,
    DROPOFF,
    DROPOFF_TIME
}


const QuotesPage =()=>{


    const [picker, setPicker] = useState(CustomerPicker.NONE);
    const [newOrderInfo, setNewOrderInfo] = useState<INewOrderInfo>({
        customer: new CustomerList(),
        profiles_id: [],
        quote_id: null,
        pickup:  new Stop(),
        dropoff: new Stop({is_pickup: false} as any),
        location: new Location()
    });
    // setNewOrderInfo({...newOrderInfo, ...evt});
    // setPicker(CustomerPicker.CUSTOMER);
    const [selected, setSelected] = useState(-1);

    const [quote, setQuote] = useState(new Quote());



    const [reload, setReload] = useState(false);

    const loading = useLoading();


    const toast = useToasts();

    useEffect(() => {

    }, []);


    async function createNewMaster(order){
        // console.log(order);
        // setPicker(CustomerPicker.LOADING);
        loading.open(`Creating order from quote #${newOrderInfo.quote_id}...`)
        const mutation = gql`
                mutation($quote_id:Int, $billing:Address_new_quote_order, $contacts: [quote_order_contacts], $pickup:quote_stop_input, $dropoff: quote_stop_input){
                        create_master_order_from_quote_by_user(
                        quote_id:$quote_id,
                            contacts: $contacts
                            pickup:$pickup
                            dropoff:$dropoff
                            billing_address:$billing
                        ){
                            id
                            message
                            success
                }
                }
        `;

        const variables = {
            "quote_id":newOrderInfo.quote_id,
            "billing": newOrderInfo.location.address,
            "contacts":newOrderInfo.profiles_id.map(x => ({id: x.customer_profile_id})),
            "pickup":{
               "contacts": newOrderInfo.pickup.contacts.map(x => ({id: x.id})),
               "location":{
                  "title":newOrderInfo.pickup.location.title,
                  "address": newOrderInfo.pickup.location.address,
                  "driver_note": newOrderInfo.pickup.location.driver_note
               }
            },
            "dropoff":{
                "contacts": newOrderInfo.dropoff.contacts.map(x => ({id: x.id})),
                "location":{
                   "title":newOrderInfo.dropoff.location.title,
                   "address": newOrderInfo.dropoff.location.address,
                   "driver_note": newOrderInfo.dropoff.location.driver_note
                }
            }
         }


         try {
             const data = await GraphqlService.SendMutation(mutation, variables);
             if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            setPicker(CustomerPicker.NONE);
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            setReload(true);
         } catch (ex) {
             setPicker(CustomerPicker.DROPOFF);
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
         }
    }
    async function updatePrice(quote)
    {
        try {
            // setLoadingText('Updating price for quote #' + quote.id + '...');
            loading.open('Updating price for quote #' + quote.id + '...');
            const data = await GraphqlService.SendMutation(QuoteMutations.UPDATE_PRICE, {quote});

            if (!data.success)
                throw new Error(data.message);
            loading.close();
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            setReload(true);

        }
        catch (ex)
        {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: false});
        }
    }

    async function archiveQuote(quote_id: number)
    {
        try {
            loading.open('Archiving quote #' + quote.id + '...');
            const data = await GraphqlService.SendMutation(QuoteMutations.ARCHIVE, {quote_id});

            if (!data.success)
                throw new Error(data.message);
            // setLoadingText('');
            loading.close();
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            setReload(true);

        }
        catch (ex)
        {
            loading.close();
            // setLoadingText('');
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: false});
        }
    }

    return(
        <>
            {/* <h1 className='col-12'>Dispatch</h1> */}
            <div className="flex-1-container row-container rounded" >
                <div className='col-8 d-flex bg-white p-3' >
                    <QuotesTable onConvertToOrderClicked={(quote_id) => {
                        setNewOrderInfo({...newOrderInfo, quote_id});
                        setPicker(CustomerPicker.CUSTOMER)
                    }} reload={reload} onReloadAccepted={evt => setReload(false)} quoteSelected={quote} onQuoteSelected={quote => setQuote(quote)} />
                </div>

                <div className="col-4 d-flex justify-content-between bg-white rounded" >
                    <QuoteDetail reloadRequired={() => {

                        setReload(true);
                    }} quote={quote} onArchive={(quote_id) => archiveQuote(quote_id)} onSendPrice={(quote) => updatePrice({
                            id: quote.id,
                            vehicle_info: [...quote.vehicle_info],
                            price: quote.price,
                            note: quote.note
                        })
                    } />
                </div>

            </div>

            {/*<Loading />*/}




            <ReactModal isOpen={picker != CustomerPicker.NONE}>
                {picker == CustomerPicker.CUSTOMER && <CustomerSelection onCancel={()=> setPicker(CustomerPicker.NONE)} topRightDiv={<img className='cursor-pointer' onClick={(evt) => {

                    setPicker(CustomerPicker.NONE);
                    setNewOrderInfo({
                        profiles_id: [],
                        customer: new CustomerList(),
                        quote_id: null,
                        location: new Location(),
                        dropoff: new Stop({is_pickup: false} as any),
                        pickup: new Stop()
                    });
                    setPicker(CustomerPicker.NONE)
                }} src={ICONS.IconCloseBig} style={{marginTop: '-.45rem'}}/>}
                                   onCustomerSelected={(c, stops, profiles) => {
                                       console.log(stops)
                                       const no = {
                                           ...newOrderInfo,
                                           location: stops.location,
                                           customer: new CustomerList(c),
                                           profiles_id: profiles.map(x => ({customer_profile_id: x.id}))
                                       };
                                       setNewOrderInfo(no);
                                       setPicker(CustomerPicker.PICKUP);
                                   }}/>}


            {picker == CustomerPicker.PICKUP &&  <PickupCustomer onConfirmClicked={(pickup) => {setNewOrderInfo({...newOrderInfo, pickup: new Stop(pickup)}); setPicker(CustomerPicker.DROPOFF)}} stop={newOrderInfo.pickup} onCancelClicked={(evt) => setPicker(CustomerPicker.CUSTOMER)} />}
            {picker == CustomerPicker.DROPOFF &&  <PickupCustomer onConfirmClicked={(dropoff) => {

                let no = {...newOrderInfo, dropoff: new Stop(dropoff)};
                setNewOrderInfo(no);
                createNewMaster(no);

                }} stop={newOrderInfo.dropoff} onCancelClicked={(evt) => setPicker(CustomerPicker.PICKUP)} />}


            </ReactModal>


        </>
    )
}

export default QuotesPage;

import { gql } from 'apollo-boost';

import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Address, Customer, Location } from '../../../classes/classes';
import CustomerMutations from '../../../graphql/mutation/Customer';
import Helper from '../../../helper/Helper';
import useLoading from '../../../hooks/useLoading/useLoading';
import { GraphqlService } from '../../../services/graphql.service';
import FormGroup from '../../FormGroup/FormGroup';
import AddressSearch from '../../Google/AddressSearch';
import LocationFormWorkHours from '../../LocationFormWorkHours/LocationFormWorkHours';

const states = Helper.States;

const EditBillingAddress = ({customer, onCancel, onSubmit}) => {
    const [address, setAddress] = useState<Address>(new Address());
    const [myLocation, setMyLocation] = useState<Location>(new Location());



    const toast = useToasts();
    const loading = useLoading();

    useEffect(() => {
        let temp = JSON.parse(JSON.stringify(location || new Location()))

        console.log(location);
        setMyLocation({...temp, id: temp.id, driver_note: temp.driver_note, work_hours: temp.work_hours, internal_note: temp.internal_note, title: temp.title});
        setAddress({...customer.billing_address});
    }, [location]);

    const saveLocation = async (location: Location) => {
        try {
           
            

            loading.open('Saving location...');
            // const data = await GraphqlService.SendMutation(CustomerMutations.ADD_LOCATION_TO_CUSTOMER, {location: l});
            
            // location.id = data.id;

            loading.close();
            // toast.addToast('Location Created with ID: #' + data.id, {appearance: 'success', autoDismiss: true});

            setMyLocation({...location});
            // onSubmit({...location});
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error'});
        }
    }

    const updateLocation = async (location: Location) => {
        try {
            let updateLocation = gql`
                mutation($location: locationInput_update) {
                    update_location_by_id(location: $location) {
                        id
                    }
                }
            `;


            loading.open('Updating location...');

            // const data = await GraphqlService.SendMutation(updateLocation, {
            //     "location": {
            //         "location_id": location?.id,
            //         "title": location?.title,
            //         "driver_note": location?.driver_note,
            //         "internal_note": location?.internal_note,
            //         "customer_id": location?.customer?.id,
            //         "address": {
            //             "city": location?.address.city,
            //             "state": location?.address.state,
            //             "street": location?.address.street,
            //             "country": location?.address.country,
            //             "postal_code": location?.address.postal_code,
            //             "street_number": location?.address.street_number,
            //             "postal_code_suffix": location?.address.postal_code_suffix
            //         },
            //         "work_hours": {
            //             "weekdays": {
            //                 "from": location?.work_hours?.weekdays?.from,
            //                 "to": location?.work_hours?.weekdays?.to,
            //             },
            //             "weekends": {
            //                 "from": location?.work_hours?.weekends?.from,
            //                 "to": location?.work_hours?.weekends?.to,
            //             }
            //         }
            //     }
            // });

            loading.close();
            toast.addToast('Location Updated', {appearance: 'success', autoDismiss: true});

            setMyLocation({...location});
            // onSubmit({...location});
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error'});
        }
    }

    async function handleOnSubmit() {
        try {
            loading.open('Updating billing address...');
            const mutation = gql`mutation($address:location_address_update, $customer_id:Int){
                update_billing_address(customer_id:$customer_id, address:$address){
                  id
                  message
                  success
                }
              }`;

              const data = await GraphqlService.SendMutation(mutation, {customer_id: customer.id, address})
              if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
              loading.close();
              toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
              onSubmit(new Address(address));
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    const isValid = () => myLocation.title != '' && address.city != '' && address.street != '' &&
        address.street_number != '' && address.state != '' && address.postal_code != '' && address.country != '';

    return(
        <>
            <div className="flex-1-container">
                <div className="col-12 mb-3">
                    <p className="font-12 font-medium mb-1">BILLING ADDRESS</p>
                    <p className='font-11'>#{customer.id} {customer.name}</p>
                    {/* { myLocation.id != null && <p className="font-12 font-medium mb-3">UPDATE LOCATION</p> } */}
                </div>
                <div className="flex-1-container">
                    <div className="row">
                <div className="col-12 mb-2">
                    <label className="font-10">Country*</label>
                </div>

                <div className="col-6 mb-2">
                    <button className={`btn btn-block ${address.country == 'United States' ? 'btn-country-selected' : 'btn-country'}`}
                            onClick={() => setAddress({...address, country: 'United States'})}>United States</button>
                </div>
                <div className="col-6 mb-2">
                    <button className={`btn btn-block ${address.country == 'Canada' ? 'btn-country-selected' : 'btn-country'}`}
                            onClick={() => setAddress({...address, country: 'Canada'})}>Canada</button>
                </div>



                <AddressSearch className="col-12" onGotAddress={(newAddress) =>{
                    setAddress(newAddress);
                }}/>

                <FormGroup label={'Street #'}
                           colSize={4}
                           value={address.street_number}
                           name={'streetNumber'}
                           placeholder={'Street #'}
                           onTextChange={value => setAddress({...address, street_number: value})}/>

                <FormGroup label={'Street'}
                           colSize={8}
                           value={address.street}
                           name={'street'}
                           placeholder={'Street'}
                           onTextChange={value => setAddress({...address, street: value})}/>

                <FormGroup label={'City'}
                           name={'city'}
                           value={address.city}
                           placeholder={'City'}
                           onTextChange={value => setAddress({...address, city: value})}/>

                <FormGroup label={'State'}
                           name={'state'}
                           colSize={6}
                           value={address.state}
                           type={'select'}
                           placeholder={'-- Select a state --'}
                           options={{data: states, value: x => x, label: x => x}}
                           onTextChange={value => setAddress({...address, state: value})}/>
                        
                <FormGroup label={'Zip'}
                           colSize={6}
                           name={'zip'}
                           value={address.postal_code}
                           placeholder={'XXXXX'}
                           onTextChange={value => setAddress({...address, postal_code: value})}/>

                <FormGroup label={'Country'}
                            name={'country'}
                            value={address.country}
                            placeholder={'Country'}
                            onTextChange={value => setAddress({...address, country: value})}/>
            </div>
            
            </div>
            </div>
            <div className="row">
                <div className="col-6 mr-3">
                    <button className="btn btn-block rounded-pill text-light-blue" onClick={onCancel}>CANCEL</button>
                </div>

                <div className="col-6">
                    <button className="btn btn-block btn-blue-light rounded-pill"
                            disabled={!isValid()}
                            onClick={() => {
                                handleOnSubmit();
                            }}>UPDATE</button>
                </div>
            </div>
        </>)
}

export default EditBillingAddress;
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {Developer} from "../developer";

export const all_developers = (input:{}) : Promise<Developer[]> => {
  let query = gql`
    query {
      all_developers {
        id
        name
        active
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Developer[]>;
};
import React, { FC } from 'react';

interface IOrderDetailsEntry
{
    index: number
}

const OrderDetailsEntry: FC<IOrderDetailsEntry> = (props) => {

    

    return <div className={'flex-1-container'}>
        <h4 className="font-11">Details for Vehicle {props.index + 1}</h4>
        <hr className="my-3" />

        <div className="flex-1-container">
            <div className="row">
                {/* VEHICLE INFO */}
                <div className="col-6">
                    <h6 className="font-9 font-normal mb-2-5">VIN Type*</h6>
                    <div className="row">
                        <button className={`px-0 btn btn-bg-blue-light col-6 py-2 rounded-pill d-flex justify-content-between px-2`}>
                            <div>US STANDARD</div>
                            <div>17 DIGITS</div>
                        </button>
                        <button className={`px-0 btn btn-gray-light col-6 py-2 rounded-pill d-flex justify-content-between px-2`}>
                            <div>SPECIAL VIN</div>
                            <div>ANY DIGITS</div>
                        </button>
                        <button className={`px-0 btn btn-gray-light col-6 py-2 rounded-pill mt-3 text-left px-2`}>NO VIN`</button>
                    </div>
                </div>
                {/* ORDER INFO */}
            </div>
        </div>
    </div>
}

export default OrderDetailsEntry;
import { gql, ObservableQuery } from "apollo-boost";
import { GraphqlService, ObservableQueryT } from "../../services/graphql.service";
import NotificationTypes from "../types/Notifications.type";


let notificationsQuery: ObservableQueryT;
const query = gql`
    query{
        get_all_notifications{
            id
            title
            time
            message
            type
        }
    }
`
export default function getAllNotifications()
{
    return dispatch => {
        dispatch(getAllNotificationsAction(true));
        if (!notificationsQuery)
        {
            notificationsQuery = GraphqlService.SendQueryObservable(query);
            notificationsQuery.onResults.subscribe(({data, error}) => {
                if (error)
                    dispatch(getAllNotificationsErrorAction(error));
                else
                    dispatch(getAllNotificationsSuccessAction(data));
            })
        }
        else
        {
            notificationsQuery.refetch();
        }
    }
}

export function stopNotifications() {
    return dispatch => {
        notificationsQuery?.stopPolling();
    }
}

const getAllNotificationsAction = (payload) => ({type: NotificationTypes.GET_ALL_NOTIFICATIONS_STARTED, payload});

const getAllNotificationsSuccessAction = (payload) => ({type: NotificationTypes.GET_ALL_NOTIFICATIONS_SUCCESS, payload});

const getAllNotificationsErrorAction = (payload) => ({type: NotificationTypes.GET_ALL_NOTIFICATIONS_ERROR, payload});


export function setLastID(id: number)
{
    return dispatch =>
    {
        localStorage.setItem('last_id', id.toString());
        dispatch(setLastIDAction(+id));
    } 
}

const setLastIDAction = payload => ({type: NotificationTypes.SET_LAST_ID, payload});

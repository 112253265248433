import Table from "../Table/Table";
import Searchbar from "../Searchbar/Searchbar";
import {FC, useEffect, useRef, useState} from "react";
import IColumn from "../Table/models/IColumn";
import { gql } from "apollo-boost";
import { GraphqlService } from "../../services/graphql.service";
import { CustomerProfile, Order } from "../../classes/classes";
import Helper from "../../helper/Helper";
import { PaymentInfo } from "../ProcessPayment/classes/classes";
import ICONS from "../../../assets/svg";
import ReactModal from "react-modal";
import SendInvoices from "../SendInvoices/SendInvoices";
import ProcessPayment from "../ProcessPayment/ProcessPayment";
import LegTable from "../LegTable/LegTable";
import OrdersTable from "../OrdersTable/OrdersTable";

const query = gql`
    query($customer_id: Int){
        get_open_master_order_by_customer(customer_id: $customer_id) 
{
            id
            time
            delivered
            started
            completed
            price
            payment_left
            last_invoice_amount
            needs_invoice
            terms
            non_move
            picked_up
            payment_due
            invoices{
                id
                time
                time_due
            }
            references{
                key
                value
            }
           
            customer{
                id
                name
                corporate_client
                terms
                billing_address{
                city
                state
                street
                country
                postal_code
                street_number
                postal_code_suffix
            }
            }
            contacts{
                id
                name
                email
                contact{
                    title
                    value
                    type
                }
            }
        }
    }
`

const columns: IColumn[] = [
    {
        label: 'MASTER',
        active: false,
        orderBy: 'DESC',
        name: 'master_column',
        sort_by: 'master_id'
    },
    {
        label: 'INVOICE #',
        active: false,
        orderBy: 'DESC',
        name: 'invoice_column',
        sort_by: 'first_invoice_id'
    },
    {
        label: 'CONTACT',
        active: false,
        orderBy: 'DESC',
        name: 'contact'
    },
    {
        label: 'SENT ON',
        active: false,
        orderBy: 'DESC',
        name: 'sent_on'
    },

    {
        label: 'DUE',
        active: false,
        orderBy: 'DESC',
        name: 'due'
    },

    {
        label: 'TERMS',
        active: false,
        orderBy: 'DESC',
        name: 'terms'
    },
    {
        label: 'CURRENT INVOICE PRICE',
        active: false,
        orderBy: 'DESC',
        name: 'last_invoice_price',
        sort_by: 'last_invoice_amount'
    },
    {
        label: 'BALANCE AMOUNT',
        active: false,
        orderBy: 'DESC',
        name: 'balance_left',
        sort_by: 'balance_left_amount'
    },
    {
        label: 'STATUS',
        active: false,
        orderBy: 'DESC',
        name: 'status'
    }];

interface IOrderList {
    customer_id: number
}

enum InvoiceStatuses {
    ALL,
    NOT_SENT
}

enum Tabs {
    OPEN,
    LEG_HISTORY,
    ORDER_HISTORY
}

const OrderList: FC<IOrderList> = (props) => {
    const ref = useRef();
    const refInv = useRef();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermInv, setSearchTermInv] = useState('');
    const [orders, setOrders] = useState([]);
    const [paymentInfo, setPaymentInfo] = useState(new PaymentInfo());
    const [emails, setEmails] = useState([]);
    const [ordersLoading, setOrdersLoading] = useState(false);
    const [masterIDS, setMasterIDS] = useState([]);
    const [invoiceStatus, setInvoiceStatus] = useState(InvoiceStatuses.ALL);
    const tabs = [
        {
            label: 'Open Orders',
            value: Tabs.OPEN
        },
        {
            label: 'Order History',
            value: Tabs.ORDER_HISTORY
        },
        {
            label: 'Leg History',
            value: Tabs.LEG_HISTORY
        }
    ];
    
    const [tab, setTab] = useState(Tabs.OPEN);
    
    async function loadOrders() {
        setOrdersLoading(true);
        try {
            let data = await GraphqlService.SendQuery(query, {customer_id: props.customer_id});
            data = data.map(x => {
                if (x.invoices == null)
                    x.invoices = [];
                // console.log(e.invoice_id)

                x.invoices = x.invoices.sort((x, y) => x.id < y.id ? 1 : -1);
                x.first_invoice_id = x.invoices[0]?.id ?? 0;
                x._ref = x.references.map(x => `${x.key}: ${x.value}`);

                if (x.invoices.length > 2) {

                    x.due = [Helper.FORMAT.USDate(x.invoices[0].time_due, 'do'), Helper.FORMAT.USDate(x.invoices[1].time_due, 'do'), '+' + (x.invoices.length - 2)];
                    x.sent_on = [Helper.FORMAT.USDate(x.invoices[0].time, 'do'), Helper.FORMAT.USDate(x.invoices[1].time, 'do'), '+' + (x.invoices.length - 2)];
                    x.invoices_formatted = ['I-' + x.invoices[0].id, 'I-' + x.invoices[1].id, '+' + (x.invoices.length - 2)];
                }

                // if (x.invoices.length > 2)


                else if (x.invoices.length >= 1) {

                    x.due = x.invoices.map(y => Helper.FORMAT.USDate(y.time_due, 'do'));
                    x.sent_on = x.invoices.map(y => Helper.FORMAT.USDate(y.time, 'do'));
                    x.invoices_formatted = x.invoices.map(x => 'I-' + x.id);
                }
                else if (x.invoices.length == 0) {
                    x.due = ['-'];
                    x.sent_on = ['-'];
                    x.invoices_formatted = ['-'];
                }

                const defaultEmails = (x.contacts as CustomerProfile[]).map((x: CustomerProfile) => [x.email, ...x.contact?.filter(x => x.type?.toLowerCase() == 'email').map(x => x.value)]).flatMap(x => x);

                return  {
                    ...x,
                    needs_invoice: x.needs_invoice,
                    master_column: ['M-' + x.id, x.non_move ? 'NON MOVE' : (x.delivered ? 'DELIVERED' : (x.picked_up ? 'PICKED UP' : 'NOT PICKED UP'))],
                    master_id: x.id,
                    invoice_column: x.invoices_formatted,
                    first_invoice_id: x.first_invoice_id,
                    time_in: [Helper.FORMAT.USDate(x.time, 'do'), Helper.FORMAT.USDate(x.time, 'to')],
                    customer: x.customer.name,
                    contact: [x.contacts[0]?.name, x.contacts[0]?.email, (x.contacts.length > 1 ?  '+' + (x.contacts.length - 1) : '') ],
                    terms: x.terms || '-',
                    status: x.needs_invoice ? 'NEEDS INVOICE':'SENT',
                    price: Helper.FORMAT.USCurrency(x.price),
                    price_amount: +x.price,
                    balance_left: Helper.FORMAT.USCurrency(x.payment_left),
                    balance_left_amount: +x.payment_left,

                    customer_id: x.customer.id,
                    references: x.references,
                    last_invoice_price: Helper.FORMAT.USCurrency(x.last_invoice_amount),
                    last_invoice_amount: x.last_invoice_amount,

                    default_emails: defaultEmails
                }});

                setOrders(data);
        } catch (error) {
            
        }
        finally {
            setOrdersLoading(false);
        }
    }

    useEffect(() => {
        if (props.customer_id && tab == Tabs.OPEN)
            loadOrders();
    }, [props.customer_id, tab]);

    // function needInvoice(master) {
    //     return master.payment_left > 0 && master.payment_left != master.last_invoice_amount;
    // }

    const ordersFiltered = () => orders.filter((x: any) => {
        // if (search == '' && InvoiceStatuses.ALL == invoiceStatus) return true;
        // console.log(x.invoice_id, searchTermInv);

        return ((x.invoices).map(x => x.id.toString()).includes(searchTermInv) || searchTermInv == '') && (invoiceStatus == InvoiceStatuses.ALL || x.needs_invoice)
            &&
            JSON.stringify(x).toLowerCase().includes(searchTerm.trim().toLowerCase());
    });
    

    return (
        <div className="flex-1-container">
        <div className="d-flex col-12 mb-3">
            {tabs.map(item => <h4 onClick={(evt) => setTab(item.value)} className={"font-10 cursor-pointer mr-2 " + (tab == item.value ? '' : ' text-gray')}>{item.label}</h4>)}
        </div>

        <div className="flex-1-container bg-white p-2">
        {tab == Tabs.OPEN && <>
            <div className="row align-items-start mb-3">
                <div className="col-6">
                    <p className="fs-14 font-medium">{ordersFiltered().length} / {orders.length} orders</p>
                </div>

                <div className="col-6 ">
                
                    <div className="col-12 d-flex align-items-center">
                    <button className="btn btn-icon-only mr-2">
                        <img src={ICONS.IconRefresh} alt="" />
                    </button>
                    <div className="col-12 d-flex">
                        <div className="col-6">
                            <Searchbar background="#F8F8F8" reference={ref} value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)} />
                        </div>
                        <div className='ml-2 col-6'>
                                    <Searchbar value={searchTermInv}
                                           placeholder={'Invoice #...'}
                                           onChange={(evt) => setSearchTermInv(evt.target.value)}
                                           background={'#F8F8F8'}
                                           reference={refInv}
                                           onFocus={(x) => setSearchTerm('')}/>
                            </div>
                    </div>
                    </div>

                    <div className="col-12 d-flex justify-content-end mt-2">

                    <button
                        className={`btn btn-blue-light${invoiceStatus != InvoiceStatuses.ALL ? '-outline' : ''}`}
                        onClick={(evt) => setInvoiceStatus(InvoiceStatuses.ALL)}>ALL
                    </button>
                    <button
                        className={`ml-2 btn btn-blue-light${invoiceStatus != InvoiceStatuses.NOT_SENT ? '-outline' : ''}`}
                        onClick={(evt) => setInvoiceStatus(InvoiceStatuses.NOT_SENT)}>NOT SENT
                    </button>

                    </div>
                </div>
            </div>

            <Table
            showCheckbox 
            loading={ordersLoading}
             menuOptions={[
                {
                    icon: ICONS.IconDetails,
                    label: 'See order details',
                    condition: x => true,
                    action: (x: any) => {
                        console.log(x);
                        let master_id = x.master_id;//.substring(x.master_id.indexOf('-') + 1, x.master_id.length);
                        if (master_id) {
                            Helper.Navigation.NavigateTo('/order/' + master_id);
                        }
                    }
                },
                {
                    icon: ICONS.IconSeeInvoice,
                    label: 'Send Invoice',
                    condition: x => true,
                    multipleRows: true,
                    action: (x: any | any[]) => {
                        // list of checked orders
                        let emails = [];
                        setEmails([]);
                        if (Array.isArray(x))
                        {
                            setMasterIDS(x.map(y => y.row.master_id));
                            emails = (x.map(y => y.row.default_emails).flatMap(y => y))
                        }
                        else
                         {
                             setMasterIDS([x.master_id])
                             emails = (x.default_emails.flatMap(y => y))
                         }

                         setEmails(emails.filter((email, index) => emails.findIndex(z => z == email) == index))


                    }
                }, 
                {
                    icon: ICONS.IconProcessPayment,
                    label: 'Pay Master Order(s)',
                    condition: (x: any | any[]) => {
                        if (Array.isArray(x))
                        {

                            return (x as any[]).filter((y, index, array) => array.findIndex(z => z.row.customer_id == y.row.customer_id)  == index).length == 1;
                        }
                        return true;
                    },
                    multipleRows: true,
                    action: (x: any | any[]) => {
                        // list of checked orders
                        setEmails([]);
                        let ids = [];
                        let totalLeft = 0;
                        let customer_id = null;
                        if (Array.isArray(x))
                        {
                            //    setMasterIDS();
                            ids = x.map(y => +y.row.master_id);
                            totalLeft = x.sumBy(y => y.row.payment_left_amount);
                            customer_id = x[0]?.row?.customer_id;
                        }
                        else
                        {
                            // setMasterIDS()
                            ids = [x.master_id];
                            totalLeft = +x.payment_left_amount;
                            customer_id = x.customer_id;
                        }



                        const paymentInfo = new PaymentInfo({...new PaymentInfo(), ...{mastersID: ids, payment_left: totalLeft.toString(), amount_to_pay: '', type: '', id: null, notes: '', customer_id, paymentPickerOpened: ids.length == 1}});
                        paymentInfo.type = 'CREDIT CARD';




                        setPaymentInfo(paymentInfo);

                        // paymentInfo.bank_account_id = environments.DEFAULT_CC_BANK_ACCOUNT_ID;


                        // setPaymentInfo(paymentInfo);



                    }
                }
            ]}
            tableHeight={'70vh'} onRowClicked={() => {}} columns={columns} rows={ordersFiltered()}/>
            </>}

            {tab == Tabs.LEG_HISTORY && <LegTable
                legID={null}
                onTableRefreshed={() => {}}
                onUndispatchLeg={() => {}}
                onVehicleOrderSelected={() => {}}
                refresh={() => {}}
                vehicleOrderSelected={null}
                showLegHistory
                showFilters={false}
                showToggles={false}
                customerID={props.customer_id}



            />}

            {
                tab == Tabs.ORDER_HISTORY && <OrdersTable customer_id={props.customer_id} />
            }
        </div>
            


<ReactModal isOpen={masterIDS.length > 0}>
                        <SendInvoices defaultEmails={emails} mastersID={masterIDS} onClose={(reload) => {
                            setMasterIDS([]);
                            if (reload)
                                loadOrders();
                        }} />
            </ReactModal>

            <ReactModal style={{content: {padding: 0}}} className='modal-large' isOpen={paymentInfo.mastersID.length > 0}>
                <ProcessPayment data={paymentInfo} onClose={(evt) => {
                        setPaymentInfo(new PaymentInfo())
                }} onPaymentSubmitted={(evt) => loadOrders()} />
            </ReactModal>
        </div>
    );
}

export default OrderList;

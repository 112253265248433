import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {Order_Details_Res, Vehicle_Order, Vehicle_Order_File, Vehicle_Orders_By_Profile_Sp} from "../vehicle_order";
import {Log} from "../../storage_order/storage_order";

export const get_vehicle_order_details = (input:{vehicleorderid: number}) : Promise<Order_Details_Res> => {
  let query = gql`
    query($vehicleorderid: Int) {
      get_vehicle_order_details(vehicleorderid: $vehicleorderid) {
        legs {
          id
          vehicle_order_id
          vehicle_order {
            id
          }
          time
          status
          time_completed
          completed
          canceled
          equipment
          velox_order_id
          stops {
            id
            location {
              id
              customer_id
              title
              internal_note
              driver_note
              timezoneName
            }
            is_pickup
            time_scheduled
            time_completed
            completed
            started
            canceled
            nofail
            contacts {
              id
              email
              verified
              name
              role
            }
            service_charges {
              id
              amount
              tax_amount
              base_amount
              name
              note
              voided
              amount_remaining
            }
            time_frame {
              end
              start
            }
            leg_id
            log {
              user_id
              message
              time
              vehicle_order_id
              leg_id
              stop_id
            }
          }
          service_charges {
            id
            amount
            tax_amount
            base_amount
            name
            note
            voided
            amount_remaining
          }
          driver {
            id
            name
            username
            equipment
            carrier_code
          }
          carrier {
            id
          }
          carrier_pay
          driver_pay
          remaining_carrier_receivable
          remaining_driver_payable
          velox_order {
            id
            accepted
            rejected
            canceled
            expired
            expires
          }
          pending_change_request
        }
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        total_price
        master_order_id
        vehicle_order_id
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vehicleorderid} as 
  Promise<Order_Details_Res>;
}

export const vehicle_order_by_id = (input:{vehicle_order_id: number}) : Promise<Vehicle_Order> => {
  let query = gql`
    query($vehicle_order_id: Int) {
      vehicle_order_by_id(vehicle_order_id: $vehicle_order_id) {
        id
        time
        master_order {
          id
          customer_id
          customer {
            id
            name
            corporate_client
            key
            terms
            internal_note
            active
            tax_number {
              type
              value
            }
            billing_address {
              city
              state
              street
              country
              postal_code
              street_number
              postal_code_suffix
            }
            default_invoice_email
          }
          time
          started
          completed
          price
          payment_left
          terms
          canceled
          contacts {
            id
            email
            verified
            name
            role
            contact {
              title
              value
              type
              unsubscribed
            }
            title
            active
          }
          vehicle_orders {
            id
            time
            bol_comment
            time_changed
            driver_note
            started
            delivered
            vehicle {
              id
              vin
              year
              make
              model
              submodel
              type
              no_vin
              special_vin
              incomplete_vin
              color
              license_plate
              internal_note
              time_created
              user_id
            }
            charges {
              id
              amount
              tax_amount
              base_amount
              name
              note
              voided
              amount_remaining
            }
            storage_order {
warehouse_id
              id
              vehicle_order_id
              billing_cycle
              rate
              date_last_issued
              active
              departure_date
              note
            }
            legs {
              id
              vehicle_order_id
              vehicle_order {
                id
              }
              time
              status
              time_completed
              completed
              canceled
              equipment
              velox_order_id
              stops {
                id
                location {
                  id
                  customer_id
                  title
                  internal_note
                  driver_note
                  timezoneName
                }
                is_pickup
                time_scheduled
                time_completed
                completed
                started
                canceled
                nofail
                contacts {
                  id
                  email
                  verified
                  name
                  role
                }
                service_charges {
                  id
                  amount
                  tax_amount
                  base_amount
                  name
                  note
                  voided
                  amount_remaining
                }
                time_frame {
                  end
                  start
                }
                leg_id
                log {
                  user_id
                  message
                  time
                  vehicle_order_id
                  leg_id
                  stop_id
                }
              }
              service_charges {
                id
                amount
                tax_amount
                base_amount
                name
                note
                voided
                amount_remaining
              }
              driver {
                id
                name
                username
                equipment
                carrier_code
              }
              carrier {
                id
              }
              carrier_pay
              driver_pay
              remaining_carrier_receivable
              remaining_driver_payable
              velox_order {
                id
                accepted
                rejected
                canceled
                expired
                expires
              }
              pending_change_request
            }
            storage_bay {
              id
              label
              storage_section_id
              vehicle_order_id
            }
            inspections {
              id
              user {
                id
                name
              }
              type
              time
            }
            pending_inspection_id
            master_order_id
            warehouse_id
            non_move
          }
          delivered
          picked_up
          non_move
          payment_due
          last_invoice_amount
          needs_invoice
          token
        }
        bol_comment
        time_changed
        driver_note
        started
        delivered
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        charges {
          id
          amount
          tax_amount
          base_amount
          name
          note
          voided
          amount_remaining
        }
        storage_order: Storage_order
        legs {
          id
          vehicle_order_id
          vehicle_order {
            id
          }
          time
          status
          time_completed
          completed
          canceled
          equipment
          velox_order_id
          stops {
            id
            location {
              id
              customer_id
              title
              internal_note
              driver_note
              timezoneName
            }
            is_pickup
            time_scheduled
            time_completed
            completed
            started
            canceled
            nofail
            contacts {
              id
              email
              verified
              name
              role
            }
            service_charges {
              id
              amount
              tax_amount
              base_amount
              name
              note
              voided
              amount_remaining
            }
            time_frame {
              end
              start
            }
            leg_id
            log {
              user_id
              message
              time
              vehicle_order_id
              leg_id
              stop_id
            }
          }
          service_charges {
            id
            amount
            tax_amount
            base_amount
            name
            note
            voided
            amount_remaining
          }
          driver {
            id
            name
            username
            equipment
            carrier_code
          }
          carrier {
            id
          }
          carrier_pay
          driver_pay
          remaining_carrier_receivable
          remaining_driver_payable
          velox_order {
            id
            accepted
            rejected
            canceled
            expired
            expires
          }
          pending_change_request
        }
        storage_bay {
          id
          label
          position {
            x
            y
          }
          storage_section {
            id
            name
            rows
            columns
            position {
              x
              y
            }
            warehouse {
              id
              name
              address {
                street_number
                street
                city
                state
                postal_code
                postal_code_suffix
                country
              }
            }
            columns_format {
              format
              reverse
              starting_value
            }
          }
        }
        inspections {
          id
          user {
            id
            name
          }
          type
          time
        }
        pending_inspection_id
        master_order_id
        warehouse_id
        non_move
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vehicle_order_id} as 
  Promise<Vehicle_Order>;
}

export const vehicle_order_by_velox_order = (input:{velox_order_id: number}) : Promise<Vehicle_Order> => {
  let query = gql`
    query($velox_order_id: Int) {
      vehicle_order_by_velox_order(velox_order_id: $velox_order_id) {
        id
        time
        master_order {
          id
          customer_id
          customer {
            id
            name
            corporate_client
            key
            terms
            internal_note
            active
            tax_number {
              type
              value
            }
            billing_address {
              city
              state
              street
              country
              postal_code
              street_number
              postal_code_suffix
            }
            default_invoice_email
          }
          time
          started
          completed
          price
          payment_left
          terms
          canceled
          contacts {
            id
            email
            verified
            name
            role
            contact {
              title
              value
              type
              unsubscribed
            }
            title
            active
          }
          vehicle_orders {
            id
            time
            bol_comment
            time_changed
            driver_note
            started
            delivered
            vehicle {
              id
              vin
              year
              make
              model
              submodel
              type
              no_vin
              special_vin
              incomplete_vin
              color
              license_plate
              internal_note
              time_created
              user_id
            }
            charges {
              id
              amount
              tax_amount
              base_amount
              name
              note
              voided
              amount_remaining
            }
            storage_order {
warehouse_id
              id
              vehicle_order_id
              billing_cycle
              rate
              date_last_issued
              active
              departure_date
              note
            }
            legs {
              id
              vehicle_order_id
              vehicle_order {
                id
              }
              time
              status
              time_completed
              completed
              canceled
              equipment
              velox_order_id
              stops {
                id
                location {
                  id
                  customer_id
                  title
                  internal_note
                  driver_note
                  timezoneName
                }
                is_pickup
                time_scheduled
                time_completed
                completed
                started
                canceled
                nofail
                contacts {
                  id
                  email
                  verified
                  name
                  role
                }
                service_charges {
                  id
                  amount
                  tax_amount
                  base_amount
                  name
                  note
                  voided
                  amount_remaining
                }
                time_frame {
                  end
                  start
                }
                leg_id
                log {
                  user_id
                  message
                  time
                  vehicle_order_id
                  leg_id
                  stop_id
                }
              }
              service_charges {
                id
                amount
                tax_amount
                base_amount
                name
                note
                voided
                amount_remaining
              }
              driver {
                id
                name
                username
                equipment
                carrier_code
              }
              carrier {
                id
              }
              carrier_pay
              driver_pay
              remaining_carrier_receivable
              remaining_driver_payable
              velox_order {
                id
                accepted
                rejected
                canceled
                expired
                expires
              }
              pending_change_request
            }
            storage_bay {
              id
              label
              storage_section_id
              vehicle_order_id
            }
            inspections {
              id
              user {
                id
                name
              }
              type
              time
            }
            pending_inspection_id
            master_order_id
            warehouse_id
            non_move
          }
          delivered
          picked_up
          non_move
          payment_due
          last_invoice_amount
          needs_invoice
          token
        }
        bol_comment
        time_changed
        driver_note
        started
        delivered
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        charges {
          id
          amount
          tax_amount
          base_amount
          name
          note
          voided
          amount_remaining
        }
        storage_order: Storage_order
        legs {
          id
          vehicle_order_id
          vehicle_order {
            id
          }
          time
          status
          time_completed
          completed
          canceled
          equipment
          velox_order_id
          stops {
            id
            location {
              id
              customer_id
              title
              internal_note
              driver_note
              timezoneName
            }
            is_pickup
            time_scheduled
            time_completed
            completed
            started
            canceled
            nofail
            contacts {
              id
              email
              verified
              name
              role
            }
            service_charges {
              id
              amount
              tax_amount
              base_amount
              name
              note
              voided
              amount_remaining
            }
            time_frame {
              end
              start
            }
            leg_id
            log {
              user_id
              message
              time
              vehicle_order_id
              leg_id
              stop_id
            }
          }
          service_charges {
            id
            amount
            tax_amount
            base_amount
            name
            note
            voided
            amount_remaining
          }
          driver {
            id
            name
            username
            equipment
            carrier_code
          }
          carrier {
            id
          }
          carrier_pay
          driver_pay
          remaining_carrier_receivable
          remaining_driver_payable
          velox_order {
            id
            accepted
            rejected
            canceled
            expired
            expires
          }
          pending_change_request
        }
        storage_bay {
          id
          label
          position {
            x
            y
          }
          storage_section {
            id
            name
            rows
            columns
            position {
              x
              y
            }
            warehouse {
              id
              name
              address {
                street_number
                street
                city
                state
                postal_code
                postal_code_suffix
                country
              }
            }
            columns_format {
              format
              reverse
              starting_value
            }
          }
        }
        inspections {
          id
          user {
            id
            name
          }
          type
          time
        }
        pending_inspection_id
        master_order_id
        warehouse_id
        non_move
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {velox_order_id} as 
  Promise<Vehicle_Order>;
}

export const vehicle_orders = (input:{}) : Promise<Vehicle_Order[]> => {
  let query = gql`
    query {
      vehicle_orders {
        id
        time
        master_order {
          id
          customer_id
          customer {
            id
            name
            corporate_client
            key
            terms
            internal_note
            active
            tax_number {
              type
              value
            }
            billing_address {
              city
              state
              street
              country
              postal_code
              street_number
              postal_code_suffix
            }
            default_invoice_email
          }
          time
          started
          completed
          price
          payment_left
          terms
          canceled
          contacts {
            id
            email
            verified
            name
            role
            contact {
              title
              value
              type
              unsubscribed
            }
            title
            active
          }
          vehicle_orders {
            id
            time
            bol_comment
            time_changed
            driver_note
            started
            delivered
            vehicle {
              id
              vin
              year
              make
              model
              submodel
              type
              no_vin
              special_vin
              incomplete_vin
              color
              license_plate
              internal_note
              time_created
              user_id
            }
            charges {
              id
              amount
              tax_amount
              base_amount
              name
              note
              voided
              amount_remaining
            }
            storage_order {
warehouse_id
              id
              vehicle_order_id
              billing_cycle
              rate
              date_last_issued
              active
              departure_date
              note
            }
            legs {
              id
              vehicle_order_id
              vehicle_order {
                id
              }
              time
              status
              time_completed
              completed
              canceled
              equipment
              velox_order_id
              stops {
                id
                location {
                  id
                  customer_id
                  title
                  internal_note
                  driver_note
                  timezoneName
                }
                is_pickup
                time_scheduled
                time_completed
                completed
                started
                canceled
                nofail
                contacts {
                  id
                  email
                  verified
                  name
                  role
                }
                service_charges {
                  id
                  amount
                  tax_amount
                  base_amount
                  name
                  note
                  voided
                  amount_remaining
                }
                time_frame {
                  end
                  start
                }
                leg_id
                log {
                  user_id
                  message
                  time
                  vehicle_order_id
                  leg_id
                  stop_id
                }
              }
              service_charges {
                id
                amount
                tax_amount
                base_amount
                name
                note
                voided
                amount_remaining
              }
              driver {
                id
                name
                username
                equipment
                carrier_code
              }
              carrier {
                id
              }
              carrier_pay
              driver_pay
              remaining_carrier_receivable
              remaining_driver_payable
              velox_order {
                id
                accepted
                rejected
                canceled
                expired
                expires
              }
              pending_change_request
            }
            storage_bay {
              id
              label
              storage_section_id
              vehicle_order_id
            }
            inspections {
              id
              user {
                id
                name
              }
              type
              time
            }
            pending_inspection_id
            master_order_id
            warehouse_id
            non_move
          }
          delivered
          picked_up
          non_move
          payment_due
          last_invoice_amount
          needs_invoice
          token
        }
        bol_comment
        time_changed
        driver_note
        started
        delivered
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        charges {
          id
          amount
          tax_amount
          base_amount
          name
          note
          voided
          amount_remaining
        }
        storage_order: Storage_order
        legs {
          id
          vehicle_order_id
          vehicle_order {
            id
          }
          time
          status
          time_completed
          completed
          canceled
          equipment
          velox_order_id
          stops {
            id
            location {
              id
              customer_id
              title
              internal_note
              driver_note
              timezoneName
            }
            is_pickup
            time_scheduled
            time_completed
            completed
            started
            canceled
            nofail
            contacts {
              id
              email
              verified
              name
              role
            }
            service_charges {
              id
              amount
              tax_amount
              base_amount
              name
              note
              voided
              amount_remaining
            }
            time_frame {
              end
              start
            }
            leg_id
            log {
              user_id
              message
              time
              vehicle_order_id
              leg_id
              stop_id
            }
          }
          service_charges {
            id
            amount
            tax_amount
            base_amount
            name
            note
            voided
            amount_remaining
          }
          driver {
            id
            name
            username
            equipment
            carrier_code
          }
          carrier {
            id
          }
          carrier_pay
          driver_pay
          remaining_carrier_receivable
          remaining_driver_payable
          velox_order {
            id
            accepted
            rejected
            canceled
            expired
            expires
          }
          pending_change_request
        }
        storage_bay {
          id
          label
          position {
            x
            y
          }
          storage_section {
            id
            name
            rows
            columns
            position {
              x
              y
            }
            warehouse {
              id
              name
              address {
                street_number
                street
                city
                state
                postal_code
                postal_code_suffix
                country
              }
            }
            columns_format {
              format
              reverse
              starting_value
            }
          }
        }
        inspections {
          id
          user {
            id
            name
          }
          type
          time
        }
        pending_inspection_id
        master_order_id
        warehouse_id
        non_move
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Vehicle_Order[]>;
}

export const get_vehicle_orders_by_profile = (input:{profile_id: number, completed: boolean}) : Promise<Vehicle_Orders_By_Profile_Sp[]> => {
  let query = gql`
    query($profile_id: Int, $completed: Boolean) {
      get_vehicle_orders_by_profile(profile_id: $profile_id, completed: $completed) {
        order {
          vehicle
          placed_by
          placed_for
          master_order_id
          vehicle_order_id
        }
        pickup {
          location {
            id
            title
            customer {
              id
              name
              key
            }
            address {
              street_number
              street
              city
              state
              postal_code
              country
            }
          }
          completed
          time_completed
          time_scheduled
        }
        dropoff {
          location {
            id
            title
            customer {
              id
              name
              key
            }
            address {
              street_number
              street
              city
              state
              postal_code
              country
            }
          }
          completed
          time_completed
          time_scheduled
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {profile_id, completed} as 
  Promise<Vehicle_Orders_By_Profile_Sp[]>;
}

export const get_bol_pdf = (input:{vehicle_order_id: number, first_stop: number, last_stop: number, bol_comment: string}) : Promise<string> => {
  let query = gql`
    query($vehicle_order_id: number, $first_stop: number, $last_stop: number, $bol_comment: string) {
      get_bol_pdf(vehicle_order_id: $vehicle_order_id, first_stop: $first_stop, last_stop: $last_stop, bol_comment: $bol_comment)
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vehicle_order_id, first_stop, last_stop, bol_comment} as 
  Promise<string>;
}

export const get_bol_pdf_by_vehicle_order = (input:{vehicle_order_id: number}) : Promise<string> => {
  let query = gql`
    query($vehicle_order_id: Int) {
      get_bol_pdf_by_vehicle_order(vehicle_order_id: $vehicle_order_id)
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vehicle_order_id} as 
  Promise<string>;
}

export const get_bol_pdf_by_vehicle_order_by_customer = (input:{vehicle_order_id: number, customer_id: number}) : Promise<string> => {
  let query = gql`
    query($vehicle_order_id: Int, $customer_id: Int) {
      get_bol_pdf_by_vehicle_order_by_customer(vehicle_order_id: $vehicle_order_id, customer_id: $customer_id)
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vehicle_order_id, customer_id} as 
  Promise<string>;
}

export const get_vehicle_order_files = (input:{vehicle_order_id}) : Promise<Vehicle_Order_File[]> => {
  let query = gql`
    query($vehicle_order_id: Int) {
      get_vehicle_order_files(vehicle_order_id: $vehicle_order_id) {
        id
        name
        uid
        time
        link
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vehicle_order_id} as 
  Promise<Vehicle_Order_File[]>;
}

export const get_all_vehicle_orders = (input:{vehicle_search: string, order_search: string, warehouseID: number}) : Promise<Vehicle_Order[]> => {
  let query = gql`
    query($vehicle_search: String, $order_search: String, $warehouseID: Int) {
      get_all_vehicle_orders(vehicle_search: $vehicle_search, order_search: $order_search, warehouseID: $warehouseID) {
        id
        time
        master_order {
          id
          customer_id
          customer {
            id
            name
            corporate_client
            key
            terms
            internal_note
            active
            tax_number {
              type
              value
            }
            billing_address {
              city
              state
              street
              country
              postal_code
              street_number
              postal_code_suffix
            }
            default_invoice_email
          }
          time
          started
          completed
          price
          payment_left
          terms
          canceled
          contacts {
            id
            email
            verified
            name
            role
            contact {
              title
              value
              type
              unsubscribed
            }
            title
            active
          }
          vehicle_orders {
            id
            time
            bol_comment
            time_changed
            driver_note
            started
            delivered
            vehicle {
              id
              vin
              year
              make
              model
              submodel
              type
              no_vin
              special_vin
              incomplete_vin
              color
              license_plate
              internal_note
              time_created
              user_id
            }
            charges {
              id
              amount
              tax_amount
              base_amount
              name
              note
              voided
              amount_remaining
            }
            storage_order {
warehouse_id
              id
              vehicle_order_id
              billing_cycle
              rate
              date_last_issued
              active
              departure_date
              note
            }
            legs {
              id
              vehicle_order_id
              vehicle_order {
                id
              }
              time
              status
              time_completed
              completed
              canceled
              equipment
              velox_order_id
              stops {
                id
                location {
                  id
                  customer_id
                  title
                  internal_note
                  driver_note
                  timezoneName
                }
                is_pickup
                time_scheduled
                time_completed
                completed
                started
                canceled
                nofail
                contacts {
                  id
                  email
                  verified
                  name
                  role
                }
                service_charges {
                  id
                  amount
                  tax_amount
                  base_amount
                  name
                  note
                  voided
                  amount_remaining
                }
                time_frame {
                  end
                  start
                }
                leg_id
                log {
                  user_id
                  message
                  time
                  vehicle_order_id
                  leg_id
                  stop_id
                }
              }
              service_charges {
                id
                amount
                tax_amount
                base_amount
                name
                note
                voided
                amount_remaining
              }
              driver {
                id
                name
                username
                equipment
                carrier_code
              }
              carrier {
                id
              }
              carrier_pay
              driver_pay
              remaining_carrier_receivable
              remaining_driver_payable
              velox_order {
                id
                accepted
                rejected
                canceled
                expired
                expires
              }
              pending_change_request
            }
            storage_bay {
              id
              label
              storage_section_id
              vehicle_order_id
            }
            inspections {
              id
              user {
                id
                name
              }
              type
              time
            }
            pending_inspection_id
            master_order_id
            warehouse_id
            non_move
          }
          delivered
          picked_up
          non_move
          payment_due
          last_invoice_amount
          needs_invoice
          token
        }
        bol_comment
        time_changed
        driver_note
        started
        delivered
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        charges {
          id
          amount
          tax_amount
          base_amount
          name
          note
          voided
          amount_remaining
        }
        storage_order: Storage_order
        legs {
          id
          vehicle_order_id
          vehicle_order {
            id
          }
          time
          status
          time_completed
          completed
          canceled
          equipment
          velox_order_id
          stops {
            id
            location {
              id
              customer_id
              title
              internal_note
              driver_note
              timezoneName
            }
            is_pickup
            time_scheduled
            time_completed
            completed
            started
            canceled
            nofail
            contacts {
              id
              email
              verified
              name
              role
            }
            service_charges {
              id
              amount
              tax_amount
              base_amount
              name
              note
              voided
              amount_remaining
            }
            time_frame {
              end
              start
            }
            leg_id
            log {
              user_id
              message
              time
              vehicle_order_id
              leg_id
              stop_id
            }
          }
          service_charges {
            id
            amount
            tax_amount
            base_amount
            name
            note
            voided
            amount_remaining
          }
          driver {
            id
            name
            username
            equipment
            carrier_code
          }
          carrier {
            id
          }
          carrier_pay
          driver_pay
          remaining_carrier_receivable
          remaining_driver_payable
          velox_order {
            id
            accepted
            rejected
            canceled
            expired
            expires
          }
          pending_change_request
        }
        storage_bay {
          id
          label
          position {
            x
            y
          }
          storage_section {
            id
            name
            rows
            columns
            position {
              x
              y
            }
            warehouse {
              id
              name
              address {
                street_number
                street
                city
                state
                postal_code
                postal_code_suffix
                country
              }
            }
            columns_format {
              format
              reverse
              starting_value
            }
          }
        }
        inspections {
          id
          user {
            id
            name
          }
          type
          time
        }
        pending_inspection_id
        master_order_id
        warehouse_id
        non_move
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vehicle_search, order_search, warehouseID} as 
  Promise<Vehicle_Order[]>;
}

export const get_all_vehicle_orders_by_customer = (input:{customer_id: number, vehicle_search: string, order_search: string, warehouseID: number}) : Promise<Vehicle_Order[]> => {
  let query = gql`
    query($customer_id: Int, $vehicle_search: String, $order_search: String, $warehouseID: Int) {
      get_all_vehicle_orders_by_customer($customer_id: customer_id, vehicle_search: $vehicle_search, order_search: $order_search, warehouseID: $warehouseID) {
        id
        time
        master_order {
          id
          customer_id
          customer {
            id
            name
            corporate_client
            key
            terms
            internal_note
            active
            tax_number {
              type
              value
            }
            billing_address {
              city
              state
              street
              country
              postal_code
              street_number
              postal_code_suffix
            }
            default_invoice_email
          }
          time
          started
          completed
          price
          payment_left
          terms
          canceled
          contacts {
            id
            email
            verified
            name
            role
            contact {
              title
              value
              type
              unsubscribed
            }
            title
            active
          }
          vehicle_orders {
            id
            time
            bol_comment
            time_changed
            driver_note
            started
            delivered
            vehicle {
              id
              vin
              year
              make
              model
              submodel
              type
              no_vin
              special_vin
              incomplete_vin
              color
              license_plate
              internal_note
              time_created
              user_id
            }
            charges {
              id
              amount
              tax_amount
              base_amount
              name
              note
              voided
              amount_remaining
            }
            storage_order {
warehouse_id
              id
              vehicle_order_id
              billing_cycle
              rate
              date_last_issued
              active
              departure_date
              note
            }
            legs {
              id
              vehicle_order_id
              vehicle_order {
                id
              }
              time
              status
              time_completed
              completed
              canceled
              equipment
              velox_order_id
              stops {
                id
                location {
                  id
                  customer_id
                  title
                  internal_note
                  driver_note
                  timezoneName
                }
                is_pickup
                time_scheduled
                time_completed
                completed
                started
                canceled
                nofail
                contacts {
                  id
                  email
                  verified
                  name
                  role
                }
                service_charges {
                  id
                  amount
                  tax_amount
                  base_amount
                  name
                  note
                  voided
                  amount_remaining
                }
                time_frame {
                  end
                  start
                }
                leg_id
                log {
                  user_id
                  message
                  time
                  vehicle_order_id
                  leg_id
                  stop_id
                }
              }
              service_charges {
                id
                amount
                tax_amount
                base_amount
                name
                note
                voided
                amount_remaining
              }
              driver {
                id
                name
                username
                equipment
                carrier_code
              }
              carrier {
                id
              }
              carrier_pay
              driver_pay
              remaining_carrier_receivable
              remaining_driver_payable
              velox_order {
                id
                accepted
                rejected
                canceled
                expired
                expires
              }
              pending_change_request
            }
            storage_bay {
              id
              label
              storage_section_id
              vehicle_order_id
            }
            inspections {
              id
              user {
                id
                name
              }
              type
              time
            }
            pending_inspection_id
            master_order_id
            warehouse_id
            non_move
          }
          delivered
          picked_up
          non_move
          payment_due
          last_invoice_amount
          needs_invoice
          token
        }
        bol_comment
        time_changed
        driver_note
        started
        delivered
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        charges {
          id
          amount
          tax_amount
          base_amount
          name
          note
          voided
          amount_remaining
        }
        storage_order: Storage_order
        legs {
          id
          vehicle_order_id
          vehicle_order {
            id
          }
          time
          status
          time_completed
          completed
          canceled
          equipment
          velox_order_id
          stops {
            id
            location {
              id
              customer_id
              title
              internal_note
              driver_note
              timezoneName
            }
            is_pickup
            time_scheduled
            time_completed
            completed
            started
            canceled
            nofail
            contacts {
              id
              email
              verified
              name
              role
            }
            service_charges {
              id
              amount
              tax_amount
              base_amount
              name
              note
              voided
              amount_remaining
            }
            time_frame {
              end
              start
            }
            leg_id
            log {
              user_id
              message
              time
              vehicle_order_id
              leg_id
              stop_id
            }
          }
          service_charges {
            id
            amount
            tax_amount
            base_amount
            name
            note
            voided
            amount_remaining
          }
          driver {
            id
            name
            username
            equipment
            carrier_code
          }
          carrier {
            id
          }
          carrier_pay
          driver_pay
          remaining_carrier_receivable
          remaining_driver_payable
          velox_order {
            id
            accepted
            rejected
            canceled
            expired
            expires
          }
          pending_change_request
        }
        storage_bay {
          id
          label
          position {
            x
            y
          }
          storage_section {
            id
            name
            rows
            columns
            position {
              x
              y
            }
            warehouse {
              id
              name
              address {
                street_number
                street
                city
                state
                postal_code
                postal_code_suffix
                country
              }
            }
            columns_format {
              format
              reverse
              starting_value
            }
          }
        }
        inspections {
          id
          user {
            id
            name
          }
          type
          time
        }
        pending_inspection_id
        master_order_id
        warehouse_id
        non_move
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {customer_id, vehicle_search, order_search, warehouseID} as 
  Promise<Vehicle_Order[]>;
}

export const get_order_logs = (input:{vehicle_order_id: number}) : Promise<Log[]> => {
  let query = gql`
    query($vehicle_order_id: Int) {
      get_order_logs(vehicle_order_id: $vehicle_order_id) {
        user_id
        message
        time
        vehicle_order_id
        leg_id
        stop_id
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vehicle_order_id} as 
  Promise<Log[]>;
}

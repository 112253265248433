import { Storage_Order } from "../services/endpoints/schemas/storage_order/storage_order";

export class Attribute{
    id?: number;
    cardholder_name: string;
    number: string;
    type: string;
    expired: Boolean;
}

export class inputAttribute{
    id?: number;
    customer_id?: number;
    cardholder_name: string;
    number: string;
    type: string;
    expiration: string;
    cvc: string;
    zip: string;
    country: string;
    street: string;
    city: string;
    state: string;
    cvv: string;
    exp: string;
}

export class attribute_input {
    id?: number;
    customer_id?: number;
    cardholder_name: string;
    number: string;
    type: string;
    expiration: string;
    cvc: string;
    zip: string;
    country: string;
    street: string;
    city: string;
    state: string;
}

export class Carrier {
    id?: number;
    name: string;
}

export class CarrierContact {
    email: string;
    name: string;
    phone: string;
    // constructor(x?:CarrierContact){
    //     if(x){
    //         this.email= x.email;
    //         this.name= x.name;
    //         this.phone = x.phone;
    //     }
    // }
}

export class Velox_Insurance_agent {
    name: string;
    email: string;
    phone: string;
}

export class Velox_driver{
    id?: number;
    name: string;
    username?: string;
    equipment?: string;
    contacts?: Velox_contact[];
    carrier?: Velox_carrier;
    carrier_code?: string
    photos?: Driver_photo[]

    constructor(x?: Velox_driver){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.username = x.username;
            this.equipment = x.equipment;
            this.contacts = x.contacts;
            this.carrier = x.carrier;
            this.carrier_code = x.carrier_code;
            this.photos = x.photos;
        }
    }
}

export class Driver_photo{
    uid?: string
    name: string
}

export class Velox_contact {
    title: string;
    type: string;
    value: string;
}

export class aging_carrier_sp {
    late: number;
    name:  string;
    picked_up: number;
    carrier_id?: number;
    orders_dispatched: number;
    drivers_dispatched: number;
    valid?: Boolean;
    driver_id?: number;
    completed: number;
}

export class Change_request {
    id?: number;
    velox_order_id?: number;
    vehicle: Vehicle;
    origin: Location;
    destination: Location;
    leg_id?: number;
    accepted: Boolean;
    rejected: Boolean;
    time: string;
    payout: number;
    note: string;
}

export class Leg_Request_Data{
    current: Leg;
    pending: Leg;
    master_order: Master_order;
}

export class Velox_status_input{
    id?: number;
    accepted: Boolean;
    rejected: Boolean;
    canceled: Boolean;
    expired: Boolean;
    expires: string;
    change_requests: Velox_change_request_input[];
}

export class Velox_change_request_input{
    id?: number;
    order_id?: number;
    accepted: Boolean;
    rejected: Boolean;
    time: string;
    payout: number;
    note: string;
    active: Boolean;
    expired: Boolean;
    expires_at: string;
    weblink: string;
    code: string;
}

export class Leg_request_input {
    id?: number;
    vehicle_order: vehicle_order_input;
    carrier: Basic_id_name_input ;
    driver: Basic_id_name_input;
    service_charges: new_charge_input_CD[];
    stops: stopInput[];
    carrier_pay: number;
    driver_pay: number;
    velox_order: Velox_status_input;
}

export class Company {
    id?: number;
    name: string;
    carriers: Carrier[];
}

export class Customer_logs{
    id?: number;
    time: string;
    message: string;
    data: string;
    customer: Customer;
}
export class Customer_profile{
    id?: number;
    email: string;
    verified: Boolean;
    name: string;
    role: string;
    customer: Customer;
    contact: conRes[];
    title: string;
    active: Boolean;
}

export class customerProfileSearchList{
    id?: number;
    name: string;
    email: string;
    title: string;
    number;s: string[];
    contact: conRes[];
}

export class cpRes{
    message: string;
    profile: profileSP;
    success: Boolean;
}

export class conRes{
    title: string;
    value: string;
    type: string;
    unsubscribed: Boolean;
}

export class profileSP{
    id?: number;
    role: string;
    customer_id?: number;
    name: string;
    title: string;
    contact: conRes[];
}

export class profileInput{
    id?: number;
    name: string;
    role: string;
    contact: contactInput[];
    title: string;
    active: Boolean;
    email: string;
}

export class profileInputVerify{
    id?: number;
    name: string;
    password: string;
    contact: contactInput[];
    title: string;
}

export class contactInput{
    title: string;
    value: string;
    type: string;
}


export class dTRes{
    profile: Customer_profile;
    redirect_link: string;
}

export class ResultWithLink{
    id?: number;
    message: string;
    success: Boolean;
    link: string;
}

export class Customer{
    id?: number;
    name: string;
    corporate_client?: Boolean;
    key?: string;
    terms?: string;
    internal_note?: string;
    active?: Boolean;
    tax_number?: Tax_info;
    billing_address?: Address;
    default_invoice_email?: string;
}

export class Customer_Details_Info{
    quoted: number
    needs_quote: number
    needs_invoice: number
    open_orders: number
    recent_payments: number
}

export class Tax_info {
    type: string;
    value: string;
}

export class Customer_location_sp {
    customer_id?: number;
    name: string;
    key: string;
    title: string;
    location_id?: number;
    address: Address;
    corporate_client: Boolean;
    active: Boolean;
}

export class newCustomerRes {
    message: string;
    success: Boolean;
    customer_id?: number;
}



export class customer_input {
    name: string;
    corporate_client: Boolean;
    key: string;
    terms: string;
    internal_note: string;
    location: newCustomer_location;
    customer_profile: profile_input;
    tax_number: tax_info_input;
    default_invoice_email: string;
}

export class tax_info_input {
    type: string;
    value: string;
}

export class update_customer_input {
    name: string;
    corporate_client: Boolean;
    key: string;
    terms: string;
    internal_note: string;
    tax_number: tax_info_input;
    default_invoice_email: string;
    billing_address: address_input;
}

export class newCustomer_location {
    address: address_input;
    driver_note: string;
    internal_note: string;
    work_hours: work_hour_input;
}

export class customer_contact_input{
    type: string;
    title: string;
    value: string;
}

export class address_input{
    city: string;
    state: string;
    street: string;
    country: string;
    postal_code: string;
    street_number: string;
    postal_code_suffix: string;
}

export class profile_input {
    name: string;
    email: string;
    title: string;
    contact: contactInput[];
}


export class customerSearchList{
    id?: number;
    key: string;
    name: string;
    corporate_client: Boolean;
    billing_address: Address;
}

export class Developer{
    id?: number;
    name: string;
    active: Boolean;
}

export class Driver{
    id?: number;
    name: string;
    email: string;
    phones: string[];
    carrier: Carrier;
    equipment: string;
    employee: Employee;
    velox_id?: number;
}

export class Closest_driver {
    velox_id?: number;
    next_stop: Next_stop;
    samsara_id?: string;
    location: t_location;
    driver_id?: number;
    gps: GPS_driver[];
    google: Closest_driver_google;
    name: string;
}

export class Next_stop {
    address_string: string;
    vehicle_description: string;
    time: Date;
}

export class Closest_driver_google{
    regular: Driver_distance;
    direct: Driver_distance;
}

export class GPS_driver {
    Speed: number;
    Course: number;
    Altitude: number;
    Timestamp: string;
    Coordinate:Coordinate_driver;
    SpeedAccuracy: number;
    CourseAccuracy: number;
    VerticalAccuracy: number;
    HorizontalAccuracy: number;
}

export class Coordinate_driver {
    Latitude: number;
    Longitude: number;
}

export class driver_contact_input {
    type: string;
    title: string;
    value: string;
}

export class driver_input {
    id?: number;
    name: string;
    equipment: string;
    email: string;
    phones: string[];
}

export class carrier_driver_res {
    driver: scheduleSP;
}

export class scheduleSP {
    id?: number;
    name: string;
    price: number;
    carrier: string;
    carrier_id?: number;
    status: statusSP;
    equipment: string;
    contacts: Contact[];
    samsara_truck_id?: string;
    itinerary: itinerarySP[];
}

export class itinerarySP {
    orders: orderSP[];
    address: string;
}
export class statusSP {
    text: string;
    text_color: string;
    background_color: string;
    border_color: string;
}
export class orderSP {
    id?: number;
    vehicle: Vehicle;
    order_id?: number;
    is_pickup: Boolean;
    scheduled: string;
    completed: Boolean;
    nofail: Boolean;
    contacts: OrderContact[];
    address: string;
    pending_change_request: Boolean;
    change_requests: Velox_change_request[];
}

export class OrderContact {
    name: string;
    value: string[]
;
}

export class locations_sp {
    id?: number;
    name: string;
    devices: device_location[];
    username: string;
}

export class device_location {
    time: string;
    speed: number;
    course: number;
    device: string;
    altitude: string;
    coordinate: coordinate_location;
    device_info: device_info_location;
    background_mode: Boolean;
}

export class coordinate_location {
    Latitude: string;
    Longitude: string;
}

export class device_info_location {
    name: string;
    type: string;
    idiom: string;
    model: string;
    version: string;
    platform: string;
    manufacturer: string;
}

export class Employee {
    id?: number;
    name: string;
    address: Address;
    user: Users;
    permission: string;
    department: string;
    active: Boolean;
    note: string;
    documents: File[];
    last_payable: Payable;
}

export class employee_input {
    name: string;
    address: address_input;
    department: string;
    note: string;
    tax_info: tax_info_input;
    user?: user_input
}

export class employee_input_update {
    employee_id?: number;
    name: string;
    address: address_input;
    department: string;
    note: string;
    file: any;
    user: user_input
}

export class Inspection{
    id?: number;
    performed_by: Signed_by;
    signed_by: Signed_by;
    user_id?: number;
    note: string;
    odometer: number;
    device: string;
    vehicle: Vehicle;
    accessories: Accessories[];
    additional_emails: string[];
    damages: Damages[];
    photos: Photos[];
    device_info: Device_info;
    flags: Flags[];
    origin_inspection: Origin_inspection;
    destination_inspection: Destination_inspection;
    vehicle_data: Vehicle;
    pending_inspection_id?: number;
    user: Users;
    time: string;
    type: string;
    vehicle_order: Vehicle_order;
}

export class Photos {
    uid?: string;
    type: string;
    user_id?: number;
}

export class Signed_by {
    time: string;
    name: string;
    uid?: string;
    user_id?: number;
}


export class Device_info {
    name: string;
    type: string;
    idiom: string;
    model: string;
    version: string;
    platform: string;
    manufacturer: string;
}

export class Required_photos {
    type: string;
    uid?: string;
}

export class Accessories {
    name: string;
    count: number;
    user_id?: number;
}

export class Damages {
    stop_id?: number;
    point: Point_damages;
    code: string;
    uid?: string;
    is_pickup: Boolean;
}

export class Point_damages {
    x: number;
    y: number;
}

export class Flags {
    too_dirty: Boolean;
    too_dark: Boolean;
    raining: Boolean;
}


export class Origin_inspection{
    origin_id?: number;
    driver_name: string;
    driver_signature: string;
    contact_name: string;
    inspections: Inspection[];
}

export class Destination_inspection{
    destination_id?: number;
    driver_name: string;
    driver_signature: string;
    contact_name: string;
    inspections: Inspection[];
}

export class point_damage{
    x: number;
    y: number;
}

export class Accessories_input {
     name: string;
     count: number;
}

export class Flags_input {
     too_dirty: Boolean;
     too_dark: Boolean;
     raining: Boolean;
}

export class Invoice {
    id?: number;
    time: string;
    time_due: string;
    data: any;
    type: string;
    master_order_id?: number;
    master_order: Master_order;
    logs: Log[];
    amount: number;
}

export class carriers_for_broker_payment {
    name: string;
    total: number;
    over30: number;
    over60: number;
    over90: number;
    current: number;
    delivered: number;
    valid?: Boolean;
    carrier_id?: number;
    payment_terms: number;
    drivers_dispatched: number;
    completed: number;
}

export class Broker {
    id?: number;
    name: string;
}

export class aging_carrier_driver_payable {
    id?: number;
    name: string;
    total: number;
    valid?:Boolean;
    last_payable: Payable;
    delivered: number;
    driver_id?: number;
}

export class Leg{
    id?: number;
    vehicle_order_id?: number;
    vehicle_order: Vehicle_order;
    time: string;
    status: string;
    time_completed: string;
    completed: Boolean;
    canceled: Boolean;
    equipment: string;
    velox_order_id?: number;
    stops: Stop[];
    service_charges: Service_charge[];
    driver?: Velox_driver;
    carrier: Velox_carrier;
    carrier_pay: number;
    driver_pay: number;
    remaining_carrier_receivable: number;
    remaining_driver_payable: number;
    velox_order: Velox_status;
    pending_change_request: Boolean;

}

export class Velox_status{
    id?: number;
    accepted: Boolean;
    rejected: Boolean;
    canceled: Boolean;
    expired: Boolean;
    expires: string;
    change_requests: Velox_change_request[];
}

export class Velox_destination {
    nofail: Boolean;
    address: Address;
    scheduled: string;
    time_completed: string;
    completed: Boolean;
}

export class Velox_change_request {
    id?: number;
    order_id?: number;
    accepted: Boolean;
    rejected: Boolean;
    time: string;
    payout: number;
    note: string;
    active: Boolean;
    expired: Boolean;
    expires_at: string;
    weblink: string;
    code: string;
}

export class legInput {
    stops: stopInput[];
    status: string;
    vehicle_order_id?: number;
    equipment: string;
    equipment_price: number;
    dispatch: newDispatch;
}

export class leg_update_input {
    id?: number;
    price: number;
    equipment: string;
    equipment_price: number;
    vehicle_order_id?: number;
    master_order_id?: number;
    velox_order_id?: number;
    pickup: pickup_dropoff_stop;
    dropoff: pickup_dropoff_stop;
    carrier_id?: number;
    driver_id?: number;
}

export class newDispatch {
    driver_id?: number;
    carrier_id?: number;
    price: number;
}

export class Basic_id_name_input {
    id?: number;
    name: string;
    key: string;
}

export class dispatch_response {
    driver: Velox_driver;
    leg_id?: number;
    price: number;
    origin: Destination;
    carrier: Velox_carrier;
    vehicle: Vehicle;
    customer: Customer;
    equipment: string;
    destination: Destination;
    velox_order_id?: number;
    master_order_id?: number;
    vehicle_order_id?: number;
    velox_order: Velox_status;
    pending_change_request: Boolean;
}

export class pickup_dropoff_stop {
    id?: number;
    leg_id?: number;
    time_scheduled: string;
    is_pickup: Boolean;
    location: locationInput;
    nofail: Boolean;
    nofail_price: number;
    contacts: conctacts_pickup_dropoff[];
    time_frame?: time_frame_input;
}

export class conctacts_pickup_dropoff {
    id?: number;
}

export class StandardResult {
    id?: number;
    message: string;
    success: Boolean;

    constructor(x: StandardResult){
        if(x){
            this.id = x.id;
            this.message = x.message;
            this.success = x.success;
        }
    }
}

export class Destination {
    address: Address;
    completed: Boolean;
    time_completed: string;
    time_scheduled: string;
    nofail: Boolean;
    contacts: dest_cont[];
}

export class dest_cont {
    name: string;
    value: string;
}

export class Location {
    id?: number;
    customer_id?: number;
    customer?: Customer;
    address: Address;
    title: string;
    internal_note: string;
    driver_note: string;
    work_hours: Work_hours;
}

export class Work_hours {
    weekdays:Week_frame;
    weekends:Week_frame;
}

export class Week_frame {
    from: string;
    to: string;
}

export class Address
{
    city: string = '';
    state: string = '';
    street?: string = '';
    country: string = '';
    postal_code?: string = '';
    street_number?: string = '';
    postal_code_suffix?: string = '';

    constructor (x?: Address)
    {
        if (x)
        {
            this.city = x.city;
            this.state = x.state;
            this.street = x.street;
            this.country = x.country;
            this.postal_code = x.postal_code;
            this.street_number = x.street_number;
            this.postal_code_suffix = x.postal_code_suffix;
        }
    }

    static toString(x: Address){
        return `${x.street_number? x.street_number + ' ':''}${x.street? x.street + ', ':''}${x.city? x.city + ', ':''}${x.state? x.state + (x.postal_code?' ':', '):''}${x.postal_code? x.postal_code + (x.postal_code_suffix? '-'+x.postal_code_suffix:'') + ', ':''}${x.country||''}`;
    }
}

export class At_location_time {
    time_formatted: string;
    timezone: TimeZone;
    place: Place;
}

export class TimeZone {
    code: string;
    name: string;
    daylight_offset: string;
    utc_offset: string;
}

export class Place {
    formatted_address: string;
    place_id?: string;
    location: Location_place;
    address_components?: AddressComponent[];
}

export class AddressComponent{
    long_name: string;
    short_name: string;
    types: string[];
}

export class Location_place {
    latitude: string;
    longitude: string;
}

export class gPlaceRes{
    address: Address;
    formatted: string;
}

export class Contact{
    type: string;
    title: string;
    value: string;
}

export class locRes {
    success: Boolean;
    message: string;
}

export class locationInput {
    id?: number;
    customer_id?: number;
    customer: Basic_id_name_input;
    title: string;
    address: address_input;
    driver_note: string;
    internal_note: string;
    work_hours: work_hour_input;
}

export class work_hour_input{
    weekends: work_hour_frame;
    weekdays: work_hour_frame;
}


export class work_hour_frame{
    from: string;
    to: string;
}

export class locationInput_update {
    location_id?: number;
    title: string;
    driver_note: string;
    internal_note: string;
    address: location_address_update;
    customer_id?: number;
    work_hours: work_hour_input;
}

export class location_address_update {
    city: string;
    state: string;
    street: string;
    country: string;
    postal_code: string;
    street_number: string;
    postal_code_suffix: string;
}

export class Master_order{
    id?: number;
    customer: Customer;
    time: string;
    started: Boolean;
    completed: Boolean;
    price: number;
    payment_left: number;
    terms: string;
    canceled: Boolean;
    contacts: Customer_profile[];
    vehicle_orders: Vehicle_order[];
    service_charges: Service_charge[];
    references: Reference[] ;
    delivered: Boolean;
    picked_up:Boolean;
    non_move:Boolean;
    payment_due: string;
    invoices: Invoice[];
    last_invoice_amount: number;
    needs_invoice: boolean;
    logs: Log[];
    notes: Log[];
    card: Attribute
    quote: Quote;
    token: string
}

export class Logs_ {
    time: string;
    user: USER_;
    message: string;
    master_id?: number;
    customer_profile_id?: number;
    customer_profile: USER_;
    vehicle_order_id?: number;
}

export class Confirmation_Result {
    emails: string[];
    phones: string[];
    body: string;
}

export class USER_ {
    id?: number;
    name: string;
}

export class receivable_aging_res {
    id?: number;
    name: string;
    current: string;
    plus30: string;
    plus60: string;
    plus90: string;
    total: string;
    not_picked_up: string;
    not_delivered: string;
    non_move: string;
    internal_note: string;
}

export class Master_order_invoice_charge_pdf {
    id?: number;
    data: string;
}

export class Master_order_invoice_charge_SP {
    contacts: Customer_profile[];
    customer: C_invoice_sp;
    references: Reference[];
    date_due: string;
    date_issued: string;
    vehicle_orders: Vehicle_order_invoice[];
    service_charges: Service_charge[];
    id?: number;
    invoice_id?: number;
    master_order_id?: number;
    amount: number;
    payment_left: number;
}

export class C_invoice_sp {
    id?: number;
    name: string;
    billing_address: Address;
    terms: string;
}

export class Reference {
    key: string;
    value: string;
}

export class Vehicle_order_invoice {
    vehicle: Vehicle_order_vehice_invoice;
    service_charges: Service_charge[];
    transportation_charges: Transportation_charges;
}

export class Vehicle_order_vehice_invoice {
    vin: string;
    description: string;
    vehicle_order_id?: number;
}

export class Transportation_charges {
    amount: number;
    pickup: PDtc;
    dropoff: PDtc;
    amount_remaining: number;
}

export class PDtc {
    title: string;
    address: Address;
    is_pickup: Boolean;
    time_scheduled: string;
}

export class quote_stop_input {
    contacts: quote_order_contacts[];
    location: location_quote;
    guaranteed: Boolean;
    pickup_date: string;
    dropoff_date: string;
}

export class location_quote {
    id?: number;
    address:Address_new_quote_order;
    title: string;
    driver_note: string;
}

export class Address_new_quote_order {
    city: string;
    state: string;
    street: string;
    country: string;
    postal_code: string;
    street_number: string;
    postal_code_suffix: string;
}

export class quote_order_contacts {
    id?: number;
}

export class master_order_input {
    customer_id?: number;
    terms: string;
    price: number;
    payment_left: number;
    notes: notes_input[];
    contacts: master_order_contacts_input[];
    references: reference_input[];
    vehicle_orders: vehicle_order_input[];
    charges: new_charge_input[];
    billing_address: string;
    attribute_id?: number;
}

export class master_order_contacts_input {
    customer_profile_id?: number;
}

export class notes_input {
    time: string;
    message: string;
    user_id?: number;
}

export class reference_input {
    key: string;
    value: string;
}

export class Notification {
    id?: number;
    title: string;
    time: string;
    message: string;
    type: string;
}

export class Payable_request {
    id?: number;
    time: string;
    time_due: string;
    type: string;
    reference: string;
    note: string;
    amount: number;
    category: string;
    payable_to: string;
    payable: Payable;
    user: Users;
    payee: Payee_request;
    bank_account_id?: number;
    payable_id?: number;
}

export class Payee_request {
    id?: number; name: string; order_ids: number[];
}

export class payee_request_input {
    id?: number; name: string; order_ids: number[];
}

export class Payable {
    id?: number;
    time: string;
    type: string;
    reference: string;
    note: string;
    amount: number;
    category: string;
    time_paid?: string;
    checks: Check[];
    carrier: Carrier;
    driver: Driver;
    employee: Employee;
    payee: Payee;
    bank_account: Bank_account;
}

export class Check {
    amount: number;
    payable_to: string;
    number: number;
    reference: string;
    memo: string;
    time: string;
    bank_account: Bank_account;
    time_created: string;
    void?: Boolean;
}

export class Payee {
    id?: number;
    name: string;
    tax_info: Tax_info;
    address: Address;
    notes: string;
}

export class Tax_input {
    type: string;
    value: string;
}

export class check_input {
    amount: number;
    payable_to: string;
    number: number;
    reference: string;
    memo: string;
    time: string;
}

export class Payment {
  id?: number;
  time: string;
  type: string;
  amount: number;
  amount_left: number;
  bank_account_id?: number;
  note: string;
  files: File[]
  voided: Boolean;
  authorize_transId?: string;
}

export class File {
  uid?: string;
  name: string;
}

export class Bank_account {
  id?: number;
  name: string;
  note: string;
  bank: string;
  active: Boolean;
  last_4: string;
}

export class Payment_date_SP {
  id?: number;
  note: string;
  time: string;
  type: string;
  amount: number;
  masters: master_payment_SP[]
  paid_for: Paid_for[]
  reference: string;
  time_paid?: string;
  amount_left: string;
  credit_card: Attribute
  bank_account: Bank_account
  files: File[];
}

export class master_payment_SP{
  id?: number;
  references: Reference[];
}

export class Paid_for {
  key: string;
  name: string;
}

export class bank_account_input {
   name: string; bank: string; note: string; last_4: string;
}

export class file_input {
  name: string;
  data: string;
}

export class service_payment_input {
  service_charge_id?: number;
  amount_to_charge: number;
  name: string;
}

export class transportation_payments_input {
  id?: number;
  amount_to_charge: number;
  name: string;
}

export class Quote {
    id?: number;
    master_order_id?: number;
    contact_info: Contact_info;
    pickup: Quote_address;
    dropoff: Quote_address;
    vehicle_info: Quote_vehicle[];
    token: string;
    create_at: string;
    price: number;
    user: Users;
    time_created: string;
    note: string;
    archived: Boolean;
    customer_profile: Customer_profile;
}

export class Quote_vehicle {
    make: string;
    model: string;
    year: string;
    vin: string;
    equipment: string;
    price: number;
    nofail_pickup_price: number;
    nofail_dropoff_price: number;
}

export class token_quote_res {
    message: string;
    link: string;
    success: Boolean;
    customer_profile_id?: number;
    verified: Boolean;
}

export class quote_input {
    contact_info: Contact_info_input;
    pickup: quote_address_input;
    dropoff: quote_address_input;
    vehicle_info: quote_vehicle_info_input[];
}

export class quote_input_update {
    id?: number;
    vehicle_info: quote_vehicle_info_input[];
    contact_info: Contact_info_input;
    pickup: quote_address_input;
    dropoff: quote_address_input;
    price: number;
}

export class Contact_info_input {
    full_name: string;
    email: string;
    phone_1: string;
    phone_2: string;
    company_name: string;
    country: string;
}

export class quote_address_input {
    zip: string;
    city: string;
    state: string;
    guaranteed: Boolean;
    pickup_date: string;
    dropoff_date: string;
    country: string;
}

export class quote_vehicle_info_input {
    special_vin: Boolean;
    incomplete_vin: Boolean;
    no_vin: Boolean;
    vin: string;
    year: string;
    make: string;
    model: string;
    price: number;
    equipment: string;
    nofail_pickup_price: number;
    nofail_dropoff_price: number;
}

export class Contact_info {
    full_name: string;
    email: string;
    phone_1: string;
    phone_2: string;
    company_name: string;
}

export class Quote_address {
    zip: string;
    city: string;
    state: string;
    guaranteed: Boolean;
    pickup_date: string;
    dropoff_date: string;
    street_number: string;
    street: string;
    title: string;
    location_id?: number;
}

export class Service_charge {
    id?: number;
    master_order_id?: number;
    master_order: Master_order;
    amount: number;
    tax_amount: number;
    base_amount: number;
    name: string;
    note: string;
    voided: Boolean;
    amount_remaining: number;
    storage_charge: Storage_charge;
    inventory_service: Inventory_service;
}

export class new_charge_input {
    amount: number;
    tax_amount: number;
    base_amount: number;
    name: string;
}

export class Charge {
    name: string;
    amount: number;
}

export class new_charge_input_CD {
    id?: number;
    amount: number;
    tax_amount: number;
    base_amount: number;
    name: string;
    master_order_id?: number;
    vehicle_order_id?: number;
    inventory_service: inventory_service_input;
}

export class charge_input{
    id?: number;
    charges: sh_input[];
    vehicle_orders: sc_vehicle_order[];
}

export class sh_input {
    id?: number;
    name: string;
    amount: number;
    base_amount: number;
    tax_amount: number;
}

export class sc_vehicle_order {
    id?: number;
    charges: sh_input[];
    legs: sc_leg_input[];
}

export class sc_leg_input {
    id?: number;
    equipment_price: number;
    equipment_name: string;
    charge_id?: number;
    stops: sc_stop_input[];
}

export class sc_stop_input {
    id?: number;
    charge_id?: number;
    is_pickup: Boolean;
    nofail: Boolean;
    nofail_price: number;
}

export class Settings{
    key: string;
    value: string;
}

export class Stop {
    id?: number;
    location: Location;
    leg: Leg;
    is_pickup: Boolean;
    time_scheduled: string;
    time_completed: string;
    completed: Boolean;
    started: Boolean;
    canceled: Boolean;
    nofail: Boolean;
    contacts: Customer_profile[];
    service_charges: Service_charge[];
    time_frame?: Time_frame;
    leg_id?: number;
    log: Log[];

    constructor(x: Stop){
        if(x){
            this.id = x.id;
            this.location = x.location;
            this.leg = x.leg;
            this.is_pickup = x.is_pickup;
            this.time_scheduled = x.time_scheduled;
            this.time_completed = x.time_completed;
            this.completed = x.completed;
            this.started = x.started;
            this.canceled = x.canceled;
            this.nofail = x.nofail;
            this.contacts = x.contacts || [];
            this.service_charges = x.service_charges || [];
            this.time_frame = x.time_frame;
            this.leg_id = x.leg_id;
            this.log = x.log || [];
        }
    }
}

export class Time_frame {
    end: string;
    start: string;
}

export class stopInput {
    id?: number;
    contacts: stopContactsInput[];
    location: locationInput;
    is_pickup: Boolean;
    time_scheduled: string;
    nofail: Boolean;
    nofail_price: number;
    time_frame?: time_frame_input;
    completed: Boolean;
}

export class time_frame_input {
    end: string;
    start: string;
}

export class stopContactsInput {
    id?: number;
    stop_id?: number;
    customer_profile_id?: number;
    name: string;
}

export class update_stop_contacts {
    key: string;
    value: string[];
}

export class storage_order {
warehouse_id
    id?: number;
    vehicle_order_id?: number;
    vehicle_order: Vehicle_order;
    billing_cycle: string;
    rate: number;
    date_last_issued: string;
    active: Boolean;
    departure_date: string;
    note: string;
    log: Log[];
    time: string;
    storage_charges: Storage_charge[];
    warehouse: Warehouse;
}

export class Log {
    user_id?: number;
    message: string;
    time: string;
    vehicle_order_id?: number;
    leg_id?: number;
    stop_id?: number;
}

export class Storage_charge {
    id?: number;
    period_start: string;
    period_end: string;
    time: string;
    price: number;
    service_charge_id?: number;
    service_charge: Service_charge;
    storage_order_id?: number;
    storage_order: Storage_Order;
}

export class Storage_orders_sp {
    id?: number;
    started: Boolean;
    vehicle: Vehicle;
    delivered: Boolean;
    master_order: Master_order;
    service_charges: Service_charge[];
    storage_order: Storage_Order;
}

export class Storage_vehicle_order_sp{
    id?: number;
    price: number;
    canceled: Boolean;
    customer: Customer;
    payment_left: number;
    vehicle_order: Vehicle_order;
    last_invoice_amount: number;
}

export class Warehouse {
    id?: number;
    name?: string;
    address?: Address;
    storage_sections?: Storage_section[];
}

export class Storage_section {
    id?: number;
    columns: number;
    rows: number;
    columns_format: Columns_format;
    rows_format: Columns_format;
    name: string;
    warehouse_id?: number;
    warehouse: Warehouse;
    position: Point_damages;
    storage_bays: Storage_bay[]
;
}

export class Columns_format {
    format: string;
    reverse: Boolean;
    starting_value: string;
}

export class Storage_bay {
    id?: number;
    label: string;
    storage_section_id?: number;
    position: Point_damages;
    storage_section: Storage_section;
    vehicle_order_id?: number;
    vehicle_order: Vehicle_order;
}

export class Inventory_service {
    service_charge_id?: number;
    service_charge: Service_charge;
    completed: Boolean;
    time_completed: string;
    time_start: string;
    time_end: string;
}

export class Inventory_order {
    id?: number;
    note: string;
    stop_id?: number;
    incoming: Boolean;
    time_due: string;
    time_completed: string;
    completed: Boolean;
    warehouse_id?: number;
    vehicle_order_id?: number;
    carrier: Inventory_carrier;
    vehicle_order: Vehicle_order;
    warehouse: Warehouse;
    stop: Stop;
}

export class Inventory_carrier {
    name: string;
    driver: string;
    velox_order_id?: number;
    velox_id?: number;
}

export class inventory_carrier_input{
    name: string;
    driver: string;
    velox_order_id?: number;
    velox_id?: number;
}

export class inventory_service_charge_input {
    id?: number;
    name: string;
    amount: number;
    base_amount: number;
    tax_amount: number;
    note: string;
    master_order_id?: number;
    vehicle_order_id?: number;
    inventory_service: inventory_service_input;
}

export class inventory_service_input {
    completed: Boolean;
    time_start: string;
    time_end: string;
    time_completed: string;
}

export class section_input {
    col_count: number;
    row_count: number;
    row_format: string;
    row_value: string;
    row_reverse: Boolean;
    col_format: string;
    col_value: string;
    col_reverse: Boolean;
    title: string;
    posx: number;
    posy: number;
    wareh_id?: number;
}

export class section_edit_input {
    col_count: number;
    row_count: number;
    row_format: string;
    row_value: string;
    row_reverse: Boolean;
    col_format: string;
    col_value: string;
    col_reverse: Boolean;
    title: string;
    posx: number;
    posy: number;
    storagesectionid?: number;
}

export class Tax {
    id?: number;
    amount: number;
    name: string;
    active: Boolean;
}

export class Samsara_truck {
    id?: string;
    vin: string;
	serial: string;
	make: string;
	model: string;
	year: string;
	licensePlate: string;
	tags: TAG[];
    staticAssignedDriver: {id?:string, name:string}
}

export class TAG{
    id?: string;
    name: string;
}

export class Truck_driver {
    id?: string;
    name: string;
    location: t_location;
    image_url: string;
    fuel_percentage: string;
}

export class t_location {
    time: string;
    latitude: number;
    longitude: number;
    heading: number;
    speed: number;
    reverseGeo: t_reverseGeo;
}

export class t_reverseGeo {
    formattedLocation: string;
}

export class Driver_distance {
    distance: google_value;
    duration: google_value;
    duration_in_traffic: google_value;
    driver?: Truck_driver;
}

export class google_value {
    text: string;
    value: number;
}

export class Jurisdiction_report {
    jurisdictionReports: JurisdictionReports[];
    troubleshooting: Troubleshooting;
    month: string;
    year: string;
    message: string;
}

export class Truck_jurisdiction_report {
    vehicleReports: VehicleReport[];
    troubleshooting: Troubleshooting;
    month: string;
    year: string;
    message: string;
}

export class VehicleReport {
    vehicle: Samsara_vehicle;
    jurisdictions: JurisdictionReports[];
}

export class Samsara_vehicle {
    id?: string;
    name: string;
}

export class JurisdictionReports {
    jurisdiction: string;
    totalMeters: number;
    taxableMeters: number;
    taxPaidLiters: number;
}

export class Troubleshooting {
    unassignedFuelTypePurchases: number;
    unassignedFuelTypeVehicles: number;
    unassignedVehiclePurchases: number;
    noPurchasesFound: Boolean;
}

export class Users{
    id?: number;
    name: string;
    level: number;
    active: Boolean;
    employee: Employee;
}

export class User_settings {
    driver_view_order: Driver_view_order[];
}

export class Driver_view_order {
    carrier_id?: number;
    order: Order_driver_settings[];
}

export class Order_driver_settings {
    id?: number;
}

export class fUser{
    id?: number;
    name: string;
    level: number;
}

export class fuRes{
    message: string;
    user: fUser;
    success: Boolean;
}

export class user_input {
    id?: number;
    user_id?: number;
    name: string;
    active: Boolean;
    password?: string;
}

export class Input_User_settings {
    driver_view_order: Input_Driver_view_order[];
}

export class Input_Driver_view_order {
    carrier_id?: number;
    order: Input_Order_driver_settings[];
}

export class Input_Order_driver_settings {
    id?: number;
}

export class Vehicle_order {
    id?: number;
    time: string;
    master_order: Master_order;
    bol_comment: string;
    time_changed: string;
    driver_note: string;
    started: Boolean;
    delivered: Boolean;
    vehicle: Vehicle;
    charges: Service_charge[];
    storage_order: Storage_Order;
    legs: Leg[];
    storage_bay: Storage_bay;
    inspections: Inspection[];
    inventory_orders: Inventory_order[];
    pending_inspection_id?: number;
    master_order_id?: number;
    vehicle_order_files: Vehicle_order_file[];
    warehouse_id?: number;
    warehouse: Warehouse;
    non_move: Boolean;
}

export class vehicle_order_input {
    id?: number;
    legs?: legInput[];
    vehicle: vehicle_input;
    bol_comment: string;
    driver_note: string;
    master_order_id?: number;
    charges: new_charge_input[];
    storage_order: storage_order_input;
    non_move: Boolean;
}

export class storage_order_input{
    billing_cycle: string;
    rate: number;
    note: string;
    active: Boolean;
    warehouse_id?: number;
    departure_date: string;
}

export class Vehicle_order_file {
    id?: number;
    name: string;
    uid?: string;
    time: string;
    link: string;
}

export class vehicle_orders_by_profile_SP {
    order: odSP;
    pickup: PDVeh_odSP;
    dropoff: PDVeh_odSP;
}

export class odSP {
    vehicle: string;
    placed_by: string[];
    placed_for: string;
    master_order_id?: number;
    vehicle_order_id?: number;
}


export class PDVeh_odSP {
    location: Location;
    completed: Boolean;
    time_completed: string;
    time_scheduled: string;
}

export class vehicle_order_update_input {
    bol_comment: string;
    driver_note: string;
    started: Boolean;
    delivered: Boolean;
}

export class order_details_res {
    legs: legRes[];
    vehicle: Vehicle;
    total_price: number;
    master_order_id?: number;
    vehicle_order_id?: number;
}

export class legRes {
    driver: Driver;
    leg_id?: number;
    origin: Destination;
    charges: Charge[];
    carrier: Carrier;
    leg_price: number;
    destination: Destination;
    equipment_name: string;
    velox_order_id?: number;
}

export class Vehicle {
    id?: number;
    vin: string;
    year: string;
    make: string;
    model: string;
    submodel: string;
    type: string;
    no_vin: Boolean;
    special_vin: Boolean;
    incomplete_vin: Boolean;
    color: string;
    license_plate: string;
    internal_note: string;
    time_created: string;
    user_id?: number;
}

export class all_makes_res {
    Make_id?: number;
    Make_Name: string;
}

export class model_make_res {
    Make_id?: number;
    Make_Name: string;
    Model_id?: string;
    Model_Name: string;
}

export class vehicle_input{
    id?: number;
    vin: string;
    year: string;
    make: string;
    model: string;
    submodel?: string;
    type?: string;
    no_vin?: Boolean;
    special_vin?: Boolean;
    incomplete_vin?: Boolean;
    color?: string;
    license_plate?: string;
    internal_note?: string;
}

export class customer_paid_statement_data_result{
    customer: Customer;
    rows: customer_paid_statement_data_row[] = [];
}

class customer_paid_statement_data_row{
    id?: number;
    time: Date;
    price: number;
    contacts: string[];
    invoices: string[];
    references: string[];
    payment_due: Date;
    payment_left: number;
}

export class batch_master_references{
    id?: number;
    po: string;
    ro: string;
    stock: string;
}

export class carrier_update_contact {
    name: string;
    email: string;
    phone: string;
}

export class velox_Vehicle{
    id?: number
    vin: string;
    year: string;
    make: string;
    model: string;
    submodel: string;
    type: string;
    no_vin: Boolean;
    special_vin: Boolean;
    incomplete_vin: Boolean;
    color: string;
    license_plate: string;
    internal_note: string;
    time_created: string;
    time: string
}

export class velox_Vin_Response{
    vehicle: velox_Vehicle
    success: Boolean
    message: string
}

export class velox_vehicle_input{
    vin: string
    year: string
    make: string
    model: string
    submodel?: string
    type?: string
    no_vin?: Boolean
    special_vin?: Boolean
    incomplete_vin?: Boolean
    color?: string
    license_plate?: string
    internal_note?: string
}

export class velox_vehicle_inspection{
    id?: number
    vin: string
    year: string
    make: string
    model: string
    submodel: string
    type: string
    no_vin: Boolean
    special_vin: Boolean
    incomplete_vin: Boolean
    color: string
    license_plate: string
    internal_note: string
}

export class velox_User{
    id?: number
    name: string
    username: string
    level: number
    carrier_id?: number
    carrier_name: string
    bol_terms: string
}


export class velox_Setting{
    key: string;
    value: string
}
export class velox_Photos{
    uid?: string
    type: string
    user_id?: number
}



export class velox_Accessories{
    name: string
    count: number
    user_id?: number
}

export class velox_Flags{
    too_dirty: Boolean
    too_dark: Boolean
    raining: Boolean
}

export class velox_Destination_inspection{
    destination_id?: number
    driver_name: string
    driver_signature: string
    contact_name: string
    inspections: velox_Inspection[]
}

export class velox_Signature_input{
    contact_name: string
    contact_signature: string
}

export class velox_Point_damage{
    x: number
    y: number
}

export class velox_Accessories_input{
     name: string
     count: number
}

export class velox_Flags_input{
     too_dirty: Boolean
     too_dark: Boolean
     raining: Boolean
}


export class velox_Pending_inspection{
    id?: number
    performed_by: string
    signed_by: string
    user_id?: number
    time: string
    note: string
    odometer: number
    device_type: string
    vehicle: velox_Vehicle
    accessories: velox_Accessories[]
    additional_emails: String[]
    damages: velox_Damages[]
    photos: velox_Photos[]
    device_info: velox_Device_info
    flags: velox_Flags[]
    vehicle_data: velox_Vehicle
    driver_signature: string
    contact_signature: string
    driver_name: string
    contact_name: string
    driver: velox_Driver
    pending_destination_inspection_id?: number
    pending_origin_inspection_id?: number
    destination_id?: number
    origin_id?: number
}

export class velox_Device_info{
    name: string
    type: string
    idiom: string
    model: string
    version: string
    platform: string
    manufacturer: string
}

export class velox_Required_photos{
    type: string
    uid?: string
}

export class velox_Damages{
    stop_id?: number
    point: velox_Point_damage
    code: string
    uid?: string
    is_pickup: Boolean
}

export class velox_destination_inspection{
    destination_id?: number
    driver_name: string
    driver_signature: string
    contact_name: string
    inspections: velox_Inspection[]
}


export class velox_Payment{
    id?: number
    type: string
    time: string
    voided: Boolean
    amount: number
    reference: string
    carrier: Velox_carrier
}

export class velox_payment_input{
    cardholder_name: string
    number: string
    type: string
    expiration: string
    cvc: string
    zip: string
    country: string
    street: string
    city: string
    state: string
}

export class velox_order_payment_input{
    order_id?: number;
    amount: number
}


export class velox_Origin{
    nofail: Boolean
    address: Address
    contacts: velox_Contacts[]
    scheduled: string
    time_completed: string
    completed: Boolean
    timezoneName?: string
}

export class velox_Origin_inspection{
    origin_id?: number
    driver_name: string
    driver_signature: string
    contact_name: string
    inspections: velox_Inspection[]
}

export class velox_Order{
    id?: number
    price: number
    carrier_pay: number
    vehicle: velox_Vehicle
    broker: velox_Broker
    driver: velox_Driver
    origin: velox_Origin
    destination: velox_Destination
    completed: Boolean
    driver_pay: number
    accepted: Boolean
    rejected: Boolean
    cancelled: Boolean
    expired: Boolean
    expires: string
    carrier: Velox_carrier
    time: string
    remaining_carrier_receivable: number
    remaining_driver_payable: number
    velox_order_id?: number
    pending_change_request: Boolean
    change_requests: velox_Change_request[]
    carrier_payments?: velox_Carrier_payment[];
    broker_note: string;
    payment_term: Payment_term
}

export class velox_carriers_for_broker_payment{
    name: string
    total: number
    over30: number
    over60: number
    over90: number
    current: number
    delivered: number
    valid?: Boolean
    carrier_id?: number
    payment_terms: number
    drivers_dispatched: number
}

export class velox_order_input{
    price: number
    origin: velox_destination_input
    vehicle: velox_vehicle_input
    destination: velox_destination_input
    broker_note?: string
}

export class velox_destination_input{
    nofail: Boolean
    address: address_input
    contacts: velox_contact_input[]
    scheduled: string
    time_frame?: time_frame_input
}

export class velox_contact_input{
    name: string
    value: String[]
    id?: number
}

export class velox_order_res{
    id?: number
    message: string
    success: Boolean
    link: string
}

export class velox_Order_history{
    id?: number
    order_id?: number
    carrier_id?: number
    broker_id?: number
    order: velox_Order
    carrier: Velox_carrier
    broker: velox_Broker

}

export class velox_Inspection{
    id?: number
    performed_by: string
    signed_by: string
    time: string
    note: string
    odometer: string
    device_type: string
    vehicle: velox_Vehicle
    vehicle_data: velox_Vehicle
    accessories: velox_Accessories[]
    additional_emails: String[]
    damages: velox_Damages[]
    device_info: velox_Device_info
    photos: velox_Required_photos[]
    flags: velox_Flags
    origin_inspection: velox_Origin_inspection
    destination_inspection: velox_Destination_inspection
    driver_signature: string
    contact_signature: string
    driver_name: string
    contact_name: string
    stop: velox_Stop
    driver: velox_Driver
    gps: velox_Gps_inspection
}

export class velox_Inspection_progress{
    id?: number
    order_id?: number
    is_pickup: Boolean
    progress: number
    uploading: number
    uploadedData: Boolean
    uploadedSignature: Boolean
    uploadedPhotos: Boolean
    vehicle: string
    address: string
    vin: string
}

export class velox_Gps_inspection{
    Timestamp: string
    Coordinate: velox_Coordinate_insp
}

export class velox_Coordinate_insp{
    Latitude: string
    Longitude: string
}

export class velox_Stop{
    id?: number
    nofail: Boolean
    address: Address
    contacts: velox_Contacts[]
    scheduled: string
    time_completed: string
    completed: Boolean
    is_pickup: Boolean
}

export class velox_Stop_inspection{
    origin_id?: number
    destination_id?: number
    driver_name: string
    driver_signature: string
    contact_name: string
}

export class velox_bol_data_stop{
    address: Address
    contacts: velox_Contacts[]
    scheduled: string
    vehicle: velox_Vehicle
    inspection: velox_Inspection
    driver_signature?: string
    contact_signature?: string
}

export class velox_bol_data{
    pickup: velox_bol_data_stop
    dropoff: velox_bol_data_stop
}

export class bol_data{
    pickup: velox_bol_data_stop
    dropoff: velox_bol_data_stop
    number?: number;
    dropoff_link?: string;
    pickup_link?: string;
    disclaimer?: string;
    company_info?: string;
    bol_comment?: string;
    bol_name?: string;
}

export class velox_Inspection_input{
     stop_id?: number
     dont_email: Boolean
     is_pickup: Boolean
     vehicle_id?: number
     vehicle: velox_vehicle_inspection
     performed_by: string
     signed_by: string
     time: string
     note: string
     gps: string
     required_photos: velox_Required_photos_input[]
     accessories: velox_Accessories_input[]
     damages: velox_Damages_input[]
     signed_time: string
     odometer: string
     flags: velox_Flags_input[]
     additional_emails: String[]
     device_type: string
     device_info: string
     velox_order_id?: number
}

export class velox_Required_photos_input{
   type: string
     uid?: string
}

export class velox_Damages_input{
     stop_id?: number
     velox_order_id?: number
     point: velox_Point_damage_input[]
     code: string
     uid?: string
     is_pickup: Boolean
}

export class velox_Point_damage_input{
     x: number
     y: number
}

export class velox_File{
    id?: number
    name: string
}

export class velox_LoginResult{
    message: string
    token: string
    profile: velox_Carrier_profile
    redirect_link: string
}

export class velox_signature_input{
    inspection_id?: number
    origin_id?: number
    destination_id?: number
    driver_id?: number
    contact_name: string
    contact_signature: string
}

export class velox_s3res{
    message: string
    success: Boolean
}

export class velox_Driver{
    id?: number
    name: string
    username: string
    carrier: Velox_carrier
    equipment: string
    contacts: velox_Contact[]
    carrier_code: string
    photos: velox_Driver_photo[]
}

export class velox_Driver_photo{
    uid?: string
    name: string
}

export class velox_Contact{
    title: string
    type: string
    value: string

}

export class velox_OrderContact{
    name: string
    value: String[]

}

export class velox_scheduleSP{
    id?: number
    name: string
    price: number
    carrier: string
    carrier_id?: number
    status: velox_statusSP
    contacts: velox_Contact[]
    equipment: string
    itinerary: velox_itinerarySP[]
    gps: velox_GPS_driver[]
    samsara_truck_id?: number
}

export class velox_GPS_driver{
    Speed: number
    Course: number
    Altitude: number
    Timestamp: string
    Coordinate: velox_Coordinate_driver
    SpeedAccuracy: number
    CourseAccuracy: number
    VerticalAccuracy: number
    HorizontalAccuracy: number
}

export class velox_Coordinate_driver{
    Latitude: number
    Longitude: number
}

export class velox_itinerarySP{
    orders: velox_orderSP[]
    address: string
}

export class velox_orderSP{
    id?: number
    vehicle: velox_Vehicle
    order_id?: number
    is_pickup: Boolean
    scheduled: string
    completed: Boolean
    nofail: Boolean
    contacts: velox_OrderContact[]
    address: string
    pending_change_request: Boolean
    change_requests: velox_Change_request[]
}

export class velox_statusSP{
    text: string
    text_color: string
    background_color: string
    border_color: string
}

export class velox_aging_carrier_driver_payable{
    id?: number;
    name: string
    total: number
    valid?: Boolean
    last_payable: velox_Payable
    delivered: number
    driver_id?: number
    completed: number
}

export class velox_Payable{
    id?: number
    amount: number
    reference: string
    type: string
    time: string
}

export class velox_driver_input{
    id?: number
    name: string;
    equipment: string;
    contacts: velox_driver_contact_input[]
}

export class velox_driver_contact_input{
    type: string
    title: string
    value: string
}

export class velox_Driver_signature{
    driver: velox_Driver
}

export class velox_standard_ret{
    message: string
    success: Boolean
}

export class velox_Driver_location{
    time: string

}

export class velox_locations_sp{
    id?: number
    name: string
    devices: velox_device_location[]
    username: string
}

export class velox_device_location{
    time: string
    speed: number
    course: number
    device: string
    altitude: string
    coordinate: velox_coordinate_location
    device_info: velox_device_info_location
    background_mode: Boolean
}

export class velox_coordinate_location{
    Latitude: string
    Longitude: string
}

export class velox_device_info_location{
    name: string
    type: string
    idiom: string
    model: string
    version: string
    platform: string
    manufacturer: string
}

export class velox_Destination{
    nofail: Boolean
    address: Address
    contacts: velox_Contacts[]
    scheduled: string
    time_completed: string
    completed: Boolean
    timezoneName?: string
}

export class velox_Contacts{
    name: string
    value: String[]
    id?: number
}

export class velox_Company{
    id?: number
    name: string
    address: Address
}

export class velox_Change_request{
    id?: number
    order_id?: number
    vehicle: velox_Vehicle
    origin: velox_Origin
    destination: velox_Destination
    accepted: Boolean
    rejected: Boolean
    time: string
    payout: number
    note: string
    active: Boolean
    expired: Boolean
    expires_at: string
    weblink: string
    code: string
}

export class velox_Change_request_result{
    order: velox_Order
    change_request: velox_Change_request
}

export class Velox_carrier{
    id?: number;
    name: string;
    code?: string;
    internal_note?: string;
    active?: Boolean;
    multi_carrier?: Boolean;
    drivers?: velox_Driver[];
    carrier_profiles?: velox_Carrier_profile[];
    carrier_insurance?: velox_Carrier_insurance;
    carrier_registration?: velox_Carrier_registration;
    contact?: velox_Carrier_contact;
    address?: Address;
    payment_terms?: Payment_term
  }

  export class velox_Carrier_contact{
    name: string
    email: string
    phone: string
  }

  export class velox_Insurance_agent{
    name: string
    email: string
    phone: string
  }

  export class velox_Carrier_insurance{
    id?: number
    name: string
    policy_number: string
    agent: velox_Insurance_agent
    liability_coverage: number
    damage_coverage: number
    deductible: number
    expiration: string
    uploaded_documents: Driver_photo[]
    carrier_id?: number
  }

  export class velox_Carrier_registration{
    id?: number
    mc_number: string
    tax_number: string
    uploaded_documents: Driver_photo[]
    carrier_id?: number
  }

  export class velox_Notification{
    id?: number
    title: string
    type: string
    message: string
    time: string
  }

  export class velox_carrier_update_input{
    id?: number
    name: string
    multi_carrier: Boolean
    code: string
    active: Boolean
    contact: velox_carrier_update_contact
    address: Address
    terms: string
  }

  export class velox_carrier_update_contact{
    name: string
    email: string
    phone: string
  }

  export class velox_carrier_driver_res{
    driver: velox_scheduleSP
  }


export class velox_SettingsInput{
    key: string;
    value: string
}

export class velox_carrier_registration_input{
    mc_number: string
    dot_number: string
    carrier_id?: number
}

export class velox_Carrier_profile{
    id?: number
    name: string
    email: string
    role: string
    contacts: velox_carrier_contact[]
    carrier_id?: number
    carrier_name: string
}

export class velox_carrier_contact{
    name: string
    value: String[]
}

export class velox_carrier_proRes{
    message: string
    profile: velox_Carrier_profile
    success: Boolean
}

export class velox_Carrier_notification{
    id?: number;
    message: string;
    type: string
    time: string
    title: string
}

export class velox_agent_insurance_input{
    name: string
    email: string
    phone: string
}

export class velox_carrier_insurance_input{
    name: string;
    policy_number: string;
    agent: velox_agent_insurance_input;
    liability_coverage: number
    damage_coverage: number
    deductible: number
    expiration: string
    carrier_id?: number
}

export class velox_Broker{
    id?: number
    name: string
    company_id?: number
    company: velox_Company
}

export class velox_aging_carrier_sp{
    driver_id?: number
    late: number
    name: string
    picked_up: number
    carrier_id?: number
    orders_dispatched: number
    drivers_dispatched: number
    valid?: Boolean
    completed: number
}

export class carrier_report{
    title: string;
    subtitle: string;
    carrier: Velox_carrier;
    orders: carrier_report_order[];
}

export class carrier_report_order{
    velox_order_id?: number;
    origin: string[];
    destination: string[];
    price: number;
    remaining_carrier_receivable: number;
    vehicle: velox_Vehicle;
    payments: velox_Payment[];
}

export class driver_report{
    title: string;
    subtitle: string;
    driver: velox_Driver;
    orders: driver_report_order[];
}

export class driver_report_order{
    leg_id?: number;
    velox_order_id?: number;
    origin: string[];
    destination: string[];
    driver_pay: number;
    vehicle: velox_Vehicle;
    payables: velox_Payable[];
    remaining_driver_payable: number;
}

export class payable_carrier{
    payable_id?: number;
    leg_id?: number;
    velox_payment_id?: number;
    velox_carrier_id?: number;
    payable: Payable;
    leg: Leg;
}

export class velox_Carrier_payment{
    id?: number
    payment_id?: number
    payment: velox_Payment
    order_id?: number
    order: velox_Order
    amount_paid?: number
    amount_refunded: number
    carrier: Velox_carrier
}

export class velox_carrier_input {
    name: string
    is_multi_carrier: Boolean
    code: string
    terms: string
    internal_note: string
    carrier_profile: velox_carrier_profile_input
    address: address_input
    tax_number: tax_info_input
    default_notification_email: string
    contact: carrier_update_contact
}

export class velox_carrier_profile_input {
    name: string;
    email: string;
    title: string;
    contact: driver_contact_input[];
}

export class Payment_term{
    carrier_id?: number
    broker_id?: number
    terms: string;
}

export interface FileUpload {
    filename: string;
    mimetype: string;
    encoding: string;
}

export class Upload {
    promise: Promise<FileUpload>;
    file?: FileUpload;
    data?: any;
    name?: string;
}

export class Carrier_tax_info {
    carrier_id?: number;
    id?: number;
    name: string;
    business_name: string;
    type: string;
    address: Address;
    ssn: string;
    ein: string;
    signature: string;
    time_signed: string;
    uploaded_documents: Driver_photo[]
}

export class carrier_tax_info_input {
    carrier_id?: number;
    name: string;
    business_name: string;
    type: string;
    address: address_input
    ssn: string;
    ein: string;
    signature: string;
    time_signed: string;
}

export class agent_insurance_input{
    name: string
    email: string
    phone: string
}

export class carrier_insurance_input{
    name: string;
    policy_number: string;
    agent: agent_insurance_input;
    liability_coverage: number
    damage_coverage: number
    deductible: number
    expiration: string
    carrier_id?: number
}

export class Insurance_agent{
    name: string
    email: string
    phone: string
  }


export class Carrier_insurance{
    id?: number
    name: string
    policy_number: string
    agent: Insurance_agent
    liability_coverage: number
    damage_coverage: number
    deductible: number
    expiration: string
    uploaded_documents: String[]
    carrier_id?: number
  }

  export class equipment_input{
    id?: number
    type: string
    year: string
    make: string
    model: string
    vin: string
    registration_expiration: string
    insurance_expiration: string
}

export class Equipment{
    id?: number
    type?: string
    year?: string
    make?: string
    model?: string
    vin?: string
    registration_expiration?: string
    insurance_expiration?: string
}

import {Employee} from "../employee/employee";

export class Users {
    __typename?: "Users"
    id?: number = null;
    name?: string = '';
    level?: number = 0;
    active?: boolean = false;
    employee?: Employee;

    constructor(x?: Users) {
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.level = x.level;
            this.active = x.active;
            this.employee =  x.employee;            
        }
    }
}

export class User_Settings {
    __typename?: "User_settings"
    driver_view_order?: Driver_View_Order[] = []; 

    constructor(x?: User_Settings) {
        if(x){
            this.driver_view_order = x.driver_view_order?.map(z => new Driver_View_Order(z)) || [];
        }
    }
}

export class Driver_View_Order {
    __typename?: "Driver_view_order"
    carrier_id?: number = null;
    order?: Order_Driver_Settings[] = [];

    constructor(x?: Driver_View_Order) {
        if(x){
            this.carrier_id = x.carrier_id;
            this.order = x.order?.map(z => z) || [];
        }
    }
}

export class Order_Driver_Settings {
    __typename?: "Order_driver_settings"
    id?: number = null;

    constructor(x?: Order_Driver_Settings){
        if(x){
            this.id = x.id
        }
    }
}

export class FUser {
    __typename?: "fUser"
    id?: number = null;
    name?: string = '';
    level?: number = 0;

    constructor(x?: FUser){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.level = x.level;
        }
    }
}

export class FuRes {
    __typename?: "fuRes"
    message?: string = '';
    user?: FUser = new FUser();
    success?: boolean = false;

    constructor(x?: FuRes) {
        if(x) {
            this.message = x.message;
            this.user = x.user;
            this.success = x.success;
        }
    }
}

export class User_Input {
    id?: number = null;
    user_id?: number = null;
    name?: string = '';
    password?: string = '';
    active?: boolean = false;

    constructor(x?: User_Input){
        if(x){
            this.id = x.id;
            this.user_id = x.user_id;
            this.name = x.name;
            this.password = x.password;
            this.active = x.active;            
        }
    }

}

export class Input_User_Settings {
    driver_view_order?: Input_Driver_View_Order[] = []

    constructor(x?: Input_User_Settings){
        if(x) {
            this.driver_view_order = x.driver_view_order?.map(z => new Driver_View_Order(z)) || [];
        }
    }
}

export class Input_Driver_View_Order {
    carrier_id?: number = null;
    order?: Input_Order_Driver_Settings[] = [];

    constructor (x?: Input_Driver_View_Order){
        if(x){
            this.carrier_id = x.carrier_id;
            this.order = x.order?.map(z => z) || [];
        }
    }
}

export class Input_Order_Driver_Settings {
    id?: number = null;

    constructor(x?: Input_Order_Driver_Settings){
        if(x) {
            this.id = x.id
        }
    }
}

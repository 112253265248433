import React, {useState, useRef, useEffect} from 'react'
import './ApPage.scss'
import { Queries } from '../../services/endpoints/queries'

const ApPage =()=>{

    useEffect(() => {
        const data = Queries.attributes_by_customerId({customer_id: 1798}).then(res => {
            console.log(res);
        })
    }, []);

    return(
        <>
            <h1>Ap</h1>
        </>
    )
}

export default ApPage

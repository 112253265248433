import React,{useState,useRef} from 'react'
import './NetworkPage.scss'



const NetworkPage =()=>{
    return(
        <>
            <h1>Network</h1>
        </>
    )
}

export default NetworkPage
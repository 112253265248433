import { timeStamp } from "console";
import Helper from "../../helper/Helper";
import { decrypt } from "../ps-crypt";
import { AuthenticationResult, UserData } from "./classes";

const exclude = ['pending-inspection', 'invoice', 'inspection-report']

export class SafeStorage{
    static user: UserData;

    static User(){
        if(!this.user){
            this.restoreUserData();
        }
        return this.user;
    }

    static saveAuthenticationResult(result: AuthenticationResult){

        this.user = UserData.verify(decrypt(result.data));

        localStorage.setItem('jpltoken', result.token);
        localStorage.setItem('username', this.user.name);
        localStorage.setItem('user', result.data);
    }

    static removeAuthenticationData(){
        localStorage.removeItem('jpltoken');
        localStorage.removeItem('username');
        localStorage.removeItem('user');
        this.user = null;
    }

    static restoreUserData(){
        try {
            let data = localStorage.getItem('user');
            if(data){
                this.user = UserData.verify(decrypt(data));
            }
            else{
                this.user = null;
            }
        } catch (ex) {
            console.log('Error in restoreUserData: ' + ex.message);
            this.removeAuthenticationData();
        }
    }

    static checkPermissions(navSite: string){
        if(exclude.includes(navSite)){
            return true;
        }
        if(this.User()?.permissions){
            return this.User().permissions.includes(navSite);
        }
        else
            return false;
    }

    static checkPermisionsRoute(route: string){
        if(this.User()?.permissions){
            if(route){
                if(route[0] == '/'){
                    if (route.startsWith('//console.jplogistics.net/')){
                        route = route.replaceAll('//console.jplogistics.net/','')
                    } else {
                        route = route.substring(1);
                    }
                }
                let navSite = route.split('/')[0];
                if(exclude.includes(navSite)){
                    return true;
                }
                let res = this.User().permissions.includes(navSite);
                return res;
            }
            else{
                return true;
            }
        }
        else{
            if(route){
                if(route[0] == '/'){
                    route = route.substring(1);
                }
                let navSite = route.split('/')[0];
                let res = exclude.includes(navSite);
                return res;
            }
            else{
                return true;
            }
        }
    }

    static redirectNoPermissions(site: string){
        if(!this.checkPermissions(site)){
            Helper.Navigation.NavigateTo(`/`);
        }
    }
}
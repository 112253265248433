import { CustomerById, CustomerList, CustomerProfileList, Location } from "../../classes/classes";
import CustomerQueries from "../../graphql/query/Customer";
import { GraphqlService } from "../../services/graphql.service";
import CustomerTypes from "../types/Customer.type"
import CustomerMutations from "../../graphql/mutation/Customer";


export function getAllCustomersList()
{
    return async dispatch => {
        dispatch(getAllCustomersListStarted());
        try {
            let data = await GraphqlService.SendQuery(CustomerQueries.GET_ALL_LIST);
            dispatch(getAllCustomersListSuccess(data.map(x => new CustomerList(x))));
        } catch (ex) {
            dispatch(getAllCustomersListError(ex.message));
        }
    }
}


const getAllCustomersListStarted = () => ({
    type: CustomerTypes.GET_ALL_CUSTOMER_LIST_STARTED,
    payload: true
});

const getAllCustomersListSuccess = (data) => ({
    type: CustomerTypes.GET_ALL_CUSTOMER_LIST_SUCCESS,
    payload: data
});

const getAllCustomersListError = (error) => ({
    type: CustomerTypes.GET_ALL_CUSTOMER_LIST_ERROR,
    payload: error
});


export function getAllCustomerProfilesList(customer_id)
{
    return async dispatch => {
        dispatch(getAllCustomerProfilesListStarted(customer_id));
        try {
            let data = await GraphqlService.SendQuery(CustomerQueries.GET_ALL_PROFILE_LIST, {customerId: customer_id});
            dispatch(getAllCustomerProfilesListSuccess(data.map(x => new CustomerProfileList(x))));
        } catch (ex) {
            dispatch(getAllCustomerProfilesListError(ex.message));
        }
    }
}


const getAllCustomerProfilesListStarted = (customer_id) => ({
    type: CustomerTypes.GET_ALL_CUSTOMER_PROFILE_LIST_STARTED,
    payload: customer_id
});

export const getAllCustomerProfilesListSuccess = (data) => ({
    type: CustomerTypes.GET_ALL_CUSTOMER_PROFILE_LIST_SUCCESS,
    payload: data
});

const getAllCustomerProfilesListError = (error) => ({
    type: CustomerTypes.GET_ALL_CUSTOMER_PROFILE_LIST_ERROR,
    payload: error
});


export function getCustomerLocations(customer_id: number) {
    return async dispatch => {
        dispatch(getCustomerLocationsStarted(customer_id));
        try {
            let data = await GraphqlService.SendQuery(CustomerQueries.GET_ALL_LOCATIONS, {customer_id: customer_id});

            dispatch(getCustomerLocationsSuccess(data.map(x => new Location(x))));
        } catch (ex) {
            dispatch(getCustomerLocationsError(ex.message));
        }
    }
}

export const getCustomerLocationsStarted = (customer_id) => ({
    type: CustomerTypes.GET_ALL_CUSTOMER_LOCATIONS_STARTED,
    payload: customer_id
});

export const getCustomerLocationsSuccess = (data) => ({
    type: CustomerTypes.GET_ALL_CUSTOMER_LOCATIONS_SUCCESS,
    payload: data
});

const getCustomerLocationsError = (error) => ({
    type: CustomerTypes.GET_ALL_CUSTOMER_LOCATIONS_ERROR,
    payload: error
});

//

export function clearGetAllCustomersListError()
{
    return dispatch => dispatch(getAllCustomersListError(null));
}

const fetchCustomerInfoRequest = () => ({
    type: CustomerTypes.GET_CUSTOMER_INFO_REQUEST,
});

const fetchCustomerInfoSuccess = (customer) => ({
    type: CustomerTypes.GET_CUSTOMER_INFO_SUCCESS,
    payload: new CustomerById(customer)
});

const fetchCustomerInfoFailure = error => ({
    type: CustomerTypes.GET_CUSTOMER_INFO_FAILURE,
    payload: error
});

export function getCustomerInfo(customerId) {
    return async dispatch => {
        try {
            dispatch(fetchCustomerInfoRequest());
            let data = await GraphqlService.SendQuery(CustomerQueries.GET_CUSTOMER_INFO, {customer_id: customerId});
            //console.log(data);
            dispatch(fetchCustomerInfoSuccess(data));
        } catch (ex) {
            dispatch(fetchCustomerInfoFailure(ex));
        }
    }
}

export function editCustomerInfo(customer_id, customer_info) {
    return async dispatch => {
        try {
            dispatch(fetchCustomerInfoRequest());
            let data = await GraphqlService.SendMutation(CustomerMutations.EDIT_CUSTOMER, {customer_id, customer_info});
        } catch (ex) {
            dispatch(fetchCustomerInfoFailure(ex));
        }
    }
}

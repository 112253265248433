import IReducerAction from "../models/IReducerAction";
import OrderReceivableTypes from "../types/Receivables.type";

const FAKE_DATA = [
    {
        master_id: 1,
        invoice_id: 1234,
        time_in: '10/11/2021',
        customer: 'JP Motors',
        contact: '0000-0000-0000',
        sent_on: '11/11/2021',
        due: '11/11/2021',
        terms: 'terms',
        status: 'Pending'
    },
    {
        master_id: 2,
        invoice_id: 1234,
        time_in: '10/11/2021',
        customer: 'JP Motors',
        contact: '0000-0000-0000',
        sent_on: '11/11/2021',
        due: '11/11/2021',
        terms: 'terms',
        status: 'Pending'
    },
    {
        master_id: 3,
        invoice_id: 1234,
        time_in: '10/11/2021',
        customer: 'JP Motors',
        contact: '0000-0000-0000',
        sent_on: '11/11/2021',
        due: '11/11/2021',
        terms: 'terms',
        status: 'Pending'
    },
    {
        master_id: 4,
        invoice_id: 1234,
        time_in: '10/11/2021',
        customer: 'JP Motors',
        contact: '0000-0000-0000',
        sent_on: '11/11/2021',
        due: '11/11/2021',
        terms: 'terms',
        status: 'Pending'
    },
]

export interface IOrderReceivable {
    master_id: number
    invoice_id: number
    time_in: string
    customer: string
    contact: string
    sent_on: string
    due: string
    terms: string
    status: string
}

export interface IOrderReceivables {
    data: IOrderReceivable[],
    loading: boolean,
    error: string,
}

export interface IOrderReceivablesKeys {
    open: IOrderReceivables,
    closed: IOrderReceivables,
    aging: IOrderReceivables,
    payments: IOrderReceivables,
}

const initialState: IOrderReceivablesKeys = {
    open: {
        data: [],
        loading: false,
        error: '',
    },
    closed: {
        data: [],
        loading: false,
        error: '',
    },
    aging: {
        data: [],
        loading: false,
        error: '',
    },
    payments: {
        data: [],
        loading: false,
        error: '',
    },
}

export default function OrderReceivableReducer(state: IOrderReceivablesKeys = initialState, action: IReducerAction): IOrderReceivablesKeys {
    switch (action.type) {
        case OrderReceivableTypes.GET_ALL_ORDER_RECEIVABLES_STARTED:
            return Object.assign({}, {
                ...state,
                [action.status]: {
                    ...state[action.status],
                    loading: action.payload,
                    error: null
                }
            })
        case OrderReceivableTypes.GET_ALL_ORDER_RECEIVABLES_SUCCESS:
            return Object.assign({}, {
                ...state,
                [action.status]: {
                    ...state[action.status],
                    data: action.payload,
                    loading: false,
                    error: null
                }
            })
        case OrderReceivableTypes.GET_ALL_ORDER_RECEIVABLES_ERROR:
            return Object.assign({}, {
                ...state,
                [action.status]: {
                    ...state[action.status],
                    loading: false,
                    error: null
                }
            })
        default:
            return state
    }
}

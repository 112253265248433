import {useEffect, useState} from "react";
import {GraphqlService} from "../../../../services/graphql.service";
import {gql} from "apollo-boost";
import FormGroup from "../../../../components/FormGroup/FormGroup";
import './BolDisclaimer.scss';
import ReactModal from "react-modal";
import useLoading from "../../../../hooks/useLoading/useLoading";
import {useToasts} from "react-toast-notifications";
import BolDisclaimerMutations from "../../../../graphql/mutation/BolDisclaimer";

const bolDisclaimerQuery = gql(`query {
  get_bol_disclaimer
}`);

const BolDisclaimer = () => {
    const [bol, setBol] = useState('');
    const [editBol, setEditBol] = useState(false);
    const [openModal,setOpenModal]=useState(false)

    const toast = useToasts()
    const loading = useLoading()

    useEffect(() => {
        fetchBolDisclaimer();
    }, []);

    const fetchBolDisclaimer = () => {
        GraphqlService.SendQuery(bolDisclaimerQuery).then(bolDisclaimer => {
            setBol(bolDisclaimer);
        }).catch(() => {});
    }

    async function handleSubmit() {
        try {
            loading.open('Submitting payee...');
            const data = await GraphqlService.SendMutation(BolDisclaimerMutations.UPDATE_BOL_DISCLAIMER, {
                key:'BOL_DISCLAIMER',
                value:bol
              
            });
            loading.close();
            if (!data.success)
                throw new Error(data.message);
                toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
       
            } catch (ex) {
                toast.addToast(ex.message, {appearance: 'error',});
                loading.close();
            }
       }
    
    return(
        <>
            <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-between">
                    <p className="font-12 font-medium">BOL disclaimer</p>

                    {
                        !editBol &&
                        <button className="btn btn-blue-light font-10 rounded-pill px-3"
                                onClick={() => {setEditBol(true);setOpenModal(!openModal)}}>EDIT TEXT</button>
                    }
                </div>

                {
                    //!editBol &&
                    <div className="col-12 mt-3">
                        <p style={{whiteSpace: 'pre'}} className="font-12">{ bol }</p>
                    </div>
                }

                {/*
                    editBol &&
                    <FormGroup name={'bol'}
                               className={'mt-3 complete-input'}
                               value={bol}
                               onTextChange={value => setBol(value)}
                               type={"textarea"}/>
                */}

                {/*
                    editBol &&
                    <div className="col-12 text-right mt-3">
                        <button className="btn bg-white rounded-pill font-12 text-light-blue px-4 py-2 pr-3"
                                onClick={() => setEditBol(false)}>CANCEL</button>
                        <button className="btn btn-blue-light rounded-pill font-12 px-4 py-2"
                                onClick={() => setEditBol(false)}>SAVE CHANGES</button>
                    </div>
                */}


                <ReactModal isOpen={openModal} onRequestClose={evt => setOpenModal(false)}>
                            <FormGroup name={'bol'}
                               className={'mt-3 complete-input'}
                               value={bol}
                               onTextChange={value => setBol(value)}
                               type={"textarea"}/>
                                <div className="col-12 text-right mt-3">
                                    <button className="btn bg-white rounded-pill font-12 text-light-blue px-4 py-2 pr-3"
                                            onClick={() => {setEditBol(false);setOpenModal(false)}}>CANCEL</button>
                                    <button className="btn btn-blue-light rounded-pill font-12 px-4 py-2"
                                            onClick={() => {setEditBol(false);handleSubmit()}}>SAVE CHANGES</button>
                                </div>
                 </ReactModal>
            </div>
        </>
    )
}


export default BolDisclaimer;
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {ResultWithLink} from "../../customer_profile/customer_profile";

export const send_master_order_invoices = (input:{master_order_ids: number[], emails: string[]}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($master_order_ids: Int[], $emails: String[]) {
      send_master_order_invoices(master_order_ids: $master_order_ids, emails: $emails) {
        id
        link
        success
        message
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {master_order_ids, emails} as 
  Promise<ResultWithLink>;
}

export const genarate_invoice_pay_invoice_link = (input:{master_order_id: number}) : Promise<string> => {
  let mutation = gql`
    mutation($master_order_id: Int) {
      send_master_order_invoices(master_order_id: $master_order_id)
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {master_order_id} as 
  Promise<string>;
}
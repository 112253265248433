import gql from "graphql-tag";

const GET_BY_ID = gql`
    query ($master_order_id: Int, $verify: Boolean){
  get_master_order_by_id(master_order_id: $master_order_id, verify: $verify) {
    id
    card{
      id
      cardholder_name
      number
      type
      expired
  }
    needs_invoice
    logs {
      time
      user_id
      message
    }
    
    customer {
      id
      name
      corporate_client
      key
      billing_address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
        }
    }
    time
    started
    completed
    price
    payment_left
    terms
    canceled
    references {
      key
      value
    }
    service_charges{
        id
        amount
        tax_amount
        base_amount
        amount_remaining
        name
      }

    contacts {
      id
      email
      name
      role
      contact {
        title
        value
        type
      }
    }
    vehicle_orders {
      id
      time
      bol_comment
      time_changed
      driver_note
      started
      delivered
      inspections {
                    id
                    user {
                      id
                      name
                    }
                    type
                    time
                }
      storage_order{
                id
                billing_cycle
                rate
                date_last_issued
                note
                warehouse {
                  id
                }
                log{
                  user_id
                  message
                  time
                }
                time
                active
                departure_date
               
            }
      charges{
        id
        amount
        tax_amount
        base_amount
        amount_remaining
        name
        inventory_service {
          service_charge_id
          time_start
          time_end
          time_completed
          completed
        }
        storage_charge {
            id
            period_start
            period_end
            price
           
          }
      }
      vehicle {
        id
        vin
        year
        make
        model
        submodel
        type
        time_created
        no_vin
        special_vin
        internal_note
        incomplete_vin
        color
        license_plate
      }
      legs {
        id
        time
        status
        time_completed
        completed
        canceled
        equipment
        velox_order_id
        carrier {
            id
            name
          }
        driver {
          id
          name
          carrier {
            id
            name
          }
        }
        service_charges {
          id
          amount
          tax_amount
          base_amount
          name
          amount_remaining
          voided
          note
          
        }
        stops {
          id
          is_pickup
          time_scheduled
          time_completed
          completed
          started
          canceled
          nofail
          log {
            time
            user_id
            message
          }
          time_frame {
            start
            end
          }
          location{
            id
            title
            customer {
              id
              name
              key
            }
            address{
              street_number
              street
              city
              state
              postal_code
              postal_code_suffix
              country
            }
          }
          service_charges{
            id
            amount
            tax_amount
            base_amount
            name
            amount_remaining
            voided
            note
          }
          location{
            id
            title
            customer {
                id
                name
                key
                corporate_client
            }
            address{
              street_number
              street
              city
              state
              postal_code
              postal_code_suffix
              country
            }
          }
          contacts {
            id
            name
            email
            title
            role
          }
        }
      }
    }
  }
}

`;

const GET_INVOICES = gql`
query($order_id: Int) {
  get_master_order_invoice_charge(master_order_id: $order_id) {
    contacts {
      id
      email
      name
      title
      contact {
        title
        value
        type
      }
    }
    vehicle_orders {
      service_charges {
        id
        amount
        tax_amount
        base_amount
        name
      }

      vehicle {
        vin
        description
        vehicle_order_id
      }
      transportation_charges {
        amount
        pickup {
          title
          address {
            city
            state
            street
            country
            postal_code
          }
          is_pickup
          time_scheduled
        }
        dropoff {
          title
          address {
            city
            state
            street
            country
            postal_code
          }
          is_pickup
          time_scheduled
        }
      }
    }
    service_charges {
      id
      amount
      tax_amount
      base_amount
      name
    }
  }
}
`

const MasterOrderQueries = {
    GET_BY_ID,
    GET_INVOICES
};


export default MasterOrderQueries;
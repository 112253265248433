import {Customer} from "../customer/customer";

export class Customer_Profile {
    __typename?: "Customer_profile"
    id?: number = null;
    email?: string = '';
    verified?: boolean = false;
    name?: string = '';
    role?: string = '';
    customer?: Customer = new Customer();
    contact?: ConRes[] = [];
    title?: string = '';
    active?: boolean = false; // Attention
    
    constructor(x?: Customer_Profile){
        if(x){
            this.id = x.id;
            this.email = x.email;
            this.verified = x.verified;
            this.name = x.name;
            this.role = x.role;
            this.customer = new Customer(x.customer);
            this.contact = x.contact?.map(z => z) || [];
            this.title = x.title;
            this.active = x.active;      
        }
    }
}

export class CustomerProfileSearchList {
    __typename?: "customerProfileSearchList"
    id?: number = null;
    name?: string = '';
    email?: string = '';
    title?: string = '';
    numbers?: string[] = [];
    contact?: ConRes[] = [];
    
    constructor(x?: CustomerProfileSearchList){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.email = x.email;
            this.title = x.title;
            this.numbers = x.numbers?.map(z => z) || [];
            this.contact = x.contact?.map(z => z) || [];           
        }
    }
}

export class CpRes {
    __typename?: "cpRes"
    message?: string = '';
    profile?: ProfileSp = new ProfileSp();
    success?: boolean = false;  // Attention

    constructor(x?: CpRes) {
        if(x) {
            this.message = x.message;
            this.profile = new ProfileSp(x.profile);
            this.success = x.success;
        }
    }
}

export class ConRes {
    __typename?: "conRes"
    title?: string = '';
    value?: string = '';
    type?: string = '';
    unsubscribed?: boolean = false;
    constructor(x?: ConRes) {
        if(x){
            this.title = x.title;
            this.value = x.value;
            this.type = x.type;
            this.unsubscribed = x.unsubscribed;            
        }
    }
}

export class ProfileSp {
    __typename?: "profileSP"
    id?: number = null;
    role?: string = '';
    customer_id?: number = null;
    name?: string = '';
    title?: string = '';
    contact?: ConRes[] = [];

    constructor (x?: ProfileSp) {
        if(x){
            this.id = x.id;
            this.role = x.role;
            this.customer_id = x.customer_id;
            this.name = x.name;
            this.title = x.title;
            this.contact = x.contact?.map(z => z) || [];            
        }
    }
}

export class ProfileInput {
    id: number = null;
    name?: string = '';
    role?: string = '';
    contact?: ContactInput[] = [];
    title?: string = '';
    active?: boolean = false;
    email?: string = '';

    constructor(x?: ProfileInput){
        if(x) {
            this.id = x.id;
            this.name = x.name;
            this.role = x.role;
            this.contact = x.contact?.map(z => z) || [];
            this.title = x.title;
            this.active = x.active;
            this.email = x.email;
            this.constructor = x.constructor;           
        }
    }
}

export class ProfileInputVerify {
    id: number = null;
    name: string = '';
    password: string = '';
    contact: ContactInput[] = [];
    title: string = '';
    constructor(x?: ProfileInputVerify){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.password = x.password;
            this.contact = x.contact?.map(z => z) || [];
            this.title = x.title;            
        }
    }
}

export class ContactInput {
    title: string = '';
    value: string = '';
    type: string = '';

    constructor (x?: ContactInput){
        if(x){
            this.title = x.title;
            this.value = x.value;
            this.type = x.type;
        }
    }
}

export class DTRes {
    __typename?: "dTRes"
    profile?: Customer_Profile = new Customer_Profile();
    redirect_link?: string = '';

    constructor(x?: DTRes){
        if(x){
            this.profile = new Customer_Profile(x.profile);
            this.redirect_link = x.redirect_link;
        }
    }
}

export class ResultWithLink {
    __typename?: "ResultWithLink"
    id?: number = null;
    message?: string = '';
    success?: boolean = false;  // Attention
    link?: string = '';

    constructor(x?: ResultWithLink){
        if(x){
            this.id = x.id;
            this.message = x.message;
            this.success = x.success;
            this.link = x.link;           
        }
    }
}

import React, { FC, useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import ICONS from '../../../assets/svg';
import environments from '../../../environments/environments';
import { Carrier, Driver } from '../../classes/classes';
import CarrierQueries from '../../graphql/query/Carrier';
import DriverQueries from '../../graphql/query/Driver';
import { GraphqlService } from '../../services/graphql.service';
import Loading from '../Loading/Loading';
import Searchbar from '../Searchbar/Searchbar';


enum Tabs
{
    CARRIERS,
    DRIVERS
}

interface ICarrierDriverPicker
{

    carrier: Carrier,
    driver: Driver,
    onDriverChanged?: (driver: Driver) => any,
    onCarrierChanged?: (carrier: Carrier) => any,
    defaultTab: Tabs
}





const CarrierDriverPicker: FC<ICarrierDriverPicker> = (props) => {



    const searchRef = useRef<HTMLInputElement>();
    const toast = useToasts();

    const [loadingCarriers, setLoadingCarriers] = useState(false);
    const [loadingDrivers, setLoadingDrivers] = useState(false);

    const [drivers, setDrivers] = useState<any[]>([]);
    const [carriers, setCarriers] = useState<any[]>([]);


    const [searchTerm, setSearchTerm] = useState('');

    const tabs = [
        {
            label: 'Carriers',
            value: Tabs.CARRIERS,
            getCount: () => carriers.length
        },
        {
            label: 'Drivers',
            value: Tabs.DRIVERS,
            getCount: () => drivers.length
        }
    ];


    async function loadCarriers() {
        try {
            setLoadingCarriers(true);
            const data = await GraphqlService.SendQuery(CarrierQueries.GET_AGING, {showempty: true});

            setCarriers(data.map(x => ({id: x.carrier_id, name: x.name, orders_dispatched: x.orders_dispatched, drivers_dispatched: x.drivers_dispatched})).sort((x, y) => x.name > y.name ? 1 : -1));

            setLoadingCarriers(false);

        } catch (ex) {
            setLoadingCarriers(false);
            toast.addToast(ex.message, {appareance: 'error', autoDismiss: true});
        }
    }

    async function loadDrivers()
    {
        setLoadingDrivers(true);
        try {
            const data = await GraphqlService.SendQuery(DriverQueries.GET_BY_CARRIER_ID, {carrier_id: environments.JP_CARRIER_ID});

            
            setDrivers(data.map(x => ({id: x.driver.id, name: x.driver.name,
            
                open_orders: x.driver.itinerary.sumBy(y => y.orders.length)
            })).sort((x, y) => x.name > y.name ? 1 : -1));

            setLoadingDrivers(false);
            
        } catch (ex) {
            setLoadingDrivers(false);
            toast.addToast(ex.message, {appareance: 'error', autoDismiss: true});
        }
    }

    const getCarriersFiltered = () => carriers.filter(x => x.name.toLowerCase().includes(searchTerm.toLowerCase().trim()));
    const getDriversFiltered = () => drivers.filter(x => x.name.toLowerCase().includes(searchTerm.toLowerCase().trim()));

    function load()
    {
        loadCarriers();
        loadDrivers();
    }

    const CarriersList: FC = () =>
    {
        if (loadingCarriers)
            return <Loading />
        return <>{getCarriersFiltered().map((carrier, index) => <div onClick={(evt) => props.onCarrierChanged?.(carrier)} className={`cursor-pointer light-hover p-2 ${index == carriers.length - 1 ? '': 'mb-2'} ${props.carrier?.id == carrier?.id  ? 'bg-gold' : 'bg-gray'}`}>
            {/* <p>{props.carrier?.id}</p> */}
            <h6 className='font-10 mb-1'>{carrier?.name}</h6>
            <p className="font-10">{carrier?.orders_dispatched} order{carrier?.orders_dispatched == 1 ? '' : 's'} dispatched</p>
        </div>)}</>
    }

    const DriversList: FC = () => {
        if (loadingDrivers)
            return <Loading />
        return <>{getDriversFiltered().map((driver, index) => <div onClick={(evt) => props.onDriverChanged?.({...driver, carrier: carriers.find(x => x.id == environments.JP_CARRIER_ID) || {id: environments.JP_CARRIER_ID, name: environments.JP_CARRIER_NAME}})} className={`cursor-pointer light-hover p-2 ${index == drivers.length - 1 ? '': 'mb-2'} ${props.driver?.id == driver?.id  ? 'bg-gold' : 'bg-gray'}`}>
            {/* <p>{props.carrier?.id}</p> */}
            <h6 className='font-10 mb-1'>{driver?.name}</h6>
            <p className="font-10">{driver?.open_orders} order{driver?.open_orders == 1 ? '' : 's'} dispatched</p>
        </div>)}</>
    }

    useEffect(() => {
        load();
        setCurrenTab(props.defaultTab);
    }, []);

    
    


    

    const [currentTab, setCurrenTab] = useState(Tabs.CARRIERS);


    useEffect(() => {

        searchRef.current?.focus();
    }, [currentTab]);

    return <div className='flex-1-container col-12'>
       <div className="d-flex mb-3">
            {tabs.map((tab, index) => <div key={'tab-picker-' + index} className='mr-3'>
                <h4 onClick={evt => setCurrenTab(tab.value)} className={"transition-3-all-ease cursor-pointer font-10 mb-2" + (currentTab == tab.value ? '' : ' text-gray')}>{tab.label} ({tab.getCount()})</h4>
                <hr className={`${tab.value == currentTab ? 'bg-blue-light ' : ''}rounded transition-3-all-ease`} style={{height: '3px', width: '20px', border: 'none'}} />
            </div>)}
       </div>

       <div className="mb-3 d-flex align-items-center">
           <div className="col-12 mr-2">
               <Searchbar value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)} reference={searchRef} placeholder='Search...' background='#F3F6F8' />
           </div>
           <button className='btn btn-icon-only' onClick={(evt) => load()}>
               <img src={ICONS.IconRefresh} className={loadingCarriers || loadingDrivers ? 'spin' : ''} alt="" />
           </button>
       </div>


        <div className='flex-1-container'>
            {currentTab == Tabs.CARRIERS && <CarriersList />}
            {currentTab == Tabs.DRIVERS && <DriversList />}
        </div>

    </div>
}

export default CarrierDriverPicker;
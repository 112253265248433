import React, {useState, useRef, useEffect} from "react";
import './CardInspection.scss'
import ICONS from "../../../../../assets/svg";
import CarImage2 from '../../../../../assets/images/tesla3.jpg';
import FormGroup from "../../../../components/FormGroup/FormGroup";
import Accessories from "../../pages/Accessories/Accessories";
import ReactModal from "react-modal";
import {gql} from 'apollo-boost';
import {GraphqlService} from "../../../../services/graphql.service";

const CardInspection = ({onSelect, onSubmit, onPhotoTaken = null, name, image = "", custom = "off", count = 0, onRemove = null}) => {

    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [accessories, setAccessories] = useState({
        name: '',
        count: 0
    })


    const [myCount, setMyCount] = useState(0);


    useEffect(() => {
        setAccessories({name, count: 0})
    }, [name])

    useEffect(() => {
        setMyCount(count);
        setAccessories({name, count})
    }, [count]);



    const [device, setDevice] = useState(false)
    const [tempImg, setTempImg] = useState<any>()
    const [img, setImage] = useState<any>()
    const [valueOdometer, setValueOdometer] = useState('')

    const MobileC = () => {
        if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) {
            return setDevice(true)
        } else {
            return setDevice(false)
        }
    }
    const [tempLink, setTempLink] = useState('');

    async function getTempLink(is_thumbnail = true) {
        const query = gql`
            query($uid:String, $is_thumbnail:Boolean){
                get_temp_link(uid:$uid is_thumbnail:$is_thumbnail)
            }
        `;
        const variables = {uid: image, is_thumbnail}

        try {
            const data = await GraphqlService.SendQuery(query, variables);
            return data;

        } catch (ex) {
            return '';
        }
    }

    useEffect(() => {
        getTempLink().then(d => setTempLink(d));
    }, [image]);


    const nodeContainer = useRef<HTMLDivElement>()
    const photoPhoneref = useRef<any>()

    // useEffect(() => {
    //     let handleClickOut = (event: any) => {
    //         if (!nodeContainer.current.contains(event.target ) ) {
    //             setOpen(false)
    //             custom === 'on'&& setAccessories({...Accessories,name:'',count:0})
    //
    //         }
    //     }
    //     document.addEventListener("mousedown", handleClickOut)
    //
    //
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOut)
    //     }
    //
    // },)


    const handleSubmit = () => {
        onSubmit({...accessories}, valueOdometer)
    }

    const takePhoto2 = (e) => {
        var image = new Image();
        // let url = URL.createObjectURL(e.target.files[0]);
        if (onPhotoTaken)
            onPhotoTaken(e.target.files[0])
        // console.log(url)
        setOpenModal(true)
        // setTempImg(url)
        if (name !== 'Odometer') {
            // setImage(url)
        }
        // setImage(url)
    }


    useEffect(() => {
        MobileC()
    })

    return (
        <>
            <div onClick={() => {
                //    if (!image)
                console.log('click');
                console.log(name);
                photoPhoneref.current?.click()
                !device && onSelect(name)
                ;setOpen(true);onSelect(name)
            }} className={`position-relative inspection-card`}>
                {device && custom === 'off' && !image &&
                <input onChange={(e) => takePhoto2(e)} ref={photoPhoneref} className={`input-capture`} type="file"
                       accept="image/*" capture/>}


                <div className={`cardinspection mb-2 ${open && custom != 'off' ? 'active' : ''}`}
                     style={{backgroundImage: `url(${tempLink || img})`}}>
                    {
                        custom === 'off' && tempLink ?
                            <div className="label-card">
                                {name}
                            </div>
                            :
                            <div className=" d-flex flex-column align-items-center justify-content-center "
                                 style={{top: 0, left: 0, width: '100%', height: '100%'}}>

                                <div className="position-relative">
                                    {(myCount > 0 && name.toLowerCase() != 'custom') &&
                                    <div className="notfication-Item">
                                        {myCount}
                                    </div>
                                    }
                                    <img src={ICONS.IconUser}/>
                                </div>
                                <span className="font-medium-i fs-12 mt-1 ">{name}</span>

                                <div
                                    className={`mt-2 align-items-center justify-content-center ${open && custom === 'addItem' ? 'd-flex' : 'd-none'}`}>
                                    <button className={'btn btn-icon-only bg-blue text-blue'}
                                            disabled={accessories.count <= 0 ? true : false}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setAccessories({...accessories, count: accessories.count + -1})
                                            }}>-
                                    </button>
                                    <span className={'mr-2 ml-2 fs-14 font-medium'}>{accessories.count}</span>
                                    <button className={'btn btn-icon-only bg-blue  text-blue'}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setAccessories({...accessories, count: accessories.count += 1});
                                                handleSubmit();
                                            }}>+
                                    </button>
                                </div>
                            </div>

                    }

                </div>


                {/*Modal*/}
                <ReactModal onRequestClose={(e) => {
                    setOpen(false);
                    setAccessories({name, count});
                    e.stopPropagation();
                }} className={`modal-fit modal-90 p-0`} isOpen={open && custom === 'on'}>
                    <div className={`popUp-card  ${open && custom === 'on' ? '' : 'd-none'}`}>
                        <div className="row">
                            <h4 className={'font-medium fs-14'}>Add accessory</h4>
                            <h4 onClick={(evt) => {
                                evt.stopPropagation();
                                setOpen(false);
                                
                                console.log('Close')
                            }} className={'font-medium fs-14'}><i className='fas fa-times' /></h4>
                        </div>

                        <FormGroup name={'item_name*'}
                                   value={accessories?.name == 'Custom' ? '' : accessories?.name}
                                   placeholder={`Item name`}
                                   required={false}
                                   label={'Item name'}
                                   className={'mt-3'}
                                   onTextChange={value => setAccessories({...accessories, name: value})}/>

                        <span className={'mt-3 font-normal fs-12'}>Quantity*</span>

                        <div className={`mt-3 d-flex align-items-center justify-content-start ml-3`}>
                            <button className={'btn btn-icon-only bg-blue text-blue'}
                                    disabled={accessories.count <= 0 ? true : false}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setAccessories({...accessories, count: accessories.count + -1})
                                    }}>-
                            </button>
                            <span className={'mr-4 ml-4 fs-14 font-medium'}>{accessories.count}</span>
                            <button className={'btn btn-icon-only bg-blue text-blue'}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setAccessories({...accessories, count: accessories.count += 1})
                                    }}>+
                            </button>
                        </div>

                        <div className={'d-flex justify-content-end p-2 mt-4'}>
                            <div className={'row col-12'}>

                                <button className="btn btn-clear text-blue-light rounded-pill col-6 fs-12" onClick={(evt) => {
                                    setOpen(false);
                                    onRemove(accessories.name);
                                    evt.stopPropagation();
                                    
                                }}>
                                    REMOVE
                                </button>

                                <button disabled={accessories?.name === '' || accessories?.count <= 0 ? true : false}
                                        className={"btn col-6 py-2 btn-blue-light rounded-pill fs-12"}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setAccessories({name, count});
                                            handleSubmit();
                                            setOpen(false)
                                        }}
                                >ADD ACCESSORY
                                </button>
                            </div>
                        </div>
                    </div>
                </ReactModal>
            </div>

            {/* { name === 'Odometer' && <ReactModal isOpen={openModal } onRequestClose={evt => setOpenModal(false)}>
                <img src={tempImg} className="h-100" alt="" />

                 <div className={'container-odometer'}>
                            <FormGroup  name={'odometer*'}
                                    value={valueOdometer}
                                    required={false}
                                    label={'Enter the odometer reading'}
                                    className={'mt-3'}
                                    onTextChange={value => setValueOdometer(value)}/>




                            <div className={'w-100 '}>
                                <button
                                disabled={valueOdometer === '' && true}
                                className={"btn w-100 py-2 btn-blue-light rounded-pill fs-12 mt-4 font-medium-i"}
                                onClick={(e)=>{
                                    //handleSubmitImage()
                                    setImage(tempImg)
                                    handleSubmit()
                                    setOpenModal(false)
                                    setValueOdometer('')
                                }}
                                >SAVE</button>
                            </div>
                        </div>

            </ReactModal>
            } */}
        </>
    )
}

export default CardInspection
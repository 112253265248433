import gql from "graphql-tag";

const ADD_VELOX_DRIVER = gql`
    mutation($velox_id:Int, $employee_id:Int, $samsara_id: String, $equipment_id: Int){
        create_driver(velox_id:$velox_id, employee_id:$employee_id, samsara_id: $samsara_id, equipment_id: $equipment_id){
            id
            message
            success
        }
    }
`;


const DriverMutations = {
    ADD_VELOX_DRIVER,

};

export default DriverMutations

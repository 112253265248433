import IReducerAction from "../models/IReducerAction";
import CustomerTypes from "../types/Customer.type";
import CarrierTypes from "../types/Carrier.types";
import environments from "../../../environments/environments";



export interface ICarrierState
{
    id: number,
    name: string
}

const initialState: ICarrierState = {
    id: environments.JP_CARRIER_ID,
    name: environments.JP_CARRIER_NAME
}

export default function CustomerReducer(state: ICarrierState = initialState, action: IReducerAction): ICarrierState
{
    switch (action.type)
    {
        case CarrierTypes.SET_CARRIER:
            return {
                ...action.payload
            }
        default:
            return state;
    }
}
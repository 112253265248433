import {gql, ObservableQuery} from "apollo-boost";
import { CustomerProfile } from "../../classes/classes";
import Helper from "../../helper/Helper";
import {GraphqlService, ObservableQueryT} from "../../services/graphql.service";
import OrderReceivableTypes from "../types/Receivables.type";

// TODO: CHANGE QUERY
const query = gql`
    query{
        get_open_master_order{
            id
            time
            delivered
            started
            completed
            price
            payment_left
            last_invoice_amount
            needs_invoice
            terms
            non_move
            picked_up
            payment_due
            invoices{
                id
                time
                time_due
            }
            references{
                key
                value
            }
           
            customer{
                id
                name
                corporate_client
                terms
                billing_address{
                city
                state
                street
                country
                postal_code
                street_number
                postal_code_suffix
            }
            }
            contacts{
                id
                name
                email
                contact{
                    title
                    value
                    type
                }
            }
        }
    }
`
export default function getReceivables(status = 'all') {
    return async dispatch => {
        if (status == 'all') {
            // const statuses = ['open', 'closed', 'aging', 'payments'];
            const statuses = ['open'];

            for (const stat of statuses) {
                dispatch(getOrderRequestByStatusAction(true, stat));
                GraphqlService
                    .SendQuery(query)
                    .then((data) => {
                        dispatch(getAllOrderRequestByStatusSuccessAction(data.map(x => {
                            if (x.invoices == null)
                                x.invoices = [];
                            // console.log(e.invoice_id)

                            
                            x._ref = x.references.map(x => `${x.key}: ${x.value}`);

                            x.invoices = x.invoices.sort((x, y) => x.id < y.id ? 1 : -1);

                            
                            x.first_invoice_id = x.invoices[0]?.id ?? 0;

                            if (x.invoices.length > 2) {

                                x.due = [Helper.FORMAT.USDate(x.invoices[0].time_due, 'do'), Helper.FORMAT.USDate(x.invoices[1].time_due, 'do'), '+' + (x.invoices.length - 2)];
                                x.sent_on = [Helper.FORMAT.USDate(x.invoices[0].time, 'do'), Helper.FORMAT.USDate(x.invoices[1].time, 'do'), '+' + (x.invoices.length - 2)];
                                x.invoices_formatted = ['I-' + x.invoices[0].id, 'I-' + x.invoices[1].id, '+' + (x.invoices.length - 2)];
                            }

                            // if (x.invoices.length > 2)


                            else if (x.invoices.length >= 1) {

                                x.due = x.invoices.map(y => Helper.FORMAT.USDate(y.time_due, 'do'));
                                x.sent_on = x.invoices.map(y => Helper.FORMAT.USDate(y.time, 'do'));
                                x.invoices_formatted = x.invoices.map(x => 'I-' + x.id);
                            }
                            else if (x.invoices.length == 0) {
                                x.due = ['-'];
                                x.sent_on = ['-'];
                                x.invoices_formatted = ['-'];
                            }

                            const defaultEmails = (x.contacts as CustomerProfile[]).map((x: CustomerProfile) => [x.email, ...x.contact?.filter(x => x.type?.toLowerCase() == 'email').map(x => x.value)]).flatMap(x => x);

                            return  {
                                ...x,
                                needs_invoice: x.needs_invoice,
                                master_column: ['M-' + x.id, x.non_move ? 'NON MOVE' : (x.delivered ? 'DELIVERED' : (x.picked_up ? 'PICKED UP' : 'NOT PICKED UP'))],
                                master_id: x.id,
                                invoice_column: x.invoices_formatted,
                                first_invoice_id: x.first_invoice_id,
                                time_in: [Helper.FORMAT.USDate(x.time, 'do'), Helper.FORMAT.USDate(x.time, 'to')],
                                customer: x.customer.name,
                                contact: [x.contacts[0]?.name, x.contacts[0]?.email, (x.contacts.length > 1 ?  '+' + (x.contacts.length - 1) : '') ],
                                terms: x.terms || '-',
                                status: x.needs_invoice ? 'NEEDS INVOICE':'SENT',
                                price: Helper.FORMAT.USCurrency(x.price),
                                balance_left: Helper.FORMAT.USCurrency(x.payment_left),

                                customer_id: x.customer.id,
                                references: x.references,
                                last_invoice_price: Helper.FORMAT.USCurrency(x.last_invoice_amount),
                                last_invoice_amount: x.last_invoice_amount,

                                default_emails: defaultEmails
                            }}), stat)); // Replace new Object by the custom class
                    })
                    .catch((ex) => {
                        // dispatch(getAllOrderRequestByStatusErrorAction(ex.message, stat));
                    });
            }

        } else {
            dispatch(getOrderRequestByStatusAction(true, status));
            try {
                let data = await GraphqlService.SendQuery(query, {filter: status});
                dispatch(getAllOrderRequestByStatusSuccessAction(data.map(x => Object.create(new Object(x))), status)); // Replace new Object by the custom class
            } catch (ex) {
                dispatch(getAllOrderRequestByStatusErrorAction(ex.message, status));
            }
        }

    }
}

const getOrderRequestByStatusAction = (payload, status = '') => ({
    type: OrderReceivableTypes.GET_ALL_ORDER_RECEIVABLES_STARTED,
    payload,
    status
});

const getAllOrderRequestByStatusSuccessAction = (payload, status = '') => ({
    type: OrderReceivableTypes.GET_ALL_ORDER_RECEIVABLES_SUCCESS,
    payload,
    status
});

const getAllOrderRequestByStatusErrorAction = (payload, status = '') => ({
    type: OrderReceivableTypes.GET_ALL_ORDER_RECEIVABLES_ERROR,
    payload,
    status
});


import './CustomerContactQuoteEdit.scss'
import {useState} from "react";
import FormGroup from "../FormGroup/FormGroup";

const CustomerContactQuoteEdit = ({onCancel, onSaved, contactQuote}) => {
    const [currentQuote, setCurrentQuote] = useState(contactQuote);

    return(
        <>
            <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center mb-4">
                    <p className="font-medium font-14">Edit customer contact</p>
                    <i className="ti-close font-12 cursor-pointer" onClick={() => onCancel()}></i>
                </div>

                <FormGroup name={'contactName'}
                           value={currentQuote.full_name}
                           label={'Contact Name'}
                           onTextChange={(value) => setCurrentQuote({...currentQuote, full_name: value})}/>
                <FormGroup name={'role'} label={'Role'}/>

                <div className="col-12 mt-3 mb-3">
                    <p className="font-12 font-medium text-light-blue">Contact info 1</p>
                </div>

                <FormGroup name={'role'}
                           value={currentQuote.phone_1}
                           label={'Phone #*'}/>
                <FormGroup name={'role'}
                           value={currentQuote.company_name}
                           label={'Nickname for this contact*'}/>

                <div className="col-12 mt-3 mb-3">
                    <p className="font-12 font-medium text-light-blue text-right"><i className="ti-plus"></i> ADD CONTACT INFO</p>
                </div>

                <div className="col-6 mt-1">
                    <button className={`btn btn-block rounded-pill btn-clear`}
                            onClick={() => onCancel()}>CANCEL</button>
                </div>
                <div className="col-6 mt-1">
                    <button className={`btn btn-block rounded-pill btn-blue-light`}
                            onClick={() => onSaved(currentQuote)}>SAVE CHANGES</button>
                </div>
            </div>
        </>
    );
}

export default CustomerContactQuoteEdit;

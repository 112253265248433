import gql from "graphql-tag";

const DECODE_VIN = gql`
    query ($vin: String!){
        decode_vin(vin:$vin){
            year
            make
            model
            submodel
            type
        }
    }
`;

const GET_ALL_MAKES = gql`
    query {
        get_all_makes {
            Make_ID
            Make_Name
        }
    }
`;

const GET_MODELS_MAKE = gql`
    query($make: String) {
        get_models_for_make(make: $make) {
            Make_ID
            Make_Name
            Model_ID
            Model_Name
        }
    }
`

const VehicleQueries = {
    DECODE_VIN,
    GET_ALL_MAKES,
    GET_MODELS_MAKE
}

export default VehicleQueries;
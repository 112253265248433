import employeeTypes from "../types/employee.types";
import {Employee} from "../../classes/classes";
import {GraphqlService} from "../../services/graphql.service";
import employeeQuery from "../../graphql/query/employee";

const fetchEmployeeRequest = () => {
    return {
        type: employeeTypes.FETCH_ALL_EMPLOYEES_REQUEST
    }
}

const fetchEmployeeSuccess = (employees: Employee[]) => {
    return {
        type: employeeTypes.FETCH_ALL_EMPLOYEES_SUCCESS,
        payload: employees
    }
}

const fetchEmployeeFailure = (error: string) => {
    return {
        type: employeeTypes.FETCH_ALL_EMPLOYEES_FAILURE,
        payload: error
    }
}

export const fetchEmployees = () => {
    return async (dispatch) => {
        dispatch(fetchEmployeeRequest());
        GraphqlService.SendQuery(employeeQuery.GET_ALL_EMPLOYEES).then(employees => {
            dispatch(fetchEmployeeSuccess(employees));
        }).catch(error => {
            dispatch(fetchEmployeeFailure(error));
        })
    }
}
import { gql } from 'apollo-boost';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useToasts } from 'react-toast-notifications';
import ICONS from '../../../../../assets/svg';
import RequestInspectionForm from '../../../../components/LegTable/RequestInspection/RequestInspection';
import Searchbar from '../../../../components/Searchbar/Searchbar';
import Status from '../../../../components/Status/Status';
import IMenuOption from '../../../../components/Table/models/IMenuOption';
import Helper from '../../../../helper/Helper';
import useLoading from '../../../../hooks/useLoading/useLoading';
import useMessageBox from '../../../../hooks/useMessageBox/useMessageBox';
import { Queries } from '../../../../services/endpoints/queries';
import { Storage_Vehicle_Order_Sp } from '../../../../services/endpoints/schemas/storage_order/storage_order';
import { GraphqlService } from '../../../../services/graphql.service';
import { SafeStorage } from '../../../../services/Storage/storageService';
import InventoryOrderDetails from '../InventoryOrderDetails/InventoryOrderDetails';
import InventoryTable from '../InventoryTable/InventoryTable';


const movesQuery = gql`
    query($warehouse_id:Int){
  get_open_inventory_orders(warehouse_id:$warehouse_id){
    id
    price
    customer{
      id
      name
      key
      terms
      active
      internal_note
      corporate_client
    }
    canceled
    payment_left
    vehicle_order{
      id
      warehouse_id
      master_order_id
      pending_inspection_id
      charges{
        id
        name
        note
        voided
        base_amount
        tax_amount
        amount
        amount_remaining
        inventory_service{
          time_end
          time_start
          completed
          service_charge_id
        }
      }
      started
      vehicle{
        id
        year
        make
        model
        color
        vin
      }
      delivered
      storage_bay{
        id
        label
        position{
          x
          y
        }
        storage_section{
          id
          name
          rows
          columns
          position{
            x
            y
          }
          warehouse{
            id
            name
            address{
              street_number
              street
              city
              state
              postal_code
              postal_code_suffix
              country
            }
          }
          columns_format{
            format
            reverse
            starting_value
          }
        }
      }
      storage_order{
        id
        note
        rate
        active
        billing_cycle
        departure_date
        date_last_issued
        vehicle_order_id
      }
      inventory_orders{
        id
        note
        completed
        carrier{
          name
          driver
          velox_order_id
        }
        stop_id
        incoming
        time_due
        time_completed
        warehouse_id
        vehicle_order_id
      }
    }
    last_invoice_amount
  }
}
`;





export class RequestInpsection
{
    vehicle_order_id: number = null;
    type: string = '';
    phones: string[] = [];
    emails: string[] = [];
    id: number;

    constructor(props?: RequestInpsection)
    {
        if (props)
        {
            this.vehicle_order_id = props.vehicle_order_id;
            this.type = props.type;
            this.phones = [...props.phones];
            this.emails = [...props.emails];
            this.id = props.id;
        }
    }


    isValid()
    {
        if (!this.vehicle_order_id) return false;

        if (this.phones.length == 0 || this.emails.length == 0) return false;

        return (this.phones.some(x => !Helper.Validators.IsValidUSPhone(x)) && this.phones.some(x => !Helper.Validators.IsValidUSPhone(x)))
    }
}


enum Tabs
{
    MOVES,
    INVENTORY,
    STORAGE
}

const WarehouseTables: FC<{section_id: number, onRowClicked?: (id) => void, vehicle_order_id: number, warehouse_id: number, reload: number, onRefresh}> = ({section_id, onRowClicked, vehicle_order_id, warehouse_id, reload, onRefresh}) => {

    const [tabSelected, setTabSelected] = useState(Tabs.MOVES);
    const messagebox = useMessageBox();

    const [searchTerm, setSearchTerm] = useState('');

    const [movesLoading, setMovesLoading] = useState(false);
    const [inventoryLoading, setInventoryLoading] = useState(false);
    const [storageLoading, setStorageLoading] = useState(false);

    const [moves, setMoves] = useState([]);
    const [inventories, setInventories] = useState([]);
    const [storages, setStorages] = useState([]);
    const [requestInspection, setRequestInspection] = useState(new RequestInpsection());

    const [voSelected, setVOSelected] = useState<any>(null);

    const toast = useToasts();
    const loading = useLoading();

    async function handlePendingInspectionClicked(pending_inspection_id)
    {
        loading.open('Getting inspection link...');
        const query = gql`query($pending_inspection_id: Int) {
            get_pending_inspection_link(pending_inspection_id: $pending_inspection_id)
          }`
        try {
            const link = await GraphqlService.SendQuery(query, {pending_inspection_id});
            // //console.log(data);
            Object.assign(document.createElement('a'), {
                target: '_blank',
                href: link,
            }).click();
            loading.close();
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            loading.close();
        }
    }


    function calculateStatus(data: Storage_Vehicle_Order_Sp) {
        let inventoryOrder = data.vehicle_order.inventory_orders?.filter(x => !x.completed).sort((x, y) => new Date(x.time_due) > new Date(y.time_due) ? 1 : -1)[0];
        let status = '';
        if(!inventoryOrder){
            status = data.vehicle_order.storage_order?.active && data.vehicle_order.warehouse_id == data.vehicle_order.storage_order?.warehouse_id ? 'PARKED' : 'ATTENTION';
        }
        else{
            status = inventoryOrder.incoming ? 'INCOMING' : (data.vehicle_order.storage_order?.active ? 'SCHEDULED' : 'TRANSIT');
        }
        if(data.vehicle_order.id == 29661){
          console.log(data);
        }
        return (
          <div>
            {data.vehicle_order.inventory_orders?.length && <Status className="mb-1" statusName={status} />}
            {inventoryOrder && <p
              className={
                "fs-11 font-bold " +
                (new Date(inventoryOrder.time_due) < new Date()
                  ? " text-red"
                  : "")
              }
            >
              {Helper.FORMAT.USDate(inventoryOrder.time_due, "do")} <br />{" "}
              {Helper.FORMAT.USDate(inventoryOrder.time_due, "to")}
            </p>}
          </div>
        );
    }
    // const [bayID, setBayID] = useState(null);

    const tabs = [
        {
            label: 'Moves',
            value: Tabs.MOVES,
            key: 'moves'
        },
        {
            label: 'Inventory',
            value: Tabs.INVENTORY,
            key: 'inventory',
        },
        {
            label: 'Storage',
            value: Tabs.STORAGE,
            key: 'storage',
        }
    ];

    const options: IMenuOption[] = [
        {
            label: 'Request Inspection',
            icon: ICONS.IconCreateInvoice,
            condition: x => x.pending_inspection_id == null,
            action: (x: any) => {
                const vo_id = x.vehicle_order_id;

                console.log(vo_id);

                const temp = new RequestInpsection();
                temp.vehicle_order_id = vo_id;
                console.log(temp)
                setRequestInspection(temp);
            }
        },
        {
            label: 'Resend Inspection',
            icon: ICONS.IconEmail,
            condition: x => x.pending_inspection_id != null,
            action: (x: any) => {
                const vo_id = x.vehicle_order_id;

                console.log(vo_id);

                const temp = new RequestInpsection();
                temp.vehicle_order_id = vo_id;
                temp.id = x.pending_inspection_id;
                console.log(temp)
                setRequestInspection(temp);
            }
        },
        
        {
            label: 'See Details',
            icon: ICONS.IconDetails,
            action: (x: any) => {
                setVOSelected({id: x.vehicle_order_id, vehicle: x.vehicle_info, master_order_id: x.master_order_id});
            }
        },
        
        {
            label: 'Master Order Details',
            icon: ICONS.IconDetails,
            action: (x: any) => {
                // setVOSelected({id: x.vehicle_order_id, vehicle: x.vehicle_info, master_order_id: x.master_order_id});
                Helper.Navigation.NavigateTo('/order/' + x.master_order_id)
            }
        }
    ];

    const movesOptions: IMenuOption[] = [
        ...options,
        {
            label: 'Complete Move',
            icon: ICONS.IconCheck,
            action: (x: any) => {
                messagebox.open({title: 'Complete Move', message: 'Are you sure you want to complete this move?', buttons: [
                    {
                        text: 'CANCEL',
                        css: 'btn btn-clear text-blue-light'
                    },
                    {
                        text: 'CONFIRM',
                        css: 'btn btn-blue-light',
                        data: x.move_id,
                        action: id => {
                            completeMove(id)
                        }
                    }
                ]})
            }
        }
    ];

    async function loadInventory()
    {

        setInventoryLoading(true);
        try {
            if(!warehouse_id){
              throw new Error('Warehouse_id is missing');
            }
            const data = await Queries.get_warehouse_vehicle_orders({warehouse_id});
            //console.log(data);
            const rows = data.map(d => {

                const hasIncompletedCharges = d.vehicle_order.charges.filter(x => x.inventory_service?.service_charge_id && !x.inventory_service.completed).length > 0;
                const inventoryOrder = d.vehicle_order.inventory_orders?.filter(x => !x.completed).sort((x, y) => new Date(x.time_due) > new Date(y.time_due) ? 1 : -1)[0];
                // console.log(vehicle_order_id, d.vehicle_order_id);
                return {
                  order_id: [
                    "VO-" + d.vehicle_order.id,
                    "M-" + d.vehicle_order.master_order_id,
                  ],
                  last_move: d.vehicle_order.inventory_orders
                    .filter((x) => x.completed)
                    .sort((x, y) =>
                      new Date(x.time_completed) > new Date(y.time_completed)
                        ? 1
                        : -1
                    )[0],
                  warehouse_id: d.vehicle_order.warehouse_id,
                  id: d.vehicle_order.storage_bay?.id,
                  vehicle_order_id: d.vehicle_order.id,
                  master_order_id: d.vehicle_order.master_order_id,
                  pending_inspection_id: d.vehicle_order.pending_inspection_id,
                  selected: vehicle_order_id == d.vehicle_order.id,
                  bay_number: d.vehicle_order.storage_bay
                    ? [
                        d.vehicle_order.storage_bay?.storage_section.name,
                        d.vehicle_order.storage_bay?.label,
                      ]
                    : ["-"],
                  vehicle: [
                    d.vehicle_order.vehicle.vin || "-",
                    `${d.vehicle_order.vehicle.year}, ${d.vehicle_order.vehicle.make}`,
                    d.vehicle_order.vehicle.model,
                  ],
                  vehicle_info: d.vehicle_order.vehicle,
                  customer: [
                    d.customer.name,
                    d.customer.corporate_client ? "Corporate" : "Private",
                    d.customer.key,
                  ],
                  time_due: inventoryOrder?.time_due || '3000-1-1',
                  time_column: (calculateStatus(d)),
                  carrier_driver: inventoryOrder
                    ? [
                        inventoryOrder.carrier.name,
                        inventoryOrder.carrier.driver || "-",
                      ]
                    : ["-"],
                  inspection: (
                    <>
                      {
                        <div
                          className={
                            d.vehicle_order.pending_inspection_id
                              ? "badge badge-pending d-fkex"
                              : "font-11"
                          }
                          onClick={(evt) =>
                            handlePendingInspectionClicked(
                              d.vehicle_order.pending_inspection_id
                            )
                          }
                        >
                          {d.vehicle_order.pending_inspection_id
                            ? "PENDING"
                            : "-"}
                        </div>
                      }

                      {hasIncompletedCharges && (
                        <>
                          <br />
                          <div className="badge badge-needs-finish-services text-center mt-1">
                            NEEDS
                            <br />
                            SERVICE
                          </div>
                        </>
                      )}
                    </>
                  ),
                };
            })
            // console.log(rows);
            

            setInventoryLoading(false);
            setInventories(rows)
        } catch (ex) {

            setInventoryLoading(false);
            console.log('ex on inventory: ', ex.message);
        }
    }

    async function loadMoves() {
        try {
            setMovesLoading(true);
            const data = await GraphqlService.SendQuery(movesQuery, {warehouse_id})
            // console.log(data.filter(x => x.vehicle_order.storage_bay != null));
            // //console.log(data);
            const rows = data.map(d => {
                const hasIncompletedCharges = d.vehicle_order.charges.filter(x => x.inventory_service?.service_charge_id && !x.inventory_service.completed).length > 0;
                const inventoryOrder = d.vehicle_order.inventory_orders?.filter(x => !x.completed).sort((x, y) => new Date(x.time_due) > new Date(y.time_due) ? 1 : -1)[0];
                return {
                    order_id: ['VO-' + d.vehicle_order.id, 'M-' + d.vehicle_order.master_order_id],
                    move_id: inventoryOrder?.id,
                    id: d.vehicle_order.storage_bay?.id,
                    vehicle_order_id: d.vehicle_order.id,
                    warehouse_id: d.vehicle_order.warehouse_id,
                    pending_inspection_id: d.vehicle_order.pending_inspection_id,
                    master_order_id: d.vehicle_order.master_order_id,
                    selected: vehicle_order_id == d.vehicle_order.id,
                    bay_number: d.vehicle_order.storage_bay ? [d.vehicle_order.storage_bay?.storage_section.name, d.vehicle_order.storage_bay?.label] : ['-'],
                    status: inventoryOrder?.incoming == null ? ['NON MOVE'] : [(inventoryOrder.incoming ? 'INCOMING' : 'OUTGOING')],
                    vehicle: [d.vehicle_order.vehicle.vin || '-', `${d.vehicle_order.vehicle.year}, ${d.vehicle_order.vehicle.make}`, d.vehicle_order.vehicle.model],
                    vehicle_info: d.vehicle_order.vehicle,
                    customer: [d.customer.name, d.customer.is_corporate ? 'Corporate' : 'Private', d.customer.key],
                    time_due: inventoryOrder?.time_due || '3000-1-1',
                    time_column: (calculateStatus(d)),
                    carrier_driver: inventoryOrder ? [inventoryOrder.carrier.name, inventoryOrder.carrier.driver || '-'] : ['-'],
                    inspection: <>{<div className={d.vehicle_order.pending_inspection_id ? 'badge badge-pending d-fkex' : 'font-11'}
                    onClick={(evt) => handlePendingInspectionClicked(d.vehicle_order.pending_inspection_id)}>{d.vehicle_order.pending_inspection_id ? 'PENDING' : '-'}</div>}
                        
                        {
                            hasIncompletedCharges &&
                            <>
                            <br />
                            <div className='badge badge-needs-finish-services text-center mt-1'>
                                NEEDS<br  />SERVICE
                            </div>
                            </>
                        }
                    
                    </>
                    
                }
            })
            
            setMoves(rows);
            onRowClicked(rows.find(x => x.selected) || null);
            setMovesLoading(false);
        } catch (ex) {
            console.log('ex', ex.message);
            setMovesLoading(false);
        }
    }

    async function loadStorage() {
        try {
            setStorageLoading(true);
            const data = await Queries.get_storage_vehicle_orders({active: true, warehouse_id});
            //console.log(data);
            
            const rows = data.map(d => {
                const hasIncompletedCharges = d.vehicle_order.charges.filter(x => x.inventory_service?.service_charge_id && !x.inventory_service.completed).length > 0;
                const inventoryOrder = d.vehicle_order.inventory_orders?.filter(x => !x.completed).sort((x, y) => new Date(x.time_due) > new Date(y.time_due) ? 1 : -1)[0];
                return {
                    order_id: ['VO-' + d.vehicle_order.id, 'M-' + d.vehicle_order.master_order_id],
                    warehouse_id: d.vehicle_order.warehouse_id,
                    id: d.vehicle_order.storage_bay?.id,
                    vehicle_order_id: d.vehicle_order.id,
                    pending_inspection_id: d.vehicle_order.pending_inspection_id,
                    master_order_id: d.vehicle_order.master_order_id,
                    selected: vehicle_order_id == d.vehicle_order.id,
                    bay_number: d.vehicle_order.storage_bay ? [d.vehicle_order.storage_bay?.storage_section.name, d.vehicle_order.storage_bay?.label] : ['-'],
                    status: inventoryOrder?.incoming == null ? ['NON STORAGE'] : [(inventoryOrder.incoming ? 'INCOMING' : 'OUTGOING')],
                    vehicle: [d.vehicle_order.vehicle.vin || '-', `${d.vehicle_order.vehicle.year}, ${d.vehicle_order.vehicle.make}`, d.vehicle_order.vehicle.model],
                    vehicle_info: d.vehicle_order.vehicle,
                    customer: [d.customer.name, d.customer.corporate_client ? 'Corporate' : 'Private', d.customer.key],
                    time_due: inventoryOrder?.time_due || '3000-1-1',
                    time_column: (calculateStatus(d)),
                    carrier_driver: inventoryOrder ? [inventoryOrder.carrier.name, inventoryOrder.carrier.driver || '-'] : ['-'],
                    inspection: <>{<div className={d.vehicle_order.pending_inspection_id ? 'badge badge-pending d-fkex' : 'font-11'}
                    onClick={(evt) => handlePendingInspectionClicked(d.vehicle_order.pending_inspection_id)}>{d.vehicle_order.pending_inspection_id ? 'PENDING' : '-'}</div>}
                        
                        {
                            hasIncompletedCharges &&
                            <>
                            <br />
                            <div className='badge badge-needs-finish-services text-center mt-1'>
                                NEEDS<br  />SERVICE
                            </div>
                            </>
                        }
                    
                    </>
                    
                }
            })
            setStorageLoading(false);
            setStorages(rows);
        } catch (ex) {
            console.log('ex', ex.message);
            setStorageLoading(false);
        }
    }

    const getCount = () => {
      let array = [];

      if (tabSelected == Tabs.MOVES)
          return `${movesFiltered().length} / ${moves.length}`;
      if (tabSelected == Tabs.INVENTORY)
          return `${inventoriesFiltered().length} / ${inventories.length}`;
      if (tabSelected == Tabs.STORAGE)
          return `${storagesFiltered().length} / ${storages.length}`;
      return '0 / 0';
  }

    useEffect(() => {
        setInventories(inventories.map(x => ({...x, selected: x.vehicle_order_id == vehicle_order_id})));
        setStorages(storages.map(x => ({...x, selected: x.vehicle_order_id == vehicle_order_id})));
        setMoves(moves.map(x => ({...x, selected: x.vehicle_order_id == vehicle_order_id})));
    }, [vehicle_order_id]);

    const movesFiltered = () => moves.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim()));
    const inventoriesFiltered = () => inventories.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim()));
    const storagesFiltered = () => storages.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim()));

    useEffect(() => {
        if (reload > 0)
        {
            loadMoves().then();
            loadInventory().then()
            loadStorage().then();
            
        }
    }, [reload]);


    useEffect(() => {
        loadMoves().then();
        loadInventory().then()
        loadStorage().then();
    }, [warehouse_id]);

    

    const ref = useRef();


    async function completeMove(inventory_order_id) {
        const mutation = gql`
          mutation(
              $inventory_order_id: Int
              $completed: Boolean
            ) {
              update_inventory_order(
                inventory_order_id: $inventory_order_id
                completed: $completed
              ) {
                id
                message
                success
              }
            }
        `;
        try {
          loading.open('Completing move...');
          const data = await GraphqlService.SendMutation(mutation, {completed: true, inventory_order_id});
          if (!data?.success)
            throw new Error(data?.message || 'Something went wrong');
          loading.close();
          toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
          loadMoves().then();
          loadStorage().then();
          loadInventory().then();
        } catch (ex) {
          loading.close();
          toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
      }

    return <div className='flex-1-container'>
        <div className="d-flex mb-3">
            {tabs.filter(x => x.key != 'storage' || SafeStorage.checkPermissions('storage')).map(tab => <h4 key={'tab-' + tab.value} onClick={(evt) => setTabSelected(tab.value)} className={'font-10 mr-3 cursor-pointer ' + (tabSelected != tab.value ? 'text-gray' : 'text-black')}>{tab.label}</h4>)}
        </div>
        <div className='bg-white rounded pt-1 pb-3 flex-1-container'>
            <div className="row px-2 py-2 align-items-center">
                <div className='d-flex align-items-center'>
                    <p className="font-9 font-medium mr-2">{getCount()} Vehicles</p>
                    <button onClick={(evt) => {
                        loadInventory();
                        loadMoves();
                        loadStorage();
                    }} className={'btn btn-icon-only' + (inventoryLoading || movesLoading || storageLoading ? ' spin' : '')}><img src={ICONS.IconRefresh} /></button>
                </div>

                <div>
                    <Searchbar value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)} background={'#f2f2f2'} reference={ref} />
                </div>

            </div>
            {tabSelected == Tabs.MOVES && <InventoryTable menuOptions={movesOptions} onClicked={onRowClicked} loading={movesLoading} rows={movesFiltered()} />}
            {tabSelected == Tabs.INVENTORY && <InventoryTable menuOptions={options} onClicked={onRowClicked} loading={inventoryLoading} rows={inventoriesFiltered()} />}
            {tabSelected == Tabs.STORAGE && <InventoryTable menuOptions={options} onClicked={onRowClicked} loading={storageLoading} rows={storagesFiltered()} />}
        </div>

        <ReactModal isOpen={requestInspection.vehicle_order_id != null}>
                <RequestInspectionForm data={requestInspection} onClose={(success) => {
                    setRequestInspection(new RequestInpsection());
                    if (success == true)
                    {
                        loadStorage();
                        loadInventory();
                        loadMoves();
                    }
                }} />
            </ReactModal>

        <ReactModal isOpen={voSelected != null}>
            <InventoryOrderDetails warehouse_id={warehouse_id} onClose={(evt) => {
                
                setVOSelected(null)
            }} onRefresh={(evt) => {
                loadMoves();
                loadInventory();
                loadStorage();
                onRefresh();
            }} vehicle_order={voSelected} />
        </ReactModal>
        
    </div>
}

export default WarehouseTables;
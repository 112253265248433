import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Searchbar from '../Searchbar/Searchbar';


class LocationFilterClass{
    cities: string[] = [];
    states: string[] = [];
    months: string[] = [];
    dates: string[] = [];

    constructor(props?: LocationFilterClass)
    {
        if (props)
        {
            this.cities = [...props.cities];
            this.states = [...props.states];
            this.months = [...props.months];
            this.dates = [...props.dates];
        }
    }
  }

class FilterClass{
    origin = new LocationFilterClass();
    destination = new LocationFilterClass();

    constructor(props?: FilterClass){
        if(props){
            this.origin = new LocationFilterClass(props.origin);
            this.destination = new LocationFilterClass(props.destination);
        }
    }
}

const DispatchFilters = ({onCancelClicked, legs, filter, onSubmit}) => {

    
    const [filterData, setFilterData] = useState(new FilterClass());
    const [myLegs, setMyLegs] = useState([]);
    const [filters, setFilters] = useState([]);
    const [originCitiesSearch, setOriginCitiesSearch] = useState('');
    const [destinationCitiesSearch, setDestinationCitiesSearch] = useState('');
    const originCitiesRef = useRef();
    const destinationCitiesRef = useRef();

    // const [myLegs, setMyLegs] = useState([]);
    
    function getLegFilters(data){
        let legs = data.filter(x => x.origin.address && x.destination.address);
        let result: {
            origin: {
                states: {name: string, count: number}[];
                cities: {name: string, count: number}[];
                months: {name: string, count: number}[];
                dates: {name: string, count: number}[];
            },
            destination: {
                states: {name: string, count: number}[];
                cities: {name: string, count: number}[];
                months: {name: string, count: number}[];
                dates: {name: string, count: number}[];
            }
        } = {
            origin: {
            states: [],
            cities: [],
            months: [],
            dates: []
            },
            destination: {
            states: [],
            cities: [],
            months: [],
            dates: []
            }
        }
        var months = [ "JAN", "FEB", "MAR", "APR", "MAY", "JUN", 
           "JUL", "AUG", "SEP", "OCT", "NOV", "DEC" ];
        
        let origin_states = [...new Set(legs.map(x => x.origin.address.state.trim().toUpperCase()))].sort((x, y) => x > y ? 1 : -1) as string[];
        let origin_cities = [...new Set(legs.map(x => x.origin.address.city.trim().toUpperCase()))].sort((x, y) => x > y ? 1 : -1) as string[];
        let origin_months = [...new Set(legs.map(x => +x.origin.time_scheduled.substring(0, 2)))].sort((x, y) => x > y ? 1 : -1).map((x: number) => months[x - 1]) as string[];
        let origin_dates = [...new Set(legs.map(x => x.origin.time_scheduled))].sort((x: any, y: any) => new Date(x) > new Date(y) ? 1 : -1) as string[];
        
        console.log(origin_months);
        let destination_states = [...new Set(legs.map(x => x.destination.address.state.trim().toUpperCase()))].sort((x, y) => x > y ? 1 : -1) as string[];
        let destination_cities = [...new Set(legs.map(x => x.destination.address.city.trim().toUpperCase()))].sort((x, y) => x > y ? 1 : -1) as string[];
        let destination_months = [...new Set(legs.map(x => +x.destination.time_scheduled.substring(0, 2)))].sort((x, y) => x > y ? 1 : -1).map((x: number) => months[x - 1]) as string[];
        let destination_dates = [...new Set(legs.map(x => x.destination.time_scheduled))].sort((x: any, y: any) => new Date(x) > new Date(y) ? 1 : -1) as string[];


        for (let state of origin_states){
            result.origin.states.push({
            name: state,
            count: legs.filter(x => x.origin.address.state.toUpperCase().trim() == state).length
            });
        }
        
        for (let city of origin_cities){
            result.origin.cities.push({
            name: city,
            count: legs.filter(x => x.origin.address.city.toUpperCase().trim() == city).length
            });
        }

        for (let month of origin_months){
            result.origin.months.push({
                name: month,
                count: legs.filter(x => moment(new Date(x.origin.time_scheduled)).format('MMM').toUpperCase() == month).length
            });
        }
        
        for (let date of origin_dates){
            result.origin.dates.push({
            name: date,
            count: legs.filter(x => x.origin.time_scheduled == date).length
            });
        }
        
        
        for (let state of destination_states){
            result.destination.states.push({
            name: state,
            count: legs.filter(x => x.destination.address.state.toUpperCase().trim() == state).length
            });
        }
        
        for (let city of destination_cities){
            result.destination.cities.push({
            name: city,
            count: legs.filter(x => x.destination.address.city.toUpperCase().trim() == city).length
            });
        }

        for (let month of destination_months){
            result.destination.months.push({
                name: month,
                count: legs.filter(x => moment(new Date(x.destination.time_scheduled)).format('MMM').toUpperCase() == month).length
            });
        }
        
        for (let date of destination_dates){
            result.destination.dates.push({
            name: date,
            count: legs.filter(x => x.destination.time_scheduled == date).length
            });
        }
        
        console.log(result);
        return result;
    }

    

    const [group, setGroup] = useState([
        {
            label: 'ORIGIN',
            stop: [
                {
                    key: 'pickup_states',
                    label: 'STATES',
                    colSize: 3,
                    data: []
                },
                {
                    key: 'pickup_cities',
                    label: 'CITIES',
                    colSize: 4,
                    data: []
                },
                {
                    key: 'pickup_dates',
                    label: 'PICKUP DATES',
                    colSize: 5,
                    data: []
                }
            ]
        },
        {
            label: 'DESTINATION',
            stop: [
                {
                    key: 'delivery_states',
                    label: 'STATES',
                    colSize: 3,
                    data: []
                },
                {
                    key: 'delivery_cities',
                    label: 'CITIES',
                    colSize: 4,
                    data: []
                },
                {
                    key: 'delivery_dates',
                    label: 'DELIVERY DATES',
                    colSize: 5,
                    data: []
                }
            ]
        }
    ]);
    
    

    const [selected, setSelected] = useState(
        {
            "pickup_states": [],
            "pickup_cities": [],
            "pickup_dates": [],
            "delivery_states": [],
            "delivery_cities": [],
            "delivery_dates": [],
        });

    const isSelected = (key, value) =>
    {
        return selected[key].includes(value);
    }

    const toggleSelection = (key, value) => {
        const s = {...selected};
        if (isSelected(key, value))
            s[key] = s[key].removeBy(x => x == value);
        else
            s[key].push(value);
        setSelected(s);
    }

    const isAllSelected = (key, index) => {
        // console.log(key, selected[key].length, group[index].stop.find(x => x.key == key).data.length - 1)
        return selected[key].length == group[index].stop.find(x => x.key == key).data.length - 1;
    }


    const distincAndCount = (data) => {
        let result = [];
        let grouped = data.groupBy(x => x);
       
        result.push({value: 'ALL', count: data.length});
        
        grouped.forEach(g => {
            result.push({value: g[0], count: g.length})
        });
        return result;
    }


    function isAllChecked(id, length) {

        const keys = id.split('_')
        const array = filterData[keys[0]][keys[1]] as string[];
        return array.length == length;
    }

    function isChecked(id, value) 
    {
        const keys = id.split('_')
        if (keys.length < 2)
            return;
        const array = filterData[keys[0]][keys[1]] as string[];
        return array.includes(value);
    }

    function handleOnCheck(id, value, checked) {
        
        const keys = id.split('_')

        const temp = new FilterClass(filterData);
        if (!checked)
            temp[keys[0]][keys[1]] = (temp[keys[0]][keys[1]] as any[]).removeBy(x => x == value);
        else
            (temp[keys[0]][keys[1]] as any[]).push(value);
            
        setFilterData(new FilterClass(temp));
    }

    function handleOnCheckAll(id, data, checked) {
        const keys = id.split('_');

        const temp = new FilterClass(filterData);

        if (checked)
            temp[keys[0]][keys[1]] = data.map(x => x.name);
        else
            temp[keys[0]][keys[1]] = [];

        setFilterData(new FilterClass(temp));
        
    }

    function handleOnClear(id) {
        const keys = id.split('_');

        const temp = new FilterClass(filterData);

        temp[keys[0]][keys[1]] = [];

        setFilterData(new FilterClass(temp));
    }
    

    useEffect(() => {
        setFilterData(new FilterClass(filter));
    }, [filter]);

    useEffect(() => {
        setMyLegs(applyFilters(legs, filterData));
    }, [filterData]);

    useEffect(() => {
        setFilters(applyFilters(myLegs, filterData));
    }, [myLegs]);


    function applyFilters(data: any[], filters: FilterClass){
        
        let legs = data.filter(x => x.origin.address && x.destination.address);
        
      
        
        if(filters.origin.states.length > 0){
            legs = legs.filter(x => filters.origin.states.includes(x.origin.address.state.toUpperCase().trim()));
        }

        if(filters.origin.cities.length > 0){
          legs = legs.filter(x => filters.origin.cities.includes(x.origin.address.city.toUpperCase().trim()));
        }

        if(filters.origin.months.length > 0){
            legs = legs.filter(x => filters.origin.months.includes(moment(x.origin.time_scheduled).format('MMM').toUpperCase()));
        }

        if(filters.origin.dates.length > 0){
          legs = legs.filter(x => {
            return filters.origin.dates.includes(x.origin.time_scheduled)
          });
        }


        if(filters.destination.states.length > 0){
            legs = legs.filter(x => filters.destination.states.includes(x.destination.address.state.toUpperCase().trim()));
        }

        if(filters.destination.cities.length > 0){
          legs = legs.filter(x => filters.destination.cities.includes(x.destination.address.city.toUpperCase().trim()));
        }

        if(filters.destination.months.length > 0){
            legs = legs.filter(x => filters.destination.months.includes(moment(x.destination.time_scheduled).format('MMM').toUpperCase()));
        }
        
        if(filters.destination.dates.length > 0){
          legs = legs.filter(x => filters.destination.dates.includes(x.destination.time_scheduled));
        }
        
        return legs;
    }


    function doesContain(id: string, value)
    {
        const keys = id.split('_');

        const getValue = (x) => {
            switch (keys[1])
            {
                case 'states':
                    return x.address.state.toUpperCase().trim();
                case 'cities':
                    return x.address.city.toUpperCase().trim();
                case 'months':
                    return moment(x.time_scheduled).format('MMM').toUpperCase();
                case 'dates':
                   return x.time_scheduled; 
            }
        }
        
        return myLegs.map(x => getValue(x[keys[0]])).includes(value);
    }
    


    function getFilterColumn(id: string, label: string, data: {name: string, count: number}[])
    {
        const totalCount = data.sumBy(x => x.count);
        return  <div className="flex-1-container">
        <div className="row mb-3 pr-2 align-items-center">
            <h4 className="fs-12">{label}</h4>
            <h4 className="fs-9 cursor-pointer text-gray" onClick={(evt) => handleOnClear(id)}>CLEAR</h4>
        </div>

        <div className="row mb-2 pl-2 pr-2 align-items-center">
            <div className="d-flex align-items-center"><input id={'all_' + id} onChange={(evt) => handleOnCheckAll(id, data, evt.target.checked)} checked={isAllChecked(id, data.length)} type={'checkbox'} className='m-0' name={'all_' + id} /> <label htmlFor={'all_' + id} className='fs-11 m-0 cursor-pointer pl-2'>All</label></div>
            <h4 className="fs-11 cursor-pointer">{totalCount}</h4>
        </div>

        {(id == 'origin_cities' || id == 'destination_cities') && <div className='mb-2'>
            <Searchbar value={id == 'origin_cities' ? originCitiesSearch : destinationCitiesSearch} onChange={(evt) => {
                if (id == 'origin_cities')
                    setOriginCitiesSearch(evt.target.value);
                else
                    setDestinationCitiesSearch(evt.target.value);
            }} background='#f2f2f2' reference={id == 'origin_cities' ? originCitiesRef : destinationCitiesRef} />
        </div>}


        <hr className="filter mb-2" />

        <div className="flex-1-container px-2">
            {data.map(x => <div className="row mb-2 align-items-center" style={{transition: '.3s all ease', opacity: doesContain(id, x.name) ? 1 : .4}}>
                <div className="d-flex align-items-center"><input onChange={(evt) => handleOnCheck(id, x.name, evt.target.checked)} checked={isChecked(id, x.name)} type={'checkbox'} id={`${id}_${x.name}`} className='m-0' name={`${id}_${x.name}`} /> <label htmlFor={`${id}_${x.name}`} className='fs-11 m-0 cursor-pointer pl-2'>{x.name}</label></div>
                <h4 className="fs-11 cursor-pointer">{x.count}</h4>
            </div>)}
        </div>
    </div>
    }



    const getFilterBody = () => {

        const filters = getLegFilters(legs);
        
        return <>
            <div className='mb-3'>
                <h4 className="fs-14">Origin</h4>
            </div>
            <div className="flex-1-container row-container px-2">
                <div className="col-2 d-flex">
                    {getFilterColumn('origin_states', 'States',filters.origin.states)}
                </div>

                <div className="col-4 d-flex">
                    {getFilterColumn('origin_cities', `Cities (${filters.origin.cities.filter(x => isChecked('origin_cities', x.name)).length})`, filters.origin.cities.filter(x => x.name.toLowerCase().includes(originCitiesSearch.toLowerCase().trim())))}
                </div>
                <div className="col-3 d-flex">
                    {getFilterColumn('origin_months', 'Months', filters.origin.months)}
                </div>
                <div className="col-3 d-flex">
                    {getFilterColumn('origin_dates', 'Dates', filters.origin.dates)}
                </div>
            </div>
            <div className='my-3'>
                <h4 className="fs-14">Destination</h4>
            </div>
            <div className="flex-1-container px-3 row-container">
                <div className="col-2 d-flex">
                    {getFilterColumn('destination_states', 'States', filters.destination.states)}
                </div>

                <div className="col-4 d-flex">
                    {getFilterColumn('destination_cities', `Cities (${filters.destination.cities.filter(x => isChecked('destination_cities', x.name)).length})`, filters.destination.cities.filter(x => x.name.toLowerCase().includes(destinationCitiesSearch.toLowerCase().trim())))}
                </div>
                <div className="col-3 d-flex">
                    {getFilterColumn('destination_months', 'Months', filters.destination.months)}
                </div>
                <div className="col-3 d-flex">
                    {getFilterColumn('destination_dates', 'Dates', filters.destination.dates)}
                </div>
            </div>
    
        </>
    
    }

    return (
        <div className='flex-1-container'>
            <div className="row col-12 mb-3">
                    <h4 className="fs-14">MATCHES {myLegs.length}</h4> <button onClick={(evt) => {
                        const temp = new FilterClass();

                        setFilterData(temp);
                        
                    }} className="btn btn-clear">CLEAR ALL</button>
                </div>


            <div className='col-12 flex-1-container' >
                {getFilterBody()}
                
            </div>


            <div className='row mt-3' >
                <button onClick={(evt) => onCancelClicked(evt)} className="btn btn-danger-outline px-3 py-2" style={{borderRadius: '0', fontWeight: 'bold'}}>CANCEL</button>
                <button onClick={(evt) => onSubmit(filterData)} className="btn btn-outline px-3 py-2" style={{borderRadius: '0', fontWeight: 'bold'}}>APPLY FILTERS</button>
            </div>

        </div>)
}

export default DispatchFilters;
import {Quote, Token_Quote_Res} from "../quote";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {Master_Order} from "../../master_order/master_order";

export const quotes = (input:{}) : Promise<Quote[]> => {
  let query = gql`
    query {
      quotes {
        id
        master_order_id
        contact_info {
          full_name
          email
          phone_1
          phone_2
          company_name
        }
        pickup {
          zip
          city
          state
          guaranteed
          pickup_date
          dropoff_date
          street_number
          street
          title
          location_id
        }
        dropoff {
          zip
          city
          state
          guaranteed
          pickup_date
          dropoff_date
          street_number
          street
          title
          location_id
        }
        vehicle_info {
          make
          model
          year
          vin
          equipment
          price
          nofail_pickup_price
          nofail_dropoff_price
        }
        token
        create_at
        price
        user {
          id
        }
        time_created
        note
        archived
        customer_profile {
          id
          title
          email
          verified
          name
          role
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Quote[]>;
}

export const get_unverified_master_order = (input:{}) : Promise<Master_Order[]> => {
  let query = gql`
    query {
      get_unverified_master_order {
        id
        customer_id
        customer {
          id
          name
          corporate_client
          key
          terms
          internal_note
          active
          tax_number {
            type
            value
          }
          billing_address {
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
          default_invoice_email
        }
        time
        started
        completed
        price
        payment_left
        terms
        canceled
        contacts {
          id
          email
          verified
          name
          role
          contact {
            title
            value
            type
            unsubscribed
          }
          title
          active
        }
        vehicle_orders {
          id
          time
          bol_comment
          time_changed
          driver_note
          started
          delivered
          vehicle {
            id
            vin
            year
            make
            model
            submodel
            type
            no_vin
            special_vin
            incomplete_vin
            color
            license_plate
            internal_note
            time_created
            user_id
          }
          charges {
            id
            amount
            tax_amount
            base_amount
            name
            note
            voided
            amount_remaining
          }
          storage_order {
warehouse_id
            id
            vehicle_order_id
            billing_cycle
            rate
            date_last_issued
            active
            departure_date
            note
          }
          legs {
            id
            vehicle_order_id
            vehicle_order {
              id
            }
            time
            status
            time_completed
            completed
            canceled
            equipment
            velox_order_id
            stops {
              id
              location {
                id
                customer_id
                title
                internal_note
                driver_note
                timezoneName
              }
              is_pickup
              time_scheduled
              time_completed
              completed
              started
              canceled
              nofail
              contacts {
                id
                email
                verified
                name
                role
              }
              service_charges {
                id
                amount
                tax_amount
                base_amount
                name
                note
                voided
                amount_remaining
              }
              time_frame {
                end
                start
              }
              leg_id
              log {
                user_id
                message
                time
                vehicle_order_id
                leg_id
                stop_id
              }
            }
            service_charges {
              id
              amount
              tax_amount
              base_amount
              name
              note
              voided
              amount_remaining
            }
            driver {
              id
              name
              username
              equipment
              carrier_code
            }
            carrier {
              id
            }
            carrier_pay
            driver_pay
            remaining_carrier_receivable
            remaining_driver_payable
            velox_order {
              id
              accepted
              rejected
              canceled
              expired
              expires
            }
            pending_change_request
          }
          storage_bay {
            id
            label
            storage_section_id
            vehicle_order_id
          }
          inspections {
            id
            user {
              id
              name
            }
            type
            time
          }
          pending_inspection_id
          master_order_id
          warehouse_id
          non_move
        }
        delivered
        picked_up
        non_move
        payment_due
        last_invoice_amount
        needs_invoice
        token
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Master_Order[]>;
}

export const quote_by_id = (input:{quote_id: number}) : Promise<Quote> => {
  let query = gql`
    query($quote_id: Int) {
      quote_by_id(quote_id: $quote_id) {
        id
        master_order_id
        contact_info {
          full_name
          email
          phone_1
          phone_2
          company_name
        }
        pickup {
          zip
          city
          state
          guaranteed
          pickup_date
          dropoff_date
          street_number
          street
          title
          location_id
        }
        dropoff {
          zip
          city
          state
          guaranteed
          pickup_date
          dropoff_date
          street_number
          street
          title
          location_id
        }
        vehicle_info {
          make
          model
          year
          vin
          equipment
          price
          nofail_pickup_price
          nofail_dropoff_price
        }
        token
        create_at
        price
        user {
          id
        }
        time_created
        note
        archived
        customer_profile {
          id
          title
          email
          verified
          name
          role
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {quote_id} as 
  Promise<Quote>;
}

export const verify_quote_token = (input:{token: string}) : Promise<Token_Quote_Res> => {
  let query = gql`
    query($token: String) {
      verify_quote_token(token: $token) {
        message
        link
        success
        customer_profile_id
        verified
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {token} as 
  Promise<Token_Quote_Res>;
}

export const quote_by_profile = (input:{profile_id: number}) : Promise<Quote[]> => {
  let query = gql`
    query($profile_id: Int) {
      quote_by_profile(profile_id: $profile_id) {
        id
        master_order_id
        contact_info {
          full_name
          email
          phone_1
          phone_2
          company_name
        }
        pickup {
          zip
          city
          state
          guaranteed
          pickup_date
          dropoff_date
          street_number
          street
          title
          location_id
        }
        dropoff {
          zip
          city
          state
          guaranteed
          pickup_date
          dropoff_date
          street_number
          street
          title
          location_id
        }
        vehicle_info {
          make
          model
          year
          vin
          equipment
          price
          nofail_pickup_price
          nofail_dropoff_price
        }
        token
        create_at
        price
        user {
          id
        }
        time_created
        note
        archived
        customer_profile {
          id
          title
          email
          verified
          name
          role
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {profile_id} as 
  Promise<Quote[]>;
}

export const quote_by_id_rest = (input:{}) : Promise<Quote> => {
  let query = gql`
    query {
      quote_by_id_rest {
        id
        master_order_id
        contact_info {
          full_name
          email
          phone_1
          phone_2
          company_name
        }
        pickup {
          zip
          city
          state
          guaranteed
          pickup_date
          dropoff_date
          street_number
          street
          title
          location_id
        }
        dropoff {
          zip
          city
          state
          guaranteed
          pickup_date
          dropoff_date
          street_number
          street
          title
          location_id
        }
        vehicle_info {
          make
          model
          year
          vin
          equipment
          price
          nofail_pickup_price
          nofail_dropoff_price
        }
        token
        create_at
        price
        user {
          id
        }
        time_created
        note
        archived
        customer_profile {
          id
          title
          email
          verified
          name
          role
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Quote>;
}

export const quotes_by_customer = (input:{customer_id: number}) : Promise<Quote[]> => {
  let query = gql`
    query($customer_id: Int) {
      quotes_by_customer($customer_id: customer_id) {
        id
        master_order_id
        contact_info {
          full_name
          email
          phone_1
          phone_2
          company_name
        }
        pickup {
          zip
          city
          state
          guaranteed
          pickup_date
          dropoff_date
          street_number
          street
          title
          location_id
        }
        dropoff {
          zip
          city
          state
          guaranteed
          pickup_date
          dropoff_date
          street_number
          street
          title
          location_id
        }
        vehicle_info {
          make
          model
          year
          vin
          equipment
          price
          nofail_pickup_price
          nofail_dropoff_price
        }
        token
        create_at
        price
        user {
          id
        }
        time_created
        note
        archived
        customer_profile {
          id
          title
          email
          verified
          name
          role
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Quote[]>;
}
import React, {useEffect, useState} from "react";
import {Damage} from "../../../../classes/classes";
import InspectionCar from '../../../../../assets/images/Car_Damage.png'
import './VehicleShowDamages.scss';
import { useWindowSize } from "@react-hook/window-size";

const VehicleShowDamages = ({damageList}: {damageList: Damage[]}) => {
    const imgWidth = 403;
    const imgHeight = 611;

    const [width, height] = useWindowSize();

    // console.log(width);

    const [damages, setDamages] = useState([]);

    useEffect(() => {
        setDamages([...mapDamagesOnImage(damageList)]);
    }, [damageList, width, height]);

    const mapDamagesOnImage = (currentDamages: Damage[]) => {
        
        let img = document.getElementById('img');
        let width = img.scrollWidth;
        let height = img.scrollHeight;

        let temp = [...currentDamages];
        let newTemp = temp.map(point => {
            let originalX = (point.point.x * width) / 100;
            let originalY = (point.point.y * height) / 100;
            // {  }
            return {...point, originalX, originalY, style: {top: `${originalY - 16}px`, left: `${originalX - 16}px`, visibility: 'visible'}};
        });

        
        return newTemp;
    }


    return(
        <>
            <div className="w-100 d-flex align-items-center justify-content-center" style={{overflow: 'hidden'}}>
                <div className="img-wrapper position-relative">
                    <img id="img" className={'img-inspection-damages w-100 '} src={InspectionCar} alt="" />

                    {
                        damages && damages?.map((damage, key) => {
                            return(
                                <div key={key} className="damage-point" style={damage?.style}>
                                    { damage?.code }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default VehicleShowDamages;
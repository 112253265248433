import React, {useState, useRef, useEffect} from 'react'
import './DriversPage.scss'
import Map from '../../libs/map/map';
import {useDispatch, useSelector} from "react-redux";
import {clearGetAllDriverListError, getDriverLocations} from "../../redux/actions/Driver.action";
import {AllStates} from "../../redux/reducers";
import {IDriverState} from "../../redux/reducers/Driver.reducer";
import {useToasts} from "react-toast-notifications";
import DriverMap from "../../components/DriverMap/DriverMap";
import DriverCardJob from "../../components/DriverCardJob/DriverCardJob";
import {ICarrierState} from "../../redux/reducers/Carrier.reducer";
import {GraphqlService, ObservableQueryT} from "../../services/graphql.service";
import DriverQueries from "../../graphql/query/Driver";
import {DriverLocation, TrackingDevice} from "../../classes/classes";
import {Link} from 'react-router-dom';
import ICONS from '../../../assets/svg';
import {getDriverTracking} from "../../redux/actions/DriverMap.action";
import {IDriverMapState} from "../../redux/reducers/DriverMap.reducer";
import {Subscription} from 'rxjs';
import { MarkerClass } from '../../libs/beta/MarkerClass';
import AddressSearch from '../../components/Google/AddressSearch';
import { gql } from 'apollo-boost';
import Status from '../../components/Status/Status';
import SideActionManager from '../../components/SideActionManager/SideActionManager';
import useLoading from '../../hooks/useLoading/useLoading';
import FormGroup from '../../components/FormGroup/FormGroup';
import { Closest_driver } from '../../classes/classes.backend';
import Helper from '../../helper/Helper';
import Loading from '../../components/Loading/Loading';
import { PointClass } from '../../libs/beta/PointClass';

const queryNearestDriver = gql`query($destination: String) {
    get_closest_driver(destination: $destination) {
      velox_id
      name
      driver_id
      samsara_id
  
      next_stop {
        address_string
        vehicle_description
        time
      }
      location {
        time
        latitude
        longitude
        heading
        speed
        reverseGeo {
          formattedLocation
        }
      }
      gps {
        Speed
        Course
        Altitude
        Timestamp
        Coordinate {
          Latitude
          Longitude
        }
        SpeedAccuracy
        CourseAccuracy
        VerticalAccuracy
        HorizontalAccuracy
      }
      google {
        regular {
          distance {
            text
            value
          }
          duration {
            text
            value
          }
          duration_in_traffic {
            text
            value
          }
        }
        direct {
          distance {
            text
            value
          }
          duration {
            text
            value
          }
          duration_in_traffic {
            text
            value
          } 
        }
      }
    }
  }
   `;

let google = window.google;

const DriversPage = () => {

    const [driversLoading, setDriversLoading] = useState(false);
    const [nearestDrivers, setNearestDrivers] = useState<Closest_driver[]>(null);
    // const {loadingLocations, driverLocations, errorLocations} = useSelector<AllStates>(states => states.drivers) as IDriverState;
    const carrier = useSelector<AllStates>(states => states.carriers) as ICarrierState;
    const interval = useRef<any>();
    const currentCarrierId = useRef<any>();
    const loading = useLoading();
    // const {trackingDrivers} = useSelector<AllStates>(states => states.driversMap) as IDriverMapState;
    const [trackingDrivers, setTrackingDrivers] = useState<TrackingDevice[]>([]);
    const [trackingData, setTrackingData] = useState<TrackingDevice[]>([]);
    const [driverLocations, setDriverLocations] = useState<DriverLocation[]>([]);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [driverOrderRoute, setDriverOrderRoute] = useState(null);
    const [showTraffic, setShowTraffic] = useState<boolean>(false);
    const dispatch = useDispatch();
    const toast = useToasts();
    const currentDriverID = useRef<number>(0);
    const [addressSearch, setAddressSearch] = useState('');
    const [loadingNDrivers, setLoadingNDrivers] = useState(false);
    // const [pin, setPin] = useState<MarkerClass>(null);

    const query = useRef<ObservableQueryT>();

    const [sortByDriver, setSortByDriver] = useState('After drop');

    const [showNearestDrivers, setShowNearestDrivers] = useState(false);

    useEffect(() => {
        // console.log({selectedDriverId})
        currentCarrierId.current = selectedDriver;


    }, [selectedDriver]);

    async function getNearestDrivers(destination: string) {
        try {
            setLoadingNDrivers(true);
            const data = await GraphqlService.SendQuery(queryNearestDriver, {destination})
            console.log(data);
            setNearestDrivers(data);
            setLoadingNDrivers(false);
            
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            loading.close();
            setLoadingNDrivers(false);
        }
    }

    async function loadDriverLocations(carrier_id = currentCarrierId.current) {
        try {
            dispatch(getDriverTracking(carrier_id));
        } catch (ex) {
            console.log(ex.message);
        }
        // dispatch(getDriverLocations(carrier.id));
    }

    const subscription = useRef<Subscription>();

    useEffect(() => {
        // interval.current = setInterval(() => loadDriverLocations(), 2000);
        // return () => clearInterval(interval.current);
    }, []);


    useEffect(() => {
        if (addressSearch)
        {
            // setPin(new MarkerClass({
            //     id: 'address_search',
            //     onClick: () => {},
            //     selected: false,
            //     text: addressSearch,
            //     tag: 'X',
            //     color: 'red'
            // }))
        }
        else
        {

        }
    }, [addressSearch]);

    useEffect(() => {
        if (!nearestDrivers)
            setAddressSearch('');
    }, [nearestDrivers]);


    useEffect(() => {
        // currentCarrierId.current = carrier.id;
        // loadDriverLocations(currentCarrierId.current ).then();
        if (query.current) {
            query.current.stopPolling();
            subscription.current.unsubscribe();
        }


        query.current = GraphqlService.SendQueryObservable(DriverQueries.GET_ALL_LIST, {carrier_id: carrier.id});
        subscription.current = query.current.subscribe(({data, errors}) => {
            const drivers = data.get_trucks_location.map(x => new TrackingDevice(x));
            const driverLocations = data.get_driver_locations.map(x => new DriverLocation(x));

            setDriverLocations(driverLocations);
            setTrackingDrivers(drivers);
        }, (err) => {

        }) as any;

        return () => {
            if (query.current) {
                query.current.stopPolling();
                subscription.current.unsubscribe();
            }
        }

    }, [carrier.id]);

    const getDriversSort = () => {
        if (!nearestDrivers) return [];
        
        
        return nearestDrivers.sort((x, y) => {

            if (!x?.google)
                return 1;
            if (!y?.google)
                return -1;

            let v1, v2;

            if (sortByDriver == 'Direct')
            {
                v1 = (x?.google.direct?.duration_in_traffic || x?.google.direct?.duration)?.value;
                v2 = (y?.google.direct?.duration_in_traffic || y?.google.direct?.duration)?.value;
            }
            else{
                v1 = (x?.google.regular?.duration_in_traffic || x?.google.regular?.duration)?.value;
                v2 = (y?.google.regular?.duration_in_traffic || y?.google.regular?.duration)?.value;
            }

            if (!v1)
                return 1;
            if (!v2)
                return -1;

            return v1 > v2 ? 1 : -1;
        })
    }

    const drawDriverOrderRoute = (driver) => {
        setDriverOrderRoute(driver)
    }

    return (
        <>
            <div className="flex-1-container">
                <div className="row align-items-start">
                    <div className="col-9 row">
                        <h4 className="font-14 col-12">Fleet View</h4>
                        <div className='row my-2' style={{flex: 1}}>

                            <Status
                            className='col-2'
                                onClick={() => setShowTraffic(!showTraffic)}
                                statusName={'TRAFFIC ' + (showTraffic ? 'ON' : 'OFF')}
                            />
                            <div  className='col-10'>
                                <button onClick={(evt) => {
                                    setShowNearestDrivers(!showNearestDrivers);
                                }} className='btn btn-blue-light py-2'>{!showNearestDrivers ? 'Show' : 'Hide'} Nearest Drivers</button>
                            </div>
                        </div>
                    </div>
                    <Link to='/dispatch'
                          className="btn btn-shy-light text-blue-light text-blue d-flex align-items-center rounded-pill"><img
                        className='mr-0-5' src={ICONS.DASHBOARD} alt=""/>Dispatch View</Link>
                </div>
                <div className="col-12 flex-1-container row-container">
                    <div className="col-9 d-flex ">
                        <DriverMap 
                            selectedDriver={selectedDriver}
                            driverOrderRoute={driverOrderRoute}
                            trackingData={trackingDrivers}
                            drivers={driverLocations} 
                            onDriverSelected={m => {
                                const marker = new MarkerClass(m);
                                // console.log({id, selectedDriverId: currentDriverID.current});
                                if (marker.id == selectedDriver?.id) {
                                    setSelectedDriver(null)
                                    // Draw driver order route
                                    drawDriverOrderRoute(null);
                                } else {

                                    setSelectedDriver(marker)
                                    // Draw driver order route
                                    // drawDriverOrderRoute(evt);
                                }
                            }}
                            showTraffic={showTraffic}/>
                    </div>
                    <div className="col-3 bg-white p-3 rounded d-flex">
                        <DriverCardJob 
                            gridOne 
                            driverSelected={selectedDriver} 
                            onDriverClicked={(evt) => {
                                // console.log(evt.id, selectedDriverId, evt.id == selectedDriverId);
                                // console.log('here');
                                if (evt.show_route && evt)
                                {
                                    setSelectedDriver(evt);
                                    drawDriverOrderRoute(evt);
                                    // setSelectedDriver(null);
                                }
                                else if (evt.id == selectedDriver?.id) {
                                    setSelectedDriver(null)
                                    // Draw driver order route
                                    drawDriverOrderRoute(null);
                                } else {

                                    setSelectedDriver(evt);
                                }
                            }}/>
                    </div>
                </div>

                <SideActionManager title={'Nearest Drivers'} opened={showNearestDrivers}>
                        <div className="flex-1-container p-3">
                            <div className="row mb-3">
                                <h4 className="font-12">Nearest Drivers</h4>
                                <h4 onClick={(evt) => setShowNearestDrivers(false)} className="font-12 cursor-pointer"><i className='fas fa-times'></i></h4>
                            </div>

                            <AddressSearch 
                            preloadedMap
                            hideLabel
                                className='mb-3'
                                onGotAddress={(evt) => false}
                                onAddressChanged={address => {
                                    setAddressSearch(address);
                                    getNearestDrivers(address);

                                    
                                }}


                            />

                            <div>
                                <FormGroup
                                value={sortByDriver}
                                onTextChange={setSortByDriver}
                                name='sort_by_nearest_driver' label='Sort By' type='select' colSize={6}
                                    options={{data: ['Direct', 'After drop'], label:x=>x, value:x=>x}}
                                    placeholder={'-- None --'}
                                />
                            </div>

                            <div className="flex-1-container">
                                {
                                loadingNDrivers ? 
                                    <Loading />
                                    :
                                getDriversSort()?.map(driver => <div style={{cursor: driver?.location?.longitude ? 'pointer' : 'not-allowed', opacity: driver?.location?.longitude ? 1 : .35}} onClick={(evt) => {
                                    if (!driver.location?.longitude)
                                        return;
                                const d = {
                                    name: driver.name,
                                    id: driver.velox_id,
                                    samsara_id: driver.samsara_id,
                                    addresses: [{lat: driver.location.latitude, lng: driver.location.longitude}, driver.next_stop?.address_string, addressSearch].filter(Boolean),
                                    show_route: true
                                }
                                setSelectedDriver(d);
                                drawDriverOrderRoute(d);
                                
                                }} className={'p-3 bg-light mb-2 rounded' + (driver?.location?.longitude ? ' light-hover' : '')}>
                                    <h4 className="font-12 mb-1">{driver?.name}</h4>
                                    
                                    {driver?.next_stop && <div className='my-3'>
                                        {/* <p className="font-10 mt-1">Direct Duration: {driver?.google?.direct?.duration_in_traffic?.text || driver?.google?.direct?.duration?.text || 'Unknown'}</p>
                                        <p className="font-10">Direct Distance: {driver?.google?.direct?.distance?.text || 'Unknown'}</p>
                                        <p className="font-10 mt-1">Next Stop: {driver?.next_stop?.address_string || 'Unknown'}</p>
                                        <p className="font-10">Vehicle: {driver?.next_stop?.vehicle_description || 'Unknown'}</p>
                                        <p className="font-10">Scheduled at: {Helper.FORMAT.USDate(driver?.next_stop?.time) || 'Unknown'}</p> */}
                                        <div className="row">
                                            <div>

                                                <h4 className="font-10 mb-1">Next Stop</h4>
                                                <p className="font-10 fw-600">{driver?.next_stop?.address_string}</p>
                                            </div>
                                            <div>

                                                <h4 className="font-12 d-flex align-items-center text-red"><span className='red-triangle mr-1'></span> DROPOFF</h4>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-start my-3'>
                                            <p className="font-10">Current Location</p>
                                            <div className='mx-2' style={{flex: 1, borderTop: '1px solid #000', marginTop: '.35rem'}}>
                                                <p className="font-10 mt-1 text-center">{(driver?.google?.regular?.duration_in_traffic?.text || driver?.google?.regular.duration?.text)?.split(',')?.[0]}</p>    
                                                <p className="font-10 text-center">{(driver?.google?.regular?.distance?.text)?.split(',')?.[0]}</p>    
                                            </div>
                                            <p className="font-10">Next Stop</p>
                                            <div className='mx-2' style={{flex: 1, borderTop: '1px solid #000', marginTop: '.35rem'}}>
                                                <p className="font-10 mt-1 text-center">{(driver?.google?.regular?.duration_in_traffic?.text || driver?.google?.regular.duration?.text)?.split(',')?.[1]}</p>    
                                                <p className="font-10 text-center">{(driver?.google?.regular?.distance?.text)?.split(',')?.[1]}</p>        
                                            </div>
                                            <p className="font-10">Destination</p>    
                                        </div>
                                        
                                    </div>}

                                    <p className="font-10">Direct to address</p>
                                    <p className="font-10" style={{fontWeight: 600}}>{driver?.google?.direct.duration_in_traffic?.text || driver?.google?.direct?.duration?.text || 'Unknown'} / {driver?.google?.direct?.distance?.text || 'Unknown'}</p>
                                </div>)}
                            </div>
                        </div>
                </SideActionManager>
            </div>

        </>
    )
}

export default DriversPage

// VELOX
const GET_ALL_VELOX_DRIVER_LOCATIONS_STARTED = 'GET_ALL_VELOX_DRIVER_LOCATIONS_STARTED';
const GET_ALL_VELOX_DRIVER_LOCATIONS_SUCCESS = 'GET_ALL_VELOX_DRIVER_LOCATIONS_SUCCESS';
const GET_ALL_VELOX_DRIVER_LOCATIONS_ERROR = 'GET_ALL_VELOX_DRIVER_LOCATIONS_ERROR';

const FETCH_ALL_VELOX_DRIVERS_REQUEST = 'FETCH_ALL_VELOX_DRIVERS_REQUEST';
const FETCH_ALL_VELOX_DRIVERS_SUCCESS = 'FETCH_ALL_VELOX_DRIVERS_SUCCESS';
const FETCH_ALL_VELOX_DRIVERS_FAILURE = 'FETCH_ALL_VELOX_DRIVERS_FAILURE';

// JP
const GET_ALL_DRIVER_LOCATIONS_STARTED = 'GET_ALL_DRIVER_LOCATIONS_STARTED';
const GET_ALL_DRIVER_LOCATIONS_SUCCESS = 'GET_ALL_DRIVER_LOCATIONS_SUCCESS';
const GET_ALL_DRIVER_LOCATIONS_ERROR = 'GET_ALL_DRIVER_LOCATIONS_ERROR';

const FETCH_ALL_DRIVERS_REQUEST = 'FETCH_ALL_DRIVERS_REQUEST';
const FETCH_ALL_DRIVERS_SUCCESS = 'FETCH_ALL_DRIVERS_SUCCESS';
const FETCH_ALL_DRIVERS_FAILURE = 'FETCH_ALL_DRIVERS_FAILURE';

const DriverTypes = {
    GET_ALL_VELOX_DRIVER_LOCATIONS_STARTED,
    GET_ALL_VELOX_DRIVER_LOCATIONS_SUCCESS,
    GET_ALL_VELOX_DRIVER_LOCATIONS_ERROR,
    FETCH_ALL_VELOX_DRIVERS_REQUEST,
    FETCH_ALL_VELOX_DRIVERS_SUCCESS,
    FETCH_ALL_VELOX_DRIVERS_FAILURE,
    // JP
    GET_ALL_DRIVER_LOCATIONS_STARTED,
    GET_ALL_DRIVER_LOCATIONS_SUCCESS,
    GET_ALL_DRIVER_LOCATIONS_ERROR,
    FETCH_ALL_DRIVERS_REQUEST,
    FETCH_ALL_DRIVERS_SUCCESS,
    FETCH_ALL_DRIVERS_FAILURE,
}

export default DriverTypes;

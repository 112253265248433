import FormGroup from "../../FormGroup/FormGroup";
import {useState} from "react";
import {CustomerProfile} from "../../../classes/classes";

const ProfileForm = ({onCancel, onSubmit}) => {
    const [customerProfile, setCustomerProfile] = useState<CustomerProfile>(new CustomerProfile());

    return(
        <>
            <div className="row">
                <FormGroup name={'name'}
                           label={'Profile Title'}
                           value={customerProfile.name}
                           onTextChange={value => setCustomerProfile({...customerProfile, name: value})}/>

                <FormGroup name={'email'}
                           label={'Email'}
                           value={customerProfile.email}
                           onTextChange={value => setCustomerProfile({...customerProfile, email: value})}/>

                <FormGroup name={'title'}
                           label={'Profile Title'}
                           value={customerProfile.title}
                           onTextChange={value => setCustomerProfile({...customerProfile, title: value})}/>
            </div>

            <div className="row">
                <div className="col-6 mr-3">
                    <button className="btn btn-block btn-blue-outline" onClick={event => onCancel(event)}>CANCEL</button>
                </div>

                <div className="col-6">
                    <button className="btn btn-block btn-blue" onClick={event => onSubmit(event)}>SAVE</button>
                </div>
            </div>
        </>
    );
}

export default ProfileForm;

import {PointClass} from "./PointClass";

export class Stop {
    address?: PointClass;
    addressString?: string;

    constructor(x) {
        if (x) {
            this.address = x.address ? new PointClass(x.address) : null;
            this.addressString = x.addressString;
        }
    }


    get getCoordinate() {
        if (this.addressString)
            return this.addressString;
        return this.address;
    }

    get getWaypoints() {
        if (this.addressString) {
            return {location: this.addressString}
        }

        return {location: this.address}
    }
}

export default class RouteClass {
    id: any;
    start?: PointClass = new PointClass();
    departureDate?: Date;
    arrivalDate?: Date;
    private arrivalAt?: Date;
    private arrivalTrafficAt?: Date;
    private departureAt?: Date;
    private departureTrafficAt?: Date;
    end?: PointClass = new PointClass();
    startAddress?: string = '';
    endAddress?: string = '';
    showTime?: boolean = false;
    ref?: any;
    arrivalTime?: Date;
    departureTime?: Date;
    stops?: Stop[] = [];

    onClick: (marker: RouteClass) => void;

    constructor(props?) {
        if (props) {
            this.id = props.id;
            this.start = new PointClass(props.start);
            this.end = new PointClass(props.end);
            this.showTime = props.showTime;
            this.ref = props.ref;
            this.departureDate = props.departureDate;
            this.arrivalDate = props.arrivalDate;
            this.startAddress = props.startAddress;
            this.endAddress = props.endAddress;
            this.onClick = props.onClick;
            this.stops = props.stops.map(x => new Stop(x));
            if (props.arrivalTime)
                this.arrivalTime = new Date(props.arrivalTime)
            if (props.departureTime)
                this.departureTime = new Date(props.departureTime);
        }
    }

    setArrivalAt(seconds: number) {
        this.arrivalAt = new Date(this.arrivalDate.getTime());
        this.arrivalAt.setSeconds(this.arrivalAt.getSeconds() + seconds);
    }

    setArrivalTrafficAt(seconds: number) {
        this.arrivalTrafficAt = new Date(this.arrivalDate.getTime());
        this.arrivalTrafficAt.setSeconds(this.arrivalTrafficAt.getSeconds() + seconds);
    }

    setDepartureAt(seconds: number) {
        this.departureAt = new Date(this.departureDate.getTime());
        this.departureAt.setSeconds(this.departureAt.getSeconds() + seconds);
    }

    setDepartureTrafficAt(seconds: number) {
        this.departureTrafficAt = new Date(this.departureDate.getTime());
        this.departureTrafficAt.setSeconds(this.departureTrafficAt.getSeconds() + seconds);
    }

    get getStartCoordinates() {
        // if (this.startAddress) {
        //     return this.startAddress
        // }
        // return {lat: this.start.lat, lng: this.start.lng}

       return this.stops?.[0]?.getCoordinate;
    }

    get getWaypoints() {
        if (this.stops) {
            const temp = this.stops.map(x => new Stop(x));

            temp.shift();
            temp.pop();
            return temp.map(x => x.getWaypoints)
        }

        return [];
    }

    get getEndCoordinates() {
        return this.stops?.[this.stops?.length - 1]?.getCoordinate;
    }
}

import FormGroup from "../FormGroup/FormGroup";
import {useEffect, useState} from "react";
import {WorkHours} from "../../classes/classes";

const LocationFormWorkHours = ({workHours, onChange}) => {
    const [currentWorkHours, setCurrentWorkHours] = useState<WorkHours>();

    useEffect(() => {
        if (workHours) setCurrentWorkHours(workHours);
        else setCurrentWorkHours(new WorkHours());
    }, [workHours]);

    const handleWorkingHours = (key, value, weekdays = true) => {
        let temp = {...currentWorkHours};

        if (weekdays) temp.weekdays[key] = value;
        else temp.weekends[key] = value;

        setCurrentWorkHours({...temp});
        onChange({...temp});
    }

    return(
        <>
            <p className="mb-2 font-12 font-medium">Working Hours</p>

            <div className="row">
                <div className="col-6">
                    <p className="mb-1 font-10 font-medium">Weekdays</p>
                </div>

                <div className="col-6">
                    <p className="mb-1 font-10 font-medium">Weekend</p>
                </div>

                <FormGroup name={'weekdays_from'}
                           label={'From'}
                           colSize={3}
                           value={currentWorkHours?.weekdays?.from}
                           placeholder={'Ex) 10:00 am to 12:00 pm'}
                           onTextChange={(event) => handleWorkingHours('from', event, true)}/>

                <FormGroup name={'weekdays_to'}
                           label={'To'}
                           colSize={3}
                           value={currentWorkHours?.weekdays?.to}
                           placeholder={'Ex) 10:00 am to 12:00 pm'}
                           onTextChange={(event) => handleWorkingHours('to', event, true)}/>

                <FormGroup name={'weekends_from'}
                           label={'From'}
                           colSize={3}
                           value={currentWorkHours?.weekends?.from}
                           placeholder={'Ex) 10:00 am to 12:00 pm'}
                           onTextChange={(event) => handleWorkingHours('from', event, false)}/>

                <FormGroup name={'weekends_to'}
                           colSize={3}
                           label={'To'}
                           value={currentWorkHours?.weekends?.to}
                           placeholder={'Ex) 10:00 am to 12:00 pm'}
                           onTextChange={(event) => handleWorkingHours('to', event, false)}/>
            </div>
        </>
    );
}

export default LocationFormWorkHours;

import gql from "graphql-tag";

const GET_ALL_DISPATCHING = gql`
    query{
  get_all_dispatching{
    price
    driver{
      id
      name
    }
    leg_id
    pending_change_request
    origin{
      timezoneName
      address{
        city
        state
        street
        country
        postal_code
        street_number
        postal_code_suffix
      }
      completed
      time_completed
      
      time_scheduled
      nofail
    }
    carrier{
      id
      name
    }
    vehicle{
      make
      model
      year
      type
      vin
      color
      submodel
    }
    customer{
      id
      name
      corporate_client
    }
    equipment
    destination{
      timezoneName
      address{
        city
        state
        street
        country
        postal_code
        street_number
        postal_code_suffix
      }
     completed
      time_completed
     
      time_scheduled
      nofail
    }
    velox_order_id
    velox_order{
      id
      accepted
      rejected
      canceled
      expired
      expires
      change_requests {
        id
        order_id
        accepted
        rejected
        time
        payout
        note
        active
        expired
        expires_at
        weblink
        code
      }
    }
    master_order_id
    vehicle_order_id
  }
}
`;

const LEG_HISTORY = gql`query($vehicle_search:String, $pickup_search: String, $dropoff_search:String, $customer_search:String, $leg_id_search: String, $master_order_id_search: String, $velox_id_search: String ){
  get_leg_history(vehicle_search:$vehicle_search, pickup_search:$pickup_search, dropoff_search:$dropoff_search, customer_search:$customer_search, leg_id_search: $leg_id_search, master_order_id_search: $master_order_id_search, velox_id_search: $velox_id_search){
    price
    driver{
      id
      name
    }
    leg_id
    pending_change_request
    origin{
      timezoneName
      address{
        city
        state
        street
        country
        postal_code
        street_number
        postal_code_suffix
      }
      completed
      time_completed
      
      time_scheduled
      nofail
    }
    carrier{
      id
      name
    }
    vehicle{
      make
      model
      year
      type
      vin
      color
      submodel
    }
    customer{
      id
      name
      corporate_client
    }
    equipment
    destination{
      timezoneName
      address{
        city
        state
        street
        country
        postal_code
        street_number
        postal_code_suffix
      }
     completed
      time_completed
     
      time_scheduled
      nofail
    }
    velox_order_id
    velox_order{
      id
      accepted
      rejected
      canceled
      expired
      expires
      change_requests {
        id
        order_id
        accepted
        rejected
        time
        payout
        note
        active
        expired
        expires_at
        weblink
        code
      }
    }
    master_order_id
    vehicle_order_id
  }
}
`;

const LEG_HISTORY_BY_CUSTOMER = gql`query($customer_id:Int, $vehicle_search:String, $pickup_search: String, $dropoff_search:String, $contact_search:String, $leg_id_search: String, $master_order_id_search: String, $velox_id_search: String ){
  get_leg_history_by_customer(customer_id:$customer_id, vehicle_search:$vehicle_search, pickup_search:$pickup_search, dropoff_search:$dropoff_search, contact_search:$contact_search, leg_id_search: $leg_id_search, master_order_id_search: $master_order_id_search, velox_id_search: $velox_id_search){
    price
    driver{
      id
      name
    }
    leg_id
    pending_change_request
    origin{
      timezoneName
      address{
        city
        state
        street
        country
        postal_code
        street_number
        postal_code_suffix
      }
      completed
      time_completed
      
      time_scheduled
      nofail
    }
    carrier{
      id
      name
    }
    vehicle{
      make
      model
      year
      type
      vin
      color
      submodel
    }
    customer{
      id
      name
      corporate_client
    }
    equipment
    destination{
      timezoneName
      address{
        city
        state
        street
        country
        postal_code
        street_number
        postal_code_suffix
      }
     completed
      time_completed
     
      time_scheduled
      nofail
    }
    velox_order_id
    velox_order{
      id
      accepted
      rejected
      canceled
      expired
      expires
      change_requests {
        id
        order_id
        accepted
        rejected
        time
        payout
        note
        active
        expired
        expires_at
        weblink
        code
      }
    }
    master_order_id
    vehicle_order_id
  }
}
`;

const LegQueries = {
  GET_ALL_DISPATCHING,
  LEG_HISTORY,
  LEG_HISTORY_BY_CUSTOMER
}

export default LegQueries;
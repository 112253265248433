import React, {useState, useRef, useEffect} from 'react'
import './HistoryPage.scss'
import LegTable from "../../components/LegTable/LegTable";
import VehicleOrderDispatch from "../../components/VehicleOrderDispatch/VehicleOrderDispatch";
import DriverCardJob from "../../components/DriverCardJob/DriverCardJob";
import DriverScheduleComponent from "../../components/DriverSchedule/DriverSchedule";
import { gql } from 'apollo-boost';
import { GraphqlService } from '../../services/graphql.service';
import useLoading from '../../hooks/useLoading/useLoading';
import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom';
import ICONS from '../../../assets/svg';
import ReactModal from 'react-modal';
import DispatchLogs from '../../components/DispatchLogs/DispatchLogs';

const HistoryPage = () => {
    useEffect(() => {
    }, []);

    const [selected, setSelected] = useState({id: null, leg_id: null, velox_order_id: null});
    const [componentsShown, setComponentsShown] = useState(true);
    const [refreshTable, setRefreshTable] = useState(true);
    const [logsOpened, setLogsOpened] = useState(false);
    const _loading = useLoading();
    const toast = useToasts();

    async function undispatchLeg(leg_id) {
        const mutation = gql`
                mutation($leg_id: Int) {
                undispatch_driver(leg_id:$leg_id){
                id
                message
                success
                }
                }
            `;
        try {
            _loading.open('Undispatching Leg...');
            const data = await GraphqlService.SendMutation(mutation, {leg_id});
            if (!data.success)
                throw new Error(data.message);
            _loading.close();

            setRefreshTable(false);
            setTimeout(() => {
                setRefreshTable(true);
            }, 1);

            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
        } catch (ex) {
            _loading.close();
            toast.addToast(ex.message, {appearance: 'error'});
        }
    }

    

    function handleChangeSchedule(leg_id, stop_id, is_pickup){

    }


    return(
        <>
            <div className='flex-1-container'>
                <div className="row mb-3">
                <h4 className="font-14">Leg History</h4>
                <div className="d-flex">
                    <button onClick={(evt) => setLogsOpened(true)} className="btn btn-outline mr-2 rounded-pill d-flex align-items-center"><img className='mr-0-5' src={ICONS.IconClock} alt="" /> SEE LOGS</button>
                </div>
            </div>
                    <div className='col-12 flex-1-container rounded p-3 d-flex align-items-start bg-white mb-3'>
                        <LegTable onUndispatchLeg={undispatchLeg} legID={selected.leg_id}
                                 refresh={refreshTable}
                                  onTableRefreshed={() => {
                                      setComponentsShown(false);
                                      setTimeout(() => {
                                          setComponentsShown(true);
                                      }, 1);
                                  }}
                                  tableHeight={'35vh'}
                                  showFilters={false}
                                  showToggles={false}
                                  showLegHistory={true}
                                  vehicleOrderSelected={selected}
                                  onVehicleOrderSelected={id => setSelected({...id, velox_order_id: null})} />
                    </div>

                    <div className="col-12 d-flex flex-column justify-content-between" >
                        <div className='bg-white p-3 mb-3' style={{height: 'auto'}}>
                            {<VehicleOrderDispatch onChangeScheduleClicked={handleChangeSchedule} onUndispatchLeg={undispatchLeg} onRefreshRequested={(evt) => {
                                setRefreshTable(false);
                                setTimeout(() => {
                                    setRefreshTable(true);
                                }, 1);
                            }} refresh={componentsShown} vehicleOrderId={selected.id} legId={selected.leg_id} veloxOrderId={selected.velox_order_id} />}
                        </div>
                    </div>
                </div>

                <ReactModal isOpen={logsOpened}>
                        <DispatchLogs onClose={(evt) => setLogsOpened(false)} />
                </ReactModal>
        </>
    )
}

export default HistoryPage
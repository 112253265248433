import {gql} from "apollo-boost";

const UPDATE = gql`
    mutation($key: String, $value: String) {
        update_user_settings(key: $key, value: $value){
            message
            success
        }
    }
`;


const UserSettingsMutations = {
    UPDATE
}

export default UserSettingsMutations;
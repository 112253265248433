import React, { useEffect, useState } from "react";
import "./CustomerProfileCard.scss";
import { Customer, CustomerById } from "../../classes/classes";
import CustomerForm from "../CustomerForm/CustomerForm";
import Loading from "../Loading/Loading";
import Modal from "react-modal";
import ICONS from "../../../assets/svg";
import { useDispatch } from "react-redux";
import { editCustomerInfo } from "../../redux/actions/Customer.action";
import { useToasts } from "react-toast-notifications";
import ReactModal from "react-modal";

const CustomerProfileCard = (props) => {
  const dispatch = useDispatch();
  const toast = useToasts();

  const [customer, setCustomer] = useState<CustomerById>(props.customer);
  const [editCustomerProfile, setEditCustomerProfile] = useState(undefined);

  useEffect(() => {
    setCustomer(new CustomerById(props.customer));
  }, []);

  return (
    <>
      <div className="card" id="customer-profile-card">
        <div className="card-body p-0">
          <div className="row mb-2">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <p className="font-12 font-medium">Customer</p>
              <button
                className="btn btn-blue-light rounded-pill font-10 font-medium"
                onClick={() => setEditCustomerProfile(props.customer)}
              >
                EDIT
              </button>
            </div>
          </div>

          <div className="bg-gray rounded p-3">
            <div className="row">
              <div className="col-6">
                <div className="row">
                  <div className="col-12 mb-3">
                    <p className="font-12 m-0 font-medium subtitle mb-1">
                      Name
                    </p>
                    <p className="font-14 m-0 font-medium mb-1">
                      {customer?.name}
                    </p>
                  </div>

                  <div className="col-6 mb-3">
                    <p className="font-12 m-0 font-medium subtitle mb-1">
                      Type
                    </p>
                    {customer?.corporate_client && (
                      <p className="font-14 font-medium">Corporate</p>
                    )}
                    {!customer?.corporate_client && (
                      <p className="font-14 font-medium">Private</p>
                    )}
                  </div>
                  <div className="col-6 mb-3">
                    <p className="font-12 m-0 font-medium subtitle mb-1">Key</p>
                    <p className="font-14 font-medium">{customer?.key}</p>
                  </div>

                  <div className="col-6 mb-3">
                    <p className="font-12 m-0 font-medium subtitle mb-1">
                      Terms
                    </p>
                    <p className="font-14 font-medium">{customer?.terms}</p>
                  </div>

                  <div className="col-6 mb-3">
                    <p className="font-12 m-0 font-medium subtitle mb-1">
                      Tax ID
                    </p>
                    <p className="font-14 font-medium text-red">*PROTECTED*</p>
                  </div>

                  <div className="col-12 mb-3">
                    <p className="font-12 m-0 font-medium subtitle mb-1">
                      Default invoice email
                    </p>
                    <p className="font-14 font-medium">
                      {customer?.default_invoice_email}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="row">
                  <div className="col-12 mb-3">
                    <p className="font-12 m-0 font-medium subtitle mb-1">
                      Billing address
                    </p>
                    <p className="font-14 m-0 font-medium mb-1">
                      {customer?.billing_address?.getName(1)}
                    </p>
                    <p className="font-14 m-0 font-medium mb-1">
                      {customer?.billing_address?.getName(2)}
                    </p>
                  </div>

                  {/* <div className="col-6 mb-3">
                                        <p className="font-12 m-0 font-medium subtitle mb-1">Work hours (Mon - Fri)</p>
                                        <p className="font-14 m-0 font-medium mb-1">{customer?.name}</p>
                                    </div> */}

                  {/* <div className="col-6 mb-3">
                                        <p className="font-12 m-0 font-medium subtitle mb-1">Work hours (Sat - Sun)</p>
                                        <p className="font-14 m-0 font-medium mb-1">{customer?.name}</p>
                                    </div> */}

                  {/* <div className="col-12 mb-3">
                                        <p className="font-12 m-0 font-medium subtitle mb-1">Internal notes</p>
                                        <p className="font-14 font-medium">{ customer?.internal_note }</p>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={editCustomerProfile != undefined}
        className={"modal-fit modal-90 modal-h"}
      >
        {
          <CustomerForm
            currentCustomer={editCustomerProfile}
            onCancel={() => setEditCustomerProfile(undefined)}
            onSubmit={(evt) => {
              //   dispatch(editCustomerInfo(evt.id, {
              //       name: evt.name,
              //       corporate_client: evt.corporate_client,
              //       key: evt.key,
              //       terms: evt.terms,
              //       internal_note: evt.internal_note
              //   }));
              //   toast.addToast("Customer information has been updated");

              props.onEditCustomer(true);
            }}
          />
        }
      </ReactModal>
    </>
  );
};

export default CustomerProfileCard;

import gql from "graphql-tag";

const CREATE_MASTER_ORDER = gql`
    mutation($order: master_order_input, $separate: Boolean){
        create_master_order(master_order:$order separate:$separate){
            id
            message
            success
        }
    }
`;

const CREATE_FROM_VECHILE_ORDER = gql`
    mutation($vehicle_order_id: Int, $master_order: master_order_input) {
    create_master_from_vehicle_order(
        vehicle_order_id: $vehicle_order_id
        master_order: $master_order
    ) {
        id
        message
        success
    }
    }
`;

const CREATE_FROM_LEG = gql`
    mutation($leg_id: Int, $master_order: master_order_input) {
  create_master_from_leg(
    leg_id: $leg_id
    master_order: $master_order
  ) {
    id
    message
    success
  }
}
`;

const MasterOrderMutations = {
    CREATE_MASTER_ORDER,
    CREATE_FROM_VECHILE_ORDER,
    CREATE_FROM_LEG
};

export default MasterOrderMutations;
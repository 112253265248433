import gql from "graphql-tag";

/**
 * GET ALL DRIVER LOCATIONS
 * @param none This does not need any param
 */
const GET_ALL_DRIVERS = gql`
    query($carrier_id: Int){
        get_driver_locations(carrier_id:$carrier_id){
            id
            name
            devices{
                time
                speed
                course
                device
                altitude
                coordinate{
                    Latitude
                    Longitude
                }
                device_info{
                    name
                    type
                    idiom
                    model
                    version
                    platform
                    manufacturer
                }
                background_mode
            }
            username
        }


        get_trucks_location{
            id
            name
            location {
                time
                latitude
                longitude
                heading
                speed
                reverseGeo {
                    formattedLocation
                }
            }
        }

    }
`;


const DriverMapQueries = {
    GET_ALL_DRIVERS,
}

export default DriverMapQueries;

import {gql} from 'apollo-boost';
import React, {ChangeEvent, FC, useEffect, useRef, useState} from 'react';
import {useToasts} from 'react-toast-notifications';
import {Address} from '../../classes/classes';
import Helper from '../../helper/Helper';
import useLoading from '../../hooks/useLoading/useLoading';
import {GraphqlService} from '../../services/graphql.service';
import FormGroup from '../FormGroup/FormGroup';
import AddressSearch from '../Google/AddressSearch';
import {
  CarrierTaxInfo, deleteMutationsCarrierProfile,
  FileDocument,
  mutationsCarrierProfile
} from "../../pages/CarriersProfilePage/CarriersProfilePage";
import useMessageBox from "../../hooks/useMessageBox/useMessageBox";
import {CarrierProfileHelper} from "../../helper/CarrierProfileHelper";

interface IProps {
  onClose: (evt) => any;
  onSubmitted: (evt) => any;
  data: CarrierTaxInfo;
  carrier_id: number;
}

const mutation = gql`
    mutation($tax_information: velox_carrier_tax_info_input) {
        update_velox_carrier_tax_info(tax_information: $tax_information) {
          id
          message
          success
        }
    }
`;

const TaxInfo: FC<IProps> = (props) => {
  const [info, setInfo] = useState(new CarrierTaxInfo());
  const messageBox = useMessageBox();
  const loading = useLoading();
  const toast = useToasts();
  const fileRef = useRef<HTMLInputElement>();
  const [businessType, setBusinessType] = useState<string>(props.data.type || '-- Select --');

  useEffect(() => {
    loadCarrierTaxInfo(props.carrier_id)
  }, [props.data]);


  const states = Helper.States;
  const countries = ['United States', 'Canada'];

  async function handleSubmit(evt) {
    try {

      loading.open('Updating...');
      const data = await GraphqlService.SendMutation(mutation, {
        "tax_information": {
          "carrier_id": props.carrier_id,
          "name": info.name,
          "business_name": info.business_name,
          "type": info.type,
          "address": info.address,
          "ssn": (businessType == types[0]) ? info.ssn : '',
          "ein": info.ein
        }
      });

      if (!data?.success)
        throw new Error(data?.message || 'Something went wrong');


      loading.close();


      toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
      props.onSubmitted(info);


    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
    }
  }

  const isValid = () => {
    return info.name.trim() != '' && info.type.trim() != ''
      && info.address.street.trim() != '' && info.address.street_number.trim() != '' && info.address.city.trim() != ''
      && info.address.state.trim() != '' && info.address.postal_code.trim() != ''
      && info.address.country.trim() != '';

  }

  const types =
    [
      'Individual/sole proprietor or single-member LLC',
      'C corporation',
      'S Corporation',
      'Partnership',
      'Trust/estate',
      'LLC (C corporation)',
      'LLC (S corporation)',
      'LLC (Partnership)'
    ];


  function handleUpdateInfo(key, value) {
    const c = new CarrierTaxInfo(info);
    c[key] = value;
    if (key == 'type') {
      setBusinessType(value);
    }
    setInfo(c);
  }

  function updateAddress(address) {
    const c = new CarrierTaxInfo(info);
    c.address = new Address(address);
    setInfo(c);
  }

  function ssnFormat(ssn) {
    var val = ssn.replace(/[^\d-]/g, '');

    // add the first dash if number from the second group appear
    val = val.replace(/^(\d{3})-?(\d{1,2})/, '$1-$2');

    // add the second dash if numbers from the third group appear
    val = val.replace(/^(\d{3})-?(\d{2})-?(\d{1,4})/, '$1-$2-$3');

    // remove misplaced dashes
    val = val.split('').filter((val, idx) => {
      return val !== '-' || idx === 3 || idx === 6;
    }).join('');


    // enforce max length
    return val.substring(0, 11);
  }

  function einFormat(ein) {
    var val = ein.replace(/[^\d-]/g, '');

    // add the first dash if number from the second group appear
    val = val.replace(/^(\d{2})-?(\d{1,2})/, '$1-$2');

    // add the second dash if numbers from the third group appear
    val = val.replace(/^(\d{3})-?(\d{2})-?(\d{1,4})/, '$1-$2-$3');

    // remove misplaced dashes
    val = val.split('').filter((val, idx) => {
      return val !== '-' || idx === 2;
    }).join('');


    // enforce max length
    return val.substring(0, 10);
  }

  async function removeFile(document: FileDocument) {
    messageBox.open({
      title: 'Delete file: ' + document.name,
      message: `Are you sure you want to delete this file?, you won't be able to revert this!`,
      buttons: [
        {
          text: 'Cancel',
          css: 'btn btn-shy-light'
        },
        {
          text: 'Confirm',
          css: 'btn btn-blue-light',
          action: () => deleteFile(document)
        }
      ]
    })
  }

  async function loadCarrierTaxInfo(carrier_id: number) {
    try {
      const query = gql`
            query ($carrier_id: Int){
                get_velox_carrier_tax_information(carrier_id: $carrier_id) {
                  carrier_id
                  id
                  name
                  ssn
                  ein
                  business_name
                  type 
                  signature
                  time_signed
                  address {
                    street
                    city
                    state
                    postal_code
                    country
                    street_number

                  }
                  uploaded_documents{
                    uid
                    name
                  }
                }
              }
            `;
      const data = await GraphqlService.SendQuery(query, {carrier_id});
      setInfo(new CarrierTaxInfo(data));
    } catch (ex) {

    }
  }

  async function deleteFile(document: FileDocument) {
    try {
      loading.open(`Deleting insurance document: ${document.name}...`);
      const data = await GraphqlService.SendMutation(deleteMutationsCarrierProfile['tax info'], {carrier_id: props.carrier_id, uid: document.uid});
      if (!data?.success)
        throw new Error(data?.message || 'Something went wrong');
      loading.close();
      await loadCarrierTaxInfo(props.carrier_id);
      toast.addToast(data.message, {appearance: 'success',autoDismiss: true});
    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, {appearance: 'error',autoDismiss: true});
    }
  }

  async function handleInputChange(evt: ChangeEvent<HTMLInputElement>) {
    try {
      const file = evt.target.files[0];
      loading.open(`Uploading tax info file: ${file.name}`);
      const data = await GraphqlService.SendMutation(mutationsCarrierProfile['tax info'], {carrier_id: props.carrier_id, file});
      if (!data?.success)
        throw new Error(data?.message || 'Something went wrong');
      await loadCarrierTaxInfo(props.carrier_id);
      toast.addToast(data?.message, {appearance: 'success',autoDismiss: true});
    } catch (ex) {
      toast.addToast(ex.message, {appearance: 'error',autoDismiss: true});
    } finally {
      loading.close();
    }
  }

  async function previewFile(doc: FileDocument) {
    await CarrierProfileHelper.previewFile(doc);
  }

  return (
    <div className='flex-1-container'>
      <div className="mb-3 row">
        <h2 className='font-14'>Carrier Tax Info</h2>
        <h4 onClick={props.onClose} className="font-14 cursor-pointer"><i className='fas fa-times'></i></h4>
      </div>

      <div className="flex-1-container">
        <div className="row">
          <FormGroup placeholder='Name...' colSize={12} name={'name'}
                     label={'Name (as shown on your income tax return). Name is required on this line; do not leave this line blank.'}
                     required value={info.name} onTextChange={(evt) => handleUpdateInfo('name', evt)}/>
          <FormGroup placeholder='Bussines Name...' colSize={12} name={'bussines_name'}
                     label={'Business name/disregarded entity name, if different from above'} value={info.business_name}
                     onTextChange={(evt) => handleUpdateInfo('business_name', evt)}/>
          <div className="col-12 row">
            <FormGroup options={{data: types, label: x => x, value: x => x}} placeholder='-- Select --' type='select'
                       colSize={12} name={'bussines_type'} label={'Bussines Type'} required value={businessType}
                       onTextChange={(evt) => handleUpdateInfo('type', evt)}/>
          </div>
          {(types.includes(businessType)) &&
          <>
              <FormGroup placeholder='XX-XXXXXXX' type='text' colSize={6} name={'ein'} label={'EIN'} value={info.ein}
                         onTextChange={(evt) => handleUpdateInfo('ein', einFormat(evt))}/>
            {(businessType == types[0]) &&
            <FormGroup placeholder='XXX-XX-XXXX' type='text' colSize={6} name={'ssn'} label={'SSN'} value={info.ssn}
                       onTextChange={(evt) => handleUpdateInfo('ssn', ssnFormat(evt))}/>}
          </>
          }
        </div>

        <div className="row">
          <div className="col-12">
            <input type='file'
                   style={{display: 'none'}}
                   ref={fileRef}
                   onChange={evt => handleInputChange(evt)} />
          </div>
          <div className="col-12 d-flex justify-content-between align-items-center my-2">
            <span className={`label-span`}>Files</span>
            <span onClick={() => fileRef.current?.click()}
                  className={`btn btn-blue-light py-1`}>Upload</span>
          </div>
          <div className="col-12">
            {info.uploaded_documents.length == 0 && <h4 className='text-red font-12 text-left cursor-pointer'
                                                                    onClick={() => fileRef.current?.click()}>Upload a document showing your tax info</h4>}
            {info.uploaded_documents.map((document, index) => <div style={{maxWidth: '100%'}}
                                                                    className={"document-row align-items-center" + (index == info.uploaded_documents.length - 1 ? '' : ' mb-3')}>
              <h4 style={{textOverflow: 'clip', overflow: 'auto', whiteSpace: 'nowrap'}} className="font-12">{document.name}</h4>
              <div className="d-flex">
                <h4 className="font-16 mr-2 cursor-pointer text-light-blue text-right" onClick={(evt) => previewFile(document)}>
                  <i className='fas fa-eye'></i>
                </h4>
                <h4 className="font-16 cursor-pointer text-right" onClick={() => removeFile(document)}>
                  <i className='fas fa-times'></i>
                </h4>
              </div>
            </div>)}
          </div>
        </div>


        <h4 className="font-14 font-bold mb-3">Address Information</h4>

        <AddressSearch className=""
                       onGotAddress={(address) => {
                         const i = new CarrierTaxInfo(info);
                         i.address = new Address(address);
                         setInfo(i);
                       }}/>

        <div className="row col-12">
          <FormGroup label={'Street #'}
                     colSize={3}
                     value={info.address?.street_number}
                     name={'streetNumber'}
                     placeholder={'Street #'}

                     required
                     onTextChange={value => updateAddress({...info.address, street_number: value})}/>

          <FormGroup name={'street'}
                     colSize={9}
                     label={'Street address'}
                     value={info.address?.street}
                     required
                     placeholder={'Street'}
                     onTextChange={(event) => updateAddress({...info.address, street: event})}/>
        </div>


        <div className="row">
          <div className="row col-12">
            <FormGroup name={'city'}
                       colSize={12}
                       label={'City'}
                       required
                       value={info.address?.city}
                       placeholder={'City'}
                       onTextChange={(event) => updateAddress({...info.address, city: event})}/>

            <FormGroup name={'state'}
                       label={'State'}
                       colSize={6}
                       required
                       value={info.address?.state}
                       type='select'
                       placeholder={'--'}
                       options={{data: states, value: x => x, label: x => x}}
                       onTextChange={(event) => updateAddress({...info.address, state: event})}/>

            <FormGroup name={'postalCode'}
                       label={'Postal Code'}
                       colSize={6}
                       value={info.address?.postal_code}
                       required
                       placeholder={'00000'}
                       onTextChange={(event) => updateAddress({
                         ...info.address,
                         postal_code: Helper.Masks.IntNumbers(event, 5)
                       })}/>

            <FormGroup label={'Country'}
                       name={'country'}
                       colSize={6}
                       value={info.address.country}
                       type={'select'}
                       placeholder={'-- Select a country --'}
                       options={{data: countries, value: x => x, label: x => x}}
                       onTextChange={value => updateAddress({...info.address, country: value})}/>
          </div>


        </div>
      </div>

      <div className="mt-3 row">
        <button className="btn bg-white text-light-blue col-6 rounded-pill py-2" onClick={props.onClose}>Cancel</button>
        <button className="btn btn-blue-light col-6 rounded-pill py-2" onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
}

export default TaxInfo;
import {useEffect, useRef, useState} from "react";
import ICONS from "../../../assets/svg";
import { NavLink,useHistory } from "react-router-dom";

import './SideMenu.scss';
import { GraphqlService, ObservableQueryT } from "../../services/graphql.service";
import QuoteQueries from "../../graphql/query/Quote";
import { Subscription } from "rxjs";
import { Quote } from "../../classes/classes";
import environments from "../../../environments/environments";
import { SafeStorage } from "../../services/Storage/storageService";

class MenuOption {
    title: string = '';
    icon: string = '';
    uri: string = '';

    constructor(props) {
        this.title = props.title;
        this.icon = props.icon;
        this.uri = props.uri;
    }
}

const baseMenuOptions: MenuOption[] = [

    {
        title: 'Recent Orders',
        icon: ICONS.LEG_HISTORY,
        uri: '/recent-orders'
    },
    {
        title: 'Dispatch',
        icon: ICONS.DISPATCH,
        uri: '/dispatch'
    },
    {
        title: 'Order Search',
        icon: ICONS.ORDERS,
        uri: '/orders'
    },

    {
        title: 'Leg History',
        icon: ICONS.LEG_HISTORY,
        uri: '/history'
    },
    {
        title: 'Quotes',
        icon: ICONS.ORDERS,
        uri: '/quotes'
    },
    {
        title: 'Receivables',
        icon: ICONS.AR,
        uri: '/receivables'
    },
    {
        title: 'Payables',
        icon: ICONS.AP,
        uri: '/payables'
    },
    {
        title: 'Storage',
        icon: ICONS.INVENTORY,
        uri: '/storage'
    },
    {
        title: 'Warehouse',
        icon: ICONS.INVENTORY,
        uri: '/warehouse'
    },
    {
        title: 'Fleet View',
        icon: ICONS.IconMapView,
        uri: '/drivers'
    },
    {
        title: 'Customers',
        icon: ICONS.CUSTOMER,
        uri: '/customers'
    },
    {
        title: 'Carriers',
        icon: ICONS.DISPATCH,
        uri: '/carriers'
    }
];


const SideBar = ({notificationsNotSeen}) => {

    const [menuOptions, setMenuOptions] = useState([]);
    const [collapsed, setCollapsed] = useState(true);
    const [quotesNotAccepted, setQuotesNotAccepted] = useState([]);
    const query = useRef<ObservableQueryT>();
    const subs = useRef<Subscription>();

    function init() {
        query.current = GraphqlService.SendQueryObservable(QuoteQueries.GET_ALL);
        subs.current = query.current.subscribe(({data, errors}) => {
            setQuotesNotAccepted(data.quotes.map(quote => new Quote(quote)).filter(x => x.status == 'NEEDS QUOTE'))
        }) as Subscription;

        
    }


    useEffect(() => {
        let sideMenus = baseMenuOptions.filter(x => SafeStorage.checkPermisionsRoute(x.uri)).map(menu => new MenuOption(menu));
        setMenuOptions(sideMenus);
        if (environments.enableQuotesListener)
        {
            init();
            return () => {
                if (query.current)
                {
                    query.current.stopPolling();
                    subs.current?.unsubscribe();
                }
            }
        }
    }, []);


    const currentRoute = useHistory().location.pathname;



    return (
        <>
            <nav id="sidebar" className={collapsed && 'collapsed' || ''} >
                <div className="sidebar-header position-relative">
                    <button onClick={(evt) => setCollapsed(!collapsed)} className="position-absolute p-0 btn btn-shy-light" style={{width: '32px', height: '32px', borderRadius: '50%', right: '-16px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><i className={`fas fa-chevron-${collapsed ? 'right' : 'left'}`}></i></button>
                </div>

                <ul className="list-unstyled components">
                {SafeStorage.checkPermissions('new-order') && <li key={`menu--1`} onClick={(evt) => evt.stopPropagation()}>
                    <a
                        target='_blank'
                        rel="noreferrer"
                        className={` navbar-item`}
                        href={'/new-order'}>
                        <img src={ICONS.NEW_ORDER} alt=""/><span className="sliderbar-lable">{collapsed ? '' : 'New Order'}</span>
                    </a>
                </li>}
                    {

                        menuOptions.map((menu: MenuOption, i) => {
                            return(
                                <li key={`menu-${i}`} onClick={(evt) => evt.stopPropagation()}>
                                    <NavLink
                                        className={`position-relative font-medium navbar-item ${currentRoute===menu.uri || (currentRoute.startsWith('/order/') && i == 0) ? "is-active":""}`}
                                        to={menu.uri}>
                                        <img src={menu.icon} alt=""/><span className="sliderbar-lable">{collapsed ? '' : menu.title}
                                        {menu.uri == '/quotes' && <span className="sliderbar-lable">{collapsed ? '' : ``} {(quotesNotAccepted.length > 0) && <span className='bg-red text-center p-0 text-white align-items-center justify-content-center' style={{transform: `scale(${collapsed ? .75 : 1})`, display: 'inline-flex', width: '1.4rem', height: '1.4rem', borderRadius: '50%', position: 'absolute', right: '1rem'}}>{quotesNotAccepted.length}</span>}</span>}
                                        </span>
                                    </NavLink>
                                </li>
                            );
                        })

                    }
                    {SafeStorage.checkPermissions('notifications') && <li key={`menu-not`} onClick={(evt) => evt.stopPropagation()}>
                        <NavLink
                            className={`position-relative navbar-item ${currentRoute=== '/notifications' ? "is-active":""}`}
                            to={'/notifications'}>
                            <img src={ICONS.NOTIFICATIONS} alt=""/>
                            <span className="sliderbar-lable">{collapsed ? '' : `Notifications`} {(  notificationsNotSeen > 0) &&  <span className='bg-red text-center p-0 text-white align-items-center justify-content-center' style={{transform: `scale(${collapsed ? .75 : 1})`, display: 'inline-flex', width: '1.4rem', height: '1.4rem', borderRadius: '50%', position: 'absolute', right: '1rem'}}>{notificationsNotSeen}</span>}
                            </span>
                        </NavLink>
                    </li>}

                    {SafeStorage.checkPermissions('settings') && <li key={`menu-${'settings'}`} onClick={(evt) => evt.stopPropagation()}>
                        <NavLink
                            className={`position-relative font-medium navbar-item ${currentRoute==='/settings'  ? "is-active":""}`}
                            to={'/settings'}>
                            <img src={ICONS.SETTING} alt=""/>
                            <span className="sliderbar-lable">{collapsed ? '' : 'Settings'} </span>
                        </NavLink>
                    </li>}
                </ul>
            </nav>
        </>
    );
};

export default SideBar;

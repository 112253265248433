import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import ICONS from '../../../../assets/svg';
import {Customer, CustomerList, Stop} from '../../../classes/classes';
import CustomerQueries from '../../../graphql/query/Customer';
import { GraphqlService } from '../../../services/graphql.service';
import Loading from '../../Loading/Loading';
import MultilineEllipsis from '../../MultilineEllipsis/MultilineEllipsis';
import Searchbar from '../../Searchbar/Searchbar';
import ReactModal from "react-modal";
import CustomerForm from "../../CustomerForm/CustomerForm";
import CustomerMutations from "../../../graphql/mutation/Customer";
import {getAllCustomersList} from "../../../redux/actions/Customer.action";
import {useToasts} from "react-toast-notifications";
import useLoading from "../../../hooks/useLoading/useLoading";
import {useDispatch , useSelector} from "react-redux";
import { MenuItem } from '@szhsin/react-menu';
import { ControlledMenu } from '@szhsin/react-menu';
import {getAllCustomerProfilesList, getCustomerInfo, getCustomerLocations} from "../../../redux/actions/Customer.action";
import {AllStates} from "../../../redux/reducers";
import {ICustomerState} from "../../../redux/reducers/Customer.reducer";

import { editCustomerInfo } from '../../../redux/actions/Customer.action';

import Helper from '../../../helper/Helper';



const CustomerSearch = ({onChangeCustomer, customer_id, onCancel}) => {

    const [customers, setCustomers] = useState<CustomerList[]>([]);
    const [customerSearch, setCustomerSearch] = useState('');
    const [customerLoading, setCustomerLoading] = useState(false);

    const [selectedCustomer, setSelectedCustomer] = useState<any>(undefined);
    const [debounceSearch, setDebounceSearch] = useState('');


    const [openCustomerModal, setOpenCustomerModal] = useState(false);
    const [MyRow,setMyRow]=useState<any>()
    const [idslect,setIdSelect]=useState<any>()
    const customerRef = useRef<HTMLInputElement>();

    const dispatch = useDispatch()
    const [focusID, setFocusID] = useState(null);
    
   const customersFiltered = useCallback(() => {
    return customers.filter(x => JSON.stringify(x).toLowerCase().includes(customerSearch.trim().toLowerCase()))
   }, [customerSearch, customers]);

   const timeout = useRef(null);


   const ref =useRef()

   const toast = useToasts()
   const {customerById, loading} = useSelector<AllStates>(states => states.customers) as ICustomerState;

   const {
       loadingProfiles,
       customerProfiles,
       errorProfiles,
       customerLocations,
       loadingLocations,
       errorLocations
   } = useSelector<AllStates>(states => states.customers) as ICustomerState;

   

  useEffect(() => {
       if (customerById) {
           customerById.location = customerLocations[0];
           customerById.customer_profile = customerProfiles[0];
       }
       setSelectedCustomer(customerById);
   }, [customerById]); 

      useEffect(() => {
        dispatch(getAllCustomerProfilesList(idslect));
        dispatch(getCustomerLocations(idslect));
    }, [idslect]);  
    


    useEffect(() => {
        (document.getElementById('customer-' + focusID)?.firstChild as any)?.classList.remove('bg-blue');
        setFocusID(null);
        if (!customerLoading)
        {
            // setSearch();
        }
            
        
    }, [customerSearch]);

 
    
    



    

    async function load() {
        try {
            setCustomerLoading(true);
            const data = await GraphqlService.SendQuery(CustomerQueries.GET_ALL_LIST);
            setCustomerLoading(false);
            const temp = (data.map(x => new CustomerList(x)) as CustomerList[]).sort((x, y) => x.name.localeCompare(y.name)); 
            setCustomers(temp);
            // filterCustomers(temp);
         

            return temp;
        } catch (ex) {
            setCustomerLoading(false);
            console.log('error', ex.message);
        }
    }

    const handleKeyUp = (evt) => {
        

        

        

        evt.stopPropagation();
        // console.log(evt.keyCode);

       
        
        const customers = customersFiltered();
        if (customers.length == 0) return;

        let index = customers.findIndex(x => x.id == focusID);
        // console.log(evt.keyCode);

        let lastID = customers[index]?.id;
        if (evt.keyCode == 40)
        {
            if (index < customers.length - 1)
                index++;
        }
        else if (evt.keyCode == 38)
        {
            if (index > 0)
                index--;
        }
        else
            return;

        
        setFocusID(customers[index]?.id);
        const id = 'customer-' + customers[index]?.id;

        console.log(id, lastID);
        const div = document.getElementById(id) as HTMLDivElement;
        if (!id)
            return;


        if (lastID != null)
        {
            (document.getElementById('customer-' + lastID).firstChild as any)?.classList.remove('bg-blue');
        }

        window.requestAnimationFrame(() => {
            (div?.firstChild as any)?.classList.add('bg-blue');
            div?.scrollIntoView({behavior: 'smooth',  block: 'nearest', inline: 'start'}) ;
        })
    }

    useEffect(() => {
        load().then();
        if (customerRef.current)
        {
            
            setTimeout(() => {
             
                customerRef.current?.focus();   
            }, 100);
            
        }
        // return () => customerRef.current?.removeEventListener('keyup', handleKeyPress);
        
    }, []);

    const onContextMenuHandled = (event) => {
        event.preventDefault();
        setMyRow( { x: event.clientX, y: event.clientY });
    }

    function rowRenderer({key, index, style}) {
        
        const myCustomer = customersFiltered()[index];
        // const last = customersFiltered().length - 1 == index;
        const address = myCustomer.billing_address;
        return (
          <div  onContextMenu={(evt)=>{
                    onContextMenuHandled(evt)
                    setSelectedCustomer(myCustomer)
                    setIdSelect(myCustomer.id)}}      
                id={'customer-' + myCustomer.id} key={key} style={style}>
            <div  className={'bg-light cursor-pointer light-hover' + (myCustomer.id == customer_id && myCustomer.id != focusID? ' bg-gold' : '')} style={{padding: '10px'}} onClick={(evt) => {
                if (myCustomer.id == customer_id)
                {
                    onChangeCustomer(myCustomer, []);
                }
                else
                {
                    onChangeCustomer(myCustomer, []);
                }
                // setCs(null);
                
            }}>
                <p style={{fontSize: '12px'}} className=" font-bold">{myCustomer.name}</p>
                <p  style={{fontSize: '12px'}} className={'mt-1 mb-1' + (myCustomer.key ? '' : ' text-gray font-bold')}> {myCustomer.key ? `Key: (${myCustomer.key})` : 'NO KEY'}</p>
                <div className='mt-1'>
                    <p  style={{fontSize: '12px', fontWeight: 'normal'}}>{address.getName(1)}</p>
                    <p  style={{fontSize: '12px', fontWeight: 'normal'}}>{address.getName(2)}</p>
                </div>
            </div>
          </div>
        );
      }

    const handleSaveCustomer = async (cust: any) => {
        onChangeCustomer(cust, []);
        setOpenCustomerModal(false);
        load().then();
    }

    return (
        <>
            <div onClick={(evt) => evt.stopPropagation()} className="flex-1-container" >
                <div className="row mb-3">
                    <div className='d-flex'>
                        {customer_id && <h4 onClick={onCancel} className='font-9 mr-2 cursor-pointer text-red'><i className="fas fa-arrow-left"></i></h4>}<h4 className="font-9">Customers: {customersFiltered().length} / {customers.length}</h4>
                    </div>
                    <h4 style={{marginTop: '-.45rem', marginRight: '-.45rem'}}
                        className="d-flex align-items-center font-9 text-blue-light cursor-pointer"
                        onClick={() => {
                            setOpenCustomerModal(true);
                            setSelectedCustomer(undefined)
                        }}><img src={ICONS.IconPlus} alt="" /></h4>
                </div>
                <div className="d-flex align-items-center">
                    <div className="col-12">
                        <Searchbar onKeyEnter={(evt) => {
                            if (!focusID) return;
                            onChangeCustomer(new CustomerList(customers.find(x => x.id == focusID)), [])
                        }} onBlur={(evt) => {
                            (document.getElementById('customer-' + focusID)?.firstChild as any)?.classList.remove('bg-blue');
                            setFocusID(null);
                        }} onKeyUp={handleKeyUp} onChange={(evt) => {
                            
                            clearTimeout(timeout.current);

                            // Make a new timeout set to go off in 1000ms (1 second)
                            timeout.current = setTimeout(function () {
                                setCustomerSearch(evt.target.value);
                            }, 100)
                        }} background={'#f2f2f2'} reference={customerRef} />
                    </div>
                    <button onClick={(evt) => load()} className="btn btn-icon-only ml-2"><img src={ICONS.IconRefresh} className={customerLoading ? 'spin' : ''} alt="" /></button>
                </div>
                <div ref={ref} className='flex-1-container mt-3'>
                    {customerLoading && <div>
                        <Loading />
                    </div>}
                    {!customerLoading && <AutoSizer>
                        {({height, width}) => (
                            <List
                                overscanRowCount={2}
                                height={height}
                                rowCount={customersFiltered().length}
                                rowHeight={100}
                                rowRenderer={rowRenderer}
                                width={width}
                            />
                        )}
                    </AutoSizer>}
                    <ControlledMenu boundingBoxRef={ref}
                                onContextMenu={(evt) => {
                                    evt.stopPropagation();
                                    evt.preventDefault()
                                }}
                                anchorPoint={MyRow}
                                isOpen={ MyRow != null}
                                animation={ false }
                                onClose={() => {
                                    setMyRow(null)
                                }}>
                    {
                     
                        <MenuItem className='my-menuitem'
                                  onClick={()=>{
                                        dispatch(getCustomerInfo(idslect))
                                        setOpenCustomerModal(true)
                                        setMyRow(null)
                                        setSelectedCustomer(customerById)
                                    }} 
                                  style={{paddingLeft: '0'}}> 
                                <img style={{marginLeft: '.1rem', marginRight: '.45rem', width: '20px'}} src={ICONS.IconDetails} alt=""/>Edit Customer
                        </MenuItem>
                    }
                </ControlledMenu>
                </div>
            </div>

            {
                openCustomerModal && <ReactModal className="modal-large" isOpen={openCustomerModal}>
                    <CustomerForm 
                                currentCustomer={selectedCustomer}
                                  onCancel={() => {setOpenCustomerModal(false)}}
                                  onSubmit={(evt) => { 
                                        let index = customers.findIndex(x => x.id == evt.id )
                                        if(index === -1){
                                             handleSaveCustomer(evt)
                                        }else{
                                            dispatch(editCustomerInfo(evt.id, {
                                                name: evt.name,
                                                corporate_client: evt.corporate_client,
                                                key: evt.key,
                                                terms: evt.terms,
                                                internal_note: evt.internal_note
                                            }));
                                            toast.addToast("Customer information has been updated",{ autoDismiss: true});
                                            setCustomers(customers.map(x=>{
                                                if(x.id === evt.id){
                                                    x.name = evt.name
                                                    x.corporate_client = evt.corporate_client
                                                    x.key = evt.key
                                                }
                                                return x
                                            }))
                                            setOpenCustomerModal(false)
                                        }
                                      }} />
                </ReactModal>
            }
        </>
    );
}

export default CustomerSearch;
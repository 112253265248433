import {Employee} from "../employee";
import {Users} from "../../users/users";
import {Tax_Info} from "../../customer/customer";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";

export const get_all_employees = (input:{}) : Promise<Employee[]> => {
  let query = gql`
    query {
      get_all_employees {
        id
        name
        address
        user {
          id
          name
          level
          active
        }
        permission
        department
        active
        note
        documents {
          uid
          name
        }
        last_payable {
          id
          time
          type
          reference
          note
          amount
          category
          time_paid
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Employee[]>;
}

export const get_employee_by_id = (input:{employee_id: number}) : Promise<Employee> => {
  let query = gql`
    query($employee_id: Int) {
      get_employee_by_id(employee_id: $employee_id) {
        id
        name
        address
        user {
          id
          name
          level
          active
        }
        permission
        department
        active
        note
        documents {
          uid
          name
        }
        last_payable {
          id
          time
          type
          reference
          note
          amount
          category
          time_paid
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, { employee_id } as 
  Promise<Employee>;
}

export const get_employee_tax_info = (input:{employee_id: number, password: string}) : Promise<Tax_Info> => {
  let query = gql`
    query($employee_id: Int, $password: String) {
      get_employee_tax_info(employee_id: $employee_id, password: $password) {
        type
        value
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, { employee_id, password } as 
  Promise<Tax_Info>;
}

export const get_user_by_employe = (input:{employee_id: number}) : Promise<Users> => {
  let query = gql`
    query($employee_id: Int) {
      get_user_by_employe(employee_id: $employee_id) {
        id
        name
        level
        active
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, { employee_id } as 
  Promise<Users>;
}
import gql from "graphql-tag";

/**
 * GET ALL CUSTOMERS LIST
 * @param none This does not need any param
 */
const GET_ALL_LIST = gql`query{
    get_customer_search_list{
      id
      key
      name
      corporate_client
      billing_address {
            street_number
            street
            city
            state
            postal_code
            country    
      }
    }
  }`



/**
 * GET ALL CUSTOMER PROFILE LIST BY customerId
 * @param customerId takes this value to search profiles by customer ID
 */
const GET_ALL_PROFILE_LIST = gql`
    query ($customerId: Int){
        get_customer_profile_search_list(customerID: $customerId){
            id
            name
            email
            title
            numbers
            contact{
                type
                title
                value
                unsubscribed
            }
        }
    }
`

const GET_CUSTOMER_PROFILE_BY_ID = gql`
  query ($profile_id: Int) {
    get_customer_profile(profile_id: $profile_id) {
            id
            name
            role
            email
            title
            contact {
                type
                title
                value
                unsubscribed
            }
        }
    }
`;

const GET_ALL_LOCATIONS = gql`
    query ($customer_id: Int){
        all_locations_by_customer(customer_id: $customer_id){
            id
            address{
                city
                state
                street
                country
                postal_code
                street_number
                postal_code_suffix
            }
            title
            internal_note
            driver_note
            work_hours {
                weekdays {
                    from
                    to
                }
                weekends {
                    from
                    to
                }
            }
        }
    }
`

const GET_GOOGLE_PLACE = gql`
    query ($placeid: String){
        get_google_place_details(placeid: $placeid){
            formatted
            address{
                city
                state
                street
                country
                postal_code
                street_number
                postal_code_suffix
            }
        }
    }
`;

const GET_CUSTOMER_INFO = gql(`query($customer_id: Int) {
  customer_by_id(customer_id:$customer_id) {
    id
    name
    corporate_client
    key
    internal_note
    terms
    active
    billing_address{
        city
        state
        street
        country
        postal_code
        street_number
        postal_code_suffix
    }
    default_invoice_email
    tax_number {
        type
        value
    }
  }
}`);

const CustomerQueries = {
    GET_ALL_LIST,
    GET_ALL_PROFILE_LIST,
    GET_ALL_LOCATIONS,
    GET_GOOGLE_PLACE,
    GET_CUSTOMER_INFO,
    GET_CUSTOMER_PROFILE_BY_ID
}

export default CustomerQueries;
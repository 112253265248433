import {Location, LocationInput} from "../location/location";
import {Leg} from "../leg/leg";
import {Customer_Profile} from "../customer_profile/customer_profile";
import {Service_Charge} from "../service_charge/service_charge";
import {Log} from "../storage_order/storage_order";

export class Stop {
    __typename?: "Stop"
    id?: number = null;
    location?: Location = new Location();
    leg?: Leg;
    is_pickup?: boolean = true;
    time_scheduled?: string |  Date = new Date();
    time_completed?: string |  Date = new Date();
    completed?: boolean = false;
    started?: boolean = false;
    canceled?: boolean = false;
    nofail?: boolean = false;
    contacts?: Customer_Profile[] = [];
    service_charges?: Service_Charge[] = [];
    time_frame?: Time_Frame = new Time_Frame();
    leg_id?: number = null;
    log?: Log[] = [];
    nofail_price?: number = 0;

    constructor(x?: Stop, isPickup = true){
        if(x){
            this.id = x.id;
            this.location = new Location(x.location);
            this.leg = x.leg;
            this.is_pickup = x.is_pickup;
            this.time_scheduled = new Date(x.time_scheduled);
            this.time_completed = new Date(x.time_completed);
            this.completed = x.completed;
            this.started = x.started;
            this.canceled = x.canceled;
            this.nofail = x.nofail;
            this.contacts = x.contacts?.map(z => z) || [];
            this.service_charges = x.service_charges?.map(z => z) || [];
            this.time_frame =  new Time_Frame(x.time_frame);
            this.leg_id = x.leg_id;
            this.log = x.log?.map(z => z) || [];
            this.nofail_price = x.nofail_price;
        }
        else{
            this.is_pickup = isPickup;
        }
    }
}

export class Time_Frame {
    __typename?: "Time_frame"
    end: string | Date = new Date();
    start: string | Date = new Date();

    constructor(x?: Time_Frame) {
        if(x){
            this.end = new Date(x.end);
            this.start  = new Date(x.start);
        }
    }
}

export class StopInput {
    id?: number = null;
    contacts?: StopContactsInput[] = [];
    location?: LocationInput = new LocationInput();
    is_pickup?: boolean = false;
    time_scheduled?: string = '';
    nofail?: boolean = false;
    nofail_price?: number = 0;
    time_frame?: Time_Frame_Input = new Time_Frame_Input();
    completed?: boolean = false;

    constructor(x?: StopInput){
        if(x){
            this.id = x.id;
            this.contacts = x.contacts?.map(z => z) || [];
            this.location =  new LocationInput(x.location);
            this.is_pickup = x.is_pickup;
            this.time_scheduled = x.time_scheduled;
            this.nofail = x.nofail;
            this.nofail_price = x.nofail_price;
            this.time_frame = new Time_Frame_Input(x.time_frame); 
            this.completed = x.completed;            
        }
    }
}

export class Time_Frame_Input {
    end: string | Date = new Date();
    start: string | Date = new Date();

    constructor(x?: Time_Frame_Input){
        if(x){
            this.end = new Date(x.end);
            this.start = new Date(x.start);
        }
    }
}

export class StopContactsInput {
    id?: number = null;
    stop_id?: number = null;
    customer_profile_id?: number = null;
    name?: string = '';

    constructor(x?: StopContactsInput){
        if(x){
            this.id = x.id;
            this.stop_id = x.stop_id;
            this.customer_profile_id = x.customer_profile_id;
            this.name = x.name;
        }
    }
}

export class Update_Stop_Contacts {
    key?: string = '';
    value?: string[] = [];

    constructor (x?: Update_Stop_Contacts){
        if(x){
            this.key = x.key;
            this.value = x.value;
        }
    }
}

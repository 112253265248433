import React, {useState, useRef, useEffect} from 'react'
import {useToasts} from "react-toast-notifications";
import {AllStates} from "../../redux/reducers";
import {useDispatch, useSelector} from "react-redux";
import {IOrderReceivables} from "../../redux/reducers/OrderReceivables.reducer";
import Table from "../../components/Table/Table";
import IColumn from "../../components/Table/models/IColumn";
import ICONS from "../../../assets/svg";
import Searchbar from "../../components/Searchbar/Searchbar";
import getReceivables from '../../redux/actions/OrderReceivables.action';
import {IAgingState} from "../../redux/reducers/aging.reducer";
import Helper from "../../helper/Helper";
import {getReceivableAging} from "../../redux/actions/aging.action";
import FormGroup from '../../components/FormGroup/FormGroup';
import ReactModal from 'react-modal';
import { GraphqlService } from '../../services/graphql.service';
import gql from 'graphql-tag';
import './ReceivablesPage.scss';
import CreditCardForm from '../../components/CreditCardForm/CreditCardForm';
import { Card, Invoice, Reference } from '../../classes/classes';
import OrderInvoicePdf from './Invoice/OrderInvoicePdf';
import moment from 'moment';
import useLoading from '../../hooks/useLoading/useLoading';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import environments from '../../../environments/environments';
import Toggle from '../../components/Toggle/Toggle';
import MastersPaymentDetail from './MastersPaymentDetail/MastersPaymentDetail';
import SendInvoices from '../../components/SendInvoices/SendInvoices';
import { PaymentInfo, PaymentSelecction } from '../../components/ProcessPayment/classes/classes';
import ProcessPayment from '../../components/ProcessPayment/ProcessPayment';
import IMenuOption from '../../components/Table/models/IMenuOption';
import BatchEdit from '../../components/BatchEdit/BatchEdit';
enum OrderStatuses {
    OPEN,
    AGING,
    PAYMENTS,
}



enum InvoiceStatuses {
    ALL,
    NOT_SENT
}

const ReceivablesPage = () => {


    const [pickerOpen, setPickerOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const [dragOver, setDragOver] = useState(false)
    const toast = useToasts();

    const [invoiceData, setInvoiceData] = useState(null);




    const [searchTermInv, setSearchTermInv] = useState('');

    const [invoiceStatus, setInvoiceStatus] = useState(InvoiceStatuses.ALL);

    const [loadingPayments, setLoadingPayments] = useState(false);

    const [payments, setPayments] = useState([])

    const refInv = useRef();

    const [reference, setReference] = useState({master_id: null, references: []});




    const [payableSearch, setPayableSearch] = useState('');
    const [checkSearch, setCheckSearch] = useState('');







    const carrierSRef = useRef();
    const driverSRef = useRef();
    const employeeSRef = useRef();
    const otherSRef = useRef();
    const payableSRef = useRef();
    const checkRef = useRef();


    const [customerSearch, setCustomerSearch] = useState('');
    const [driverSearch, setDriverSearch] = useState('');
    const [employeeSearch, setEmployeeSearch] = useState('');
    const [otherSearch, setOtherSearch] = useState('');


    const [calendarPickerOpened, setCalendarPickerOpened] = useState(false);
    const [dates, setDates] = useState({from: new Date(), to: new Date()});


    async function searchPayments() {
        loadPayments();

    }





    const getColumns = (status): IColumn[] => {
        switch (status) {
            case OrderStatuses.OPEN:
                return [
                    {
                        label: 'MASTER',
                        active: false,
                        orderBy: 'DESC',
                        name: 'master_column'
                    },
                    {
                        label: 'INVOICE #',
                        active: false,
                        orderBy: 'DESC',
                        name: 'invoice_column',
                        sort_by: 'first_invoice_id'
                    },
                    {
                        label: 'REFERENCES',
                        active: false,
                        orderBy: 'DESC',
                        name: '_ref'
                    },
                    {
                        label: 'CUSTOMER',
                        active: false,
                        orderBy: 'DESC',
                        name: 'customer'
                    },
                    {
                        label: 'CONTACT',
                        active: false,
                        orderBy: 'DESC',
                        name: 'contact'
                    },
                    {
                        label: 'SENT ON',
                        active: false,
                        orderBy: 'DESC',
                        name: 'sent_on'
                    },

                    {
                        label: 'DUE',
                        active: false,
                        orderBy: 'DESC',
                        name: 'due'
                    },

                    {
                        label: 'TERMS',
                        active: false,
                        orderBy: 'DESC',
                        name: 'terms'
                    },
                    {
                        label: 'CURRENT INVOICE PRICE',
                        active: false,
                        orderBy: 'DESC',
                        name: 'last_invoice_price',
                        sort_by: 'last_invoice_amount'
                    },
                    {
                        label: 'BALANCE AMOUNT',
                        active: false,
                        orderBy: 'DESC',
                        name: 'balance_left'
                    },
                    {
                        label: 'STATUS',
                        active: false,
                        orderBy: 'DESC',
                        name: 'status'
                    }];
            case OrderStatuses.AGING:
                return [
                    { label: 'CUSTOMER NAME', active: false, orderBy: 'DESC', name: 'name' },
                    { label: 'CURRENT', active: false, orderBy: 'DESC', name: 'current_$', sort_by: 'current' },
                    { label: '31 - 60', active: false, orderBy: 'DESC', name: 'plus30_$', sort_by: 'plus30' },
                    { label: '61 - 90', active: false, orderBy: 'DESC', name: 'plus60_$', sort_by: 'plus60' },
                    { label: 'OVER 90', active: false, orderBy: 'DESC', name: 'plus90_$', sort_by: 'plus90' },
                    { label: 'TOTAL', active: false, orderBy: 'DESC', name: 'total_$', sort_by: 'total' },
                    { label: 'CREDIT TERMS', active: false, orderBy: 'DESC', name: 'terms' },
                    { label: 'STATUS', active: false, orderBy: 'DESC', name: 'moves' },
                    { label: 'NOTES', active: false, orderBy: 'DESC', name: 'internal_note' },
                ];
            case OrderStatuses.PAYMENTS:
                return [
                    { label: 'PAYMENT ID', active: false, orderBy: 'DESC', name: 'p_id' },
                    { label: 'DATE/TIME', active: false, orderBy: 'DESC', name: 'time' },
                    { label: 'AMOUNT', active: false, orderBy: 'DESC', name: 'amount' },
                    { label: 'CREDIT LEFT', active: false, orderBy: 'DESC', name: 'amount_left' },
                    { label: 'PAID BY', active: false, orderBy: 'DESC', name: 'paid_by' },
                    { label: 'MASTERS', active: false, orderBy: 'DESC', name: '_masters', type: 'html'},
                    { label: 'PAYMENT METHOD', active: false, orderBy: 'DESC', name: 'payment_method' },
                    { label: 'REFERENCE', active: false, orderBy: 'DESC', name: 'reference' },
                    { label: 'NOTE', active: false, orderBy: 'DESC', name: 'note' },
                    { label: 'CREDIT CARD', active: false, orderBy: 'DESC', name: 'cc' },
                    { label: 'BANK ACCOUNT', active: false, orderBy: 'DESC', name: 'bank' }
                ];
        }
    }

    const [searchTerm, setSearchTerm] = useState('');

    const orderReceivableOpen = useSelector<AllStates>(x => x.orderReceivables.open) as IOrderReceivables;
    const orderReceivableClosed = useSelector<AllStates>(x => x.orderReceivables.closed) as IOrderReceivables;
    const orderReceivableAging = useSelector<AllStates>(x => x.agings) as IAgingState;
    const orderReceivablePayments = useSelector<AllStates>(x => x.orderReceivables.payments) as IOrderReceivables;
    const loading = useLoading();
    const [orderStatus, setOrderStatus] = useState(OrderStatuses.OPEN);
    const [mastersSelected, setMastersSelected] = useState([]);
    const [paymentSelected, setPaymentSelected] = useState(null);
    const [editBatchOpen, setEditBatchOpen] = useState(false);



    const [paymentInfo, setPaymentInfo] = useState(new PaymentInfo());





    const dispatch = useDispatch();

    useEffect(() => {
        load();

    }, []);

    const load = () => {
        dispatch(getReceivableAging());
        dispatch(getReceivables());

        loadPayments();
    }

    const [isDates, setIsDates] = useState(false);

    const ordersFiltered = () => {
        return []
    }


    function needInvoice(master) {
        return master.payment_left > 0 && master.payment_left != master.last_invoice_amount;
    }

    const getOpens = () => {
        if (!orderReceivableOpen) return [];

        if(searchTermInv.trim()){
            let search = Helper.Masks.IntNumbers(searchTermInv);
            return orderReceivableOpen
                .data
                .filter((x: any) => {
                    return (x.invoices.filter(y => y.id.toString().startsWith(search)).length > 0)
                    && (invoiceStatus == InvoiceStatuses.ALL || needInvoice(x));
                });
        } else if (searchTerm.trim()){
            let search = searchTerm.trim().toUpperCase();
            return orderReceivableOpen
                .data
                .filter((x: any) => {
                    return (JSON.stringify(x).toUpperCase().includes(search))
                    && (invoiceStatus == InvoiceStatuses.ALL || needInvoice(x));
                });
        } else {
            return orderReceivableOpen.data;
        }
    }

    const getAgings = () => {
        if (!orderReceivableAging) return [];
        if (!orderReceivableAging.data) return [];

        // console.log(orderReceivableAging.data)
        return orderReceivableAging
            .data
            .filter(e => {
                return e.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 || !searchTerm
            });
    }

    const getCount = (key = '') => {
        // return 0;
        try{
            const keys = {
                'OPEN': orderReceivableOpen,
                'AGING': orderReceivableAging,
                'PAYMENTS': {data: payments},
            }
            // console.log(keys[key], 'hello', key, keys);
            return keys[key].data.length;
        }catch (ex){
            console.log('ex', ex.message);
            return 0;
        }
    }
    const statusesOptions = [
        {
            label: 'Open Invoices ',
            key: 'OPEN',
            value: OrderStatuses.OPEN
        },
        {
            label: 'Aging ',
            key: 'AGING',
            value: OrderStatuses.AGING
        },
        {
            label: 'Payments ',
            key: 'PAYMENTS',
            value: OrderStatuses.PAYMENTS
        }
    ]

    const [emails, setEmails] = useState([]);

    const [mastersID, setMasterIDS] = useState<number[]>([]);



    const ref = useRef();

    const paymentsMenu: IMenuOption[] = [
        {
            label: 'Add File',
            icon: ICONS.IconPlus,
            action:(x: any) => {
                const paymentInfo = new PaymentInfo();
                paymentInfo.id = x.id;
                paymentInfo.mastersID = x.masters_id;
                setPaymentInfo(paymentInfo);
            }


        },
        {
            label: 'See Files',
            icon: ICONS.IconDetails,
            condition: x => x.files?.length > 0,
            action: async (x: any) => {

                const query = gql`
            query($uid:String, $is_thumbnail:Boolean){
                get_temp_link(uid:$uid is_thumbnail:$is_thumbnail)
            }
        `;

                const files = x.files as any[];

                for (let file of files)
                {
                    try {
                        const temp = await GraphqlService.SendQuery(query, {uid: file.uid, is_thumbnail: false});
                        const html = `
                        <html><head></head><body><img src="${temp}" /></body></html>
                               

                        `


                        var tab = window.open(file.name, '_blank');
                            tab.document.write(html); // where 'html' is a variable containing your HTML

                    } catch (ex) {

                    }
                }

            }

        }
    ]


    async function loadPayments(_dates = dates, _is = isDates) {
        if (!_is && customerSearch.trim() == '' && driverSearch.trim() == '' && employeeSearch.trim() == '' &&  '' == otherSearch.trim() && payableSearch.trim() == '')
        {
            return setPayments([]);
        }
        let variables = !_is ? {
            "customer_search": customerSearch,
            "ordernumber_search": driverSearch,
            "reference_search": employeeSearch,
            "creditcard_search": otherSearch,
            "orderreference_search": payableSearch
        } : {start_date: moment(_dates.from).hours(0).minutes(0).seconds(0).toDate(), end_date: moment(_dates.to).hours(23).minutes(59).seconds(59).toDate()};


        setLoadingPayments(true);
        const query = !_is ?  gql`
            query(
                $customer_search: String
                $ordernumber_search: String
                $reference_search: String
                $creditcard_search: String
                $orderreference_search: String
            ) {
                payment_search(
                    customer_search: $customer_search
                    ordernumber_search: $ordernumber_search
                    reference_search: $reference_search
                    creditcard_search: $creditcard_search
                    orderreference_search: $orderreference_search
                ) {
                    id
                    note
                    time
                    type
                    reference
                    amount
                    masters {
                        id
                        references {
                            key
                            value
                        }
                    }
                    paid_for {
                        key
                        name
                    }
                    time_paid
                    amount_left
                    files {
                        uid
                        name
                    }
                    credit_card {
                        number
                        type
                    }
                    bank_account {
                        name
                        last_4
                        bank
                    }
                }
            }
        ` : gql`
            query($start_date:String, $end_date:String){
                get_payments_by_date(start_date:$start_date, end_date:$end_date){
                    id
                    note
                    time
                    type
                    amount
                    files {
                        uid
                        name
                    }
                    masters{
                        id
                        references{
                            key
                            value
                        }
                    }
                    paid_for{
                        key
                        name
                    }
                    reference
                    time_paid
                    amount_left
                    credit_card{
                        number
                        type
                    }
                    bank_account{
                        name
                        last_4
                        bank
                    }
                }
            }

        `;

        try {
            const data = await GraphqlService.SendQuery(query, variables);
            // //console.log(data);
            setLoadingPayments(false);
            setPayments(data.map(x => ({...x, p_id: `P-` + x.id, time_paid_value: x.time_paid, time: [Helper.FORMAT.USDate(x.time_paid, 'do'), Helper.FORMAT.USDate(x.time_paid, 'to')],
                amount: Helper.FORMAT.USCurrency(x.amount),
                paid_by: x.paid_for[0]?.name || '-',
                invoices: '-',
                masters_id: x.masters.map(x => x.id),
                _masters: ()=> <div className={'cursor-pointer text-blue-light'} onClick={(ev)=>{ev.stopPropagation();
                    setPaymentSelected(x);
                }}><p className='font-11'>M-{x.masters[0]?.id}</p> {x.masters.length > 1 && <span style={{display: 'block'}} className='mt-1'>+{x.masters.length - 1} more</span>}</div>,
                payment_method: x.type,
                reference: x.reference || '-',
                note: x.note || '-',
                cc: !x.credit_card?.type ? '-' : [`${x.credit_card?.type}`, `${x.credit_card?.number}`],
                bank: !x.bank_account?.name ? '-' : [x.bank_account.name, x.bank_account.last_4 + ' ' + x.bank_account.bank],
                id: x.id,
                amount_left: Helper.FORMAT.USCurrency(x.amount_left),
                files: x.files || []
            })));
        } catch (ex) {
            setLoadingPayments(false);
        }
    }




    const paymentsHeader = () => {
        return <>

            {!isDates && <>
                <div className="row">
                    <Searchbar value={customerSearch}
                               placeholder={'Customer...'}
                               className={'col-4 mb-2'}
                               onChange={(evt) => setCustomerSearch(evt.target.value)}
                               onKeyEnter={(evt) => searchPayments()}
                               background={'#F8F8F8'}

                               reference={carrierSRef}/>

                    <Searchbar value={driverSearch}
                               className={'col-4 mb-2'}
                               placeholder={'Order Number...'}
                               onChange={(evt) => setDriverSearch(evt.target.value)}
                               onKeyEnter={(evt) => searchPayments()}
                               background={'#F8F8F8'}
                               reference={driverSRef}/>

                    <Searchbar value={employeeSearch}
                               className={'col-4 mb-2'}
                               placeholder={'Reference...'}
                               onKeyEnter={(evt) => searchPayments()}
                               onChange={(evt) => setEmployeeSearch(evt.target.value)}
                               background={'#F8F8F8'}
                               reference={employeeSRef}/>

                    <Searchbar value={otherSearch}
                               className={'col-4'}
                               onChange={(evt) => setOtherSearch(evt.target.value)}
                               onKeyEnter={(evt) => searchPayments()}
                               placeholder={'Credit Card...'}
                               background={'#F8F8F8'}
                               reference={otherSRef}/>

                    <Searchbar value={payableSearch}
                               className={'col-4'}
                               onKeyEnter={(evt) => searchPayments()}
                               onChange={(evt) => setPayableSearch(evt.target.value)}
                               placeholder={'Order Reference...'}
                               background={'#F8F8F8'}
                               reference={payableSRef}/>

                    <Searchbar value={searchTerm}
                               placeholder='Search...'
                               className={'col-4'}
                               onChange={(evt) => setSearchTerm(evt.target.value)}
                               background={'#F8F8F8'}
                               reference={ref}/>
                </div>

            </>
            }

            {isDates && <>
                <button onClick={(evt) => setCalendarPickerOpened(true)} className='no-select btn btn-blue-light mr-2'><i className="far fa-calendar mr-1"></i> {Helper.FORMAT.USDate(dates.from, 'do')} {moment(dates.from).format('MM/DD/YYYY') != moment(dates.to).format('MM/DD/YYYY') && <span> - {Helper.FORMAT.USDate(dates.to, 'do')}</span>}</button>

            </>}
        </>
    }

    const getPayments = () => payments.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.trim().toLowerCase()));


    const getHeaderText = () => {

        const label = statusesOptions[orderStatus]?.label;

        const key = statusesOptions[orderStatus]?.key;
        const length = getCount(key);

        const length1 = orderStatus == OrderStatuses.OPEN ? getOpens().length
            :(orderStatus == OrderStatuses.AGING ? getAgings().length
                : getPayments().length);
        return `${length1} / ${length} ${label}` ;
    }


    return (
        <>


            <div className='col-12 flex-1-container'>
                <div className="mb-3 d-flex">
                    {
                        statusesOptions.map((opt, index) =>
                                <h4 key={'key-' + index} onClick={(evt) => setOrderStatus(opt.value)} className={"font-10 transition-3-all-ease cursor-pointer mr-3 " + (orderStatus == opt.value ? 'text-black' : 'text-gray')}>{opt.label} ({getCount(opt.key)})</h4>
                            // <div className={'mr-3'} key={'key-' + index}>
                            //     <p onClick={(evt) => setOrderStatus(opt.value)}
                            //        className={`fs-12 cursor-pointer mb-1 font-medium${opt.value != orderStatus ? ' text-gray' : ''}`}>{opt.label} ({getCount(opt.key)})</p>
                            //     <div style={{
                            //         width: '24px',
                            //         height: '2px',
                            //         background: orderStatus == opt.value ? '#42ACDE' : 'transparent'
                            //     }}/>
                            // </div>
                        )
                    }
                    {/*<h4 onClick={(evt) => setOrderStatus(OrderStatuses.OPEN)} className={"font-12 transition-3-all-ease cursor-pointer " + (tab == Tabs.ORDERS ? 'text-black' : 'text-gray')}>Orders</h4>*/}
                    {/*<h4 onClick={(evt) => setOrderStatus(OrderStatuses.AGING)} className={"font-12 transition-3-all-ease ml-3 cursor-pointer " + (tab == Tabs.QUOTES ? 'text-black' : 'text-gray')}>Quotes</h4>*/}
                </div>
                <div className="flex-1-container bg-white rounded p-3">
                    <div className='row col-12 aling-items-start mb-3'>
                        <div className="col-6 align-items-start">
                            <h4 className="fs-12">{getHeaderText()}</h4>
                            {orderStatus == OrderStatuses.OPEN && <button onClick={(evt) => setEditBatchOpen(true)} className="btn btn-blue-light">EDIT REFERENCES</button>}

                        </div>
                        
                        <div className="d-flex col-6 align-items-center justify-content-end">
                            {(orderStatus != OrderStatuses.PAYMENTS || (orderStatus == OrderStatuses.PAYMENTS) && isDates) && <button className='btn btn-icon-only mr-2'
                                                                                                                                      onClick={() => load()}><img
                                className={(orderReceivableOpen.loading || orderReceivableAging.loading || orderReceivableClosed.loading || orderReceivablePayments.loading || loadingPayments) ? 'spin' : ''}
                                style={{transform: ''}} src={ICONS.IconRefresh}
                                alt=""/></button>}
                            {orderStatus == OrderStatuses.OPEN && <div className='mr-2'>
                                <Searchbar value={searchTermInv}
                                           placeholder={'Invoice #...'}
                                           onChange={(evt) => setSearchTermInv(Helper.Masks.IntNumbers(evt.target.value))}
                                           background={'#F8F8F8'}
                                           reference={refInv}
                                           onFocus={(x) => setSearchTerm('')}/>
                            </div>}

                            {
                                orderStatus == OrderStatuses.PAYMENTS && paymentsHeader()

                            }

                            { (orderStatus != OrderStatuses.PAYMENTS || (orderStatus == OrderStatuses.PAYMENTS && isDates)) &&
                            <Searchbar value={searchTerm}
                                       placeholder='Search All Fields...'
                                       onChange={(evt) => setSearchTerm(evt.target.value)}
                                       background={'#F8F8F8'}
                                       reference={ref}
                                       onFocus={(x) => setSearchTermInv('')}/>
                            }


                            {orderStatus == OrderStatuses.PAYMENTS && <div className={'ml-2 h-100'}>
                                <Toggle id={'toggle'} checked={isDates} label={'DATE'} onCheck={(evt) => {
                                    setIsDates(evt);
                                    loadPayments(dates, evt);
                                }}/>

                                {(orderStatus == OrderStatuses.PAYMENTS && !isDates) && <button className='btn btn-icon-only icon-big mt-3'
                                                                                                onClick={() => load()}><img
                                    className={(orderReceivableOpen.loading || orderReceivableAging.loading || orderReceivableClosed.loading || orderReceivablePayments.loading || loadingPayments) ? 'spin' : ''}
                                    style={{transform: ''}} src={ICONS.IconRefresh}
                                    alt=""/></button>}
                            </div>}






                        </div>


                        {orderStatus == OrderStatuses.OPEN && <div className="col-12 d-flex justify-content-end mt-2">

                            <button
                                className={`btn btn-blue-light${invoiceStatus != InvoiceStatuses.ALL ? '-outline' : ''}`}
                                onClick={(evt) => setInvoiceStatus(InvoiceStatuses.ALL)}>ALL
                            </button>
                            <button
                                className={`ml-2 btn btn-blue-light${invoiceStatus != InvoiceStatuses.NOT_SENT ? '-outline' : ''}`}
                                onClick={(evt) => setInvoiceStatus(InvoiceStatuses.NOT_SENT)}>NOT SENT
                            </button>

                        </div>}

                    </div>

                    <div className={'col-12 flex-1-container'}>
                        {
                            orderStatus == OrderStatuses.OPEN &&
                            <Table loading={orderReceivableOpen.loading} tableHeight={'81vh'}
                                   showCheckbox={true}
                                   rowHeight={60}
                                   menuOptions={[
                                       {
                                           icon: ICONS.IconDetails,
                                           label: 'See order details',
                                           condition: x => true,
                                           action: (x: any) => {
                                               console.log(x);
                                               let master_id = x.master_id;//.substring(x.master_id.indexOf('-') + 1, x.master_id.length);
                                               if (master_id) {
                                                   Helper.Navigation.NavigateTo('/order/' + master_id);
                                               }
                                           }
                                       },
                                       {
                                           icon: ICONS.IconSeeInvoice,
                                           label: 'Send Invoice',
                                           condition: x => true,
                                           multipleRows: true,
                                           action: (x: any | any[]) => {
                                               // list of checked orders
                                               let emails = [];
                                               setEmails([]);
                                               if (Array.isArray(x))
                                               {
                                                   setMasterIDS(x.map(y => y.row.master_id));
                                                   emails = (x.map(y => y.row.default_emails).flatMap(y => y))
                                               }
                                               else
                                                {
                                                    setMasterIDS([x.master_id])
                                                    emails = (x.default_emails.flatMap(y => y))
                                                }

                                                setEmails(emails.filter((email, index) => emails.findIndex(z => z == email) == index))


                                           }
                                       },
                                       {
                                           icon: ICONS.IconDetails,
                                           label: 'Edit references',
                                           condition: x => true,
                                           action: (x: any) => {
                                               let master_id = x.master_id.substring(x.master_id.indexOf('-') + 1, x.master_id.length);
                                               setReference({master_id, references: x.references});
                                           }
                                       },
                                       {
                                           icon: ICONS.IconProcessPayment,
                                           label: 'Pay Master Order(s)',
                                           condition: (x: any | any[]) => {
                                               if (Array.isArray(x))
                                               {

                                                   return (x as any[]).filter((y, index, array) => array.findIndex(z => z.row.customer_id == y.row.customer_id)  == index).length == 1;
                                               }
                                               return true;
                                           },
                                           multipleRows: true,
                                           action: (x: any | any[]) => {
                                               // list of checked orders
                                               setEmails([]);
                                               let ids = [];
                                               let totalLeft = 0;
                                               let customer_id = null;
                                               if (Array.isArray(x))
                                               {
                                                   //    setMasterIDS();
                                                   ids = x.map(y => y.row.master_id);
                                                   totalLeft = x.sumBy(y => +y.row.payment_left);
                                                   customer_id = x[0]?.row?.customer_id;
                                               }
                                               else
                                               {
                                                   // setMasterIDS()
                                                   ids = [x.master_id];
                                                   totalLeft = +x.payment_left;
                                                   customer_id = x.customer_id;
                                               }



                                               const paymentInfo = new PaymentInfo({...new PaymentInfo(), ...{mastersID: ids, payment_left: totalLeft.toString(), amount_to_pay: '', type: '', id: null, notes: '', customer_id, paymentPickerOpened: ids.length == 1}});
                                               paymentInfo.type = 'CREDIT CARD';




                                               setPaymentInfo(paymentInfo);

                                               // paymentInfo.bank_account_id = environments.DEFAULT_CC_BANK_ACCOUNT_ID;


                                               // setPaymentInfo(paymentInfo);



                                           }
                                       }
                                   ]}
                                   onRowClicked={(event) => {
                                   }} 
                                   columns={getColumns(OrderStatuses.OPEN)} 
                                   rows={getOpens()}/>
                        }
                        {
                            orderStatus == OrderStatuses.AGING &&
                            <Table loading={orderReceivableAging.loading}
                                   showCheckbox={true}
                                   rowHeight={60}
                                   menuOptions={[
                                       {
                                           label: 'See customer details',
                                           icon: ICONS.IconSearch,
                                           condition: () => true,
                                           action: (row: any) => {
                                               Helper.Navigation.NavigateTo('/customer/' + row.id + '/profile');
                                           }
                                       }
                                   ]}
                                   onRowClicked={(event) => {
                                   }} columns={getColumns(OrderStatuses.AGING)} rows={getAgings()}/>
                        }
                        {
                            orderStatus == OrderStatuses.PAYMENTS &&
                            <Table loading={loadingPayments}
                                   rowHeight={50}
                                   menuOptions={paymentsMenu}
                                   onRowClicked={(event) => {
                                       console.log(event.masters);
                                       setPaymentSelected(event)
                                   }} columns={getColumns(OrderStatuses.PAYMENTS)} rows={getPayments()}/>
                        }
                    </div>
                </div>
            </div>

            <ReactModal isOpen={mastersID.length > 0}>
                        <SendInvoices defaultEmails={emails} mastersID={mastersID} onClose={(reload) => {
                            setMasterIDS([]);
                            if (reload)
                                load();
                        }} />
            </ReactModal>





            <ReactModal isOpen={reference.master_id != null}>
                <div className='row align-items-center mb-3'>
                    <h4 className='font-16'>Master References</h4>
                    <img src={ICONS.IconCloseBig} onClick={(evt) => setReference({master_id: null, references: []})} alt="" />
                </div>
                <div className="col-12 row mb-1">
                    <h4 className='font-14 col-4'>Key</h4>
                    <h4 className='font-14 col-4'>Value</h4>
                    <h4 onClick={(evt) => {
                        setReference({...reference, references:  [...reference.references, new Reference()]});
                    }} className='font-12 cursor-pointer text-light-blue d-flex align-items-center' style={{fontWeight: 400}}>ADD NEW <img src={ICONS.IconPlus} alt="" /></h4>
                </div>
                <div style={{ flex: 1, overflow: 'auto'}}>

                    {reference.references.map((x, i) => <div className='row align-items-start'>
                        <FormGroup placeholder={'Key - ' + (i + 1)} type='combobox' options={{data: ['PO', 'RO', 'STOCK'], label: x=>x, value: x=>x}} colSize={4} name={'r-key-' + i} value={x.key} onTextChange={(evt) => {
                            const temp = [...reference.references];
                            temp[i].key = evt;
                            setReference({...reference, references: temp});
                        }} />
                        <FormGroup placeholder={'Value - ' + (i + 1)} colSize={4} name={'r-value-' + i}  value={x.value} onTextChange={(evt) => {
                            const temp = [...reference.references];
                            temp[i].value = evt;
                            setReference({...reference, references: temp});
                        }} />
                        <button onClick={(evt) => {
                            const temp = [...reference.references];
                            temp.splice(i, 1);
                            setReference({...reference, references: temp});
                        }} className='btn btn-danger py-2'>REMOVE</button>
                    </div>)}
                </div>
                <div className='row align-items-center mt-3'>
                    <button onClick={(evt) => setReference({master_id: null, references: []})} className="btn btn-danger col-6 py-2">CANCEL</button>
                    <button onClick={async (evt) => {
                        const mutation = gql`
                        mutation($order_id: Int, $references: [reference_input]){
                            update_master_order_references(order_id:$order_id, references:$references){
                                id
                                message
                                success
                            }
                            }
                    `;

                        try {
                            loading.open('Submitting...');
                            const data = await GraphqlService.SendMutation(mutation, {order_id: +reference.master_id, references: reference.references});
                            if (!data?.success)
                                throw new Error(data?.message || 'Something went wrong');

                            loading.close();
                            load();
                            setReference({references: [], master_id: null})
                            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
                        } catch (ex) {
                            loading.close();
                            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
                        }
                    }} disabled={reference.references.length != 0 && reference.references.some(x => x.key.trim() == '' || x.value.trim() == '')} className="btn btn-blue-light col-6 py-2">UPDATE</button>
                </div>
            </ReactModal>

            {calendarPickerOpened && <DateTimePicker hideTime date={[dates.from, dates.to]}  modal onCancelClicked={(evt) => setCalendarPickerOpened(false)} onDateSubmitted={(evt) => {
                setDates({from: evt[0], to: evt[1]});
                setCalendarPickerOpened(false);

                loadPayments({from: evt[0], to: evt[1]});
            }} />}


            <ReactModal onRequestClose={(evt) => setPaymentSelected(null)} isOpen={paymentSelected != null}>
                <MastersPaymentDetail paymentSelected={paymentSelected} onClose={(evt) => {
                    setPaymentSelected(null);
                }}  onMasterDetailClicked={id => {setPaymentSelected(null); Helper.Navigation.NavigateTo(`/order/${id}`); }} />
            </ReactModal>


            <ReactModal style={{content: {padding: 0}}} className='modal-large' isOpen={paymentInfo.mastersID.length > 0}>
                <ProcessPayment data={paymentInfo} onClose={(evt) => {
                        setPaymentInfo(new PaymentInfo())
                        loadPayments();
                }} onPaymentSubmitted={(evt) => load()} />
            </ReactModal>
            
            <ReactModal className={'modal-large'} isOpen={editBatchOpen}>
                <BatchEdit onClose={(evt) => setEditBatchOpen(false)} onSubmitted={() => {
                    load();
                    setEditBatchOpen(false);
                }} data={orderReceivableOpen.loading ? [] : getOpens()} />
            </ReactModal>
        </>
    )
}

export default ReceivablesPage;


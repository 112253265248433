import AgingTypes from "../types/aging.types";

export class ReceivableAging {
    id: number;
    name: string;
    current: any;
    plus30: any;
    plus60: any;
    plus90: any;
    total: number;
    not_picked_up: any;
    not_delivered: any;
    non_move: any;
    internal_note;
}

export interface IAgingState {
    loading: boolean;
    data: ReceivableAging[];
}

const initialState: IAgingState = {
    loading: true,
    data: [],
}

const AgingReducer = (state = initialState, action) => {
    switch (action.type) {
        case AgingTypes.FETCH_RECEIVABLE_AGING_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AgingTypes.FETCH_RECEIVABLE_AGING_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case AgingTypes.FETCH_RECEIVABLE_AGING_FAILURE:
            return {
                ...state,
                receivableAging: [],
                loading: false,
            };
        default:
            return state;
    }
}

export default AgingReducer;
import moment from 'moment';
import React, { ComponentType, FC, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import environments from '../../../../environments/environments';
//import { Carrier, Driver, Stop } from '../../../classes/classes';
import Helper from '../../../helper/Helper';
import useMessageBox from '../../../hooks/useMessageBox/useMessageBox';
import Status from '../../Status/Status';
import './Trip.scss';

import {ControlledMenu, MenuItem, useMenuState} from '@szhsin/react-menu';
import ICONS from '../../../../assets/svg';
import { GraphqlService } from '../../../services/graphql.service';
import { gql } from 'apollo-boost';
import useLoading from '../../../hooks/useLoading/useLoading';
import { useToasts } from 'react-toast-notifications';
import ReactModal from 'react-modal';
import IMenuOption from '../../Table/models/IMenuOption';
import InspectionQueries from '../../../graphql/query/Inspection';
import { Leg } from '../../../services/endpoints/schemas/leg/leg';
import { Stop } from '../../../services/endpoints/schemas/stop/stop';
import { Carrier } from '../../../services/endpoints/schemas/carrier/carrier';
import { Driver } from '../../../services/endpoints/schemas/driver/driver';


type ITrip = {
    data: Leg,
    last: boolean,
    current?: boolean,
    showLabels?: boolean,
    onSelectDriverClicked?: (data: any) => any,
    onUndispatchLeg?: (legID: number) => any,
    onChangeScheduleClicked?: (leg_id: number, stop_id: number, is_pickup: boolean) => any,
    status?: string,
    changeRequestLink?: string,
    rightClickOptions?: IMenuOption[],
    hideButton?: boolean
}

const Trip: ComponentType<ITrip> = ({data, rightClickOptions, hideButton, last, current, onSelectDriverClicked, onUndispatchLeg, onChangeScheduleClicked, showLabels, status, changeRequestLink}) => {

    const [stopSelected, setStopSelected] = useState(new Stop());
    const messageBox = useMessageBox();
    const ref = useRef<HTMLDivElement>();
    const toast = useToasts();
    const [carrierShown, setCarrierShown] = useState(false);

    const {openMenu, closeMenu, ...menuProps} = useMenuState();
    const [anchorPoint, setAnchorPoint] = useState({x: 0, y: 0});

    const loading = useLoading();

    async function getLink() {
        const mutation = gql`
            mutation($carrier_id: Int, $link: String){
                create_velox_redirect_link(carrier_id: $carrier_id, link: $link)
            }
        `;
        try {
            loading.open('Getting Link...');
            const r = await GraphqlService.SendMutation(mutation, {carrier_id: data?.carrier?.id, link: changeRequestLink || (environments.VELOX_LINK + '/order-requests' )})
            loading.close();

        Object.assign(document.createElement('a'), {
            target: '_blank',
            href: r,
        }).click();
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
                    

    }

    useEffect(() => {
        setTimeout(() => {
            if (current)
                ref?.current?.scrollIntoView({behavior: 'smooth'});
        }, 1);
    }, [current]);

    async function getInspectionLink(stop_id = stopSelected?.id)
    {

        loading.open('Getting Inspection...');
        try {

            const link = await GraphqlService.SendQuery(InspectionQueries.GET_LINK, {stop_id});
            
            loading.close();
            Object.assign(document.createElement('a'), {
                target: '_blank',
                href: link,
            }).click();
        }
        catch (ex)
        {
            loading.close();
            console.log(ex);
        }

    }


    function handleClickOut(evt) {
        setCarrierShown(false);
    }

    useEffect(() => {
        window.addEventListener('click', handleClickOut);
        return () => window.removeEventListener('click', handleClickOut);
    }, []);

    const dispatchBtnText = () => {
        if (!data?.carrier?.id)
            return <>Select Carrier / Driver</>;
        if (data?.driver?.id)
            return <>{data?.carrier?.name} ({data?.carrier?.id})<br />{data?.driver?.name}</>
        return <>{data?.carrier?.name} ({data?.carrier?.id})</>;
    }

    return <><div ref={ref}
    onClick={evt => {
        onSelectDriverClicked?.(data);
    }}
    onContextMenu={(e) => {

        setStopSelected(new Stop());
        e.preventDefault();
        if (status != 'PENDING' && !data?.carrier?.id && rightClickOptions?.length == 0)
            return;
        setAnchorPoint({ x: e.clientX, y: e.clientY });
        openMenu();
    }} className={'position-relative cursor-pointer trip-component ' + (last ? 'last' : '') +  (showLabels ? ' readonly' : '') + (data?.driver?.id ? ' with-driver' : '') }>
        {/* {current && JSON.stringify(data.velox_order)} */}
        <div className="d-inline-flex tip">
            <div className={'trip-column-left cursor-pointer' + (data?.getPickup().nofail ? ' no-fail-dot' : '')} onContextMenu={(e) => {
                setStopSelected(new Stop(data?.getPickup()));
                e.preventDefault();
                e.stopPropagation();
                if (status != 'PENDING' && !data?.carrier?.id && rightClickOptions?.length == 0)
                    return;
                setAnchorPoint({ x: e.clientX, y: e.clientY });
                openMenu();
            }}>
                <div className='mb-2 cursor-pointer' onClick={(evt) => {
                    onChangeScheduleClicked?.(data.id, data.getPickup().id, false);
                    if (onChangeScheduleClicked)
                        evt.stopPropagation();
                    }}>
                    <div className='row'>
                        {showLabels && <h4 className='font-11 mb-2 col-12 text-gray'>{'Origin'}</h4>}
                        <h4 className={'font-10 ' + (data.getPickup().completed ? ' text-blue-light' : '')}>{moment(data.getPickup().time_scheduled).format('M/DD')}</h4>
                        {data.getPickup().nofail && <Status statusName='NO FAIL' />}
                    </div>
                    <h4 className={'font-10 ' + (data.getPickup().completed ? ' text-blue-light' : '')}>{moment(data.getPickup().time_scheduled).format('h:mm a')}</h4>
                    {data.getPickup().completed && <button onClick={(evt) => {
                        getInspectionLink(data.getPickup().id);
                        evt.stopPropagation();
                        }} className="btn btn-blue-light mt-2">SEE INSPECTION</button>}
                </div>
                <div>
                    <h4 className={'font-8 ' + (data.getPickup().completed ? ' text-blue-light' : '')}>{data.getPickup().location?.title}</h4>
                    <h4 className={'font-8 font-normal ' + (data.getPickup().completed ? ' text-blue-light' : '')}>{data.getPickup().location?.address.getName(2)}</h4>
                    
                </div>

                {!showLabels && <div className={'trip-bar' + (current ? ' current' : '')}></div>}

                <div className='driver-section'>
                    <div className="d-flex flex-column justify-content-center cursor-pointer" 
                    onClick={(evt) => {
                        // if (!onUndispatchLeg) return;
                        // if (data?.carrier?.id != null && data?.carrier?.id != environments.JP_CARRIER_ID)
                        // {
                        //     return Swal.fire({
                        //         text: 'Are you sure you want to undispatch this leg? This action cannot be reverted.',
                        //         icon: 'question',
                        //         title: 'Undispatch Leg',
                        //         confirmButtonText: 'Confirm',
                        //         denyButtonText: `No, don't do it`,
                        //         showDenyButton: true
                        //     }).then(r => {
                        //         if (r.isConfirmed) {
                        //         onUndispatchLeg(data?.id);
                        //         }
                        //     })
                        
                        // }
                        evt.stopPropagation();
                        if (!onSelectDriverClicked) return;
                        onSelectDriverClicked({
                            id: data.id,
                            price: '',
                            driver_pay: '',
                            carrier: new Carrier(data.carrier),
                            driver: new Driver(data.driver),
                            hours: '1',
                            change: false,
                            undispatch: data?.carrier?.id != null && data?.carrier.id != environments.JP_CARRIER_ID,
                            leg: new Leg(data)
                        })
                        }} >
                            {!showLabels && <>
                            
                            <h4 className='font-9 font-normal text-center'>Leg-{data.id} ● {Helper.FORMAT.USCurrency(data.equipment_price)}</h4>
                            
                            <div className='d-flex align-items-center'>
                                {!hideButton && <button className={`line-height-0-6 btn btn-${data?.driver?.id ? 'blue-light' : (data?.carrier?.id ? 'orange' : 'blue-light-outline')} mt-2`}>{dispatchBtnText()}</button>}
                                {status && <Status className={'mr-1 mt-2'} statusName={status} />}
                            </div>
                            
                            <h4 className={"font-9 text-center font-normal " + (hideButton ? 'mb-3' : '')}>{data?.carrier.id ? data?.carrier_pay?.toCurrency():'-'}</h4>
                        </>}


                    </div>
                </div>
            </div>
            <div  className={'trip-column-right cursor-pointer' + (data?.getDropoff().nofail ? ' no-fail-dot' : '')} onContextMenu={(e) => {
                setStopSelected(new Stop(data?.getDropoff()));
                e.preventDefault();
                e.stopPropagation();
                if (status != 'PENDING' && !data?.carrier?.id && rightClickOptions?.length == 0)
                    return;
                setAnchorPoint({ x: e.clientX, y: e.clientY });
                openMenu();
            }}>
                
                <div className='mb-2 cursor-pointer' onClick={(evt) => {
                    onChangeScheduleClicked && onChangeScheduleClicked(data.id, data.getPickup().id, false);
                    if (onChangeScheduleClicked)
                        evt.stopPropagation();
                    }}>
                <div className='row'>
                        {showLabels && <h4 className='font-11 mb-2 col-12 text-gray'>{'Destination'}</h4>}
                        <h4 className={'font-10 ' + (data.getDropoff().completed ? ' text-blue-light' : '')}>{moment(data.getDropoff().time_scheduled).format('M/DD')}</h4>
                        {data.getDropoff().nofail && <Status statusName='NO FAIL' />}
                    </div>

                    <h4 className={'font-10 ' + (data.getDropoff().completed ? ' text-blue-light' : '')}>{moment(data.getDropoff().time_scheduled).format('h:mm a')}</h4>
                    {data.getDropoff().completed && <button onClick={(evt) => {
                        getInspectionLink(data.getDropoff().id);
                        evt.stopPropagation();
                        }} className="btn btn-blue-light mt-2">SEE INSPECTION</button>}
                    
                </div>
                <div>
                    <h4 className={'font-8 ' + (data.getDropoff().completed ? ' text-blue-light' : '')}>{data.getDropoff().location?.title}</h4>
                    <h4 className={'font-8 font-normal ' + (data.getDropoff().completed ? ' text-blue-light' : '')}>{data.getDropoff().location?.address.getName(2)}</h4>
                </div>
                <div className='trip-bar'></div>
            </div>
        </div>



        
        {carrierShown && <div className='d-flex bg-white p-2 rounded' style={{position: 'absolute', top:'50%', left: '50%', transform: 'translate(-50%, -50%)', boxShadow: '0 3px 6px #00000029', width: '200px', height: '160px'}}>
            <div className="flex-1-container">
            <div className="row mb-2">
                <h4 className="font-10">Carrier Details</h4>
                <h4 onClick={(evt) => {
                    setCarrierShown(false);
                    evt.stopPropagation();
                }} className="font-10 cursor-pointer"><i className='fas fa-times'></i></h4>
            </div>
            
            <div className="flex-1-container">
                <p className='font-8'>
                {JSON.stringify(data?.driver?.id ? data.driver : data.carrier, null, 2)}
                </p>
            </div>
            </div>
        </div>}
    </div>
    
    <ControlledMenu  {...menuProps} anchorPoint={anchorPoint}
                onClose={() => closeMenu()}>
                    
                {status == 'PENDING' && <MenuItem onClick={(evt) => {
                    
                    getLink()
                }} className='my-menuitem' style={{display: 'flex', alignItems: 'center'}}><img src={ICONS.IconDetails} alt="" /> Accept Change</MenuItem>}

                {(stopSelected.id && stopSelected.completed) && <MenuItem onClick={(evt) => {

                    getInspectionLink();
                }} className='my-menuitem' style={{display: 'flex', alignItems: 'center'}}><img src={ICONS.IconDetails} alt="" /> See Inspection</MenuItem>}

                {data?.carrier?.id && <MenuItem onClick={(evt) => {
                    setTimeout(() => {
                        setCarrierShown(true);   
                    });
                
                }} className='my-menuitem' style={{display: 'flex', alignItems: 'center'}}><img src={ICONS.IconDetails} alt="" /> See Carrier Details</MenuItem>}

                {rightClickOptions?.map((item, idx) => <MenuItem onClick={(evt) => item.action(data)} className='my-menuitem' style={{display: 'flex', alignItems: 'center'}}><img src={item.icon} alt="" /> {item.label}</MenuItem>)}
                
            </ControlledMenu>
    </>
}

export default Trip;
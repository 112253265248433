import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {Velox_Order} from "../../leg/leg";
import {Aging_Carrier_Driver_Payable, Carriers_For_Broker_Payment, Invoice} from "../invoice";

export const download_new_invoice = (input:{master_order_id: number, profile_id: number, office: boolean, due_time: string}) : Promise<string> => {
  let query = gql`
    query($master_order_id: Int, $profile_id: Int, $office: Boolean, $due_time: String) {
      download_new_invoice(master_order_id: $master_order_id, profile_id: $profile_id, office: $office, due_time: $due_time)
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {master_order_id, profile_id, office, due_time} as 
  Promise<string>;
}

export const download_existing_invoice = (input:{invoice_id: number}) : Promise<string> => {
  let query = gql`
    query($invoice_id: Int) {
      download_existing_invoice(invoice_id: $invoice_id)
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {invoice_id} as 
  Promise<string>;
}

export const get_broker_orders = (input:{carrier_id: number}) : Promise<Velox_Order[]> => {
  let query = gql`
    query($carrier_id: Int) {
      get_broker_orders(carrier_id: $carrier_id) {
        id
        price
        carrier_pay
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        broker {
          id
          name
        }
        driver {
          id
          name
          username
          equipment
          carrier_code
          photos {
            uid
            name
          }
          contacts {
            type
            title
            value
          }
        }
        origin {
          nofail
          address
          scheduled
          time_completed
          completed
          contacts {
            type
            title
            value
          }
        }
        destination {
          nofail
          address
          scheduled
          time_completed
          completed
          contacts {
            type
            title
            value
          }
        }
        completed
        driver_pay
        accepted
        rejected
        canceled
        expired
        expires
        carrier {
          id
          name
          code
          internal_note
          active
          multi_carrier
          contact
        }
        time
        remaining_carrier_receivable
        remaining_driver_payable
        velox_order_id
        pending_change_request
        change_requests {
          id
          order_id
          accepted
          rejected
          time
          payout
          note
          active
          expired
          expires_at
          weblink
          code
        }
        broker_note
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {carrier_id} as 
  Promise<Velox_Order[]>;
}

export const get_aging_carriers_for_broker_payment = (input:{showempty: boolean}) : Promise<Carriers_For_Broker_Payment[]> => {
  let query = gql`
    query($showempty: Boolean) {
      get_aging_carriers_for_broker_payment(showempty: $showempty) {
        id
        price
        carrier_pay
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        broker {
          id
          name
        }
        driver {
          id
          name
          username
          equipment
          carrier_code
          photos {
            uid
            name
          }
          contacts {
            type
            title
            value
          }
        }
        origin {
          nofail
          address
          scheduled
          time_completed
          completed
          contacts {
            type
            title
            value
          }
        }
        destination {
          nofail
          address
          scheduled
          time_completed
          completed
          contacts {
            type
            title
            value
          }
        }
        completed
        driver_pay
        accepted
        rejected
        canceled
        expired
        expires
        carrier {
          id
          name
          code
          internal_note
          active
          multi_carrier
          contact
        }
        time
        remaining_carrier_receivable
        remaining_driver_payable
        velox_order_id
        pending_change_request
        change_requests {
          id
          order_id
          accepted
          rejected
          time
          payout
          note
          active
          expired
          expires_at
          weblink
          code
        }
        broker_note
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {showempty} as 
  Promise<Carriers_For_Broker_Payment[]>;
}

export const get_carrier_driver_orders = (input:{driver_id: number, showclosed: boolean}) : Promise<Velox_Order[]> => {
  let query = gql`
    query($driver_id: Int!, $showclosed: Boolean) {
      get_carrier_driver_orders(driver_id: $driver_id, showclosed: $showclosed) {
        id
        price
        carrier_pay
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        broker {
          id
          name
        }
        driver {
          id
          name
          username
          equipment
          carrier_code
          photos {
            uid
            name
          }
          contacts {
            type
            title
            value
          }
        }
        origin {
          nofail
          address
          scheduled
          time_completed
          completed
          contacts {
            type
            title
            value
          }
        }
        destination {
          nofail
          address
          scheduled
          time_completed
          completed
          contacts {
            type
            title
            value
          }
        }
        completed
        driver_pay
        accepted
        rejected
        canceled
        expired
        expires
        carrier {
          id
          name
          code
          internal_note
          active
          multi_carrier
          contact
        }
        time
        remaining_carrier_receivable
        remaining_driver_payable
        velox_order_id
        pending_change_request
        change_requests {
          id
          order_id
          accepted
          rejected
          time
          payout
          note
          active
          expired
          expires_at
          weblink
          code
        }
        broker_note
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {driver_id, showclosed} as 
  Promise<Velox_Order[]>;
}

export const get_aging_drivers_for_carrier_payables = (input:{carrier_id: number, showempty: boolean}) : Promise<Aging_Carrier_Driver_Payable> => {
  let query = gql`
    query($carrier_id: Int, $showempty: Boolean) {
      get_aging_drivers_for_carrier_payables(carrier_id: $carrier_id, showempty: $showempty) {
        id
        name
        total
        valid
        last_payable {
          id
          time
          type
          reference
          note
          amount
          category
          time_paid
        }
        delivered
        driver_id
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {carrier_id, showempty} as 
  Promise<Aging_Carrier_Driver_Payable>;
}

export const check_invoice_issued = (input:{master_order_id: number}) : Promise<boolean> => {
  let query = gql`
    query($master_order_id: Int) {
      check_invoice_issued(master_order_id: $master_order_id)
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {master_order_id} as 
  Promise<boolean>;
}

export const get_invoices_for_master = (input:{master_order_id: number}) : Promise<Invoice[]> => {
  let query = gql`
    query($master_order_id: Int) {
      get_invoices_for_master(master_order_id: $master_order_id) {
        id
        master_order_id
        time_due
        time
        amount
        logs{
        time
        message
        user_id
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {master_order_id} as 
  Promise<Invoice[]>;
}
import React, {useState, useEffect} from 'react'
import Helper from '../../helper/Helper';
import FormGroup from '../FormGroup/FormGroup';
import ICONS from '../../../assets/svg';
import {CarrierInfo} from "../../pages/CarriersProfilePage/CarriersProfilePage";
import {GraphqlService} from "../../services/graphql.service";
import CarrierMutation from "../../graphql/mutation/Carrier";
import {useToasts} from "react-toast-notifications";
import useLoading from "../../hooks/useLoading/useLoading";

export class velox_carrier_update_input{
    id: number
    name: string
    multi_carrier: Boolean
    code: string
    active: Boolean
    contact: carrier_contact
    address: Address

    constructor(props?: any) {
        if (props) {
            this.id = props.id;
            this.name = props.name;
            this.code = props.code;
            this.contact = new carrier_contact(props?.contact);
            this.address = new Address(props?.address);
        }
    }
  }


  export class carrier_contact{
    name: string
    email: string
    phone: string
    
    constructor(props?: carrier_contact) {
        if (props) {
            this.name = props.name;
            this.email = props.email;
            this.phone = props.phone;
        }
    }
  }


export class Address{
    city: string
    state: string
    street: string
    country: string = 'United States';
    postal_code: string
    street_number: string
    postal_code_suffix: string

    constructor(props?: Address) {
        if (props) {
            this.city = props.city;
            this.state = props.state;
            this.street = props.street;
            this.street_number = props.street_number;
            this.country = props.country;
            this.postal_code = props.postal_code;
            this.postal_code_suffix = props.postal_code_suffix;
        }
    }
} 


const CompanyAccountInfoForm = ({onSubmit, onCancel, data}) => {
    const toast = useToasts();
    const loading = useLoading();
    const states = Helper.States;

    const [carrier, setCarrier] = useState<velox_carrier_update_input>(new velox_carrier_update_input());

    useEffect(() => {
        setCarrier(new velox_carrier_update_input(data));
    }, [data]);

    function onCarrierUpdate(key, value) {
        const x = new velox_carrier_update_input(carrier);
        x[key] = value;
        setCarrier(x);
    }

    function onContactUpdate(key, value) {
        const x = new velox_carrier_update_input(carrier);
        x.contact[key] = value;
        setCarrier(x);
    }

    function onAddressUpdate(key, value) {
        const x = new velox_carrier_update_input(carrier);
        x.address[key] = value;
        setCarrier(x);
    }

    const handleUpdate = async () => {
        try {
            let data = {
                "id": carrier.id,
                "name": carrier.name,
                "multi_carrier": carrier.multi_carrier,
                "code": carrier.code,
                "contact": {
                    "name": carrier.contact.name,
                    "email": carrier.contact.email,
                    "phone": carrier.contact.phone,
                },
                address: carrier.address
            };

            loading.open('saving...');
            const result = await GraphqlService.SendMutation(CarrierMutation.CARRIER_INFO, { carrier: data })
            if (!result?.success)
                throw new Error(result?.message || 'Something went wrong');

                
            loading.close();
            onSubmit();

            toast.addToast(result.message, {appearance: 'success', autoDismiss: true});
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }


    return (
        <>
            <div className="flex-1-container">

                <div className="col-12 mb-3 row">
                    <span className="fs-14 font-medium mb-0">Edit company & account holder info</span>
                    <span onClick={onCancel} className="fs-14 font-medium mb-0"><i className='fas fa-times'></i></span>
                </div>


                <div className="flex-1-container">
                    <div className={`row `}>

                        <div className="col-12 mb-3 d-flex row align-items-center">
                            <span className="fs-14 font-medium mb-0">Company info</span>
                            
                        </div>

                        <FormGroup name={'Companyname'}
                                   value={carrier?.name}
                                   required={true}
                                   label={'Company name'}
                                   placeholder={"ABC Logistics"}
                                   onTextChange={text => onCarrierUpdate('name', text)}
                        />


                        <FormGroup name={'Streetnumber'}
                                   value={carrier?.address?.street_number}
                                   required={true}
                                   colSize={4}
                                   label={'Street Number'}
                                   placeholder={"Street Number"}
                                   onTextChange={text => onAddressUpdate('street_number', text)}
                        />

                        <FormGroup name={'Street'}
                                   value={carrier?.address?.street}
                                   required={true}
                                   colSize={8}
                                   label={'Street'}
                                   placeholder={"Street"}
                                   onTextChange={text => onAddressUpdate('street', text)}
                        />


                        <FormGroup name={'city'}
                                   value={carrier?.address?.city}
                                   required={true}
                                   label={'City'}
                                   placeholder={"City"}
                                   onTextChange={text => onAddressUpdate('city', text)}
                        />


                        <FormGroup name={'state'}
                                   label={'State'}
                                   colSize={6}
                                   options={{data: states, label: x=>x, value: x=>x}}
                                   required={true}
                                   type={'select'}
                                   value={carrier?.address?.state}
                                   placeholder={'-- Select --'}
                                   onTextChange={text => onAddressUpdate('state', text)}
                            //options={{data: states, value: x => x, label: x => x}}
                            // onTextChange={(value) => setdata({...data, state: value})}
                        />


                        <FormGroup name={'zipCode'}
                                   value={carrier?.address?.postal_code}
                                   required={true}
                                   colSize={6}
                                   label={'Zip code'}
                                   placeholder={'00000'}
                                   validation={x => x.length == 5}
                                   errorText='Postal Code Must Be 5 Digits'
                                   onTextChange={text => onAddressUpdate('postal_code', Helper.Masks.IntNumbers(text, 5))}
                        />

                        <div className="col-12 mb-3 d-flex row align-items-center">
                            <span className="fs-14 font-medium mb-0">Account holder info</span>
                            {/* <span className="text-gray fs-10 font-medium cursor-pointer no-select">CLEAR ALL</span> */}
                        </div>


                        <FormGroup name={'adminName'}
                                   value={carrier?.contact?.name || '-'}
                                   required={true}
                                   label={'Admin name*'}
                                   placeholder={'Admin name'}
                                   onTextChange={text => onContactUpdate('name', text)}/>




                        <FormGroup required hintText={'Phone number must be xxx-xxx-xxxx'}
                                   errorText={'Phone number must be xxx-xxx-xxxx'}
                                   validation={x => x.length == 12}
                                   colSize={12}
                                   name={'phone'}
                                   label={'Phone #*'}
                                   placeholder={"000-000-0000 "}
                                   value={carrier?.contact?.phone || '-'}
                                   onTextChange={text => onContactUpdate('phone', Helper.Masks.USPhone(text))}/>

                        <FormGroup name={'company_billing_adminEmail'}
                                   label={'Company billing/admin email*'}
                                   required
                                   colSize={12}
                                   value={carrier?.contact?.email || '-'}
                                   className={'mt-3'}
                                   type={'email'}
                                   placeholder={'abc@xyz.com'}
                                   validation={x => Helper.Validators.IsValidEmail(x)}
                                   onTextChange={text => onContactUpdate('email',text)}/>

                        
                        
                    </div>
                </div>

                <div className="row mt-3 d-flex ">

                    <div className="col-6 text-right">
                        <button className="btn w-100 py-2 bg-white rounded-pill font-12 text-light-blue"
                                onClick={onCancel}>CANCEL
                        </button>
                    </div>


                    <div className="col-6 text-right">
                        <button className="btn w-100 py-2 btn-blue-light rounded-pill font-12"
                                onClick={handleUpdate}>SAVE CHANGES
                        </button>

                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyAccountInfoForm
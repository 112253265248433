import React, { FC, useState } from 'react';
import ICONS from '../../../../../assets/svg';
import { VehicleOrder } from '../../../../classes/classes';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Helper from '../../../../helper/Helper';
import useMessageBox from '../../../../hooks/useMessageBox/useMessageBox';
import VehicleCard from '../VehicleCard/VehicleCard';

interface OnCheckChangedAction
{
    index: number, checked: boolean, checkbox: boolean, leg: boolean
}

interface IVehicleList
{
    orders: VehicleOrder[],
    onAddVehicleClicked: (count: number) => any,
    index: number,
    indexes: number[],
    onCheckChanged: (data: OnCheckChangedAction) => any,
    onAllCheckChanged: (checked: boolean) => any,
    onRemoveVehiclesClicked: (indexes: number[]) => any,
    onOrderChanged: (order: VehicleOrder, index: number) => any
}

const VehicleList: FC<IVehicleList> = (props) => {

    const [count, setCount] = useState<string>('');

    const messageBox = useMessageBox();
    
    return <div className='py-3 rounded bg-white col-12 flex-1-container'>
        <div className={'bg-white pb-3 px-3'}>
            <div className="row align-items-start" >
                <h4 className="font-11 mb-2">Vehicles: {props.orders.length}</h4>
                {props.orders.length > 0 && <div className='d-flex align-items-center'>
                    <Checkbox onCheckChange={props.onAllCheckChanged} checked={props.indexes.length == props.orders.length} name={'all_vehicles_check'} label={'All'}  />
                    <button onClick={() => {
                        messageBox.open(
                            {title: 'Are you sure?',
                            message: `Remove this vehicle${props.indexes.length > 1 ? 's' : ''}:\n${props.indexes.sort((x, y) => x > y ? 1 : -1).map(x => 'Vehicle ' + (x + 1)).join(',')}`,
                            buttons: [
                                {
                                    text: 'Cancel',
                                    css: 'btn btn-clear text-blue-light'
                                },
                                {
                                    text: 'Confirm',
                                    css: 'btn btn-blue-light',
                                    action: () => {
                                        props.onRemoveVehiclesClicked(props.indexes)
                                    }
                                }
                            ]})
                    }} className="btn btn-icon-only bg-red-light ml-2"><img src={ICONS.IconTrash} alt="" /></button>
                </div>}
            </div>

            <div className='row mt-2'>
                <div className={'col-4 d-flex'}>
                    <input value={count} onChange={(evt) => setCount(Helper.Masks.IntNumbers(evt.target.value).substring(0, 2))} style={{width: '2.5rem'}} className={'inline-input text-center'} type="text" placeholder={'1'} />
                    <button className="btn btn-blue-light ml-2" onClick={(evt) => props.onAddVehicleClicked(+count == 0 ? 1 : +count)}>ADD</button>
                </div>
                <button className="btn btn-blue-light">DUMP</button>
            </div>
            {props.orders.length > 0 && <hr className={'mt-3'} />}
        </div>
        

       

        <div className="px-3 flex-1-container">
        {props.orders.map((order, index) => 
                <VehicleCard 
                    onOrderChanged={props.onOrderChanged}
                    order={order}
                    onCheckChanged={props.onCheckChanged}
                    checkDisabled={props.indexes.length == 1 && index == props.index}
                    index={index}
                    id={'vehicle-card-' + index}
                    selected={props.index == index}
                    checked={props.indexes.includes(index)}
                    margin={index != props.orders.length - 1} />)}
        </div>
    </div>
}

export default VehicleList;
import { gql } from 'apollo-boost';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
// import { Table } from 'react-virtualized';
import ICONS from '../../../../assets/svg';
import { PaymentInfo } from '../../../components/ProcessPayment/classes/classes';
import ProcessPayment from '../../../components/ProcessPayment/ProcessPayment';
import Searchbar from '../../../components/Searchbar/Searchbar';
import IColumn from '../../../components/Table/models/IColumn';
import IMenuOption from '../../../components/Table/models/IMenuOption';
import Table from '../../../components/Table/Table';
import Helper from '../../../helper/Helper';
import { GraphqlService } from '../../../services/graphql.service';
import MastersPaymentDetail from '../../ReceivablesPage/MastersPaymentDetail/MastersPaymentDetail';

const columns: IColumn[] = [
    { label: 'PAYMENT ID', active: false, orderBy: 'DESC', name: 'p_id' },
    { label: 'DATE/TIME', active: false, orderBy: 'DESC', name: 'time' },
    { label: 'AMOUNT', active: false, orderBy: 'DESC', name: 'amount' },
    { label: 'CREDIT LEFT', active: false, orderBy: 'DESC', name: 'amount_left' },
    { label: 'PAID BY', active: false, orderBy: 'DESC', name: 'paid_by' },
    { label: 'MASTERS', active: false, orderBy: 'DESC', name: '_masters', type: 'html'},
    { label: 'PAYMENT METHOD', active: false, orderBy: 'DESC', name: 'payment_method' },
    { label: 'REFERENCE', active: false, orderBy: 'DESC', name: 'reference' },
    { label: 'NOTE', active: false, orderBy: 'DESC', name: 'note' },
    { label: 'CREDIT CARD', active: false, orderBy: 'DESC', name: 'cc' },
    { label: 'BANK ACCOUNT', active: false, orderBy: 'DESC', name: 'bank' }
];

const PaymentsTableByCustomer = (props) => {

    const ref = useRef();
    const [loadingPayments, setLoadingPayments] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState(new PaymentInfo())
    const [paymentSelected, setPaymentSelected] = useState(null);
    const [payments, setPayments] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const paymentsMenu: IMenuOption[] = [
        {
            label: 'Add File',
            icon: ICONS.IconPlus,
            action:(x: any) => {
                const paymentInfo = new PaymentInfo();
                paymentInfo.id = x.id;
                paymentInfo.mastersID = x.masters_id;
                setPaymentInfo(paymentInfo);
            }


        },
        {
            label: 'See Files',
            icon: ICONS.IconDetails,
            condition: x => x.files?.length > 0,
            action: async (x: any) => {

                const query = gql`
            query($uid:String, $is_thumbnail:Boolean){
                get_temp_link(uid:$uid is_thumbnail:$is_thumbnail)
            }
        `;

                const files = x.files as any[];

                for (let file of files)
                {
                    try {
                        const temp = await GraphqlService.SendQuery(query, {uid: file.uid, is_thumbnail: false});
                        const html = `
                        <html><head></head><body><img src="${temp}" /></body></html>
                               

                        `


                        var tab = window.open(file.name, '_blank');
                            tab.document.write(html); // where 'html' is a variable containing your HTML

                    } catch (ex) {

                    }
                }

            }

        }
    ]

    async function loadPayments() {
        
        let variables = {customer_id: props.customer_id}


        setLoadingPayments(true);
        const query = gql`
            query($customer_id:Int){
                get_payments_by_customer(customer_id: $customer_id){
                    id
                    note
                    time
                    type
                    amount
                    files {
                        uid
                        name
                    }
                    masters{
                        id
                        references{
                            key
                            value
                        }
                    }
                    paid_for{
                        key
                        name
                    }
                    reference
                    time_paid
                    amount_left
                    credit_card{
                        number
                        type
                    }
                    bank_account{
                        name
                        last_4
                        bank
                    }
                }
            }
        `

        try {
            const data = await GraphqlService.SendQuery(query, variables);
            // //console.log(data);
            setLoadingPayments(false);
            setPayments(data.map(x => ({...x, p_id: `P-` + x.id, time_paid_value: new Date(x.time_paid), time: [Helper.FORMAT.USDate(x.time_paid, 'do'), Helper.FORMAT.USDate(x.time_paid, 'to')],
                amount: Helper.FORMAT.USCurrency(x.amount),
                paid_by: x.paid_for[0]?.name || '-',
                invoices: '-',
                masters_id: x.masters.map(x => x.id),
                _masters: ()=> <div className={'cursor-pointer text-blue-light'} onClick={(ev)=>{ev.stopPropagation();
                    setPaymentSelected(x);
                }}><p className='font-11'>M-{x.masters[0]?.id}</p> {x.masters.length > 1 && <span style={{display: 'block'}} className='mt-1'>+{x.masters.length - 1} more</span>}</div>,
                payment_method: x.type,
                reference: x.reference || '-',
                note: x.note || '-',
                cc: !x.credit_card?.type ? '-' : [`${x.credit_card?.type}`, `${x.credit_card?.number}`],
                bank: !x.bank_account?.name ? '-' : [x.bank_account.name, x.bank_account.last_4 + ' ' + x.bank_account.bank],
                id: x.id,
                amount_left: Helper.FORMAT.USCurrency(x.amount_left),
                files: x.files || []
            })));
            console.log(data);
        } catch (ex) {
            console.log(ex.message);
            setLoadingPayments(false);
        }
    }

    useEffect(() => {
        loadPayments();
    }, []);

    const paymentsFiltered = () => payments.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.trim().toLowerCase()));


    return <div className='flex-1-container bg-white p-3'>
        <div className="row mb-3">
            <h4 className="font-10">Payments {paymentsFiltered().length} / {payments.length}</h4>
            <div className='d-flex align-items-center'>
                <button onClick={(evt) => loadPayments()} className="btn btn-icon-only mr-2">
                    <img className={loadingPayments ? 'spin' : ''} src={ICONS.IconRefresh} alt="" />
                </button>
                <Searchbar value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)} background='#F8F8F8' placeholder='Search...' reference={ref} />
            </div>
        </div>
        <Table 
        backgroundColor={(row) => {
            if (row.time_paid_value.getTime() > moment().add(-1, 'day').toDate().getTime())
                return 'bg-green-light';

        }}
        loading={loadingPayments}
                                   rowHeight={50}
                                   menuOptions={paymentsMenu}
                                   onRowClicked={(event) => {
                                       console.log(event.masters);
                                       setPaymentSelected(event)
                                   }} columns={columns} rows={paymentsFiltered()}/>

<ReactModal onRequestClose={(evt) => setPaymentSelected(null)} isOpen={paymentSelected != null}>
                <MastersPaymentDetail paymentSelected={paymentSelected} onClose={(evt) => {
                    setPaymentSelected(null);
                }}  onMasterDetailClicked={id => {setPaymentSelected(null); Helper.Navigation.NavigateTo(`/order/${id}`); }} />
            </ReactModal>


            <ReactModal style={{content: {padding: 0}}} className='modal-large' isOpen={paymentInfo.mastersID.length > 0}>
                <ProcessPayment data={paymentInfo} onClose={(evt) => {
                        setPaymentInfo(new PaymentInfo())
                        loadPayments();
                }} onPaymentSubmitted={(evt) => loadPayments()} />
            </ReactModal>
    </div>
}

export default PaymentsTableByCustomer;
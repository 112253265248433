import { gql } from 'apollo-boost';
import moment from 'moment';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import ICONS from '../../../../assets/svg';
import { CustomerProfile } from '../../../classes/classes';
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import Searchbar from '../../../components/Searchbar/Searchbar';
import IColumn from '../../../components/Table/models/IColumn';
import IMenuOption from '../../../components/Table/models/IMenuOption';
import Table from '../../../components/Table/Table';
import Helper from '../../../helper/Helper';
import useLoading from '../../../hooks/useLoading/useLoading';
import { GraphqlService } from '../../../services/graphql.service';

const query = gql`query($carrier_id: Int, $from_date: String!, $to_date: String!){
    get_carrier_orders_by_date(carrier_id: $carrier_id, from_date: $from_date, to_date: $to_date){
      id
      price
      completed
      accepted
      cancelled
      expired
      rejected
      expires
      driver_pay
      remaining_driver_payable
      remaining_carrier_receivable
      pending_change_request
      change_requests{
        id
        order_id
        accepted
        rejected
        time
        payout
        note
        active
        expired
        expires_at
        weblink
        code
      }
      vehicle{
        id
        vin
        year
        make
        model
        submodel
        type
        no_vin
        incomplete_vin
        color
        license_plate
        internal_note
        time_created
      }
      driver {
        id
        name
        contacts{
          title
          type
          value
        }
      }
      carrier {
        id
        name
        contact{
          name
          email
          phone
        }
      }
      origin{
        scheduled
        time_completed
        completed
        nofail
        address{
          street_number
          street
          city
          state
          postal_code
          postal_code_suffix
          country
        }
      }
      destination{
        scheduled
        time_completed
        completed
        nofail
        address{
          street_number
          street
          city
          state
          postal_code
          postal_code_suffix
          country
        }
      }
    }
  }
  `;

const queryOpen = gql`
    query($carrier_id: Int){
  get_carrier_outstanding_orders(carrier_id: $carrier_id){
    id
    price
    completed
    accepted
    cancelled
    expired
    rejected
    expires
    driver_pay
    remaining_driver_payable
    remaining_carrier_receivable
    pending_change_request
    change_requests{
      id
      order_id
      accepted
      rejected
      time
      payout
      note
      active
      expired
      expires_at
      weblink
      code
    }
    vehicle{
      id
      vin
      year
      make
      model
      submodel
      type
      no_vin
      incomplete_vin
      color
      license_plate
      internal_note
      time_created
    }
    driver {
      id
      name
      contacts{
        title
        type
        value
      }
    }
    carrier {
      id
      name
      contact{
        name
        email
        phone
      }
    }
    origin{
      scheduled
      time_completed
      completed
      nofail
      address{
        street_number
        street
        city
        state
        postal_code
        postal_code_suffix
        country
      }
    }
    destination{
      scheduled
      time_completed
      completed
      nofail
      address{
        street_number
        street
        city
        state
        postal_code
        postal_code_suffix
        country
      }
    }
  }
}

`;

const COLUMNS: IColumn[] = [
    {
        label: 'ORDER #',
        active: false,
        orderBy: 'DESC',
        name: 'velox_order_column'
    },
    {
        label: 'VEHICLE',
        active: false,
        orderBy: 'DESC',
        name: 'vehicle',
        sort_by: 'vehicle_vin'
    },
    {
        label: 'TIME IN',
        active: false,
        orderBy: 'DESC',
        name: 'time_in'
    },
    {
        label: 'ORIGIN',
        active: false,
        orderBy: 'DESC',
        name: 'origin'
    },

    {
        label: 'DESTINATION',
        active: false,
        orderBy: 'DESC',
        name: 'destination'
    },


    {
        label: 'CARRIER PAY',
        active: false,
        orderBy: 'DESC',
        name: 'carrier_pay',
        sort_by: 'carrier_pay_amount',
    },

    {
        label: 'REMAINING BALANCE',
        active: false,
        orderBy: 'DESC',
        name: 'remainig_balance',
        sort_by: 'remainig_balance_amount',
    },

    {
        label: 'STATUS',
        active: false,
        orderBy: 'DESC',
        name: 'status'
    }];


interface ICustomerStatments {
    openOrders: boolean,
    carrier_id: number,
    onClose: (evt) => any,
    onPDFReceived: (pdf: string) => any
}

const CarrierStatments: FC<ICustomerStatments> = (props) => {

    const ref = useRef();
    const [searchTerm, setSearchTerm] = useState('');
    const [rows, setRows] = useState<any[]>([]);
    const toast = useToasts();
    const [loadingTable, setLoadingTable] = useState(false);
    const loading = useLoading();

    const [fromDate, setFromDate] = useState(new Date(moment().add(-1, 'day').format('YYYY/MM/DD 00:00:00')));
    const [toDate, setToDate] = useState(new Date(moment().format('YYYY/MM/DD 23:59:59')));

    const [pickerOpen, setPickerOpen] = useState(false);

    useEffect(() => {
        setPickerOpen(!props.openOrders);
        if (props.openOrders)
            loadOpenOrders();
    }, []);

    async function loadPaid(date1, date2) {
        try {
            setLoadingTable(true);
            const data = await GraphqlService.SendQuery(query, {carrier_id: props.carrier_id, from_date: date1, to_date: date2});
            fillData(data);
            setFromDate(date1);
            setToDate(date2);
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            setLoadingTable(false);
        }
    }

    async function loadOpenOrders() {
        try {
            setLoadingTable(true);
            const data = await GraphqlService.SendQuery(queryOpen, {carrier_id: props.carrier_id});
            fillData(data);
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            setLoadingTable(false);
            
        }
    }

    async function fillData(data) {
        setLoadingTable(false);
        data = data.map(r => (
            {...r,
              velox_order_column: 'VLX-' + r.id,
              velox_order_id: r.id,
          vehicle: [r.vehicle.vin || '-', r.vehicle.year + ' ' + r.vehicle.make, r.vehicle.model],
          driver: [r.driver?.name || '-'],
          carrier: [r?.carrier?.name || '-'],
          broker: [r.broker?.name || '-'],
          vehicle_vin: r.vehicle.vin || '',
          time_in: [Helper.FORMAT.USDate(r.time, 'do'), Helper.FORMAT.USDate(r.time, 'totz')],
          time: r.time,
          origin: [r.origin.address?.city, `${r.origin.address?.state} ${r.origin.address?.postal_code}`, Helper.FORMAT.USDate(r.origin.scheduled, 'do'), Helper.FORMAT.USDate(r.origin.scheduled, 'totz')],
          destination: [r.destination.address?.city, `${r.destination.address?.state} ${r.destination.address?.postal_code}`, Helper.FORMAT.USDate(r.destination.scheduled, 'do'), Helper.FORMAT.USDate(r.destination.scheduled, 'totz')],
          status: r.origin.completed && r.destination.completed ? 'COMPLETED' : (r.origin.completed ? 'PICKED UP' : 'DISPATCHED'),
          remainig_balance: Helper.FORMAT.USCurrency(r.remaining_carrier_receivable),
          remainig_balance_amount:  r.remaining_carrier_receivable,
          carrier_pay_amount: r.carrier_pay,
          carrier_pay: Helper.FORMAT.USCurrency(r.carrier_pay)}));

            setRows(data);
    }


    async function getPDF(velox_ids) {
        const mutation = gql`
            mutation($carrier_id: Int, $velox_ids: [Int], $title: String){
  generate_carrier_report(carrier_id: $carrier_id, velox_ids: $velox_ids, title: $title)
}
        `;

        try {
            loading.open('Getting report...');
            const data = await GraphqlService.SendMutation(mutation, {carrier_id: props.carrier_id, velox_ids, title: props.openOrders ? 'Open Orders' : 'Paid Orders'})
            loading.close();
            props.onPDFReceived(data);
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }


    const menuOptions: IMenuOption[] = [
        {
            label: 'See Order Details',
            icon: ICONS.IconDetails,
            action: (x) => {
                Object.assign(document.createElement('a'), {
                    target: '_blank',
                    href: '/order/' + x.master_id,
                }).click();
            }
        },
        {
            label: 'Print Statment',
            icon: ICONS.IconPrintYellow,
            multipleRows: true,
            action: (x) => {
                const ids = Array.isArray(x) ? x.map(x => x.row.id) : [x.id];

                getPDF(ids).then();
            }
        }
    ];

    return <div className='flex-1-container'>
        
        <div className="row mb-3">
            <h4 className="font-11">{props.openOrders ? 'Open' : 'Paid'} Orders {((!props.openOrders && !pickerOpen)) && <span className='font-normal'>for: {moment(fromDate).format('MM/DD/YYYY')} - {moment(toDate).format('MM/DD/YYYY')}</span>}</h4>
            <div className='col-4 d-flex align-items-center'>
                <button onClick={(evt) => props.openOrders ? loadOpenOrders() : loadPaid(fromDate, toDate)} className="btn btn-icon-only mr-2">
                    <img className={loadingTable ? 'spin' : ''} src={ICONS.IconRefresh} alt="" />
                </button>
                <Searchbar className='col-12' background='#f8f8f8' placeholder='Search...' value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)} reference={ref} />
                <button onClick={props.onClose} className="btn btn-icon-only ml-2">
                    <h4 className="font-10">
                        <i className='fas fa-times'></i>
                    </h4>
                </button>
            </div>
        </div>

        <Table menuOptions={menuOptions} showCheckbox columns={COLUMNS} loading={loadingTable} rows={rows.filter(x => JSON.stringify(x).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase().trim()))} onRowClicked={(evt) => {}} />

        {pickerOpen && <DateTimePicker hideTime date={[fromDate, toDate]} modal onCancelClicked={(evt) => props.onClose(evt)} onDateSubmitted={(evt) => {
            (evt[0] as Date).setHours(0, 0, 0);
            (evt[1] as Date).setHours(23, 59, 59);
            console.log(evt);
            loadPaid(evt[0], evt[1]);
            setPickerOpen(false);
        }} />}
    </div>
}

export default CarrierStatments;
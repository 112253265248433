import React, {useEffect, useState} from "react";
import {CustomerProfile} from "../../classes/classes";
import ReactModal from "react-modal";
import CustomerProfileForm from "../CustomerProfileForm/CustomerProfileForm";

const NEW_PROFILE = 'Create new profile';
const EDIT_PROFILE = 'Edit profile';
const ADD_EDIT_CONTACT = 'Add / edit contact';
const RESET_PASSWORD = 'Reset Password';

const ProfilesContacts = ({customerId, customerProfiles, onProfileSubmitted}) => {
    const [profiles, setProfiles] = useState<CustomerProfile[]>(customerProfiles || []);
    const [buttonAction, setButtonAction] = useState<string>();
    const [selectedProfile, setSelectedProfile] = useState(undefined);

    useEffect(()=>{
        setProfiles(customerProfiles);
    }, [customerProfiles])

    const handleNewProfile = () => {
        setSelectedProfile(new CustomerProfile());
        setButtonAction(NEW_PROFILE);
    }

    const handleAddEditContact = (event, profile) => {
        event.preventDefault();
        event.stopPropagation();

        setSelectedProfile(profile);
        setButtonAction(ADD_EDIT_CONTACT);
    }

    const handleSelectProfile = profile => {
        setSelectedProfile(profile);
        setButtonAction(EDIT_PROFILE);
    }

    const showProfileContacts = (profile: CustomerProfile) => {
        return(
            <>
                {
                    (profile?.numbers ? profile?.numbers : profile.contact)?.map((contact, index) => {
                        return (
                            <div key={`contact-${index}`} className="bg-white p-3 border-radius mb-3 d-flex justify-content-between align-items-center">
                                <p className="fs-12 font-medium">{ contact }</p>
                                <p className="fs-12 font-italic">Office</p>
                            </div>
                        )
                    })
                }

                <p className="fs-12 font-medium text-light-blue text-right cursor-pointer"
                   onClick={(evt)=> { handleAddEditContact(evt, profile) }}>ADD / EDIT CONTACT</p>
            </>
        );
    }

    return (
        <>
            <div className="row">
                <div className="col-12 mb-3 d-flex justify-content-between align-items-center">
                    <p className="fs-12 font-medium">Profiles ({profiles?.length})</p>
                    <p className="fs-12 font-medium cursor-pointer text-light-blue"
                       onClick={handleNewProfile}><i className="ti-plus"></i> NEW PROFILE</p>
                </div>

                {
                    profiles?.map((profile, index) => {
                        return(
                            <div key={index} className={"col-12 mb-" + (index == profiles.length - 1 ? '0' : '3')}>
                                <div className={`card cursor-pointer ${selectedProfile?.id == profile.id ? 'bg-light-blue' : 'bg-gray'}`}
                                     onClick={(ev)=>{handleSelectProfile(profile)}}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="fs-14 font-medium">{profile.name}</p>
                                                    <p className="fs-12">Admin</p>
                                                </div>
                                                <p className="fs-12 font-light font-italic mb-3">{profile.title}</p>

                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="fs-12">{profile.email}</p>
                                                    <p className="fs-10 font-medium text-light-blue">VERIFIED</p>
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                { showProfileContacts(profile) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <ReactModal isOpen={selectedProfile}
                        className={""}
                        //style={{content: {borderRadius: '12px', width: '496px', paddingBottom: '0', minWidth: '496px'}}}
                        >
                <CustomerProfileForm customerId={customerId}
                                     customerProfile={selectedProfile}
                                     onSubmit={(customerProfile) => {
                                            setSelectedProfile(undefined);
                                            onProfileSubmitted();
                                        //  if (customerProfile) {
                                        //     let index = profiles.findIndex(x => x.id == customerProfile.id )
                                        //      let temp = [...profiles];
                                        //      if(index === -1){
                                        //      temp.push(customerProfile);

                                        //      setProfiles([...temp]);
                                        //      }else{
                                        //         setProfiles(profiles.map(x=>{
                                        //             if(x.id === customerProfile.id){
                                        //                 x = customerProfile
                                        //             }
                                        //             return x
                                        //         }))
                                        //      }
                                            
                                        //  }
                                     }}/>
            </ReactModal>
        </>
    );
}

export default ProfilesContacts;

import {gql} from "apollo-boost";

const GET_BANK_ACCOUNTS = gql(`
query {
    get_bank_accounts {
        id
        name
        bank
        note
        active
    }
}`);

const bankAccountQuery = {
    GET_BANK_ACCOUNTS
}

export default bankAccountQuery;
import gql from "graphql-tag";

const UPDATE_BOL_DISCLAIMER = gql`
    mutation($key:String, $value:String)
    { update_setting(key:$key, value:$value)
    { success message } }
`;

const BolDisclaimerMutations = {
    UPDATE_BOL_DISCLAIMER
};

export default BolDisclaimerMutations

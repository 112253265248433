import './DriverCardJob.scss'
import ReactModal from "react-modal";
import {useEffect, useRef, useState} from "react";
import CarriersDriversTable from "../CarriersDriversTable/CarriersDriversTable";
import {useDispatch, useSelector} from "react-redux";
import {AllStates} from "../../redux/reducers";
import {ICarrierState} from "../../redux/reducers/Carrier.reducer";
import setCarrier from "../../redux/actions/Carrier.action";
import {GraphqlService} from "../../services/graphql.service";
import DriverQueries from "../../graphql/query/Driver";
import Status from "../Status/Status";
import Loading from "../Loading/Loading";
import DriverListOrder from "../DriverListOrder/DriverListOrder";
import {UserSettings} from "../../classes/classes";
import {getReceivableAging} from "../../redux/actions/aging.action";
import environments from '../../../environments/environments';
import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
  } from '@react-hook/window-size';
  import useResizeObserver from "use-resize-observer";

const DriverCardJob = ({onDriverClicked, onAddClicked= null, onEditClicked = (driver) => {}, driverSelected, onCarrierSelected = null, gridOne = false, modal = false, showCarrier = true, refresh = false, defaultCarrier = null, onLoadedData = ()=>{}, edit = false}) => {

    // const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
    // console.log(width);

    const dispatch = useDispatch();
    const carrier = useSelector<AllStates>(states => states.carriers) as ICarrierState;

    const myCarrier = modal ? {id: environments.JP_CARRIER_ID, name: environments.JP_CARRIER_NAME} : defaultCarrier ? defaultCarrier : carrier;

    const userSettings = useSelector<AllStates>(states => states.userSettings) as UserSettings;
    const orders = (userSettings.driver_view_order.find(x => x.carrier_id == myCarrier.id)?.order || []).map(x => x.id);
    const [drivers, setDrivers] = useState([]);
    const [loading, setLoading] = useState(false);
    // const screenWidth = useWindowWidth()

    const containerRef = useRef();


    function getDriversOrdered () {
        const driversOrdered = orders.map(x => drivers.find(d => d.driver.id == x)).filter(x => x);
        return [...new Set([...driversOrdered, ...drivers])];
    }

    async function load() {
        try {
            setLoading(true);

            const carrierID = defaultCarrier ? defaultCarrier.id : myCarrier.id;
            const data = await GraphqlService.SendQuery(DriverQueries.GET_BY_CARRIER_ID, {carrier_id: carrierID}) || [];
            // //console.log(data);
            let cont = 0;

            const array = [...data].map(x => {
                const temp = JSON.parse(JSON.stringify(x));

                temp.driver.itinerary = temp.driver.itinerary.map((it, index) => {
                    // if ()
                    let pickup_count = 0;
                    let dropoff_count = 0;


                    const array = (it.orders[0]?.address?.split(',') || []);
                    // console.log(array);
                    it.state = array[array.length - 1]?.toUpperCase().trim().substring(0, 2) || 'N/A';
                    // console.log(it.state);

                    it.orders.forEach(o => {
                        pickup_count += o.is_pickup ? 1 : 0;
                        dropoff_count += o.is_pickup ? 0 : 1;
                    });

                    cont += pickup_count - dropoff_count;
                    const _it = {...it};
                    _it.pickup_count = pickup_count;
                    _it.dropoff_count = dropoff_count;
                    _it.line_style = index == temp.driver.itinerary.length - 1 ?  'none' :
                        cont == 0 ? 'dashed' : 'solid';

                    return _it;
                })
                return temp;
            });

            if (!modal) {

                // let d = array.find(x => x.driver.id == driverSelected?.id)?.driver;
                // if (d)
                //     onDriverClicked({...d}, true);
                // else
                //     onDriverClicked(null);
            }

            setDrivers(array);
            setLoading(false);

            onLoadedData();
        }
        catch (ex)
        {
            setLoading(false);
            console.log(ex.message);
        }
    }

    const dRef = useRef<HTMLDivElement>();

    useEffect(() => {
        load().then();

    }, [myCarrier.id]);


    const [cardWidth, setCardWidth] = useState(0);

    const getWidth = () => {
        const div = document.getElementById('container-cards');

        let width = div?.scrollWidth || 0;
            return width  - (!gridOne && window.innerWidth >= 1500 ? width / 2 : 0);

    }

    useEffect(() => {


        setCardWidth(getWidth());

        new ResizeObserver((evt) => {
            setCardWidth(getWidth());
        }).observe(document.getElementById('container-cards'))
    }, []);

    useEffect(() => {
        if (refresh)
            load();
    }, [refresh]);

    const hasPickup = (orders, pickup = true) => orders.some(x => x.is_pickup == pickup)

    const [modalOpened, setModalOpened] = useState(false);
    const [modalOOrganizepened, setModalOOrganizepened] = useState(false);

    return(<>
        <div className="flex-1-container driver-card-job" ref={containerRef}>
            {/*<div className="card-body flex-1-container rounded">*/}
            {/*  */}
            {/*</div>*/}
            <div className="row col-12 align-items-baseline mb-3">
                <div className="col-12 row">
                    <p className={`font-medium cursor-pointer font-10 mr-3`}>Drivers</p>
                    {onAddClicked && <span className={`edit`}
                    onClick={onAddClicked}>Add Driver</span>}
                    {showCarrier && <button onClick={(evt) => setModalOpened(true)} className="btn btn-orange d-flex align-items-center">{myCarrier.name} <i className="ml-1 fas fa-chevron-down"></i></button>}
                </div>


            </div>

            <div className="row align-items-start col-12">
                <p className="font-medium font-9 pb-3">{drivers.length} DRIVERS </p>
                {!modal && <button onClick={(evt) => setModalOOrganizepened(true)} className="btn btn-blue-light-outline d-flex align-items-center">Organize <i className="ml-1 fas fa-sort"></i></button>}
            </div>

            <div id='container-cards' className="flex-1-container" style={{overflowX: 'hidden'}}>
                <div className={'row '}>
                    {
                        loading ? <div className={'col-12'}><Loading /></div>  :
                            getDriversOrdered().map((e, i, array) => {
                                return(
                                    <div id={`driver-card-detail-${e.driver.id}`}  ref={i == 0 ? dRef : null} key={i} className={`col-${gridOne ? '12' : '6'} ${i < array.length - (1) ? 'mb-3 ' : ''}cursor-pointer ${modal ? '' : 'col-1500-12'}`} onClick={(evt) => {
                                        onDriverClicked({...e.driver});
                                    }}>
                                        <div className={"card "  + (driverSelected?.id == e.driver?.id ? 'bg-gold' : 'bg-light')} style={{height: '100%'}}>
                                            <div className="card-body px-2 pt-3 pb-2">
                                                <div className="row">
                                                    <div className="col-12 row align-items-start">
                                                        <div className='col-6'>
                                                            <p className="font-9 font-medium flex-wrap mb-1">{ e.driver.name }</p>
                                                            <p className="font-8 font-regular flex-wrap">{ e.driver.contacts.filter(x => x.type.toLowerCase() == 'phone').map(x => x.value)[0] || 'NONE' }</p>
                                                        </div>
                                                        <div>
                                                            <Status statusName={e?.driver?.status?.text} className={'font-6'} />
                                                            { edit && <h4 onClick={(evt) => {
                                                                evt.stopPropagation();
                                                                onEditClicked({...e.driver});

                                                            }} className="font-10 mt-2 text-right text-blue-light cursor-pointer">EDIT</h4>}
                                                        </div>

                                                    </div>

                                                    <div
                                                        style={{opacity: e.driver.itinerary.length == 0 ? 0 : 1}}
                                                        className="col-12 d-flex justify-content-between align-items-center mt-2">
                                                        <p className="font-9 font-regular">{e.driver.equipment || 'None'} ({e.driver.itinerary.length})</p>
                                                        <p className="font-9 font-regular">{e.driver.price.toCurrency()}</p>
                                                    </div>

                                                    {e.driver.itinerary.length == 0  && <div style={{opacity: e.driver.itinerary.length > 0 ? 0 : 1}}
                                                                                             className="col-12"><h4
                                                        className={'font-10 text-red text-center'}>No jobs</h4></div>}
                                                    {e.driver.itinerary.length > 0 && <div
                                                    
                                                    onClick={(evt) => {
                                                        onDriverClicked({...e.driver, show_route: true});
                                                        evt.stopPropagation();
                                                    }}
                                                    className="col-12 row align-items-center light-hover mt-1" style={{padding: '0 7px'}}>

                                                        <div className="line-container mt-1">
                                                            {
                                                                e.driver.itinerary.slice(0, Math.trunc(cardWidth / 40) - 2).map((driver, j) => {
                                                                    return (
                                                                        <div key={j} className={`line ${driver.line_style == 'dashed' ? 'dashed' : ''} ${driver.line_style == 'none' ? 'none' : ''}`}>
                                                                            <div className="points" >

                                                                                <div className="points-container">
                                                                                    <p style={{position: 'absolute', left: '-8px', top: '-18px', fontSize: '10px'}}>{driver.state}</p>
                                                                                    {driver.pickup_count > 0 && <span
                                                                                        className="orders">{driver.pickup_count}</span>}

                                                                                    <div className={`d-flex triangles ${driver.dropoff_count && driver.pickup_count ? 'center-triangles' : ''}`}>
                                                                                        {driver.dropoff_count > 0 && <div className="red-triangle"></div>}
                                                                                        {driver.pickup_count > 0 && <div className="green-triangle"></div>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        {Math.trunc(cardWidth / 40) - 2 < e.driver.itinerary.length &&<div>
                                                            <p className="font-regular font-10">+{e.driver.itinerary.length - (Math.trunc(cardWidth / 40) - 2) }</p>
                                                        </div>}
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>
            </div>

        </div>

        <ReactModal className={"modal-large"} isOpen={modalOpened} onRequestClose={evt => setModalOpened(false)} >
            <CarriersDriversTable onCarrierSelected={carrier => {
                if (modal) {
                    onCarrierSelected && onCarrierSelected(carrier);
                } else {
                    dispatch(setCarrier(carrier));
                }

                setModalOpened(false);
            }} />
        </ReactModal>

        <ReactModal shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false} isOpen={modalOOrganizepened} onRequestClose={evt => setModalOOrganizepened(false)} >
            <DriverListOrder carrier_id={defaultCarrier?.id} onClose={() => setModalOOrganizepened(false)} drivers={drivers.map((x, index)  => x.driver)} />
        </ReactModal>
    </>);
}

export default DriverCardJob;

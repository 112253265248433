import { ServiceCharge } from "../../classes/classes";
import Helper from "../../helper/Helper";

const OrderInvoicePdf = ({data}) => {

    //console.log(data);
    return(
        <>
            <div className="row mt-4">
                <div className="col-2">
                </div>

                <div className="col-3">
                    <p className="font-11">11582 Sheldon St.</p>
                    <p className="font-11 mb-2">Sun Valley, CA 91352</p>

                    <p className="font-11 mb-1">P | 818-381-8313</p>
                    <p className="font-11">F | 818-381-8315</p>
                </div>

                <div className="col-2">
                    <p className="font-11">EIN: 80-0472956</p>
                </div>

                <div className="col-5">
                    <p className="font-18 font-medium">INVOICE #12345</p>

                    <div className="row mt-3">
                        <div className="col-7">
                            <p className="font-10 font-medium text-gray mb-1">DATE ISSUED</p>
                            <p className="font-11">6/19/2019</p>
                        </div>

                        <div className="col-5">
                            <p className="font-10 font-medium text-red mb-1">DUE</p>
                            <p className="font-11 font-medium text-red">6/19/2019</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-2 text-center">
                    <p className="font-10 font-medium text-gray">BILLED TO</p>
                </div>

                <div className="col-5">
                    <p className="font-10 font-medium text-gray mb-3">CUSTOMER</p>

                    <p className="font-12 font-medium mb-1">Customer Namehere</p>
                    <p className="font-12 font-medium mb-1">Customer</p>
                    <p className="font-12 font-medium mb-2">3 lines max</p>

                    <p className="font-11 mb-2">Customer ID: #00000</p>

                    <p className="font-11 font-medium mb-1">100 Main St.</p>
                    <p className="font-11 font-medium mb-1">Los Angeles, CA 99999</p>
                </div>

                <div className="col-5">
                    <p className="font-10 font-medium text-gray mb-3">POINT OF CONTACT</p>
                    <p className="font-12 font-medium mb-2">Invoice Contact</p>

                    <p className="font-11 mb-1">888-888-8888</p>
                    <p className="font-11 mb-2">abc@xyz.com</p>

                    <p className="font-11 mb-1">PO #00000</p>
                    <p className="font-11 mb-1">RO #00000</p>
                </div>
            </div>

            <div className="row mt-5 mb-3 pb-3 border-bottom">
                <div className="col-2 text-center">
                    <p className="font-10 font-medium text-gray">ORDER #</p>
                </div>

                <div className="col-6">
                    <p className="font-10 font-medium text-gray">VEHICLES & DELIVERY DETAILS</p>
                </div>

                <div className="col-2">
                    <p className="font-10 font-medium text-gray">PRICE</p>
                </div>
                <div className="col-2">
                    <p className="font-10 font-medium text-gray">REMAINING BALANCE</p>
                </div>
            </div>

            {
                data.vehicle_orders.map((vo) => {
                    return(
                        <>
                            <div className="row">
                                <div className="col-2 pl-4">
                                    <p className="font-16 font-medium mb-1">#{vo.vehicle.vehicle_order_id}</p>

                                    {/* <p className="font-10 mb-1">PO #00000</p>
                                    <p className="font-10 mb-1">REF #00000</p> */}
                                </div>

                                <div className="col-6">
                                    <p className="font-16 font-medium mb-1">{vo.vehicle.vin}</p>
                                    <p className="font-11 mb-1">{vo.vehicle.description}</p>
                                </div>

                                <div className="col-2">
                                    <p className="font-16 font-medium mb-1">{Helper.FORMAT.USCurrency(vo.transportation_charges.amount + vo.service_charges.sumBy(x => x.amount))}</p>
                                </div>

                                <div className="col-2">
                                <p className="font-16 font-medium mb-1">{Helper.FORMAT.USCurrency(vo.transportation_charges.amount + vo.service_charges.sumBy(x => x.amount_remaining))}</p>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-2"></div>
                                <div className="col-8">
                                    <p className="font-10 font-medium text-gray mb-3">TRANSPORTATION CHARGE</p>
                                </div>
                                <div className="col-2"></div>
                            </div>

                            <div className="row">
                                <div className="col-2"></div>
                                <div className="col-3">
                                    <p className="font-12 font-medium mb-1">{vo.transportation_charges.pickup.title}</p>

                                    <p className="font-11 mb-2">{vo.transportation_charges.pickup.address.city}, {vo.transportation_charges.pickup.address.postal_code}</p>
                                    <p className="font-12 font-medium">{Helper.FORMAT.USDate(vo.transportation_charges.pickup.time_scheduled, 'do')} {Helper.FORMAT.USDate(vo.transportation_charges.pickup.time_scheduled, 'to')}</p>
                                </div>

                                <div className="col-3">
                                    <p className="font-12 font-medium mb-1">{vo.transportation_charges.dropoff.title || 'NO TITLE'}</p>

                                    <p className="font-11 mb-2">{vo.transportation_charges.dropoff.address.city}, {vo.transportation_charges.dropoff.address.postal_code}</p>
                                    <p className="font-12 font-medium">{Helper.FORMAT.USDate(vo.transportation_charges.dropoff.time_scheduled, 'do')} {Helper.FORMAT.USDate(vo.transportation_charges.dropoff.time_scheduled, 'to')}</p>
                                </div>

                                <div className="col-2">
                                    <p className="font-12 font-medium mb-1">{Helper.FORMAT.USCurrency(vo.transportation_charges.amount)}</p>
                                </div>

                                <div className="col-2">
                                    <p className="font-12 font-medium mb-1 text-red">{Helper.FORMAT.USCurrency(vo.transportation_charges.amount)}</p>
                                </div>
                                
                            </div>

                            <div className="row">
                                <div className="col-2"></div>
                                <div className="col-8">
                                    <p className="font-10 font-medium text-gray mt-3 mb-3">SERVICE CHARGE</p>
                                </div>
                                <div className="col-2"></div>
                            </div>

                            {vo.service_charges.map((sc: ServiceCharge) => <div className="row mb-2">
                                <div className="col-2"></div>
                                <div className="col-6">
                                    <p className="font-12 font-medium mb-2">{sc.name}</p>
                                </div>
                                <div className="col-2">
                                    <p className="font-12 font-medium mb-2">{Helper.FORMAT.USCurrency(sc.amount)}</p>
                                </div>
                                <div className="col-2">
                                    <p className="font-12 font-medium mb-2 text-red">{Helper.FORMAT.USCurrency(sc.amount_remaining)}</p>
                                </div>
                            </div>)}

                            <div className="border-bottom mt-1 mb-3"></div>
                        </>
                    )
                })
            }

            <div className="row">
                <div className="col-2"></div>
                <div className="col-8">

                <p className="font-10 font-medium text-gray mb-3">MASTER SERVICE CHARGE</p>
                </div>
                <div className="col-2"></div>
            {data.service_charges.map((sc: ServiceCharge) => <div className="row col-12 mb-2">
                                <div className="col-2"></div>
                                <div className="col-6">
                                    <p className="font-12 font-medium mb-2">{sc.name}</p>
                                </div>
                                <div className="col-2">
                                    <p className="font-12 font-medium mb-2">{Helper.FORMAT.USCurrency(sc.amount)}</p>
                                </div>
                                <div className="col-2">
                                    <p className="font-12 font-medium mb-2 text-red">{Helper.FORMAT.USCurrency(sc.amount_remaining)}</p>
                                </div>
                            </div>)}
            </div>

            {data.service_charges.length > 0 && <div className='border-bottom mt-1 mb-3'></div>}

            <div className="row mb-4">
                <div className="col-8 d-flex justify-content-end align-items-center">
                    {/* <p className="text-red font-11">total</p> */}
                </div>

                <div className="col-2 d-flex justify-content-start align-items-end">
                    <p className="font-18 font-medium">{Helper.FORMAT.USCurrency(data.service_charges.sumBy(x => x.amount) + data.vehicle_orders.sumBy(x => x.transportation_charges.amount + x.service_charges.sumBy(y => y.amount)))}</p>
                </div>
                <div className="col-2 d-flex justify-content-start align-items-end">
                <p className="font-18 font-medium">{Helper.FORMAT.USCurrency(data.service_charges.sumBy(x => x.amount_remaining) + data.vehicle_orders.sumBy(x => x.transportation_charges.amount + x.service_charges.sumBy(y => y.amount_remainig)))}</p>
                </div>
            </div>
            
        </>
    );
}

export default OrderInvoicePdf;

import React, { useRef, useState } from 'react';
import environments from '../../../environments/environments';
import { Leg } from '../../classes/classes';
import Helper from '../../helper/Helper';
import CarrierDriverSelector from '../CarrierDriverSelector/CarrierDriverSelector';
import FormGroup from '../FormGroup/FormGroup';

import StopLegEntry from '../NewOrder/StopLegEntry/StopLegEntry';

const LegSection = (props) => {
    const [hours, setHours] = useState('');

    const carrierRef = useRef();
    const driverRef = useRef();

    return  <div className={'row ' + (props.animated && props.animationOn ? 'translate-down bg-white z-index-2' : (props.leg?.id ? 'mb-3 pb-3 border-bottom' : ''))}>
        <h4 className="font-10 col-12 mb-2">{props.leg?.id ? 'Leg-' + props?.leg?.id : 'New Leg'}</h4>
         <div className="col-6">
             <StopLegEntry popupFit stop={props.leg?.getPickup()} sameAsBillingClicked={(evt) => props.handleSameAsBillingClicked(props.leg, true)} 
             stopChanged={(stop) => props.handleStopChanged(props.leg, stop)}
             />
         </div>
         <div className="col-6">
             <StopLegEntry popupFit stop={props.leg?.getDropoff()} sameAsBillingClicked={(evt) => props.handleSameAsBillingClicked(props.leg, false)} 
             stopChanged={(stop) => props.handleStopChanged(props.leg, stop)}
             />
         </div>
         <div className="col-6 mt-3">
                                 
         <div className="d-flex mb-2">
             <h4 className="font-10">Equipment & Price</h4>                
             <button className='btn btn-orange' style={{opacity: 0, pointerEvents: 'none'}}>TEST</button>
         </div>
         <div className="row col-12 box">
             <div style={{borderTopLeftRadius: 8, borderBottomLeftRadius: 8, width: '70%'}} className={"pr-2 "}>
                 <FormGroup label={'Equipment'} value={props.leg?.equipment}
                             onTextChange={(evt) => props.handleEquipmentChanged(props.leg, 'equipment', evt)} placeholder={'-- Select --'} name={'equipment'}
                             type='select' options={{
                     data: ['Flatbed', 'Enclosed', 'Enclosed Premium', 'Open'],
                     label: x => x,
                     value: x => x
                 }}/>
             </div>
             <div style={{borderTopRightRadius: 8, borderBottomRightRadius: 8, width: '30%'}} className={"pl-2"}>
                 <FormGroup type="number" moneySymbol label={'Charge'}
                         onTextChange={(evt) => props.handleEquipmentChanged(props.leg, 'equipment_price', evt ? +Helper.Masks.DecimalNumbers(evt) : 0)} value={props.leg?.equipment_price.toString()} placeholder={'$0.00'}
                         name={'charge'}
                         onFocus={(evt) => {
                             evt.target.select()
                         }}/>
             </div>
         </div>

     </div>
         <div className="col-6 mt-3">
             <CarrierDriverSelector leg={props.leg} onLegChanged={props.handleOnLegChanged} />
         </div>

         <div className="mt-3 col-12 row">
         {(props.leg.carrier?.id && (!props.leg.driver?.id || props.leg.carrier?.id != environments.JP_CARRIER_ID)) && <div className="row col-12">
                                    <div className="row col-12">
                                        <div className="col-6 box">
                                            <h4 className="label-style">Carrier Payout</h4>
                                            <h4 className="font-10 font-normal">{(+(props.carrier_pay || 0)).toCurrency()}</h4>
                                        </div>
                                        <div className={"col-6 box "}>
                                            <FormGroup innerRef={carrierRef} onTextChange={(evt) => {
                                                            const temp = new Leg(props.leg);
                                                            temp.carrier_pay = Helper.Masks.DecimalNumbers(evt) as any;
                                                            props.handleOnLegChanged(temp);
                                                        }} value={props.leg.carrier_pay?.toString()} moneySymbol label='Carrier Payout'
                                                        name='payout' colSize={12} placeholder={'Carrier payout...'}/>

                                            
                                        </div>
                                    </div>
                                    
                                </div>}


                                {(props.leg.driver?.id && props.leg.carrier?.id == environments.JP_CARRIER_ID) && <div className='row col-12'>
                                    <div className="col-6 box">
                                            <h4 className="label-style">Driver Payout</h4>
                                            <h4 className="font-10 font-normal">{(+(props.driver_pay || 0)).toCurrency()}</h4>
                                        </div>
                                    <div className={"col-6 box"}>
                                        <FormGroup innerRef={driverRef}
                                                    onTextChange={(evt) => {
                                                        const temp = new Leg(props.leg);
                                                        if (evt)
                                                            evt = Helper.Masks.DecimalNumbers(evt);
                                                        else
                                                            evt = '';
                                                        temp.driver_pay = Helper.Masks.DecimalNumbers(evt) as any;
                                                        props.handleOnLegChanged(temp);
                                                    }}
                                                    value={props.leg.driver_pay?.toString() || '0'} moneySymbol label='Driver Payout'
                                                    name='payout' colSize={12} placeholder={'Driver payout...'}/>
                                    </div>
                                </div>}
         </div>
    </div>
    

}

export default LegSection;
import React from 'react';
import ICONS from '../../../assets/svg';
import { ServiceCharge } from '../../classes/classes';

const StorageServices = ({charges, onEditClicked, onDeleteClicked, onAddClicked}) => {

    return <div className='row col-12'>
        <div className="col-12 row mb-3">
            <h4 className='font-11'>Storage Services</h4>
            <h4 onClick={onAddClicked} className="font-11 cursor-pointer font-normal text-blue-light d-flex align-items-center"><img src={ICONS.IconPlus} alt="" /> Add New Charge</h4>
        </div>
        {charges.map((x, i) => <div className="row col-12 py-2" style={{borderBottom: '1px solid #CCC'}}>
            <div className="col-2">
                <h4 className="font-11">VH-{i + 1}</h4>
                <div className="d-flex mt-2">
                    <button onClick={(evt) => onEditClicked(new ServiceCharge(x))} className="btn btn-blue-light">EDIT</button>
                    <button onClick={(evt) => onDeleteClicked(x, i)} className="btn btn-danger ml-2">DELETE</button>
                </div>
            </div>
            <div className="col-7">
                <h4 className="font-11">{x.name}</h4>
            </div>
            <div className="col-3">
            <h4 className="font-11">{x.amount.toCurrency()}</h4>
            </div>
        </div>)}
    </div>
}

export default StorageServices;
const Toggle = ({id, label, onCheck, checked, disabled = false}) => {
    return (
        <>
            <div className="toggle-container form-group d-flex justify-content-start align-items-center m-0" style={{opacity: disabled ? .3 : 1}}>
                <label className="switch m-0 d-flex align-items-center">
                    <input className='m-0' id={id} type="checkbox" checked={checked} onChange={(event) => onCheck(event.target.checked)}/>
                    <span className="slider round"></span>
                </label>
                <label htmlFor={id} className="ml-1 mb-0 cursor-pointer">{label}</label>
            </div>
        </>
    )
}

export default Toggle;

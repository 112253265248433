const GET_ALL_DRIVERS_STARTED = 'GET_ALL_DRIVERS_STARTED';
const GET_ALL_DRIVERS_SUCCESS = 'GET_ALL_DRIVERS_SUCCESS';
const GET_ALL_DRIVERS_ERROR = 'GET_ALL_DRIVERS_ERROR';

const GET_DRIVERS_TRACKING_STARTED = 'GET_DRIVERS_TRACKING_STARTED';
const GET_DRIVERS_TRACKING_SUCCESS = 'GET_DRIVERS_TRACKING_SUCCESS';
const GET_DRIVERS_TRACKING_ERROR = 'GET_DRIVERS_TRACKING_ERROR';
const RESET_DRIVERS_TRACKING = 'RESET_DRIVERS_TRACKING';


const DriverMapTypes = {
    GET_ALL_DRIVERS_STARTED,
    GET_ALL_DRIVERS_SUCCESS,
    GET_ALL_DRIVERS_ERROR,
    GET_DRIVERS_TRACKING_STARTED,
    GET_DRIVERS_TRACKING_SUCCESS,
    GET_DRIVERS_TRACKING_ERROR,
    RESET_DRIVERS_TRACKING
}

export default DriverMapTypes;

import {Master_Order} from "../master_order/master_order";
import {Inventory_Service, Inventory_Service_Input, Storage_Charge} from "../storage_order/storage_order";

export class Service_Charge {
    __typename?: "Service_charge"
    id?: number = null;
    master_order?: Master_Order = new Master_Order();
    amount?: number = 0;
    tax_amount?: number = 0;
    base_amount?: number = 0;
    name?: string = '';
    note?: string = '';
    voided?: boolean = false;
    amount_remaining?: number = 0;
    storage_charge?: Storage_Charge// = new Storage_Charge();
    inventory_service?: Inventory_Service// = new Inventory_Service();
    leg_id?: number = null;
    vehicle_order_id?: number = null;
    master_order_id?: number = null;
    stop_id?: number = null;
    payment_id?: number = null;
    refund_id?: number = null;
    tax_id?:number = null;

    constructor(x?: Service_Charge){
        if(x){
            this.id = x.id;
            this.master_order =  new Master_Order(x.master_order);
            this.amount = x.amount;
            this.tax_amount = x.tax_amount;
            this.base_amount = x.base_amount;
            this.name = x.name;
            this.note = x.note;
            this.voided = x.voided;
            this.amount_remaining = x.amount_remaining;
            this.storage_charge = new Storage_Charge(x.storage_charge );
            this.inventory_service = new Inventory_Service(x.inventory_service );
            this.leg_id = x.leg_id;
            this.vehicle_order_id = x.vehicle_order_id;
            this.master_order_id = x.master_order_id;
            this.stop_id = x.stop_id;
            this.payment_id = x.payment_id;
            this.refund_id = x.refund_id;
            this.tax_id = x.tax_id;           
        }
    }
}

export class New_Charge_Input {
    amount?: number = 0;
    tax_amount?: number = 0;
    base_amount?: number = 0;
    name?: string = '';

    constructor(x?: New_Charge_Input){
        if(x){
            this.amount = x.amount;
            this.tax_amount = x.tax_amount;
            this.base_amount = x.base_amount;
            this.name = x.name;            
        }
    }
}

export class Charge {
    __typename?: "Charge"
    name?: string = '';
    amount?: number = 0;

    constructor(x?: Charge){
        if(x){
            this.name = x.name;
            this.amount = x.amount;
        }
    }
}

export class New_Charge_Input_Cd {
    id?: number = null;
    amount?: number = 0;
    tax_amount?: number = 0;
    base_amount?: number = 0;
    name?: string = '';
    master_order_id?: number = null;
    vehicle_order_id?: number = null;
    inventory_service?: Inventory_Service_Input = new Inventory_Service_Input();

    constructor(x?: New_Charge_Input_Cd){
        if(x){
            this.id = x.id;
            this.amount = x.amount;
            this.tax_amount = x.tax_amount;
            this.base_amount = x.base_amount;
            this.name = x.name;
            this.master_order_id = x.master_order_id;
            this.vehicle_order_id = x.vehicle_order_id;
            this.inventory_service = new Inventory_Service_Input(x.inventory_service);         
        }
    }
}

export class Charge_Input {
    id?: number;
    charges?: Sh_Input[] = [];
    vehicle_orders?: Sc_Vehicle_Order[] = [];

    constructor(x?:Charge_Input) {
        if(x){
            this.id = x.id;
            this.charges = x.charges;
            this.vehicle_orders = x.vehicle_orders;
        }
    }
}

export class Sh_Input {
    id?: number = null;
    name?: string = '';
    amount?: number = 0;
    base_amount?: number = 0;
    tax_amount?: number = 0;

    constructor(x?: Sh_Input){
        if(x){
            this.id = x.id;
            this.name = x.name;
            this.amount = x.amount;
            this.base_amount = x.base_amount;
            this.tax_amount = x.tax_amount;            
        }
    }
}

export class Sc_Vehicle_Order {
    id?: number = null;
    charges?: Sh_Input[] = [];
    legs?: Sc_Leg_Input[] = [];

    constructor(x?: Sc_Vehicle_Order){
        if(x){
            this.id = x.id;
            this.charges = x.charges;
            this.legs = x.legs;
        }
    }
}

export class Sc_Leg_Input {
    id?: number = null;
    equipment_price?: number = 0;
    equipment_name?: string = '';
    charge_id?: number = null;
    stops?: Sc_Stop_Input[] = [];

    constructor (x?: Sc_Leg_Input){
        if(x){
            this.id = x.id;
            this.equipment_price = x.equipment_price;
            this.equipment_name = x.equipment_name;
            this.charge_id = x.charge_id;
            this.stops = x.stops?.map(z => z) || [];            
        }
    }
}

export class Sc_Stop_Input {
    id?: number = null;
    charge_id?: number = null;
    is_pickup?: boolean = false;
    nofail?: boolean = false;
    nofail_price?: number = 0;

    constructor(x?: Sc_Stop_Input) {
        if(x){
            this.id = x.id;
            this.charge_id = x.charge_id;
            this.is_pickup = x.is_pickup;
            this.nofail = x.nofail;
            this.nofail_price = x.nofail_price;            
        }
    }
}

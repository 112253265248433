import {ContactInput, ProfileInput, ProfileInputVerify, ResultWithLink} from "../customer_profile";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {StandardResult} from "../../leg/leg";

export const add_profile_to_customer_from_profile = (input:{profileid: number, name: string, email: string, role: string, contact: ContactInput[], title: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($profileid: Int!, $name: String!, $email: String, $role: String!, $contact: contactInput[], $title: String) {
      add_profile_to_customer_from_profile(profileid: $profileid, name: $name, email: $email, role: $role, contact: $contact, title: $title) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {profileid, name, email, role, contact, title} as 
  Promise<ResultWithLink>;
};

export const add_profile_to_customer = (input:{customerid: number, new_name: string, title: string, new_email: string, new_role: string, contact: ContactInput[], redirect_link: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($customerid: Int!, $new_name: String!, $title: String!, $new_role: String!, $new_email: String, $contact: contactInput[], $redirect_link: String) {
      add_profile_to_customer(customerid: $customerid, title: $title, new_name: $new_name, new_email: $new_email, new_role: $new_role, contact: $contact, redirect_link: $redirect_link) {
        message
        success
        link
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {customerid, new_name, title, new_email, new_role, contact, redirect_link} as 
  Promise<ResultWithLink>;
}

export const create_new_customer_profile = (input:{new_name: string, new_email: string, is_company: boolean, redirect_link: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($new_name: String, $new_email: String, $is_company: Boolean, $redirect_link: String) {
      create_new_customer_profile(new_name: $new_name, new_email: $new_email, is_company: $is_company, redirect_link: $redirect_link) {
        message
        success
        link
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {new_name, new_email, is_company, redirect_link} as 
  Promise<ResultWithLink>;
}

export const change_Customer_Profile_Password = (input:{profile_id: number, old: string, newpassword: string, confirmpassword: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($profile_id: Int, $old: String, $newpassword: String, $confirmpassword: String) {
      change_Customer_Profile_Password(profile_id: $profile_id old: $old, newpassword: $newpassword, confirmpassword: $confirmpassword) {
        message
        success
        link
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {profile_id, old, newpassword, confirmpassword} as 
  Promise<ResultWithLink>;
}

export const update_customer_profile = (input:{profile: ProfileInput}) : Promise<StandardResult> => {
  let mutation = gql`
    mutation($profile: ProfileInput) {
      update_customer_profile(profile: $profile) {
        message
        success
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {profile} as 
  Promise<StandardResult>;
}

export const verify_customer_profile = (input:{profile: ProfileInputVerify}) : Promise<boolean> => {
  let mutation = gql`
    mutation($profile: profileInputVerify) {
      verify_customer_profile(profile: $profile) {
        message
        success
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {profile} as 
  Promise<boolean>;
}

export const resend_verification = (input:{profile_id: number, redirect_link: string}) : Promise<string> => {
  let mutation = gql`
    mutation($profile_id: Int, $redirect_link: String) {
      verify_customer_profile(profile_id: $profile_id, redirect_link: $redirect_link) {
        message
        success
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {profile_id, redirect_link} as 
  Promise<string>;
}

export const create_customer_profile_from_quote = (input:{token: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($token: String) {
      create_customer_profile_from_quote(token: $token) {
        message
        success
        link
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {token} as 
  Promise<ResultWithLink>;
}

export const delete_contact = (input:{profile_id: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($profile_id: Int) {
      delete_contact(profile_id: $profile_id) {
        message
        success
        link
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {profile_id} as 
  Promise<ResultWithLink>;
}

export const reset_password = (input:{email: string, password_reset_link: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($email: String, $password_reset_link: String) {
      reset_password(email: $email, password_reset_link: $password_reset_link) {
        message
        success
        link
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {email, password_reset_link} as 
  Promise<ResultWithLink>;
}

export const update_password_from_token = (input:{token: string, new_password: string, confirm_password: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($token: String, $new_password: String, $confirm_password: String) {
      update_password_from_token(token: $token, new_password: $new_password, confirm_password: $confirm_password) {
        message
        success
        link
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {token, new_password, confirm_password} as 
  Promise<ResultWithLink>;
}

export const delete_customer_profile = (input:{profile_id: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($profile_id: Int) {
      delete_customer_profile(profile_id: $profile_id) {
        message
        success
        link
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {profile_id} as 
  Promise<ResultWithLink>;
}

export const unsubscribe_contact = (input:{value: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($value: String) {
      unsubscribe_contact(value: $value) {
        message
        success
        link
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {value} as 
  Promise<ResultWithLink>;
}


export const resubscribe_contact = (input:{value: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($value: String) {
      resubscribe_contact(value: $value) {
        message
        success
        link
        id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {value} as 
  Promise<ResultWithLink>;
}
import React,{useState,useRef,useEffect,ReactNode} from 'react'
import './MenuOption.scss'

type MenuOptionProps ={
     nodeContainer:any,
     visible:boolean,
     handleMenuOPtionClick:()=>void,
     children:ReactNode

}

const MenuOption =(props:MenuOptionProps)=>{
    

    useEffect(() => {
        let handleClickOut = (event: any) => {
            if (!props.nodeContainer.current.contains(event.target)) {
                props.handleMenuOPtionClick()
            }
        }
        document.addEventListener("mousedown", handleClickOut)
      
        return () => {
            document.removeEventListener("mousedown", handleClickOut)
        }
      
    }, []);

    // console.log(props.children)

      
    return(
        <>
            <div className={`${props.visible?"d-block":"d-none"} menu-option`}>
                 {props.children}
            </div>
        </>
    )
}

export default MenuOption

import './DriverForm.scss';
import FormGroup from "../../../../components/FormGroup/FormGroup";
import {useEffect, useRef, useState} from "react";
import ProfileSelectField from "../ProfileSelectField/ProfileSelectField";
import {Employee} from "../../../../classes/classes";
import {VeloxProfile} from "../VeloxProfileForm/VeloxProfileForm";
import { Velox_driver } from '../../../../classes/classes.backend';

export class IDriver {
    id?: number = 0;
    name?: string = '';
    email?: string = '';
    phone?: string = '';
    employee?: Employee = new Employee();
    veloxProfile?: Velox_driver = new Velox_driver();
    samsara_truck?: any = null;
    equipment?: any = null;

    constructor(props?: any) {
        if (props) {
            this.id = props.id ?? 0;
            this.name = props.name ?? '';
            this.email = props.email ?? '';
            this.phone = props.name ?? '';

            this.employee = new Employee(props.employee);
            this.veloxProfile = new VeloxProfile(props.veloxProfile);
            this.samsara_truck = props.samsara_truck;
            this.equipment = props.equipment;
        }
    }
}


const DriverForm = ({driver, onCancel, onSubmit}: {driver: IDriver, onCancel, onSubmit}) => {
    console.log(driver);

    const driverRef = useRef<IDriver>(new IDriver());
    const [currentDriver, setCurrentDriver] = useState<IDriver>(driver);
    const[phoneIndex,setPhoneIndex]=useState<number>(0)
    const[emailindex,setEmailIndex]=useState<number>(0)


    useEffect(() => {
        if (driver)
        {
            
            setCurrentDriver({...driver});
        }

    }, [driver]);

    useEffect(() => {
        driverRef.current = new IDriver(currentDriver);
    }, [currentDriver]);


    const handleOnSubmitForm = (wasSubmitted) => {
        if (!wasSubmitted) {
            onCancel();
            return;
        }

        // if (currentDriver.name === '' ||
        //     currentDriver.phone === '' || currentDriver.email === '') {
        //     return;
        // }

        console.log(currentDriver);

        onSubmit({...currentDriver});
    }

    return(
        <>
            <div className="flex-1-container" >
               
                        <div className="col-12 mb-3 d-flex row">
                            <span className="font-14 font-medium mb-0" >Link Driver</span> 
                        </div>
               
                        <div className="flex-1-container">
                        <div >

<div className="col-12">

    <p className="font-14 font-medium mt-3 mb-3">Link profiles</p>

    <ProfileSelectField showListUp={false}
                          data2={currentDriver.veloxProfile}
                        type={'velox'}
                        onProfileSelected={({veloxProfile}) => {
                            setCurrentDriver({...currentDriver, veloxProfile: veloxProfile});
                        }} />

    <ProfileSelectField showListUp={true}
                        data2={currentDriver.employee}
                        type={'employee'}
                        onProfileSelected={({employeeProfile}) => {
                            setCurrentDriver({...currentDriver, employee: employeeProfile})

                        }} />

    <ProfileSelectField 
        showListUp
        data2={currentDriver.samsara_truck}
        type={'samsara'}
        onProfileSelected={({samsaraProfile}) => {
            console.log(samsaraProfile)
            setCurrentDriver({...currentDriver, samsara_truck: samsaraProfile})
        }}
    />

    <ProfileSelectField 
        showListUp
        data2={currentDriver.equipment}
        type={'equipment'}
        onProfileSelected={({equipment}) => {
            // console.log(samsaraProfile)
            setCurrentDriver({...currentDriver, equipment: equipment})
            console.log({...currentDriver, equipment: equipment});
        }}
    />
</div>

{
    ((currentDriver.veloxProfile?.id || 0) != 0) &&
    <>
        <div className="col-12 mt-4">
            <p className="font-14 font-medium mb-3">Driver information</p>
        </div>

        <div className="col-12 form-group">
            <label className="">Driver Name: <span className='d-block pl-3 font-14 mt-1'>{currentDriver?.veloxProfile.name}</span></label>
            <label className="">Driver Email: <span className='d-block pl-3 font-14 mt-1'>{currentDriver?.veloxProfile.contacts.find(x=>x.type == 'email')?.value || ''}</span></label>
            <label className="">Driver Phones: <span className='d-block pl-3 font-14 mt-1'>{currentDriver?.veloxProfile.contacts.filter(x=>x.type == 'phone').map(x=>x.value)?.join(', ')}</span></label>



        </div>
    </>
}
</div>
                        </div>


            
                    <div className="row mt-3 d-flex">
                        <div className="col-6 text-right">
                            <button className="btn w-100 py-2 bg-white rounded-pill font-12 text-light-blue"
                                    onClick={() => handleOnSubmitForm(false)}>CANCEL</button>
                        </div>

                        <div className="col-6 text-right">
                            <button className="btn w-100 py-2 btn-blue-light rounded-pill font-12"
                                    onClick={() => handleOnSubmitForm(true)}>
                                    Link Driver
                            </button>
                        </div>
               
                </div>
                </div>

         
     
        </>
    );
}

export default DriverForm;

import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import Helper from "../../helper/Helper";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import CarrierQueries from "../../graphql/query/Carrier";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {GraphqlService} from "../../services/graphql.service";
import DriverQueries from "../../graphql/query/Driver";
import ReactModal from "react-modal";
import CompanyAccountInfoForm from "../../components/CompanyAccountInfoForm/CompanyAccountInfoForm";
import './CarriersProfilePage.scss';
import RegistrationInfoForm from "../../components/RegistrationInfoForm/RegistrationInfoForm";
import InsuranceInfoForm from "../../components/InsuranceInfoForm/InsuranceInfoForm";
import useLoading from "../../hooks/useLoading/useLoading";
import {useToasts} from "react-toast-notifications";
import DriverCardJob from "../../components/DriverCardJob/DriverCardJob";
import {Address, Driver} from "../../classes/classes";
import {useDispatch} from "react-redux";
import DriverScheduleComponent from "../../components/DriverSchedule/DriverSchedule";
import DriverSettingForm from "../../components/DriverSettingForm/DriverForm";
import CarrierStatments from "./CarrierStatments/CarrierStatments";
import {gql} from "apollo-boost";
import useMessageBox from "../../hooks/useMessageBox/useMessageBox";
import Loading from "../../components/Loading/Loading";
import moment from 'moment';
import TaxInfo from "../../components/TaxInfo/TaxInfo";
import {CarrierProfileHelper} from "../../helper/CarrierProfileHelper";

enum ReportState {
    NONE,
    OPEN_ORDERS,
    PAID_ORDERS
}

export class CarrierContact {
    name: string = '';
    phone: string = '';
    email: string = '';
    fax?: string = '';

    constructor(props?: CarrierContact) {
        if (props) {
            this.name = props.name;
            this.phone = props.phone;
            this.email = props.email;
            this.fax = props.fax || '';
        }
    }
}

export class CarrierRegistration {
    mc_number: string = '';
    dot_number: string = '';
    uploaded_documents: FileDocument[] = [];

    constructor(props?: CarrierRegistration) {
        if (props) {
            this.mc_number = props.mc_number;
            this.dot_number = props.dot_number || '';
            this.uploaded_documents = props.uploaded_documents?.map(x => new FileDocument(x)) || [];
        }
    }
}

export class FileDocument {
    uid: string = '';
    name: string = '';
    constructor(props?: FileDocument) {
        if (props) {
            this.uid = props.uid;
            this.name = props.name;
        }
    }
}

export class CarrierTaxInfo {
    name: string = '';
    business_name: string = '';
    type: string = '';
    address: Address = new Address();
    ssn: string = '';
    ein: string = '';
    signature: string = '';
    time_signed: Date  = new Date();
    uploaded_documents: FileDocument[] = [];

    constructor(props?: CarrierTaxInfo)
    {
        if (props)
        {
            this.name = props.name ?? '';
            this.business_name = props.business_name ?? '';
            this.type = props.type || '';
            if (props.address?.street)
                this.address = new Address(props.address);
            this.ssn = props.ssn || '';
            this.ein = props.ein || '';
            this.signature = props.signature;
            if (props.time_signed)
                this.time_signed = new Date(props.time_signed);
            this.uploaded_documents = props.uploaded_documents?.map(x => new FileDocument(x)) || [];
        }
    }
}

export class CarrierInsurance {
    name: string = '';
    policy_number: string = '';
    agent: CarrierContact = new CarrierContact();
    liability_coverage: number = 0;
    damage_coverage: number = 0;
    deductible: number = 0;
    expiration: Date;
    uploaded_documents: FileDocument[] = [];

    constructor(props?: CarrierInsurance) {
        if (props) {
            this.name = props.name;
            this.policy_number = props.policy_number;
            this.agent = new CarrierContact(props.agent);
            this.liability_coverage = props.liability_coverage;
            this.damage_coverage = props.damage_coverage;
            this.deductible = props.deductible;
            if (props.expiration)
                this.expiration = new Date(props.expiration);
            this.uploaded_documents = props.uploaded_documents?.map(x => new FileDocument(x)) || [];
        }
    }
}

class PaymentTerms {
    carrier_id: number;
    broker_id: number;
    terms: string;

    constructor(props?: any) {
        if (props) {
            this.carrier_id = props.carrier_id;
            this.broker_id = props.broker_id;
            this.terms = props.terms;
        }
    }
}

export class CarrierInfo {
    id: number;
    name: string = '';
    payment_terms: PaymentTerms;
    address: Address = new Address();
    code: string = '';
    liability_disclaimer: string = '';
    internal_note: string = '';
    contact: CarrierContact = new CarrierContact();
    carrier_insurance: CarrierInsurance = new CarrierInsurance();
    carrier_registration: CarrierRegistration = new CarrierRegistration();
    drivers: any[] = [];


    constructor(props?: any) {
        if (props) {
            this.id = props.id;
            this.name = props.name;
            this.code = props.code;
            this.liability_disclaimer = props.liability_disclaimer;
            this.internal_note = props.internal_note;
            this.contact = new CarrierContact(props?.contact);
            this.carrier_insurance = new CarrierInsurance(props?.carrier_insurance);
            this.carrier_registration = new CarrierRegistration(props?.carrier_registration);
            this.drivers = props.drivers ? props.drivers.map(x => ({...x})) : [];
            this.address = new Address(props.address);

            if (props.payment_terms) this.payment_terms = new PaymentTerms(props.payment_terms);
        }
    }
}

export const deleteMutationsCarrierProfile = {
    'registration': gql`mutation($carrier_id:Int, $uid:String){
        remove_velox_carrier_registration_file(carrier_id:$carrier_id, uid:$uid){
          message
          success
        }
      } 
      `,
    'insurance': gql`mutation($carrier_id:Int, $uid:String){
        remove_velox_carrier_insurance_file(carrier_id:$carrier_id, uid:$uid){
          message
          success
        }
      } 
      `,
    'tax info': gql`mutation($carrier_id:Int, $uid:String){
        remove_velox_carrier_tax_info_file(carrier_id:$carrier_id, uid:$uid){
          id
          message
          success
        }
      }`
}

export const mutationsCarrierProfile = {
    'registration': gql`mutation($carrier_id: Int, $file: Upload) {
        upload_velox_carrier_registration_file(
          carrier_id: $carrier_id
          registration_file: $file
        ) {
          id
          message
          success
        }
      }`,
    'insurance': gql`mutation($carrier_id: Int, $file: Upload) {
        upload_velox_carrier_insurance_file(
          carrier_id: $carrier_id
          insurance_file: $file
        ) {
          id
          message
          success
        }
      }`,
    'tax info': gql`mutation($carrier_id:Int, $file:Upload){
        upload_velox_carrier_tax_info_file(carrier_id:$carrier_id, tax_info_file:$file){
          id
          message
          success
        }
      }`
}

const CarriersProfilePage = (props) => {
    const COMPANY = 'COMPANY'
    const INSURANCE = ' INSURANCE'
    const REGISTRATION = 'REGISTRATION'
    const TAX_INFO = 'TAX_INFO';

    const loading = useLoading();
    const toast = useToasts();

    const dispatch = useDispatch();

    const [reportState, setReportState] = useState(ReportState.NONE);

    const heightModal = 600;
    const messageBox = useMessageBox();
    const [tab, setTab] = useState('');
    const [open, setOpen] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [carrier, setCarrier] = useState(undefined);
    const [openModal, setOpenModal] = useState(false);

    // Driver handling
    const [showDriverModal, setShowDriverModal] = useState(undefined);
    const [editDriver, setEditDriver] = useState(null);

    const [componentsShown, setComponentsShown] = useState(true);
    const [selected, setSelected] = useState({id: null, leg_id: null, velox_order_id: null});
    const [refreshDriverList, setRefreshDriverList] = useState(false);
    const [base64, setBase64] = useState(null);

    const [loadingTaxInfo, setLoadingTaxInfo] = useState(false);
    const [loadingRegistration, setLoadingRegistration] = useState(false);
    const [loadingInsurance, setLoadingInsurance] = useState(false);
    const [loadingDisclaimer, setLoadingDisclaimer] = useState(false);

    const [carrier_insurance, setCarrierInsurance] = useState(new CarrierInsurance());
    const [carrier_registration, setCarrierRegistration] = useState(new CarrierRegistration());
    const [carrier_tax_info, setCarrierTaxInfo] = useState(new CarrierTaxInfo());
    const [tempLiabilityDisclaimer, setTempLiabilityDisclaimer] = useState('');
    const carrier_id = +props.match.params.id;
    const [carrierLoading, setCarrierLoading] = useState(false);

    useEffect(() => {
        if(!open){
            loadData();
        }
    }, [open]);

    const getCarrier = async () => {
        try {
            // loading.open('loading...');
            setCarrierLoading(true);
            const result = await GraphqlService.SendQuery(CarrierQueries.GET_CARRIER, {carrier_id: +props.match.params.id});

            // await driverByCarrier(+props.match.params.id);
         
            

            if (result) {
                result.address.country = 'United States';
                setCarrier(new CarrierInfo(result));
                setCarrierLoading(false);
            } else
                throw new Error('Carrier not found');

                
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            setCarrierLoading(false);
        }
    }

    
    const handleDisclaimer = () => {
        try {

        } catch (ex) {

        }
    }

    const fileRef = useRef<HTMLInputElement>();
    const fileRef2 = useRef<HTMLInputElement>();
    const fileRef3 = useRef<HTMLInputElement>();

    async function handleInputChange(key: 'registration' | 'insurance' | 'tax info' | 'liability disclaimer', evt: ChangeEvent<HTMLInputElement>) {
        try {
            const file = evt.target.files[0];
            if (key == 'registration')
                fileRef.current.value = '';
            else if (key == 'insurance')
                fileRef2.current.value = '';
            else if (key == 'tax info')
                fileRef3.current.value = '';

            loading.open(`Uploading ${key} file: ${file.name}`);
            const data = await GraphqlService.SendMutation(mutationsCarrierProfile[key], {carrier_id: carrier.id, file});
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            toast.addToast(data?.message, {appearance: 'success',autoDismiss: true});
            loadData(key);
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error',autoDismiss: true});
        }
        finally {
            loading.close();
        }
    }

    function loadData(key: 'registration' | 'insurance' | 'tax info' | 'liability disclaimer' | 'carrier' | '' = '') {
        if (key == 'carrier' || key == '') getCarrier();
        if (key == 'registration' || key == '') loadCarrierRegistration().then();
        if (key == 'insurance' || key == '') loadCarrierInsurance().then();
        if (key == 'tax info' || key == '') loadCarrierTaxInfo().then();
    }

    async function loadCarrierTaxInfo() {
        try {
            const query = gql`
            query ($carrier_id: Int) {
                get_velox_carrier_tax_information(carrier_id: $carrier_id) {
                  carrier_id
                  id
                  name
                  ssn
                  ein
                  business_name
                  type 
                  signature
                  time_signed
                  address {
                    street
                    city
                    state
                    postal_code
                    country
                    street_number

                  }
                  uploaded_documents{
                    uid
                    name
                  }
                }
              }
            `;
            setLoadingTaxInfo(true);
            const data = await GraphqlService.SendQuery(query, {carrier_id});
            
            setCarrierTaxInfo(new CarrierTaxInfo(data));
        } catch (ex) {

        } finally {
            setLoadingTaxInfo(false);
        }
    }

    async function loadCarrierRegistration() {
        try {
            const query = gql`
            query ($carrier_id: Int) {
                get_velox_carrier_registration(carrier_id: $carrier_id) {
                  id
                  mc_number
                  dot_number
                  carrier_id
                  uploaded_documents{
                    uid
                    name
                  }
                }
              } 
              
            `;
            setLoadingRegistration(true);
            const data = await GraphqlService.SendQuery(query, {carrier_id});
            setCarrierRegistration(new CarrierRegistration(data));
        } catch (ex) {

        } finally {
            setLoadingRegistration(false);
        }
    }

    async function loadCarrierInsurance() {
        try {
            if((+Helper.Masks.IntNumbers(`${carrier_id}`)) < 1){
                throw new Error('Missing carrier_id in loadCarrierInsurance');
            }
            const query = gql`
            query  ($carrier_id: Int) {
                get_velox_carrier_insurance (carrier_id: $carrier_id) {
                  id
                  name
                  policy_number
                  agent {
                    name
                    email
                    phone
                    fax
                  }
                  liability_coverage
                  damage_coverage
                  deductible
                  expiration
                  carrier_id
                  uploaded_documents{
                    uid
                    name
                  }
                }
              }
              
            `;
            setLoadingInsurance(true);
            const data = await GraphqlService.SendQuery(query, {carrier_id});
            setCarrierInsurance(new CarrierInsurance(data));
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        } finally {
            setLoadingInsurance(false);
        }
    }

    async function deleteFile(key: 'registration' | 'insurance' | 'tax info' | 'liability disclaimer', document: FileDocument) {
        try {
            loading.open(`Deleting ${key} document: ${document.name}...`);
            const data = await GraphqlService.SendMutation(deleteMutationsCarrierProfile[key], {carrier_id: carrier.id, uid: document.uid});
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            loading.close();
            toast.addToast(data.message, {appearance: 'success',autoDismiss: true});
            loadData(key);
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error',autoDismiss: true});
        }
    }

    async function removeFile(key, document: FileDocument) {
        messageBox.open({
            title: 'Delete file: ' + document.name,
            message: `Are you sure you want to delete this file?, you won't be able to revert this!`,
            buttons: [
                {
                    text: 'Cancel',
                    css: 'btn btn-shy-light'
                },
                {
                    text: 'Confirm',
                    css: 'btn btn-blue-light',
                    action: (data) => deleteFile(key, document)
                }
            ]
        })
    }

    async function previewFile(doc: FileDocument) {
        await CarrierProfileHelper.previewFile(doc);
    }

    function checkIfExpiringSoon(day: Date) {
        return moment(day).diff(moment(), 'days') < 15;
    }

    function checkIfExpired(day: Date){
        return new Date(day).getTime() < new Date().getTime();
    }


    const carrierRegistration = () => {
        return(
          <>
              <input style={{display: 'none'}} multiple={false} onChange={evt => handleInputChange('registration', evt)} type='file' ref={fileRef} />
              <div
                className="col-12 d-flex justify-content-between align-items-center mb-2">
                  <h2 className={`font-12`}>Registration</h2>
                  <button onClick={()=>{setOpen(true);setTab(REGISTRATION)}} className={`btn btn-blue-light py-1`}>Edit</button>
              </div>
              {loadingRegistration && <Loading /> }
              {!loadingRegistration && <div className="">

                  <div  onClick={(evt) => {setOpen(true); setTab(REGISTRATION)}} className="col-12 mt-2 cursor-pointer">
                      <div className="row">
                          <div className="col-6">
                              <span className={'label-span'}>DOT</span>
                              <p className={'value-p'}>{carrier_registration.dot_number }</p>
                          </div>
                          <div className="col-12 my-1">
                              <div className="col-6">
                                  <span className={'label-span'}>MC</span>
                                  <p className={'value-p'}>{carrier_registration.mc_number }</p>
                              </div>
                          </div>

                      </div>
                      {(carrier_registration.mc_number?.trim() || carrier_registration?.dot_number?.trim() || '') == '' && <h4 className='text-red font-12 text-left cursor-pointer' onClick={(evt) => {setOpen(true);setTab(REGISTRATION)}}>Enter DOT (Department of Transportation) or MC (Motor Carrier) number</h4>}
                  </div>

                  <div
                    className="col-12 d-flex justify-content-between align-items-center my-2">
                      <span className={`label-span`}>Files</span>
                      <span onClick={(evt) => fileRef.current?.click()} className={`btn btn-blue-light py-1`}>Upload</span>
                  </div>
                  <div className="col-12">
                      {carrier_registration.uploaded_documents.length == 0 && <h4 className='text-red font-12 text-left cursor-pointer' onClick={(evt) => fileRef.current?.click()}>Upload a document showing your DOT (Department of Transportation) or MC (Motor Carrier) number</h4>}
                      {carrier_registration.uploaded_documents.map((document, index) =>  <div style={{maxWidth: '100%'}} className={"document-row align-items-center" + (index == carrier_registration.uploaded_documents.length - 1 ? '' : ' mb-3')}>
                          <h4 style={{textOverflow: 'clip', overflow: 'auto', whiteSpace: 'nowrap'}} className="font-12">{document.name}</h4>
                          <div className="d-flex">
                              <h4 className="font-16 mr-2 cursor-pointer text-light-blue text-right" onClick={(evt) => previewFile(document)}>
                                  <i className='fas fa-eye'></i>
                              </h4>
                              <h4 className="font-16 cursor-pointer text-right" onClick={(evt) => removeFile('registration', document)}>
                                  <i className='fas fa-times'></i>
                              </h4>
                          </div>
                      </div>)}
                  </div>
              </div>}
          </>
        );
    }

    const carrierInsurance = () => {
        return(
          <>
              <input type='file' style={{display: 'none'}} ref={fileRef2} onChange={evt => handleInputChange('insurance', evt)} />
              <div className="col-12 bg-white main-radius ">
                  <div className="">
                      <div
                        className="col-12 d-flex justify-content-between align-items-center mb-2">
                          <h2 className={`font-12`}>Insurance</h2>
                          <button onClick={()=>{setOpen(true);setTab(INSURANCE)}} className={`btn btn-blue-light py-1`}>Edit</button>
                      </div>
                      {!loadingInsurance && <div className="">
                          <div className='cursor-pointer' onClick={(evt) => {setOpen(true); setTab(INSURANCE)}}>
                              <div className="col-12 mt-2">
                                  <div className="row">
                                      <div className="col-6">
                                          <span className={'label-span'}>Insurance company name*</span>
                                          {(carrier_insurance.name) ?
                                            <p className={'value-p' + (!carrier_insurance?.name ? ' text-red' : '')}>{carrier_insurance.name}</p>
                                            :
                                            <h4 className='text-red font-12 text-left cursor-pointer'>Enter Insurance Name</h4>
                                          }
                                      </div>
                                      <div className="col-6">
                                          <span className={'label-span'}>Policy #*</span>
                                          {(carrier_insurance.policy_number) ?
                                            <p className={'value-p' + (!carrier_insurance?.name ? ' text-red' : '')}>{carrier_insurance.policy_number}</p>
                                            :
                                            <h4 className='text-red font-12 text-left cursor-pointer'>Enter Policy Number</h4>
                                          }
                                      </div>
                                  </div>
                              </div>
                              {(!carrier_insurance.agent.name || !carrier_insurance.agent.phone || !carrier_insurance.agent.email) ?
                                <>
                                    <div className="col-12 mt-2">
                                        <span className={'label-span'}>Agent*</span>
                                        <h4 className='text-red font-12 text-left cursor-pointer'>Enter insurance Agent's info</h4>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-12 mt-2">
                                        <span className={'label-span'}>Agent*</span>
                                        <p className={'value-p mb-0' + (!carrier_insurance?.agent?.name ? ' text-red' : '')}>{carrier_insurance.agent.name || 'Enter Agent Name'}</p>
                                    </div>
                                    <div className="col-12">
                                        <span className={'phone-span'}>{carrier_insurance.agent.phone}</span>
                                        <span className={'email-span'}>{carrier_insurance.agent.email}</span>
                                        <span className={'email-span'}>{carrier_insurance.agent.fax || '-'}</span>
                                    </div>
                                </>}
                              <div className="col-12 mt-2">
                                  <div className="row">
                                      <div className="col-6">
                                          <span className={'label-span'}>Liability coverage*</span>
                                          <p className={'value-p' + (carrier_insurance.liability_coverage  > 0 ? '' : ' text-red')}>{carrier_insurance.liability_coverage > 0 ? carrier_insurance.liability_coverage.toCurrency('$', true, false) : 'Value must be greater than zero'}</p>
                                      </div>
                                      <div className="col-6">
                                          <span className={'label-span'}>Damage coverage*</span>
                                          <p className={'value-p' + (carrier_insurance.damage_coverage  > 0 ? '' : ' text-red')}>{carrier_insurance.damage_coverage > 0 ? carrier_insurance.damage_coverage.toCurrency('$', true, false) : 'Value must be greater than zero'}</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-12 mt-2">
                                  <div className="row">
                                      <div className="col-6">
                                          <span className={'label-span'}>Deductible*</span>
                                          <p className={'value-p' + (carrier_insurance.deductible  > 0 ? '' : ' text-red')}>{carrier_insurance.deductible > 0 ? carrier_insurance.deductible.toCurrency('$', true, false) : 'Value must be greater than zero'}</p>
                                      </div>
                                      <div className="col-6">
                                                        <span
                                                          className={'label-span'}>Expiration date(dd/mm/yyyy)</span>
                                          <p className={'value-p' + (checkIfExpiringSoon(carrier_insurance.expiration) ? ` text-${checkIfExpired(carrier_insurance.expiration)? 'red':'gold'}` : '')}>{carrier_insurance.expiration ? Helper.FORMAT.USDate(carrier_insurance.expiration, 'do') + (checkIfExpiringSoon(carrier_insurance.expiration) ? ` (${checkIfExpired(carrier_insurance.expiration) ? 'Expired' : 'Expiring soon'})` : '') : '-'}</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div
                            className="col-12 d-flex justify-content-between align-items-center my-2">
                              <span className={`label-span`}>Files</span>
                              <span onClick={(evt) => fileRef2.current?.click()} className={`btn btn-blue-light py-1`}>Upload</span>
                          </div>
                          <div className="col-12">
                              {carrier_insurance.uploaded_documents.length == 0 && <h4 className='text-red font-12 text-left cursor-pointer' onClick={(evt) => fileRef2.current?.click()}>Upload a document showing your insurance</h4>}
                              {carrier_insurance.uploaded_documents.map((document, index) =>  <div style={{maxWidth: '100%'}} className={"document-row align-items-center" + (index == carrier_insurance.uploaded_documents.length - 1 ? '' : ' mb-3')}>
                                  <h4 style={{textOverflow: 'clip', overflow: 'auto', whiteSpace: 'nowrap'}} className="font-12">{document.name}</h4>
                                  <div className="d-flex ">
                                      <h4 className="font-16 mr-2 cursor-pointer text-light-blue text-right" onClick={(evt) => previewFile(document)}>
                                          <i className='fas fa-eye'></i>
                                      </h4>
                                      <h4 className="font-16 cursor-pointer text-right" onClick={(evt) => removeFile('insurance', document)}>
                                          <i className='fas fa-times'></i>
                                      </h4>
                                  </div>
                              </div>)}
                          </div>
                      </div>}
                      {loadingInsurance && <Loading />}
                  </div>
              </div>
          </>
        );
    }

    const taxInfo = () => {
        return(
          <>
              <input type='file'
                     style={{display: 'none'}}
                     ref={fileRef3}
                     onChange={evt => handleInputChange('tax info', evt)} />
              <div className="col-12 ">
                  <div className="">
                      <div className="col-12 d-flex justify-content-between align-items-center mb-2">
                          <h2 className={`font-12`}>Tax Info</h2>
                          <button onClick={()=>{setOpen(true);setTab(TAX_INFO)}} className={`btn btn-blue-light py-1`}>Edit</button>
                      </div>
                      {!loadingTaxInfo && <div className="">


                          <div className='cursor-pointer' onClick={(evt) => {setOpen(true); setTab(TAX_INFO)}}>
                              <div className="col-12 mt-2">
                                  <div className="row">
                                      <div className="col-6">
                                          <span className={'label-span'}>Name*</span>
                                          <p className={'value-p' + (carrier_tax_info.name.trim() == '' ? ' text-red' : '')}>{carrier_tax_info.name || 'Enter Tax Name'}</p>
                                      </div>
                                      <div className="col-6">
                                          <span className={'label-span'}>Business Name</span>
                                          <p className={'value-p'}>{carrier_tax_info.business_name || '-'}</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-12 mt-2">
                                  <span className={'label-span'}>Type*</span>
                                  <p className={'value-p mb-0' + (carrier_tax_info.type.trim() == '' ? ' text-red' : '')}>{carrier_tax_info.type || 'Enter Business Type'}</p>
                              </div>
                              <div className="col-12 mt-2">
                                  <span className={'label-span'}>TIN*</span>
                                  {(!carrier_tax_info.ssn && !carrier_tax_info.ein) ?
                                    <h4 className='text-red font-12 text-left cursor-pointer'>Enter EIN/SSN</h4>
                                    :
                                    <>{(carrier_tax_info.ssn) ?
                                      <h4 className={' font-12'}>SSN: <span className={carrier_tax_info.ssn.trim() == '' ? 'text-red' : ''}>{carrier_tax_info.ssn ? 'xxx-xx-' + carrier_tax_info.ssn.substring(carrier_tax_info.ssn?.length - 4) : (carrier_tax_info.ein ? '' : 'Enter SSN')}</span></h4>
                                      :
                                      <h4 className={' font-12'}>EIN: <span className={carrier_tax_info.ein.trim() == '' ? 'text-red' : ''}>{carrier_tax_info.ein ? 'xx-xxx' + carrier_tax_info.ein.substring(carrier_tax_info.ein?.length - 4) : (carrier_tax_info.ssn ? '' : 'Enter EIN')}</span></h4>
                                    }
                                    </>
                                  }
                              </div>

                              <div className="col-12 mt-2">
                                  <span className={'label-span'}>Address*</span>
                                  {(carrier_tax_info.address.street?.trim() || '') != '' ? <>
                                      <p className={'value-p mb-0'}>{carrier_tax_info.address.street_number} {carrier_tax_info.address.street}</p>
                                      <p className={'value-p mt-1'}>{carrier_tax_info.address.city} {carrier_tax_info.address.state}, {carrier_tax_info.address.postal_code}</p>
                                      {(carrier_tax_info.address.country != 'United States' && carrier_tax_info.address.country != 'United States') && <p className={'value-p mt-1'}>{carrier_tax_info.address.country}</p>}
                                  </> : <h4 className='text-red font-12 text-left cursor-pointer'>Enter Address</h4>}

                              </div>
                          </div>

                          <div
                            className="col-12 d-flex justify-content-between align-items-center my-2">
                              <span className={`label-span`}>Files</span>
                              <span onClick={(evt) => fileRef3.current?.click()} className={`btn btn-blue-light py-1`}>Upload</span>
                          </div>
                          <div className="col-12">
                              {carrier_tax_info.uploaded_documents.length == 0 && <h4 className='text-red font-12 text-left cursor-pointer' onClick={(evt) => fileRef3.current?.click()}>Upload a document showing your tax info</h4>}
                              {carrier_tax_info.uploaded_documents.map((document, index) =>  <div style={{maxWidth: '100%'}} className={"document-row align-items-center" + (index == carrier_tax_info.uploaded_documents.length - 1 ? '' : ' mb-3')}>
                                  <h4 style={{textOverflow: 'clip', overflow: 'auto', whiteSpace: 'nowrap'}} className="font-12">{document.name}</h4>
                                  <div className="d-flex">
                                      <h4 className="font-16 mr-2 cursor-pointer text-light-blue text-right" onClick={(evt) => previewFile(document)}>
                                          <i className='fas fa-eye'></i>
                                      </h4>
                                      <h4 className="font-16 cursor-pointer text-right" onClick={(evt) => removeFile('tax info', document)}>
                                          <i className='fas fa-times'></i>
                                      </h4>
                                  </div>
                              </div>)}
                          </div>
                      </div>}
                      {loadingTaxInfo && <Loading />}
                  </div>
              </div>
          </>
        );
    }


    return (
        <>
            <div className="flex-1-container">
                <div className="row title-section-base">
                    <div className="col-12 font-medium mb-3">Carrier Profile</div>
                </div>

                <div className='flex-1-container row-container'>
                    <div className="col-4 col-sm-12 d-flex">
                        <div className="flex-1-container">

                            <div className="col-12 bg-white p-3 main-radius mb-2">
                                <div className="row">
                                    <div
                                        className="col-12 d-flex justify-content-between align-items-center mb-2">
                                        <span className={`subtitle-section`}>Reports</span>
                                        {/* <span onClick={() => {
                                            setOpen(true);
                                            setTab(INSURANCE)
                                        }}
                                              className={`edit cursor-pointer no-select`}>Edit</span> */}
                                    </div>
                                    <div className="d-flex">
                                        <p onClick={evt => setReportState(ReportState.PAID_ORDERS)} style={{textDecoration: 'underline'}} className="font-10 font-medium cursor-pointer text-blue-light mr-2">Paid Orders</p>
                                        <p onClick={evt => setReportState(ReportState.OPEN_ORDERS)} style={{textDecoration: 'underline'}} className="font-10 font-medium cursor-pointer text-red">Open Orders</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 bg-white p-3 main-radius mb-2">
                                <div className="row">
                                    <div
                                        className="col-12 d-flex justify-content-between align-items-center mb-2">
                                        <span className={`subtitle-section`}>Company</span>
                                        <button onClick={() => {
                                            setOpen(true);
                                            setTab(COMPANY)
                                        }}
                                              className={`btn btn-blue-light py-1`}>Edit</button>
                                    </div>
                                    {!carrierLoading && <>
                                    <div className="col-12 mt-2">
                                        <span className={'label-span'}>Name*</span>
                                        <p className={'value-p'}>{carrier?.name}</p>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <span className={'label-span'}>Terms*</span>
                                        <p className={'value-p'}>{carrier?.payment_term?.terms || '-'}</p>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <span className={'label-span'}>Contact*</span>
                                        <p className={'value-p mb-0'}>{carrier?.contact?.name || '-'}</p>
                                    </div>
                                    <div className="col-12">
                                        {/* <span className={'username-span'}>Admin</span> */}
                                        <span className={'phone-span'}>{carrier?.contact?.phone || '-'}</span>
                                        <span className={'email-span'}>{carrier?.contact?.email || '-'}</span>
                                    </div>
                                    </>}
                                    {carrierLoading && <div className="col-12">
                                        <Loading />
                                    </div>}
                                </div>
                            </div>

                            <div className="col-12 bg-white p-3 main-radius mb-2">
                                { carrierRegistration() }
                            </div>
                            <div className="col-12 bg-white p-3 main-radius mb-2">
                                { carrierInsurance() }
                            </div>

                            <div className="col-12 bg-white p-3 main-radius">
                                { taxInfo() }
                            </div>

                        </div>
                    </div>
                    <div className="col-4 col-sm-12 d-flex main-radius p-3 bg-white">
                        <div className="d-flex flex-1-container">
                            
                            
                            <div className="flex-1-container">
                                <DriverCardJob
                                               onAddClicked={(evt) => {
                                                setShowDriverModal(new Driver());

                                               }}
                                               edit
                                               gridOne defaultCarrier={{id: +props.match.params.id, name: ''}}
                                               showCarrier={false}
                                               onLoadedData={()=>{setRefreshDriverList(false)}}
                                               refresh={refreshDriverList}
                                               driverSelected={editDriver}
                                               onEditClicked={(driver)=>{
                                                setShowDriverModal(driver);
                                            }}
                                               onDriverClicked={(e) => {setEditDriver(e);}}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-4 col-sm-12 d-flex bg-white  p-3 main-radius">
                        <div className="row flex-1-container">
                            {componentsShown && <DriverScheduleComponent 
                            
                            veloxOrderID={selected.velox_order_id}
                                                                         onOrderClicked={evt => setSelected({
                                                                             leg_id: null,
                                                                             id: null,
                                                                             velox_order_id: evt
                                                                         })} driver={editDriver}/>}
                        </div>
                    </div>
                </div>
            </div>

            <ReactModal isOpen={open} className={'modal-496'} onRequestClose={() => setOpen(false)}>
                {
                    tab === COMPANY &&
                    <CompanyAccountInfoForm data={carrier}
                                            onSubmit={() => {
                                                loadData('carrier');
                                                setOpen(false);
                                            }}
                                            onCancel={() => {
                                                setOpen(false);
                                            }}/>
                }
                {
                    tab === REGISTRATION &&
                    <RegistrationInfoForm onCancel={() => {
                        setOpen(false);
                        
                    }}
                                          onSubmit={value => {
                                              loadData('registration');
                                              setOpen(false)
                                          }} carrier_registration={carrier_registration} data={carrier}/>
                }
                {
                    tab === INSURANCE &&
                    <InsuranceInfoForm onCancel={() => {
                        setOpen(false);
                        
                    }}
                                       onSubmit={value => {
                                           loadData('insurance');
                                           setOpen(false)
                                       }} data={carrier} carrier_insurance={carrier_insurance}/>
                }
                {
                    tab === TAX_INFO &&
                    <TaxInfo onClose={() => {
                        
                        setOpen(false);
                    }}
                             data={carrier_tax_info}
                             onSubmitted={() => {
                                 loadData('tax info');
                                 setOpen(false);
                             }}
                             carrier_id={carrier?.id} />
                }
            </ReactModal>

            <ReactModal isOpen={openModal} className={'modal-fit'} onRequestClose={() => setOpenModal(false)}>
                <div>
                    <div className="modalpopcontainer row w-100" style={{minHeight: `${heightModal}px`}}>
                        <div className="col-12 mb-3">
                            <span className="font-18 font-medium">Liability disclaimer</span>
                        </div>

                        <div className="col-12">
                            <CKEditor className="h-75"
                                      editor={ClassicEditor}
                                      onReady={(editor) => {
                                          editor.editing.view.change((writer) => {
                                              writer.setStyle(
                                                  'height',
                                                  `${(heightModal / 2) + 50}px`,
                                                  editor.editing.view.document.getRoot()
                                              );
                                          });
                                      }}
                            />
                        </div>

                        <div className="col-12 font-14 font-normal mt-2">
                            <div className="row">
                                <div className="col-6">
                                    <button onClick={() => setOpenModal(false)}
                                            className="btn w-100 text-center btn-mute">Close
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button className="btn w-100 text-center btn-blue-light"
                                            onClick={handleDisclaimer}>Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ReactModal>

            {/*Modal to add/edit drivers*/}
            <ReactModal className="modal-large" isOpen={showDriverModal} onRequestClose={evt => setShowDriverModal(undefined)}>
                <DriverSettingForm carrier={carrier}
                            driver={new Driver(showDriverModal)}
                            onSubmit={() => {
                                
                                setRefreshDriverList(true);
                                setShowDriverModal(undefined);
                                if (editDriver)
                                {
                                    let temp = {...editDriver};
                                    setEditDriver(null);
                                    setTimeout(() => {
                                        setEditDriver(temp)   
                                    });
                                }
                            }} />
                {/*<DriverSettingForm driver={editDriver}*/}
                {/*            onCancel={() => {setEditDriver(undefined);setShowDriverModal(!showDriverModal)}}*/}
                {/*            onSubmit={(driver) => {*/}
                {/*                handleSubmit(driver)*/}
                {/*            }} />*/}
            </ReactModal>

            <ReactModal className={'modal-large'} isOpen={reportState != ReportState.NONE}>
                    <CarrierStatments carrier_id={+props.match.params.id} openOrders={reportState == ReportState.OPEN_ORDERS} onClose={(evt) => setReportState(ReportState.NONE)}
                    onPDFReceived={(pdf) => {
                        setReportState(ReportState.NONE);
                        setBase64('data:application/pdf;base64,' + pdf);
                    }}
                    />
                </ReactModal>

                <ReactModal className={"modal-h p-0"} isOpen={base64 != null} onRequestClose={(evt) => setBase64(null)}>
                <div className="flex-1-container">
                    <embed width={'100%'} height={'100%'} src={base64} type='application/pdf'>
                    </embed>
                </div>


            </ReactModal>
        </>
    );
}

export default CarriersProfilePage;

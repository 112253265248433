import React, {useState, useEffect, useRef, ChangeEvent} from 'react'
import FormGroup from '../FormGroup/FormGroup';
import {
    CarrierInfo,
    CarrierRegistration,
    deleteMutationsCarrierProfile,
    FileDocument, mutationsCarrierProfile
} from "../../pages/CarriersProfilePage/CarriersProfilePage";
import {GraphqlService} from "../../services/graphql.service";
import CarrierMutation from "../../graphql/mutation/Carrier";
import {useToasts} from "react-toast-notifications";
import useLoading from "../../hooks/useLoading/useLoading";
import useMessageBox from "../../hooks/useMessageBox/useMessageBox";
import {gql} from "apollo-boost";
import {CarrierProfileHelper} from "../../helper/CarrierProfileHelper";

const RegistrationInfoForm = ({onSubmit, onCancel, data, carrier_registration}) => {
    const toast = useToasts();
    const loading = useLoading();
    const messageBox = useMessageBox();

    const fileRef = useRef<HTMLInputElement>();

    const [carrier, setCarrier] = useState<CarrierInfo>(new CarrierInfo());
    const [carrierRegistration, setCarrierRegistration] = useState<CarrierRegistration>(new CarrierRegistration());

    useEffect(() => {
        setCarrier(data);
        setCarrierRegistration(carrier_registration);
    }, [data, carrier_registration]);

    const onHandleSubmit = () => {
        try {
            let registration = {
                "mc_number": carrierRegistration.mc_number,
                "dot_number": carrierRegistration.dot_number,
                "carrier_id": +carrier.id,
            };

            loading.open('saving...');
            GraphqlService.SendMutation(CarrierMutation.CARRIER_REGISTRATION, { registration }).then(() => {
                toast.addToast('Registration information saved successfully', {appearance: 'success', autoDismiss: true});
                loading.close();
                onSubmit();
            });
        } catch (ex) {
            loading.close();
        }
    }

    async function removeFile(document: FileDocument) {
        messageBox.open({
            title: 'Delete file: ' + document.name,
            message: `Are you sure you want to delete this file?, you won't be able to revert this!`,
            buttons: [
                {
                    text: 'Cancel',
                    css: 'btn btn-shy-light'
                },
                {
                    text: 'Confirm',
                    css: 'btn btn-blue-light',
                    action: () => deleteFile(document)
                }
            ]
        })
    }

    async function previewFile(doc: FileDocument) {
        await CarrierProfileHelper.previewFile(doc);
    }

    async function loadCarrierRegistration(carrier_id: number) {
        try {
            const query = gql`
            query ($carrier_id: Int) {
                get_velox_carrier_registration(carrier_id: $carrier_id) {
                  id
                  mc_number
                  dot_number
                  carrier_id
                  uploaded_documents{
                    uid
                    name
                  }
                }
              } 
              
            `;
            const data = await GraphqlService.SendQuery(query, {carrier_id});
            setCarrierRegistration(new CarrierRegistration(data));
        } catch (ex) {

        }
    }

    async function deleteFile(document: FileDocument) {
        try {
            loading.open(`Deleting registration document: ${document.name}...`);
            const data = await GraphqlService.SendMutation(deleteMutationsCarrierProfile['registration'], {carrier_id: carrier.id, uid: document.uid});
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            loading.close();
            await loadCarrierRegistration(carrier?.id);
            toast.addToast(data.message, {appearance: 'success',autoDismiss: true});
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error',autoDismiss: true});
        }
    }

    async function handleInputChange(evt: ChangeEvent<HTMLInputElement>) {
        try {
            const file = evt.target.files[0];
            loading.open(`Uploading registration file: ${file.name}`);
            const data = await GraphqlService.SendMutation(mutationsCarrierProfile['registration'], {carrier_id: carrier.id, file});
            if (!data?.success){
                throw new Error(data?.message || 'Something went wrong');
            }
            await loadCarrierRegistration(carrier?.id);
            toast.addToast(data?.message, {appearance: 'success',autoDismiss: true});
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error',autoDismiss: true});
        } finally {
            loading.close();
        }
    }

    return (
        <>
            <div className="flex-1-container">

                <div className="col-12 mb-3 d-flex row align-items-center">
                    <span className="fs-14 font-medium mb-0">Edit registration info</span>
                    <span onClick={onCancel} className="fs-14 font-medium cursor-pointer"><i className='fas fa-times'></i></span>
                </div>

                <div className={`flex-1-container`}>
                    <div className={`row `}>

                        <FormGroup name={'mc_number '}
                                   value={carrierRegistration.mc_number}
                                   required={true}
                                   label={'MC #*'}
                                   placeholder={"MC #"}
                                   onTextChange={(value) => setCarrierRegistration({
                                       ...carrierRegistration, mc_number: value })}/>


                        <FormGroup name={'DOT*'}
                                   value={carrierRegistration.dot_number}
                                   required={true}
                                   label={'DOT #*'}
                                   placeholder={"DOT #"}
                                   onTextChange={(value) => setCarrierRegistration({
                                       ...carrierRegistration, dot_number: value })}/>

                        <div className="col-12 d-flex justify-content-between align-items-center my-2">
                            <label className="font-10">FILES</label>
                            <span onClick={() => fileRef.current?.click()}
                                  className={`btn btn-blue-light py-1`}>Upload</span>
                        </div>

                        <div className="col-12">
                            <input style={{display: 'none'}}
                                   multiple={false}
                                   onChange={evt => handleInputChange(evt)}
                                   type='file' ref={fileRef} />
                            {
                                carrierRegistration.uploaded_documents.length == 0 &&
                                <h4 className='text-red font-12 text-left cursor-pointer'
                                    onClick={() => fileRef.current?.click()}>Upload a document showing your DOT (Department of Transportation) or MC (Motor Carrier) number</h4>
                            }
                            {
                                carrierRegistration.uploaded_documents.map((document, index) => <div style={{maxWidth: '100%'}} className={"document-row align-items-center" + (index == carrierRegistration.uploaded_documents.length - 1 ? '' : ' mb-3')}>
                                    <h4 style={{textOverflow: 'clip', overflow: 'auto', whiteSpace: 'nowrap'}} className="font-12">{document.name}</h4>
                                    <div className="d-flex">
                                        <h4 className="font-16 mr-2 cursor-pointer text-light-blue text-right" onClick={() => previewFile(document)}>
                                            <i className='fas fa-eye'></i>
                                        </h4>
                                        <h4 className="font-16 cursor-pointer text-right" onClick={() => removeFile(document)}>
                                            <i className='fas fa-times'></i>
                                        </h4>
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                </div>


                <div className="row mt-3 d-flex ">

                    <div className="col-6 text-right">
                        <button className="btn w-100 py-2 bg-white rounded-pill fs-12 font-medium text-light-blue"
                                onClick={onCancel}>CANCEL
                        </button>
                    </div>

                    <div className="col-6 text-right">
                        <button className="btn w-100 py-2 btn-blue-light rounded-pill fs-12 font-medium"
                                onClick={() => onHandleSubmit()}>SAVE CHANGES
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default RegistrationInfoForm

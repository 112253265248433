import React, { FC, useRef, useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { Driver, Carrier, Leg } from '../../classes/classes';
import CarrierDriverPicker from '../CarrierDriverPicker/CarrierDriverPicker';

enum Tabs
{
    CARRIERS,
    DRIVERS
}


interface ICarrierDriverSelector
{
    leg: Leg,
    onLegChanged: (leg: Leg) => any,
    isCarrierDifferent?: boolean,
    isDriverDifferent?: boolean
}

const CarrierDriverSelector: FC<ICarrierDriverSelector> = (props) => {

    const [carrierDriverOpen, setCarrierDriverOpen] = useState(false);

    const carrierRef = useRef<HTMLInputElement>();
    const driverRef = useRef<HTMLInputElement>();
    const [tab, setTab] = useState(Tabs.CARRIERS);

    
    return <div className="col-12 h-100 d-flex flex-column">
    <div className="d-flex mb-2">
        
        <Popover
            isOpen={carrierDriverOpen}
            onClickOutside={(evt: any) => {
                
                const id = evt.target?.id;
                if (id == 'btn_edit_carrier' || id == 'btn_edit_driver')
                    return;
                setCarrierDriverOpen(false);
            }}
            positions={['right', 'left', 'top', 'bottom']} // if you'd like, you can limit the positions
            padding={16} // adjust padding here!
            reposition={true}
            content={({ position, nudgedLeft, nudgedTop, childRect, popoverRect }) => ( // you can also provide a render function that injects some useful stuff!
                <ArrowContainer arrowSize={10} arrowColor={'white'} position={position} childRect={childRect} popoverRect={popoverRect}>
                    <div className='popover-box d-flex' style={{minHeight: '60vh', maxHeight: '60vh', minWidth: '300px'}}>
                        <CarrierDriverPicker 
                            defaultTab={tab}
                        onCarrierChanged={(carrier) => {
                            const temp = new Leg(props.leg);
                            temp.driver = new Driver();
                            temp.carrier = new Carrier(carrier);
                            props.onLegChanged(temp);
                            setCarrierDriverOpen(false);
                            setTimeout(() => {
                                carrierRef?.current?.focus();   
                                carrierRef?.current?.select();   
                            });
                        }}
                        
                        onDriverChanged={(driver) => {
                            const temp = new Leg(props.leg);
                            temp.driver = new Driver(driver);
                            temp.carrier = new Carrier(driver.carrier);
                            props.onLegChanged(temp);
                            setCarrierDriverOpen(false);
                            setTimeout(() => {
                                driverRef?.current?.focus();   
                                driverRef?.current?.select();   
                            });
                        }}
                        carrier={props.leg.carrier} driver={props.leg.driver} />
                    </div>
                </ArrowContainer>
                )}
        >
        <h4 className="font-10">Carrier / Driver</h4>
        </Popover>
        <button onClick={(evt) => {
                setTab(Tabs.CARRIERS);
                setCarrierDriverOpen(true);
            }} className="btn btn-orange ml-2" id='btn_edit_carrier'>EDIT CARRIER</button>
            <button onClick={(evt) => {
                setTab(Tabs.DRIVERS);
                setCarrierDriverOpen(true);
            }} className="btn btn-orange ml-2" id='btn_edit_driver'>EDIT DRIVER</button>
        {(props.leg.carrier?.id || props.leg?.driver?.id) && <button onClick={(evt) => {
            const temp = new Leg(props.leg);
            temp.carrier = new Carrier();
            temp.driver = new Driver();
            props.onLegChanged(temp);
        }} className="btn btn-danger-light ml-2">CLEAR</button>}
        
    </div>
    <div className='py-1 flex-1-container' style={{border: '1px solid #ccc', borderRadius: 8}}>
        <div className={"py-1 px-2 mb-0-5"  + (props.isCarrierDifferent ? ' bg-gold' : '')}>
            {<p className={'font-11'}>{props.leg?.carrier?.name || '-'}</p>}
        </div>
        
        <div className={"py-1 px-2"  + (props.isDriverDifferent ? ' bg-gold' : '')}>
            <p className={'font-11'}>{props.leg?.driver?.name || '-'}</p>
        </div>
    </div>
</div>
}

export default CarrierDriverSelector;
import './BankAccountList.scss';
import {useDispatch, useSelector} from "react-redux";
import {AllStates} from "../../../../redux/reducers";
import {useEffect, useState} from "react";
import {BankAccount} from "../../../../classes/classes";
import {IBankAccountState} from "../../../../redux/reducers/bank-account.reducer";
import {fetchBankAccounts} from "../../../../redux/actions/bank-account.action";
import BankAccountForm from "../BankAccountForm/BankAccountForm";
import ReactModal from 'react-modal';
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../services/graphql.service";
import useLoading from "../../../../hooks/useLoading/useLoading";
import {useToasts} from "react-toast-notifications";

const update_settings = gql(`mutation update_setting($key: String, $value: String) {
    update_setting(key: $key, value: $value) {
        message
        success
    }
}`);

const BankAccountList = ({merchantBank, defaultCheck}) => {
    const dispatch = useDispatch();
    const {bankAccounts, error} = useSelector<AllStates>(state => state.bankAccounts) as IBankAccountState

    const [editBankAccount, setEditBankAccount] = useState(undefined);
    const [bankAccountList, setBankAccountList] = useState<BankAccount[]>([]);
    const [openModal,setOpenModal]=useState(false)

    const toast = useToasts();
    const loading = useLoading();

    const [merchantBankAccount, setMerchantBankAccount] = useState(merchantBank);
    const [defaultCheckAccount, setDefaultCheckAccount] = useState(defaultCheck);

    useEffect(() => {
        setMerchantBankAccount(merchantBank);
        setDefaultCheckAccount(defaultCheck);

        dispatch(fetchBankAccounts());
    }, []);

    useEffect(() => {
        setBankAccountList([...bankAccounts].sort((x, y) => x.name.localeCompare(y.name)));
    }, [bankAccounts]);

    const getActiveAccounts = () => {
        return bankAccountList.filter(e => e.active);
    }

    const getInactiveAccounts = () => {
        return bankAccountList.filter(e => !e.active);
    }

    const showInactiveAccounts = () => {
        if (getInactiveAccounts().length == 0) return null;

        return(
            <>
                <p className="font-14 font-medium mt-5 mb-3">{getInactiveAccounts().length} inactive</p>

                {
                    getInactiveAccounts().map((e,index) => {
                        return <div key={index} className={`card mb-3 cursor-pointer ${editBankAccount && e.id == editBankAccount.id ? 'bg-light-blue' : 'bg-gray'}`}
                                    onClick={() => setEditBankAccount({...e})}>
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <p className="font-14 text-gray m-0 font-medium">{ e?.name }</p>
                                </div>

                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <p className="font-12 text-gray m-0">{ e?.bank }</p>
                                </div>

                                <p className="font-12 text-gray m-0">{ e?.note }</p>
                            </div>
                        </div>;
                    })
                }
            </>
        );
    }

    const createBankAccount = (bankAccount, isDefault, isMerchant) => {
        let temp = [...bankAccountList];
        temp.push({...bankAccount});

        setBankAccountList([...temp]);
        setEditBankAccount(undefined);
        setOpenModal(false);

        updateSettings(bankAccount.id, isDefault, isMerchant).then(() => {
            dispatch(fetchBankAccounts());
        });
    }

    const updateBankAccount = (bankAccount, isDefault, isMerchant) => {
        let index = bankAccountList.findIndex(e => e.id == editBankAccount.id);
        bankAccountList[index] = {...bankAccount};

        setBankAccountList([...bankAccountList]);
        setEditBankAccount(undefined);
        setOpenModal(false);

        updateSettings(bankAccount.id, isDefault, isMerchant).then(() => {
            dispatch(fetchBankAccounts());
        });
    }

    const updateSettings = async (id, isDefault, isMerchant) => {
        loading.open('loading...');

        if (isDefault) {
            const data = await GraphqlService.SendMutation(update_settings, {
                key: 'DEFAULT_CHECK_ACCOUNT',
                value: id.toString()
            });

            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            setDefaultCheckAccount(id);
        }

        if (isMerchant) {
            const data = await GraphqlService.SendMutation(update_settings, {
                key: 'MERCHANT_BANK_ACCOUNT',
                value: id.toString()
            });

            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            setMerchantBankAccount(id);
        }

        loading.close();
    }

    return(
        <>
            { error != '' && <p className="font-12 font-medium">Bank Accounts not loaded correctly</p> }

            <div className="row h-100">
                <div className="col-12 d-flex align-items-center justify-content-between mb-3">
                    <p className="font-14 font-medium m-0">Bank account</p>
                    <p className="font-12 font-medium text-light-blue cursor-pointer"
                       onClick={() => {setEditBankAccount(new BankAccount());setOpenModal(!openModal)}}><i className="fa fa-plus"></i> NEW ACCOUNT</p>
                </div>

                <div className="col-5 ml-3">
                    <p className="font-14 font-medium mb-3">{getActiveAccounts().length} active</p>
                </div>

                {
                    editBankAccount ?
                        <div className="col-6 ml-3">
                            <p className="font-14 font-medium mb-3">Add new bank Account</p>
                        </div> : <div className="col-6"></div>
                }

                <div className="col-12 bank-account-list ml-3">
                    <div className="row">
                    {
                        getActiveAccounts().map((e,index) => {
                            return <div key={index} className={`col-6 card mb-3 cursor-pointer ${editBankAccount && e.id == editBankAccount.id ? 'bg-light-blue' : 'bg-gray'}`}
                                        onClick={() => {setEditBankAccount({...e});setOpenModal(!openModal)}}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                        <p className="font-14 m-0 font-medium">{ e?.name }</p>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                        <p className="font-12 m-0">{ e?.bank }</p>
                                    </div>

                                    <p className="font-12 m-0">{ e?.note }</p>
                                </div>
                            </div>;
                        })
                    }
                    </div>

                    { showInactiveAccounts() }
                </div>

                <ReactModal className="" isOpen={openModal} onRequestClose={() => setOpenModal(false)}>
                        <BankAccountForm merchantBank={merchantBankAccount}
                                         defaultCheck={defaultCheckAccount}
                                         bankAccount={editBankAccount}
                                         onCancel={() => {setEditBankAccount(undefined);setOpenModal(false)}}
                                         onSubmit={({bankAccount, isDefault, isMerchant}) => {
                                             if (bankAccount.id === editBankAccount.id)
                                                 updateBankAccount(bankAccount, isDefault, isMerchant);
                                             else
                                                 createBankAccount(bankAccount, isDefault, isMerchant);
                                         }} />
                 </ReactModal>
            </div>
        </>
    );
}

export default BankAccountList;
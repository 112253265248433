import gql from "graphql-tag";

const ADD_USER = gql`
mutation($name:String, $password:String){
    create_user(name:$name, password:$password){
      id
      message
      success
    }
  }
`;

const ADD_NEW_EMPLOYEE = gql`
    mutation($employee: employee_input){
        create_employee(employee:$employee){
        id
        message
        success
        }
    }
`;

const UPDATE_EMPLOYEE = gql`
    mutation($employee:employee_input_update){
        update_employee(employee:$employee){
        id
        message
        success
        }
    }
`;


const EmployeeMutations = {
    ADD_USER,
    ADD_NEW_EMPLOYEE,
    UPDATE_EMPLOYEE

};

export default EmployeeMutations
import React from 'react';

export const Loading = ({size = '24px', margin = '1rem auto'}: {size?: string, margin?: string}) => {
    return (
        <div className="loading-component">
            <img style={{display: 'block', margin, width: size, height: size}} src="https://media0.giphy.com/media/l4FGKbWgkhHVGXzTW/giphy.gif" alt=""/>
        </div>
    )
}

export default Loading;
import React, { FC, useEffect, useRef } from 'react';
import { VehicleOrder } from '../../../../classes/classes';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import LegCard from '../LegCard/LegCard';

interface OnCheckChangedAction
{
    index: number, checked: boolean, checkbox: boolean, leg: boolean
}



interface IVehicleCard
{
    id?: string, 
    margin: boolean,
    selected: boolean,
    checked: boolean,
    index: number,
    checkDisabled: boolean,
    onCheckChanged: (data: OnCheckChangedAction) => any,
    order: VehicleOrder,
    onOrderChanged: (order: VehicleOrder, index: number) => any
}
const VehicleCard: FC<IVehicleCard> = (props) => {

    const cardRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (props.selected)
        {
            if (!isInViewport(cardRef.current))
            {
                console.log('not in viewport')
                cardRef.current?.scrollIntoView({behavior: 'smooth'});
            }
            else
            {
                console.log('in viewport')
            }
        }
    }, [props.selected]);

    function handleOnChange(key, value) {
        const temp = new VehicleOrder(props.order);

        temp.vehicle[key] = value;

        props.onOrderChanged(temp, props.index);
    }


    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        const parent = element.parentElement;
        console.log(parent);
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (parent.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (parent.innerWidth || document.documentElement.clientWidth)
        );
    }


    return <div onClick={(evt) => props.onCheckChanged({index: props.index, checked: true, checkbox: false, leg: false})} ref={cardRef} id={props.id} style={{transition: '.3s all ease'}} className={`p-2-5 cursor-pointer position-relative rounded ${props.selected ? 'bg-light-blue' : 'bg-gray'} ${props.margin ? 'mb-2' : ''}`}>
        <h6 style={{position: 'absolute', right: 'calc(100% + 6px)', top: '1.1rem'}} className="font-9">{props.index + 1}</h6>
        <div className='row'>
            <div className={'col-8 pr-1'}>
                <input maxLength={17} onChange={(evt) => handleOnChange('vin', evt.target.value.substring(0, 17))} value={props.order.vehicle.vin} onClick={(evt) => evt.stopPropagation()} type="text" placeholder={'VIN Number'} className="col-12 inline-input simple" />
            </div>
            <div className={'row col-4'}>
                <button style={{marginLeft: '-.35rem'}} className={'btn btn-blue-light rounded-pill'}>DECODE</button>
                <Checkbox onClick={(evt) => evt.stopPropagation()} onCheckChange={checked => props.onCheckChanged({index: props.index, checked, checkbox: true, leg: false})} disabled={props.checkDisabled} checked={props.checked || props.selected} />
            </div>
            
        </div>
        <div className="row mt-2">
            <input onChange={(evt) => handleOnChange('year', evt.target.value)} value={props.order.vehicle.year} onClick={(evt) => evt.stopPropagation()} placeholder={'Year'} type="text" className="col-3 inline-input simple" />
            <div className="col-9 row">
                <input onChange={(evt) => handleOnChange('make', evt.target.value)} value={props.order.vehicle.make} onClick={(evt) => evt.stopPropagation()} placeholder={'Make'} type="text" className={'col-6 inline-input simple'} />
                <input onChange={(evt) => handleOnChange('model', evt.target.value)} value={props.order.vehicle.model} onClick={(evt) => evt.stopPropagation()} placeholder={'Model'} type="text" className={'col-6 inline-input simple'} />
            </div>
        </div>
        
        {props.order.legs.length == 0 && <div className={'bg-white rounded mt-3 p-2'}><h6 className={'font-9 text-gray text-center'}>NO LEGS</h6></div>}

        {props.order.legs.map((leg, i) => 
            <LegCard 
                onClick={(evt) => {
                    evt.stopPropagation();
                    props.onCheckChanged({checkbox: false, leg: true, checked: true, index: props.index});
                }}
                leg={leg} margin={i != props.order.legs.length - 1} />)}
    </div>
}

export default VehicleCard;
import {Bank_Account, Payment, Payment_Date_Sp} from "../payment";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";

export const get_customer_remaining_credits = (input:{customer_id: number}) : Promise<Payment[]> => {
  let query = gql`
    query($customer_id: Int) {
      get_customer_remaining_credits(customer_id: $customer_id) {
        id
        time
        type
        amount
        amount_left
        bank_account_id
        note
        files {
          uid
          name
        }
        voided
      }
    } 
  `;
  return GraphqlService.SendQuery(query, input) as  //, {customer_id} as 
  Promise<Payment[]>;
}

export const get_bank_accounts = (input:{}) : Promise<Bank_Account[]> => {
  let query = gql`
    query {
      get_bank_accounts {
        id
        name
        note
        bank
        active
        last_4
      }
    } 
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Bank_Account[]>;
}

export const get_payment_categories = (input:{}) : Promise<string[]> => {
  let query = gql`
    query {
      get_payment_categories
    } 
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<string[]>;
}

export const get_payments_by_date = (input:{start_date: string, end_date: string}) : Promise<Payment_Date_Sp[]> => {
  let query = gql`
    query($start_date: String, $end_date: String) {
      get_payments_by_date(start_date: $start_date, end_date: $end_date) {
        id
        note
        time
        type
        amount
        paid_for {
          key
          name
        }
        reference
        time_paid
        amount_left
        credit_card {
          id
          cardholder_name
          number
          type
          expired
        }
        bank_account {
          id
          name
          note
          bank
          active
          last_4
        }
        files {
          uid
          name
        }
      }
    } 
  `;
  return GraphqlService.SendQuery(query, input) as  //, {start_date, end_date} as 
  Promise<Payment_Date_Sp[]>;
}

export const get_payments_by_customer = (input:{customer_id: number}) : Promise<Payment_Date_Sp[]> => {
  let query = gql`
    query($customer_id: Int) {
      get_payments_by_customer(customer_id: $customer_id) {
        id
        note
        time
        type
        amount
        paid_for {
          key
          name
        }
        reference
        time_paid
        amount_left
        credit_card {
          id
          cardholder_name
          number
          type
          expired
        }
        bank_account {
          id
          name
          note
          bank
          active
          last_4
        }
        files {
          uid
          name
        }
      }
    } 
  `;
  return GraphqlService.SendQuery(query, input) as  //, {customer_id} as 
  Promise<Payment_Date_Sp[]>;
}

export const get_payments_by_master = (input:{master_order_id: number}) : Promise<Payment_Date_Sp[]> => {
  let query = gql`
    query($master_order_id: Int) {
      get_payments_by_master(master_order_id: $master_order_id) {
        id
        note
        time
        type
        amount
        paid_for {
          key
          name
        }
        reference
        time_paid
        amount_left
        credit_card {
          id
          cardholder_name
          number
          type
          expired
        }
        bank_account {
          id
          name
          note
          bank
          active
          last_4
        }
        files {
          uid
          name
        }
      }
    } 
  `;
  return GraphqlService.SendQuery(query, input) as  //, {master_order_id} as 
  Promise<Payment_Date_Sp[]>;
}

export const payment_search = (input:{customer_search: string, ordernumber_search: string, reference_search: string, creditcard_search: string, orderreference_search: string}) : Promise<Payment_Date_Sp[]> => {
  let query = gql`
    query($customer_search: String, $ordernumber_search: String, $reference_search: String, $creditcard_search: String, $orderreference_search: String) {
      payment_search(customer_search: $customer_search, ordernumber_search: $ordernumber_search, reference_search: $reference_search, creditcard_search: $creditcard_search, orderreference_search: $orderreference_search) {
        id
        note
        time
        type
        amount
        paid_for {
          key
          name
        }
        reference
        time_paid
        amount_left
        credit_card {
          id
          cardholder_name
          number
          type
          expired
        }
        bank_account {
          id
          name
          note
          bank
          active
          last_4
        }
        files {
          uid
          name
        }
      }
    } 
  `;
  return GraphqlService.SendQuery(query, input) as  //, {customer_search, ordernumber_search, reference_search, creditcard_search, orderreference_search} as 
  Promise<Payment_Date_Sp[]>;
}
import {gql} from "apollo-boost";
import {ResultWithLink} from "../../customer_profile/customer_profile";
import {GraphqlService} from "../../../../graphql.service";
import {User_Input} from "../users";

export const change_User_Profile_Password = (input:{userid: number, old: string, newpassword: string, confirmpassword: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($userid: Int, $old: String, $newpassword: String, $confirmpassword: String) {
      change_User_Profile_Password(userid: $userid, old: $old, newpassword: $newpassword, confirmpassword: $confirmpassword) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input)  //, {userid, old, newpassword, confirmpassword});
}

export const update_user_settings = (input:{key: string, value: string}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($key: String, $value: String) {
      update_user_settings(key: $key, value: $value) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input)  //, {key, value});
}

export const create_user = (input:{name: string, password: string, level: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($name: String, $password: String, $level: Int) {
      create_user(name: $name, password: $password, level: $level) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input)  //, {name, password, level});
}

export const update_user = (input:{user: User_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($user: user_input) {
      update_user(user: $user) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input)  //, {user});
}

export const activate_or_deactivate_user = (input:{user_id: number, active: boolean}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($user_id: Int, $active: Boolean) {
      activate_or_deactivate_user(user_id: $user_id, active: $active) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input)  //, {user_id, active});
}
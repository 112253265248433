import {Driver, DriverLocation} from "../../classes/classes";
import IReducerAction from "../models/IReducerAction";
import DriverTypes from "../types/Driver.type";

export interface IDriverState {
    drivers: Driver[];
    veloxDrivers: Driver[];
    driverLocations: DriverLocation[];
    loadingLocations: boolean;
    errorLocations: boolean;
    errorList: string;
}

const initialState: IDriverState = {
    drivers: [],
    veloxDrivers: [],
    driverLocations: null,
    loadingLocations: false,
    errorLocations: null,
    errorList: '',
}

export default function DriverReducer(state: IDriverState = initialState, action: IReducerAction): IDriverState
{
    switch (action.type)
    {
        case DriverTypes.FETCH_ALL_VELOX_DRIVERS_REQUEST:
            return {
                ...state,
            };
        case DriverTypes.FETCH_ALL_VELOX_DRIVERS_SUCCESS:
            return {
                ...state,
                errorList: '',
                veloxDrivers: action.payload,
            };
        case DriverTypes.FETCH_ALL_VELOX_DRIVERS_FAILURE:
            return {
                ...state,
                errorList: action.payload
            }
        case DriverTypes.GET_ALL_DRIVER_LOCATIONS_STARTED:
            return {
                ...state,
                loadingLocations: true,
                errorLocations: null
            }
        case DriverTypes.GET_ALL_DRIVER_LOCATIONS_SUCCESS:
                return {
                    ...state,
                    loadingLocations: false,
                    driverLocations: action.payload
                }
        case DriverTypes.GET_ALL_DRIVER_LOCATIONS_ERROR:
            return {
                ...state,
                loadingLocations: false,
                errorLocations: action.payload
            }
            // JP
        case DriverTypes.FETCH_ALL_DRIVERS_REQUEST:
            return {
                ...state,
            };
        case DriverTypes.FETCH_ALL_DRIVERS_SUCCESS:
            return {
                ...state,
                errorList: '',
                drivers: action.payload,
            };
        case DriverTypes.FETCH_ALL_DRIVERS_FAILURE:
            return {
                ...state,
                errorList: action.payload
            }
        default:
            return state;
    }
}

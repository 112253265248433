import {GraphqlService} from "../../../../graphql.service";
import {gql} from "apollo-boost";
import {Inspection} from "../inspection";

export const get_inspection_by_id = (input:{inspection_id: number}) : Promise<Inspection> => {
  let query = gql`
    query($inspection_id: Int) {
      get_inspection_by_id(inspection_id: $inspection_id) {
        id
        performed_by {
          time
          name
          uid
          user_id
        }
        signed_by {
          time
          name
          uid
          user_id
        }
        user_id
        note
        odometer
        device_type
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        accessories {
          name
          count
          user_id
        }
        additional_emails
        damages {
          stop_id
          point {
            x
            y
          }
          code
          uid
          is_pickup
        }
        photos {
          uid
          type
          user_id
        }
        device_info {
          name
          type
          idiom
          model
          version
          platform
          manufacturer
        }
        flags {
          too_dirty
          too_dark
          raining
        }
        origin_inspection {
          origin_id
          driver_name
          driver_signature
          contact_name
        }
        destination_inspection {
          destination_id
          driver_name
          driver_signature
          contact_name
        }
        vehicle_data {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        pending_inspection_id
        user {
          id
          name
          level
          active
        }
        time
        type
        vehicle_order {
          id
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {inspection_id} as 
  Promise<Inspection>;
}

export const get_inspections_by_token = (input:{token: string}) : Promise<Inspection> => {
  let query = gql`
  query($token:String){
get_inspections_by_token(token:$token){
  id
  performed_by {
              uid
              name
              user_id
          }
          signed_by {
              uid
              name
              user_id
          }
          additional_emails
          time
          note
          odometer
          photos {
              uid
              type
              user_id
          }
          vehicle_data {
              id
              vin
              year
              make
              model
              submodel
              type
              no_vin
              special_vin
              incomplete_vin
              color
              license_plate
              internal_note
              time_created
          }
          accessories {
              name
              count
          }
          damages {
              
              point {
                  x
                  y
              }
              code
              uid
          }
          flags {
              too_dirty
              too_dark
              raining
          }
         
      }

}
`;
  return GraphqlService.SendQuery(query, input) as  //, {token} as 
  Promise<Inspection>;
}

export const get_pending_inspection = (input:{token: string}) : Promise<Inspection> => {
  let query = gql`
    query($token: String) {
      get_pending_inspection(token: $token) {
        id
        performed_by {
          time
          name
          uid
          user_id
        }
        signed_by {
          time
          name
          uid
          user_id
        }
        user_id
        note
        odometer
        device_type
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        accessories {
          name
          count
          user_id
        }
        additional_emails
        damages {
          stop_id
          point {
            x
            y
          }
          code
          uid
          is_pickup
        }
        photos {
          uid
          type
          user_id
        }
        device_info {
          name
          type
          idiom
          model
          version
          platform
          manufacturer
        }
        flags {
          too_dirty
          too_dark
          raining
        }
        origin_inspection {
          origin_id
          driver_name
          driver_signature
          contact_name
        }
        destination_inspection {
          destination_id
          driver_name
          driver_signature
          contact_name
        }
        vehicle_data {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        pending_inspection_id
        user {
          id
          name
          level
          active
        }
        time
        type
        vehicle_order {
          id
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {token} as 
  Promise<Inspection>;
}

export const get_inventory_inspections_by_vehicle_order = (input:{vehicle_order_id: number}) : Promise<Inspection[]> => {
  let query = gql`
    query($vehicle_order_id: Int) {
      get_inventory_inspections_by_vehicle_order(vehicle_order_id: $vehicle_order_id) {
        id
        performed_by {
          time
          name
          uid
          user_id
        }
        signed_by {
          time
          name
          uid
          user_id
        }
        user_id
        note
        odometer
        device_type
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        accessories {
          name
          count
          user_id
        }
        additional_emails
        damages {
          stop_id
          point {
            x
            y
          }
          code
          uid
          is_pickup
        }
        photos {
          uid
          type
          user_id
        }
        device_info {
          name
          type
          idiom
          model
          version
          platform
          manufacturer
        }
        flags {
          too_dirty
          too_dark
          raining
        }
        origin_inspection {
          origin_id
          driver_name
          driver_signature
          contact_name
        }
        destination_inspection {
          destination_id
          driver_name
          driver_signature
          contact_name
        }
        vehicle_data {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        pending_inspection_id
        user {
          id
          name
          level
          active
        }
        time
        type
        vehicle_order {
          id
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vehicle_order_id} as 
  Promise<Inspection[]>;
}

export const get_pending_inspections = (input:{}) : Promise<Inspection[]> => {
  let query = gql`
    query {
      get_pending_inspections {
        id
        performed_by {
          time
          name
          uid
          user_id
        }
        signed_by {
          time
          name
          uid
          user_id
        }
        user_id
        note
        odometer
        device_type
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        accessories {
          name
          count
          user_id
        }
        additional_emails
        damages {
          stop_id
          point {
            x
            y
          }
          code
          uid
          is_pickup
        }
        photos {
          uid
          type
          user_id
        }
        device_info {
          name
          type
          idiom
          model
          version
          platform
          manufacturer
        }
        flags {
          too_dirty
          too_dark
          raining
        }
        origin_inspection {
          origin_id
          driver_name
          driver_signature
          contact_name
        }
        destination_inspection {
          destination_id
          driver_name
          driver_signature
          contact_name
        }
        vehicle_data {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        pending_inspection_id
        user {
          id
          name
          level
          active
        }
        time
        type
        vehicle_order {
          id
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Inspection[]>;
}

export const get_pending_inspection_link = (input:{pending_inspection_id: number}) : Promise<string> => {
  let query = gql`
    query($pending_inspection_id: Int) {
      get_inspection_by_id(pending_inspection_id: $pending_inspection_id)
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {pending_inspection_id} as 
  Promise<string>;
}
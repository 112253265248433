import gql from "graphql-tag";

const GET_TEMP_LINK = gql`
    query ( $minutes:Int, $is_thumbnail:Boolean, $uid: String){
      get_temp_link(minutes:$minutes is_thumbnail:$is_thumbnail uid: $uid)
    }
`;

const GET_TEMP_LINK_VELOX = gql`
    query($uid:String, $is_thumbnail:Boolean){
        get_velox_temp_link(uid:$uid is_thumbnail:$is_thumbnail)
    }
`;


const S3Queries = {
    GET_TEMP_LINK,
    GET_TEMP_LINK_VELOX
};

export default S3Queries;

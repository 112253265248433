import {useEffect, useState} from "react";
import FormGroup from "../FormGroup/FormGroup";
import Helper from "../../helper/Helper";
import AddressSearch from "../Google/AddressSearch";

class PayeeTax {
    type: string = '';
    value: string = '';

    constructor(props?) {
        if(props){
            this.type = props.type;
            this.value = props.value;
        }
    }
}

class PayeeAddress {
    city: string = '';
    state: string = '';
    street: string = '';
    country: string = '';
    postal_code: string = '';
    street_number: string = '';
    postal_code_suffix: string = '';

    constructor(props?) {
        if(props){
            this.city = props.city;
            this.state = props.state;
            this.street = props.street;
            this.country = props.country;
            this.postal_code = props.postal_code;
            this.street_number = props.street_number;
            this.postal_code_suffix = props.postal_code_suffix;
        }
    }
}

export class Payee {
    id: number = null;
    name: string = '';
    tax_info?: PayeeTax = new PayeeTax();
    address?: PayeeAddress = new PayeeAddress();

    constructor(props?) {
        if(props){
            this.name = props.name;
            this.tax_info = new PayeeTax(props.tax_info);
            this.address = new PayeeAddress(props.address);
        }
    }
}

type PayeeFormProps = {
    currentPayee?: Payee,
    onCancel: (e: any) => void,
    onSubmit: (e: any) => void,
}

const states = Helper.States;
const countries = ['United States', 'Canada'];

const PayeeForm = ({currentPayee, onCancel, onSubmit}: PayeeFormProps) => {
    const [payee, setPayee] = useState<Payee>(new Payee());

    useEffect(() => {
        console.log('here in effect');
    }, [currentPayee])

    return (
        <>

            <div className="row">
                <p className="font-14 font-medium mb-3">NEW PAYEE</p>

                <FormGroup label={'Name'}
                           name={'name'}
                           value={payee.name}
                           placeholder={'Name'}
                           onTextChange={value => {
                               setPayee({...payee, name: value})
                           }}/>

                <AddressSearch className="col-12" onGotAddress={(newAddress) =>{
                    setPayee(new Payee({...payee, ...{address: newAddress}}))
                }}/>

                <FormGroup label={'Street #'}
                           colSize={4}
                           value={payee.address.street_number}
                           name={'streetNumber'}
                           placeholder={'Street #'}
                           onTextChange={value => setPayee(new Payee({...payee, ...{address: {...payee.address, ...{street_number: value}}}}))}/>

                <FormGroup label={'Street'}
                           colSize={8}
                           value={payee.address.street}
                           name={'street'}
                           placeholder={'Street'}
                           onTextChange={value => setPayee(new Payee({...payee, ...{address: {...payee.address, ...{street: value}}}}))}/>

                <FormGroup label={'City'}
                           name={'city'}
                           colSize={4}
                           value={payee.address.city}
                           placeholder={'City'}
                           onTextChange={value => setPayee(new Payee({...payee, ...{address: {...payee.address, ...{city: value}}}}))}/>

                <FormGroup label={'State'}
                           name={'state'}
                           colSize={4}
                           value={payee.address.state}
                           type={'select'}
                           placeholder={'-- Select a state --'}
                           options={{data: states, value: x => x, label: x => x}}
                           onTextChange={value => setPayee(new Payee({...payee, ...{address: {...payee.address, ...{state: value}}}}))}/>

                <FormGroup label={'Zip'}
                           colSize={4}
                           name={'zip'}
                           value={payee.address.postal_code}
                           placeholder={'XXXXX'}
                           onTextChange={value => setPayee(new Payee({...payee, ...{address: {...payee.address, ...{postal_code: value}}}}))}/>

                <FormGroup label={'Country'}
                           name={'country'}
                           colSize={8}
                           value={payee.address.country}
                           type={'select'}
                           placeholder={'-- Select a country --'}
                           options={{data: countries, value: x => x, label: x => x}}
                           onTextChange={value => setPayee(new Payee({...payee, ...{address: {...payee.address, ...{country: value}}}}))}/>

                <FormGroup label={'Tax type'}
                           name={'tax'}
                           colSize={6}
                           value={payee.tax_info.type}
                           type={'select'}
                           placeholder={'-- Select a tax type --'}
                           options={{data: ['SSN', 'EIN'], value: x => x, label: x => x}}
                           onTextChange={value => setPayee(new Payee({...payee, ...{tax_info: {...payee.tax_info, ...{type: value}}}}))}/>

                <FormGroup label={'Tax value'}
                           name={'value'}
                           colSize={6}
                           value={payee.tax_info.value}
                           placeholder={'Tax value'}
                           onTextChange={value => setPayee(new Payee({...payee, ...{tax_info: {...payee.tax_info, ...{value: value,}}}}))}/>

            </div>


            <div className="row">
                <div className="col-6 mr-3">
                    <button className="btn btn-block btn-blue-outline" onClick={event => onCancel(event)}>CANCEL
                    </button>
                </div>

                <div className="col-6">
                    <button className="btn btn-block btn-blue" onClick={event => {
                        onSubmit(payee);
                    }}>{payee.id == null ? 'SAVE' : 'UPDATE'}</button>
                </div>
            </div>
        </>
    );
}

export default PayeeForm;

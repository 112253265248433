import React,{useState,useRef,ReactNode} from 'react'
import './MenuOptionItem.scss'

type MenuOptionItemProps={
    icon:string
    handleItemClick:(ev)=>any,
    children:ReactNode
}

const MenuOptionItem =(props:MenuOptionItemProps)=>{
    return(
        <>
            <li onClick={props.handleItemClick} className="item fs-14 font-normal mb-2 p-1 ml-3">
                <img style={{width:"24px",height:"24px"}} src={props.icon} alt="" />
                <span className="ml-2">{props.children}</span>
            </li>
        </>
    )
}

export default MenuOptionItem
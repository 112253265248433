import CustomerProfileCard from "../../components/CustomerProfileCard/CustomerProfileCard";
import CustomerLocation from "../../components/CustomerLocation/CustomerLocation";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AllStates } from "../../redux/reducers";
import { ICustomerState } from "../../redux/reducers/Customer.reducer";
import {
  getAllCustomerProfilesList,
  getCustomerInfo,
  getCustomerLocations,
} from "../../redux/actions/Customer.action";
import { useParams } from "react-router";
import Loading from "../../components/Loading/Loading";
import "./CustomerProfilePage.scss";
import ProfilesContacts from "../../components/ProfilesContacts/ProfilesContacts";
import OrderList from "../../components/OrderList/OrderList";
import Quotes from "../../components/Quotes/Quotes";
import { gql } from "apollo-boost";
import { GraphqlService } from "../../services/graphql.service";
import { useToasts } from "react-toast-notifications";
import QuotesTableByCustomer from "./QuotesTableByCustomer/QuotesTableByCustomer";
import PaymentsTableByCustomer from "./PaymentsTableByCustomer/PaymentsTableByCustomer";
import ReactModal from "react-modal";
import CustomerStatments from "./CustomerStatments/CustomerStatments";

enum ReportState {
  NONE,
  PAID_ORDERS,
  OPEN_ORDERS,
}

const queryGql = gql`
  query ($customer_id: Int) {
    get_customer_page_details(customer_id: $customer_id) {
      quoted
      needs_quote
      open_orders
      needs_invoice
      recent_payments
      open_orders
    }
  }
`;

class DataInfo {
  quoted: number = 0;
  needs_quote: number = 0;
  open_orders: number = 0;
  needs_invoice: number = 0;
  recent_payments: number = 0;

  constructor(props?: DataInfo) {
    if (props) {
      this.quoted = props.quoted;
      this.needs_quote = props.needs_quote;
      this.open_orders = props.open_orders;
      this.needs_invoice = props.needs_invoice;
      this.recent_payments = props.recent_payments;
    }
  }
}
interface ParamTypes {
  id: string;
}

const CustomerProfilePage = () => {
  const [dataInfo, setDataInfo] = useState<DataInfo>(new DataInfo());
  const toast = useToasts();

  const dispatch = useDispatch();
  let { id } = useParams<ParamTypes>();
  const customerID = +id;

  const { customerById, loading } = useSelector<AllStates>(
    (states) => states.customers
  ) as ICustomerState;

  const {
    loadingProfiles,
    customerProfiles,
    errorProfiles,
    customerLocations,
    loadingLocations,
    errorLocations,
  } = useSelector<AllStates>((states) => states.customers) as ICustomerState;

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [actionManagerOpened, setActionManagerOpened] = useState(false);
  const [base64, setBase64] = useState(null);

  useEffect(() => {
    if (customerById) {
      customerById.location = customerLocations[0];
      customerById.customer_profile = customerProfiles[0];
    }
  }, [customerById]);

  useEffect(() => {
    dispatch(getAllCustomerProfilesList(customerID));
    dispatch(getCustomerLocations(customerID));

    dispatch(getCustomerInfo(+id));
  }, [id]);

  useEffect(() => {
    let query = GraphqlService.SendQueryObservable(queryGql, {
      customer_id: customerID,
    });

    let subs = query.onResults.subscribe((result) => {
      if (result.error)
        toast.addToast(result.error.message, {
          appearance: "error",
          autoDismiss: true,
        });
      else {
        const data = new DataInfo(result.data);
        setDataInfo(data);
      }
    });

    return () => {
      subs.unsubscribe();
      query.stopPolling();
    };
  }, []);

  const onLocationEditClick = (location) => {
    setSelectedLocation({ ...location });
    setActionManagerOpened(true);

    dispatch(getCustomerLocations(customerID));
  };
  const [reportState, setReportState] = useState(ReportState.NONE);
  const [activeTab, setActiveTab] = useState(5);

  const getCustomerDetails = () => {
    return (
      <>
        <p className="font-14 font-medium mb-3">Customer details</p>

        {loading && <Loading />}
        {!loading && (
          <div className="flex-1-container">
            <CustomerProfileCard
              customer={customerById}
              onEditCustomer={() => {
                dispatch(getAllCustomerProfilesList(customerID));
                dispatch(getCustomerLocations(customerID));

                dispatch(getCustomerInfo(+id));
              }}
            />

            <div className="row">
              <div className="col-6 mt-3 mb-3">
                <CustomerLocation
                  customerId={customerID}
                  customer={customerById}
                  isCustomerLocationsLoading={loadingLocations}
                  onLocationEditClick={(location) => {
                    onLocationEditClick(location);
                  }}
                  customerLocations={customerLocations}
                />
              </div>
              <div className="col-6"></div>
            </div>

            <ProfilesContacts
              customerId={customerID}
              customerProfiles={customerProfiles}
              onProfileSubmitted={() => {
                dispatch(getAllCustomerProfilesList(customerID));
                dispatch(getCustomerLocations(customerID));

                dispatch(getCustomerInfo(+id));
              }}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="customer-page flex-1-container">
        <div className="col-12 d-flex align-items-center justify-content-start mb-3">
          <p className="font-18 font-medium mr-3">{customerById?.name}</p>
          <div className="customer-badge font-10 font-medium">
            CUSTOMER ID #{customerById?.id}
          </div>
        </div>

        <div className="flex-1-container row-container">
          <div className="customer-menu">
            <ul>
              <li
                className={`cursor-pointer ${activeTab === 5 ? "active" : ""}`}
                onClick={() => setActiveTab(5)}
              >
                <p className="font-11 font-medium mb-1">Customer details</p>
                <p className="font-9 mb-1">Basic info</p>
                <p className="font-9 mb-1">Location & contacts</p>
              </li>

              <li
                className={`cursor-pointer ${activeTab === 2 ? "active" : ""}`}
                onClick={() => setActiveTab(2)}
              >
                <p className="font-11 font-medium mb-1">Orders</p>
                <p className="font-9 mb-1">Open & History</p>
                <p className="d-flex align-items-center font-9 font-medium blue mb-1">
                  <div className="dot blue mr-2"></div> {dataInfo.open_orders}{" "}
                  open order{dataInfo.open_orders == 1 ? "" : "s"}
                </p>
                <p className="d-flex align-items-center font-9 font-medium pink mb-1">
                  <div className="dot pink mr-2"></div> {dataInfo.needs_invoice}{" "}
                  open invoice{dataInfo.needs_invoice == 1 ? "" : "s"}
                </p>
              </li>
              <li
                className={`cursor-pointer ${activeTab === 1 ? "active" : ""}`}
                onClick={() => setActiveTab(1)}
              >
                <p className="font-11 font-medium mb-1">Quotes</p>
                <p className="d-flex align-items-center font-9 font-medium pink mb-1">
                  <div className="dot pink mr-2"></div> {dataInfo.needs_quote}{" "}
                  needs quote
                </p>
                <p className="d-flex align-items-center font-9 font-medium green mb-1">
                  <div className="dot green mr-2"></div> {dataInfo.quoted}{" "}
                  quoted
                </p>
              </li>

              {/* <li className={`cursor-pointer ${activeTab === 3 ? 'active' : ''}`}
                                        onClick={() => setActiveTab(3)}>

                                        <p className="font-11 font-medium mb-1">Invoices</p>
                                        <p className="d-flex align-items-center font-9 font-medium green mb-1"><div className="dot green mr-2"></div> 10 sent</p>
                                        <p className="d-flex align-items-center font-9 font-medium red mb-1"><div className="dot red mr-2"></div> 3 past due</p>
                                    </li> */}

              <li
                className={`cursor-pointer ${activeTab === 4 ? "active" : ""}`}
                onClick={() => setActiveTab(4)}
              >
                <p className="font-11 font-medium mb-1">Payments</p>
                <p className="font-9 font-medium text-gray mb-1">
                  {dataInfo.recent_payments} recent
                  {dataInfo.recent_payments == 1 ? "" : "s"}
                </p>
              </li>

              <li className={``}>
                <p className="font-11 font-medium mb-1">Reports</p>
                <p
                  onClick={(evt) => setReportState(ReportState.PAID_ORDERS)}
                  style={{ textDecoration: "underline" }}
                  className="font-9 font-medium mb-1 cursor-pointer text-blue-light"
                >
                  Paid Orders
                </p>
                <p
                  onClick={(evt) => setReportState(ReportState.OPEN_ORDERS)}
                  style={{ textDecoration: "underline" }}
                  className="font-9 font-medium mb-1 cursor-pointer text-red"
                >
                  Open Orders
                </p>
              </li>
            </ul>
          </div>

          <div className="d-flex col-12">
            <div className="flex-1-container">
              {activeTab === 1 && (
                <QuotesTableByCustomer customer_id={customerID} />
              )}
              {activeTab === 2 && <OrderList customer_id={customerID} />}
              {/* { activeTab === 3 && <PaymentsTableByCustomer /> } */}
              {activeTab === 4 && (
                <PaymentsTableByCustomer customer_id={customerID} />
              )}
              {activeTab === 5 && (
                <div className="flex-1-container bg-white p-3">
                  {getCustomerDetails()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ReactModal
        className={"modal-large"}
        isOpen={reportState != ReportState.NONE}
      >
        <CustomerStatments
          customer_id={customerID}
          openOrders={reportState == ReportState.OPEN_ORDERS}
          onClose={(evt) => setReportState(ReportState.NONE)}
          onPDFReceived={(pdf) => {
            setReportState(ReportState.NONE);
            setBase64("data:application/pdf;base64," + pdf);
          }}
        />
      </ReactModal>

      <ReactModal
        className={"modal-h p-0"}
        isOpen={base64 != null}
        onRequestClose={(evt) => setBase64(null)}
      >
        <div className="flex-1-container">
          <embed
            width={"100%"}
            height={"100%"}
            src={base64}
            type="application/pdf"
          ></embed>
        </div>
      </ReactModal>
    </>
  );
};

export default CustomerProfilePage;

import './CompanyInfoEdit.scss';
import FormGroup from "../../../../components/FormGroup/FormGroup";
import {useEffect, useState} from "react";
import AddressSearch from "../../../../components/Google/AddressSearch";
import {Address} from "../../../../classes/classes";
import NotificationEmailEdit from "../NotificationEmailEdit/NotificationEmailEdit";
import Helper from "../../../../helper/Helper";
import { GraphqlService } from '../../../../services/graphql.service';
import { gql } from 'apollo-boost';
import useLoading from '../../../../hooks/useLoading/useLoading';
import { useToasts } from 'react-toast-notifications';

const mutationUpdateSettings = gql`mutation($key:String, $value:String){
    update_setting(key:$key, value:$value){
      id
      message
      success
    }
  }`;
const states = Helper.States;
const CompanyInfoEdit = ({onCancel, onSubmit, data}) => {
    const [address, setAddress] = useState(new Address());
    const [oEmail, setOEmail] = useState<string>('');
    const [iEmail, setIEmail] = useState<string>('');
    const [pEmail, setPEmail] = useState<string>('');
    const [qEmail, setQEmail] = useState<string>('');
    const [bEmail, setBEmail] = useState<string>('');
    const loading = useLoading();
    const toast = useToasts();
    const [currentCompanyInfo, setCurrentCompanyInfo] = useState<any>({});
    const [company, setCompany] = useState({
        name: '',
        street: '',
        country: '',
        city: '',
        state: '',
        zip: '',
        tax_id: '',
        tax_number: '',
        phone_number: '',
        fax_number: ''
    });

    function ssnFormat(ssn) {
        if (!ssn)
            ssn = '';
        var val = ssn.replace(/[^\d-]/g, '');

  // add the first dash if number from the second group appear
  val = val.replace(/^(\d{3})-?(\d{1,2})/, '$1-$2');

  // add the second dash if numbers from the third group appear
  val = val.replace(/^(\d{3})-?(\d{2})-?(\d{1,4})/, '$1-$2-$3');

  // remove misplaced dashes
  val = val.split('').filter((val, idx) => {
    return val !== '-' || idx === 3 || idx === 6;
  }).join('');
  

        // enforce max length
        return val.substring(0, 11);
    }

    function einFormat(ein) {
        if (!ein)
            ein = '';
        var val = ein.replace(/[^\d-]/g, '');

  // add the first dash if number from the second group appear
  val = val.replace(/^(\d{2})-?(\d{1,2})/, '$1-$2');

  // add the second dash if numbers from the third group appear
  val = val.replace(/^(\d{3})-?(\d{2})-?(\d{1,4})/, '$1-$2-$3');

  // remove misplaced dashes
  val = val.split('').filter((val, idx) => {
    return val !== '-' || idx === 2;
  }).join('');
  

        // enforce max length
        return val.substring(0, 10);
    }

    async function updateSetting(key, value) {
        return GraphqlService.SendMutation(mutationUpdateSettings, {key, value});
    }

    async function updateSubmit(evt) {
        loading.open('Updating Info....');
        try {
            
            await updateSetting('COMPANY_INFO', JSON.stringify(currentCompanyInfo));
            await updateSetting('ORDER_CONFIRMATION_EMAIL', oEmail);
            await updateSetting('INVOICE_EMAIL', iEmail);
            await updateSetting('PAYMENT_EMAIL', pEmail);
            await updateSetting('QUOTES_EMAIL', qEmail);
            await updateSetting('BOL_EMAIL', bEmail);

            loading.close();
            toast.addToast('Info Updated', {appearance: 'success', autoDismiss: true});
            onSubmit();
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    useEffect(() => {

       
        
        
        let info = data.find(e => e.key == "COMPANY_INFO");
            let parseInfo = JSON.parse(info.value);

            let orderConfirmationEmail = data.find(e => e.key == "ORDER_CONFIRMATION_EMAIL").value;
            let invoiceEmail = data.find(e => e.key == "INVOICE_EMAIL").value;
            let paymentEmail = data.find(e => e.key == "PAYMENT_EMAIL").value;
            let quotesEmail = data.find(e => e.key == "QUOTES_EMAIL").value;
            let bolEmail = data.find(e => e.key == "BOL_EMAIL").value;

            setOEmail(orderConfirmationEmail);
            setIEmail(invoiceEmail);
            setPEmail(paymentEmail);
            setQEmail(quotesEmail);
            setBEmail(bolEmail);
            setCurrentCompanyInfo(parseInfo);
    }, [data]);

    return(
        <>
            <div className="form-page">
                <div className="edit-form">
                    <div className="row">
                        <div className="col-6">
                            <p className="font-14 font-medium mb-3">Company info</p>

                            <FormGroup name={'name'}
                                       label={'Company name'}
                                       value={currentCompanyInfo.name}
                                       placeholder={'Company name...'}
                                       onTextChange={(value) => setCurrentCompanyInfo({...currentCompanyInfo, name: value})}/>

                        
                       
                       


                            <div className="col-12">
                                <div className="row">
                                    <FormGroup name={'tax_id'}
                                               label={'Tax Type'}
                                               placeholder={'-- Select --'}
                                               colSize={4}
                                               type={'select'}

                                               options={{data: ['SSN', 'EIN'], label: x=>x, value:x=>x}}
                                               value={currentCompanyInfo?.tax?.type}
                                               onTextChange={(value) => setCurrentCompanyInfo({...currentCompanyInfo, tax: {...currentCompanyInfo?.tax, type: value, 
                                               number: ''
                                               }})}/>

                                    <FormGroup name={'tax_number'}
                                               label={'Tax Number'}
                                               disabled={!currentCompanyInfo?.tax?.type}
                                                placeholder={(currentCompanyInfo?.tax?.type || '') != '' ? (currentCompanyInfo?.tax?.type == 'SSN' ? 'XXX-XX-XXXX' : 'XX-XXXXXXX') : '-'}
                                               colSize={8}
                                               value={currentCompanyInfo?.tax?.number}
                                               onTextChange={(value) => setCurrentCompanyInfo({...currentCompanyInfo, tax: {...currentCompanyInfo?.tax, number: currentCompanyInfo?.tax?.type == 'SSN' ? ssnFormat(value) : einFormat(value)}})}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-6">
                            <p className="font-14 font-medium mb-3">Contacts</p>

                            <FormGroup name={'phone_number'}
                                       label={'Phone #'}
                                       value={currentCompanyInfo.phone}
                                       placeholder={'xxx-xxx-xxxx'}
                                       required
                                       
                                       validation={x => Helper.Validators.IsValidPhone(x)}
                                       onTextChange={(value) => setCurrentCompanyInfo({...currentCompanyInfo, phone: Helper.Masks.USPhone(value)})}/>

                            <FormGroup name={'fax_number'}
                                       label={'FAX # (optional)'}
                                       placeholder={'xxx-xxx-xxxx'}
                                       value={currentCompanyInfo.fax}
                                       onTextChange={(value) => setCurrentCompanyInfo({...currentCompanyInfo, fax: Helper.Masks.USPhone(value)})}/>
                        </div>

                        <div className="col-12">
                            <AddressSearch className="col-12"
                                            onGotAddress={(newAddress) => {
                                                newAddress = new Address(newAddress);
                                                setCurrentCompanyInfo({...currentCompanyInfo, address: `${newAddress.getName(1)} ${newAddress.getName(2)}, ${newAddress.country}`})
                                            }}/>

                            <FormGroup label='Address' name={'address'} value={currentCompanyInfo?.address} onTextChange={(evt) => {
                                setCurrentCompanyInfo({...currentCompanyInfo, address: evt});
                            }} />
    
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <div className="col-12">
                            <p className="font-14 font-medium mb-3">Notification emails</p>


                            <div className="col-12 row">
                                <FormGroup colSize={6} name={'quotes_email'} value={qEmail} onTextChange={setQEmail} label={'Quotes'} required validation={Helper.Validators.IsValidEmail} />
                                <FormGroup colSize={6} name={'orders_email'} value={oEmail} onTextChange={setOEmail} label={'Orders'} required validation={Helper.Validators.IsValidEmail} />
                                <FormGroup colSize={6} name={'bols_email'} value={bEmail} onTextChange={setBEmail} label={'BOLs'} required validation={Helper.Validators.IsValidEmail} />
                                <FormGroup colSize={6} name={'invoices_email'} value={iEmail} onTextChange={setIEmail} label={'Invoices'} required validation={Helper.Validators.IsValidEmail} />
                                <FormGroup colSize={6} name={'payments_email'} value={pEmail} onTextChange={setPEmail} label={'Payments'} required validation={Helper.Validators.IsValidEmail} />

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row bottom">
                    <div className="col-12 text-right">
                        <button className="btn w-25 font-12 font-medium text-light-blue bg-white rounded-pill"
                                onClick={onCancel}>CANCEL</button>
                        <button className="btn w-25 font-12 font-medium btn-blue-light rounded-pill ml-3"
                                onClick={updateSubmit}>SAVE CHANGES</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CompanyInfoEdit;
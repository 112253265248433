import { gql } from 'apollo-boost';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { Stop } from '../../../../classes/classes';
import { GraphqlService } from '../../../../services/graphql.service';
import Loading from '../../../Loading/Loading';

const queryTimeAtAddress = gql`query($address: address_input, $atTime: String, $format:String){
    get_time_at_address(address: $address, atTime:$atTime, format:$format){
      time_formatted
      timezone {
            code
            name
        }
    }
  } `;

const queryUTCAtAddress = gql`query($address: address_input, $time_string: String!){
    get_utc_time_at_address(address: $address, time_string:$time_string)
  }`;

class TimeAtAddressResult {
    time_formatted: string = '';
    timezone: {
        code: string;
        name: string;
    };
}
const TimeFrameDiv: FC<{stop: Stop}> = ({stop}) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [data1, setData1] = useState<TimeAtAddressResult>(new TimeAtAddressResult());
    const [data2, setData2] = useState<TimeAtAddressResult>(new TimeAtAddressResult());

    const [_loading, setLoading] = useState(false);

    async function getDate() {
        try {
            const address = stop.location.address;
            setLoading(true);
            const r1 = await GraphqlService.SendQuery(queryTimeAtAddress, {address, atTime: stop.time_frame.start, format: 'MM/DD/YYYY HH12:MI AM'});
            const r2 = await GraphqlService.SendQuery(queryTimeAtAddress, {address, atTime: stop.time_frame.end, format: 'MM/DD/YYYY HH12:MI AM'});

            // setStartDate(data1.time_formatted);
            // setEndDate(data2.time_formatted);
            setData1(r1);
            setData2(r2);

            
            setLoading(false);

        } catch (ex) {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!stop.location.customer.id){
            setData1({time_formatted: moment(stop.time_frame.start).format('MM/DD/YYYY hh:mm A'), timezone: null});
            setData2({time_formatted: moment(stop.time_frame.end).format('MM/DD/YYYY hh:mm A'), timezone: null});
        }
        else
            getDate();
    }, [stop.location]);

    if (_loading)
        return <Loading />
    return <>
        <p className='font-10'>{data1.time_formatted.split(' ')[0]} - {data2.time_formatted.split(' ')[0]}</p>
        <p className='font-10'>{data1.time_formatted.split(' ')[1]} {data1.time_formatted.split(' ')[2]} - {data2.time_formatted.split(' ')[1]} {data2.time_formatted.split(' ')[2]}</p>
        {(data1 && data1.timezone) && <p className="font-10">({data1.timezone.code.replace('Standard ', '')})</p>}
    </>
}




export default TimeFrameDiv;
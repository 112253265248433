import {Settings} from "../settings";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";

export const all_settings = (input:{}) : Promise<Settings[]> => {
  let query = gql`
    query {
      all_settings {
        key
        value
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Settings[]>;
}

export const get_bol_disclaimer = (input:{}) : Promise<string[]> => {
  let query = gql`
    query {
      get_bol_disclaimer
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<string[]>;
}
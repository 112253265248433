import Helper from '../../helper/Helper';
import React, { useEffect, useRef, useState } from 'react';

import './LoginPage.scss';
import { GraphqlService } from '../../services/graphql.service';
import ICONS from '../../../assets/svg';
import environments from '../../../environments/environments';
import { useLocation } from 'react-router';
import { AuthenticationResult } from '../../services/Storage/classes';
import { SafeStorage } from '../../services/Storage/storageService';

const LoginPage = () => {
    const [username, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [businessID, setBusinessID] = useState<string>('')
    const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState(false);



    const userRef = useRef<HTMLInputElement>();
    const passwordRef = useRef<HTMLInputElement>();

    // const check = async () => {
    //     try {
    //         await GraphqlService.SendQuery(CHECK_AUTH, null, true);
    //         Helper.Navigation.NavigateTo('/');
    //     } catch (ex) {
            
    //     }
    // }

    
    const clear = () => {
        setUserName('')
        setPassword('')
        setBusinessID('')
    }


    const isValid = () => username.trim() && password.trim();


    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        try {
            let data = {
                username,
                password
            }
    
    
            if (!isValid() || loading) return;
            
            setLoading(true);
            let response: Response = await fetch(environments.LOGIN_LINK, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'secret'   
                },
                method: 'POST',
                body: JSON.stringify({username, password})
            });
            if(response.status != 200){
                throw new Error(await response.text())
            }
            let result: AuthenticationResult = await response.json();
    
            SafeStorage.saveAuthenticationResult(result);
            setLoading(false);

            Helper.Navigation.NavigateTo(`/${uri}`);

    
        } catch (ex) {
            setLoading(false)
            setErrorText(ex.message)
        }
    }

    const {pathname} = useLocation();

    // console.log()

    const array = pathname.split('/');

    array.splice(0, 2);

    // console.log()
    const uri = array.join('/');
    
    useEffect(() => {
        
    }, [])

    return (
        <>
        <div className='login-container'>
            <h4>Log In</h4>

            <form onSubmit={handleSubmit.bind(this)}>
                <div className='input-group'>
                    <img src={ICONS.IconUser} className='left' />
                    <input ref={userRef} value={username} onChange={(evt) => setUserName(evt.target.value)} id='name' name='username' placeholder='Username' />
                    {username != '' ? <img src={ICONS.IconDelete} className='right'  onClick={(evt) => {setUserName(''); userRef.current?.focus()}} /> : null} 
                </div>
                <div className='input-group'>
                    <img src={ICONS.IconLocked} className='left' />
                    <input ref={passwordRef} autoComplete='off' type='password' value={password} onChange={(evt) => setPassword(evt.target.value)} id='password' name='password' placeholder='Password' />
                    {password != '' ? <img src={ICONS.IconDelete} className='right'  onClick={(evt) => {setPassword(''); passwordRef.current?.focus()}} /> : null} 
                </div>

                {errorText && <p className='wrong'>{errorText}</p>}

                <button disabled={!isValid() || loading} type='submit'>LOG IN{loading && <span className='doing-login'>...<span></span></span>}</button>
              
            </form>
        </div>

      
        </>
    )
}

export default LoginPage;
import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {Tax} from "../tax";

export const all_taxes = (input:{}) : Promise<Tax[]> => {
  let query = gql`
    query {
      all_taxes {
        id
        name
        amount
        active
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Tax[]>;
}

export const tax_by_id = (input:{tax_id: number}) : Promise<Tax> => {
  let query = gql`
    query($tax_id: Int) {
      tax_by_id(tax_id: $tax_id) {
        id
        name
        amount
        active
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Tax>;
}
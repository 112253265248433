import FormGroup from "../FormGroup/FormGroup";
import Helper from "../../helper/Helper";
import Loading from "../Loading/Loading";
import { Charge, Tax } from "../../classes/classes";
import { useEffect, useState } from "react";
import { GraphqlService } from "../../services/graphql.service";
import TaxQueries from "../../graphql/query/Tax";
import { gql } from "apollo-boost";
import useLoading from "../../hooks/useLoading/useLoading";
import { useToasts } from "react-toast-notifications";
import Toggle from "../Toggle/Toggle";
import moment from "moment";
import DateTimePicker from "../DateTimePicker/DateTimePicker";
import Status from "../Status/Status";

const ChargeDiscountForm = ({
  charge,
  onCancel,
  onSubmit,
  master_order_id,
  vehicle_order_id,
  inventoryOnly = false,
}) => {
  const [pickerOpened, setPickerOpened] = useState(0);
  const [isDiscount, setIsDiscount] = useState(false);
  const [inventoryService, setInventoryService] = useState(false);

  // console.log(charge.base_amount);

  const [taxes, setTaxes] = useState<Tax[]>([]);
  const [tempCharge, setTempCharge] = useState<Charge>(charge);
  const [loadingTaxes, setLoadingTaxes] = useState(false);

  const toast = useToasts();

  const loading = useLoading();

  useEffect(() => {
    getAllTaxes().then();

    if (tempCharge && tempCharge.base_amount < 0) {
      setIsDiscount(true);
      setTempCharge({
        ...tempCharge,
        base_amount: -tempCharge.base_amount,
        amount: -tempCharge.amount,
      });
    }
  }, []);

  useEffect(() => {
    if (taxes.length > 0 && tempCharge.tax_amount > 0) {
      let tax = taxes.find(
        (x) =>
          +Number((+tempCharge.base_amount * (x?.amount || 0)) / 100).toFixed(
            2
          ) == tempCharge.tax_amount
      );
      setTempCharge({ ...tempCharge, tax_id: tax?.id });
    }
  }, [taxes]);

  useEffect(() => {
    setInventoryService(
      inventoryOnly ||
        (charge as Charge).inventory_service.service_charge_id != null
    );
  }, [charge]);

  async function getAllTaxes() {
    try {
      setLoadingTaxes(true);
      const data = await GraphqlService.SendQuery(TaxQueries.GET_ALL);
      setTaxes(data);
    } catch (ex) {
    } finally {
      setLoadingTaxes(false);
    }
  }

  async function submitServiceCharge() {
    const mutation = gql`
      mutation ($charge: new_charge_input_CD) {
        create_or_update_service_charge(charge: $charge) {
          id
        }
      }
    `;

    try {
      const c = {
        id: tempCharge.id,
        amount: isDiscount ? -tempCharge.amount : tempCharge.amount,
        tax_amount: tempCharge.tax_amount,
        base_amount: isDiscount
          ? -+tempCharge.base_amount
          : +tempCharge.base_amount,
        name: tempCharge.name,
        master_order_id: master_order_id,
        vehicle_order_id: vehicle_order_id,
        inventory_service: inventoryService
          ? {
              completed: tempCharge.inventory_service.completed,
              time_start: tempCharge.inventory_service.time_start,
              time_end: tempCharge.inventory_service.time_end,
              time_completed: tempCharge.inventory_service.time_completed,
            }
          : null,
      };
      loading.open(`${tempCharge.id ? "Updating" : "Creating"} charge...`);
      const data = await GraphqlService.SendMutation(mutation, { charge: c });

      console.log(c);

      c.id = data?.id;

      loading.close();
      toast.addToast(`Charge ${tempCharge.id ? "updated" : "created"}`, {
        appearance: "success",
        autoDismiss: true,
      });
      onSubmit();
    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
    }
  }

  const handleOnTextChange = (evt) => {
    let money = Helper.Masks.DecimalNumbers(evt);

    if (!isNaN(+money)) {
      let tax = +Number(
        (+money *
          (taxes.find((x) => x.id === tempCharge.tax_id)?.amount || 0)) /
          100
      ).toFixed(2);
      setTempCharge({
        ...tempCharge,
        base_amount: money as any,
        tax_amount: tax,
        amount: +money + tax,
      });
    }
  };

  const handleOnTaxTextChange = (evt) => {
    const money = tempCharge.base_amount;
    let tax = +Number(
      (+money * (taxes.find((x) => x.id === +evt)?.amount || 0)) / 100
    ).toFixed(2);

    setTempCharge({
      ...tempCharge,
      base_amount: money as any,
      tax_amount: tax,
      amount: +money + tax,
      tax_id: +evt,
    });
  };

  return (
    <>
      <div className="flex-1-container" style={{width: '100%'}}>
        <div className="col-12">
          <div className="row justify-content-start">
            <p className="font-14 font-medium mb-3">
              {tempCharge.id ? "Update" : "Add"}
            </p>
            <p
              className={`font-14 font-medium ml-2 cursor-pointer ${
                isDiscount && "text-blue-light"
              }`}
              onClick={() => {
                setIsDiscount(true);
                setTempCharge({
                  ...tempCharge,
                  tax_id: null,
                  tax_amount: 0,
                });
              }}
            >
              DISCOUNT
            </p>
            <p className={`font-14 font-medium ml-2`}>/</p>
            <p
              className={`font-14 font-medium ml-2 cursor-pointer ${
                !isDiscount && "text-blue-light"
              }`}
              onClick={() => {
                setIsDiscount(false);
              }}
            >
              CHARGE
            </p>
          </div>

          <div className="flex-1-container">
            <div className="row col-12">
              <div className="col-12">
                <FormGroup
                  value={tempCharge.name}
                  onTextChange={(name) =>
                    setTempCharge({ ...tempCharge, name })
                  }
                  colSize={12}
                  required
                  options={{
                    data: [
                      "Wait Time",
                      "Wash",
                      "Detail",
                      "No Fail",
                      "Driver Accommodation",
                      "Other",
                    ],
                    label: (x) => x,
                    value: (x) => x,
                  }}
                  type={"combobox"}
                  name="c_name"
                  label={`Charge Name`}
                  placeholder={`Charge Name...`}
                />
                <FormGroup
                  onTextChange={(evt) => {
                    handleOnTextChange(evt);
                  }}
                  colSize={12}
                  name="c_name"
                  label="Base amount"
                  value={tempCharge?.base_amount.toString()}
                  required
                />
              </div>


              {!isDiscount && (
                <>
                  <div className="col-12">
                    {loadingTaxes ? (
                      <>
                        <Loading />
                        <p className="text-center fs-14 ">Loading taxes...</p>
                      </>
                    ) : (
                      <FormGroup
                        value={(
                          tempCharge.tax_id || (taxes.length ? taxes[0].id : "")
                        ).toString()}
                        onTextChange={(evt) => {
                          handleOnTaxTextChange(evt);
                        }}
                        colSize={12}
                        placeholder="--Select tax--"
                        label="Tax"
                        name="c_tax"
                        type="select"
                        required
                        options={{
                          data: taxes,
                          label: (x) => x.name + " " + x.amount + "%",
                          value: (x) => x.id,
                        }}
                      />
                    )}
                  </div>

                  <div className="col-12">
                    <p className="fs-14 ">Tax amount</p>
                    <p className="fs-16 mt-3">
                      {Helper.Masks.ValidUSMoney(tempCharge.tax_amount)}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-3">
          {!inventoryOnly && (
            <div className="col-12 mb-3">
              <Toggle
                id={"inventory_service"}
                label={"This is an inventory service"}
                checked={inventoryOnly ? true : inventoryService}
                onCheck={(evt) => {
                  if (inventoryOnly) return;
                  setInventoryService(!inventoryService);
                }}
              />
            </div>
          )}

          {inventoryOnly && (
            <div className="mb-3 col-12">
              <h4 className="font-11">Inventory Service Details</h4>
            </div>
          )}

          {inventoryService && (
            <>
              <FormGroup
                readOnly
                onClick={(evt) => setPickerOpened(1)}
                className="position-relative"
                value={moment(tempCharge.inventory_service.time_start).format(
                  "MM/DD/YYYY"
                )}
                required
                colSize={12}
                name="period_start"
                type="text"
                label={`Time Start`}
                placeholder="Start Date..."
              >
                <i className="far fa-calendar-alt font-10"></i>
              </FormGroup>

              <FormGroup
                readOnly
                onClick={(evt) => setPickerOpened(2)}
                className="position-relative"
                value={moment(tempCharge.inventory_service.time_end).format(
                  "MM/DD/YYYY"
                )}
                required
                colSize={12}
                name="period_end"
                type="text"
                label={`Time End`}
                placeholder="End Date..."
              >
                <i className="far fa-calendar-alt font-10"></i>
              </FormGroup>

              <div className="col-12">
                <Status
                  className="fs-14 no-select p-2"
                  statusName={
                    tempCharge.inventory_service.completed
                      ? "COMPLETED"
                      : "INCOMPLETED"
                  }
                  onClick={(evt) => {
                    setTempCharge({
                      ...tempCharge,
                      inventory_service: {
                        ...tempCharge.inventory_service,
                        completed: !tempCharge.inventory_service.completed,
                      },
                    });
                  }}
                />
              </div>

              {/* <FormGroup readOnly className='position-relative d-flex mt-3'
                                checked={tempCharge.inventory_service.completed}
                                colSize={12} name='completed' type='checkbox'
                                onCheckChanged={(evt) => setTempCharge({...tempCharge, inventory_service: {...tempCharge.inventory_service, completed: evt}})}
                                label={`Completed`} placeholder='Not completed...'>
                        </FormGroup> */}

              <FormGroup
                readOnly
                disabled={!tempCharge.inventory_service.completed}
                onClick={(evt) =>
                  tempCharge.inventory_service.completed && setPickerOpened(3)
                }
                className="position-relative"
                value={
                  !tempCharge.inventory_service.completed
                    ? ""
                    : moment(
                        tempCharge.inventory_service.time_completed
                      ).format("MM/DD/YYYY hh:mm A")
                }
                colSize={12}
                name="time_completed"
                type="text"
                label={`Time Completed`}
                placeholder="Time Completed..."
              >
                <i className="far fa-calendar-alt font-10"></i>
              </FormGroup>
            </>
          )}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6 mt-3">
          <button
            className="btn rounded-pill btn-block btn-shy-light"
            onClick={() => {
              onCancel(true);
            }}
          >
            CANCEL
          </button>
        </div>
        <div className="col-6 mt-3">
          <button
            className="btn rounded-pill btn-block btn-blue-light"
            onClick={() => {
              // let ch = new Charge(tempCharge);
              // if (isDiscount) {
              //     ch.base_amount = -ch.base_amount;
              //     ch.amount = -ch.amount;
              // }

              // setIsDiscount(false);
              // setTempCharge(new Charge());

              // onSubmit(ch);
              submitServiceCharge();
            }}
          >
            {tempCharge.id ? "UPDATE" : "ADD"} CHARGE
          </button>
        </div>
      </div>

      {pickerOpened != 0 && (
        <DateTimePicker
          hideTime={pickerOpened != 3}
          date={
            pickerOpened == 1
              ? new Date(tempCharge.inventory_service.time_start)
              : pickerOpened == 2
              ? new Date(tempCharge.inventory_service.time_end)
              : new Date(tempCharge.inventory_service.time_completed)
          }
          onCancelClicked={(evt) => setPickerOpened(0)}
          onDateSubmitted={(evt) => {
            if (pickerOpened == 1)
              setTempCharge({
                ...tempCharge,
                inventory_service: {
                  ...tempCharge.inventory_service,
                  time_start: evt,
                },
              });
            else if (pickerOpened == 2)
              setTempCharge({
                ...tempCharge,
                inventory_service: {
                  ...tempCharge.inventory_service,
                  time_end: evt,
                },
              });
            else if (pickerOpened == 3)
              setTempCharge({
                ...tempCharge,
                inventory_service: {
                  ...tempCharge.inventory_service,
                  time_completed: evt,
                },
              });

            setPickerOpened(0);
          }}
        />
      )}
    </>
  );
};

export default ChargeDiscountForm;

import {gql} from "apollo-boost";
import {Stop} from "../stop";
import {GraphqlService} from "../../../../graphql.service";
import {Inspection} from "../../inspection/inspection";
import {Address_Input} from "../../customer/customer";

export const get_stop = (input:{stop_id: number}) : Promise<Stop> => {
  let query = gql`
    query($stop_id: Int) {
      get_stop(stop_id: $stop_id) {
        id
        location {
          id
          customer_id
          title
          internal_note
          driver_note
          timezoneName
        }
        is_pickup
        time_scheduled
        time_completed
        completed
        started
        canceled
        nofail
        contacts {
          id
          email
          verified
          name
          role
        }
        service_charges {
          id
          amount
          tax_amount
          base_amount
          name
          note
          voided
          amount_remaining
        }
        time_frame {
          end
          start
        }
        leg_id
        leg {
          id
          vehicle_order_id
          vehicle_order {
            id
          }
          time
          status
          time_completed
          completed
          canceled
          equipment
          velox_order_id
          service_charges {
            id
            amount
            tax_amount
            base_amount
            name
            note
            voided
            amount_remaining
          }
          driver {
            id
            name
            username
            equipment
            carrier_code
          }
          carrier {
            id
          }
          carrier_pay
          driver_pay
          remaining_carrier_receivable
          remaining_driver_payable
          velox_order {
            id
            accepted
            rejected
            canceled
            expired
            expires
          }
          pending_change_request
        }
        log {
          user_id
          message
          time
          vehicle_order_id
          leg_id
          stop_id
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {stop_id} as 
  Promise<Stop>;
}

export const get_inspection_by_stop_id = (input:{stop_id: number}) : Promise<Inspection[]> => {
  let query = gql`
    query($stop_id: Int) {
      get_inspection_by_stop_id(stop_id: $stop_id) {
        id
        performed_by {
          time
          name
          uid
          user_id
        }
        signed_by {
          time
          name
          uid
          user_id
        }
        user_id
        note
        odometer
        device_type
        vehicle {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        accessories {
          name
          count
          user_id
        }
        additional_emails
        damages {
          stop_id
          point {
            x
            y
          }
          code
          uid
          is_pickup
        }
        photos {
          uid
          type
          user_id
        }
        device_info {
          name
          type
          idiom
          model
          version
          platform
          manufacturer
        }
        flags {
          too_dirty
          too_dark
          raining
        }
        origin_inspection {
          origin_id
          driver_name
          driver_signature
          contact_name
        }
        destination_inspection {
          destination_id
          driver_name
          driver_signature
          contact_name
        }
        vehicle_data {
          id
          vin
          year
          make
          model
          submodel
          type
          no_vin
          special_vin
          incomplete_vin
          color
          license_plate
          internal_note
          time_created
          user_id
        }
        pending_inspection_id
        user {
          id
          name
          level
          active
        }
        time
        type
        vehicle_order {
          id
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {stop_id} as 
  Promise<Inspection[]>;
}

export const get_inspection_link = (input:{stop_id: number}) : Promise<string> => {
  let query = gql`
    query($stop_id: Int) {
      get_inspection_link(stop_id: $stop_id)
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {stop_id} as 
  Promise<string>;
}

export const get_inspection_by_vehicle_order = (input:{vehicle_order_id: number, is_pickup: Boolean}) : Promise<string> => {
  let query = gql`
    query($vehicle_order_id: Int, $is_pickup: Boolean) {
      get_inspection_by_vehicle_order(vehicle_order_id: $vehicle_order_id, is_pickup: $is_pickup) {
        id
        location {
          id
          customer_id
          title
          internal_note
          driver_note
          timezoneName
        }
        is_pickup
        time_scheduled
        time_completed
        completed
        started
        canceled
        nofail
        contacts {
          id
          email
          verified
          name
          role
        }
        service_charges {
          id
          amount
          tax_amount
          base_amount
          name
          note
          voided
          amount_remaining
        }
        time_frame {
          end
          start
        }
        leg_id
        leg {
          id
          vehicle_order_id
          vehicle_order {
            id
          }
          time
          status
          time_completed
          completed
          canceled
          equipment
          velox_order_id
          service_charges {
            id
            amount
            tax_amount
            base_amount
            name
            note
            voided
            amount_remaining
          }
          driver {
            id
            name
            username
            equipment
            carrier_code
          }
          carrier {
            id
          }
          carrier_pay
          driver_pay
          remaining_carrier_receivable
          remaining_driver_payable
          velox_order {
            id
            accepted
            rejected
            canceled
            expired
            expires
          }
          pending_change_request
        }
        log {
          user_id
          message
          time
          vehicle_order_id
          leg_id
          stop_id
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {vehicle_order_id, is_pickup} as 
  Promise<string>;
}

export const get_utc_time_at_address = (input:{address: Address_Input, time_string: string}) : Promise<string> => {
  let query = gql`
    query($address: address_input, $time_string: String!) {
      get_utc_time_at_address(address: $address, time_string: $time_string)
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {address, time_string} as 
  Promise<string>;
}
import React, { useState, useRef, useEffect } from "react";
import "./OrderDetailsPage.scss";
import { useParams } from "react-router";
import {
  Card,
  CustomerList,
  CustomerProfile,
  Inspection,
  Reference,
  Leg,
  ServiceCharge,
  StorageOrder,
  Vehicle,
  VehicleOrder,
} from "../../classes/classes";
import { Master_Order } from "../../services/endpoints/schemas/master_order/master_order";
import Status from "../../components/Status/Status";
import ICONS from "../../../assets/svg";
import { GraphqlService } from "../../services/graphql.service";
import MasterOrderMutations from "../../graphql/mutation/MasterOrder";
import MasterOrderQueries from "../../graphql/query/MasterOrder";
import OrderDetailsCustomerInfo from "../../components/OrderDetailsCustomerInfo/OrderDetailsCustomerInfo";
import OrderVehicles from "../../components/OrderVehicles/OrderVehicles";
import OrderBillingCustomer from "../../components/OrderBillingCustomer/OrderBillingCustomer";
import OrderPaymentList from "../../components/OrderPaymentList/OrderPaymentList";
import OrderInvoiceList from "../../components/OrderInvoiceList/OrderInvoiceList";
import VehicleDetails from "../../components/VehicleDetails/VehicleDetails";
import LegList from "../../components/Leg/Leg";
import VehicleInformation from "../../components/NewOrder/VehicleInformation/VehicleInformation";
import LegForm from "../../components/LegForm/LegForm";
import PriceBreakdown from "../../components/PriceBreakdown/PriceBreakdown";
import MenuOption from "../../components/MenuOption/MenuOption";
import MenuOptionItem from "../../components/MenuOptionItem/MenuOptionItem";
import Helper from "../../helper/Helper";
import Loading from "../../components/Loading/Loading";
import { gql } from "apollo-boost";
import VehicleOrderMutations from "../../graphql/mutation/VehicleOrder";
import { useToasts } from "react-toast-notifications";
import LegMutations from "../../graphql/mutation/Leg";
import ReactModal from "react-modal";
import CustomerSelection from "../../components/CustomerSelection/CustomerSelection";
import Modal from "../../components/Modal/Modal";
import OrderInvoicePdf from "../../components/OrderInvoicePdf/OrderInvoicePdf";
import LegDetail from "../../components/LegDetail/LegDetail";
import environments from "../../../environments/environments";
import moment from "moment";
import FormGroup from "../../components/FormGroup/FormGroup";
import useLoading from "../../hooks/useLoading/useLoading";
import SplitRoundtripLeg from "../../components/SplitRoundtripLeg/SplitRoundtripLeg";
import SendInvoices from "../../components/SendInvoices/SendInvoices";
import ProcessPayment from "../../components/ProcessPayment/ProcessPayment";
import { PaymentInfo } from "../../components/ProcessPayment/classes/classes";
import StorageOrderModal from "../../components/PriceBreakdown/StorageOrderModal";
import InvoicesPayments from "./InvoicesPayments/InvoicesPayments";
import CustomerCard from "../../components/NewOrder/CustomerCard/CustomerCard";
import MoveVehicles from "./MoveVehicles";
import PickupCustomer from "../../components/NewOrder/PickupCustomer/PickupCustomer";
import StopLegEntry from "../../components/NewOrder/StopLegEntry/StopLegEntry";
import { Queries } from "../../services/endpoints/queries";
import { Mutations } from "../../services/endpoints/mutations";
import {
  Basic_Id_Name_Input,
  Leg_Update_Input,
  Pickup_Dropoff_Stop,
} from "../../services/endpoints/schemas/leg/leg";
import { Vehicle_Order } from "../../services/endpoints/schemas/vehicle_order/vehicle_order";
import { Customer } from "../../services/endpoints/schemas/customer/customer";
import { Stop } from "../../services/endpoints/schemas/stop/stop";
import { Vehicle_Input } from "../../services/endpoints/schemas/vehicle/vehicle";
import { Mutation } from "../../services/endpoints/endpoints";
import { RequestInpsection } from "../WarehousePage/components/WarehouseTables/WarehouseTables";
import RequestInspectionForm from "../../components/LegTable/RequestInspection/RequestInspection";

const mutationAddVOrder = gql`
  mutation ($master_order_id: Int, $vehicle_order: vehicle_order_input) {
    add_vehicle_order_to_master_order(
      master_order_id: $master_order_id
      vehicle_order: $vehicle_order
    ) {
      id
      message
      success
    }
  }
`;
interface INewOrderInfo {
  customer: CustomerList;
  profiles_id: { customer_profile_id: number }[];
  leg_id: number;
  vo_id: number;
}

enum CustomerPicker {
  NONE,
  CUSTOMER,
  CONTACTS,
}

const OrderDetailsPage = () => {
  const nodeContainer = useRef<HTMLDivElement>();
  const toast = useToasts();

  const [showNewVehicleForm, setShowNewVehicleForm] = useState(false);
  const [openedMenu, setOpenedMenu] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(new PaymentInfo());

  const [base64, setBase64] = useState(null);

  const [picker, setPicker] = useState(CustomerPicker.NONE);
  const [newOrderInfo, setNewOrderInfo] = useState<INewOrderInfo>({
    customer: new CustomerList(),
    profiles_id: [],
    leg_id: null,
    vo_id: null,
  });
  const [isPickup, setIsPickup] = useState(null);
  const [newLeg, setNewLeg] = useState<Leg_Update_Input>(
    new Leg_Update_Input()
  );

  const loading = useLoading();

  const { order_id, verify } = useParams<any>();
  const [invoiceData, setInvoiceData] = useState(null);
  const [master, setMaster] = useState<Master_Order>(new Master_Order());
  const [tempMaster, setTempMaster] = useState(new Master_Order());
  const [defaultEmails, setDefaultEmails] = useState([]);
  const [sendInvoiceOpen, setSendInvoiceOpen] = useState(false);
  const [requestInspection, setRequestInspection] = useState<RequestInpsection>(
    new RequestInpsection()
  );

  const [selectedLeg, setSelectedLeg] = useState<{
    legNumber: number;
    leg: Leg;
    split: boolean;
  }>(undefined);
  const [selectedVehicle, setSelectedVehicle] = useState(undefined);
  const selectedVRef = useRef<any>();
  const [selectedBreakdown, setSelectedBreakdown] = useState(undefined);
  const [loadingText, setLoadingText] = useState("");
  const legListRef = useRef();
  const [canSendInvoice, setCanSendInvoice] = useState(false);
  const [seeLogs, setSeeLogs] = useState(false);
  const [quickAction, setQuickAction] = useState(0);
  const [deleteLeg, setDeleteLeg] = useState({ id: null, note: "" });

  const deleteNoteRef = useRef<HTMLTextAreaElement>();
  const cancelNoteRef = useRef<HTMLTextAreaElement>();
  const cancelAllNoteRef = useRef<HTMLTextAreaElement>();
  // Leg detail Modal

  const [references, setReferences] = useState<Reference[]>([]);
  const [referencesOpened, setReferencesOpened] = useState(false);

  const [showLegDetailModal, setShowLegDetailModal] = useState(undefined);

  const [storageSelected, setStorageSelected] = useState({
    index: null,
    storage: new StorageOrder(),
    charges: [],
  });

  const [moveOrder, setMoveOrder] = useState({ legs: [], vehicle_orders: [] });
  const [cancelOrder, setCancelOrder] = useState<{ id: number; note: string }>({
    id: null,
    note: "",
  });
  const [cancelVehicleOrder, setCancelVehicleOrder] = useState<{
    id: number;
    note: string;
  }>({
    id: null,
    note: "",
  });

  // NAVIGATIONS
  const VIEWS = {
    VEHICLE_DETAIL: "VEHICLE DETAIL",
    EDIT_VEHICLE: "EDIT VEHICLE",
    EDIT_LEG: "EDIT LEG",
    OPEN_BREAKDOWN: "OPEN BREAKDOWN",
    INVOICES_TABLE: "INVOICES_TABLE",
    PAYMENTS_TABLE: "PAYMENTS_TABLE",
  };
  const [currentView, setCurrentView] = useState(null);

  const [splitOpen, setSplitOpen] = useState(false);

  const [loadingNew, setLoadingNew] = useState(false);

  async function handleInspectionClicked(inspection_id: number) {
    try {
      loading.open("Getting inspection link...");
      const data = await Mutations.generate_inventory_inspection_link({
        inspection_id: inspection_id,
      }); // await GraphqlService.SendMutation(mutation, {inspection_id});
      const link = data;
      console.log(link);
      Object.assign(document.createElement("a"), {
        target: "_blank",
        href: link,
      }).click();
      loading.close();
    } catch (ex) {
      loading.close();
    }
  }

  const isCustomerDetailDifferent = () => {
    if (!master.id) return false;

    const oc_id = master.customer.id;
    const nc_id = tempMaster.customer.id;

    if (oc_id != nc_id) return true;

    const ocontacts_id = master.contacts
      .map((x) => x.id)
      .sort((x, y) => (x > y ? 1 : -1));
    const ncontacts_id = tempMaster.contacts
      .map((x) => x.id)
      .sort((x, y) => (x > y ? 1 : -1));

    if (JSON.stringify(ocontacts_id) != JSON.stringify(ncontacts_id))
      return true;

    const ocard = master.card?.id;
    const ncard = tempMaster.card?.id;

    if (ocard != ncard) return true;

    const oreferences = [...master.references]
      .sort((x, y) => (x.key > y.key ? 1 : -1))
      .map((x) => `${x.key}:${x.value}`)
      .join(",");
    const nreferences = [...tempMaster.references]
      .sort((x, y) => (x.key > y.key ? 1 : -1))
      .map((x) => `${x.key}:${x.value}`)
      .join(",");

    //console.table({oreferences, nreferences})
    if (JSON.stringify(oreferences) != JSON.stringify(nreferences)) return true;

    //console.log('NO DIFFERENCES');

    return false;
  };

  async function updateCustomerDetails() {
    const mutation = gql`
      mutation (
        $order_id: Int
        $references: [reference_input]
        $customer_id: Int
        $contacts: [Int]
        $card_id: Int
      ) {
        update_master_order_references(
          order_id: $order_id
          references: $references
          customer_id: $customer_id
          contacts: $contacts
          card_id: $card_id
        ) {
          success
          message
        }
      }
    `;

    try {
      loading.open("Updating...");
      const data = await GraphqlService.SendMutation(mutation, {
        order_id: master.id,
        references: tempMaster.references,
        customer_id: tempMaster.customer.id,
        contacts: tempMaster.contacts.map((x) => x.id),
        card_id: tempMaster.card.id,
      });
      if (!data?.success)
        throw new Error(data?.message || "Something went wrong");
      loading.close();
      toast.addToast(data.message, {
        appearance: "success",
        autoDismiss: true,
      });
      getOrderById().then();
    } catch (ex) {
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
      loading.close();
    }
  }

  function cancelUpdateCustomerDetails() {
    const temp = new Master_Order(tempMaster);

    if (temp.customer.id != master.customer.id)
      temp.customer = new Customer(master.customer);

    let ocids = master.contacts.map((x) => x.id);

    console.log(ocids);

    if (temp.customer.id == master.customer.id) {
      let contacts = temp.contacts.filter((x) => ocids.includes(x.id));

      let ncids = contacts.map((x) => x.id);

      console.log(ncids);

      temp.contacts = [
        ...contacts,
        ...master.contacts.filter((x) => !ncids.includes(x.id)),
      ];
    } else {
      temp.contacts = [...master.contacts];
    }

    temp.references = [...master.references];

    temp.card = new Card(master.card);

    setTempMaster(temp);
  }

  async function createNewMaster(orderInfo: INewOrderInfo) {
    try {
      setLoadingNew(true);
      const mutation = orderInfo.vo_id
        ? MasterOrderMutations.CREATE_FROM_VECHILE_ORDER
        : MasterOrderMutations.CREATE_FROM_LEG;
      const variables: any = {
        master_order: {
          customer_id: orderInfo.customer.id,
          contacts: orderInfo.profiles_id,
        },
      };

      if (orderInfo.leg_id) variables.leg_id = orderInfo.leg_id;
      else variables.vehicle_order_id = orderInfo.vo_id;

      setPicker(CustomerPicker.NONE);

      const data = await GraphqlService.SendMutation(mutation, variables);
      setLoadingNew(false);
      setNewOrderInfo({
        customer: new CustomerList(),
        profiles_id: [],
        leg_id: null,
        vo_id: null,
      });
      // //console.log(data);

      const link = "/order/" + data.id;
      console.log(link);
      Object.assign(document.createElement("a"), {
        target: "_blank",
        href: link,
      }).click();
      getOrderById().then((x) => setLoadingNew(false));
    } catch (ex) {
      console.log("ex", ex.message);
      setLoadingNew(false);
      setNewOrderInfo({
        customer: new CustomerList(),
        profiles_id: [],
        leg_id: null,
        vo_id: null,
      });
    }
  }

  async function getInvoice() {
    try {
      const data = await GraphqlService.SendQuery(
        MasterOrderQueries.GET_INVOICES,
        { order_id: master.id }
      );
      console.log(data);
      setInvoiceData(data);
    } catch (ex) {
      console.log(ex.message);
    }
  }

  function handleDeleteLeg(id) {
    setDeleteLeg({ id, note: "" });
    setTimeout(() => {
      // console.log()
      const textarea = document.getElementById(
        "cancel_reason"
      ) as HTMLTextAreaElement;

      if (textarea) {
        textarea.focus();
      }
    });
  }

  async function submitCancelOrder() {
    try {
      loading.open("Canceling Order...");
      console.log(cancelOrder);
      const data = await Mutations.cancel_master_order({
        master_order_id: cancelOrder.id,
        note: cancelOrder.note,
      });

      if (!data?.success)
        throw new Error(data?.message || "Something went wrong");

      setCancelOrder({ id: null, note: "" });

      loading.close();
      toast.addToast(data.message, {
        appearance: "success",
        autoDismiss: true,
      });
      getOrderById().then();
    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
    }
  }

  async function submitCancelVehicleOrder() {
    try {
      loading.open("Canceling Order...");
      console.log(cancelVehicleOrder);
      const data = await Mutations.cancel_vehicle_order({
        vehicle_order_id: cancelVehicleOrder.id,
        note: cancelVehicleOrder.note,
      });

      if (!data?.success)
        throw new Error(data?.message || "Something went wrong");

      setCancelVehicleOrder({ id: null, note: "" });

      loading.close();
      toast.addToast(data.message, {
        appearance: "success",
        autoDismiss: true,
      });
      getOrderById().then();
    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
    }
  }

  async function submitDeleteLeg() {
    const mutation = gql`
      mutation ($leg_id: Int, $note: String) {
        delete_leg(leg_id: $leg_id, note: $note) {
          id
          message
          success
        }
      }
    `;

    try {
      loading.open("Deleting leg...");

      const data = await GraphqlService.SendMutation(mutation, {
        leg_id: deleteLeg.id,
        note: deleteLeg.note?.trim(),
      });

      if (!data?.success)
        throw new Error(data?.message || "Something went wrong");

      setDeleteLeg({ id: null, note: "" });

      loading.close();
      toast.addToast(data.message, {
        appearance: "success",
        autoDismiss: true,
      });
      getOrderById().then();
    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
    }
  }

  function payMasterClicked() {
    const pi = new PaymentInfo();
    pi.mastersID = [master.id];
    pi.paymentPickerOpened = true;
    pi.customer_id = master.customer.id;
    console.log(master.service_charges);
    pi.payment_left = (
      master.service_charges.sumBy((x) => x.amount_remaining) +
      master.vehicle_orders.sumBy(
        (x) =>
          x.legs.sumBy(
            (l) =>
              l.stops.sumBy((s) =>
                s.service_charges.sumBy((y) => y.amount_remaining)
              ) + l.service_charges.sumBy((y) => y.amount_remaining)
          ) + x.charges.sumBy((y) => y.amount_remaining)
      )
    ).toString();
    setPaymentInfo(pi);
  }

  async function getOrderById(select_vo_id?) {
    let oid = +order_id;

    setMaster(new Master_Order());

    try {
      if (isNaN(oid) || oid < 1) {
        throw new Error("Invalid order number");
      }
      const data = await Queries.get_master_order_by_id({
        master_order_id: oid,
        verify: (verify || "").toLowerCase() == "verify",
      });
      const temp = new Master_Order(data);
      if (temp.id == null) throw new Error("Master not found");

      temp.references = temp.references.map((x) => ({
        key: x.key.toUpperCase(),
        value: x.value,
      }));
      const keys = temp.references.map((x) => x.key);
      if (!keys.includes("PO")) temp.references.push({ key: "PO", value: "" });
      if (!keys.includes("RO")) temp.references.push({ key: "RO", value: "" });
      if (!keys.includes("STOCK"))
        temp.references.push({ key: "STOCK", value: "" });
      loading.close();
      setTempMaster(new Master_Order(temp));

      setMaster(new Master_Order(temp));
      setDefaultEmails(
        temp.contacts
          .map((x) => [
            x.email,
            ...x.contact
              .filter((x) => x.type?.toLocaleLowerCase() == "email")
              .map((y) => y.value),
          ])
          .flatMap((z) => z)
      );

      let index = temp.vehicle_orders.findIndex(
        (x) => x.id == select_vo_id || selectedVRef.current?.vehicle?.id
      );
      if (index > -1) {
        onSelectVehicle({ index, vehicle: temp.vehicle_orders[index] }, true);
        let breakdown = temp.getServiceChargesBreakDown();
        setSelectedBreakdown(breakdown);
      } else if (temp.vehicle_orders.length > 0) {
        onSelectVehicle({ index: 0, vehicle: temp.vehicle_orders[0] });
      }
    } catch (ex) {
      console.log("ex", ex.message);
      loading.close();
      const m = new Master_Order();
      m.id = -1;
      setMaster(m);
      toast.addToast(ex.message, { appearance: "error" });
    }
  }

  const formatStop = (stop: Pickup_Dropoff_Stop, defaultIsPickup = true) => {
    if (!stop) {
      let newStop = new Stop();
      newStop.is_pickup = defaultIsPickup;
      return newStop;
    }
    let newStop: Stop = {
      time_scheduled: new Date(stop.time_scheduled),
      is_pickup: stop.is_pickup,
      location: {
        id: stop.location.id,
        title: stop.location.title,
        customer: new Customer(stop.location.customer as any),
        address: stop.location.address,
      },
      nofail: stop.nofail,
      nofail_price: +stop.nofail_price,
      contacts: stop.contacts.map((x) => ({ id: x.id })),
      time_frame: stop.time_frame,
    };
    return newStop;
  };

  const formatPickupDropoffStop = (stop: Stop) => {
    if (!stop) {
      return new Pickup_Dropoff_Stop();
    }
    let newStop: Pickup_Dropoff_Stop = {
      time_scheduled: stop.time_scheduled,
      is_pickup: stop.is_pickup,
      location: {
        id: stop.location.id,
        title: stop.location.title,
        customer: new Basic_Id_Name_Input(stop.location.customer),
        address: stop.location.address,
      },
      nofail: stop.nofail,
      nofail_price: +stop.nofail_price,
      contacts: stop.contacts.map((x) => ({ id: x.id })),
      time_frame: stop.time_frame,
    };
    console.log(newStop);
    return newStop;
  };

  function checkValidInputForLeg(leg: Leg_Update_Input) {
    if (!leg.equipment) {
      throw new Error("Missing Equipment");
    }

    if (!leg.pickup?.location?.customer?.id) {
      throw new Error(`Missing Origin address`);
    }
    if (!leg.dropoff?.location?.customer?.id) {
      throw new Error(`Missing Destination address`);
    }
    if (!leg.pickup?.contacts || leg.pickup?.contacts.length < 1) {
      throw new Error(`Missing Origin contact`);
    }
    if (!leg.dropoff?.contacts || leg.dropoff?.contacts.length < 1) {
      throw new Error(`Missing Destination contact`);
    }
    return true;
  }

  async function addNewLeg() {
    try {
      if (checkValidInputForLeg(newLeg)) {
        loading.open("Adding Leg...");
        // setLoadingText(legs[0].id == null ? 'ADDING LEG...' : 'UPDATING LEG...');
        const variables: Leg_Update_Input = {
          equipment: newLeg.equipment,
          equipment_price: newLeg.equipment_price,
          vehicle_order_id: selectedVRef.current.vehicle.id,
          pickup: newLeg.pickup,
          dropoff: newLeg.dropoff,
          master_order_id: master.id,
        };

        const data = await Mutations.add_leg({
          leg: variables,
          carrier_message: "",
          hours: 1,
        });
        // //console.log(data);
        if (!data?.success) throw new Error(data?.message);
        toast.addToast(data.message, {
          appearance: "success",
          autoDismiss: true,
        });
        setCurrentView(VIEWS.VEHICLE_DETAIL);
        getOrderById();
        loading.close();
      }
    } catch (ex) {
      toast.addToast(ex.message || "Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      });
      setLoadingText("");
      loading.close();
      console.log(ex.message);
    }
  }

  // function splitLeg(evt) {
  //     const temp = {...selectedLeg?.leg} as Leg;
  //     const sc = new ServiceCharge();
  //     sc.name = evt.equipment;
  //     sc.amount = evt.equipment_price;
  //     sc.base_amount = evt.equipment_price;
  //     temp.service_charges = [sc];
  //     const leg1 = new Leg(temp);

  //     const dindex1 = leg1.stops.findIndex(x => !x.is_pickup);

  //     console.log(evt);

  //     const drop1 = {...leg1.stops[dindex1]};

  //     leg1.stops[dindex1] = {...evt.dropoff};

  //     const leg2 = new Leg();
  //     const dindex2 = leg2.stops.findIndex(x => !x.is_pickup);

  //     leg2.stops[dindex2 == 1 ? 0 : 1] = {...evt.pickup};
  //     leg2.stops[dindex2] = {...drop1};

  //     leg1.equipment_price = evt.equipment_price;
  //     leg1.equipment = evt.equipment;

  //     leg2.equipment_price = evt.equipment_price_new;
  //     leg2.equipment = evt.equipment_new;

  //     setSelectedLeg({...selectedLeg, leg: leg1});
  //     setCurrentView(VIEWS.EDIT_LEG);
  //     setSplitOpen(false);
  // }

  async function donwloadInvoice() {
    loading.open("Getting invoice...");
    const query = gql`
      query ($master_order_id: Int, $office: Boolean) {
        download_new_invoice(master_order_id: $master_order_id, office: $office)
      }
    `;
    try {
      const data = await GraphqlService.SendMutation(query, {
        master_order_id: master.id,
        office: true,
      });
      setCanSendInvoice(true);
      setBase64("data:application/pdf;base64," + data);

      loading.close();
    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
    }
  }

  async function donwloadByIDInvoice(invoice_id, send) {
    loading.open("Getting invoice...");
    const query = gql`
      query ($invoice_id: Int) {
        download_existing_invoice(invoice_id: $invoice_id)
      }
    `;
    try {
      const data = await GraphqlService.SendMutation(query, { invoice_id });

      setBase64("data:application/pdf;base64," + data);
      setCanSendInvoice(send);

      loading.close();
    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
    }
  }

  useEffect(() => {
    setCurrentView(VIEWS.VEHICLE_DETAIL);
  }, []);

  async function updateVehicleOrder(vorder: VehicleOrder) {
    try {
      // console.log(vorder.vehicle);

      console.log("Here");
      if (vorder.id != null) {
        setLoadingText("UPDATING VEHICLE ORDER #" + vorder.id);
        const v = { ...vorder.vehicle };
        delete v.time_created;
        const data = await GraphqlService.SendMultipleMutation(
          VehicleOrderMutations.UPDATE_VEHICLE_ORDER,
          {
            vehicle_order: {
              bol_comment: vorder.bol_comment,
              driver_note: vorder.driver_note,
              started: vorder.started,
              delivered: vorder.delivered,
            },
            vehicle_order_id: vorder.id,
            vehicle: new Vehicle_Input(v),
          }
        );
        //console.log(data);
      }
      const temp = new Master_Order(master);
      temp.vehicle_orders[selectedVehicle.index] = vorder;
      onSelectVehicle({ ...selectedVehicle, vehicle: vorder });
      setMaster(temp);

      setLoadingText("");
    } catch (ex) {
      setLoadingText("");
      toast.addToast(ex.message, { appearance: "error" });
    }
  }

  useEffect(() => {
    getOrderById().then();
  }, [order_id]);

  useEffect(() => {
    //console.log('MASTER CHANGED', master.vehicle_orders.map(v => v.legs.map(l => l.getPickup().location.address)));
  }, [master]);

  async function addVehicleOrder(vehicle_order: Vehicle_Order) {
    try {
      loading.open("Adding Vehicle Order...");
      const data = await GraphqlService.SendMutation(mutationAddVOrder, {
        vehicle_order: {
          vehicle: {
            id: vehicle_order.vehicle.id,
            vin: vehicle_order.vehicle.vin,
            year: vehicle_order.vehicle.year,
            make: vehicle_order.vehicle.make,
            model: vehicle_order.vehicle.model,
            submodel: vehicle_order.vehicle.submodel,
            type: vehicle_order.vehicle.type,
            no_vin: vehicle_order.vehicle.no_vin,
            special_vin: vehicle_order.vehicle.special_vin,
            incomplete_vin: vehicle_order.vehicle.incomplete_vin,
            color: vehicle_order.vehicle.color,
            license_plate: vehicle_order.vehicle.license_plate,
            internal_note: vehicle_order.vehicle.internal_note,
          },
        },
        master_order_id: master.id,
      });
      if (!data?.success) {
        throw new Error(data?.message || "Something went wrong");
      }

      setShowNewVehicleForm(false);
      loading.close();
      toast.addToast(data.message, {
        appearance: "success",
        autoDismiss: true,
      });
      getOrderById(data.id);
    } catch (ex) {
      loading.close();
      toast.addToast(ex.message, { appearance: "error", autoDismiss: true });
    }
  }

  const onSelectVehicle = ({ index, vehicle }, keepView = false) => {
    if (!keepView) setCurrentView(VIEWS.VEHICLE_DETAIL);
    setSelectedVehicle({ index, vehicle });
    selectedVRef.current = { index, vehicle };
  };

  const handleOrderBillingCustomer = (breakdown) => {
    setCurrentView(VIEWS.OPEN_BREAKDOWN);
    setSelectedBreakdown(breakdown);
  };

  function handleRequestInspection() {
    const vo_id = selectedVehicle?.vehicle?.id;
    if (!vo_id) {
      return;
    }

    const temp = new RequestInpsection();
    temp.vehicle_order_id = vo_id;
    setRequestInspection(temp);
  }

  function handleEditLeg() {
    let infoLeg = {
      legNumber: selectedVehicle?.legs?.length || 0,
      leg: new Leg(),
      split: false,
    };
    setSelectedLeg(infoLeg);

    // Show modal if not is JP
    // if (infoLeg) {

    //     if (infoLeg.leg.carrier.id && infoLeg.leg.carrier.id != environments.JP_CARRIER_ID) {
    //         return setShowLegDetailModal(infoLeg)
    //     }

    //     if (infoLeg && infoLeg.split) {
    //         return setSplitOpen(true)
    //     }
    // }
    setCurrentView(VIEWS.EDIT_LEG);
  }
  const handleRountripLeg = (infoLeg) => {
    let leg = new Leg(infoLeg.leg);
    leg.id = null;
    leg.stops.forEach((x) => (x.is_pickup = !x.is_pickup));
    // leg.getPickup().

    let timeDiff = Math.abs(
      moment(leg.getPickup().time_scheduled).diff(
        moment(leg.getDropoff().time_scheduled),
        "minutes"
      )
    );

    let date = new Date();

    let pickup = leg.getPickup();
    pickup.time_scheduled = date;

    let dropoff = leg.getDropoff();
    dropoff.time_scheduled = moment(date).add(timeDiff, "minutes").toDate();

    leg.setStop(pickup);
    leg.setStop(dropoff);

    infoLeg.leg = leg;

    setSelectedLeg(infoLeg);

    setCurrentView(VIEWS.EDIT_LEG);
    // setShowLegDetailModal({})
  };

  const handleSplitLeg = (legs) => {
    console.log(selectedLeg.leg);
    console.log(legs);
  };

  return (
    <>
      <ReactModal isOpen={requestInspection.vehicle_order_id != null}>
        <RequestInspectionForm
          data={requestInspection}
          onClose={(success) => {
            setRequestInspection(new RequestInpsection());
          }}
        />
      </ReactModal>
      <div className="flex-1-container">
        <div className="mb-3 row">
          <div className="d-flex">
            <div className="d-flex mr-2">
              <h4 className="fs-18 mr-2">M-{order_id}</h4>
              <Status statusName="STATUS PLACEHOLDER" />
              <h4 className="ml-2 fs-18">
                {Helper.FORMAT.USCurrency(master?.price)} /{" "}
                {master.payment_left.toCurrency()}
              </h4>
            </div>
          </div>
          <div className="row align-items-center">
            <h4 className="mr-2 font-12 font-light">
              Booked on: {Helper.FORMAT.USDate(master?.time, "dd")}{" "}
              {Helper.FORMAT.USDate(master?.time)}
            </h4>
            <div
              ref={nodeContainer}
              className="position-relative col-0-5 d-flex justify-content-end "
            >
              <div
                onClick={() => {
                  setOpenedMenu(!openedMenu);
                }}
                className={`cursor-pointer`}
              >
                <div
                  style={{ borderTop: "2px solid #035B96", width: "18px" }}
                ></div>
                <div
                  style={{
                    borderTop: "2px solid #035B96",
                    width: "18px",
                    margin: "4px 0",
                  }}
                ></div>
                <div
                  style={{ borderTop: "2px solid #035B96", width: "18px" }}
                ></div>
              </div>

              <MenuOption
                nodeContainer={nodeContainer}
                visible={openedMenu}
                handleMenuOPtionClick={() => setOpenedMenu(false)}
              >
                <MenuOptionItem
                  icon={ICONS.IconClock}
                  handleItemClick={(ev) => {
                    setOpenedMenu(false);
                    setSeeLogs(true);
                  }}
                >
                  See log
                </MenuOptionItem>
                <MenuOptionItem
                  icon={ICONS.IconSeeInvoice}
                  handleItemClick={(ev) => {
                    setOpenedMenu(false);
                    donwloadInvoice().then();
                  }}
                >
                  See Invoice
                </MenuOptionItem>
                <MenuOptionItem
                  icon={ICONS.IconCreateInvoice}
                  handleItemClick={(ev) => {
                    setSendInvoiceOpen(true);
                    setOpenedMenu(false);
                  }}
                >
                  Send Invoice
                </MenuOptionItem>
                <MenuOptionItem
                  icon={ICONS.IconProcessPayment}
                  handleItemClick={(ev) => {
                    setOpenedMenu(false);
                    payMasterClicked();
                  }}
                >
                  Process Payment
                </MenuOptionItem>
                <MenuOptionItem
                  icon={ICONS.IconReceipt}
                  handleItemClick={(ev) => {
                    console.log("event");
                  }}
                >
                  See receipt
                </MenuOptionItem>

                <MenuOptionItem
                  icon={ICONS.IconDelete}
                  handleItemClick={(ev) => {
                    setCancelOrder({ id: master.id, note: "" });
                  }}
                >
                  Cancel order
                </MenuOptionItem>
              </MenuOption>
            </div>
          </div>
        </div>
        <div className="flex-1-container">
          <div className="mb-3 bg-white">
            {!master.id && <Loading />}
            {master.id && (
              <CustomerCard
                openCustomersByDefault={false}
                references={tempMaster.references}
                card={tempMaster.card}
                customer={tempMaster.customer}
                customerProfiles={tempMaster.contacts}
                quickAction={quickAction}
                onReferencesChanged={(evt) => {
                  const temp = new Master_Order(tempMaster);
                  temp.references = [...evt];
                  setTempMaster(temp);
                }}
                onEditChanged={(evt) => {}}
                onChangeCustomer={(evt, contacts) => {
                  const temp = new Master_Order(tempMaster);
                  temp.customer = new Customer(evt);
                  temp.contacts = contacts.map((c) => new CustomerProfile(c));

                  setTempMaster(temp);
                }}
                onCardChanged={(evt) => {
                  const temp = new Master_Order(tempMaster);
                  temp.card = new Card(evt);
                  setTempMaster(temp);
                }}
              />
            )}

            {isCustomerDetailDifferent() && (
              <div className="mb-3 d-flex justify-content-end mr-3">
                <div className="d-flex">
                  <button
                    onClick={(evt) => cancelUpdateCustomerDetails()}
                    className="btn btn-clear text-blue-light mr-2 px-5 py-2 rounded-pill"
                  >
                    CANCEL
                  </button>
                  <button
                    onClick={(evt) => updateCustomerDetails()}
                    className="btn btn-blue-light px-5 py-2 rounded-pill"
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            )}
          </div>
          <div
            style={{ minHeight: "100%", overflow: "visible", display: "flex" }}
          >
            <div className="flex-1-container">
              <div className="col-12 mb-3">
                <div className="row">
                  <div className="col-4 col-lg-11 d-flex justify-content-between"></div>
                </div>
              </div>

              <div className="flex-1-container row-container">
                <div className="col-3 col-lg-12 align-items-start">
                  <div className="row scroll" style={{ padding: "0" }}>
                    <div className="col-12 px-1 pb-2">
                      {(master?.id && (
                        <OrderVehicles
                          onCancelClicked={({ vo_id }) => {
                            setCancelVehicleOrder({ id: vo_id, note: "" });
                          }}
                          onNewClicked={(evt) => {
                            // setNewOrderInfo({...newOrderInfo, ...evt});
                            // setPicker(CustomerPicker.CUSTOMER);
                            // console.log('Here');
                            setMoveOrder({
                              vehicle_orders: master.vehicle_orders,
                              legs: [],
                            });
                          }}
                          index={selectedVehicle?.index}
                          onAddNewVehicleClicked={(evt) => {
                            setShowNewVehicleForm(true);
                          }}
                          vehicles={master?.vehicle_orders}
                          onSelectVehicle={onSelectVehicle}
                        />
                      )) || <Loading />}
                    </div>
                  </div>
                </div>

                <div className="col-9 col-lg-12 d-flex flex-column justify-content-between p-0 pr-1">
                  <div className="d-flex mb-3">
                    <h4
                      onClick={(evt) => setCurrentView(VIEWS.VEHICLE_DETAIL)}
                      className={
                        "cursor-pointer mr-2 font-10" +
                        (currentView == VIEWS.VEHICLE_DETAIL
                          ? ""
                          : " text-gray")
                      }
                    >
                      Order{" "}
                      {selectedVehicle?.index != null ? (
                        <span>for vehicle {selectedVehicle?.index + 1}</span>
                      ) : null}
                    </h4>
                    <h4
                      onClick={(evt) => {
                        setCurrentView(VIEWS.OPEN_BREAKDOWN);
                        let breakdown = master.getServiceChargesBreakDown();
                        console.log("data: ", master);
                        console.log("breakdown: ", breakdown);
                        setSelectedBreakdown(breakdown);
                      }}
                      className={
                        "cursor-pointer mr-2 font-10" +
                        (currentView == VIEWS.OPEN_BREAKDOWN
                          ? ""
                          : " text-gray")
                      }
                    >
                      Breakdown
                    </h4>

                    <h4
                      onClick={(evt) => setCurrentView(VIEWS.INVOICES_TABLE)}
                      className={
                        "cursor-pointer mr-2 font-10" +
                        (currentView == VIEWS.INVOICES_TABLE
                          ? ""
                          : " text-gray")
                      }
                    >
                      Invoices / Payments
                    </h4>
                  </div>
                  <div
                    className="flex-1-container p-3 bg-white"
                    style={{
                      boxShadow: "0px 0px 8px #CCC",
                      borderRadius: "8px",
                    }}
                    ref={legListRef}
                  >
                    {currentView == VIEWS.VEHICLE_DETAIL ? (
                      <div className="col-12">
                        {(master?.id && !loadingText && (
                          <>
                            {selectedVehicle?.index != null && (
                              <>
                                <div className="row">
                                  <div className="col-12 d-flex justify-content-start align-items-center mb-4">
                                    <p className="fs-12 font-medium mr-3">
                                      Vehicle {selectedVehicle?.index + 1}
                                    </p>
                                    <Status statusName="IN TRANSIT" />
                                  </div>
                                </div>
                                <div className="row col-12">
                                  <div className="col-6">
                                    <VehicleDetails
                                      index={selectedVehicle?.index}
                                      onMarkAsStorageClicked={
                                        setStorageSelected
                                      }
                                      vehicle={selectedVehicle?.vehicle}
                                      onEditVehicleBtnClicked={() =>
                                        setCurrentView(VIEWS.EDIT_VEHICLE)
                                      }
                                    />
                                  </div>
                                  <div className="col-6">
                                    <div className="row">
                                      <p className="fs-14 font-medium mb-3">
                                        Previous Inspections:{" "}
                                        {
                                          selectedVehicle?.vehicle?.inspections
                                            ?.length
                                        }
                                      </p>
                                      <button
                                        className="btn btn-blue-light rounded-pill px-3 py-2"
                                        onClick={handleRequestInspection}
                                      >
                                        REQUEST INSPECTION
                                      </button>
                                    </div>
                                    <div className="row">
                                      {selectedVehicle?.vehicle?.inspections?.map(
                                        (inspection: Inspection) => (
                                          <div
                                            onClick={(evt) =>
                                              handleInspectionClicked(
                                                inspection.id
                                              )
                                            }
                                            className="rounded bg-gray p-2 col-6 cursor-pointer"
                                          >
                                            <div className="row mb-1">
                                              <h4 className="fs-12 font-normal">
                                                {inspection.user.name}
                                              </h4>
                                              <h4 className="fs-12 font-normal">
                                                {Helper.FORMAT.USDate(
                                                  inspection.time,
                                                  "do"
                                                )}
                                              </h4>
                                            </div>
                                            <div className="row">
                                              <h4 className="fs-12 font-normal">
                                                {inspection.type}
                                              </h4>
                                              {/* <h4 className="fs-12 font-normal">Manolo</h4> */}
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <LegList
                                  onMoveLegClicked={(evt) => {
                                    setMoveOrder({
                                      vehicle_orders: [
                                        selectedVehicle?.vehicle,
                                      ],
                                      legs: selectedVehicle?.vehicle?.legs,
                                    });
                                  }}
                                  onDeleteLegClicked={handleDeleteLeg}
                                  onRountripLegBtnClicked={handleRountripLeg}
                                  parentRef={legListRef}
                                  legs={selectedVehicle?.vehicle?.legs}
                                  onEditLegBtnClicked={handleEditLeg}
                                />
                              </>
                            )}

                            {selectedVehicle?.index == null && (
                              <div>
                                <h2 className="font-12 text-center">
                                  No Vehicle Selected
                                </h2>
                              </div>
                            )}
                          </>
                        )) || (
                          <>
                            <Loading />
                            {loadingText && (
                              <h2 className="fs-16 text-center">
                                {loadingText}
                              </h2>
                            )}
                          </>
                        )}
                      </div>
                    ) : null}

                    {currentView == VIEWS.EDIT_LEG
                      ? (master?.id && !loadingText && (
                          <>
                            {/* <LegForm legNumber={selectedLeg?.legNumber}
                                                    leg={selectedLeg?.leg}
                                                    legSplit={selectedLeg?.legSplit}
                                                    onUpdateLeg={(legs: Leg[]) => {
                                                        if (!legs) return setCurrentView(VIEWS.VEHICLE_DETAIL);
                                                        updateLeg(legs).then();
                                                    }}
                                            /> */}
                            <div className="row col-12">
                              <div className="col-12">
                                <Modal
                                  show={isPickup != null}
                                  onClose={() => setIsPickup(null)}
                                >
                                  <PickupCustomer
                                    onConfirmClicked={(evt) => {
                                      const temp = { ...newLeg };
                                      console.log("IS PICKUP IS: ", isPickup);
                                      if (isPickup) {
                                        temp.pickup =
                                          formatPickupDropoffStop(evt);
                                      } else {
                                        temp.dropoff =
                                          formatPickupDropoffStop(evt);
                                      }
                                      setNewLeg(temp);
                                      setIsPickup(null);
                                    }}
                                    stop={formatStop(
                                      newLeg[isPickup ? "pickup" : "dropoff"]
                                    )}
                                    onCancelClicked={(evt) => setIsPickup(null)}
                                  />
                                </Modal>
                              </div>
                              <div className="col-12 row mb-2 align-items-start">
                                <div className="row col-9">
                                  <p className="font-12">{`New Leg for ${(
                                    selectedVRef.current.vehicle.vehicle.make +
                                    " " +
                                    selectedVRef.current.vehicle.vehicle.model
                                  ).trim()}`}</p>
                                </div>
                                {/* {props.index == props.last_index && <button onClick={props.roundTripClicked} className="btn btn-danger-outline mr-2">Round Trip
                                                    </button>} */}
                                <button
                                  onClick={(evt) => {
                                    setNewLeg(new Leg_Update_Input());
                                    setCurrentView(VIEWS.VEHICLE_DETAIL);
                                  }}
                                  className="btn btn-danger-outline"
                                >
                                  X
                                </button>
                              </div>
                              <div className={`row align-items-start col-12`}>
                                <div className="row col-6">
                                  <StopLegEntry
                                    stop={formatStop(newLeg.pickup)}
                                    sameAsBillingClicked={() => {
                                      //SAME AS BILLING CLICKED
                                    }}
                                    stopChanged={(stopData) => {
                                      const temp = { ...newLeg };
                                      temp.pickup =
                                        formatPickupDropoffStop(stopData);
                                      setNewLeg(temp);
                                      setIsPickup(null);
                                    }}
                                  />
                                </div>
                                <div className="row col-6">
                                  <StopLegEntry
                                    stop={formatStop(newLeg.dropoff, false)}
                                    sameAsBillingClicked={() => {
                                      //SAME AS BILLING CLICKED
                                    }}
                                    stopChanged={(stopData) => {
                                      const temp = { ...newLeg };
                                      temp.dropoff =
                                        formatPickupDropoffStop(stopData);
                                      setNewLeg(temp);
                                      setIsPickup(null);
                                    }}
                                  />
                                </div>
                              </div>
                              {/* DRIVER  */}
                              <div className="row col-12 mt-3">
                                <div className="col-6">
                                  <div
                                    className={`row pt-1 px-2 align-item-start`}
                                    style={{
                                      border: "2px solid #CCC",
                                      borderRadius: 12,
                                    }}
                                  >
                                    <div className="col-12 ">
                                      <div className="row">
                                        <FormGroup
                                          colSize={6}
                                          label={"Equipment"}
                                          value={newLeg.equipment}
                                          onTextChange={(evt) => {
                                            const temp = { ...newLeg };
                                            temp.equipment = evt;
                                            setNewLeg(temp);
                                          }}
                                          placeholder={"--select--"}
                                          name={"equipment"}
                                          type="select"
                                          options={{
                                            data: [
                                              "Flatbed",
                                              "Enclosed",
                                              "Enclosed Premium",
                                              "Open",
                                            ],
                                            label: (x) => x,
                                            value: (x) => x,
                                          }}
                                        />
                                        <FormGroup
                                          type="text"
                                          moneySymbol
                                          colSize={6}
                                          label={"Charge"}
                                          onTextChange={(evt) => {
                                            evt =
                                              Helper.Masks.DecimalNumbers(evt);

                                            const temp = { ...newLeg };
                                            temp.equipment_price = +evt as any;
                                            setNewLeg(temp);
                                          }}
                                          value={newLeg.equipment_price.toString()}
                                          placeholder={"$0.00"}
                                          name={"charge"}
                                          onFocus={(evt) => {
                                            evt.target.select();
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {
                                  <button
                                    onClick={async (evt) => {
                                      await addNewLeg();
                                    }}
                                    className="btn btn-danger-outline col-6 font-14 font-medium"
                                  >
                                    Save
                                  </button>
                                }
                              </div>
                            </div>
                          </>
                        )) || (
                          <>
                            <div className="col-12">
                              <Loading />
                              {loadingText && (
                                <h2 className="fs-16 text-center">
                                  {loadingText}
                                </h2>
                              )}
                            </div>
                          </>
                        )
                      : null}

                    {currentView == VIEWS.EDIT_VEHICLE
                      ? (master?.id && (
                          <VehicleInformation
                            masterID={master.id}
                            vehicleOrder={
                              new VehicleOrder(selectedVehicle?.vehicle)
                            }
                            onVehicleOrdersChanged={(evt) => {
                              setCurrentView(VIEWS.VEHICLE_DETAIL);
                              if (!evt) return;

                              updateVehicleOrder(evt).then();
                            }}
                          />
                        )) || <Loading />
                      : null}

                    {currentView == VIEWS.OPEN_BREAKDOWN ? (
                      <div className="col-12 flex-1-container">
                        {(master?.id && (
                          <PriceBreakdown
                            onChargesUpdated={() => {
                              // setLoadingText('LOADING...');
                              // setCurrentView(VIEWS.VEHICLE_DETAIL);
                              getOrderById().then();
                            }}
                            breakdown={selectedBreakdown}
                          />
                        )) || <Loading />}
                      </div>
                    ) : null}
                    {currentView == VIEWS.INVOICES_TABLE && (
                      <InvoicesPayments
                        payment_left={master.payment_left}
                        needs_invoice={master.needs_invoice}
                        master_id={master.id}
                        onSeeInvoiceClick={(invoice_id, cansend) => {
                          donwloadByIDInvoice(invoice_id, cansend);
                        }}
                        onSendNewClick={() => {
                          setSendInvoiceOpen(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ReactModal
        className={"modal-h"}
        onRequestClose={(evt) => {
          setNewOrderInfo({
            ...newOrderInfo,
            profiles_id: [],
            customer: new CustomerList(),
            leg_id: null,
            vo_id: null,
          });
          setPicker(CustomerPicker.NONE);
        }}
        isOpen={picker != CustomerPicker.NONE}
      >
        <CustomerSelection
          onCancel={() => console.log("onCancel from orderDetailsPage")}
          topRightDiv={
            <img
              className="cursor-pointer"
              onClick={(evt) => {
                setNewOrderInfo({
                  ...newOrderInfo,
                  profiles_id: [],
                  customer: new CustomerList(),
                  leg_id: null,
                  vo_id: null,
                });
                setPicker(CustomerPicker.NONE);
              }}
              src={ICONS.IconCloseBig}
              style={{ marginTop: "-.45rem" }}
            />
          }
          onCustomerSelected={(c, stops, profiles) => {
            const no = {
              ...newOrderInfo,
              customer: new CustomerList(c),
              profiles_id: profiles.map((x) => ({ customer_profile_id: x.id })),
            };
            setNewOrderInfo(no);
            setPicker(CustomerPicker.NONE);
            createNewMaster(no);
          }}
        />
      </ReactModal>

      <ReactModal className={"modal-h"} isOpen={loadingNew}>
        <div className="p3">
          <Loading />
          <h4 className="font-14">
            Create new master from{" "}
            {newOrderInfo.leg_id
              ? "LEG-" + newOrderInfo.leg_id
              : "VO-" + newOrderInfo.vo_id}
            ...
          </h4>
        </div>
      </ReactModal>

      <Modal
        show={invoiceData != null}
        title={"title_example_invoice.pdf"}
        center={true}
        width={"80vw"}
        rounded={true}
        onClose={() => setInvoiceData(null)}
      >
        <OrderInvoicePdf data={invoiceData} />
      </Modal>

      <ReactModal
        className={"modal-h"}
        isOpen={showLegDetailModal != undefined}
        onRequestClose={() => {
          setShowLegDetailModal(undefined);
        }}
      >
        <LegDetail
          order={showLegDetailModal?.object}
          onClose={() => setShowLegDetailModal(undefined)}
        />
      </ReactModal>

      <ReactModal className={"modal-h"} isOpen={showNewVehicleForm}>
        <div className="row">
          <div className="col-12">
            <VehicleInformation
              masterID={master.id}
              vehicleOrder={new VehicleOrder()}
              onVehicleOrdersChanged={(evt) => {
                // console.log(evt);

                if (!evt) {
                  setShowNewVehicleForm(false);
                } else {
                  addVehicleOrder(evt);
                }
              }}
            />
          </div>
        </div>
      </ReactModal>

      <ReactModal className={""} isOpen={referencesOpened}>
        <div className="row align-items-center mb-3">
          <h4 className="font-16">Master References</h4>
          <img
            src={ICONS.IconCloseBig}
            onClick={(evt) => setReferencesOpened(false)}
            alt=""
          />
        </div>
        <div className="col-12 row mb-1">
          <h4 className="font-14 col-4">Key</h4>
          <h4 className="font-14 col-4">Value</h4>
          <h4
            onClick={(evt) => {
              setReferences([...references, new Reference()]);
            }}
            className="font-12 cursor-pointer text-light-blue d-flex align-items-center"
            style={{ fontWeight: 400 }}
          >
            ADD NEW <img src={ICONS.IconPlus} alt="" />
          </h4>
        </div>
        <div style={{ flex: 1, overflow: "auto" }}>
          {references.map((x, i) => (
            <div className="row align-items-start">
              <FormGroup
                placeholder={"Key - " + (i + 1)}
                type="combobox"
                options={{
                  data: ["PO", "RO", "STOCK"],
                  label: (x) => x,
                  value: (x) => x,
                }}
                colSize={4}
                name={"r-key-" + i}
                value={x.key}
                onTextChange={(evt) => {
                  const temp = [...references];
                  temp[i].key = evt;
                  setReferences(temp);
                }}
              />
              <FormGroup
                placeholder={"Value - " + (i + 1)}
                colSize={4}
                name={"r-value-" + i}
                value={x.value}
                onTextChange={(evt) => {
                  const temp = [...references];
                  temp[i].value = evt;
                  setReferences(temp);
                }}
              />
              <button
                onClick={(evt) => {
                  const temp = [...references];
                  temp.splice(i, 1);
                  setReferences(temp);
                }}
                className="btn btn-danger py-2"
              >
                REMOVE
              </button>
            </div>
          ))}
        </div>
        <div className="row align-items-center mt-3">
          <button
            onClick={(evt) => setReferencesOpened(false)}
            className="btn btn-danger col-6 py-2"
          >
            CANCEL
          </button>
          <button
            onClick={async (evt) => {
              const mutation = gql`
                mutation ($order_id: Int, $references: [reference_input]) {
                  update_master_order_references(
                    order_id: $order_id
                    references: $references
                  ) {
                    id
                    message
                    success
                  }
                }
              `;

              try {
                loading.open("Submitting...");
                const data = await GraphqlService.SendMutation(mutation, {
                  order_id: master.id,
                  references,
                });
                if (!data?.success)
                  throw new Error(data?.message || "Something went wrong");

                loading.close();
                getOrderById().then();
                setReferencesOpened(false);
                toast.addToast(data.message, {
                  appearance: "success",
                  autoDismiss: true,
                });
              } catch (ex) {
                loading.close();
                toast.addToast(ex.message, {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            }}
            disabled={
              references.length != 0 &&
              references.some((x) => x.key.trim() == "" || x.value.trim() == "")
            }
            className="btn btn-blue-light col-6 py-2"
          >
            UPDATE
          </button>
        </div>
      </ReactModal>

      {splitOpen && (
        <SplitRoundtripLeg
          onLegSplitted={(legs) => {
            setSplitOpen(false);
            getOrderById().then();
          }}
          leg={selectedLeg?.leg as any}
          master_id={master.id}
          vehicle_order_id={selectedVehicle?.vehicle?.id}
          onClose={(evt) => setSplitOpen(false)}
        />
      )}

      <ReactModal
        className={"modal-h p-0"}
        isOpen={base64 != null}
        onRequestClose={(evt) => setBase64(null)}
      >
        <div className="flex-1-container">
          <embed
            width={"100%"}
            height={"100%"}
            src={base64}
            type="application/pdf"
          ></embed>
        </div>

        {canSendInvoice && (
          <button
            onClick={(evt) => setSendInvoiceOpen(true)}
            className="btn btn-blue-light px-3 py-2"
            style={{ borderRadius: 0 }}
          >
            SEND INVOICE
          </button>
        )}
      </ReactModal>

      <ReactModal className={"modal-h"} isOpen={sendInvoiceOpen}>
        <SendInvoices
          mastersID={[master.id]}
          onClose={(evt) => {
            setBase64(null);
            if (evt) getOrderById();
            setSendInvoiceOpen(false);
          }}
          defaultEmails={defaultEmails}
        />
      </ReactModal>

      {/* <ReactModal 
      className={""}
      style={{content:{minWidth:'80% !important'},overlay:{minWidth:'80% !important'}}}
      isOpen={paymentInfo.mastersID.length > 0} >
        <ProcessPayment
          data={paymentInfo}
          onClose={(evt) => setPaymentInfo(new PaymentInfo())}
          onPaymentSubmitted={(evt) => getOrderById()}
        />
      </ReactModal> */}
      <Modal
        show={paymentInfo.mastersID.length > 0}
        center={true}
        width={"80vw"}
        onClose={() => setInvoiceData(null)}
        rounded={true}
        children={
          <ProcessPayment
            data={paymentInfo}
            onClose={(evt) => setPaymentInfo(new PaymentInfo())}
            onPaymentSubmitted={(evt) => getOrderById()}
          />
        }
      ></Modal>

      <ReactModal
        onRequestClose={(evt) => setSeeLogs(false)}
        isOpen={seeLogs}
        className=""
      >
        <div className="row mb-3" style={{ minWidth: "300px" }}>
          <h4 className="font-12">
            Logs:{" "}
            {master.logs.length +
              master.vehicle_orders.sumBy((x) =>
                x.legs.sumBy((y) => y.stops.sumBy((z) => z.log.length))
              )}
          </h4>
          <h4
            className="font-12 cursor-pointer"
            onClick={(evt) => setSeeLogs(false)}
          >
            <i className="fas fa-times"></i>
          </h4>
        </div>
        <hr className="mb-1" />
        <div className="flex-1-container">
          <ul>
            {master.logs
              .concat(
                master.vehicle_orders.flatMap((x) =>
                  x.legs.flatMap((y) => y.stops.flatMap((z) => z.log))
                )
              )
              .sort((x, y) => (x.time > y.time ? 1 : -1))
              .map((log, index) => (
                <li
                  key={"log-" + index}
                  className="mb-1 pt-2 d-flex"
                  style={{ borderTop: index == 0 ? "none" : "1px solid #eee" }}
                >
                  <h6 className="font-11 mr-2">{index + 1}.)</h6>
                  <div className="col-12">
                    <p className="font-11">{log.message || "-"}</p>
                    <p className="font-11 my-1 font-bold">
                      {Helper.FORMAT.USDate(log.time)}
                    </p>
                    <p className="font-11 text-blue-light font-bold">
                      User #{log.user_id}
                    </p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </ReactModal>

      <ReactModal
        className={"modal-large"}
        isOpen={
          moveOrder.legs.length > 0 || moveOrder.vehicle_orders.length > 0
        }
      >
        <MoveVehicles
          onSubmitted={() => {
            getOrderById();
            setMoveOrder({ legs: [], vehicle_orders: [] });
          }}
          onCancel={(evt) => setMoveOrder({ vehicle_orders: [], legs: [] })}
          vehicle_orders={moveOrder.vehicle_orders}
          legs={moveOrder.legs}
        />
      </ReactModal>

      <ReactModal
        isOpen={cancelOrder.id != null}
        style={{ content: { height: "400px", maxWidth: "400px" } }}
      >
        <div className="row mb-3">
          <h4 className="font-12">Cancel Order #{cancelOrder.id}</h4>
          <h4
            onClick={(evt) => setCancelOrder({ id: null, note: "" })}
            className="font-12 cursor-pointer"
          >
            <i className="fas fa-times"></i>
          </h4>
        </div>

        <div className="flex-1-container">
          <FormGroup
            innerRef={cancelAllNoteRef}
            value={cancelOrder.note}
            onTextChange={(note) => setCancelOrder({ ...cancelOrder, note })}
            name={"cancel_reason"}
            label={"Reason"}
            required
            type={"textarea"}
            placeholder={"Explain why you are canceling this Order"}
          />
        </div>

        <p className={"font-11 mt-3 text-red font-bold text-center"}>
          This action can't be reverted
        </p>

        <div className="row mt-3">
          <button
            onClick={(evt) => setCancelOrder({ id: null, note: "" })}
            className={"btn btn-clear text-blue-light col-6 py-2"}
          >
            CANCEL
          </button>
          <button
            onClick={(evt) => submitCancelOrder()}
            disabled={cancelOrder?.note?.trim() == ""}
            className={"btn btn-danger-light col-6 py-2"}
          >
            CONFIRM
          </button>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={cancelVehicleOrder.id != null}
        style={{ content: { height: "400px", maxWidth: "400px" } }}
      >
        <div className="row mb-3">
          <h4 className="font-12">
            Cancel Vehicle Order #{cancelVehicleOrder.id}
          </h4>
          <h4
            onClick={(evt) => setCancelVehicleOrder({ id: null, note: "" })}
            className="font-12 cursor-pointer"
          >
            <i className="fas fa-times"></i>
          </h4>
        </div>

        <div className="flex-1-container">
          <FormGroup
            innerRef={cancelNoteRef}
            value={cancelVehicleOrder.note}
            onTextChange={(note) =>
              setCancelVehicleOrder({ ...cancelVehicleOrder, note })
            }
            name={"cancel_reason"}
            label={"Reason"}
            required
            type={"textarea"}
            placeholder={"Explain why you are canceling this Order"}
          />
        </div>

        <p className={"font-11 mt-3 text-red font-bold text-center"}>
          This action can't be reverted
        </p>

        <div className="row mt-3">
          <button
            onClick={(evt) => setCancelVehicleOrder({ id: null, note: "" })}
            className={"btn btn-clear text-blue-light col-6 py-2"}
          >
            CANCEL
          </button>
          <button
            onClick={(evt) => submitCancelVehicleOrder()}
            disabled={cancelVehicleOrder?.note?.trim() == ""}
            className={"btn btn-danger-light col-6 py-2"}
          >
            CONFIRM
          </button>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={deleteLeg.id != null}
        style={{ content: { height: "400px", maxWidth: "400px" } }}
      >
        <div className="row mb-3">
          <h4 className="font-12">Cancel Leg #{deleteLeg.id}</h4>
          <h4
            onClick={(evt) => setDeleteLeg({ id: null, note: "" })}
            className="font-12 cursor-pointer"
          >
            <i className="fas fa-times"></i>
          </h4>
        </div>

        <div className="flex-1-container">
          <FormGroup
            innerRef={deleteNoteRef}
            value={deleteLeg.note}
            onTextChange={(note) => setDeleteLeg({ ...deleteLeg, note })}
            name={"cancel_reason"}
            label={"Reason"}
            required
            type={"textarea"}
            placeholder={"Explain why this leg must be canceled"}
          />
        </div>

        <p className={"font-11 mt-3 text-red font-bold text-center"}>
          This action can't be reverted
        </p>

        <div className="row mt-3">
          <button
            onClick={(evt) => setDeleteLeg({ id: null, note: "" })}
            className={"btn btn-clear text-blue-light col-6 py-2"}
          >
            CANCEL
          </button>
          <button
            onClick={(evt) => submitDeleteLeg()}
            disabled={deleteLeg?.note?.trim() == ""}
            className={"btn btn-danger-light col-6 py-2"}
          >
            CONFIRM
          </button>
        </div>
      </ReactModal>

      <ReactModal className={""} isOpen={storageSelected.index != null}>
        <StorageOrderModal
          chargesData={storageSelected.charges}
          data={storageSelected.storage}
          onClose={(evt) => {
            setStorageSelected({ index: null, storage: null, charges: [] });
            if (evt.reload) getOrderById();
          }}
        />
      </ReactModal>
    </>
  );
};

export default OrderDetailsPage;

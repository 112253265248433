import {Quote_Input, Quote_Input_Update} from "../quote";
import {ResultWithLink} from "../../customer_profile/customer_profile";
import {GraphqlService} from "../../../../graphql.service";
import {gql} from "apollo-boost";

export const create_quote = (input:{quote: Quote_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($quote: quote_input) {
      create_quote(quote: $quote) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {quote} as 
  Promise<ResultWithLink>;
};

export const accept_quote = (input:{quote: Quote_Input_Update}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($quote: quote_input_update) {
      accept_quote(quote: $quote) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {quote} as 
  Promise<ResultWithLink>;
};

export const create_quote_authenticated = (input:{quote: Quote_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($quote: quote_input) {
      create_quote_authenticated(quote: $quote) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {quote} as 
  Promise<ResultWithLink>;
};

export const update_quote = (input:{quote: Quote_Input_Update}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($quote: quote_input_update) {
      update_quote(quote: $quote) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {quote} as 
  Promise<ResultWithLink>;
};

export const archive_quote = (input:{quote_id: number}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($quote_id: Int) {
      archive_quote(quote_id: $quote_id) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {quote_id} as 
  Promise<ResultWithLink>;
};

import {useEffect, useState} from "react";
import CompanyInfoEdit from "../CompanyInfoEdit/CompanyInfoEdit";

const CompanyInfo = ({setting, onUpdated}: {setting: any[], onUpdated}) => {
    const [showEditForm, setShowEditForm] = useState(false);

    const [oEmail, setOEmail] = useState<string>('');
    const [iEmail, setIEmail] = useState<string>('');
    const [pEmail, setPEmail] = useState<string>('');
    const [qEmail, setQEmail] = useState<string>('');
    const [bEmail, setBEmail] = useState<string>('');
    const [currentCompanyInfo, setCurrentCompanyInfo] = useState<any>();
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        if (setting) {
            console.log(setting);
            setSettings(setting);

            let info = setting.find(e => e.key == "COMPANY_INFO");
            let parseInfo = JSON.parse(info.value);

            let orderConfirmationEmail = setting.find(e => e.key == "ORDER_CONFIRMATION_EMAIL").value;
            let invoiceEmail = setting.find(e => e.key == "INVOICE_EMAIL").value;
            let paymentEmail = setting.find(e => e.key == "PAYMENT_EMAIL").value;
            let quotesEmail = setting.find(e => e.key == "QUOTES_EMAIL").value;
            let bolEmail = setting.find(e => e.key == "BOL_EMAIL").value;

            setOEmail(orderConfirmationEmail);
            setIEmail(invoiceEmail);
            setPEmail(paymentEmail);
            setQEmail(quotesEmail);
            setBEmail(bolEmail);
            setCurrentCompanyInfo(parseInfo);

            console.log(setting)
        }
    }, [setting]);

    const CompanyInfo = () => {
        return (
            <>
                <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                        <p className="font-14 font-medium m-0">Company info</p>
                        <button className="btn btn-blue-light rounded-pill font-10 font-medium"
                                onClick={() => setShowEditForm(true)}>EDIT INFO</button>
                    </div>

                    <div className="col-12 mt-4 ml-3">
                        <p className="font-12 font-medium text-gray mb-1">Name</p>
                        <p className="font-14 font-medium mb-1">{currentCompanyInfo?.name}</p>
                    </div>

                    <div className="col-5 mt-4 ml-3 mb-4">
                        <p className="font-12 font-medium text-gray mb-1">Address</p>
                        <p className="font-14 font-medium mb-1">{ currentCompanyInfo?.address }</p>
                    </div>

                    <div className="col-5 mt-4 ml-3 mb-4">
                        <p className="font-12 font-medium text-gray mb-1">Tax ID</p>
                        {(currentCompanyInfo?.tax?.type && currentCompanyInfo?.tax?.number) && <p className="font-14 font-medium mb-1">{currentCompanyInfo?.tax?.number} ({currentCompanyInfo?.tax?.type})</p>}
                    </div>

                    <hr/>

                    <div className="col-12 mt-4 d-flex align-items-center justify-content-between">
                        <p className="font-14 font-medium m-0">Contacts</p>
                    </div>

                    <div className="col-3 mt-4 ml-3 mb-4">
                        <p className="font-12 font-medium text-gray mb-1">Phone #</p>
                        <p className="font-14 font-medium mb-1">{ currentCompanyInfo?.phone }</p>
                    </div>

                    <div className="col-3 mt-4 ml-3 mb-4">
                        <p className="font-12 font-medium text-gray mb-1">FAX #</p>
                        <p className="font-14 font-medium mb-1">{ currentCompanyInfo?.fax }</p>
                    </div>

                    <div className="col-3"></div>
                    <div className="col-3"></div>

                    <hr/>

                    <div className="col-12 mt-4 d-flex align-items-center justify-content-between">
                        <p className="font-14 font-medium m-0">Notification emails</p>
                    </div>

                    <div className="col-5 mt-4 ml-3">
                        <p className="font-12 font-medium text-gray mb-1">Quotes</p>
                        <p className="font-14 font-medium mb-1">{ qEmail }</p>
                    </div>

                    <div className="col-5 mt-4 ml-3">
                        <p className="font-12 font-medium text-gray mb-1">Orders</p>
                        <p className="font-14 font-medium mb-1">{ oEmail }</p>
                    </div>

                    <div className="col-5 mt-4 ml-3">
                        <p className="font-12 font-medium text-gray mb-1">BOLs</p>
                        <p className="font-14 font-medium mb-1">{ bEmail }</p>
                    </div>

                    <div className="col-5 mt-4 ml-3">
                        <p className="font-12 font-medium text-gray mb-1">Invoices</p>
                        <p className="font-14 font-medium mb-1">{ iEmail }</p>
                    </div>

                    <div className="col-5 mt-4 ml-3">
                        <p className="font-12 font-medium text-gray mb-1">Payments</p>
                        <p className="font-14 font-medium mb-1">{ pEmail }</p>
                    </div>
                </div>
            </>
        );
    }

    return(<>{ showEditForm ? <CompanyInfoEdit data={settings} onCancel={() => setShowEditForm(false)}
                                               onSubmit={() => {
                                                setShowEditForm(false);
                                                onUpdated();                     
                                               }} /> : CompanyInfo() }</>);
}

export default CompanyInfo;
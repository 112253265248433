import {gql} from "apollo-boost";
import {GraphqlService} from "../../../../graphql.service";
import {Driver_Distance, Jurisdiction_Report, Samsara_Truck, Truck_Driver, Truck_Jurisdiction_Report} from "../truck";

export const get_trucks_location = (input:{}) : Promise<Truck_Driver> => {
  let query = gql`
    query {
      get_trucks_location {
        id
        name
        location {
          time
          latitude
          longitude
          heading
          speed
          reverseGeo {
            formattedLocation
          }
        }
        image_url
        fuel_percentage
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Truck_Driver>;
}

export const get_truck_location_byId = (input:{truckId: string, time: string}) : Promise<Truck_Driver> => {
  let query = gql`
    query($truckId: String, $time: String) {
      get_truck_location_byId(truckId: $truckId, time: $time) {
        id
        name
        location {
          time
          latitude
          longitude
          heading
          speed
          reverseGeo {
            formattedLocation
          }
        }
        image_url
        fuel_percentage
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {truckId, time} as 
  Promise<Truck_Driver>;
}

export const trucks_info = (input:{}) : Promise<Samsara_Truck[]> => {
  let query = gql`
    query {
      trucks_info {
        id
        vin
        serial
        make
        model
        year
        licensePlate
        tags {
          id
          name
        }
        staticAssignedDriver {
          id
          name
        }
        velox_driver_id
        driver_id
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  // as 
  Promise<Samsara_Truck[]>;
}

export const get_trucks_fuel_report = (input:{month: string, year: string}) : Promise<Jurisdiction_Report> => {
  let query = gql`
    query($month: String, $year: String) {
      get_trucks_fuel_report(month: $month, year: $year) {
        jurisdictionReports {
          jurisdiction
          totalMeters
          taxableMeters
          taxPaidLiters
        }
        troubleshooting {
          unassignedFuelTypePurchases
          unassignedFuelTypeVehicles
          unassignedVehiclePurchases
          noPurchasesFound
        }
        month
        year
        message
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {month, year} as 
  Promise<Jurisdiction_Report>;
}

export const get_truck_ifta_report = (input:{truckId: string, month: string, year: string}) : Promise<Truck_Jurisdiction_Report> => {
  let query = gql`
    query($truckId: String, $month: String, $year: String) {
      get_truck_ifta_report(truckId: $truckId, month: $month, year: $year) {
        vehicleReports {
          vehicle {
            id
            name
          }
          jurisdictions {
            jurisdiction
            totalMeters
            taxableMeters
            taxPaidLiters
          }
        }
        troubleshooting {
          unassignedFuelTypePurchases
          unassignedFuelTypeVehicles
          unassignedVehiclePurchases
          noPurchasesFound
        }
        month
        year
        message
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {truckId, month, year} as 
  Promise<Truck_Jurisdiction_Report>;
}

export const distance_to_drivers = (input:{address: string}) : Promise<Driver_Distance> => {
  let query = gql`
    query($address: String) {
      distance_to_drivers(address: $address) {
        distance {
          text
          value
        }
        duration {
          text
          value
        }
        duration_in_traffic {
          text
          value
        }
        driver {
          id
          name
          location {
            time
            latitude
            longitude
            heading
            speed
            reverseGeo {
              formattedLocation
            }
          }
          image_url
          fuel_percentage
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {address} as 
  Promise<Driver_Distance>;
}
import {gql} from "apollo-boost";

const UPDATE_PRICE = gql`
    mutation($quote: quote_input_update){
        accept_quote(quote:$quote){
            id
            message
            success
        }
    }
`;

const ARCHIVE = gql`
    mutation($quote_id:Int){
        archive_quote(quote_id:$quote_id ){
            id
            message
            success
        }
    }
`;

const UPDATE = gql`
    mutation($quote:quote_input_update){
        update_quote(quote:$quote){
            id
            message
            success
        }
    }
`;

const QuoteMutations = {
    UPDATE_PRICE,
    ARCHIVE,
    UPDATE
};

export default QuoteMutations;
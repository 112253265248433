import {gql} from "apollo-boost";
import {CpRes, Customer_Profile, CustomerProfileSearchList, DTRes, ResultWithLink} from "../customer_profile";
import {GraphqlService} from "../../../../graphql.service";

export const find_customer_profile = (input:{usermail: string, password: string}) : Promise<CpRes> => {
  let query = gql`
    query($usermail: String, $password: String) {
      find_customer_profile(usermail: $usermail, password: $password) {
        message
        profile
        success
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {usermail, password} as 
  Promise<CpRes>;
}

export const deTokenize = (input:{token: string}) : Promise<DTRes> => {
  let query = gql`
    query($token: String!) {
      deTokenize(token: $token) {
        profile {
          id
          email
          verified
          name
          role
          customer {
            id
            name
            corporate_client
            key
            terms
            internal_note
            active
            default_invoice_email
          }
          contact {
            title
            value
            type
            unsubscribed
          }
          title
          active
        }
        redirect_link
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {token} as 
  Promise<DTRes>;
}

export const get_customer_profile = (input:{profile_id: number}) : Promise<Customer_Profile> => {
  let query = gql`
    query($profile_id: Int) {
      get_customer_profile(profile_id: $profile_id) {
        id
        email
        verified
        name
        role
        customer {
          id
          name
          corporate_client
          key
          terms
          internal_note
          active
          default_invoice_email
        }
        contact {
          title
          value
          type
          unsubscribed
        }
        title
        active
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {profile_id} as 
  Promise<Customer_Profile>;
}

export const get_customer_profile_search_list = (input:{customerID: number}) : Promise<CustomerProfileSearchList[]> => {
  let query = gql`
    query($customerID: Int) {
      get_customer_profile_search_list(customerID: $customerID) {
        id
        name
        email
        title
        numbers
        contact {
          title
          value
          type
          unsubscribed
        }
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {customerID} as 
  Promise<CustomerProfileSearchList[]>;
}

export const profile_exists = (input:{email: string}) : Promise<ResultWithLink> => {
  let query = gql`
    query($email: String) {
      find_customer_profile(email: $email) {
        id
        link
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(query, input) as  //, {email} as 
  Promise<ResultWithLink>;
}
import {gql} from "apollo-boost";

const CARRIER_INFO = gql`
    mutation($carrier: velox_carrier_update_input){
        update_velox_carrier_information(carrier: $carrier){
            success
            message
        }
    }
`;

const CARRIER_REGISTRATION = gql`
    mutation($registration: velox_carrier_registration_input) {
        update_velox_carrier_registration(registration: $registration) {
            id
            message
            success
        }
    }`;

const CARRIER_INSURANCE = gql`
    mutation($insurance: velox_carrier_insurance_input) {
        update_velox_carrier_insurance(insurance: $insurance) {
            id
            message
            success
        }
    }`;

const CARRIER_TAX_INFO = gql`
mutation($tax_information:carrier_tax_info_input){
    update_carrier_tax_info(tax_information:$tax_information){
        carrier_id
        id
        name
        ssn
        ein
        business_name
        type 
        signature
        time_signed
        address {
            street
            city
            state
            postal_code
            country
            street_number
        }
        uploaded_documents{
            uid
            name
        }
    }
}
`;

const CREATE_CARRIER = gql`
    mutation($carrier: carrier_input) {
        create_carrier(carrier: $carrier) {
            id
            name
            code
        }
    }`;

const CHECK_CARRIER_CODE = gql
        `query($code: String) {
        check_carrier_code(code:$code){
            success
            message
        }
    }`;

const CarrierMutation = {
    CARRIER_INFO,
    CARRIER_INSURANCE,
    CARRIER_REGISTRATION,
    CREATE_CARRIER,
    CHECK_CARRIER_CODE,
    CARRIER_TAX_INFO
}

export default CarrierMutation;

import React, { useEffect, useRef, useState } from 'react';
import ICONS from '../../../assets/svg';
import Helper from '../../helper/Helper';
import { GraphqlService } from '../../services/graphql.service';
import Modal from 'react-modal';
import Searchbar from '../Searchbar/Searchbar';
import Table from '../Table/Table';
import DispatchFilters from '../DispatchFilters/DispatchFilters';
import QuoteQueries from "../../graphql/query/Quote";
import {CustomerProfile, Quote} from "../../classes/classes";
import IColumn from "../Table/models/IColumn";
import IMenuOption from "../Table/models/IMenuOption";
import { gql } from 'apollo-boost';
import { useToasts } from 'react-toast-notifications';


const columnsBooked: IColumn[] = [
    {
        label: 'MASTER',
        active: false,
        orderBy: 'DESC',
        name: 'master_column',
        sort_by: 'master_id'
    },
    {
        label: 'QUOTE #',
        active: false,
        orderBy: 'DESC',
        name: 'quote_column',
        sort_by: 'quote_id'
    },
    {
        label: 'CUSTOMER',
        active: false,
        orderBy: 'DESC',
        name: 'customer',
        sort_by: 'customer_name'
    },
    {
        label: 'CONTACT',
        active: false,
        orderBy: 'DESC',
        name: 'contact'
    },
    {
        label: 'TERMS',
        active: false,
        orderBy: 'DESC',
        name: 'terms'
    },
    {
        label: 'PRICE',
        active: false,
        orderBy: 'DESC',
        name: 'price_column',
        sort_by: 'price'
    },
    {
        label: 'STATUS',
        active: false,
        orderBy: 'DESC',
        name: 'status'
    }
];

const queryBooked = gql`
query{
get_unverified_master_order{
id
time
delivered
started
quote {
    id
            master_order_id
            time_created
            note
            price
            archived
            user {
                id
                name
            }
            contact_info{
                full_name
                email
                phone_1
                phone_2
                company_name
            }
            pickup{
                city
                state
                postal_code
                guaranteed
                pickup_date
            }
            dropoff {
                city
                state
                postal_code
                guaranteed
                dropoff_date
            }
            vehicle_info{
                make
                model
                year
                vin
                equipment
                price
                nofail_pickup_price
                nofail_dropoff_price
            }
}
completed
price
payment_left
terms
non_move
picked_up
payment_due
invoices{
  id
  time
  time_due
}
references{
  key
  value
}

customer{
  id
  name
  corporate_client
  terms
  billing_address{
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
        }
}
contacts{
  id
  name
  email
  contact{
    title
    value
    type
  }
}
}
}
`

type TQuotesTable = {
    onQuoteSelected: (quote: Quote) => void,
    quoteSelected: Quote,
    reload: boolean,
    onReloadAccepted: (reload: boolean) => void,
    onConvertToOrderClicked: (id: number) => void,
    customer_id?: number
}
    enum QuotesStatuses {
        NONE,
        OPEN,
        ACCEPTED,
        ARCHIVED,
        BOOKED
    }

const QuotesTable = ({customer_id, reload, onReloadAccepted, onQuoteSelected, quoteSelected, onConvertToOrderClicked}: TQuotesTable) => {





    const statusesOptions = [
        {
            label: 'Open ',
            key: 'NEEDS QUOTE - IN PROGRESS - QUOTED ',
            value: QuotesStatuses.OPEN
        },
        {
            label: 'Accepted ',
            key: 'ACCEPTED',
            value: QuotesStatuses.ACCEPTED
        },
        {
            label: 'Archived ',
            key: 'ARCHIVED',
            value: QuotesStatuses.ARCHIVED
        },
        !customer_id && {
            label: 'Booked ',
            key: 'BOOKED',
            value: QuotesStatuses.BOOKED
        }
    ].filter(Boolean)


    const [loading, setLoading] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [quoteStatus, setQuoteStatus] = useState(QuotesStatuses.OPEN);

    const toast = useToasts();




    const [selected, setSelected] = useState(-1);
    const [opened, setOpened] = useState(false);
    const [quotes, setQuotes] = useState<{[key: string]: any, data: Quote}[]>([]);
    const [booked, setBooked] = useState([]);
    const [bookedLoading, setBookedLoading] = useState(false);

    async function loadBooked() {
        try {
            setBookedLoading(true);
            let data = await GraphqlService.SendQuery(queryBooked);
            data = data.map(x => {
                if (x.invoices == null)
                    x.invoices = [];
                // console.log(e.invoice_id)

                x.invoices = x.invoices.sort((x, y) => x.id < y.id ? 1 : -1);
                x.first_invoice_id = x.invoices[0]?.id ?? 0;
                x._ref = x.references.map(x => `${x.key}: ${x.value}`);

                if (x.invoices.length > 2) {

                    x.due = [Helper.FORMAT.USDate(x.invoices[0].time_due, 'do'), Helper.FORMAT.USDate(x.invoices[1].time_due, 'do'), '+' + (x.invoices.length - 2)];
                    x.sent_on = [Helper.FORMAT.USDate(x.invoices[0].time, 'do'), Helper.FORMAT.USDate(x.invoices[1].time, 'do'), '+' + (x.invoices.length - 2)];
                    x.invoices_formatted = ['I-' + x.invoices[0].id, 'I-' + x.invoices[1].id, '+' + (x.invoices.length - 2)];
                }

                // if (x.invoices.length > 2)


                else if (x.invoices.length >= 1) {

                    x.due = x.invoices.map(y => Helper.FORMAT.USDate(y.time_due, 'do'));
                    x.sent_on = x.invoices.map(y => Helper.FORMAT.USDate(y.time, 'do'));
                    x.invoices_formatted = x.invoices.map(x => 'I-' + x.id);
                }
                else if (x.invoices.length == 0) {
                    x.due = ['-'];
                    x.sent_on = ['-'];
                    x.invoices_formatted = ['-'];
                }

                const defaultEmails = (x.contacts as CustomerProfile[]).map((x: CustomerProfile) => [x.email, ...x.contact?.filter(x => x.type?.toLowerCase() == 'email').map(x => x.value)]).flatMap(x => x);

                return  {
                    ...x,
                    needs_invoice: x.needs_invoice,
                    quote_id: x.quote?.id,
                    id: x.quote?.id,
                    quote_column: ['Q-' + x.quote?.id],
                    data: x.quote,
                    master_column: ['M-' + x.id, x.non_move ? 'NON MOVE' : (x.delivered ? 'DELIVERED' : (x.picked_up ? 'PICKED UP' : 'NOT PICKED UP'))],
                    master_id: x.id,
                    invoice_column: x.invoices_formatted,
                    first_invoice_id: x.first_invoice_id,
                    time_in: [Helper.FORMAT.USDate(x.time, 'do'), Helper.FORMAT.USDate(x.time, 'to')],
                    customer: [x.customer.name],
                    customer_name: x.customer.name,
                    contact: [x.contacts[0]?.name, x.contacts[0]?.email, (x.contacts.length > 1 ?  '+' + (x.contacts.length - 1) : '') ],
                    terms: x.terms || '-',
                    status: x.needs_invoice ? 'NEEDS INVOICE':'SENT',
                    price_column: Helper.FORMAT.USCurrency(x.price),
                    price: +x.price,
                    balance_left: Helper.FORMAT.USCurrency(x.payment_left),
                    balance_left_amount: +x.payment_left,

                    customer_id: x.customer.id,
                    references: x.references,
                    last_invoice_price: Helper.FORMAT.USCurrency(x.last_invoice_amount),
                    last_invoice_amount: x.last_invoice_amount,

                    default_emails: defaultEmails
                }});
            setBooked(data);
            setBookedLoading(false);
        } catch (ex) {
            setBookedLoading(false);
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            
        }
    }


    async function getAllQuotes()
    {
        try {

            let variables;
            if (customer_id)
                variables = {customer_id};

            setLoading(true);

            const data = await GraphqlService.SendQuery(customer_id ? QuoteQueries.GET_BY_CUSTOMER : QuoteQueries.GET_ALL, variables) || [];

            setQuotes(data.map((x: Quote) => {
                let q = new Quote(x);
                return {
                    data: q,
                    number: 'Q-' + x.id,
                    id: +x.id,
                    quoted_by: x.user ? [x.user.name.toUpperCase(), Helper.FORMAT.USCurrency(x.price)] : '-',
                    customer: [x.contact_info.full_name, x.contact_info.phone_1],
                    vehicle_info: [x.vehicle_info[0]?.vin, x.vehicle_info[0]?.year + ' ' + x.vehicle_info[0]?.make + ' ' + x.vehicle_info[0]?.model, x.vehicle_info.length > 1 ? '+' + (x.vehicle_info.length - 1) : ''],
                    status: q.status,
                    pickup: [x.pickup.city, x.pickup.state + ' ' + x.pickup.postal_code, Helper.FORMAT.USDate(x.pickup.pickup_date, 'do') + ' ' + Helper.FORMAT.USDate(x.pickup.pickup_date, 'to')],
                    dropoff: [x.dropoff.city, x.dropoff.state + ' ' + x.dropoff.postal_code, Helper.FORMAT.USDate(x.dropoff.dropoff_date, 'do') + ' ' + Helper.FORMAT.USDate(x.dropoff.dropoff_date, 'to')]
                }
            }));


            let q = new Quote(data.find(x => x.id == quoteSelected.id));
            onQuoteSelected(q);

            setLoading(false);
        } catch (ex) {
            console.log('ex', ex.message);
            setLoading(false);
        }
    }

    const menuOptions: IMenuOption[] = [
        {label: 'Archive Quote', icon: ICONS.IconArchive, condition: x => !x.data?.archived && (!x.data?.master_order_id && !x.master_id)},
        {label: 'See Quote Details', icon: ICONS.IconDetails, action: (obj: any) => {
                onQuoteSelected(new Quote(obj.data));
        }},
        // {label: 'See customer details', icon: ICONS.IconUserOption},
        {label: 'Convert To Order', icon: ICONS.IconCheck, condition: x => !x.data?.archived && (!x.data?.master_order_id && !x.master_id), action: (obj: any) => onConvertToOrderClicked(obj.id)},
        {label: 'See Order Details', icon: ICONS.IconDetails, condition: x => x.master_id, action: (obj: any) => {
            Helper.Navigation.NavigateTo(`/order/${obj.master_id}/verify`);
        }}

    ]

    const quotesFiltered = () =>
    {
        let q = [];
        if (quoteStatus == QuotesStatuses.BOOKED)
            q = [...booked];

        else if (quoteStatus == QuotesStatuses.NONE)
            q = [...quotes];
        else
        {
            const status = quoteStatus == QuotesStatuses.OPEN ? 'NEEDS QUOTE - IN PROGRESS - QUOTED ' : (quoteStatus == QuotesStatuses.ACCEPTED ? 'ACCEPTED' : 'ARCHIVED');
            q = quotes.filter(x => status.includes(x.status));
        }
        return q.filter(x => {
            let searchRes = searchTerm.split(' ').map(s => JSON.stringify(x).toLowerCase().includes(s.toLowerCase()));
            x.selected = x.id == quoteSelected .id;
            return !searchRes.includes(false);
        });
    }


    const columns: IColumn[] = [
        {
            label: 'NUMBER',
            active: false,
            orderBy: 'DESC',
            name: 'number',
            sort_by: 'id'
        },
        {
            label: 'VEHICLE INFO',
            active: false,
            orderBy: 'DESC',
            name: 'vehicle_info'
        },
        {
            label: 'STATUS',
            active: false,
            orderBy: 'DESC',
            name: 'status'
        },
        {
            label: 'CUSTOMER',
            active: false,
            orderBy: 'DESC',
            name: 'customer'
        },
        {
            label: 'PICKUP',
            active: false,
            orderBy: 'DESC',
            name: 'pickup'
        },
        {
            label: 'DROPOFF',
            active: false,
            orderBy: 'DESC',
            name: 'dropoff'
        },

        {
            label: 'QUOTED BY / PRICE',
            active: false,
            orderBy: 'DESC',
            name: 'quoted_by'
        }];


    useEffect(() => {
        getAllQuotes();
        loadBooked();
    }, []);

    useEffect(() => {
        if (reload){
            onReloadAccepted(true);
            getAllQuotes();
        }
    }, [reload]);


    const getCount = key => {
        if (key == 'BOOKED')
            return booked.length;
        if (!key)
            return quotes.length;
        return quotes.filter(x => key.includes(x.status)).length;
    }


    const ref = useRef();
    return  <div className='col-12  flex-1-container'>
        <div className="row">
            <div className="col-12 d-flex align-items-center">
                {QuotesStatuses.BOOKED != quoteStatus && <h4 className="fs-12">{quotesFiltered().length} / {quotes.length} Quotes</h4>}
                {QuotesStatuses.BOOKED == quoteStatus && <h4 className="fs-12">{quotesFiltered().length} / {booked.length} Booked</h4>}
                {/* <button className="btn btn-outline mx-2">THIS WEEK <i className="fas fa-chevron-down"></i></button> */}

            </div>


        </div>

        <div className="col-12 row align-items-center d-flex my-3">
            {/*{JSON.stringify(statusesOptions)}*/}
            {/*<div className="col-6">*/}
            <div className={'d-flex'}>
                {
                    statusesOptions.map((opt, index) =>
                        <div className={'mr-3'} key={'key-' + index}>
                            <p onClick={(evt) => setQuoteStatus(opt.value)} className={`fs-12 cursor-pointer mb-1 font-medium${opt.value != quoteStatus ? ' text-gray' : ''}`}>{opt.label} ({getCount(opt.key)})</p>
                            <div style={{width: '24px', height: '2px', background: quoteStatus == opt.value ? '#42ACDE' : 'transparent'}} />
                        </div>
                    )
                }

            </div>
            <div className="d-flex align-items-center justify-content-end">
                <button className='btn btn-icon-only mr-2' onClick={(evt) => {
                    getAllQuotes()
                    loadBooked();    
                }}><img className={(loading || bookedLoading) ? 'spin' : ''} style={{transform: ''}} src={ICONS.IconRefresh} alt=""/></button>

                <Searchbar value={searchTerm} onChange={(evt) => setSearchTerm(evt.target.value)} background={'#F8F8F8'} reference={ref} />
            </div>

        </div>

        {quoteStatus != QuotesStatuses.BOOKED && <Table rowHeight={90} menuOptions={menuOptions} loading={loading} columns={columns} rows={quotesFiltered()} onRowClicked={(evt) => {
            const data = new Quote(quoteSelected.id == null ? evt.data : (evt.data.id == quoteSelected.id ? null : evt.data));
            onQuoteSelected(data);

        }} tableHeight={'90vh'} />}

        {quoteStatus == QuotesStatuses.BOOKED && <Table 
            menuOptions={menuOptions}
            columns={columnsBooked}
            rows={quotesFiltered()}
            onRowClicked={(evt) => {
                const data = new Quote(quoteSelected.id == null ? evt.data : (evt.data.id == quoteSelected.id ? null : evt.data));
                onQuoteSelected(data);
            }}
        />}

    </div>
}

export default QuotesTable;

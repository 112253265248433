import gql from "graphql-tag";

const GET_BY_ID = gql`
    query($order_id: Int){
    vehicle_order_by_id(vehicle_order_id:$order_id){
    id
    time
    bol_comment
    time_changed
    driver_note
    started
    delivered
    master_order{
      id
      time
      started
      completed
      price
      payment_left
      terms
      canceled
      contacts{
        id
        name
        email
        role
        verified
        title
        contact{
          title
          value
          type
        }
      }
      logs {
        user_id
        time
        message
      }
      customer{
        id
        name
        terms
        corporate_client
      }
    }
    vehicle{
      id
      vin
      year
      make
      model
      submodel
      type
      no_vin
      special_vin
      incomplete_vin
      color
      license_plate
      internal_note
      time_created
    }
    legs{
      id
      time
      status
      time_completed
      completed
      canceled
      equipment
      velox_order_id
      velox_order {
        id
        accepted
        rejected
        canceled
        expired
        expires
        change_requests {
          id
          order_id
          accepted
          rejected
          time
          payout
          note
          active
          expired
          expires_at
          weblink
          code
        }
      }
      driver_pay
      remaining_carrier_receivable
      remaining_driver_payable
      carrier_pay
      driver {
      id
      name
      contacts{
        title
        type
        value
      }
    }
    carrier {
      id
      name
      contact{
        name
        email
        phone
      }
    }
      service_charges{
        id
        amount
      }
      stops{
        id
        is_pickup
        time_scheduled
        time_completed
        completed
        started
        canceled
        nofail
        time_frame {
            start
            end
        }
        location{
          id
          title
          internal_note
          driver_note
          customer {
            id
            name
          }
          address{
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
        }
        contacts{
          id
          email
          name
          role
          title
        }
      }
    }
  }
}
`;

const GET_BY_VELOX_ID = gql`
  query($velox_order_id: Int){
    vehicle_order_by_velox_order(velox_order_id:$velox_order_id){
    id
    time
    bol_comment
    time_changed
    driver_note
    started
    delivered
    master_order{
      id
      time
      started
      completed
      price
      payment_left
      terms
      canceled
      logs {
        user_id
        time
        message
      }
      contacts{
        id
        name
        email
        role
        verified
        title
        contact{
          title
          value
          type
        }
      }
      customer{
        id
        name
        terms
        corporate_client
      }
    }
    vehicle{
      id
      vin
      year
      make
      model
      submodel
      type
      no_vin
      special_vin
      incomplete_vin
      color
      license_plate
      internal_note
      time_created
    }
    legs{
      id
      time
      status
      time_completed
      completed
      canceled
      equipment
      velox_order_id
      velox_order {
        id
        accepted
        rejected
        canceled
        expired
        expires
        change_requests {
          id
          order_id
          accepted
          rejected
          time
          payout
          note
          active
          expired
          expires_at
          weblink
          code
        }
      }
      driver_pay
      remaining_carrier_receivable
      remaining_driver_payable
      driver {
      id
      name
      contacts{
        title
        type
        value
      }
    }
    carrier {
      id
      name
      contact{
        name
        email
        phone
      }
    }
      service_charges{
        id
        amount
      }
      stops{
        id
        is_pickup
        time_scheduled
        time_completed
        completed
        started
        canceled
        nofail
        time_frame {
            start
            end
        }
        location{
          id
          title
          internal_note
          driver_note
          customer {
            id
            name
          }
          address{
            city
            state
            street
            country
            postal_code
            street_number
            postal_code_suffix
          }
        }
        contacts{
          id
          email
          name
          role
          title
        }
      }
    }
  }
}
`

const VehicleOrderQueries = {
    GET_BY_ID,
    GET_BY_VELOX_ID
}

export default VehicleOrderQueries;
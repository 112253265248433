import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormGroup from '../../components/FormGroup/FormGroup';
import Loading from '../../components/Loading/Loading';
import Helper from '../../helper/Helper';
import { GraphqlService } from '../../services/graphql.service';

const RedirectPage = () => {
 
    const {token} = useParams<any>();
    const [link, setLink] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);


    async function getFullLink()
    {
        
        setLoading(true);
        try {
            setTimeout(() => {
                setLoading(false);
                // setLink(data);
                setError(null);
                Helper.Navigation.NavigateTo('/');
            }, 1000);    

        } catch (ex) {
            setError(ex.message?.replace('GraphQL error: ', ''));
            setLink(null)
            setLoading(false);
        }
    }

    useEffect(() => {
        getFullLink();
    }, [token]);

    return <div className='p-3 bg-white' style={{width: '90%', maxWidth: '800px', height: 'auto', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
        {/* {token}
    <br />
    <br />
        <FormGroup label='Aquí va esto' name='test' type="text" value={token} onTextChange={(evt) => {
            Helper.Navigation.NavigateTo(`/r/${evt}`)
        }} /> */}

        {/* {link && <h1 className="font-18 text-center">Link: {link}</h1>} */}
        {(!error && !link) && <div>
            { <Loading />}
            <h4 className="font-16 text-center">Calculating Path...</h4>
        </div>}
        
        {error && <h1 className="font-18 text-red text-center">{error}</h1>}
    </div>
}

export default RedirectPage;
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { dispatch } from 'rxjs/internal/observable/pairs';
import ICONS from '../../../assets/svg';
import CustomerMutations from '../../graphql/mutation/Customer';
import Helper from '../../helper/Helper';
import { clearGetAllCustomersListError, getAllCustomersList } from '../../redux/actions/Customer.action';
import { AllStates } from '../../redux/reducers';
import { ICustomerState } from '../../redux/reducers/Customer.reducer';
import { GraphqlService } from '../../services/graphql.service';
import CustomerForm from '../CustomerForm/CustomerForm';
import Loading from '../Loading/Loading';
import Modal from 'react-modal';
import Searchbar from '../Searchbar/Searchbar';
import IColumn from '../Table/models/IColumn';
import Table from '../Table/Table';
import MultilineEllipsis from '../MultilineEllipsis/MultilineEllipsis';
import ReactModal from 'react-modal';

const CustomerTable = ({
                           tableHeight = '67vh',
                           onCustomerClicked = null,
                           topRightDiv = null,
                           refresh = false,
                           tableGridStyle = null}) => {

    const [customerLoading, setCustomerLoading] = useState(false);
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [newCustomerOpened, setNewCustomerOpened] = useState(false);
    const [customerID, setCustomerID] = useState(-1);
    const [key, setKey] = useState('');

    const {loading, customers, error} = useSelector<AllStates>(states => states.customers) as ICustomerState;
    const toast = useToasts();

    if (error)
    {
        toast.addToast(error, {appearance: 'error'});
        dispatch(clearGetAllCustomersListError())
    }

    const menuOptions = [
        {
            label: 'See customer details',
            icon: ICONS.IconSearch,
            condition: () => true,
            action: (row) => {
                Helper.Navigation.NavigateTo('/customer/' + row.id + '/profile');
            }
        }
    ];

    const columns: IColumn[] = [
        {
            label:'CUSTOMER ID',
            active: false,
            name: 'c_id',
            orderBy: 'ASC',
            sort_by: 'id'
        },
        {
            label:'NAME',
            active: false,
            name: '_name',
            orderBy: 'ASC'
        },
        {
            label:'TYPE',
            active: false,
            name: 'type',
            orderBy: 'ASC'
        },
        {
            label:'KEY',
            active: false,
            name: '_key',
            orderBy: 'ASC'
        },
        {
            label:'ADDRESS',
            active: false,
            name: '_address',
            type: 'html',
            orderBy: 'ASC'
        }
    ];


    async function handleSubmitCustomer(customer: any) {
        dispatch(getAllCustomersList());
        setNewCustomerOpened(false);

        setTimeout(() => {
            setCustomerID(customer.id);
        }, 100);
    }

    const ref = useRef();
    const ref1 = useRef();


    const customerFiltered = () => customers.filter(x => {
        if (key.trim() != '')
        {
            return x.key.toLowerCase().startsWith(key.toLowerCase());
        }
        let searchRes = searchTerm.split(' ').map(s => JSON.stringify(x).toLowerCase().includes(s.toLowerCase()));
        return !searchRes.includes(false);
    });


    useEffect(() => {
        dispatch(getAllCustomersList());
    }, []);

    useEffect(() => {
        if (refresh) dispatch(getAllCustomersList());
    }, [refresh]);

    return (<div className={'flex-1-container my0tablw'}>

        <div className='pb-2' style={{zIndex: 2}}>
            <div className="row col-12">
                <h4 className="font-11 ">Customers: {customerFiltered().length} / {customers.length}</h4>
                {/* {topRightDiv || <p></p>} */}
                <div className='col-5'>
                    <div>
                        <div className="d-flex justify-content-end mb-2">
                            <h4 onClick={(evt) => {
                                    setNewCustomerOpened(true)
                                }} className='btn-text'>
                                    <img src={ICONS.IconPlus} alt=""/>
                                    New Customer
                                </h4>
                        </div>
                        <div className="d-flex align-items-center">
                            <button onClick={(evt) => dispatch(getAllCustomersList())} className='btn btn-icon-only mr-2'>
                                <img className={loading ? 'spin' : ''} src={ICONS.IconRefresh} alt=""/>
                            </button>
                            <Searchbar onFocus={(evt) => setKey('')} className={'col-12'} value={searchTerm} background={'#f1f1f1'} onChange={(evt) => setSearchTerm(Helper.Masks.NoSpecialCharacters(evt.target.value))} reference={ref} />
                            <Searchbar onFocus={(evt) => setSearchTerm('')} placeholder={'Key'} className={'ml-2'} value={key} background={'#f1f1f1'} onChange={(evt) => setKey(Helper.Masks.NoSpecialCharacters(evt.target.value))} reference={ref1} />        
                           
                            
                        </div>
                    </div>
                    
                </div>
            </div>
            {/* <div className='d-flex justify-content-between align-items-center'>
                
            </div> */}
        </div>

        <Table loading={loading}
               tableGridStyle={tableGridStyle == null ? { overflow: "auto", position: "relative", flex: 1 } : tableGridStyle}
               rows={customerFiltered().map(x => ({...x, id: x.id, c_id: [`C-${x.id}`], _name: [x.name], billing_address: x.billing_address,  _address: <MultilineEllipsis className={"font-8"} lineLimit={2}>
                       <p>{x.billing_address?.getName(1)}</p>
                       <p>{x.billing_address?.getName(2)}</p>
                   </MultilineEllipsis>, _key: [x.key || '-'], corporate_client: x.corporate_client, type: [x.corporate_client ? 'Corporate' : 'Private']}))}
               columns={columns}
               menuOptions={menuOptions}
               onRowClicked={(evt) =>
                   onCustomerClicked && onCustomerClicked(evt)
               }
               tableHeight={tableHeight == '67vh' ? 'auto' : tableHeight} />

        {/*  MODALS */}

        <ReactModal isOpen={newCustomerOpened} className={"modal-fit modal-large modal-h"}>
            {
                !customerLoading ?
                <CustomerForm onCancel={(evt) => setNewCustomerOpened(false)} onSubmit={(evt) => {handleSubmitCustomer(evt)}} />
                :
                <div className='py-3'>
                    <h4 className='regular-text text-center'>Creating Customer...</h4>
                    <Loading />
                </div>
            }
        </ReactModal>

    </div>)
}


export default CustomerTable;

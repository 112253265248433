import {Customer, Customer_Input, NewCustomerRes, Tax_Info_Input, Update_Customer_Input} from "../customer";
import {GraphqlService} from "../../../../graphql.service";
import {gql} from "apollo-boost";
import {ResultWithLink} from "../../customer_profile/customer_profile";
import {Location_Address_Update} from "../../location/location";

export const create_customer = (input:{customer: Customer_Input}) : Promise<Customer> => {
  let mutation = gql`
    mutation ($customer: customer_input) {
      create_customer(customer: $customer) {
        id
        name
        billing_address {
          street
          street_number
          postal_code
          country
          city
          state
        }
        key
        terms
        corporate_client
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {customer} as 
  Promise<Customer>;
}

export const update_customer = (input:{customer_id: number, customer_info: Update_Customer_Input}) : Promise<NewCustomerRes> => {
  let mutation = gql`
    mutation($customer_id: Int, $customer_info: update_customer_input) {
      update_customer(customer_id: $customer_id, customer_info: $customer_info) {
        message
        success
        customer_id
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {customer_id, customer_info} as 
  Promise<NewCustomerRes>;
}

export const update_customer_tax_info = (input:{customer_id: number, tax_info: Tax_Info_Input}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($customer_id: Int, $tax_info: tax_info_input) {
      update_customer_tax_info(customer_id: $customer_id, tax_info: $tax_info) {
        success
        message
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {customer_id, tax_info} as 
  Promise<ResultWithLink>;
}

export const update_billing_address = (input:{customer_id: number, address: Location_Address_Update}) : Promise<ResultWithLink> => {
  let mutation = gql`
    mutation($address: location_address_update, $customer_id: Int) {
      update_billing_address(customer_id: $customer_id, address: $address) {
        id
        message
        success
      }
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {customer_id, address} as 
  Promise<ResultWithLink>;
}

export const generate_customer_statement = (input:{customer_id: number, master_ids: number[], title: string}) : Promise<string> => {
  let mutation = gql`
    mutation($customer_id: Int!, $master_ids: Int[], $title: String) {
        generate_customer_statement(customer_id: $customer_id, master_ids: $master_ids, title: $title)
    }
  `;
  return GraphqlService.SendQuery(mutation, input) as  //, {customer_id, master_ids, title} as 
  Promise<string>;
}
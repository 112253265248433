import { gql } from 'apollo-boost';
import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { PendingInspection } from '../../../../classes/classes';
import Helper from '../../../../helper/Helper';
import useLoading from '../../../../hooks/useLoading/useLoading';
import useMessageBox from '../../../../hooks/useMessageBox/useMessageBox';
import { GraphqlService } from '../../../../services/graphql.service';
import ConfirmationForm from '../../components/ConfirmationForm/ConfirmationForm';
import Content from '../../components/Content/Content';
import Signature from '../../components/Signature/Signature';
import Toolbar from '../../components/Toolbar/Toolbar';
import InspectionObservable from '../../observables/InspectionObservable';

const Confirmation = () => {
    const {token} = useParams<any>();

    const [originalData, setOriginalData] = useState(new PendingInspection());
    const [currentData, setCurrentData] = useState(new PendingInspection());
    
    const originalDataRef = useRef<PendingInspection>(new PendingInspection());

    const [signatures, setSignatures] = useState({});

    const toast = useToasts();
    const messageBox = useMessageBox();

    const loading = useLoading();

    const backLink = `/pending-inspection/${token}/notes`;

    async function getTempLink(uid) {
        const query = gql`
            query($uid:String){
                get_temp_link(uid:$uid)
            }
        `;
        const variables = {uid}

        try {
            console.log(variables)
            const data = await GraphqlService.SendQuery(query, variables);
            return data;

        } catch (ex) {
            return '';
        }
    }

    async function loadSignatures() {
        try {
            const sign: any = {};
            if (originalData.signed_by.uid)
            {
                sign.origin = await getTempLink(originalData.signed_by.uid);
            }
            if (originalData.performed_by.uid)
            {
                sign.driver = await getTempLink(originalData.performed_by.uid);
            }

            console.log(sign);
            setSignatures(sign);
        } catch (ex) {
            
        }
    }

    useEffect(() => {
        
        originalDataRef.current.vehicle_data = originalData.vehicle_data;
        originalDataRef.current.note = originalData.note;
        originalDataRef.current.pending_inspection_id = originalData.pending_inspection_id;
        originalDataRef.current.signed_by = originalData.signed_by;
        originalDataRef.current.performed_by = originalData.performed_by;
        loadSignatures();
            
    }, [originalData]);

    async function getConfirmation(title, message)
    {
        return new Promise((res, rej) => {
            messageBox.open({title, message, buttons: [{text: 'Cancel', css: 'btn btn-clear text-blue-light', action: () => res(false)}, {text: 'Acept', action: () => res(true)}]});
        })
    }


    async function handleSubmitSignature(type, file, name) {
        const mutation = type == 'origin' ?
        gql`
            mutation($pending_inspection_id:Int, $name:String, $file:Upload ){
                upload_customer_signature(pending_inspection_id:$pending_inspection_id, name:$name, file:$file){
                    id
                    message
                    success
                }
            }
        ` 
        : 
        gql`
            mutation($pending_inspection_id:Int, $name:String, $file:Upload ){
                upload_user_signature_pending_inspection(pending_inspection_id:$pending_inspection_id, name:$name, file:$file){
                    id
                    message
                    success
                }
            }
        `;


        const variables = {pending_inspection_id: originalData.pending_inspection_id, name, file};

        loading.open('Submitting Singature...');

        try {
            const data = await GraphqlService.SendMutation(mutation, variables);
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            loading.close();
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true})
        }
    }

    async function isValidToSubmit()
    {
        if (originalData.vehicle_data.vin.trim().length != 17 && (!originalData.vehicle_data.special_vin && !originalData.vehicle_data.no_vin))
            {toast.addToast('Vin must be 17 digits', {appearance: 'error', autoDismiss: true}); return false;}
        else if (originalData.vehicle_data.vin.trim().length == 0 && originalData.vehicle_data.special_vin)
            { toast.addToast('Vin is required for vehicles with "special vin"', {appearance: 'error', autoDismiss: true}); return false;}
        if (originalData.vehicle_data.year.trim() == '' || originalData.vehicle_data.make.trim() == '' || originalData.vehicle_data.model.trim() == '')
            {toast.addToast('Vehicle Year, Make, Model is required', {appearance: 'error', autoDismiss: true}); return false;}

        if (originalData.photos.some(x => x.uid == ''))
            {toast.addToast('Mandatory photos are missing', {appearance: 'error', autoDismiss: true}); return false;}

        if (originalData.odometer == null)
            { toast.addToast('Odometer is required', {appearance: 'error', autoDismiss: true}); return false;}

        if (!originalData.signed_by.name.trim())
            {toast.addToast('Signed by name is required', {appearance: 'error', autoDismiss: true}); return false;}
        if (!originalData.performed_by.name.trim())
            {toast.addToast('Performed by name is required', {appearance: 'error', autoDismiss: true}); return false;}

        if (!originalData.signed_by.uid)
            { toast.addToast('Signed by signature is required', {appearance: 'error', autoDismiss: true}); return false;}
        if (!originalData.performed_by.uid)
            { toast.addToast('Performed by signature is required', {appearance: 'error', autoDismiss: true}); return false;}
        
        if (originalData.damages.length == 0 && !(await getConfirmation('Warning', 'This vehicle has been marked with no damages, do you want continue?')))
            return false;
        if (originalData.accessories.length == 0 && !(await getConfirmation('Warning', 'This vehicle has been marked with no accessories, do you want continue?')))
            return false;


        
        return true;

    }

    useEffect(() => {
        let query = InspectionObservable.GetObservable(token);

        let subs = query.onResults.subscribe(result => {
            if (result.error)
                toast.addToast(result.error.message, {appearance: 'error', autoDismiss: true})
            else
            {
                console.log('something changed');
                let o = new PendingInspection(result.data);

                
                const s1 = JSON.stringify({name: originalDataRef.current.signed_by.name, uid: originalDataRef.current.signed_by.uid});
                const s2 = JSON.stringify({name: o.signed_by.name, uid: o.signed_by.uid});

                const p1 = JSON.stringify({name: originalDataRef.current.performed_by.name, uid: originalDataRef.current.performed_by.uid});
                const p2 = JSON.stringify({name: o.performed_by.name, uid: o.performed_by.uid});


                console.log(s1, s2);
                console.log('------');
                console.log(p1, p2);

                let diff = s1 != s2 ? 1 : (p1 != p2 ? 2 : 0);


                if (diff != 0 || originalDataRef.current.pending_inspection_id == null)
                {
                    if (originalDataRef.current.pending_inspection_id != null)
                        toast.addToast(`User #${diff == 1 ? o.signed_by.user_id : o.performed_by.user_id} has changed`, {appearance: 'warning', autoDismiss: true});
                }

                setCurrentData(new PendingInspection(result.data));

                setOriginalData(o);

            }
        });

        return () => {
            subs.unsubscribe();
            query.stopPolling();
        }
    }, []);

    async function handleSubmit() {
        const isValid = await isValidToSubmit();

        if (!isValid) return;

        const mutation = gql`
            mutation($pending_inspection_id:Int, $gps:String, $device_info:String, $device_type:String){
                finish_inspection(pending_inspection_id:$pending_inspection_id, gps:$gps, device_info:$device_info, device_type:$device_type){
                    id
                    message
                    success
                    link
                }
            }
        `;
        try {
            const variables = {pending_inspection_id: originalData.pending_inspection_id, gps: '{}', device_info: '{}', device_type: '{}'};
            loading.open('Submitting inspection...');
            const data = await GraphqlService.SendMutation(mutation, variables)
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');

            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            loading.close();
            setTimeout(() => {
                
                window.location.href = data.link;
            }, 1000);

        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    return <>
                <Toolbar vehicle={currentData.vehicle_data} />

                <Content>
                    <div className="mb-3">
                        <h4 className="font-11">Inspection Confirmation</h4>
                    </div>
                    <div className='flex-1-container'>
                           <ConfirmationForm onSubmitSignature={handleSubmitSignature} signatures={signatures} data={originalData}/>
                    </div>

                    <div className="row mt-3">
                        <button onClick={(evt) => Helper.Navigation.NavigateTo(backLink)} className="btn btn-back col-6"><i className="fas fa-chevron-left"></i> NOTES</button>
                        <button onClick={handleSubmit} className="btn btn-blue-light col-6">SUBMIT</button>
                    </div>
                </Content>
            </>
}

export default Confirmation;

import React, { useRef } from 'react';
import ICONS from '../../../assets/svg';
import Helper from '../../helper/Helper';

import './Searchbar.scss';

const Searchbar = ({onKeyUp, placeholder = 'Search...', className = '', onFocus, onBlur, onChange, onKeyEnter, value, reference, background }: {background?: string, placeholder?: string, onFocus?: any, onBlur?: any, onChange?: any, onKeyEnter?: any, value?: string, reference: any, className?: string, onKeyUp?}) => {

    return (
        <div style={{position: 'relative'}} className={'searchbar-component ' + className}>
            <img onClick={(evt) => reference.current.focus()} style={{position:'absolute', top: '50%', left: '.15rem', transform: 'translateY(-50%)', cursor: 'pointer'}} src={ICONS.IconSearch} alt=""/>
            <input style={{background: background ? background : ''}}
                    autoComplete='off'
                   onFocus={onFocus}
                   onBlur={onBlur}
                   className={'search-input '}
                   ref={reference}
                   value={value}
                   onKeyUp={(evt) => {
                       if (evt.key == 'Enter') onKeyEnter && onKeyEnter(evt);
                       if (onKeyUp) onKeyUp(evt);
                   }}
                   onChange={(evt) => onChange && onChange(evt)} type="text" placeholder={placeholder}/>
            <img onClick={(evt)  => { if (reference?.current) {
                                    reference.current.value = '';
                                    reference.current.focus();
                                } onChange && onChange({target: {value: ''}}) }
                                } style={{position:'absolute', top: '50%', right: '0.45rem', transform: 'translateY(-50%)', cursor: 'pointer'}} src={ICONS.IconDelete} alt=""/>
        </div>
    )
}
export default Searchbar;